import config from "../config";
import {
  handleResponse,
  authHeader,
  handleResponseWithoutError,
  handleRegistrationResponse,
} from "../_helpers";

export const usersService = {
  getUsers,
  getMe,
  modifyUser,
  modifyEmail,
  createUser,
  multiOrgEmail,
  getRoles,
  deleteUser,
  toggleNetwork,
  getNetworkCode,
  getMuseums,
  deleteMuseum,
  addToNetwork,
  checkIfAdmin,
  getUserFromEmail,
  getPermissions,
  confirmUser,
};

function getUsers() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/organisations/users`, requestOptions).then(
    handleResponse
  );
}

function getMe() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/users/me`, requestOptions).then(
    handleResponse
  );
}

function getRoles() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/users-permissions/roles`,
    requestOptions
  ).then(handleResponse);
}

function getNetworkCode() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/organisations/admissionCode`,
    requestOptions
  ).then(handleResponse);
}

function checkIfAdmin() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/retis/isAdmin`, requestOptions).then(
    handleResponse
  );
}

function getMuseums() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/retis/getMuseums`, requestOptions).then(
    handleResponse
  );
}

function modifyUser(org_museo, org_roles_map, id) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      org_museo,
      org_roles_map,
    }),
  };

  return fetch(`${config.api.url}/users/role/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function toggleNetwork(flag_network) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      flag_network,
    }),
  };

  return fetch(`${config.api.url}/users/switch`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

async function addToNetwork(code) {
  const requestOptions = { method: "GET", headers: await authHeader() };
  return fetch(`${config.api.url}/retis/admit/${code}`, requestOptions).then(
    handleResponse
  );
}

function createUser(username, email, password, role, org_museo, org_rete) {
  let flag_network = false;
  let flag_network_mobile = false;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username,
      email,
      password,
      role,
      org_museo,
      org_rete,
      flag_network,
      flag_network_mobile,
    }),
  };

  return fetch(`${config.api.url}/auth/local/register`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function multiOrgEmail(email, org_museo, role) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      email,
      org_museo,
      role,
    }),
  };

  return fetch(`${config.api.url}/auth/local/register/multiOrg`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function modifyEmail(email) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      email,
    }),
  };

  return fetch(`${config.api.url}/users/email`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function getUserFromEmail(email) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/users/fromEmail/${email}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteUser(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };

  return fetch(`${config.api.url}/users/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteMuseum(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };

  return fetch(`${config.api.url}/retis/remove/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function getPermissions(role) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/role-permissions/json/${role}`,
    requestOptions
  ).then(handleResponseWithoutError);
}

async function confirmUser(confirmation) {
  const requestOptions = {
    method: "GET",
  };

  return fetch(
    `${config.api.url}/auth/email-confirmation?confirmation=${confirmation}`,
    requestOptions
  ).then(handleRegistrationResponse);
}
