import React, { useState, useEffect } from "react";
import logo from "./../img/logo_white.png";
import { eventsService, reservationsService } from "../_services";
import colors from "./../museumColors";
import ReactLoading from "react-loading";
import moment from "moment";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const DeleteReservation = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isMobile, setIsMobile] = useState(false);
  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState("");
  const [step, setStep] = useState(1);
  const [mainReservation, setMainReservation] = useState(null);
  const [reservations, setReservations] = useState([]);

  useEffect(() => {
    if (windowSize.innerWidth > 800) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize.innerWidth]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    console.log(mainReservation);
    console.log(reservations);
  }, [mainReservation, reservations]);

  useEffect(() => {
    /**
     * Retrieve reservation
     */
    const retrieveReservation = () => {
      if (props.match.params.type.toLowerCase() === "evento") {
        reservationsService
          .getOnlineEventReservation(props.match.params.id)
          .then((response) => {
            if (response.status === false) {
              setShowError(true);
              setMessage(response.message);
              setMainReservation(null);
              setStep(2);
            } else {
              setShowError(false);
              setMainReservation(response.message);
              setMessage("");
              eventsService
                .getGroup(response.message.gruppo?.id)
                .then((res) => {
                  setReservations(res.prenotazioni_eventi);
                  setStep(2);
                });
            }
          })
          .catch((errors) => {
            setStep(2);
            // c'è stato un errore, mostra il messaggio
            setShowError(true);
            setMessage(errors.message[0].messages[0].message);
          });
      } else if (props.match.params.type.toLowerCase() === "ingresso") {
        reservationsService
          .getOnlineAccessReservation(props.match.params.id)
          .then((response) => {
            if (response.status === false) {
              setShowError(true);
              setMessage(response.message);
              setMainReservation(null);
            } else {
              setShowError(false);
              setMainReservation(response.message);
              setMessage("");
            }
            setStep(2);
          })
          .catch((errors) => {
            setStep(2);
            // c'è stato un errore, mostra il messaggio
            setShowError(true);
            setMessage(errors.message[0].messages[0].message);
          });
      } else {
        //Mostra errore
      }
    };

    retrieveReservation();
  }, [props.match.params.id, props.match.params.type]);

  /**
   * Delete reservation
   */
  const deleteReservation = (id) => {
    setStep(1);
    setMessage("");
    setShowError(false);
    if (props.match.params.type.toLowerCase() === "evento") {
      reservationsService
        .deleteEventReservationOnline(id, props.match.params.otp, reservations)
        .then((response) => {
          if (response.status === false) {
            setStep(2);
            setShowError(true);
            setMessage(response.message);
          } else {
            setStep(3);
          }
        })
        .catch((errors) => {
          setStep(2);
          setShowError(true);
          setMessage(errors.message[0].messages[0].message);
        });
    } else if (props.match.params.type.toLowerCase() === "ingresso") {
      reservationsService
        .deleteAccessReservationOnline(id, props.match.params.otp)
        .then((response) => {
          if (response.status === false) {
            setStep(2);
            setShowError(true);
            setMessage(response.message);
          } else {
            setStep(3);
          }
        })
        .catch((errors) => {
          setStep(2);
          setShowError(true);
          setMessage(errors.message[0].messages[0].message);
        });
    } else {
      setStep(2);
      setShowError(true);
      setMessage("Link errato");
    }
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.secondary,
      overflow: "scroll",
    },
    header: {
      display: isMobile ? "" : "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "10px",
    },
    mainContent: {
      display: "flex",
      width: isMobile ? "90%" : "80%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    content: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    textInput: {
      outline: "none",
      padding: 10,
      textAlign: "left",
      width: "100%",
      border: "1px solid lightgray",
      borderRadius: 5,
      fontFamily: "inherit",
      resize: "none",
      backgroundColor: colors.quaternary,
      marginRight: isMobile ? 0 : 20,
    },
    field: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      marginTop: 20,
    },
    label: {
      color: colors.primary,
      width: "100%",
    },
    text: {
      color: colors.darkgray,
      fontWeight: "bold",
      width: "100%",
    },
    message: {
      color: colors.white,
      fontWeight: 700,
      fontSize: 13,
      width: "100%",
      backgroundColor: "rgba(277,277,277,0.3)",
    },
  };

  return (
    <div style={styles.container}>
      {step !== 1 && (
        <a style={styles.header} href="https://www.rnb4culture.com/emma/">
          <img
            alt="App logo"
            src={logo}
            style={{ width: 200, objectFit: "contain" }}
          />
        </a>
      )}
      <div style={styles.mainContent}>
        <div
          style={{
            height: "80%",
            width: isMobile ? "90%" : "80%",
            marginTop: 20,
            backgroundColor: step === 1 ? "" : colors.white,
            padding: 30,
            borderRadius: 10,
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={styles.content}>
            {step === 1 && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={colors.primary}
                  height={50}
                  width={50}
                />
              </div>
            )}
            {step === 2 && mainReservation !== null && (
              <form
                style={{
                  width: isMobile ? "100%" : "70%",
                  padding: 30,
                  borderRadius: 10,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onSubmit={handleSubmit}
              >
                <h2 style={{ color: colors.primary }}>La tua prenotazione</h2>
                <h3
                  style={{
                    color: colors.secondary,
                    fontSize: 13,
                    marginBottom: 0,
                  }}
                >
                  Sei sicuro di voler annullare la tua prenotazione?{" "}
                </h3>
                <div style={styles.field}>
                  <p style={styles.label}>Codice prenotazione</p>
                  <p style={styles.text}>{mainReservation.codice}</p>
                </div>
                <div style={styles.field}>
                  <p style={styles.label}>Evento</p>
                  <p style={styles.text}>{mainReservation.nome}</p>
                </div>
                <div style={styles.field}>
                  <p style={styles.label}>Giorno</p>
                  <p style={styles.text}>
                    {moment(new Date(mainReservation.giorno)).format(
                      "DD/MM/yyyy"
                    )}
                  </p>
                </div>
                <div style={styles.field}>
                  <p style={styles.label}>Ora</p>
                  <p style={styles.text}>
                    {moment(new Date(mainReservation.ora_inizio)).format(
                      "HH:mm"
                    )}
                  </p>
                </div>
                <div style={styles.field}>
                  <p style={styles.label}>Luogo</p>
                  <p style={styles.text}>{mainReservation.luogo}</p>
                </div>
                <div style={styles.field}>
                  <p style={styles.label}>N° prenotati</p>
                  <p style={styles.text}>
                    {reservations.length > 0
                      ? reservations.length
                      : mainReservation.numero_partecipanti}
                  </p>
                </div>
                {showError && (
                  <p style={{ color: colors.red }}>ATTENZIONE! {message}</p>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <button
                    onClick={() => deleteReservation(mainReservation.id)}
                    style={{
                      border: "none",
                      width: "100%",
                      backgroundColor: "transparent",
                      outline: "none",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        color: colors.white,
                        fontWeight: "bolder",
                        fontSize: 15,
                        backgroundColor: colors.primary,
                        borderRadius: 10,
                        padding: 20,
                      }}
                    >
                      ANNULLA PRENOTAZIONE
                    </p>
                  </button>
                </div>
              </form>
            )}

            {step === 3 && (
              <div
                style={{
                  width: "50%",
                  marginTop: 30,
                  marginLeft: 10,
                  padding: 30,
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 10,
                }}
              >
                <h2 style={{ color: colors.primary }}>
                  {showError === true ? "Errore" : "Prenotazione annullata"}
                </h2>
                <p style={{ color: colors.darkgray }}>{message}</p>
              </div>
            )}
          </div>
          <div style={{ display: "flex", flex: 1 }}></div>
        </div>
      </div>
    </div>
  );
};

export default DeleteReservation;
