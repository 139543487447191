import React, { useState } from "react";
import copy from "./../../../img/copy.png";
import { CopyToClipboard } from "react-copy-to-clipboard";

const CopyLinkButton = ({
  text,
  colors,
  width,
  backgroundColor,
  link,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [coordinates, setCoordinates] = useState(false);
  const styles = {
    buttonBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 2,
      cursor: "pointer",
    },
    hoverLabel: {
      textAlign: "center",
      fontSize: 12,
      width: width,
      position: "absolute",
      color: colors?.darkgray,
      zIndex: 100,
      backgroundColor: backgroundColor,
      borderRadius: 10,
      padding: 20,
      top: coordinates.y,
      left: coordinates.x,
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      cursor: "pointer",
    },
  };
  return (
    <div
      style={styles.buttonBox}
      onMouseEnter={() => {
        if (!disabled) {
          setOpen(true);
        }
      }}
      onMouseLeave={() => setOpen(false)}
      onMouseMove={(e) => {
        setCoordinates({
          x: width === 200 ? e.pageX - window.innerWidth + 150 : e.pageX,
          y: e.pageY - 100,
        });
      }}
    >
      {open && <p style={styles.hoverLabel}>{copied ? "Copiato!" : text}</p>}
      <CopyToClipboard text={link}>
        <button
          style={{
            ...styles.button,
            color: colors?.white,
            opacity: disabled ? 0.3 : 1,
          }}
          onClick={() => {
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 3000);
          }}
          disabled={disabled}
        >
          <img
            alt="Download icon"
            src={copy}
            style={{ height: 15, objectFit: "contain" }}
          />
        </button>
      </CopyToClipboard>
    </div>
  );
};

export default CopyLinkButton;
