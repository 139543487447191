import React from "react";
import moment from "moment";
import "moment/locale/it";
moment.locale("it");

const camelCase = (str) => {
  return str.substring(0, 1).toUpperCase() + str.substring(1);
};

const titles = [
  "",
  "Codice",
  "Card",
  "Nome",
  "Cognome",
  "Acquistato il",
  "Scadenza",
];
const Table = ({ cards, cardTypes, colors, setOpenRecord }) => {
  const styles = {
    table: {
      textAlign: "center",
      borderCollapse: "collapse",
      border: "1px solid #ddd",
      width: "100%",
      overflow: "scroll",
    },
    headerCell: {
      border: "1px solid #ddd",
      padding: "8px",
      paddingTop: "12px",
      paddingBottom: "12px",
      textAlign: "center",
      backgroundColor: colors.verylightgray,
      fontSize: 12,
      color: colors.gray,
    },
    field: {
      border: "1px solid #ddd",
      padding: "8px",
      fontSize: 12,
    },
    row: {
      cursor: "pointer",
    },
  };

  const renderTableHeader = () => {
    return titles.map((key, index) => {
      return (
        <th style={styles.headerCell} key={index}>
          {key.toUpperCase()}
        </th>
      );
    });
  };

  const renderTableData = () => {
    return cards.map((card, key) => {
      const { codice, target_tessere, nome, cognome, created_at, scadenza } =
        card;
      return (
        <tr
          key={key}
          style={styles.row}
          onClick={() => {
            setOpenRecord(card);
          }}
        >
          <td style={styles.field}>{key + 1}</td>
          <td style={styles.field}>{codice}</td>
          <td style={styles.field}>
            {cardTypes.find(
              (type) => type.id === target_tessere.tipologie_tessere
            ).nome +
              " - " +
              target_tessere.nome}
          </td>
          <td style={styles.field}>{camelCase(nome)}</td>
          <td style={styles.field}>{camelCase(cognome)}</td>
          <td style={styles.field}>
            {moment(new Date(created_at)).format("DD/MM/yyyy, HH:mm")}
          </td>
          <td style={styles.field}>
            {moment(new Date(scadenza)).format("DD/MM/yyyy")}
          </td>
        </tr>
      );
    });
  };
  return (
    <table style={styles.table}>
      <tbody>
        <tr>{renderTableHeader()}</tr>
        {renderTableData()}
      </tbody>
    </table>
  );
};

export default Table;
