import React from "react";
import moment from "moment";

const TimeSlot = ({ starting, closing, setOpening, setClosing, edit }) => {
  const styles = {
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: 20,
      marginRight: 20,
    },
    textInput: {
      borderRadius: 5,
      outline: "none",
      border: "1px solid gray",
      textAlign: "center",
      marginRight: 5,
      marginLeft: 5,
      fontFamily: "Arial",
      width: 70,
      height: 20,
    },
  };
  return (
    <div style={styles.row}>
      {edit ? (
        <input
          type="time"
          name="startingTime"
          value={moment(starting).format("HH:mm")}
          onChange={(event) => {
            let hour = event.target.value.split(":")[0];
            let minutes = event.target.value.split(":")[1];
            let temp = new Date();
            temp.setHours(hour, minutes, 0, 0);
            setOpening(temp.toISOString());
          }}
          style={styles.textInput}
        />
      ) : (
        <p style={{ fontSize: 14 }}>
          {starting
            ? moment(starting).format("HH:mm")
            : "Nessuno slot orario valido"}
        </p>
      )}
      {((!edit && starting) || edit) && <p>{" - "}</p>}
      {((!edit && starting) || edit) &&
        (edit ? (
          <input
            type="time"
            name="endingTime"
            value={moment(closing).format("HH:mm")}
            onChange={(event) => {
              let hour = event.target.value.split(":")[0];
              let minutes = event.target.value.split(":")[1];
              let temp = new Date();
              temp.setHours(hour, minutes, 0, 0);
              if (temp < new Date(starting)) {
                temp = new Date();
                temp.setDate(new Date().getDate() + 1);
                temp.setHours(hour, minutes, 0, 0);
              }
              setClosing(temp.toISOString());
            }}
            style={styles.textInput}
          />
        ) : (
          <p style={{ fontSize: 14 }}>
            {closing ? moment(closing).format("HH:mm") : "(non valido)"}
          </p>
        ))}
    </div>
  );
};

export default TimeSlot;
