import React from "react";
import ReactLoading from "react-loading";

const HandleWaitingList = ({ closeModal, saveData, colors, loader }) => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      width: 500,
      height: 300,
      zIndex: 300,
    },
    loaderBox: {
      display: "flex",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      zIndex: 300,
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors.primary,
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    content: {
      width: "100%",
      flex: 6,
      backgroundColor: colors.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    textInput: {
      border: "1px solid lightgray",
      borderRadius: 10,
      width: "90%",
      padding: 18,
      outline: "none",
    },
    field: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      width: "100%",
      marginLeft: 50,
      marginRight: 50,
    },
    footer: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors?.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    cancelButton: {
      borderWidth: 1,
      borderColor: colors?.primary,
      backgroundColor: colors?.white,
      color: colors?.primary,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
  };
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: colors.gray,
          opacity: 0.3,
        }}
      />
      {loader ? (
        <div style={styles.loaderBox}>
          <ReactLoading
            type={"spinningBubbles"}
            color={colors.primary}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <div style={styles.container}>
          <div style={styles.header}>
            <p style={{ color: colors.white, fontSize: 20 }}>
              GESTIONE LISTA D'ATTESA
            </p>
          </div>
          <div style={styles.content}>
            <div style={styles.field}>
              <p style={{ textAlign: "left", color: colors?.darkgray }}>
                Sono presenti richieste di prenotazione in lista d'attesa.{" "}
                <br /> Si desidera far scalare i prenotati dalla lista?
              </p>
            </div>
          </div>
          <div style={styles.footer}>
            <button
              onClick={() => {
                closeModal();
              }}
              style={styles.cancelButton}
            >
              NON SCALARE
            </button>
            <button
              onClick={() => {
                saveData();
              }}
              style={styles.button}
            >
              SCALA
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default HandleWaitingList;
