import React, { useContext } from "react";
import { LanguageContext } from "../../containers/language";

const Footer = ({ colors, total, infoCollected, createCards, isVip }) => {
  const { dictionary } = useContext(LanguageContext);

  const styles = {
    footer: {
      display: "flex",
      flex: 1,
      width: "100%",
      background: colors.secondary,
      alignItems: "center",
      justifyContent: "space-between",
      boxShadow: "0 0 4px 2px rgb(0 0 0 / 10%)",
    },
    total: {
      color: colors.white,
      marginTop: 25,
      marginBottom: 25,
      marginLeft: 80,
      marginRight: 30,
    },
    currency: { color: colors.white, fontWeight: "700", fontSize: 30 },
    button: {
      backgroundColor: colors.primary,
      borderRadius: 10,
      padding: 5,
      color: colors.white,
      fontSize: 15,
      width: 150,
      marginRight: 50,
      cursor: "pointer",
      border: "none",
      outline: "none",
    },
  };
  return (
    <div style={styles.footer}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <p style={styles.total}>{dictionary.total}</p>
        <p style={styles.currency}>€ {isVip ? "0.00" : total?.toFixed(2)}</p>
      </div>
      {total > 0 && infoCollected && (
        <button style={styles.button} onClick={createCards}>
          <p>{dictionary.endSale.toUpperCase()}</p>
        </button>
      )}
    </div>
  );
};

export default Footer;
