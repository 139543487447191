import React, { useContext } from "react";
import { UserContext } from "../contexts";
import moment from "moment";
import pencil from "./../img/modify_gray.png";
import trash from "./../img/delete_gray.png";
import lightEuro from "./../img/sale_topay.png";
import darkEuro from "./../img/sale_euro.png";

function restoreName(nameString) {
  if (nameString.includes("_DEL")) {
    var splitName = nameString.split("_DEL");
    return splitName[0];
  } else {
    return nameString;
  }
}

const Purchase = ({
  purchase,
  setSelection,
  setInvoiceSelection,
  onDelete,
}) => {
  const colors = useContext(UserContext)?.colors;

  const paidPurchase = purchase.datetime_pagamento ? true : false;
  const name = purchase.acquisti_articoli
    ? restoreName(purchase.acquisti_articoli.nome_articolo)
    : "-";
  const category = purchase.acquisti_articoli
    ? purchase.acquisti_articoli.nome_categoria
    : "-";
  const unitaryCost = purchase.costo_articolo;
  const quantity = purchase.quantita;
  const purchaseMethod = purchase.modalita_acquisto
    ? purchase.modalita_acquisto.nome_modalita
    : "-";
  const seller = purchase.venditore;

  function capitalizeFirstLetter(string) {
    if (string) return string.charAt(0).toUpperCase() + string.slice(1);
    else return "-";
  }

  const styles = {
    field: {
      width: 170,
      fontSize: 15,
    },
    smallField: {
      width: 80,
      fontSize: 15,
    },
    profileBox: {
      flex: 1,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    menu: {
      border: "1px solid lightgray",
      backgroundColor: colors?.white,
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 10,
      fontSize: 12,
      paddingBottom: 10,
      color: colors?.darkgray,
      borderRadius: 20,
      outline: "none",
      height: 2,
    },
    label: {
      position: "absolute",
      backgroundColor: colors?.quaternary,
      paddingLeft: 15,
      paddingRight: 15,
      borderRadius: 15,
      zIndex: 100,
      color: colors?.darkgray,
    },
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              ...styles.field,
              width: 220,
            }}
          >
            <p>{capitalizeFirstLetter(name)}</p>
          </div>
        </div>
        <div style={styles.field}>
          <p>{capitalizeFirstLetter(category)}</p>
        </div>
        <div style={styles.field}>
          <p>
            {moment(new Date(purchase.datetime_acquisto)).format(
              "DD/MM/yyyy, HH:mm"
            )}
          </p>
        </div>
        <div style={styles.smallField}>
          <p>
            {unitaryCost !== null && unitaryCost !== undefined
              ? "€ " + unitaryCost.toFixed(2)
              : "-"}
          </p>
        </div>
        <div style={styles.smallField}>
          <p>{quantity}</p>
        </div>
        <div style={styles.field}>
          <p>
            {purchaseMethod !== undefined
              ? capitalizeFirstLetter(purchaseMethod)
              : "-"}
          </p>
        </div>
        <div style={styles.field}>
          <p>{seller}</p>
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginLeft: 10 }}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setInvoiceSelection(purchase);
            }}
            style={{
              zIndex: 200,
              border: "none",
              backgroundColor: "transparent",
              outline: "none",
              cursor: "pointer",
            }}
          >
            <img
              alt="Payment icon"
              src={paidPurchase ? darkEuro : lightEuro}
              style={{ width: 12, objectFit: "contain", margin: 10 }}
            />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setSelection(purchase);
            }}
            style={{
              zIndex: 200,
              border: "none",
              backgroundColor: "transparent",
              outline: "none",
              cursor: "pointer",
            }}
          >
            <img
              alt="Modify icon"
              src={pencil}
              style={{ width: 12, objectFit: "contain", margin: 10 }}
            />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onDelete(purchase);
            }}
            style={{
              zIndex: 200,
              border: "none",
              backgroundColor: "transparent",
              outline: "none",
              cursor: "pointer",
            }}
          >
            <img
              alt="Delete icon"
              src={trash}
              style={{ width: 12, objectFit: "contain", margin: 10 }}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Purchase;
