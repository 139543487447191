import React, { useState, useContext } from "react";
import { UserContext, OrgContext } from "../../contexts";
import arrow from "../../img/arrow_back_dark.png";
import deleteIcon from "../../img/delete_darkgray.png";
import pencil from "../../img/modify_black.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import EndOperation from "../../components/popup/endOperation";
import { interventionService } from "../../_services";
import DeleteIntervention from "../../components/popup/deleteIntervention";
import InterventionBox from "./interventionBox";
import NoticeBox from "./noticeBox";
import ReactLoading from "react-loading";

function isEmpty(value) {
  return value === null || value === undefined || value === "";
}
const InterventionPage = (props) => {
  const colors = useContext(UserContext)?.colors;
  const org = useContext(OrgContext)?.org;
  const info_1L = org.configurazioni.intervento_1L;
  const info_2L = org.configurazioni.intervento_2L;
  const [title, setTitle] = useState(props.openRecord.titolo || "");
  const [closing1L, setClosing1L] = useState(
    props.openRecord.intervento1L?.data_chiusura
      ? props.openRecord.intervento1L?.data_chiusura
      : null
  );
  const [closing2L, setClosing2L] = useState(
    props.openRecord.intervento2L?.data_chiusura
      ? props.openRecord.intervento2L?.data_chiusura
      : null
  );
  const [report1L, setReport1L] = useState(
    props.openRecord.intervento1L?.report || ""
  );
  const [report2L, setReport2L] = useState(
    props.openRecord.intervento2L?.report || ""
  );
  const [status1L, setStatus1L] = useState(
    props.openRecord.intervento1L?.stato || "Intervento in corso"
  );
  const [status2L, setStatus2L] = useState(
    props.openRecord.intervento2L?.stato || "Intervento in corso"
  );
  const [isEditing, setIsEditing] = useState(props.isCreation);
  const [isEditing1L, setIsEditing1L] = useState(false);
  const [isEditing2L, setIsEditing2L] = useState(false);
  const [loader, setLoader] = useState(false);
  const [deleteIntervention, setDeleteIntervention] = useState(false);
  const [endOperation, setEndOperation] = useState(false);
  const [operationResult, setOperationResult] = useState(false);

  const createRecord = () => {
    setLoader(true);
    let segnalazione = props.openRecord.segnalazione;
    segnalazione.data_creazione = new Date();
    segnalazione.stato = "Nuova";

    interventionService
      .createRecord(org.id, title, segnalazione)
      .then((response) => {
        setLoader(false);
        props.setIsCreation(false);
        setIsEditing(false);
        props.setOpenRecord(response);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  const updateRecord = (param) => {
    setLoader(true);
    interventionService
      .updateRecord(props.openRecord.id, param)
      .then((response) => {
        setLoader(false);
        setIsEditing(false);
        props.setOpenRecord(response);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };
  const openIntervention1L = () => {
    let segnalazione = props.openRecord.segnalazione;
    segnalazione.stato = "Intervento in corso";
    let intervento1L = {
      data_creazione: new Date(),
      stato: "Intervento in corso",
      info: info_1L,
    };
    let param = {
      segnalazione: segnalazione,
      intervento1L: intervento1L,
    };
    updateRecord(param);
  };

  const closeIntervention1L = () => {
    let segnalazione = props.openRecord.segnalazione;
    let intervento2L = props.openRecord.intervento2L;
    if (isEmpty(intervento2L?.data_creazione)) {
      if (status1L === "Risolto") {
        segnalazione.stato = "Risolta";
      } else if (status1L === "Non risolto") {
        segnalazione.stato = "Non risolta";
      } else {
        segnalazione.stato = "Intervento in corso";
      }
    }

    segnalazione.data_chiusura = closing1L;
    let intervento1L = props.openRecord.intervento1L;
    intervento1L.report = report1L;
    if (status1L !== "Intervento in corso")
      intervento1L.data_chiusura = closing1L;

    if (status1L === "Risolto") {
      intervento1L.stato = "Risolto";
    } else if (status1L === "Non risolto") {
      intervento1L.stato = "Non risolto";
    } else {
      intervento1L.stato = "Intervento in corso";
      intervento1L.data_chiusura = null;
    }

    let param = {
      segnalazione: segnalazione,
      intervento1L: intervento1L,
    };
    updateRecord(param);
    setIsEditing1L(false);
  };

  const openIntervention2L = () => {
    let segnalazione = JSON.parse(
      JSON.stringify(props.openRecord.segnalazione)
    );
    segnalazione.stato = "Intervento in corso";
    let intervento2L = {
      data_creazione: new Date(),
      stato: "Intervento in corso",
      info: info_2L,
    };
    let param = {
      segnalazione: segnalazione,
      intervento2L: intervento2L,
    };
    updateRecord(param);
  };

  const closeIntervention2L = () => {
    let segnalazione = props.openRecord.segnalazione;
    if (status2L === "Risolto") {
      segnalazione.stato = "Risolta";
    } else if (status2L === "Non risolto") {
      segnalazione.stato = "Non risolta";
    } else {
      segnalazione.stato = "Intervento in corso";
    }
    segnalazione.data_chiusura = closing2L;
    let intervento2L = props.openRecord.intervento2L;
    intervento2L.report = report2L;
    if (status2L !== "Intervento in corso")
      intervento2L.data_chiusura = closing2L;

    if (status2L === "Risolto") {
      intervento2L.stato = "Risolto";
    } else if (status2L === "Non risolto") {
      intervento2L.stato = "Non risolto";
    } else {
      intervento2L.stato = "Intervento in corso";
      intervento2L.data_chiusura = null;
    }

    let param = {
      segnalazione: segnalazione,
      intervento2L: intervento2L,
    };
    updateRecord(param);
    setIsEditing2L(false);
  };

  const toggleDeleteIntervention = () => {
    if (deleteIntervention) {
      props.setIsCreation(false);
      props.setOpenRecord(null);
    }
    setDeleteIntervention(!deleteIntervention);
  };

  const toggleEndOperation = () => {
    if (endOperation) {
      props.setIsCreation(false);
      props.setOpenRecord(null);
    }
    setEndOperation(!endOperation);
  };

  const handleDelete = () => {
    interventionService
      .deleteRecord(props.openRecord.id)
      .then((res) => {
        if (res) {
          setOperationResult(true);
        } else {
          setOperationResult(false);
        }
        props.getInterventions();
        props.setIsCreation(false);
        props.setOpenRecord(null);
      })
      .catch(() => {
        setDeleteIntervention(false);
        toggleEndOperation();
      });
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      overflow: "scroll",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      overflowX: "scroll",
    },
    rowTop: {
      alignItems: "start",
      color: colors?.primary,
      width: "100%",
    },
    rowBottom: {
      display: "flex",
      justifyContent: "center",
      width: "90%",
    },
    subcontainer: {
      width: "100%",
      paddingBottom: 20,
      paddingTop: 20,
    },
    goBackButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    arrow: {
      height: 13,
      objectFit: "contain",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      paddingTop: "10px",
      paddingBottom: "20px",
    },
    column: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: 10,
    },
    avatar: {
      backgroundColor: colors.gray,
      width: "250px",
      height: "250px",
    },
    label: {
      color: colors?.primary,
      fontSize: 15,
      textAlign: "left",
    },
    textInput: {
      outline: "none",
      padding: 10,
      textAlign: "left",
      width: "93%",
      border: "1px solid lightgray",
      borderRadius: 5,
    },
    saveButton: {
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: colors.darkgray,
      margin: 30,
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 15,
    },
    value: {
      textAlign: "left",
      margin: 2,
      marginBottom: 10,
      fontSize: 15,
    },
    title: {
      transform: "rotate(270deg)",
      fontWeight: "600",
      width: 150,
    },
  };

  return (
    <div style={styles.container} id="scroll">
      {deleteIntervention && (
        <DeleteIntervention
          name={props.openRecord?.id}
          closeModal={toggleDeleteIntervention}
          saveData={() => handleDelete()}
          colors={colors}
        />
      )}
      {endOperation && (
        <EndOperation
          flag={operationResult}
          operation={2}
          closeModal={toggleEndOperation}
          colors={colors}
        />
      )}
      <div style={styles.body}>
        <div style={styles.rowBottom}>
          <div style={styles.subcontainer}>
            <div
              style={{
                ...styles.row,
                justifyContent: "space-between",
              }}
            >
              <button
                style={styles.goBackButton}
                onClick={() => {
                  props.setIsCreation(false);
                  props.setOpenRecord(null);
                }}
                disabled={loader}
              >
                <img src={arrow} style={styles.arrow} alt={"Go back"} />
                <p
                  style={{
                    fontSize: 12,
                    color: colors.darkgray,
                    margin: 0,
                    marginLeft: 5,
                    fontWeight: "600",
                  }}
                >
                  Indietro
                </p>
              </button>

              {!isEditing && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <button
                    style={styles.goBackButton}
                    onClick={() => setDeleteIntervention(!deleteIntervention)}
                  >
                    <img src={deleteIcon} style={styles.arrow} alt={"Delete"} />
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.darkgray,
                        margin: 0,
                        marginLeft: 5,
                        fontWeight: "600",
                      }}
                    >
                      Elimina
                    </p>
                  </button>
                </div>
              )}
            </div>

            <div
              style={{
                borderBottom: "1px solid darkgray",
                marginTop: 10,
                marginBottom: 20,
              }}
            ></div>

            {/* "Modifica segnalazione" */}
            {!isEditing && !isEditing1L && !isEditing2L && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <div>
                  <button
                    style={styles.goBackButton}
                    onClick={() => {
                      setIsEditing(true);
                      setIsEditing1L(false);
                      setIsEditing2L(false);
                    }}
                  >
                    <img src={pencil} style={styles.arrow} alt={"Modify"} />
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.darkgray,
                        margin: 0,
                        marginLeft: 5,
                        fontWeight: "600",
                      }}
                    >
                      Modifica
                    </p>
                  </button>
                </div>
              </div>
            )}

            {/* with both isEditing === true and false */}
            <NoticeBox
              isEditing={isEditing}
              field={title}
              setField={setTitle}
              openRecord={props.openRecord}
              update={props.setOpenRecord}
            />

            {/* "Salva" */}
            {isEditing && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {!loader ? (
                  <button
                    style={{
                      ...styles.saveButton,
                      backgroundColor: colors.darkgray,
                    }}
                    onClick={() => {
                      if (!isEmpty(props.openRecord.id)) {
                        updateRecord(props.openRecord);
                      } else {
                        createRecord();
                      }
                      setIsEditing(false);
                    }}
                  >
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.white,
                        margin: 0,
                        fontWeight: "600",
                      }}
                    >
                      Salva
                    </p>
                  </button>
                ) : (
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={colors?.primary}
                    height={50}
                    width={50}
                  />
                )}
              </div>
            )}

            <div
              style={{
                borderBottom: "1px solid darkgray",
                marginTop: 10,
                marginBottom: 20,
              }}
            ></div>

            {/* "Apri intervento 1L" */}
            {isEmpty(props.openRecord.intervento1L) && !isEditing && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {!loader ? (
                  <button
                    style={{
                      ...styles.saveButton,
                      backgroundColor: colors.darkgray,
                    }}
                    onClick={() => {
                      setIsEditing(false);
                      setIsEditing2L(false);
                      openIntervention1L();
                    }}
                  >
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.white,
                        margin: 0,
                        fontWeight: "600",
                      }}
                    >
                      Apri intervento 1L
                    </p>
                  </button>
                ) : (
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={colors?.primary}
                    height={50}
                    width={50}
                  />
                )}
              </div>
            )}

            {/* "Modifica intervento 1L" */}
            {!isEditing &&
              !isEditing1L &&
              !isEditing2L &&
              !isEmpty(props.openRecord.intervento1L) && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <div>
                    <button
                      style={styles.goBackButton}
                      onClick={() => {
                        setIsEditing(false);
                        setIsEditing1L(true);
                        setIsEditing2L(false);
                      }}
                    >
                      <img src={pencil} style={styles.arrow} alt={"Modify"} />
                      <p
                        style={{
                          fontSize: 12,
                          color: colors.darkgray,
                          margin: 0,
                          marginLeft: 5,
                          fontWeight: "600",
                        }}
                      >
                        Modifica
                      </p>
                    </button>
                  </div>
                </div>
              )}

            {!isEmpty(props.openRecord.intervento1L) && (
              <InterventionBox
                isEditing={isEditing1L}
                title={"Intervento 1L"}
                intervention={props.openRecord.intervento1L}
                field={report1L}
                setField={setReport1L}
                status={status1L}
                setStatus={setStatus1L}
                closing={closing1L}
                setClosing={setClosing1L}
                openRecord={props.openRecord}
                onClick={closeIntervention1L}
              />
            )}

            {/* "Salva intervento 1L" */}
            {isEditing1L && !isEmpty(props.openRecord.intervento1L) && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {!loader ? (
                  <button
                    style={{
                      ...styles.saveButton,
                      backgroundColor: colors.darkgray,
                    }}
                    onClick={() => closeIntervention1L()}
                  >
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.white,
                        margin: 0,
                        fontWeight: "600",
                      }}
                    >
                      Salva
                    </p>
                  </button>
                ) : (
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={colors?.primary}
                    height={50}
                    width={50}
                  />
                )}
              </div>
            )}

            {!isEmpty(props.openRecord.intervento1L) && (
              <div
                style={{
                  borderBottom: "1px solid darkgray",
                  marginTop: 10,
                  marginBottom: 20,
                }}
              ></div>
            )}

            {/* "Apri intervento 2L" */}
            {!isEmpty(props.openRecord.intervento1L) &&
              !isEmpty(props.openRecord.intervento1L.data_chiusura) &&
              props.openRecord.intervento1L.stato === "Non risolto" &&
              isEmpty(props.openRecord.intervento2L) && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {!loader ? (
                    <div>
                      <button
                        style={{
                          ...styles.saveButton,
                          backgroundColor: colors.darkgray,
                        }}
                        onClick={() => {
                          setIsEditing(false);
                          setIsEditing1L(false);
                          openIntervention2L();
                        }}
                      >
                        <p
                          style={{
                            fontSize: 12,
                            color: colors.white,
                            margin: 0,
                            fontWeight: "600",
                          }}
                        >
                          Apri intervento 2L
                        </p>
                      </button>
                    </div>
                  ) : (
                    <ReactLoading
                      type={"spinningBubbles"}
                      color={colors?.primary}
                      height={50}
                      width={50}
                    />
                  )}
                </div>
              )}

            {/* "Modifica intervento 2L" */}
            {!isEditing &&
              !isEditing1L &&
              !isEditing2L &&
              !isEmpty(props.openRecord.intervento2L) && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <button
                    style={styles.goBackButton}
                    onClick={() => {
                      setIsEditing(false);
                      setIsEditing1L(false);
                      setIsEditing2L(true);
                    }}
                  >
                    <img src={pencil} style={styles.arrow} alt={"Modify"} />
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.darkgray,
                        margin: 0,
                        marginLeft: 5,
                        fontWeight: "600",
                      }}
                    >
                      Modifica
                    </p>
                  </button>
                </div>
              )}

            {!isEmpty(props.openRecord.intervento2L) && (
              <InterventionBox
                isEditing={isEditing2L}
                title={"Intervento 2L"}
                intervention={props.openRecord.intervento2L}
                field={report2L}
                setField={setReport2L}
                status={status2L}
                setStatus={setStatus2L}
                closing={closing2L}
                setClosing={setClosing2L}
                openRecord={props.openRecord}
                onClick={closeIntervention2L}
              />
            )}

            {/* "Salva intervento 2L" */}
            {isEditing2L && !isEmpty(props.openRecord.intervento2L) && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {!loader ? (
                  <button
                    style={{
                      ...styles.saveButton,
                      backgroundColor: colors.darkgray,
                    }}
                    onClick={() => closeIntervention2L()}
                  >
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.white,
                        margin: 0,
                        fontWeight: "600",
                      }}
                    >
                      Salva
                    </p>
                  </button>
                ) : (
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={colors?.primary}
                    height={50}
                    width={50}
                  />
                )}
              </div>
            )}

            {!isEmpty(props.openRecord.intervento2L) && (
              <div
                style={{
                  borderBottom: "1px solid darkgray",
                  marginTop: 10,
                  marginBottom: 20,
                }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterventionPage;
