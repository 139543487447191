import React from 'react';
import moment from 'moment';
import location from './../img/location_secondary.png'
import 'moment/locale/it'
moment.locale('it')


const EventBoxOnline = ({ event, selectEvent, width, colors }) => {
    const styles = {
        container: {
            display: 'flex',
            flexDirection: width < 700 ? 'column' :'row',
            width: '100%',
            borderRadius: 10,
            alignItems: 'center',
            height: width > 700 && 380,
            backgroundColor:'transparent',
            justifyContent: 'center',
            marginTop: 20,
            marginBottom: 20,
            cursor: event.posti_disponibili > 0 && 'pointer' ,
        },
        addContainer: {
            display: 'flex',
            flexDirection: 'row',
            width: 220,
            height: 280,
            backgroundColor: colors.secondary,
            borderRadius: 10,
            justifyContent: 'center',
            alignItems: 'center',
            opacity: 0.4
        },
        button: {
            outline: 'none',
            border: 0,
            backgroundColor:'transparent',
            width:'100%'
            
        },
        header: {
            height: 70,
            width: width < 700 ? '100%' : '40%',
            borderRadius:10
        },
        body: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderTopRightRadius: width < 700 ? 0 : 10,
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 10,
            borderBottomLeftRadius: width < 700 ? 10 : 0,
            backgroundColor:'white'
        },
        title: {
            color: colors.gray,
            maxWidth: 180,
            fontSize: 18,
            overflow:'hidden',
            maxHeight:40,
            margin:10
        },
        data: {
            color: colors.darkgray,
            width: '60%',
            fontSize: 25,
            textAlign:'center',
            fontWeight:'600',
            marginBottom:0,
            marginTop:0
        },
        location: {
            color: colors.darkgray,
            maxWidth: 200,
        },
        info: {
            color: colors.darkgray,
            width: '80%',
            fontSize: 15,
            overflow:'scroll',
            maxHeight:120,
            marginBottom:20,
            textAlign:'left'
        },
        warning:{
            width:'100%',
            textAlign:'center',
            color: colors.highlight,
            marginBottom:5,
            fontSize:10
        }
    }
    return (
        <button style={styles.button} onClick={() =>  event.posti_disponibili > 0 ? selectEvent(event) : null}>
                <div style={styles.container}>
                        <img alt="Header" src={event.immagine_evento.url} style={{ height: width < 700 ? 150 : 380, width: width < 700 ? '100%' : '50%', objectFit: 'cover', borderTopLeftRadius: 10,  borderTopRightRadius: width < 700 ? 10 : 0,   borderBottomLeftRadius: width < 700 ? 0 : 10 }} />
                    <div style={styles.body}>
                    <p style={styles.warning}><b style={{fontSize:15}}>{event.posti_disponibili}</b> posti disponibili</p>
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '80%' }}>
                        <p style={{...styles.data, marginBottom:0}}>{moment(event.ora_inizio).format("DD/MM/yyyy")}</p>
                        <p style={{...styles.data, marginTop:5, fontSize:18}}>{moment(event.ora_inizio).format("HH:mm")}</p>
                    </div> 
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <img alt="Location icon" src={location} style={{ width: 10, objectFit: 'contain', marginRight:10 }} />
                            <p style={styles.location}>{event.luogo_evento}</p>
                        </div>
                        <p style={styles.title}>{event.nome_evento}</p>
                        <p style={styles.info}>{event.info_evento}</p>
                    </div>
                </div>
        </button>

    )
}



export default EventBoxOnline;