import React from "react";
import moment from "moment";
import "moment/locale/it";
moment.locale("it");

const titles = [
  "Identificativo",
  "Data creazione",
  "Titolo",
  "Intervento 1° Livello",
  "Intervento 2° Livello",
  "Stato segnalazione",
];

const Table = (props) => {
  const styles = {
    table: {
      textAlign: "center",
      borderCollapse: "collapse",
      border: "1px solid #ddd",
      width: "100%",
      overflow: "scroll",
    },
    headerCell: {
      border: "1px solid #ddd",
      padding: "8px",
      paddingTop: "12px",
      paddingBottom: "12px",
      textAlign: "center",
      backgroundColor: props.colors.verylightgray,
      fontSize: 12,
      color: props.colors.gray,
    },
    field: {
      border: "1px solid #ddd",
      padding: "8px",
      fontSize: 12,
    },
    row: {
      cursor: "pointer",
    },
  };

  const renderTableHeader = (titles) => {
    return titles.map((key, index) => {
      return (
        <th style={styles.headerCell} key={index}>
          {key.toUpperCase()}
        </th>
      );
    });
  };

  const renderTableData = () => {
    return props.records.map((record, key) => {
      return (
        <tr
          key={key}
          style={styles.row}
          onClick={() => props.setOpenRecord(record)}
        >
          <td style={styles.field}>{record.id}</td>
          <td style={styles.field}>
            {moment(record.segnalazione?.data_creazione).format(
              "DD/MM/yyyy HH:mm"
            )}
          </td>
          <td style={styles.field}>{record.titolo}</td>
          <td style={styles.field}>
            {record.intervento1L
              ? record.intervento1L.data_chiusura
                ? "Chiusura: " +
                  moment(record.intervento1L?.data_chiusura).format(
                    "DD/MM/yyyy HH:mm"
                  )
                : "Apertura: " +
                  moment(record.intervento1L?.data_creazione).format(
                    "DD/MM/yyyy HH:mm"
                  )
              : "-"}
          </td>
          <td style={styles.field}>
            {record.intervento2L
              ? record.intervento2L.data_chiusura
                ? "Chiusura: " +
                  moment(record.intervento2L?.data_chiusura).format(
                    "DD/MM/yyyy HH:mm"
                  )
                : "Apertura: " +
                  moment(record.intervento2L?.data_creazione).format(
                    "DD/MM/yyyy HH:mm"
                  )
              : "-"}
          </td>
          <td style={styles.field}>{record.segnalazione?.stato}</td>
        </tr>
      );
    });
  };

  return (
    <table style={styles.table}>
      <tbody>
        <tr>{renderTableHeader(titles)}</tr>
        {renderTableData()}
      </tbody>
    </table>
  );
};

export default Table;
