import React, { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../../../contexts";
import { settingsService } from "../../../_services";
import ReactLoading from "react-loading";

function isEmpty(value) {
  return value === undefined || value === null || value === "";
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let correct = re.test(String(email).toLowerCase());

  return correct;
}

const timeSlots = [0, 30, 60, 90, 120, 150, 180];

const Settings = (props) => {
  const colors = useContext(UserContext)?.colors;
  const [prepTime, setPrepTime] = useState("");
  const [blockingRequest, setBlockingRequest] = useState(false);
  const [blockingSpace, setBlockingSpace] = useState(false);
  const [singleConfirmation, setSingleConfirmation] = useState(false);
  const [confirmedBy, setConfirmedBy] = useState("");
  const [paymentInfo, setPaymentInfo] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [paymentInfoLenght, setPaymentInfoLength] = useState(0);

  const prevPrepTime = usePrevious(prepTime);
  const prevBlockingRequest = usePrevious(blockingRequest);
  const prevBlockingSpace = usePrevious(blockingSpace);
  const prevSingleConfirmation = usePrevious(singleConfirmation);
  const prevConfirmedBy = usePrevious(confirmedBy);
  const prevPaymentInfo = usePrevious(paymentInfo);
  const prevAdminEmail = usePrevious(adminEmail);

  const [emailError, setEmailError] = useState(false);

  //bool used to disable the update button
  const [isDisabled, setIsDisabled] = useState(true);

  const [loader, setLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const getSettings = () => {
    setLoader(true);
    settingsService
      .getSettings()
      .then((settings) => {
        setLoader(false);
        setPrepTime(settings.impostazioni_attivita.tempo_preparazione);
        setBlockingRequest(settings.impostazioni_attivita.richiesta_bloccante);
        setBlockingSpace(settings.impostazioni_attivita.spazio_bloccante);
        setSingleConfirmation(settings.impostazioni_attivita.conferma_unica);
        setPaymentInfo(
          settings.impostazioni_attivita.info_pagamento
            ? settings.impostazioni_attivita.info_pagamento
            : ""
        );
        setPaymentInfoLength(
          settings.impostazioni_attivita.info_pagamento
            ? settings.impostazioni_attivita.info_pagamento.length
            : 0
        );
        setAdminEmail(settings.impostazioni_attivita.email_amministrazione);

        if (settings.impostazioni_attivita.conferma_al_feedback === null) {
          setConfirmedBy("museo");
        } else {
          setConfirmedBy(settings.impostazioni_attivita.conferma_al_feedback);
        }
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    if (
      !isEmpty(prevPrepTime) &&
      !isEmpty(prevBlockingRequest) &&
      !isEmpty(prevBlockingSpace) &&
      !isEmpty(prevSingleConfirmation) &&
      !isEmpty(prevConfirmedBy) &&
      !isEmpty(prevPaymentInfo) &&
      !isEmpty(prevAdminEmail) &&
      (prepTime !== prevPrepTime ||
        blockingRequest !== prevBlockingRequest ||
        blockingSpace !== prevBlockingSpace ||
        singleConfirmation !== prevSingleConfirmation ||
        confirmedBy !== prevConfirmedBy ||
        paymentInfo !== prevPaymentInfo ||
        adminEmail !== prevAdminEmail)
    ) {
      setIsDisabled(false);
    }
  }, [
    prepTime,
    blockingRequest,
    blockingSpace,
    singleConfirmation,
    confirmedBy,
    paymentInfo,
    adminEmail,
    prevBlockingRequest,
    prevBlockingSpace,
    prevPrepTime,
    prevSingleConfirmation,
    prevConfirmedBy,
    prevPaymentInfo,
    prevAdminEmail,
    props.history,
  ]);

  const updateSettings = () => {
    if (validateEmail(adminEmail)) {
      setEmailError(false);
      setButtonLoader(true);
      settingsService
        .updateActivitiesSetting(
          prepTime,
          blockingRequest,
          blockingSpace,
          singleConfirmation,
          confirmedBy,
          paymentInfo,
          adminEmail
        )
        .then((response) => {
          setButtonLoader(false);
          setIsDisabled(true);
        })
        .catch((error) => {
          if (error.statusCode === 401 || error.statusCode === 403) {
            props.history.push("/");
          }
        });
    } else {
      setEmailError(true);
    }
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    loaderContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },
    content: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginBottom: 20,
      backgroundColor: colors.lightgray,
      boxShadow: "2px 0 4px 0 rgb(0 0 0 / 20%)",
    },
    title: {
      fontSize: 20,
      marginBottom: 5,
      marginLeft: 30,
    },
    labelTable: {
      flex: 1,
      fontSize: 12,
      color: colors.lightgray,
      textAlign: "left",
    },
    settingsSection: {
      width: "90%",
    },
    button: {
      backgroundColor: colors.secondary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    textInput: {
      outline: "none",
      padding: 10,
      textAlign: "left",
      width: "100%",
      border: "1px solid lightgray",
      borderRadius: 5,
      fontFamily: "inherit",
      resize: "none",
    },
  };

  return loader ? (
    <div style={styles.loaderContainer}>
      <ReactLoading
        type={"spinningBubbles"}
        color={colors?.primary}
        height={50}
        width={50}
      />
    </div>
  ) : (
    <div style={styles.container}>
      <div style={styles.content}>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            width: "90%",
            height: "100%",
            marginTop: 30,
          }}
        >
          <div style={styles.settingsSection}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ flexDirection: "column" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      onChange={() => setBlockingRequest(!blockingRequest)}
                      checked={blockingRequest}
                    />
                    <p style={{ marginLeft: 20, fontSize: 15 }}>
                      Rendi le richieste di prenotazione bloccanti
                    </p>
                  </div>
                  <p
                    style={{
                      marginLeft: 20,
                      fontSize: 12,
                      color: "gray",
                      marginTop: 0,
                      textAlign: "left",
                    }}
                  >
                    Se selezionato, alla ricezione di una richiesta di
                    prenotazione il sistema blocca le date a calendario; se non
                    selezionato, il sistema blocca le date a calendario solo
                    dopo la conferma della prenotazione da parte di relatore e
                    museo
                  </p>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      onChange={() => setBlockingSpace(!blockingSpace)}
                      checked={blockingSpace}
                    />
                    <p style={{ marginLeft: 20, fontSize: 15 }}>
                      Rendi gli spazi bloccanti
                    </p>
                  </div>
                  <p
                    style={{
                      marginLeft: 20,
                      fontSize: 12,
                      color: "gray",
                      marginTop: 0,
                      textAlign: "left",
                    }}
                  >
                    Se selezionato, qualora le richieste siano bloccanti
                    impedisce la prenotazione di qualsiasi attività prevista in
                    uno spazio nella stessa fascia oraria in cui é presente una
                    richiesta di prenotazione; qualora le richieste non siano
                    bloccanti, impedisce la prenotazione di qualsiasi attività
                    prevista in uno spazio nella stessa fascia oraria in cui é
                    stata confermata un'altra prenotazione
                  </p>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="checkbox"
                      onChange={() =>
                        setSingleConfirmation(!singleConfirmation)
                      }
                      checked={singleConfirmation}
                    />
                    <p style={{ marginLeft: 20, fontSize: 15 }}>
                      Conferma prenotazione con un solo feedback
                    </p>
                  </div>
                  {singleConfirmation && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginLeft: 22,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 12,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Alla conferma di{" "}
                      </p>
                      <select
                        name="preparation"
                        style={{
                          outline: "none",
                          border: "1px solid lightgray",
                          borderRadius: 40,
                          marginRight: 10,
                          marginLeft: 10,
                          textAlign: "center",
                        }}
                        value={confirmedBy}
                        onChange={(event) => {
                          setConfirmedBy(event.target.value);
                        }}
                      >
                        <option key={0} value={"museo"}>
                          {"Museo"}
                        </option>
                        <option key={1} value={"relatore"}>
                          {"Relatore"}
                        </option>
                      </select>
                    </div>
                  )}

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginLeft: 6, fontSize: 15 }}>
                      Tempo di preparazione dello spazio
                    </p>
                  </div>
                  <div style={{ marginLeft: 22 }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <p
                        style={{
                          fontSize: 12,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Il sistema blocca automaticamente nel calendario il
                        tempo di preparazione indicato, prima e dopo ogni
                        prenotazione.
                      </p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <p
                        style={{
                          fontSize: 12,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Il tempo di preparazione necessario tra una prenotazione
                        e la successiva é di{" "}
                      </p>
                      <select
                        name="preparation"
                        style={{
                          outline: "none",
                          border: "1px solid lightgray",
                          // width: 30,
                          borderRadius: 40,
                          marginRight: 10,
                          marginLeft: 10,
                          textAlign: "center",
                        }}
                        value={prepTime}
                        onChange={(event) => {
                          setPrepTime(event.target.value);
                        }}
                      >
                        {timeSlots.map((slot, key) => {
                          return (
                            <option key={key} value={slot}>
                              {slot}
                            </option>
                          );
                        })}
                      </select>
                      <p
                        style={{
                          fontSize: 12,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        {" "}
                        minuti
                      </p>
                    </div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginLeft: 6, fontSize: 15 }}>
                      Istruzioni per il pagamento
                    </p>
                  </div>
                  <div style={{ marginLeft: 22 }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <p
                        style={{
                          fontSize: 12,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Le informazioni in merito alle modalità di pagamento
                        scritte nello spazio sottostante vengono specificate nel
                        modulo di prenotazione delle attività e allegate alla
                        e-mail inviata automaticamente ai referenti al momento
                        della conferma di una richiesta di prenotazione.
                      </p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <textarea
                        value={paymentInfo}
                        onChange={(event) => {
                          setPaymentInfo(event.target.value.replace("\n", ""));
                          setPaymentInfoLength(event.target.value.length);
                        }}
                        style={styles.textInput}
                        rows={Math.ceil(paymentInfoLenght / 100)}
                      />
                    </div>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ marginLeft: 6, fontSize: 15 }}>
                      Email amministrazione
                    </p>
                  </div>
                  <div style={{ marginLeft: 22 }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <p
                        style={{
                          fontSize: 12,
                          color: "gray",
                          textAlign: "left",
                        }}
                      >
                        Indirizzo email dell'amministrazione responsabile
                        dell'invio delle coordinate per il pagamento delle
                        attività
                      </p>
                    </div>
                    {emailError && (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <p
                          style={{
                            fontSize: 12,
                            color: colors.highlight,
                            textAlign: "left",
                          }}
                        >
                          Indirizzo email non valido
                        </p>
                      </div>
                    )}
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <input
                        value={adminEmail}
                        onChange={(event) => {
                          setAdminEmail(event.target.value);
                        }}
                        style={styles.textInput}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      marginTop: "30px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {buttonLoader ? (
                      <button
                        onClick={() => {}}
                        style={{ ...styles.button, backgroundColor: "white" }}
                      >
                        <ReactLoading
                          type={"spinningBubbles"}
                          color={colors?.darkgray}
                          height={50}
                          width={50}
                        />
                      </button>
                    ) : (
                      !isDisabled && (
                        <button
                          onClick={() => {
                            updateSettings();
                          }}
                          style={styles.button}
                        >
                          SALVA
                        </button>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
