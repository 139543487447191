import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import back from "./../../../img/closeButton.png";
import trash from "./../../../img/delete.png";
import trashsec from "./../../../img/delete_secondary.png";
import pen from "./../../../img/modify.png";
import upload from "./../../../img/uploadimg.png";
import pin from "./../../../img/location.png";
import children from "./../../../img/children.png";
import placeholder from "./../../../img/placeholder_img.png";
import people from "./../../../img/capacity.png";
import Week from "../../../components/week";
import pencil from "../../../img/modify_gray.png";
import reserved from "../../../img/reserved.png";
import add from "../../../img/add.png";
import Switch from "react-switch";
import DatePicker, { registerLocale } from "react-datepicker";
import { SketchPicker } from "react-color";
import ReactLoading from "react-loading";
import InfoButton from "../../../components/buttons/infoButton";
import it from "date-fns/locale/it";
import "react-datepicker/dist/react-datepicker.css";
import InvitationButton from "./invitationButton";
import DownloadResButton from "./downloadResButton";
import CopyLinkButton from "./copyLinkButton";
import ShareEventButton from "./shareEventButton";
import { eventsService, settingsService } from "../../../_services";

registerLocale("it", it);

function checkTimesValidity(startingTime, endingTime, isCopying) {
  return (
    startingTime !== "" &&
    endingTime !== "" &&
    (moment(startingTime).format("HH:mm") !== "00:00" ||
      moment(endingTime).format("HH:mm") !== "00:00") &&
    startingTime >= endingTime
  );
}

function withoutTime(date) {
  var d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return d;
}

function checkCapacityVariation(newCapacity, event) {
  if (event !== null && event !== undefined) {
    var eventsList = event.events;
    var newCapacityCheck = true;

    if (eventsList !== null && eventsList !== undefined) {
      eventsList.forEach((el) => {
        if (newCapacity < el.posti_prenotati) newCapacityCheck = false;
      });
    }

    return newCapacityCheck;
  } else {
    return true;
  }
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
const ShowEvent = ({
  event,
  categories,
  categorySelected,
  onDeleteEvent,
  closeInfo,
  onDeleteSchema,
  onModifySchema,
  onCreateSchema,
  onModifyTicketsType,
  onDeleteTicketsType,
  flagModify,
  flagAdd,
  uploadImg,
  closingDays,
  colors,
  onShowGenerate,
  onDownloadRes,
  addTickets,
  isCopying,
  barLoader,
  imageLoader,
}) => {
  const [onUpdate, setOnUpdate] = useState(flagModify);
  const [onAdd, setOnAdd] = useState(flagAdd);
  const [addOrCopy, setAddOrCopy] = useState(flagAdd || isCopying);
  const [name, setName] = useState(event ? event.nome_evento : "");
  const [ticketsType, setTicketsType] = useState([]);
  const [ticketsTypeName, setTicketsTypeName] = useState("");
  const [ticketsTypeAvailabilty /*, setTicketsTypeAvailability*/] =
    useState("1000");
  const [ticketsTypePrice, setTicketsTypePrice] = useState("");
  const [editingTicketsType, setEditingTicketsType] = useState("");
  // const [error, setError] = useState(false);
  const [emailTitle, setEmailTitle] = useState(event ? event.titolo_email : "");
  const [location, setLocation] = useState(event ? event.luogo_evento : "");
  const [colore, setColore] = useState(event ? event.colore : "");
  const [category, setCategory] = useState(
    event && event.categoria && event.categoria.id
      ? event.categoria.id
      : categorySelected.id
  );
  const [description, setDescription] = useState(
    event ? event.descrizione_evento : ""
  );
  const [capacity, setCapacity] = useState(event ? event.posti_totali : "");
  const [startingTime, setStartingTime] = useState(
    !isCopying && event ? new Date(event.ora_inizio) : ""
  );
  const prevStartingTime = usePrevious({ startingTime });

  const [endingTime, setEndingTime] = useState(
    !isCopying && event ? new Date(event.ora_fine) : ""
  );
  const [reservationEnabled, setReservationEnabled] = useState(
    event ? event.prenotabile : false
  );
  const [cardReservationEnabled, setCardReservationEnabled] = useState(
    event ? event.prenotabile_card : false
  );
  const [lunedi, setLunedi] = useState(
    !isCopying && event ? event.giorni_ripetizione.lunedi : false
  );
  const [martedi, setMartedi] = useState(
    !isCopying && event ? event.giorni_ripetizione.martedi : false
  );
  const [mercoledi, setMercoledi] = useState(
    !isCopying && event ? event.giorni_ripetizione.mercoledi : false
  );
  const [giovedi, setGiovedi] = useState(
    !isCopying && event ? event.giorni_ripetizione.giovedi : false
  );
  const [venerdi, setVenerdi] = useState(
    !isCopying && event ? event.giorni_ripetizione.venerdi : false
  );
  const [sabato, setSabato] = useState(
    !isCopying && event ? event.giorni_ripetizione.sabato : false
  );
  const [domenica, setDomenica] = useState(
    !isCopying && event ? event.giorni_ripetizione.domenica : false
  );
  const [multipleDays, setMultipleDays] = useState(
    withoutTime(startingTime) < withoutTime(endingTime)
  );
  const [isRepeated, setIsRepeated] = useState(
    event ? event.flag_ripete : false
  );
  const giorni_ripetizione = {
    lunedi: lunedi,
    martedi: martedi,
    mercoledi: mercoledi,
    giovedi: giovedi,
    venerdi: venerdi,
    sabato: sabato,
    domenica: domenica,
  };
  const [loader, setLoader] = useState(barLoader);
  const [eventForChildren, setEventForChildren] = useState(
    event ? event.flag_bambini : false
  );
  const [externalManagementFlag, setExternalManagementFlag] = useState(
    event ? event.gestione_esterna : false
  );
  const [externalURL, setExternalURL] = useState(
    event ? event.link_esterno : ""
  );
  const [imgLoader, setImgLoader] = useState(imageLoader);
  const [pathToUpload, setPathToUpload] = useState(null);
  const [tempImage, setTempImage] = useState(null);
  const [uniqueEvent, setUniqueEvent] = useState(false);

  //Variable used to show the tmpImage when copying an event
  const [tmpImgCopying, setTmpImgCopying] = useState(false);

  const [eventImage, setEventImage] = useState(
    event ? event.immagine_evento : null
  );

  const [ticketsList, setTicketsList] = useState([]);

  const [disabled, setDisabled] = useState(false);

  const [eventLink, setEventLink] = useState("");

  const [flagAttesa, setFlagAttesa] = useState(
    event ? event.flag_attesa : false
  );

  const [imageError, setImageError] = useState(true);

  const [parentDeletable, setParentDeletable] = useState(false);

  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          observer.disconnect();
          resolve(document.querySelector(selector));
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  const divRef = useRef();
  useEffect(() => {
    waitForElm(".thisRef").then(() => {
      if (
        !!description &&
        !onAdd &&
        !onUpdate &&
        divRef.current !== undefined &&
        divRef.current !== null
      ) {
        divRef.current.innerHTML = description;
      }
    });
  }, [description, onAdd, onUpdate]);

  useEffect(() => {
    if (event?.gestione_esterna) {
      setEventLink(event?.link_esterno);
    } else {
      //TODO: get museum.identificativo from the context (?)
      settingsService.getMuseumInfo().then((response) => {
        setEventLink(
          "emma4culture.com/" +
            response.identificativo +
            "/event/" +
            event?.id +
            "/events"
        );
      });
    }
  }, [setEventLink, event]);

  useEffect(() => {
    setOnUpdate(flagModify);
    setOnAdd(flagAdd);

    //The following boolean checks if the events have any reservation: if not, date and time can be modified
    let anyReservation =
      event?.eventi?.some((e) => e.posti_prenotati > 0) ?? false;

    setAddOrCopy(flagAdd || isCopying || !anyReservation);
  }, [flagModify, flagAdd, isCopying, event]);

  useEffect(() => {
    setLoader(barLoader);
    setImgLoader(imageLoader);
  }, [barLoader, imageLoader]);

  React.useEffect(() => {
    setMultipleDays(isRepeated ? false : multipleDays);

    setEventImage(event ? event.immagine_evento : null);
    setName(event ? event.nome_evento : "");
    setEmailTitle(event ? event.titolo_email : "");
    setColore(event ? event.colore : "");
    setLocation(event ? event.luogo_evento : "");
    setCategory(
      event && event.categoria && event.categoria.id
        ? event.categoria.id
        : categorySelected.id
    );
    setDescription(event ? event.descrizione_evento : "");
    setCapacity(event ? event.posti_totali : "");
    setStartingTime(!isCopying && event ? new Date(event.ora_inizio) : "");
    setEndingTime(!isCopying && event ? new Date(event.ora_fine) : "");
    setLunedi(!isCopying && event ? event.giorni_ripetizione.lunedi : false);
    setMartedi(!isCopying && event ? event.giorni_ripetizione.martedi : false);
    setMercoledi(
      !isCopying && event ? event.giorni_ripetizione.mercoledi : false
    );
    setGiovedi(!isCopying && event ? event.giorni_ripetizione.giovedi : false);
    setVenerdi(!isCopying && event ? event.giorni_ripetizione.venerdi : false);
    setSabato(!isCopying && event ? event.giorni_ripetizione.sabato : false);
    setDomenica(
      !isCopying && event ? event.giorni_ripetizione.domenica : false
    );
    setEventForChildren(event ? event.flag_bambini : false);
    setExternalManagementFlag(event ? event.gestione_esterna : false);
    setExternalURL(event ? event.link_esterno : "");
    setImgLoader(imageLoader);
    setPathToUpload(null);
    setTempImage(null);
    setReservationEnabled(event ? event.prenotabile : false);
    setCardReservationEnabled(event ? event.prenotabile_card : false);
    setImageError(false);
    setFlagAttesa(event ? event.flag_attesa : false);
    setParentDeletable(false);

    const tmpTicketsList = [];

    if (event && event.id) {
      eventsService
        .getTicketsType(event.id)
        .then((response) => {
          if (response && response.length > 0) {
            setTicketsType(response);
            if (isCopying) {
              response.forEach((ticket) => {
                var tmpTicket = {
                  nome: ticket.nome,
                  prezzo: ticket.prezzo,
                  disponibilita: ticketsTypeAvailabilty,
                };
                tmpTicketsList.push(tmpTicket);
              });
              setTicketsList(tmpTicketsList);
            }
          }
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            // this.props.history.push("/");
          }
        });

      var totBooked = 0;
      event.eventi.forEach((eventChild) => {
        totBooked += eventChild.prenotazioni_evento.length;
      });
      if (totBooked === 0 && !onUpdate) setParentDeletable(true);

      var checkReservations = false;
      event.eventi.forEach((eventChild) => {
        if (eventChild.prenotazioni_evento.length > 0) {
          checkReservations = true;
        }
      });

      setDisabled(!onAdd && !isCopying && checkReservations);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, categorySelected, addTickets, isCopying, ticketsTypeAvailabilty]);

  const hiddenFileInput = React.useRef(null);

  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded) {
      if (fileUploaded.size < 1000000) {
        if (event && !isCopying) {
          setImgLoader(true);
          var isImageCorrectlyUploaded = uploadImg(event.id, fileUploaded);
          setImageError(!isImageCorrectlyUploaded);
        } else {
          if (isCopying) {
            setTmpImgCopying(true);
          }
          setTempImage(URL.createObjectURL(fileUploaded));
          setPathToUpload(e.target.files[0]);
        }
        setEventImage(null);
        setImageError(false);
      } else {
        console.log(
          "Dimensione dell'immagine scelta: " +
            (fileUploaded.size / 1000000).toFixed(2) +
            "... MB (superiore a 1 MB)"
        );
        setImageError(true);
      }
    }
  };

  useEffect(() => {
    if (
      !moment(prevStartingTime?.startingTime).isSame(startingTime) &&
      startingTime !== ""
    ) {
      if (
        (!multipleDays && !isRepeated) ||
        new Date(startingTime).setHours(0, 0, 0, 0) >
          new Date(endingTime).setHours(0, 0, 0, 0)
      ) {
        let temp = new Date(
          startingTime.getFullYear(),
          startingTime.getMonth(),
          startingTime.getDate(),
          endingTime.getHours(),
          endingTime.getMinutes(),
          0,
          0
        );
        setEndingTime(temp);
      }
    }
  }, [prevStartingTime, startingTime, endingTime, multipleDays, isRepeated]);

  const styles = {
    container: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      width: 400,
      height: "100hv",
      backgroundColor: colors?.tertiary,
      alignItems: "center",
      zIndex: 200,
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      marginLeft: 10,
      marginRight: 2,
      cursor: "pointer",
    },
    upperBar: {
      width: "100%",
      backgroundColor: "rgba(0,0,0,0.3)",
      position: "absolute",
      top: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    lowerBar: {
      width: "100%",
      position: "absolute",
      top: 160,
      backgroundColor: "rgba(0,0,0,0.3)",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    header: {
      height: 200,
      width: "100%",
    },
    body: {
      width: "90%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflowY: "scroll",
    },
    titleBox: {
      height: onUpdate ? 100 : 50,
      width: "100%",
      backgroundColor: colors?.secondary,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      color: colors?.white,
      fontWeight: "700",
      fontSize: 20,
    },
    subtitle: {
      height: 40,
      width: "100%",
      backgroundColor: colors?.primary,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
    },
    fieldBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    description: {
      maxHeight: 80,
      textAlign: "left",
      fontSize: 14,
    },
    field: {
      fontSize: 15,
    },
    location: {
      color: colors?.white,
      fontSize: 11,
    },
    row: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
    },
    label: {
      color: colors?.secondary,
      width: 120,
      fontSize: 13,
      textAlign: "left",
      marginBottom: 5,
      marginTop: 10,
    },
    labelTicketsType: {
      color: colors.secondary,
      width: 40,
      fontSize: 13,
      textAlign: "left",
      marginBottom: 5,
      marginTop: 10,
    },
    list: {
      width: "100%",
      height: "calc(100vh - 650px)",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      overflowY: "scroll",
    },
    info: {
      flex: 1,
      flexDirection: "row",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    event: {
      flexDirection: "row",
      display: "flex",
      width: "100%",
      height: 40,
      alignItems: "center",
      justifyContent: "space-around",
      cursor: "pointer",
    },
    passedEvent: {
      flexDirection: "row",
      display: "flex",
      width: "100%",
      height: 40,
      alignItems: "center",
      justifyContent: "space-around",
      cursor: "pointer",
      opacity: 0.4,
    },
    textInput: {
      borderRadius: 5,
      border: 0,
      outline: "none",
      textAlign: "center",
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.upperBar}>
          <div>
            {!onUpdate && (
              <button
                style={{ ...styles.button, marginRight: 0 }}
                onClick={() => setOnUpdate(!onUpdate)}
              >
                <img
                  alt="Modify Event Schema Button"
                  src={pen}
                  style={{ height: 20 }}
                />
              </button>
            )}
            {parentDeletable && (
              <button
                style={styles.button}
                onClick={() => onDeleteSchema(event)}
              >
                <img
                  alt="Delete Event Schema Button"
                  src={trash}
                  style={{ height: 20 }}
                />
              </button>
            )}
            {onUpdate && (
              <>
                <button style={styles.button} onClick={handleClick}>
                  <img
                    alt="Upload Button"
                    src={upload}
                    style={{ height: 20 }}
                  />
                </button>
                <input
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
              </>
            )}
          </div>
          <button style={styles.button} onClick={closeInfo}>
            <img
              alt="Back Button"
              src={back}
              style={{ height: 20, objectFit: "cover" }}
            />
          </button>
        </div>
        {imgLoader ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={colors?.primary}
              height={50}
              width={50}
            />
          </div>
        ) : (
          <img
            alt="Header"
            src={
              event?.immagine_evento && !tmpImgCopying
                ? event?.immagine_evento.url
                : tempImage
                ? tempImage
                : placeholder
            }
            style={{ height: 200, width: "100%", objectFit: "cover" }}
          />
        )}
      </div>
      {imageError ? (
        <div
          style={{
            ...styles.subtitle,
            backgroundColor: colors?.highlight,
            color: "white",
            fontSize: 10,
            fontWeight: "700",
          }}
        >
          <p>{"L'immagine scelta non può eccedere la dimensione di 1MB"}</p>
        </div>
      ) : null}
      {addTickets ? null : (
        <>
          <div style={styles.titleBox}>
            <InfoButton
              width={200}
              backgroundColor={colors?.quaternary}
              text="Questo è il nome che verrà usato nel calendario Prenotazioni per identificare l'evento. Sarà visibile solo agli operatori e non verrà inserito nella mail di avvenuta prenotazione inviata automaticamente ai visitatori."
              colors={colors}
            />
            {onUpdate ? (
              <input
                type="text"
                name="name"
                autoComplete="on"
                value={name}
                onChange={(event) => setName(event.target.value)}
                placeholder="Identificativo evento"
                style={{
                  ...styles.textInput,
                  height: "60%",
                  width: "80%",
                }}
              />
            ) : (
              <p style={styles.title}>{name}</p>
            )}
          </div>
          <div style={styles.subtitle}>
            <div style={styles.fieldBox}>
              <img
                alt="Location"
                src={pin}
                style={{ height: 15, marginRight: 10 }}
              />
              {onUpdate ? (
                <input
                  type="text"
                  name="location"
                  autoComplete="on"
                  value={location}
                  maxLength="40"
                  onChange={(event) => setLocation(event.target.value)}
                  placeholder="Luogo evento"
                  style={{ ...styles.textInput, height: "90%", padding: 4 }}
                />
              ) : (
                <p style={styles.location}>{location}</p>
              )}
            </div>
            <div style={styles.fieldBox}>
              <img
                alt="Capacity"
                src={people}
                style={{ height: 12, marginRight: 10 }}
              />
              {onUpdate ? (
                <input
                  type="text"
                  name="number"
                  autoComplete="on"
                  value={capacity}
                  onChange={(event) =>
                    setCapacity(event.target.value.replace(/[^\d]/, ""))
                  }
                  placeholder="N°"
                  style={{
                    ...styles.textInput,
                    width: 40,
                    height: "100%",
                    marginRight: 10,
                    padding: 4,
                  }}
                />
              ) : (
                <p style={styles.location}>{capacity}</p>
              )}
              <p style={{ ...styles.location, marginLeft: 5 }}> posti</p>
            </div>
          </div>
          {onUpdate ? (
            <div
              style={{
                ...styles.subtitle,
                backgroundColor: colors?.quaternary,
                opacity: reservationEnabled ? 1 : 0.4,
                color: "white",
                fontSize: 12,
                fontWeight: "700",
              }}
            >
              <p
                style={{
                  color: colors?.primary,
                  fontWeight: "normal",
                  width: "60%",
                }}
              >
                {reservationEnabled
                  ? "Prenotazioni online aperte"
                  : "Prenotazioni online chiuse"}
              </p>
              <Switch
                onChange={() => {
                  setReservationEnabled(!reservationEnabled);
                  setCardReservationEnabled(false);
                }}
                checked={reservationEnabled}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor={colors?.primary}
                width={30}
                height={20}
              />
            </div>
          ) : (
            <div
              style={{
                ...styles.subtitle,
                backgroundColor: reservationEnabled
                  ? colors?.highlight
                  : colors?.gray,
                color: "white",
                fontSize: 12,
                fontWeight: "700",
              }}
            >
              <p>
                {reservationEnabled
                  ? "Prenotazioni online aperte"
                  : "Prenotazioni online chiuse"}
              </p>
            </div>
          )}
          {!reservationEnabled &&
            (onUpdate ? (
              <div
                style={{
                  ...styles.subtitle,
                  backgroundColor: colors?.quaternary,
                  opacity: cardReservationEnabled ? 1 : 0.4,
                  color: "white",
                  fontSize: 12,
                  fontWeight: "700",
                }}
              >
                <p
                  style={{
                    color: colors?.primary,
                    fontWeight: "normal",
                    width: "60%",
                  }}
                >
                  {cardReservationEnabled
                    ? "Prenotazioni online con card aperte"
                    : "Prenotazioni online con card chiuse"}
                </p>
                <Switch
                  onChange={() =>
                    setCardReservationEnabled(!cardReservationEnabled)
                  }
                  checked={cardReservationEnabled}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor={colors?.primary}
                  width={30}
                  height={20}
                />
              </div>
            ) : (
              <div
                style={{
                  ...styles.subtitle,
                  backgroundColor: cardReservationEnabled
                    ? colors?.highlight
                    : colors?.gray,
                  color: "white",
                  fontSize: 12,
                  fontWeight: "700",
                }}
              >
                <p>
                  {cardReservationEnabled
                    ? "Prenotazioni online con card aperte"
                    : "Prenotazioni online con card chiuse"}
                </p>
              </div>
            ))}
        </>
      )}

      {loader ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={colors?.primary}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <div style={styles.body}>
          <div style={{ width: "100%", flex: 2 }}>
            {addTickets ? null : (
              <>
                <div style={{ ...styles.row, justifyContent: "center" }}>
                  {onUpdate ? (
                    <>
                      <input
                        type="checkbox"
                        onChange={() => setEventForChildren(!eventForChildren)}
                        checked={eventForChildren}
                      />
                      <p
                        style={{
                          ...styles.label,
                          width: "100%",
                          marginLeft: 10,
                          marginBottom: 10,
                          color: colors?.darkgray,
                        }}
                      >
                        Evento per bambini
                      </p>
                    </>
                  ) : (
                    eventForChildren && (
                      <>
                        <img
                          alt="Event for children"
                          src={children}
                          style={{ height: 16, marginRight: 10 }}
                        />
                        <p
                          style={{
                            textAlign: "left",
                            color: "black",
                            fontSize: 14,
                          }}
                        >
                          Evento per bambini
                        </p>
                      </>
                    )
                  )}
                </div>
                <div style={styles.field}>
                  <p style={styles.label}>Nome evento</p>
                  {onUpdate ? (
                    <input
                      type="text"
                      name="emailTitle"
                      autoComplete="on"
                      value={emailTitle}
                      onChange={(event) => setEmailTitle(event.target.value)}
                      placeholder="Nome evento"
                      style={{
                        ...styles.textInput,
                        width: "90%",
                        padding: 10,
                        textAlign: "left",
                      }}
                    />
                  ) : (
                    <p style={{ ...styles.description, marginTop: 5 }}>
                      {emailTitle}
                    </p>
                  )}
                </div>
                <div style={styles.field}>
                  {(description || onUpdate) && (
                    <p style={{ ...styles.label, marginTop: 5 }}>Info evento</p>
                  )}
                  {onUpdate ? (
                    <textarea
                      type="text"
                      name="location"
                      value={description.replace(/<br*\/?>/gi, "\n")}
                      onChange={(event) => setDescription(event.target.value)}
                      placeholder="Aggiungi qui informazioni utili per i visitatori"
                      style={{
                        ...styles.textInput,
                        width: "90%",
                        height: 80,
                        textAlign: "left",
                        padding: 10,
                        fontFamily: "Arial",
                        resize: "none",
                      }}
                    />
                  ) : (
                    <p
                      style={{
                        ...styles.description,
                        overflowY: "scroll",
                        whiteSpace: "pre-line",
                        marginTop: 5,
                      }}
                    >
                      <div className="thisRef" ref={divRef}>
                        {
                          //Custom content will be programmatically inserted in div below
                        }
                        <div></div>
                      </div>
                    </p>
                  )}
                </div>
                <div style={{ ...styles.field, textAlign: "left" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <InfoButton
                      width={200}
                      backgroundColor={colors?.quaternary}
                      text="Se selezionato, verrà richiesto di indicare il link della pagina a cui reindirizzare gli utenti"
                      colors={colors}
                    />
                    <p style={{ ...styles.label, width: "", margin: 0 }}>
                      {"Gestione esterna"}
                    </p>
                  </div>
                  <input
                    type="checkbox"
                    onChange={() =>
                      setExternalManagementFlag(!externalManagementFlag)
                    }
                    checked={externalManagementFlag}
                    disabled={!onUpdate}
                  />
                </div>
                {externalManagementFlag && (
                  <div style={styles.field}>
                    <p style={styles.label}>Link esterno</p>
                    {onUpdate ? (
                      <input
                        type="text"
                        name="externalURL"
                        autoComplete="on"
                        value={externalURL}
                        onChange={(event) => setExternalURL(event.target.value)}
                        placeholder="Link esterno"
                        style={{
                          ...styles.textInput,
                          width: "90%",
                          padding: 10,
                          textAlign: "left",
                        }}
                      />
                    ) : (
                      <p style={styles.description}>{externalURL}</p>
                    )}
                  </div>
                )}
                {onUpdate && (
                  <div style={styles.field}>
                    <p style={styles.label}>Categoria</p>
                    <select
                      name="category"
                      style={{
                        ...styles.textInput,
                        width: "95%",
                        padding: 10,
                        textAlign: "left",
                      }}
                      value={category}
                      onChange={(event) => setCategory(event.target.value)}
                    >
                      {categories.map((cat, key) => {
                        return (
                          <option key={key} value={cat.id}>
                            {cat.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
              </>
            )}
            {addTickets ? null : (
              <>
                {onUpdate ? (
                  <>
                    <div style={styles.row}>
                      <div style={styles.field}>
                        <p style={styles.label}>
                          Data {multipleDays && "inizio"}
                        </p>
                        <DatePicker
                          name="startingDate"
                          dateFormat="dd/MM/yyyy"
                          locale="it"
                          className={
                            multipleDays
                              ? "event-custom-input-multiple"
                              : "event-custom-input"
                          }
                          minDate={new Date()}
                          selected={startingTime}
                          onChange={(date) => {
                            if (date !== null) {
                              date.setHours(10);
                              setStartingTime(date);
                              if (endingTime === "") {
                                var endDate = new Date(date);
                                endDate.setHours(12);
                                setEndingTime(endDate);
                              }
                            } else {
                              setStartingTime("");
                            }
                          }}
                          placeholderText="Data inizio"
                          disabled={!addOrCopy}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                      </div>
                      {multipleDays && (
                        <div style={styles.field}>
                          <p style={styles.label}>Data fine</p>
                          <DatePicker
                            name="endingTime"
                            dateFormat="dd/MM/yyyy"
                            className={"event-custom-input-multiple"}
                            locale="it"
                            minDate={
                              startingTime !== "" ? startingTime : new Date()
                            }
                            selected={endingTime}
                            onChange={(date) => {
                              if (date !== null) {
                                if (endingTime === "") {
                                  if (startingTime !== "") {
                                    date.setHours(
                                      moment(startingTime.getHours()).add(
                                        2,
                                        "hours"
                                      )
                                    );
                                  } else {
                                    date.setHours(12);
                                  }
                                }
                                setEndingTime(date);
                              } else {
                                setEndingTime("");
                              }
                            }}
                            placeholderText="Data fine"
                            popperPlacement="bottom-end"
                            disabled={!addOrCopy}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                        </div>
                      )}
                    </div>
                    {multipleDays && !event && (
                      <div
                        style={{
                          ...styles.row,
                          justifyContent: "row",
                          marginTop: 10,
                        }}
                      >
                        <input
                          type="checkbox"
                          onChange={() => setUniqueEvent(!uniqueEvent)}
                          checked={uniqueEvent}
                        />
                        <p
                          style={{
                            ...styles.label,
                            width: "100%",
                            marginLeft: 10,
                            color: colors?.darkgray,
                          }}
                        >
                          Evento unico
                        </p>
                      </div>
                    )}
                    {addOrCopy && (
                      <div style={{ ...styles.row, marginTop: 10 }}>
                        <p
                          style={{
                            ...styles.label,
                            width: 250,
                            marginTop: 10,
                            textAlign: "left",
                          }}
                        >
                          L'evento dura più giorni consecutivi
                        </p>
                        <Switch
                          onChange={() => {
                            if (!multipleDays) {
                              setLunedi(false);
                              setMartedi(false);
                              setMercoledi(false);
                              setGiovedi(false);
                              setVenerdi(false);
                              setSabato(false);
                              setDomenica(false);
                              setIsRepeated(false);
                            }
                            if (multipleDays) {
                              let hour = 0;
                              let minutes = 0;
                              let temp = "";
                              if (endingTime !== "") {
                                hour = endingTime.getHours();
                                minutes = endingTime.getMinutes();
                                if (startingTime !== "") {
                                  temp = new Date(
                                    startingTime.getFullYear(),
                                    startingTime.getMonth(),
                                    startingTime.getDate(),
                                    hour,
                                    minutes,
                                    0,
                                    0
                                  );
                                } else {
                                  let today = new Date();
                                  temp = new Date(
                                    today.getFullYear(),
                                    today.getMonth(),
                                    today.getDate(),
                                    hour,
                                    minutes,
                                    0,
                                    0
                                  );
                                }
                              }
                              setEndingTime(temp);
                            }
                            setMultipleDays(!multipleDays);
                          }}
                          checked={multipleDays}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onColor={colors?.primary}
                          width={30}
                          height={20}
                          disabled={disabled}
                        />
                      </div>
                    )}
                    {addOrCopy && (
                      <div style={{ ...styles.row }}>
                        <p
                          style={{
                            ...styles.label,
                            width: 250,
                            marginTop: 20,
                            textAlign: "left",
                          }}
                        >
                          L'evento si ripete settimanalmente{" "}
                          {(isRepeated || event?.flag_ripete) && " ogni"}
                        </p>
                        <Switch
                          onChange={() => {
                            setEndingTime(
                              !isCopying && event
                                ? new Date(event.ora_fine)
                                : new Date(moment().add(7, "days"))
                            );
                            setMultipleDays(false);
                            setIsRepeated(!isRepeated);
                          }}
                          checked={isRepeated}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onColor={colors?.primary}
                          width={30}
                          height={20}
                          disabled={disabled}
                        />
                      </div>
                    )}
                    {addOrCopy && event?.flag_ripete && (
                      <p
                        style={{
                          ...styles.label,
                          width: 250,
                          marginTop: 20,
                          textAlign: "left",
                        }}
                      >
                        L'evento si ripete settimanalmente ogni
                      </p>
                    )}
                    {((onUpdate && isRepeated) ||
                      (!onUpdate && event?.flag_ripete)) && (
                      <>
                        <div style={styles.field}>
                          <Week
                            lunedi={lunedi}
                            martedi={martedi}
                            mercoledi={mercoledi}
                            giovedi={giovedi}
                            venerdi={venerdi}
                            sabato={sabato}
                            domenica={domenica}
                            setLunedi={setLunedi}
                            setMartedi={setMartedi}
                            setMercoledi={setMercoledi}
                            setGiovedi={setGiovedi}
                            setVenerdi={setVenerdi}
                            setSabato={setSabato}
                            setDomenica={setDomenica}
                            colors={colors}
                            allowEdit={addOrCopy}
                          />
                        </div>
                        <div style={styles.field}>
                          <div
                            style={{
                              ...styles.row,
                              justifyContent: "center",
                              marginTop: 10,
                            }}
                          >
                            <p style={{ ...styles.label, width: "50px" }}>
                              Fino al
                            </p>
                            {onUpdate ? (
                              <DatePicker
                                name="endingTime"
                                dateFormat="dd/MM/yyyy"
                                locale="it"
                                className={"event-custom-input-multiple"}
                                minDate={
                                  startingTime !== ""
                                    ? startingTime
                                    : new Date()
                                }
                                selected={endingTime}
                                onChange={(date) => {
                                  if (date !== null) {
                                    date.setHours(12);
                                    setEndingTime(date);
                                  } else {
                                    setEndingTime("");
                                  }
                                }}
                                placeholderText="Data fine"
                                popperPlacement="bottom-start"
                                disabled={!addOrCopy}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                              />
                            ) : (
                              <p style={styles.description}>
                                {moment(endingTime).format("DD/MM/yyy")}
                              </p>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {startingTime !== "" &&
                      (!multipleDays || endingTime !== "") && (
                        <div style={styles.row}>
                          <div style={styles.field}>
                            <p style={{ ...styles.label, width: 80 }}>
                              Orario inizio
                            </p>
                            <DatePicker
                              selected={startingTime}
                              onChange={(time) => {
                                if (time !== null) {
                                  var tmpDate = new Date(
                                    startingTime.getTime()
                                  );
                                  tmpDate.setHours(time.getHours());
                                  tmpDate.setMinutes(time.getMinutes());
                                  setStartingTime(tmpDate);
                                } else {
                                  setStartingTime("");
                                }
                              }}
                              className={"event-custom-input-multiple"}
                              showTimeSelect
                              showTimeSelectOnly
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              dateFormat="HH:mm"
                              disabled={disabled}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />
                          </div>
                          <div style={styles.field}>
                            <p style={{ ...styles.label, width: 80 }}>
                              Orario fine
                            </p>
                            <DatePicker
                              selected={endingTime}
                              onChange={(time) => {
                                if (time !== null) {
                                  var tmpDate = new Date(endingTime.getTime());
                                  tmpDate.setHours(time.getHours());
                                  tmpDate.setMinutes(time.getMinutes());
                                  setEndingTime(tmpDate);
                                } else {
                                  setEndingTime("");
                                }
                              }}
                              className={"event-custom-input-multiple"}
                              showTimeSelect
                              showTimeSelectOnly
                              timeFormat="HH:mm"
                              timeIntervals={15}
                              dateFormat="HH:mm"
                              disabled={disabled}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                            />
                          </div>
                        </div>
                      )}
                    {checkTimesValidity(
                      startingTime,
                      endingTime,
                      isCopying
                    ) && (
                      <p
                        style={{
                          color: colors?.highlight,
                          fontSize: 11,
                          marginLeft: 25,
                          marginRight: 25,
                          textAlign: "left",
                        }}
                      >
                        L'orario di fine deve essere successivo all'orario di
                        inizio.
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <div
                      style={{ ...styles.row, justifyContent: "space-between" }}
                    >
                      <div style={styles.field}>
                        <p style={{ ...styles.label, textAlign: "center" }}>
                          Data {multipleDays && " inizio"}
                        </p>
                        <p>{moment(startingTime).format("D/MM/yyy")}</p>
                      </div>
                      {multipleDays && (
                        <div style={styles.field}>
                          <p style={{ ...styles.label, textAlign: "center" }}>
                            Data fine
                          </p>
                          <p>{moment(endingTime).format("D/MM/yyy")}</p>
                        </div>
                      )}
                    </div>
                    <div
                      style={{ ...styles.row, justifyContent: "space-between" }}
                    >
                      <div style={styles.field}>
                        <p style={{ ...styles.label, textAlign: "center" }}>
                          Orario inizio
                        </p>
                        <p>{moment(startingTime).format("HH:mm")}</p>
                      </div>
                      <div style={styles.field}>
                        <p style={{ ...styles.label, textAlign: "center" }}>
                          Orario fine
                        </p>
                        <p>{moment(endingTime).format("HH:mm")}</p>
                      </div>
                    </div>
                  </>
                )}
                <div style={{ ...styles.field, marginTop: 30 }}>
                  <p style={styles.label}>Colore evento</p>
                  {onUpdate ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 10,
                      }}
                    >
                      <SketchPicker
                        color={colore || "#fff"}
                        onChangeComplete={(e) => setColore(e.hex)}
                      />
                    </div>
                  ) : (
                    <div
                      style={{ backgroundColor: colore, height: 20, width: 20 }}
                    ></div>
                  )}
                </div>
                <div
                  style={{
                    ...styles.field,
                    textAlign: "left",
                    width: "",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: "10px",
                      marginBottom: "5px",
                    }}
                  >
                    <InfoButton
                      width={200}
                      backgroundColor={colors?.quaternary}
                      text="Se selezionato, attiva l'iscrizione alla lista d'attesa nel momento in cui i posti disponibili risultano esauriti"
                      colors={colors}
                    />
                    <p style={{ ...styles.label, width: "", margin: 0 }}>
                      {"Lista d'attesa"}
                    </p>
                  </div>
                  <input
                    type="checkbox"
                    onChange={() => setFlagAttesa(!flagAttesa)}
                    checked={flagAttesa}
                    disabled={!onUpdate}
                  />
                </div>
              </>
            )}
            {event && event.id && (
              <div>
                <p
                  style={{
                    marginTop: 30,
                    marginBottom: 0,
                    color: colors.secondary,
                    fontSize: 13,
                    textAlign: "left",
                  }}
                >
                  Biglietti disponibili
                </p>
                {ticketsType && ticketsType.length > 0 ? (
                  ticketsType.map((el, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flex: 1,
                        }}
                        key={`tickets-type-${index.toString()}`}
                      >
                        {onUpdate && editingTicketsType !== el.id ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                            }}
                          >
                            <button
                              style={{
                                padding: "0 10px",
                                border: "none",
                                borderRadius: 5,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setEditingTicketsType(el.id);
                              }}
                            >
                              <img
                                alt="Modify icon"
                                src={pencil}
                                style={{
                                  width: 12,
                                  objectFit: "contain",
                                  margin: "10px 0",
                                }}
                              />
                            </button>
                          </div>
                        ) : null}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 3,
                          }}
                        >
                          <p style={styles.label}>Tipo biglietto</p>
                          <input
                            type="text"
                            name="ticketsTypeName"
                            autoComplete="on"
                            value={el.nome}
                            disabled={editingTicketsType !== el.id}
                            onChange={(event) => {
                              const newTicketsType = JSON.parse(
                                JSON.stringify(ticketsType)
                              );
                              newTicketsType[index].nome = event.target.value;
                              setTicketsType(newTicketsType);
                              setTicketsList(newTicketsType);
                            }}
                            placeholder="Tipo biglietto"
                            style={{
                              ...styles.textInput,
                              padding: 10,
                              textAlign: "left",
                              width: "80%",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 2,
                          }}
                        >
                          <p style={styles.labelTicketsType}>Prezzo</p>
                          <input
                            type="text"
                            name="ticketsTypePrice"
                            autoComplete="on"
                            value={el.prezzo}
                            disabled={editingTicketsType !== el.id}
                            onChange={(event) => {
                              const newTicketsType = JSON.parse(
                                JSON.stringify(ticketsType)
                              );
                              newTicketsType[index].prezzo = event.target.value;
                              setTicketsType(newTicketsType);
                              setTicketsList(newTicketsType);
                            }}
                            placeholder="Prezzo"
                            style={{
                              ...styles.textInput,
                              padding: 10,
                              textAlign: "left",
                              width: "70%",
                            }}
                          />
                        </div>
                        {onUpdate && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              flex: 1,
                              justifyContent: "flex-end",
                            }}
                          >
                            <button
                              style={{
                                ...styles.button,
                                backgroundColor: colors.primary,
                                padding: 10,
                                borderRadius: 8,
                                color: colors.white,
                                marginBottom: 0,
                              }}
                              onClick={() => {
                                setLoader(true);
                                var tmpList = [];
                                if (!isCopying) {
                                  if (editingTicketsType === el.id) {
                                    if (
                                      el.nome !== null &&
                                      el.nome !== undefined &&
                                      el.prezzo !== null &&
                                      el.prezzo !== undefined
                                    ) {
                                      //onModifyTicketsType(el);
                                      eventsService
                                        .modifyTicketsType(el)
                                        .then(() => {
                                          eventsService
                                            .getTicketsType(event.id)
                                            .then((res) => {
                                              setTicketsType(res);
                                              setLoader(false);
                                            })
                                            .catch((errors) => {
                                              if (
                                                errors.statusCode === 401 ||
                                                errors.statusCode === 403
                                              ) {
                                                // props.history.push("/");
                                              }
                                            });
                                        })
                                        .catch((errors) => {
                                          if (
                                            errors.statusCode === 401 ||
                                            errors.statusCode === 403
                                          ) {
                                            //props.history.push("/");
                                          }
                                        });
                                      setEditingTicketsType("");
                                    }
                                  } else {
                                    onDeleteTicketsType(el.id);
                                    ticketsType.forEach((ticket) => {
                                      if (ticket.id !== el.id) {
                                        tmpList.push(ticket);
                                      }
                                    });
                                    setTicketsType(tmpList);
                                    setLoader(false);
                                  }
                                } else {
                                  if (editingTicketsType === el.id) {
                                    setEditingTicketsType("");
                                  } else {
                                    ticketsList.forEach((ticket) => {
                                      if (ticket.nome !== el.nome) {
                                        tmpList.push(ticket);
                                      }
                                    });

                                    setTicketsList(tmpList);
                                    setTicketsType(tmpList);
                                  }
                                  setLoader(false);
                                }
                              }}
                            >
                              {editingTicketsType === el.id ? (
                                <img
                                  alt="Edit icon"
                                  src={reserved}
                                  style={{
                                    width: 10,
                                    objectFit: "contain",
                                    margin: "0",
                                  }}
                                />
                              ) : (
                                <img
                                  alt="Delete icon"
                                  src={trash}
                                  style={{
                                    width: 10,
                                    objectFit: "contain",
                                    margin: "0",
                                  }}
                                />
                              )}
                            </button>
                          </div>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p
                    style={{
                      color: colors?.darkgray,
                      fontSize: 10,
                      textAlign: "left",
                    }}
                  >
                    Nessun biglietto disponibile per l'evento. <br />{" "}
                    <b>Attenzione.</b> Gli eventi senza biglietti non sono
                    acquistabili online.
                  </p>
                )}
                {/* TODO aggiungere dei tipi di biglietti */}
                {onUpdate && (
                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 3,
                      }}
                    >
                      <p style={styles.label}>Tipo biglietto</p>
                      <input
                        type="text"
                        name="ticketsTypeName"
                        autoComplete="on"
                        value={ticketsTypeName}
                        onChange={(event) =>
                          setTicketsTypeName(event.target.value)
                        }
                        placeholder="Tipo biglietto"
                        style={{
                          ...styles.textInput,
                          width: "80%",
                          padding: 10,
                          textAlign: "left",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 2,
                      }}
                    >
                      <p style={styles.labelTicketsType}>Prezzo</p>
                      <input
                        type="text"
                        name="ticketsTypePrice"
                        autoComplete="on"
                        value={ticketsTypePrice}
                        onChange={(event) =>
                          setTicketsTypePrice(
                            event.target.value.replace(/[^0-9.]/, "")
                          )
                        }
                        placeholder="Prezzo"
                        style={{
                          ...styles.textInput,
                          width: "70%",
                          padding: 10,
                          textAlign: "left",
                        }}
                      />
                    </div>
                    <div>
                      <button
                        style={{
                          ...styles.button,
                          backgroundColor: colors.primary,
                          padding: 10,
                          borderRadius: 8,
                          color: colors.white,
                          marginBottom: 0,
                        }}
                        onClick={() => {
                          setLoader(true);
                          const newTicketsType = [].concat(ticketsType);
                          newTicketsType.push({
                            nome: ticketsTypeName,
                            prezzo: ticketsTypePrice,
                            disponibilita: ticketsTypeAvailabilty,
                          });
                          if (!isCopying) {
                            var tempEv = event ? event.id : "";
                            eventsService
                              .createTicketsType(
                                ticketsTypeName,
                                ticketsTypePrice,
                                ticketsTypeAvailabilty,
                                tempEv
                              )
                              .then(() => {
                                eventsService
                                  .getTicketsType(tempEv)
                                  .then((res) => {
                                    setTicketsType(res);
                                    setLoader(false);
                                  })
                                  .catch((errors) => {
                                    if (
                                      errors.statusCode === 401 ||
                                      errors.statusCode === 403
                                    ) {
                                      // props.history.push("/");
                                    }
                                  });
                              })
                              .catch((errors) => {
                                if (
                                  errors.statusCode === 401 ||
                                  errors.statusCode === 403
                                ) {
                                }
                              });
                          } else {
                            setTicketsList(newTicketsType);
                            setTicketsType(newTicketsType);
                            setLoader(false);
                          }
                          setTicketsTypePrice("");
                          setTicketsTypeName("");
                        }}
                        disabled={
                          !ticketsTypeName ||
                          !ticketsTypePrice ||
                          !ticketsTypeAvailabilty
                        }
                      >
                        <img
                          alt="Add icon"
                          src={add}
                          style={{
                            width: 10,
                            objectFit: "contain",
                            margin: "0",
                          }}
                        />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {!onUpdate && (
            <div style={{ width: "100%" }}>
              {event?.eventi.length > 0 ? (
                <div style={styles.field}>
                  <p
                    style={{
                      color: colors?.secondary,
                      fontSize: 13,
                      textAlign: "left",
                      marginTop: 30,
                      marginBottom: 0,
                    }}
                  >
                    Date in programma
                  </p>
                  <div
                    style={
                      event.flag_ripete
                        ? styles.list
                        : { ...styles.list, height: "calc(100vh - 530px)" }
                    }
                  >
                    {event.eventi.map((e, key) => {
                      return (
                        <div
                          key={key}
                          style={
                            new Date(e.ora_inizio) >= new Date()
                              ? styles.event
                              : styles.passedEvent
                          }
                        >
                          <p style={styles.info}>
                            {moment(e.ora_inizio).format("D MMMM")}
                          </p>
                          <div style={styles.info}>
                            <p>{e.posti_prenotati}</p>
                            <p style={{ fontSize: 9, marginLeft: 8 }}>
                              {"prenotat" +
                                (e.posti_prenotati !== 1 ? "i" : "o") +
                                " su " +
                                e.posti_totali}
                            </p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {e.posti_prenotati === 0 ? (
                              <button
                                style={styles.button}
                                onClick={() => onDeleteEvent(e, event)}
                                disabled={new Date(e.ora_inizio) <= new Date()}
                              >
                                <img
                                  alt="Delete Event Button"
                                  src={trashsec}
                                  style={{ height: 15 }}
                                />
                              </button>
                            ) : (
                              <div style={{ marginLeft: 10 }}>
                                <DownloadResButton
                                  colors={colors}
                                  text={"Scarica le prenotazioni per l'evento"}
                                  width={200}
                                  backgroundColor={colors?.quaternary}
                                  onClick={() => onDownloadRes(e.id)}
                                />
                              </div>
                            )}
                            <InvitationButton
                              colors={colors}
                              text={
                                "Genera link personali per permettere agli invitati di registrarsi al tuo evento. Ogni indirizzo permetterà ad un solo visitatore ed, eventualmente, ad un suo ospite, di prenotare il proprio posto."
                              }
                              width={200}
                              backgroundColor={colors?.quaternary}
                              onClick={() => onShowGenerate(e)}
                              disabled={
                                !reservationEnabled && !cardReservationEnabled
                              }
                            />
                            <CopyLinkButton
                              colors={colors}
                              text={"Copia link dell'evento"}
                              width={200}
                              backgroundColor={colors?.quaternary}
                              link={eventLink}
                              disabled={
                                new Date(e.ora_inizio) <= new Date() ||
                                (!reservationEnabled &&
                                  !cardReservationEnabled) ||
                                eventLink === ""
                              }
                            />
                            <ShareEventButton
                              eventInfo={e}
                              eventLink={eventLink}
                              colors={colors}
                              text={"Condividi l'evento su WhatsApp"}
                              width={200}
                              backgroundColor={colors?.quaternary}
                              disabled={
                                new Date(e.ora_inizio) <= new Date() ||
                                (!reservationEnabled &&
                                  !cardReservationEnabled) ||
                                eventLink === ""
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <p
                  style={{
                    color: colors?.secondary,
                    fontSize: 13,
                  }}
                >
                  Nessuna data in programma.
                </p>
              )}
            </div>
          )}
          {onUpdate && (
            <button
              style={{
                ...styles.button,
                backgroundColor: colors?.primary,
                padding: 15,
                borderRadius: 15,
                width: "60%",
                marginBottom: 20,
                marginTop: 40,
                color: colors?.white,
                opacity:
                  name === "" ||
                  emailTitle === "" ||
                  colore === "" ||
                  location === "" ||
                  capacity === "" ||
                  startingTime === "" ||
                  endingTime === "" ||
                  checkTimesValidity(startingTime, endingTime, isCopying) ||
                  description === "" ||
                  !checkCapacityVariation(capacity, event)
                    ? 0.3
                    : 1,
              }}
              disabled={
                name === "" ||
                emailTitle === "" ||
                colore === "" ||
                location === "" ||
                capacity === "" ||
                startingTime === "" ||
                endingTime === "" ||
                checkTimesValidity(startingTime, endingTime, isCopying) ||
                description === "" ||
                !checkCapacityVariation(capacity, event)
              }
              onClick={() => {
                setLoader(true);
                var tempExternalURL = "";
                if (!externalManagementFlag) {
                  setExternalURL("");
                  tempExternalURL = "";
                } else {
                  tempExternalURL = externalURL;
                }
                var tempDescription = description.replace(
                  /\r\n|\r|\n/g,
                  "<br/>"
                );
                setDescription(tempDescription);
                if (event && !isCopying) {
                  onModifySchema(
                    event.id,
                    name,
                    emailTitle,
                    location,
                    capacity,
                    tempDescription,
                    category,
                    externalManagementFlag,
                    tempExternalURL,
                    startingTime,
                    endingTime,
                    giorni_ripetizione,
                    eventForChildren,
                    reservationEnabled,
                    colore,
                    ticketsType,
                    cardReservationEnabled,
                    flagAttesa
                  );
                } else {
                  onCreateSchema(
                    name,
                    emailTitle,
                    location,
                    capacity,
                    tempDescription,
                    category,
                    externalManagementFlag,
                    tempExternalURL,
                    startingTime,
                    endingTime,
                    giorni_ripetizione,
                    eventForChildren,
                    reservationEnabled,
                    colore,
                    ticketsType,
                    cardReservationEnabled,
                    pathToUpload,
                    uniqueEvent,
                    eventImage,
                    ticketsList,
                    flagAttesa
                  );
                }
              }}
            >
              Salva
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ShowEvent;
