import React from "react";
import moment from "moment";
import "moment/locale/it";
moment.locale("it");

const Recap = ({ colors, ownersList, giftForm }) => {
  const styles = {
    container: {
      width: "100%",
      overflow: "scroll",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      margin: 10,
    },
    title: {
      color: colors.darkgray,
      fontSize: 13,
      fontWeight: "700",
      width: "95%",
      textAlign: "left",
      marginBottom: 10,
    },
    field: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: 15,
      marginRight: 15,
    },
    label: {
      color: colors.gray,
      fontSize: 11,
      marginRight: 5,
      marginBottom: 8,
      marginTop: 0,
    },
    content: {
      color: colors.darkgray,
      fontSize: 13,
      marginBottom: 8,
      marginTop: 0,
    },
  };
  const owner = (owner, key) => {
    const {
      numero,
      nome,
      cognome,
      email,
      nascita,
      via,
      citta,
      cap,
      provincia,
    } = owner;
    return (
      <div key={key} style={styles.card}>
        <p style={styles.title}>CARD N° {numero}</p>
        <div style={styles.row}>
          <div style={styles.field}>
            <p style={styles.label}>Nome</p>
            <p style={styles.content}>{nome}</p>
          </div>
          <div style={styles.field}>
            <p style={styles.label}>Cognome</p>
            <p style={styles.content}>{cognome}</p>
          </div>
          <div style={styles.field}>
            <p style={styles.label}>Email</p>
            <p style={styles.content}>{email}</p>
          </div>
        </div>
        <div style={styles.row}>
          <div style={styles.field}>
            <p style={styles.label}>Data di nascita</p>
            <p style={styles.content}>
              {moment(new Date(nascita)).format("DD MMM yyyy")}
            </p>
          </div>
          <div style={styles.field}>
            <p style={styles.label}>Indirizzo</p>
            <p style={styles.content}>
              {via} - {citta} {cap} ({provincia})
            </p>
          </div>
        </div>
      </div>
    );
  };

  return ownersList.length > 0 ? (
    <div style={styles.container}>
      {ownersList.map((o, key) => {
        return owner(o, key);
      })}
    </div>
  ) : (
    <div style={styles.container}>
      {giftForm && (
        <div style={styles.card}>
          <p style={styles.title}>Riepilogo regalo tessera</p>
          <div style={styles.row}>
            <div style={styles.field}>
              <p style={styles.label}>Nome destinatario</p>
              <p style={styles.content}>{giftForm.name}</p>
            </div>
          </div>
          {giftForm.email !== "" && (
            <div style={styles.row}>
              <div style={styles.field}>
                <p style={styles.label}>Email destinatario</p>
                <p style={styles.content}>{giftForm.email}</p>
              </div>
            </div>
          )}
          <div style={styles.row}>
            <div style={styles.field}>
              <p style={styles.label}>Messaggio per il destinatario</p>
              <p style={styles.content}>{giftForm.message}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Recap;
