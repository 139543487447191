import React, { useContext } from "react";
import Field from "../../components/field";
import moment from "moment";
import { UserContext } from "../../contexts";

const NoticeBox = (props) => {
  const colors = useContext(UserContext)?.colors;
  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
      marginBottom: 10,
    },
    value: {
      textAlign: "left",
      margin: 2,
      marginBottom: 10,
      fontSize: 15,
    },
    title: {
      transform: "rotate(270deg)",
      fontWeight: "600",
      width: 150,
    },
    column: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: 10,
    },
    textInput: {
      outline: "none",
      padding: 10,
      textAlign: "left",
      width: "95%",
      height: 18,
      border: "1px solid lightgray",
      borderRadius: 5,
    },
    saveButton: {
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: colors.darkgray,
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 15,
      marginTop: 30,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
    },
  };

  return (
    <div style={styles.container}>
      <p style={styles.title}>Segnalazione</p>
      <div style={styles.column}>
        <div style={styles.row}>
          <div style={styles.column}>
            <div style={{ marginTop: 5 }}>
              <p
                style={{
                  margin: 10,
                  marginLeft: 0,
                  fontSize: 12,
                  textAlign: "left",
                }}
              >
                Titolo
              </p>
              {props.isEditing ? (
                <input
                  type="text"
                  style={styles.textInput}
                  value={props.field}
                  onChange={(event) => props.setField(event.target.value)}
                  placeholder="Titolo"
                />
              ) : (
                <p
                  style={{
                    ...styles.value,
                    width: 400,
                    wordWrap: "break-word",
                  }}
                >
                  {props.field}
                </p>
              )}
            </div>
            {props.openRecord.segnalazione?.info
              ?.filter((field) => field.type !== -1)
              .slice(
                0,
                Math.ceil(props.openRecord.segnalazione?.info.length / 2)
              )
              .map((field, key) => {
                return (
                  <Field
                    key={key}
                    field={field}
                    colors={colors}
                    addField={(newField) => (field.value = newField)}
                    fixed={!props.isEditing}
                    tickets={1}
                  />
                );
              })}
          </div>
          <div style={styles.column}>
            {!props.isEditing && (
              <div>
                <p
                  style={{
                    margin: 10,
                    marginLeft: 0,
                    fontSize: 12,
                    textAlign: "left",
                  }}
                >
                  Data e ora segnalazione
                </p>
                <p style={styles.value}>
                  {moment(props.openRecord.segnalazione?.data_creazione).format(
                    "DD/MM/yyyy, HH:mm"
                  )}
                </p>
              </div>
            )}

            {props.openRecord.segnalazione?.info
              ?.slice(Math.ceil(props.openRecord.segnalazione?.info.length / 2))
              .map((field, key) => {
                return (
                  <Field
                    key={key}
                    field={field}
                    colors={colors}
                    addField={(newField) => (field.value = newField)}
                    fixed={!props.isEditing}
                    tickets={1}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeBox;
