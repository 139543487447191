import React, { useState, useContext } from "react";
import share from "./../../../img/whatsapp.png";
import { OrgContext } from "../../../contexts";

const ShareEventButton = ({
  eventInfo,
  eventLink,
  text,
  colors,
  width,
  backgroundColor,
  disabled,
}) => {
  const org = useContext(OrgContext)?.org;
  const [open, setOpen] = useState(false);
  const [coordinates, setCoordinates] = useState(false);

  const message =
    "Ecco il link all'evento *\"" +
    eventInfo?.nome_evento +
    '"*, a cura di _' +
    org?.nome +
    "_, il giorno " +
    new Date(eventInfo?.ora_inizio).toLocaleDateString("it-IT") +
    ", dalle ore " +
    new Date(eventInfo?.ora_inizio).toLocaleTimeString("it-IT", {
      hour: "2-digit",
      minute: "2-digit",
    }) +
    " alle ore " +
    new Date(eventInfo?.ora_fine).toLocaleTimeString("it-IT", {
      hour: "2-digit",
      minute: "2-digit",
    }) +
    ".%0aLuogo dell'evento: " +
    eventInfo?.luogo_evento +
    ".%0a%0a" +
    eventLink;

  const styles = {
    buttonBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 2,
      cursor: "pointer",
    },
    hoverLabel: {
      textAlign: "center",
      fontSize: 12,
      width: width,
      position: "absolute",
      color: colors?.darkgray,
      zIndex: 100,
      backgroundColor: backgroundColor,
      borderRadius: 10,
      padding: 20,
      top: coordinates.y,
      left: coordinates.x,
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      cursor: "pointer",
      color: colors?.white,
    },
  };
  return (
    <div
      style={styles.buttonBox}
      onMouseEnter={() => {
        if (!disabled) {
          setOpen(true);
        }
      }}
      onMouseLeave={() => setOpen(false)}
      onMouseMove={(e) => {
        setCoordinates({
          x: width === 200 ? e.pageX - window.innerWidth + 150 : e.pageX,
          y: e.pageY - 100,
        });
      }}
    >
      {open && <p style={styles.hoverLabel}>{text}</p>}
      <div>
        <a
          href={"https://wa.me/?text=" + message}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt="Share icon"
            src={share}
            style={{
              height: 15,
              objectFit: "contain",
              paddingLeft: 6,
            }}
          ></img>
        </a>
      </div>
    </div>
  );
};

export default ShareEventButton;
