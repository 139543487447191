let types=[
    {   id: 1,
        name: "Testo"
    },
    {   id: 2,
        name: "Telefono"
    },
    {   id: 3,
        name: "E-mail"
    },
    {   id: 4,
        name: "Numero"
    },
    {   id: 5,
        name: "Prezzo"
    },
    {   id: 6,
        name: "Scelta multipla"
    },
    {   id: 7,
        name: "Data"
    },
    {   id: 8,
        name: "Data e ora"
    }
]

module.exports = types