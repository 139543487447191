import React from "react";
import CodiceFiscale from "codice-fiscale-js";
import moment from "moment";
import "moment/locale/it";
moment.locale("it");

function validateEmail(email, setEmailError) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let error = re.test(String(email).toLowerCase());
  if (email === "") {
    setEmailError(false);
  } else {
    setEmailError(!error);
  }
  return error;
}

function validateTaxCode(taxCode, hasVAT, setTaxCodeError) {
  let error;
  if (taxCode === "") {
    setTaxCodeError(false);
  } else {
    if (!hasVAT) {
      try {
        new CodiceFiscale(taxCode);
        error = false;
      } catch {
        error = true;
      }
    } else {
      error = taxCode.length !== 11;
    }
    setTaxCodeError(error);
  }
  return error;
}

const BillingForm = ({ colors, formFunctions, renewal, giftBilling }) => {
  const styles = {
    label: {
      fontSize: 13,
      color: colors.gray,
    },
    selectBox: {
      marginTop: 5,
      marginBottom: 5,
      width: "100%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    textInput: {
      outline: "none",
      border: "1px solid lightgray",
      width: "80%",
      height: 20,
      borderRadius: 10,
      marginRight: 10,
      marginLeft: 10,
      padding: 5,
      textAlign: "center",
    },
    text: {
      fontSize: 13,
      margin: 0,
    },
    title: {
      fontSize: 13,
      fontWeight: "600",
      color: colors.darkgray,
      width: "90%",
      textAlign: "left",
    },
  };

  return (
    <div style={{ marginTop: 20, marginBottom: 20, width: "100%" }}>
      <div style={styles.row}>
        <p style={styles.title}>Dati di fatturazione</p>
      </div>
      {!giftBilling && (
        <div style={styles.row}>
          <input
            type="checkbox"
            defaultChecked={formFunctions.useCardData}
            onChange={(event) =>
              formFunctions.setUseCardData(event.target.checked)
            }
          />
          <p style={styles.text}>
            {renewal
              ? "Utilizza i dati di fatturazione forniti al momento dell’acquisto"
              : "Utilizza dati tessera"}
          </p>
        </div>
      )}

      <div style={styles.row}>
        <div style={styles.selectBox}>
          <p style={styles.label}>Nome</p>
          <input
            type="text"
            style={styles.textInput}
            value={formFunctions.name}
            onChange={(event) => formFunctions.setName(event.target.value)}
            placeholder="Nome"
          ></input>
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>Cognome</p>

          <input
            type="text"
            style={styles.textInput}
            value={formFunctions.surname}
            onChange={(event) => formFunctions.setSurname(event.target.value)}
            placeholder="Cognome"
          ></input>
        </div>
      </div>
      <div style={styles.row}>
        <div style={styles.selectBox}>
          <p style={styles.label}>Email</p>
          <input
            type="text"
            style={styles.textInput}
            value={formFunctions.email}
            onChange={(event) => {
              formFunctions.setEmail(event.target.value);
              validateEmail(event.target.value, formFunctions.setEmailError);
            }}
            placeholder="Email"
          ></input>
          {formFunctions.emailError && (
            <p
              style={{
                color: colors.highlight,
                marginTop: 2,
                fontSize: 10,
                width: "100%",
                textAlign: "center",
              }}
            >
              Email non valida
            </p>
          )}
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>CAP</p>
          <input
            type="text"
            style={styles.textInput}
            value={formFunctions.zip}
            onChange={(event) => {
              formFunctions.setZip(event.target.value.replace(/[^\d]/, ""));
            }}
            placeholder="CAP"
          ></input>
        </div>
      </div>
      <div style={styles.row}>
        <div style={styles.selectBox}>
          <p style={styles.label}>Indirizzo</p>
          <input
            type="text"
            style={styles.textInput}
            value={formFunctions.address}
            onChange={(event) => {
              formFunctions.setAddress(event.target.value);
            }}
            placeholder="Indirizzo"
          ></input>
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>Città</p>
          <input
            type="text"
            style={styles.textInput}
            value={formFunctions.city}
            onChange={(event) => {
              formFunctions.setCity(event.target.value);
            }}
            placeholder="Città"
          ></input>
        </div>
      </div>
      <div style={styles.row}>
        <div style={styles.selectBox}>
          <p style={styles.label}>Provincia</p>
          <input
            type="text"
            style={styles.textInput}
            value={formFunctions.county}
            onChange={(event) => {
              if (event.target.value.length <= 2) {
                formFunctions.setCounty(event.target.value.toUpperCase());
              }
            }}
            placeholder="Provincia"
          ></input>
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>
            {formFunctions.hasVAT ? "Partita IVA" : "Codice fiscale"}
          </p>
          <input
            type="text"
            style={styles.textInput}
            value={formFunctions.taxCode}
            onChange={(event) => {
              if (
                event.target.value.length <= (formFunctions.hasVAT ? 11 : 16)
              ) {
                formFunctions.setTaxCode(event.target.value.toUpperCase());
                validateTaxCode(
                  event.target.value,
                  formFunctions.hasVAT,
                  formFunctions.setTaxCodeError
                );
              }
            }}
            placeholder={
              formFunctions.hasVAT ? "Partita IVA" : "Codice fiscale"
            }
          ></input>
          {formFunctions.taxCodeError && (
            <p
              style={{
                color: colors.highlight,
                marginTop: 2,
                fontSize: 10,
                width: "100%",
                textAlign: "center",
              }}
            >
              {formFunctions.hasVAT
                ? "Partita IVA non valida"
                : "Codice fiscale errato"}
            </p>
          )}
        </div>
      </div>
      <div style={styles.row}>
        {formFunctions.hasVAT && (
          <div style={styles.selectBox}>
            <p style={styles.label}>SDI</p>
            <input
              type="text"
              style={styles.textInput}
              value={formFunctions.sdiCode}
              onChange={(event) => {
                if (event.target.value.length <= 7) {
                  formFunctions.setSdiCode(event.target.value.toUpperCase());
                }
              }}
              placeholder="SDI"
            ></input>{" "}
          </div>
        )}
        <div
          style={{
            ...styles.row,
            marginTop: 40,
          }}
        >
          <input
            type="checkbox"
            defaultChecked={formFunctions.hasVAT}
            onChange={(event) => formFunctions.setHasVAT(event.target.checked)}
          />
          <p style={styles.text}>Ha partiva IVA</p>
        </div>
      </div>
      <div style={styles.row}>
        <p style={styles.title}>Tessera VIP</p>
      </div>
      <div style={styles.row}>
        <input
          type="checkbox"
          defaultChecked={formFunctions.isVip}
          onChange={(event) => formFunctions.setIsVip(event.target.checked)}
        />
        <p style={styles.text}>Tessera VIP</p>
      </div>
      <div style={styles.row}>
        <p style={styles.title}>Metodo di pagamento</p>
      </div>
      <div
        style={{ ...styles.row, width: "100%", justifyContent: "space-around" }}
      >
        <div>
          <input
            type="radio"
            id="Contanti"
            value="Contanti"
            checked={formFunctions.paymentMethod === "Contanti"}
            onChange={(event) =>
              formFunctions.setPaymentMethod(event.target.value)
            }
          />
          <label style={styles.text}>Contanti</label>
        </div>
        <div>
          <input
            type="radio"
            id="POS"
            value="POS"
            checked={formFunctions.paymentMethod === "POS"}
            onChange={(event) => {
              formFunctions.setPaymentMethod(event.target.value);
            }}
          />
          <label style={styles.text}>POS</label>
        </div>
        <div>
          <input
            type="radio"
            id="Sospeso"
            value="Sospeso di cassa"
            checked={formFunctions.paymentMethod === "Sospeso di cassa"}
            onChange={(event) =>
              formFunctions.setPaymentMethod(event.target.value)
            }
          />
          <label style={styles.text}>Sospeso di cassa</label>
        </div>
      </div>
    </div>
  );
};

export default BillingForm;
