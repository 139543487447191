import React, { useEffect, useState } from "react";
import close from "./../../../img/closeButton.png";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it";
import "./../../../customizer.css";
import ReactLoading from "react-loading";

registerLocale("it", it);

const ModifyStockItem = ({ item, closeModal, colors, decreaseQuantity }) => {
  const [details] = useState(item.nome_articolo);
  const [loader, setLoader] = useState(false);
  const [giacenza, setGiacenza] = useState(0);
  const [chosenValue, setChosenValue] = useState(0);
  const [errorNumber, setErrorNumber] = useState(false);

  useEffect(() => {
    let tmpGiacenza = 0;
    for (let purchase of item.acquisti) {
      tmpGiacenza += purchase.giacenza;
    }
    setGiacenza(tmpGiacenza);
    setChosenValue(1);
  }, [item]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 30,
      margin: "auto",
      width: 600,
      zIndex: 300,
      justifyContent: "center",
    },
    loaderBox: {
      display: "flex",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      zIndex: 300,
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      display: "flex",
      width: "100%",
      height: 60,
      backgroundColor: colors.primary,
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    content: {
      width: "100%",
      maxHeight: 300,
      flexDirection: "column",
      backgroundColor: colors.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    field: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginTop: 20,
    },
    footer: {
      display: "flex",
      width: "100%",
      height: 120,
      backgroundColor: colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      marginTop: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    disabledButton: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      marginTop: 40,
      border: "none",
      outline: "none",
      opacity: 0.7,
    },
    menu: {
      border: "1px solid lightgray",
      width: "100%",
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 10,
      color: colors.darkgray,
      borderRadius: 20,
    },
    label: {
      fontSize: 17,
      fontWeight: "bold",
      color: colors.lightgray,
      margin: 20,
      width: 400,
    },
    box: {
      width: 400,
      display: "flex",
    },
    halfLabel: {
      fontSize: 15,
      color: colors.lightgray,
      margin: 20,
      width: "100%",
      textAlign: "left",
    },
    halfBox: {
      width: "100%",
      display: "flex",
    },
    textInput: {
      borderRadius: 5,
      border: "1px solid lightgray",
      outline: "none",
      textAlign: "center",
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
      width: "20%",
    },
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: colors.gray,
          opacity: 0.7,
        }}
      />
      {loader ? (
        <div style={styles.loaderBox}>
          <ReactLoading
            type={"spinningBubbles"}
            color={colors.primary}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <div style={styles.container}>
          <div style={styles.header}>
            <p style={{ color: colors.white, fontSize: 20 }}>
              RIDUCI GIACENZA ARTICOLO
            </p>
            <button
              onClick={closeModal}
              style={{
                position: "absolute",
                right: 30,
                marginTop: "auto",
                marginBottom: "auto",
                border: "none",
                backgroundColor: "transparent",
                outline: "none",
                cursor: "pointer",
              }}
            >
              <img
                alt="App logo"
                src={close}
                style={{ width: 15, objectFit: "contain" }}
              />
            </button>
          </div>
          <>
            <div style={styles.content}>
              <div style={styles.field}>
                <p style={{ ...styles.label, color: "black" }}>
                  {item.nome_articolo}
                </p>
                <p style={{ fontSize: 14 }}>
                  Unità di cui ridurre la giacenza dell'articolo:
                </p>
                <input
                  type="number"
                  name="number"
                  value={chosenValue}
                  onChange={(event) => {
                    if (
                      parseInt(event.target.value) <= giacenza &&
                      parseInt(event.target.value) > 0
                    ) {
                      setErrorNumber(false);
                    } else {
                      setErrorNumber(true);
                    }
                    setChosenValue(event.target.value);
                  }}
                  placeholder="Da rimuovere"
                  style={{ ...styles.textInput }}
                />
                {errorNumber && (
                  <p style={{ color: "red", fontSize: 9 }}>
                    La giacenza può essere modificata solo di un numero di unità
                    maggiore di 0 e minore o uguale a {giacenza}
                  </p>
                )}
              </div>
            </div>
            <div style={styles.footer}>
              <button
                onClick={() => {
                  setLoader(true);
                  decreaseQuantity(item.codice_articolo, chosenValue);
                }}
                style={
                  details === undefined ||
                  parseInt(chosenValue) === 0 ||
                  parseInt(chosenValue) > giacenza ||
                  chosenValue === ""
                    ? styles.disabledButton
                    : styles.button
                }
                disabled={
                  details === undefined ||
                  parseInt(chosenValue) === 0 ||
                  parseInt(chosenValue) > giacenza ||
                  chosenValue === ""
                }
              >
                RIDUCI
              </button>
            </div>
          </>
        </div>
      )}
    </>
  );
};

export default ModifyStockItem;
