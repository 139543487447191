import React, { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../../../contexts";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const SalesCrossedFiltersBox = (props) => {
  const colors = useContext(UserContext)?.colors;

  //Being a crossed-filters chart, it needs two distinct cateogories for filtering data
  const [firstSelection, setFirstSelection] = useState("");
  const prevFirstSelection = usePrevious(firstSelection);
  const [secondSelection, setSecondSelection] = useState("");
  const prevSecondSelection = usePrevious(secondSelection);

  const [filters, setFilters] = useState([]);
  const [categories, setCategories] = useState([]);

  //Each time a new filter is selected, the update of the chart is triggered
  useEffect(() => {
    if (
      prevFirstSelection !== undefined &&
      prevFirstSelection !== firstSelection &&
      firstSelection !== "" &&
      firstSelection !== undefined
    ) {
      props.handleFirstSelection(firstSelection);
    }

    if (
      prevSecondSelection !== undefined &&
      prevSecondSelection !== secondSelection &&
      secondSelection !== "" &&
      secondSelection !== undefined
    ) {
      props.handleSecondSelection(secondSelection);
    }
  }, [
    firstSelection,
    prevFirstSelection,
    secondSelection,
    prevSecondSelection,
    props,
  ]);

  useEffect(() => {
    if (props.contentFilters) {
      let tmpCategories = props.categories;
      let tmpFilters = props.contentFilters;
      setSecondSelection(tmpCategories[0]);
      setFirstSelection(tmpFilters[0]);

      setFilters(tmpFilters);
      setCategories(tmpCategories);
    }
  }, [props.contentFilters, props.categories]);

  const styles = {
    container: {
      backgroundColor: colors?.darkBackground,
      width: "98%",
      display: "flex",
      flexDirection: "column",
      margin: 10,
      borderRadius: 10,
    },
    header: {
      backgroundColor: colors?.secondary,
      height: 50,
      alignItems: "center",
      justifyContent: "space-between",
      display: "flex",
      outline: "none",
      border: "none",
      borderRadius: 10,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    title: {
      color: colors?.white,
      marginLeft: 30,
      fontSize: 18,
    },
    dropdown: {
      backgroundColor: colors?.primary,
      borderColor: colors?.primary,
      color: colors?.white,
      height: 40,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      margin: 1,
      marginRight: 10,
      borderRadius: 10,
      fontSize: 15,
      padding: 10,
    },
    lable: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    body: {
      margin: 10,
    },
    button: {
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
      outline: "none",
      marginRight: "20px",
      marginLeft: "15px",
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <p style={styles.title}>{props.title}</p>
        <div style={styles.lable}>
          <div style={styles.lable}>
            <select
              name={"Filtro 1"}
              style={styles.dropdown}
              value={firstSelection}
              onChange={(chosenCategory) => {
                setFirstSelection(chosenCategory.target.value);
              }}
            >
              {filters &&
                filters.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item.charAt(0).toUpperCase() + item.slice(1)}
                    </option>
                  );
                })}
            </select>
          </div>
          <div style={styles.lable}>
            <select
              name={"Filtro 2"}
              style={styles.dropdown}
              value={secondSelection}
              onChange={(chosenCategory) => {
                setSecondSelection(chosenCategory.target.value);
              }}
            >
              {categories &&
                categories.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item.charAt(0).toUpperCase() + item.slice(1)}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>
      <div style={styles.body}>{props.body}</div>
    </div>
  );
};

export default SalesCrossedFiltersBox;
