import React, { useContext } from "react";
import SubButton from "./buttons/subButton";
import info from "./../img/info.png";
import users from "./../img/users.png";
import settings from "./../img/settings_white.png";
import sale from "./../img/sale.png";
import { UserContext } from "../contexts";

const SettingsBar = ({ selection, setSelection }) => {
  const colors = useContext(UserContext)?.colors;
  const user = useContext(UserContext)?.user;
  const styles = {
    sideBar: {
      width: 200,
      backgroundColor: colors?.darkgray,
      height: "100%",
      boxShadow: "2px 0 4px 0 rgb(0 0 0 / 20%)",
    },
  };
  return (
    <div style={styles.sideBar}>
      <SubButton
        icon={settings}
        mod={{ id: 1, name: "Generali" }}
        colors={colors}
        onClick={() => setSelection(1)}
        selection={selection}
        size={18}
        selectionColor={colors?.gray}
        hoverColor={colors?.mediumgray}
      />
      <SubButton
        icon={info}
        mod={{ id: 2, name: user?.flag_network ? "Info rete" : "Info museo" }}
        colors={colors}
        onClick={() => setSelection(2)}
        selection={selection}
        size={18}
        selectionColor={colors?.gray}
        hoverColor={colors?.mediumgray}
      />
      <SubButton
        icon={users}
        mod={{ id: 3, name: user?.flag_network ? "Musei" : "Utenti" }}
        colors={colors}
        onClick={() => setSelection(3)}
        selection={selection}
        size={18}
        selectionColor={colors?.gray}
        hoverColor={colors?.mediumgray}
      />
      <SubButton
        icon={sale}
        mod={{ id: 4, name: "Codici sconto" }}
        colors={colors}
        onClick={() => setSelection(4)}
        selection={selection}
        size={18}
        selectionColor={colors?.gray}
        hoverColor={colors?.mediumgray}
      />
    </div>
  );
};

export default SettingsBar;
