import React, { useEffect, useState } from "react";
import SubBar from "./activityBar";
import ActivitiesManager from "./list/activitiesManager";
import Speakers from "./speakers/speakersManager";
import Requests from "./requests/requestsManager";
import InvoicesManager from "./invoices/invoicesManager";
import Settings from "./settings";

const Activities = ({ history, permissions, iframe, index }) => {
  const [selection, setSelection] = useState(1);

  useEffect(() => {
    if (index !== undefined && !isNaN(parseInt(index))) {
      setSelection(parseInt(index));
    } else {
      permissions?.tutte
        ? setSelection(1)
        : permissions?.relatori
        ? setSelection(2)
        : permissions?.richieste
        ? setSelection(3)
        : permissions?.fatture
        ? setSelection(4)
        : permissions?.impostazioni
        ? setSelection(5)
        : setSelection(1);
    }
  }, [index, permissions]);

  const selected = (id) => {
    switch (id) {
      case 1:
        return <ActivitiesManager history={history} />;
      case 2:
        return <Speakers history={history} />;
      case 3:
        return <Requests history={history} />;
      case 4:
        return <InvoicesManager history={history} />;
      case 5:
        return <Settings history={history} />;
      default: {
        return permissions?.tutte ? (
          <ActivitiesManager history={history} />
        ) : permissions?.relatori ? (
          <Speakers history={history} />
        ) : permissions?.richieste ? (
          <Requests history={history} />
        ) : permissions?.fatture ? (
          <InvoicesManager history={history} />
        ) : permissions?.impostazioni ? (
          <Settings history={history} />
        ) : (
          <ActivitiesManager history={history} />
        );
      }
    }
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      width: iframe ? "100%" : "85%",
      position: "absolute",
      right: 0,
      overflow: "scroll",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      width: iframe ? "100%" : "88%",
    },
  };

  return (
    <div style={styles.container}>
      {!iframe && (
        <SubBar
          selection={selection}
          setSelection={(selection) => {
            setSelection(selection);
          }}
          permissions={permissions}
        />
      )}
      <div style={styles.content}>{selected(selection)}</div>
    </div>
  );
};

export default Activities;
