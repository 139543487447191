import React, { useContext } from "react";
import SubButton from "../../../components/buttons/subButton";
import document from "./../../../img/document.png";
import calendar from "./../../../img/calendar.png";
import speakers from "./../../../img/users.png";
import requests from "./../../../img/newsletter.png";
import settings from "./../../../img/settings_white.png";
import { UserContext } from "../../../contexts";
import { LanguageContext } from "../../../containers/language";

const ActivityBar = (props) => {
  const colors = useContext(UserContext)?.colors;

  const { dictionary } = useContext(LanguageContext);

  const styles = {
    sideBar: {
      width: 200,
      backgroundColor: colors?.darkgray,
      height: "100%",
      boxShadow: "2px 0 4px 0 rgb(0 0 0 / 20%)",
    },
  };
  return (
    <div style={styles.sideBar}>
      {props.permissions?.tutte && (
        <SubButton
          icon={calendar}
          mod={{ id: 1, name: dictionary.activitiesList }}
          onClick={() => props.setSelection(1)}
          selection={props.selection}
          size={18}
          selectionColor={colors?.gray}
          hoverColor={colors?.mediumgray}
        />
      )}
      {props.permissions?.relatori && (
        <SubButton
          icon={speakers}
          mod={{ id: 2, name: dictionary.speakers }}
          onClick={() => props.setSelection(2)}
          selection={props.selection}
          size={18}
          selectionColor={colors?.gray}
          hoverColor={colors?.mediumgray}
        />
      )}
      {props.permissions?.richieste && (
        <SubButton
          icon={requests}
          mod={{ id: 3, name: dictionary.requests }}
          onClick={() => props.setSelection(3)}
          selection={props.selection}
          size={18}
          selectionColor={colors?.gray}
          hoverColor={colors?.mediumgray}
        />
      )}
      {props.permissions?.fatture && (
        <SubButton
          icon={document}
          mod={{ id: 4, name: dictionary.invoices }}
          onClick={() => props.setSelection(4)}
          selection={props.selection}
          size={18}
          selectionColor={colors?.gray}
          hoverColor={colors?.mediumgray}
        />
      )}
      {props.permissions?.impostazioni && (
        <SubButton
          icon={settings}
          mod={{ id: 5, name: dictionary.settings }}
          onClick={() => props.setSelection(5)}
          selection={props.selection}
          size={18}
          selectionColor={colors?.gray}
          hoverColor={colors?.mediumgray}
        />
      )}
    </div>
  );
};

export default ActivityBar;
