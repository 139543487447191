import React from "react";
import moment from "moment";
import "moment/locale/it";
moment.locale("it");

function isEmpty(field) {
  return field === "" || field === null || field === undefined;
}

const camelCase = (str) => {
  return str.substring(0, 1).toUpperCase() + str.substring(1);
};
const BillingForm = ({ colors, card }) => {
  const styles = {
    label: {
      fontSize: 13,
      color: colors.gray,
    },
    selectBox: {
      marginTop: 5,
      marginBottom: 5,
      width: "100%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 10,
      marginBottom: 10,
    },
    textInput: {
      outline: "none",
      border: "1px solid lightgray",
      width: "80%",
      height: 20,
      borderRadius: 10,
      marginRight: 10,
      marginLeft: 10,
      padding: 5,
      textAlign: "center",
    },
    text: {
      fontSize: 13,
      margin: 0,
    },
    title: {
      fontSize: 13,
      fontWeight: "600",
      color: colors.darkgray,
      width: "90%",
      textAlign: "left",
    },
  };
  const {
    tessera,
    nome,
    cognome,
    created_at,
    luogo_vendita,
    consegnata,
    codice_fiscale,
    partita_iva,
    sdi,
    indirizzoString,
    email,
    prezzo,
    metodo_pagamento,
    pagamento,
    rinnovo,
  } = card;
  return (
    <>
      <div style={{ ...styles.row, marginTop: 30, backgroundColor: "#ececec" }}>
        <p style={styles.title}>Dati acquisto</p>
      </div>
      <div style={styles.row}>
        <div style={styles.selectBox}>
          <p style={styles.label}>Card</p>
          <p style={styles.text}>{tessera}</p>
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>Data aquisto </p>
          <p style={styles.text}>
            {moment(new Date(created_at)).format("DD/MM/yyyy, HH:mm")}
          </p>
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>Luogo acquisto</p>
          <p style={styles.text}>{camelCase(luogo_vendita)}</p>
        </div>
      </div>
      <div style={styles.row}>
        <div style={styles.selectBox}>
          <p style={styles.label}>Prezzo </p>
          <p style={styles.text}>{"€ " + parseFloat(prezzo).toFixed(2)}</p>
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>Le tessera è stata consegnata</p>
          <p style={styles.text}>{consegnata ? "Si" : "No"}</p>
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>Tipologia</p>
          <p style={styles.text}>{rinnovo}</p>
        </div>
      </div>
      <div style={{ ...styles.row, marginTop: 30, backgroundColor: "#ececec" }}>
        <p style={styles.title}>Dati di fatturazione</p>
      </div>
      <div style={styles.row}>
        <div style={styles.selectBox}>
          <p style={styles.label}>Nome</p>
          <p style={styles.text}>{nome}</p>
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>Cognome</p>
          <p style={styles.text}>{cognome}</p>
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>Email</p>
          <p style={styles.text}>{email}</p>
        </div>{" "}
      </div>
      <div style={styles.row}>
        <div style={styles.selectBox}>
          <p style={styles.label}>Indirizzo</p>
          <p style={styles.text}>{indirizzoString}</p>
        </div>
        {!isEmpty(codice_fiscale) ? (
          <div style={styles.selectBox}>
            <p style={styles.label}>Codice Fiscale</p>
            <p style={styles.text}>{codice_fiscale}</p>
          </div>
        ) : (
          <div style={styles.selectBox}>
            <p style={styles.label}>Partita IVA</p>
            <p style={styles.text}>{partita_iva}</p>
          </div>
        )}

        <div style={styles.selectBox}>
          {!isEmpty(sdi) && <p style={styles.label}>SDI</p>}
          {!isEmpty(sdi) && <p style={styles.text}>{sdi}</p>}
        </div>
      </div>
      {metodo_pagamento === "Paypal" && (
        <>
          <div
            style={{ ...styles.row, marginTop: 30, backgroundColor: "#ececec" }}
          >
            <p style={styles.title}>Dati transazione Paypal</p>
          </div>
          <div style={styles.row}>
            <div style={styles.selectBox}>
              <p style={styles.label}>Codice transazione</p>
              <p style={styles.text}>
                {pagamento.purchase_units[0].payments.captures[0].id}
              </p>
            </div>
            <div style={styles.selectBox}>
              <p style={styles.label}>Descrizione</p>
              <p style={styles.text}>
                {pagamento.purchase_units[0].description}
              </p>
            </div>
            <div style={styles.selectBox}>
              <p style={styles.label}>Nome</p>
              <p style={styles.text}>
                {camelCase(pagamento.payer.name.given_name) +
                  " " +
                  camelCase(pagamento.payer.name.surname)}
              </p>
            </div>
            <div style={styles.selectBox}>
              <p style={styles.label}>Email</p>
              <p style={styles.text}>{pagamento.payer.email_address}</p>
            </div>{" "}
          </div>
        </>
      )}
    </>
  );
};

export default BillingForm;
