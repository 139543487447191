import React, { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../../../contexts";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const DataBoxUserBehavior = (props) => {
  const colors = useContext(UserContext)?.colors;

  //The filter selected for showing data (age, gender, etc.)
  const [selection, setSelection] = useState("");
  const prevSelection = usePrevious(selection);

  //If we have custom filters and those include birthdate, add the age filter
  const [showAge, setShowAge] = useState(false);

  //Trigger render of the content each time the filter is changed
  useEffect(() => {
    if (
      prevSelection !== undefined &&
      prevSelection !== selection &&
      selection !== "" &&
      selection !== undefined
    ) {
      props.handleSelection(selection);
    }
  }, [props, selection, prevSelection]);

  useEffect(() => {
    if (props.contentFilters) {
      setSelection(props.contentFilters.sort()[0]);
    }

    if (props.contentFilters.includes("Anno di nascita")) {
      setShowAge(true);
    }
  }, [props.contentFilters]);

  const styles = {
    container: {
      backgroundColor: colors?.darkBackground,
      width: "98%",
      display: "flex",
      flexDirection: "column",
      margin: 10,
      borderRadius: 10,
    },
    header: {
      backgroundColor: colors?.secondary,
      height: 50,
      alignItems: "center",
      justifyContent: "space-between",
      display: "flex",
      outline: "none",
      border: "none",
      borderRadius: 10,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    title: {
      color: colors?.white,
      marginLeft: 30,
      fontSize: 18,
    },
    dropdown: {
      backgroundColor: colors?.primary,
      borderColor: colors?.primary,
      color: colors?.white,
      height: 40,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      margin: 1,
      marginRight: 10,
      borderRadius: 10,
      fontSize: 15,
      padding: 10,
    },
    body: {
      margin: 10,
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <p style={styles.title}>{props.title}</p>
        <select
          name={"Filtro"}
          style={styles.dropdown}
          value={selection}
          onChange={(chosenCategory) => {
            setSelection(chosenCategory.target.value);
          }}
        >
          {props.contentFilters &&
            props.contentFilters.sort().map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item.charAt(0).toUpperCase() + item.slice(1)}
                </option>
              );
            })}
          {showAge && (
            <option key={props.contentFilters.length} value={"Età"}>
              Età
            </option>
          )}
        </select>
      </div>
      <div style={styles.body}>{props.body}</div>
    </div>
  );
};

export default DataBoxUserBehavior;
