import React, { useState, useEffect, useRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import "react-datepicker/dist/react-datepicker.css";
import nations from "../assets/states_it.json";
import moment from "moment";
import { reportService } from "../_services";
registerLocale("it", it);

const styles = {
  textInput: {
    borderRadius: 5,
    border: "1px solid lightgray",
    width: "100%",
    height: 40,
    textAlign: "left",
    outline: "none",
    padding: 10,
    boxSizing: `border-box`,
  },
  value: {
    textAlign: "left",
    margin: 2,
    marginBottom: 10,
    fontSize: 15,
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
};

function getList(type) {
  switch (type) {
    case "countries":
      return nations;
    default:
      return [];
  }
}
function isEmpty(value) {
  return value === "" || value === null || value === undefined;
}

function validateEmail(email, setShowError, setIsEmail) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let error = re.test(String(email).toLowerCase());
  if (email === "") {
    setShowError(false);
  } else {
    setShowError(true);
  }
  setIsEmail(error);
  return error;
}
function filterNullValue(value) {
  if (value === null || value === "null") {
    return "";
  } else {
    return value;
  }
}
function getInput(
  field,
  value,
  onChange,
  options,
  retrieveCitiesFromZip,
  city,
  setCity,
  setShowError,
  setIsEmail,
  showError,
  isEmail,
  fixed
) {
  switch (field.type) {
    case 1: //Testo
      if (fixed) {
        return (
          <p
            style={{
              ...styles.value,
              wordWrap: "break-word",
            }}
          >
            {isEmpty(field.value) ? "-" : field.value}
          </p>
        );
      } else {
        return (
          <input
            type="text"
            style={styles.textInput}
            value={filterNullValue(value)}
            onChange={(event) => onChange(event.target.value)}
            placeholder={field.name}
          />
        );
      }
    case 2: //Telefono
      if (fixed) {
        return (
          <p style={styles.value}>{isEmpty(field.value) ? "-" : field.value}</p>
        );
      } else {
        return (
          <input
            type="text"
            style={styles.textInput}
            value={filterNullValue(value)}
            onChange={(event) =>
              onChange(event.target.value.replace(/[^\d]/, ""))
            }
            placeholder={field.name}
          />
        );
      }
    case 3: //Email
      if (fixed) {
        return (
          <p style={styles.value}>{isEmpty(field.value) ? "-" : field.value}</p>
        );
      } else {
        return (
          <>
            <input
              type="text"
              style={styles.textInput}
              value={filterNullValue(value)}
              onChange={(event) => {
                onChange(event.target.value);
                validateEmail(event.target.value, setShowError, setIsEmail);
              }}
              placeholder={field.name}
            />
            {showError && !isEmail && (
              <p
                style={{
                  // color: colors.highlight,
                  color: "darkred",
                  marginTop: 2,
                  fontSize: 10,
                  width: "100%",
                }}
              >
                Email non valida
              </p>
            )}
          </>
        );
      }
    case 4: //Numero
      if (fixed) {
        return (
          <p style={styles.value}>{isEmpty(field.value) ? "-" : field.value}</p>
        );
      } else {
        return (
          <input
            type="text"
            style={styles.textInput}
            value={filterNullValue(value)}
            onChange={(event) =>
              onChange(event.target.value.replace(/[^\d]/, ""))
            }
            placeholder={field.name}
          />
        );
      }
    case 5: //Prezzo
      if (fixed) {
        return (
          <p style={styles.value}>
            € {isEmpty(field.value) ? "-" : parseFloat(field.value).toFixed(2)}
          </p>
        );
      } else {
        return (
          <input
            type="text"
            style={styles.textInput}
            value={filterNullValue(value)}
            onChange={(event) =>
              onChange(event.target.value.replace(/[^0-9.]/, ""))
            }
            placeholder={field.name}
          />
        );
      }
    case 6: //Scelta multipla
      if (fixed) {
        return (
          <p style={styles.value}>{isEmpty(field.value) ? "-" : field.value}</p>
        );
      } else {
        return (
          <select
            name="options"
            style={styles.textInput}
            value={filterNullValue(value)}
            onChange={(event) => onChange(event.target.value)}
          >
            {field.options.map((option, key) => {
              return (
                <option key={key} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
        );
      }
    case 7: //Data
      if (fixed) {
        return (
          <p style={styles.value}>
            {isEmpty(field.value)
              ? "-"
              : moment(field.value).format("DD/MM/yyyy")}
          </p>
        );
      } else {
        return (
          <div
            style={{
              ...styles.textInput,
              flex: 1,
              width: "100%",
              textAlign: "left",
              paddingLeft: 20,
              justifyContent: "center",
            }}
          >
            <DatePicker
              name="startingTime"
              dateFormat="dd/MM/yyyy"
              locale="it"
              className={"event-custom-input-multiple"}
              selected={value}
              //filterDate={(date) => !closingDays.includes(moment(date).format("yyyy-MM-DD"))}
              onChange={(date) => onChange(date)}
              placeholderText={field.name}
              popperPlacement="bottom-start"
            />
          </div>
        );
      }
    case 8: //Data e ora
      if (fixed) {
        return (
          <p style={styles.value}>
            {isEmpty(field.value)
              ? "-"
              : moment(field.value).format("DD/MM/yyyy, HH:mm")}
          </p>
        );
      } else {
        return (
          <div
            style={{
              ...styles.textInput,
              flex: 1,
              width: "100%",
              textAlign: "left",
              paddingLeft: 20,
              justifyContent: "center",
            }}
          >
            <DatePicker
              name="startingTime"
              locale="it"
              className={"event-custom-input-multiple"}
              selected={value}
              //filterDate={(date) => !closingDays.includes(moment(date).format("yyyy-MM-DD"))}
              onChange={(date) => onChange(date)}
              placeholderText={field.name}
              popperPlacement="bottom-start"
              showTimeSelect
              dateFormat="Pp"
            />
          </div>
        );
      }
    case 9: //Autocomplete
      if (fixed) {
        return (
          <p style={styles.value}>{isEmpty(field.value) ? "-" : field.value}</p>
        );
      } else {
        let list = getList(field.options);
        return (
          <>
            <input
              type="text"
              name="options"
              style={styles.textInput}
              value={filterNullValue(value)}
              onChange={(event) => onChange(event.target.value)}
              list="objectlist"
            />
            <datalist id="objectlist">
              {list.map((option, key) => {
                return (
                  <option
                    key={key}
                    value={option.name}
                    style={{ width: "100%" }}
                  >
                    {option.name}
                  </option>
                );
              })}
            </datalist>
          </>
        );
      }
    case 10: //CAP
      if (fixed) {
        return (
          <p style={styles.value}>{isEmpty(field.value) ? "-" : field.value}</p>
        );
      } else {
        return (
          <div style={styles.row}>
            <input
              type="text"
              name="options"
              maxLength="5"
              placeholder="CAP"
              style={{
                ...styles.textInput,
                marginRight: options.length > 0 && 5,
              }}
              value={filterNullValue(value)}
              onChange={(event) => {
                onChange(event.target.value);
                if (event.target.value.length > 4) {
                  retrieveCitiesFromZip(event.target.value);
                }
              }}
              list="citylist"
            />
            {options.length > 0 && (
              <select
                name="options"
                style={{
                  ...styles.textInput,
                  marginLeft: 5,
                  appearance: options.length < 2 && "none",
                }}
                value={filterNullValue(city)}
                onChange={(event) => setCity(event.target.value)}
              >
                {options.map((option, key) => {
                  return (
                    <option
                      key={key}
                      value={option.name}
                      style={{ width: "100%" }}
                    >
                      {option.name}
                    </option>
                  );
                })}
              </select>
            )}
          </div>
        );
      }
    case 11: //Birth year
      if (fixed) {
        return (
          <p style={styles.value}>{isEmpty(field.value) ? "-" : field.value}</p>
        );
      } else {
        return (
          <input
            type="text"
            style={styles.textInput}
            value={filterNullValue(value)}
            onChange={(event) =>
              onChange(event.target.value.replace(/[^\d]/, ""))
            }
            placeholder={field.name}
          />
        );
      }

    default:
      break;
  }
}

const getValue = (field, listLenght = 1) => {
  let index = 0;
  switch (field.type) {
    case 6:
      if (!isEmpty(field.value)) {
        index = field.options.findIndex((el) => el === field.value);
      } else {
        if (field.mandatory === true) {
          index = field.options.findIndex((el) => el === field.default);
        } else {
          if (field.options[0] !== "-") {
            field.options = ["-", ...field.options];
          }
          index = 0;
        }
      }
      return field.options[index];
    case 7:
      return new Date(field.value);
    case 8:
      return new Date(field.value);
    case 9:
      let list = getList(field.options);
      index = list.findIndex((el) => {
        return el.name === field.default;
      });
      return list[index].name;
    default:
      return listLenght > 0 ? field.value : "";
  }
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Field = ({ field, addField, addHiddenFields, fixed, listLenght }) => {
  const [value, setValue] = useState(getValue(field, listLenght));
  const [options, setOptions] = useState([]);
  const [showError, setShowError] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [region, setRegion] = useState("");
  const [age, setAge] = useState("");

  const prevValue = usePrevious(value);

  useEffect(() => {
    if (listLenght <= 0) {
      setValue(getValue(field, listLenght));
      setOptions([]);
      setShowError(false);
      setIsEmail(false);
      setCity("");
      setCounty("");
      setRegion("");
      setAge("");
    }
  }, [listLenght, field]);

  const retrieveCitiesFromZip = (cap) => {
    setCity("");
    reportService.getInfoFromZipCode(cap).then((response) => {
      if (response) {
        setCounty(response.provincia);
        setRegion(response.regione);
        setOptions(
          response.comuni.map((c) => {
            return { name: c, value: cap };
          })
        );
      }
    });
  };

  useEffect(() => {
    const hiddenFields = () => {
      switch (field.type) {
        case 10:
          return [
            {
              name: "Città",
              type: -1,
              order: field.order,
              default: "",
              options: [],
              mandatory: field.mandatory,
              value: city,
            },
            {
              name: "Provincia",
              type: -1,
              order: field.order,
              default: "",
              options: [],
              mandatory: field.mandatory,
              value: county,
            },
            {
              name: "Regione",
              type: -1,
              order: field.order,
              default: "",
              options: [],
              mandatory: field.mandatory,
              value: region,
            },
          ];
        case 11:
          return [
            {
              name: "Età",
              type: -1,
              order: field.order,
              default: "",
              options: [],
              mandatory: field.mandatory,
              value: age,
            },
          ];
        default:
          return [];
      }
    };

    if (
      (field.type === 10 && city !== undefined) ||
      (field.type === 11 && age !== undefined)
    ) {
      addHiddenFields(hiddenFields());
    }
  }, [city, age, county, region, field, addHiddenFields]);

  useEffect(() => {
    if (prevValue !== value) {
      addField(value);

      if (field.type === 10) {
        if (value?.length < 5 && !isEmpty(city)) {
          setCounty("");
          setRegion("");
          setOptions([]);
          setCity("");
        }
      }
      if (field.type === 11) {
        let currentYear = new Date().getFullYear();
        if (!isEmpty(value)) {
          setAge(currentYear - parseInt(value));
        } else {
          setAge("");
        }
      }
    }
  }, [prevValue, value, field, addField, city]);

  useEffect(() => {
    if (options.length > 0) {
      setCity(options[0].name);
    }
  }, [options]);

  // useEffect(() => {
  //   console.log("FIELD", JSON.parse(JSON.stringify(field)));
  //   if (field.type === 6 && !field.mandatory && isEmpty(field.value)) {
  //     console.log("Entro qui");
  //     field.options = ["-", ...field.options];
  //     setValue(field.options[0]);
  //   }
  //   getValue(field, listLenght);
  // }, [field, listLenght]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: 5,
      }}
    >
      <p
        style={{
          margin: 10,
          marginLeft: 0,
          fontSize: 12,
          textAlign: "left",
        }}
      >
        {field.name}
        {field.mandatory && "*"}
      </p>

      {getInput(
        field,
        value,
        setValue,
        options,
        retrieveCitiesFromZip,
        city,
        setCity,
        setShowError,
        setIsEmail,
        showError,
        isEmail,
        fixed
      )}
    </div>
  );
};

export default Field;
