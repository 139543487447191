import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../contexts";
import {
  eventsService,
  settingsService,
  reservationsService,
} from "../../../_services";
import ReactLoading from "react-loading";
import ArchiveCategory from "./archiveCategory";
import ArchiveEventBar from "./archiveEventBar";
import moment from "moment";
import PopupManager from "./popupManager";

const ArchiveManager = ({ history, setIndex }) => {
  const colors = useContext(UserContext)?.colors;
  const [categoriesList, setCategoriesList] = useState([]); // list of categories
  const [neatCategoriesList, setNeatCategoriesList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [event, setEvent] = useState(null);
  const [isCopying, setIsCopying] = useState(false);
  const [closingDays, setClosingDays] = useState([]);
  const [categorySelected, setCategorySelected] = useState(null);
  const [showDeleteCategory, setShowDeleteCategory] = useState(false);
  const [showRecoverCategory, setShowRecoverCategory] = useState(false);
  const [operation, setOperation] = useState(null);
  const [operationResult] = useState(true); //outcome of CRUD operations
  const [endOperation, setEndOperation] = useState(false); //if end operation popup is shown or not

  useEffect(() => {
    const navigate = async () => {
      history.push("/");
    };

    const getEvents = () => {
      eventsService
        .getPastEvents()
        .then((categoriesList) => {
          setCategoriesList(categoriesList);
          let tempList = [];
          for (let i = 0; i < categoriesList.length; i++) {
            let temp = {};
            temp.id = categoriesList[i].id;
            temp.name = categoriesList[i].nome_categoria;
            tempList.push(temp);
          }
          setNeatCategoriesList(tempList);
          setLoader(false);
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            navigate();
          }
        });
    };

    const getClosingDays = (date) => {
      eventsService
        .getClosingDay(date)
        .then((closingDays) => setClosingDays(closingDays))
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            navigate();
          }
        });
    };

    getEvents();
    getClosingDays(moment(new Date()).format("yyyy-MM-DD"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeInfo = () => {
    setEvent(null);
  };

  const downloadStats = (id) => {
    let currentEvent = JSON.parse(JSON.stringify(event));
    setEvent(null);
    setLoader(true);
    reservationsService
      .getResDoc(id)
      .then((file) => {
        setLoader(false);
        var a = document.createElement("a");
        a.href = file.url;
        a.target = "_blank";
        a.download = "statistiche.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
        setEvent(currentEvent);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  const toggleCopy = (eventChosen) => {
    if (event === eventChosen) {
      setEvent(null);
      setIsCopying(false);
    } else {
      setEvent(eventChosen);
      setIsCopying(true);
    }
  };

  const toggleInfo = (eventChosen) => {
    setIsCopying(false);
    if (event === eventChosen) {
      closeInfo();
    } else {
      setEvent(eventChosen);
    }
  };

  const onAddSchema = (
    nome_evento,
    titolo_email,
    luogo_evento,
    posti_totali,
    descrizione_evento,
    categoria_evento,
    gestione_esterna,
    link_esterno,
    ora_inizio,
    ora_fine,
    giorni_ripetizione,
    flag_bambini,
    prenotabile,
    colore,
    ticketsType,
    prenotabile_card,
    pathToUpload,
    uniqueEvent,
    eventImage,
    ticketsList,
    flag_attesa
  ) => {
    setLoader(true);
    setEvent(null);

    eventsService
      .createEvent(
        nome_evento,
        titolo_email,
        luogo_evento,
        parseInt(posti_totali),
        descrizione_evento,
        categoria_evento,
        gestione_esterna,
        link_esterno,
        ora_inizio,
        ora_fine,
        giorni_ripetizione,
        flag_bambini,
        prenotabile,
        prenotabile_card,
        colore,
        ticketsType,
        uniqueEvent,
        eventImage,
        flag_attesa
      )
      .then((newCategoriesList) => {
        let selectedEvent;
        for (let i = 0; i < newCategoriesList.length; i++) {
          let temp = newCategoriesList[i].lista_eventi.find(
            (event) =>
              event.nome_evento === nome_evento &&
              new Date(event.ora_inizio).toUTCString() ===
                new Date(ora_inizio).toUTCString()
          );
          if (temp !== undefined) {
            selectedEvent = temp;
          }
        }
        if (pathToUpload) {
          uploadImg(selectedEvent.id, pathToUpload, true, categoria_evento);
        }

        if (ticketsList.length > 0) {
          ticketsList.forEach((ticket) => {
            onAddTicketsType(
              ticket.nome,
              ticket.prezzo,
              ticket.disponibilita,
              selectedEvent.id
            );
          });
        }
        setIndex(0);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          history.push("/");
        }
      });
  };

  const onModifySchema = (
    id,
    nome_evento,
    titolo_email,
    luogo_evento,
    posti_totali,
    descrizione_evento,
    categoria_evento,
    gestione_esterna,
    link_esterno,
    ora_inizio,
    ora_fine,
    giorni_ripetizione,
    flag_bambini,
    prenotabile,
    colore,
    ticketsType,
    prenotabile_card,
    flag_attesa,
    pathToUpload
  ) => {
    setLoader(true);
    setEvent(null);
    setOperation(1);
    eventsService
      .modifyEvent(
        id,
        nome_evento,
        titolo_email,
        luogo_evento,
        parseInt(posti_totali),
        descrizione_evento,
        categoria_evento,
        gestione_esterna,
        link_esterno,
        ora_inizio,
        ora_fine,
        giorni_ripetizione,
        flag_bambini,
        prenotabile,
        colore,
        prenotabile_card,
        ticketsType,
        flag_attesa
      )
      .then(() => {
        eventsService.getPastEvents().then((newCategoriesList) => {
          // update archived event image
          if (pathToUpload) {
            uploadImg(id, pathToUpload, true, categoria_evento);
          }

          if (ticketsType.length > 0) {
            // update archived event ticket list
            eventsService.getTicketsType(id).then((response) => {
              // only modified tickets are managed
              var tempOldList = [...response];
              var tempNewList = [...ticketsType];
              tempNewList.forEach((newTicket) => {
                var toCheck = tempOldList.filter(
                  (x) => x.id === newTicket.id
                )[0];
                if (
                  toCheck !== undefined &&
                  (toCheck.nome !== newTicket.nome ||
                    toCheck.prezzo !== newTicket.prezzo)
                ) {
                  eventsService.modifyTicketsType({
                    nome: newTicket.nome,
                    prezzo: newTicket.prezzo,
                    disponibilita: newTicket.disponibilita,
                    id: toCheck.id,
                  });
                }
              });

              setCategoriesList(newCategoriesList);
              setEndOperation(true);
              setLoader(false);
            });
          }
        });
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          history.push("/");
        }
      });
  };

  const uploadImg = (id, img, isCreation, eventCategory = null) => {
    const formData = new FormData();
    formData.append("files", img);
    formData.append("refId", id);
    formData.append("ref", "schema_eventi");
    formData.append("field", "immagine_evento");

    settingsService
      .upload(formData)
      .then((event) => {
        eventsService.getEventCategories().then((categoriesList) => {
          let selectedEvent;
          for (let i = 0; i < categoriesList.length; i++) {
            let temp = categoriesList[i].lista_eventi.find(
              (event) => event.id === id
            );
            if (temp !== undefined) {
              selectedEvent = temp;
            }
          }
          setCategoriesList(categoriesList);
          if (event) {
            setEvent(selectedEvent);
          }
          let tempList = [];
          for (let i = 0; i < categoriesList.length; i++) {
            let temp = {};
            temp.id = categoriesList[i].id;
            temp.name = categoriesList[i].nome_categoria;
            tempList.push(temp);
          }
          setNeatCategoriesList(tempList);
          setLoader(false);
        });
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          history.push("/");
        }
      });
  };

  const onAddTicketsType = (nome, prezzo, disponibilita, id_evento) => {
    eventsService
      .createTicketsType(nome, prezzo, disponibilita, id_evento)
      .then((response) => {})
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
        }
      });
  };

  const onDeleteCategory = () => {
    let id = categorySelected.id;
    eventsService
      .deleteEventCategory(id)
      .then((newCategoriesList) => {
        setShowDeleteCategory(false);
        setCategoriesList(newCategoriesList);
        toggleDeleteCategory(null);
        setCategorySelected(null);
        setEndOperation(true);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          history.push("/");
        }
      });
  };

  const onRecoverCategory = () => {
    let id = categorySelected.id;
    eventsService
      .enableEventCategory(id)
      .then((newCategoriesList) => {
        setShowRecoverCategory(false);
        setCategoriesList(newCategoriesList);
        toggleRecoverCategory(null);
        setCategorySelected(null);
        setEndOperation(true);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          history.push("/");
        }
      });
  };

  const toggleDeleteCategory = (cat) => {
    setCategorySelected(cat);
    setShowDeleteCategory(!showDeleteCategory);
  };

  const toggleRecoverCategory = (cat) => {
    setCategorySelected(cat);
    setShowRecoverCategory(!showRecoverCategory);
  };

  const toggleEndOperation = () => {
    setEndOperation(!endOperation);
  };

  useEffect(() => {
    if (showDeleteCategory) {
      setOperation(2);
    }
  }, [showDeleteCategory]);

  useEffect(() => {
    if (showRecoverCategory) {
      setOperation(8);
    }
  }, [showRecoverCategory]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    mainView: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      overflowX: "scroll",
    },
  };

  return (
    <div style={styles.container}>
      {loader ? (
        <ReactLoading
          type={"spinningBubbles"}
          color={colors?.primary}
          height={50}
          width={50}
        />
      ) : (
        <div style={styles.mainView}>
          {categoriesList.length > 0 &&
            categoriesList
              .sort((a, b) => {
                const nameA = a.nome_categoria.toUpperCase();
                const nameB = b.nome_categoria.toUpperCase();
                if (nameA > nameB) {
                  return 1;
                }
                if (nameA < nameB) {
                  return -1;
                }
                return 0;
              })
              .map((cat, key) => {
                return (
                  <ArchiveCategory
                    key={key}
                    toggleCopy={(event) => toggleCopy(event)}
                    onDelete={() => toggleDeleteCategory(cat)}
                    onRecover={() => toggleRecoverCategory(cat)}
                    category={cat}
                    eventsList={cat.lista_eventi}
                    toggleInfo={(event) => toggleInfo(event)}
                    colors={colors}
                  />
                );
              })}
        </div>
      )}
      <PopupManager
        showDeleteCategory={showDeleteCategory}
        showRecoverCategory={showRecoverCategory}
        onDeleteCategory={onDeleteCategory}
        onRecoverCategory={onRecoverCategory}
        categorySelected={categorySelected}
        toggleDeleteCategory={toggleDeleteCategory}
        toggleRecoverCategory={toggleRecoverCategory}
        operation={operation}
        endOperation={endOperation}
        toggleEndOperation={toggleEndOperation}
        operationResult={operationResult}
        colors={colors}
      />
      {event !== null && (
        <ArchiveEventBar
          event={event}
          closeInfo={closeInfo}
          colors={colors}
          onDownloadStats={downloadStats}
          isCopying={isCopying}
          categories={neatCategoriesList}
          onCreateSchema={onAddSchema}
          closingDays={closingDays}
          addTickets={false}
          onModifySchema={onModifySchema}
        />
      )}
    </div>
  );
};

export default ArchiveManager;
