import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { OrgContext, UserContext } from "../../../contexts";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { reportService } from "../../../_services";
import ReactLoading from "react-loading";
import NoFiltersBox from "../boxes/noFiltersBox";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const InteractionsTimeChart = (props) => {
  const org = useContext(OrgContext)?.org;
  const colors = useContext(UserContext)?.colors;

  const [loader, setLoader] = useState(false);

  const prevTemporalFilters = usePrevious(props.temporalFilters);

  const [firstLoaded, setFirstLoaded] = useState(false);

  //The render options of the chart. These can be changed as explained in chart.js doc
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      yAxis: {
        ticks: {
          callback: function (value) {
            if (value % 1 === 0) {
              return value;
            }
            return "";
          },
        },
      },
    },
  };

  //The structure of the chart's data. Labels is the array of the identifiers of each dataset, while label identifies the meaning of the data shown
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderWidth: 1,
      },
    ],
  });

  const generatePastelColor = () => {
    let R = Math.floor(Math.random() * 127 + 127);
    let G = Math.floor(Math.random() * 127 + 127);
    let B = Math.floor(Math.random() * 127 + 127);

    let rgb = (R << 16) + (G << 8) + B;
    return `#${rgb.toString(16)}`;
  };

  const getGraphStats = useCallback(() => {
    setLoader(true);
    reportService
      .getSectionTimes(
        org,
        props.temporalFilters.start,
        props.temporalFilters.end
      )
      .then((data) => {
        var labels = [];
        var dataList = [];
        var backgroundColor = [];

        for (const key in data.labels) {
          labels.push(data.labels[key]);
          dataList.push(data.values[data.labels[key]]);
          backgroundColor.push(generatePastelColor());
        }

        var datasets = [
          {
            data: dataList,
            backgroundColor: backgroundColor,
            borderWidth: 1,
          },
        ];

        setData({ labels, datasets });
        setLoader(false);
      });
  }, [org, props.temporalFilters.end, props.temporalFilters.start]);

  useEffect(() => {
    if (
      (props.temporalFilters !== prevTemporalFilters &&
        prevTemporalFilters !== undefined) ||
      !firstLoaded
    ) {
      getGraphStats();
      if (!firstLoaded) setFirstLoaded(true);
    }
  }, [props.temporalFilters, prevTemporalFilters, firstLoaded, getGraphStats]);

  return (
    <NoFiltersBox
      title={"Tempo medio di fruizione per sezione"}
      // maintainAspectRatio: false gives the opportunity to handle the chart's dimension
      body={
        loader ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "320px",
              paddingBottom: "320px",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={colors?.primary}
              height={50}
              width={50}
            />
          </div>
        ) : (
          <Bar
            data={data}
            options={{ ...options, maintainAspectRatio: false }}
            height={"700%"}
          />
        )
      }
      isMean={false}
    />
  );
};

export default InteractionsTimeChart;
