import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../contexts";
import { LanguageContext } from "../../containers/language";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import arrow from "../../img/arrow_back_dark.png";
import modify from "../../img/modify_black.png";
import deleteIcon from "../../img/delete_darkgray.png";
// import upload from "../../img/uploadimg_black.png";
// import placeholderImg from "../../img/placeholder.png";
import { activitiesService } from "../../_services/activities.service";
// import { settingsService } from "../../_services";
import ReactLoading from "react-loading";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { reservationsService } from "../../_services";
import EndOperation from "../../components/popup/endOperation";
import DeleteSpace from "../../components/popup/deleteSpace";
const localizer = momentLocalizer(moment);

const minTime = new Date();
minTime.setHours(8, 0, 0);
const maxTime = new Date();
maxTime.setHours(20, 0, 0);

const SpacePage = (props) => {
  const colors = useContext(UserContext)?.colors;
  const { dictionary } = useContext(LanguageContext);
  const [tabIndex, setTabIndex] = useState(0);
  var space = JSON.parse(JSON.stringify(props.space));
  // const [tmpImage, setTmpImage] = useState(null);
  // const [fileUploaded, setFileUploaded] = useState(null)
  const [newName, setNewName] = useState(space.nome);
  const [newDescription, setNewDescription] = useState(space.descrizione || "");
  const [descriptionLength, setDescriptionLength] = useState(
    space.descrizione?.length || 1
  );
  const [newPosition, setNewPosition] = useState(space.posizione || "");
  const [newCapacity, setNewCapacity] = useState(space.capienza || "");

  //We intialiaze this as the isCreation bool because the page's appereance during the creation is the same as while modifying a speaker
  const [isEditing, setIsEditing] = useState(props.isCreation);

  const isCreation = props.isCreation;
  //Bool used during creation to check that all required fields have been compiled
  const [notFilled, setNotFilled] = useState(false);

  const [loader, setLoader] = useState(false);

  const [height, setHeight] = useState(window.innerHeight);
  const [view, setView] = useState("month");
  const [dateSelected, setDateSelected] = useState(new Date());
  const [reservations, setReservations] = useState([]);
  // const hiddenFileInput = React.useRef(null);

  // const handleClick = (e) => {
  //     hiddenFileInput.current.click();
  // };

  // const handleChange = (e) => {
  //     const tmpFileUploaded = e.target.files[0];
  //     setFileUploaded(tmpFileUploaded);
  //     setTmpImage(URL.createObjectURL(tmpFileUploaded));
  // };

  //Flag used to show the popup for confirming the delete of the speaker
  const [deleteSpace, setDeleteSpace] = useState(false);

  //Bool used to show the result of operations
  const [endOperation, setEndOperation] = useState(false);

  const CustomToolbar = (toolbar) => {
    const styles = {
      toolbarContainer: {
        margin: 20,
      },
      button: {
        marginLeft: 20,
        marginRight: 20,
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        fontSize: 20,
        cursor: "pointer",
      },
      row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        margin: 0,
        visibility: view === "month" && "hidden",
      },
      back: {
        fontSize: 10,
        marginLeft: 5,
        cursor: "pointer",
      },
      buttonBack: {
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        fontSize: 20,
        cursor: "pointer",
      },
    };

    const goToBack = () => {
      if (view === "month") {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      } else if (view === "day") {
        toolbar.date.setDate(toolbar.date.getDate() - 1);
      }

      toolbar.onNavigate("prev");
    };

    const goToNext = () => {
      if (view === "month") {
        toolbar.date.setDate(1);
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      } else if (view === "day") {
        toolbar.date.setDate(toolbar.date.getDate() + 1);
      }
      toolbar.onNavigate("next");
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span>
          {view === "day" && <b>{date.format("DD")} </b>}
          <b>{date.format("MMMM")}</b>
          <b> {date.format("YYYY")}</b>
        </span>
      );
    };

    return (
      <>
        <div style={styles.row}>
          <button style={styles.buttonBack} onClick={() => setView("month")}>
            &#8249;
          </button>
          <p style={styles.back} onClick={() => setView("month")}>
            Torna al calendario
          </p>
        </div>
        <div style={styles.toolbarContainer}>
          <button style={styles.button} onClick={goToBack}>
            &#8249;
          </button>
          <label>{label()}</label>
          <button style={styles.button} onClick={goToNext}>
            &#8250;
          </button>
        </div>
      </>
    );
  };

  const createSpace = () => {
    if (newName !== "" && newName !== null) {
      space.nome = newName;
    }

    if (newDescription !== "" && newDescription !== null) {
      space.descrizione = newDescription;
    }

    if (newCapacity !== "" && newCapacity !== null) {
      space.capienza = newCapacity;
    }

    if (newPosition !== "" && newPosition !== null) {
      space.posizione = newPosition;
    }

    setLoader(true);
    activitiesService
      .createSpace(space)
      .then(() => {
        // if(fileUploaded){
        //     const formData = new FormData();
        //     formData.append("files", fileUploaded);
        //     formData.append("refId", space.id);
        //     formData.append("ref", "spazi");
        //     formData.append("field", "foto");

        //     settingsService
        //         .upload(formData)
        //         .then(() => {
        //             setLoader(false)
        //             props.setOpenRecord(null)
        //         })
        // }
        // else{
        setLoader(false);
        props.getSpaces();
        props.setOpenRecord(null);
        props.setIsCreation(false);
        // }
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  const updateSpace = () => {
    if (newName !== "" && newName !== null) {
      space.nome = newName;
    }

    if (newDescription !== "" && newDescription !== null) {
      space.descrizione = newDescription;
    }

    if (newCapacity !== "" && newCapacity !== null) {
      space.capienza = newCapacity;
    }

    if (newPosition !== "" && newPosition !== null) {
      space.posizione = newPosition;
    }

    setLoader(true);
    activitiesService.updateSpace(space).then(() => {
      // if(fileUploaded){
      //     const formData = new FormData();
      //     formData.append("files", fileUploaded);
      //     formData.append("refId", space.id);
      //     formData.append("ref", "spazi");
      //     formData.append("field", "foto");

      //     settingsService
      //         .upload(formData)
      //         .then(() => {
      //             setLoader(false)
      //             props.setOpenRecord(null)
      //         })
      // }
      // else{
      setLoader(false);
      props.getSpaces();
      props.setOpenRecord(null);
      // }
    });
  };

  useEffect(() => {
    const getSpaceReservations = (date, space) => {
      reservationsService
        .getActivitiesResPerSpace(date, space)
        .then((response) => {
          let temp = [];
          response.forEach((act) => {
            if (
              act.speaker_feedback !== "refused" &&
              act.org_feedback !== "refused"
            ) {
              temp.push(act);
            }
          });
          setReservations(temp);
        });
    };

    if (!props.isCreation) {
      getSpaceReservations(dateSelected, props.space.id);
    }
  }, [props, dateSelected]);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newHeight = window.innerHeight;
      setHeight(newHeight);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const toggleDeleteSpace = () => {
    setDeleteSpace(!deleteSpace);
  };

  const toggleEndOperation = () => {
    setEndOperation(!endOperation);
  };

  const handleDelete = () => {
    activitiesService
      .deleteSpace(space.id)
      .then(() => {
        props.getSpaces();
        props.setIsCreation(false);
        props.setOpenRecord(null);
      })
      .catch(() => {
        setDeleteSpace(false);
        toggleEndOperation();
      });
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      overflowX: "scroll",
    },
    rowTop: {
      alignItems: "start",
      color: colors?.primary,
      width: "100%",
    },
    rowBottom: {
      display: "flex",
      justifyContent: "center",
      width: "90%",
      overflow: "scroll",
    },
    subcontainer: {
      width: "100%",
      paddingBottom: 20,
      paddingTop: 20,
    },
    goBackButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    arrow: {
      height: 13,
      objectFit: "contain",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      paddingBottom: "50px",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "center",
    },
    avatar: {
      backgroundColor: colors.gray,
      width: "250px",
      height: "250px",
    },
    label: {
      color: colors?.primary,
      fontSize: 15,
      textAlign: "left",
    },
    textInput: {
      outline: "none",
      padding: 10,
      textAlign: "left",
      width: "93%",
      border: "1px solid lightgray",
      borderRadius: 5,
    },
    saveButton: {
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: colors.darkgray,
      margin: 30,
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 15,
    },
  };

  return (
    <div style={styles.container}>
      {deleteSpace && (
        <DeleteSpace
          name={newName}
          closeModal={toggleDeleteSpace}
          saveData={() => handleDelete()}
          colors={colors}
        />
      )}
      {endOperation && (
        <EndOperation
          flag={false}
          operation={2}
          closeModal={toggleEndOperation}
          colors={colors}
        />
      )}
      <div style={styles.body}>
        {!isCreation && (
          <div style={styles.rowTop}>
            <Tabs
              value={tabIndex}
              variant="fullWidth"
              TabIndicatorProps={{ style: { background: colors?.secondary } }}
              onChange={(event, newValue) => {
                setIsEditing(false);
                setTabIndex(newValue);
              }}
            >
              <Tab label={"Info"} />
              <Tab label={"Calendario"} />
            </Tabs>
          </div>
        )}
        <div style={styles.rowBottom}>
          {tabIndex === 0 ? (
            <div style={styles.subcontainer}>
              <div style={{ ...styles.row, justifyContent: "space-between" }}>
                <button
                  style={styles.goBackButton}
                  onClick={() => {
                    props.setIsCreation(false);
                    props.setOpenRecord(null);
                  }}
                  disabled={loader}
                >
                  <img src={arrow} style={styles.arrow} alt={"Go back"} />
                  <p
                    style={{
                      fontSize: 12,
                      color: colors.darkgray,
                      margin: 0,
                      marginLeft: 5,
                      fontWeight: "600",
                    }}
                  >
                    Indietro
                  </p>
                </button>
                {!isEditing && (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <button
                      style={styles.goBackButton}
                      onClick={() => setIsEditing(!isEditing)}
                    >
                      <img src={modify} style={styles.arrow} alt={"Go back"} />
                      <p
                        style={{
                          fontSize: 12,
                          color: colors.darkgray,
                          margin: 0,
                          marginLeft: 5,
                          fontWeight: "600",
                        }}
                      >
                        Modifica
                      </p>
                    </button>
                    <button
                      style={styles.goBackButton}
                      onClick={() => setDeleteSpace(!deleteSpace)}
                    >
                      <img
                        src={deleteIcon}
                        style={styles.arrow}
                        alt={"Delete"}
                      />
                      <p
                        style={{
                          fontSize: 12,
                          color: colors.darkgray,
                          margin: 0,
                          marginLeft: 5,
                          fontWeight: "600",
                        }}
                      >
                        Elimina
                      </p>
                    </button>
                  </div>
                )}
              </div>
              <div style={styles.row}>
                <div style={styles.column}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 3,
                      }}
                    >
                      <p style={styles.label}>{dictionary.name}</p>
                      <input
                        type="text"
                        name="spaceName"
                        placeholder="Nome"
                        value={newName}
                        disabled={!isEditing}
                        onChange={(event) => {
                          setNewName(event.target.value);
                        }}
                        style={styles.textInput}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 3,
                      }}
                    >
                      <p style={styles.label}>{dictionary.capacity}</p>
                      <input
                        type="number"
                        name="spaceCapacity"
                        placeholder="Capienza"
                        value={newCapacity}
                        disabled={
                          !isEditing || space.lista_prenotazioni?.length > 0
                        }
                        onChange={(event) => {
                          if (event.target.value >= 0) {
                            setNewCapacity(event.target.value);
                          }
                        }}
                        style={styles.textInput}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div style={styles.row}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <p style={styles.label}>{dictionary.description}</p>
                  <textarea
                    value={newDescription}
                    disabled={!isEditing}
                    placeholder="Descrizione"
                    onChange={(event) => {
                      setNewDescription(event.target.value);
                      setDescriptionLength(event.target.value.length);
                    }}
                    style={{
                      ...styles.textInput,
                      width: "93%",
                      resize: "none",
                      fontFamily: "inherit",
                    }}
                    rows={Math.ceil(descriptionLength / 100 + 1)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <p style={styles.label}>{dictionary.position}</p>
                  <textarea
                    value={newPosition}
                    disabled={!isEditing}
                    placeholder="Come raggiungerlo"
                    onChange={(event) => {
                      setNewPosition(event.target.value);
                    }}
                    style={{
                      ...styles.textInput,
                      width: "93%",
                      resize: "none",
                      fontFamily: "inherit",
                    }}
                    rows={Math.ceil(descriptionLength / 100 + 1)} //We use descriptionLength so that they match height
                  />
                </div>
              </div>
              {/* <div style={{...styles.row}}>
                                    <div style={{width: "50%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                                        <Carousel
                                            swipeable
                                            emulateTouch
                                            infiniteLoop
                                        >
                                            {space.foto !== null ? (
                                                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                        <img src={placeholderImg}/>
                                                    </div>
                                                )   : (
                                                    space.foto.map((photo, key) => {
                                                        return( <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                                                    <img src={photo.url}/>
                                                                </div>)
                                                            })
                                                )
                                            }
                                        </Carousel>
                                    </div>
                                </div> */}
              {isEditing && (
                <div style={{ ...styles.column, alignItems: "center" }}>
                  {notFilled && (
                    <p style={{ ...styles.label, color: colors?.highlight }}>
                      {dictionary.notFilledSpace}
                    </p>
                  )}
                  <div style={styles.row}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {!loader && (
                        <button
                          style={{
                            ...styles.saveButton,
                            backgroundColor: colors.darkgray,
                          }}
                          onClick={() => {
                            var tmpNotFilled = notFilled;

                            if (
                              isCreation &&
                              (newName === "" || newCapacity === "")
                            ) {
                              tmpNotFilled = true;
                              setNotFilled(true);
                            }
                            if (!tmpNotFilled) {
                              if (isCreation) {
                                createSpace();
                              } else {
                                updateSpace();
                              }
                            }
                          }}
                          disabled={isCreation && notFilled}
                        >
                          <p
                            style={{
                              fontSize: 12,
                              color: colors.white,
                              margin: 0,
                              fontWeight: "600",
                            }}
                          >
                            {dictionary.save}
                          </p>
                        </button>
                      )}
                      {loader && (
                        <ReactLoading
                          type={"spinningBubbles"}
                          color={colors?.primary}
                          height={50}
                          width={50}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div style={styles.subcontainer}>
              <div
                style={{
                  ...styles.row,
                  justifyContent: "space-between",
                  paddingBottom: "",
                }}
              >
                <button
                  style={styles.goBackButton}
                  onClick={() => {
                    props.setIsCreation(false);
                    props.setOpenRecord(null);
                  }}
                  disabled={loader}
                >
                  <img src={arrow} style={styles.arrow} alt={"Go back"} />
                  <p
                    style={{
                      fontSize: 12,
                      color: colors.darkgray,
                      margin: 0,
                      marginLeft: 5,
                      fontWeight: "600",
                    }}
                  >
                    Indietro
                  </p>
                </button>
              </div>
              <Calendar
                date={moment(dateSelected).toDate()}
                localizer={localizer}
                events={reservations}
                titleAccessor={(event) => {
                  if (view === "day") {
                    return (
                      event.attivita?.nome +
                      " - " +
                      event.relatori.name +
                      " " +
                      event.relatori.surname
                    );
                  }
                  return event.attivita?.nome;
                }}
                startAccessor={(event) => {
                  return new Date(event.datetime_inizio);
                }}
                endAccessor={(event) => {
                  return new Date(event.datetime_fine);
                }}
                style={{ height: height * 0.8 }}
                components={{
                  toolbar: CustomToolbar,
                }}
                view={view}
                defaultView={view}
                min={minTime}
                max={maxTime}
                onNavigate={(date) => setDateSelected(new Date(date))}
                popup={true}
                onSelectEvent={(event) => {
                  if (view === "month") {
                    setView("day");
                  }
                  setDateSelected(event.datetime_inizio);
                  //setAvailabilitySelected(event);
                }}
                dayPropGetter={(date) => {
                  let dayStyle = {
                    backgroundColor:
                      moment(date).add(1, "days") < moment()
                        ? colors?.verylightgray
                        : "transparent",
                  };
                  return {
                    className: "",
                    style: dayStyle,
                  };
                }}
                eventPropGetter={(event, start, end, isSelected) => {
                  let newStyle = {
                    backgroundColor: event.relatori.color,
                    color: "black",
                    borderRadius: 5,
                    outline: "none",
                    border: isSelected ? "8px solid" : "8px solid",
                    borderColor: event.relatori.color,
                    fontSize: 13,
                  };
                  return {
                    className: "",
                    style: newStyle,
                  };
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SpacePage;
