import React, { useState, useContext } from "react";
import Daytime from "./dayTime";
import pencil from "./../img/modify_gray.png";
import { UserContext } from "../contexts";

const Timetable = ({ timetable, save, amIAdmin }) => {
  const colors = useContext(UserContext)?.colors;

  const [monday, setMonday] = useState(timetable.lunedi);
  const [tuesday, setTuesday] = useState(timetable.martedi);
  const [wednesday, setWednesday] = useState(timetable.mercoledi);
  const [thursday, setThursday] = useState(timetable.giovedi);
  const [friday, setFriday] = useState(timetable.venerdi);
  const [saturday, setSaturday] = useState(timetable.sabato);
  const [sunday, setSunday] = useState(timetable.domenica);
  const [edit, setEdit] = useState(false);

  const styles = {
    timetableSection: {
      marginBottom: 30,
      width: "30%",
      marginLeft: 20,
      marginRight: 20,
    },
    header: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    labelTable: {
      marginBottom: 15,
      fontSize: 14,
      color: colors?.lightgray,
      textAlign: "left",
      display: "flex",
      flexDirection: edit ? "column" : "row",
      alignItems: "center",
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: colors?.lightgray,
      cursor: "pointer",
      borderRadius: 15,
      padding: "11px",
      marginTop: "20px",
    },
  };

  React.useEffect(() => {
    setMonday(timetable.lunedi);
    setTuesday(timetable.martedi);
    setWednesday(timetable.mercoledi);
    setThursday(timetable.giovedi);
    setFriday(timetable.venerdi);
    setSaturday(timetable.sabato);
    setSunday(timetable.domenica);
  }, [timetable]);

  return (
    <div style={styles.timetableSection}>
      <div style={styles.header}>
        <div style={styles.labelTable}>
          Orari museo
          {amIAdmin === true && !edit && (
            <img
              alt="Modify"
              src={pencil}
              style={{
                width: 15,
                objectFit: "contain",
                marginLeft: 15,
                cursor: "pointer",
              }}
              onClick={() => {
                setEdit(!edit);
              }}
            />
          )}
          {amIAdmin && edit && (
            <button
              style={styles.button}
              onClick={() => {
                monday.day_of_week = 1;
                tuesday.day_of_week = 2;
                wednesday.day_of_week = 3;
                thursday.day_of_week = 4;
                friday.day_of_week = 5;
                saturday.day_of_week = 6;
                sunday.day_of_week = 0;
                let temp = {
                  lunedi: monday,
                  martedi: tuesday,
                  mercoledi: wednesday,
                  giovedi: thursday,
                  venerdi: friday,
                  sabato: saturday,
                  domenica: sunday,
                };
                save(temp);
                setEdit(!edit);
              }}
            >
              Salva
            </button>
          )}
        </div>
      </div>
      <Daytime
        day={"Lunedì"}
        timetable={monday}
        save={setMonday}
        colors={colors}
        edit={edit}
      />
      <Daytime
        day={"Martedì"}
        timetable={tuesday}
        save={setTuesday}
        colors={colors}
        edit={edit}
      />
      <Daytime
        day={"Mercoledì"}
        timetable={wednesday}
        save={setWednesday}
        colors={colors}
        edit={edit}
      />
      <Daytime
        day={"Giovedì"}
        timetable={thursday}
        save={setThursday}
        colors={colors}
        edit={edit}
      />
      <Daytime
        day={"Venerdì"}
        timetable={friday}
        save={setFriday}
        colors={colors}
        edit={edit}
      />
      <Daytime
        day={"Sabato"}
        timetable={saturday}
        save={setSaturday}
        colors={colors}
        edit={edit}
      />
      <Daytime
        day={"Domenica"}
        timetable={sunday}
        save={setSunday}
        colors={colors}
        edit={edit}
      />
    </div>
  );
};

export default Timetable;
