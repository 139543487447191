import React, { useState, useEffect } from "react";
import SubButton from "../../components/buttons/subButton";
import cards from "./../../img/cards.png";
import add from "./../../img/add.png";
import activate from "./../../img/activate.png";
import doc from "./../../img/document.png";
//import newsletter from "./../../img/newsletter.png";
//import statistics from "./../../img/statistics.png";

const CardsBar = ({
  colors,
  isNetwork,
  selection,
  setSelection,
  cardTypes,
  permissions,
}) => {
  const [hasCards, setHasCards] = useState(false);

  useEffect(() => {
    if (cardTypes.length > 0) {
      setHasCards(true);
    }
  }, [cardTypes]);

  const styles = {
    sideBar: {
      width: 200,
      backgroundColor: colors?.darkgray,
      height: "100%",
      boxShadow: "2px 0 4px 0 rgb(0 0 0 / 20%)",
    },
  };

  return (
    <div style={styles.sideBar}>
      {permissions?.tutte && (
        <SubButton
          icon={cards}
          mod={{ id: 1, name: "Tutte le card" }}
          colors={colors}
          onClick={() => setSelection(1)}
          selection={selection}
          size={18}
          selectionColor={colors.gray}
          hoverColor={colors.mediumgray}
        />
      )}
      {hasCards && (
        <>
          {permissions?.nuova && (
            <SubButton
              icon={add}
              mod={{ id: 2, name: "Nuova card" }}
              colors={colors}
              onClick={() => setSelection(2)}
              selection={selection}
              size={18}
              selectionColor={colors.gray}
              hoverColor={colors.mediumgray}
            />
          )}
          {permissions?.attiva && (
            <SubButton
              icon={activate}
              mod={{ id: 3, name: "Attiva card" }}
              colors={colors}
              onClick={() => setSelection(3)}
              selection={selection}
              size={18}
              selectionColor={colors.gray}
              hoverColor={colors.mediumgray}
            />
          )}
          {permissions?.fatture && (
            <SubButton
              icon={doc}
              mod={{ id: 4, name: "Fatture" }}
              colors={colors}
              onClick={() => setSelection(4)}
              selection={selection}
              size={18}
              selectionColor={colors.gray}
              hoverColor={colors.mediumgray}
            />
          )}
          {/*<SubButton
          icon={newsletter}
          mod={{ id: 4, name: "Newsletter" }}
          colors={colors}
          onClick={() => setSelection(4)}
          selection={selection}
          size={18}
          selectionColor={colors.gray}
          hoverColor={colors.mediumgray}
        />
        <SubButton
          icon={statistics}
          mod={{ id: 5, name: "Statistiche" }}
          colors={colors}
          onClick={() => setSelection(5)}
          selection={selection}
          size={18}
          selectionColor={colors.gray}
          hoverColor={colors.mediumgray}
        />*/}
        </>
      )}
    </div>
  );
};

export default CardsBar;
