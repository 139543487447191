import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { OrgContext, UserContext } from "../../../contexts";
import NoFiltersBox from "../boxes/noFiltersBox";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { reportService } from "../../../_services";
import ReactLoading from "react-loading";
import StackCustomLegend from "../legends/stackCustomLegend";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const SectionsInteractionsChart = (props) => {
  const org = useContext(OrgContext)?.org;
  const colors = useContext(UserContext)?.colors;

  const [loader, setLoader] = useState(false);

  const [hiddenDatasets, setHiddenDatasets] = useState({}); // State to track visibility of datasets

  const prevTemporalFilters = usePrevious(props.temporalFilters);

  const [firstLoaded, setFirstLoaded] = useState(false);

  //The render options of the chart. These can be changed as explained in chart.js doc
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  //The structure of the chart's data. Labels is the array of the identifiers of each dataset, while label identifies the meaning of the data shown
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        label: "",
      },
    ],
  });

  const filteredData = {
    ...data,
    datasets: data.datasets.map((dataset) => ({
      ...dataset,
      data: dataset.data.map((value, index) =>
        hiddenDatasets[dataset.label] ? null : value
      ),
    })),
  };

  const generatePastelColor = () => {
    let R = Math.floor(Math.random() * 127 + 127);
    let G = Math.floor(Math.random() * 127 + 127);
    let B = Math.floor(Math.random() * 127 + 127);

    let rgb = (R << 16) + (G << 8) + B;
    return `#${rgb.toString(16)}`;
  };

  const getGraphStats = useCallback(() => {
    setLoader(true);
    reportService
      .getInteractions(
        org,
        props.temporalFilters.start,
        props.temporalFilters.end
      )
      .then((data) => {
        var labels = data.labels;
        var dataLabels = data.interactions;
        var datasets = [];
        for (const label of dataLabels) {
          var dataList = [];
          var backgroundColor = generatePastelColor();

          for (const key in data.values) {
            var tmpValue = 0;
            for (const innerKey in data.values[key]) {
              if (innerKey.toString() === label.toString()) {
                tmpValue = data.values[key][innerKey];
              }
            }
            dataList.push(tmpValue);
          }

          var labelData = {
            label: label,
            data: dataList,
            backgroundColor: backgroundColor,
          };

          datasets.push(labelData);
        }

        setData({ labels, datasets });
        setLoader(false);
      });
  }, [org, props.temporalFilters.end, props.temporalFilters.start]);

  useEffect(() => {
    if (
      (props.temporalFilters !== prevTemporalFilters &&
        prevTemporalFilters !== undefined) ||
      !firstLoaded
    ) {
      getGraphStats();
      if (!firstLoaded) setFirstLoaded(true);
    }
  }, [props.temporalFilters, prevTemporalFilters, firstLoaded, getGraphStats]);

  return (
    <NoFiltersBox
      title={"Tipologie di interazioni per sezione"}
      // maintainAspectRatio: false gives the opportunity to handle the chart's dimension
      body={
        loader ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "320px",
              paddingBottom: "320px",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={colors?.primary}
              height={50}
              width={50}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <StackCustomLegend
                data={data}
                setHiddenDatasets={setHiddenDatasets}
              />
            </div>
            <div style={{ width: "100%" }}>
              <Bar
                data={filteredData}
                options={{ ...options, maintainAspectRatio: false }}
                height={"700%"}
              />
            </div>
          </div>
        )
      }
      isMean={false}
    />
  );
};

export default SectionsInteractionsChart;
