import React, { createContext, useState, useCallback, useMemo } from "react";
// create context
const CheckoutContext = createContext();

const CheckoutContextProvider = ({ children }) => {
  // the value that will be given to the context
  const [customProfiling, setCustomProfiling] = useState(null);
  const [ticketsList, setTicketsList] = useState([]);

  // Refresh the user status, memoized
  const updateInfo = useCallback((info) => {
    setCustomProfiling(info);
  }, []);

  const updateTicketList = useCallback((list) => {
    setTicketsList(list);
  }, []);

  // memoize the full context value
  const contextValue = useMemo(
    () => ({
      customProfiling,
      ticketsList,
      updateInfo,
      updateTicketList,
    }),
    [customProfiling, ticketsList, updateInfo, updateTicketList]
  );

  return (
    // the Provider gives access to the context to its children
    <CheckoutContext.Provider value={contextValue}>
      {children}
    </CheckoutContext.Provider>
  );
};

export { CheckoutContext, CheckoutContextProvider };
