import React, { createContext, useState, useCallback, useMemo } from "react";
import networkColors from "../networkColors";
import museumColors from "../museumColors";
import { usersService } from "../_services";
// create context
const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  // the value that will be given to the context
  const [user, setUser] = useState(null);
  const [colors, setColors] = useState(null);

  // sign out the user, memoized
  const signout = useCallback(() => {
    setUser(null);
  }, []);

  // Refresh the user status, memoized
  const fetch = useCallback(() => {
    const fetch = () => {
      usersService
        .getMe()
        .then((me) => {
          setUser(me);
          setColors(me.flag_network ? networkColors : museumColors);
        })
        .catch((error) => {
          setUser(undefined);
        });
    };

    fetch();
  }, []);

  // memoize the full context value
  const contextValue = useMemo(
    () => ({
      user,
      colors,
      signout,
      fetch,
    }),
    [user, colors, signout, fetch]
  );

  return (
    // the Provider gives access to the context to its children
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
