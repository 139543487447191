import React, { Component } from "react";
import PropTypes from "prop-types";

export default class AutoCompleteText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestions: [],
      text: "",
      locationId: "",
    };
  }

  static propTypes = {
    placeholder: PropTypes.string,
    apiKey: PropTypes.string.isRequired,
    setZip: PropTypes.func.isRequired,
    setCountry: PropTypes.func.isRequired,
  };

  onTextChanged = (e) => {
    const value = e.target.value;
    this.setState({ locationId: "", text: value });

    if (value.length > 0) {
      this.setState({ text: value });
      fetch(
        `https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?language=it&query=${value}&apiKey=${this.props.apiKey}`
      )
        .then((response) => response.json())
        .then((data) => {
          var op = data.suggestions
            .filter((o) => o.matchLevel === "city")
            .map((o) => ({
              label:
                o.address?.city +
                ", " +
                o.address?.county +
                ", " +
                o.address?.country,
              locationId: o.locationId,
              zip: o.address.postalCode,
              country: o.address?.country,
            }));

          this.setState({ suggestions: op });
        })
        .catch((err) => {
          this.setState({ suggestions: [] });
        });
    } else {
      this.setState({ suggestions: [] });
    }
  };

  renderSuggestions = () => {
    const { suggestions } = this.state;

    if (suggestions.length === 0) {
      return null;
    } else {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ul
            style={{
              width: "80%",
              padding: 0,
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              backgroundColor: this.props.colors.white,
              border: "1px solid lightgray",
              margin: 0,
              zIndex: 10,
              borderTop: 0,
            }}
          >
            {suggestions.map((item) => {
              return (
                <li
                  style={{ listStyleType: "none", margin: 15 }}
                  key={item.locationId}
                  locationid={item.locationId}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.suggestionSelected(item);
                  }}
                >
                  {item.label}
                </li>
              );
            })}
          </ul>
        </div>
      );
    }
  };

  suggestionSelected = (item) => {
    this.setState({
      text: item.label,
      locationId: item.locationId,
      suggestions: [],
    });
    this.props.setZip(item.zip);
    this.props.setCountry(item.country);
  };

  render() {
    const { text } = this.state;
    const styles = {
      menu: {
        border: "1px solid lightgray",
        backgroundColor: this.props.colors.white,
        paddingRight: 15,
        paddingLeft: 15,
        paddingTop: 10,
        paddingBottom: 10,
        color: this.props.colors.darkgray,
        borderRadius: 20,
        outline: "none",
        width: "100%",
        height: 25,
      },
      box: {
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
      },
      textInput: {
        borderRadius: 5,
        border: "1px solid lightgray",
        outline: "none",
        textAlign: "center",
        marginTop: 10,
        padding: 10,
        width: "80%",
      },
    };
    return (
      <div style={styles.box}>
        <input
          type="text"
          value={text}
          onChange={this.onTextChanged}
          placeholder={this.props.placeholder}
          style={{
            ...styles.textInput,
            height: this.props.salesHistory === true && 2,
            marginTop: this.props.salesHistory === true && 3,
          }}
          onClick={(e) => e.stopPropagation()}
        />

        {this.renderSuggestions()}
      </div>
    );
  }
}
