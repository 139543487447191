import React, { useEffect, useState, useRef, useContext } from "react";
import useScanDetection from "use-scan-detection";
import trash from "../img/delete_darkgray.png";
import { UserContext } from "../contexts";
import scanner from "../img/scannergun.png";
import add from "../img/add.png";
import ReactLoading from "react-loading";
import { vouchersService } from "../_services/vouchers.service";

const CodeEntry = ({ value, type, onDelete }) => {
  const styles = {
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: 10,
      marginBottom: 10,
    },
    button: {
      outline: "none",
      border: "none",
      backgroundColor: "transparent",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
  };
  return (
    <div style={styles.row}>
      <p>
        <b>{value}</b>
        {" ("}
        {type}
        {")"}
      </p>
      <button style={styles.button} onClick={() => onDelete(value)}>
        <img
          alt="Trash icon"
          src={trash}
          style={{ height: 15, objectFit: "contain" }}
        ></img>
      </button>
    </div>
  );
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
function isEmpty(value) {
  return value === "" || value === undefined || value === null;
}
const CodesScanner = ({ orgId, category }) => {
  const [loader, setLoader] = useState(false);
  const [code, setCode] = useState("");
  const [codesList, setCodesList] = useState([]);
  const [typesList, setTypesList] = useState([]);
  const [tmpCode, setTmpCode] = useState("");
  const [byHand, setByHand] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const prevCode = usePrevious(code);
  const colors = useContext(UserContext)?.colors;

  const styles = {
    column: {
      display: "flex",
      flexDirection: "column",
      overflow: "scroll",
      alignItems: "center",
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      margin: 20,
    },
    button: {
      backgroundColor: colors?.darkgray,
      borderRadius: 10,
      color: colors?.white,
      fontSize: 15,
      height: 50,
      width: 140,
      cursor: "pointer",
      border: "none",
      outline: "none",
      padding: 10,
    },
    transparentButton: {
      border: "none",
      backgroundColor: "transparent",
      outline: "none",
      cursor: "pointer",
    },
    scannerImg: {
      width: 200,
      objectFit: "contain",
      margin: "0",
    },
    typeContainer: {
      backgroundColor: colors?.darkBackground,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      borderRadius: 10,
      marginTop: 20,
      marginBottom: 20,
      marginLeft: 50,
      height: 70,
    },
    typeHeader: {
      backgroundColor: colors?.darkgray,
      height: 30,
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      outline: "none",
      border: "none",
      borderRadius: 10,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      paddingTop: 5,
      paddingBottom: 5,
    },
    typeTitle: {
      color: colors?.white,
      fontSize: 16,
      fontWeight: 700,
    },
    typeBody: {
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: 16,
    },
    label: {
      fontSize: 16,
      fontWeight: "700",
      marginBottom: 20,
    },
    title: {
      fontWeight: 600,
    },
    byHand: {
      color: colors.primary,
      fontStyle: "italic",
      fontSize: 14,
      marginTop: "30px",
    },
    input: {
      border: "1px solid lightgray",
      borderRadius: 10,
      width: "80%",
      padding: "10px",
      marginTop: "10px",
      marginBottom: "30px",
      outline: "none",
    },
    warning: {
      color: "red",
      fontSize: 14,
      marginTop: 40,
      width: 400,
      textAlign: "center",
    },
  };

  const resetAfterValidation = () => {
    setCode("");
    setCodesList([]);
    setTmpCode("");
    setByHand(false);
    setErrorMessage("");
  };

  const deleteCode = (value) => {
    setCode("");
    setCodesList(codesList?.filter((el) => el.code !== value));
  };

  useScanDetection({
    onComplete: (code) => {
      let ticketCode = code.replaceAll("Shift", "").replaceAll("Backspace", "");
      setCode(ticketCode);
    },
    minLength: 4, // EAN13
  });

  useEffect(() => {
    function checkIfInList(list, value) {
      return list.includes(value);
    }

    if (
      prevCode !== code &&
      !isEmpty(code) &&
      !checkIfInList(codesList, code)
    ) {
      setLoader(true);
      //check code
      vouchersService.checkCode(orgId, code, category.id).then((response) => {
        if (!response.error) {
          setErrorMessage("");
          setCodesList([
            ...codesList,
            {
              id: response.voucher.id,
              code: code,
              category: category.id,
              type: response.voucher.tipologia,
            },
          ]);
        } else {
          setErrorMessage(
            'Errore con il voucher "' +
              code.toString() +
              '"' +
              ": " +
              response.error
          );
        }
        setLoader(false);
      });
    }
  }, [category, code, prevCode, codesList, orgId]);

  useEffect(() => {
    setCodesList([]);
    setErrorMessage("");
  }, [category]);

  useEffect(() => {
    var tempList = [];
    if (codesList.length > 0) {
      codesList.forEach((item) => {
        if (tempList.length === 0) {
          tempList = [{ type: item.type, count: 1 }];
        } else {
          var found = tempList.find((temp) => temp.type === item.type);
          if (found) {
            found.count++;
          } else {
            tempList.push({ type: item.type, count: 1 });
          }
        }
      });
    }
    setTypesList(tempList);
  }, [codesList]);

  const handleCodeInsert = (newCode) => {
    setTmpCode("");
    setCode(newCode);
    setByHand(false);
  };

  const handleValidate = () => {
    setLoader(true);
    var idsList = [];
    if (codesList.length > 0) {
      codesList.forEach((item) => {
        idsList.push(item.id);
      });
    }
    vouchersService.validateVouchers(idsList).then((response) => {
      resetAfterValidation();
      setLoader(false);
    });
  };

  return loader ? (
    <div
      style={{
        display: "flex",
        marginTop: 40,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLoading
        type={"spinningBubbles"}
        color={colors.darkgray}
        height={50}
        width={50}
      />
    </div>
  ) : (
    <>
      {errorMessage !== "" && (
        <div>
          <p style={styles.warning}>{errorMessage}</p>
        </div>
      )}
      <div style={styles.container}>
        <div style={{ ...styles.column, flex: 1 }}>
          {!codesList || codesList?.length < 1 ? (
            byHand ? (
              <div style={styles.column}>
                <p style={styles.title}>Inserisci manualmente il codice</p>
                <input
                  type="text"
                  value={tmpCode}
                  onChange={(event) => {
                    setTmpCode(event.target.value);
                  }}
                  style={styles.input}
                />
                <button
                  style={{
                    ...styles.button,
                    opacity: tmpCode === "" ? 0.4 : 1,
                  }}
                  disabled={tmpCode === ""}
                  onClick={() => {
                    handleCodeInsert(tmpCode);
                  }}
                >
                  Inserisci
                </button>
                <button
                  onClick={() => setByHand(false)}
                  style={styles.transparentButton}
                >
                  <p style={styles.byHand}>Scannerizza il codice</p>
                </button>
              </div>
            ) : (
              <div style={styles.column}>
                <p style={styles.title}>Scannerizza il codice</p>
                <img
                  alt="Scanner icon"
                  src={scanner}
                  style={styles.scannerImg}
                />
                <button
                  onClick={() => setByHand(true)}
                  style={styles.transparentButton}
                >
                  <p style={styles.byHand}>
                    Non riesci a scannerizzare il codice?
                  </p>
                </button>
              </div>
            )
          ) : (
            <div style={{ ...styles.column, width: "100%" }}>
              {!byHand ? (
                <button
                  onClick={() => setByHand(true)}
                  style={styles.transparentButton}
                >
                  <p style={styles.byHand}>
                    Non riesci a scannerizzare il codice?
                  </p>
                </button>
              ) : (
                <div
                  style={{
                    ...styles.row,
                    justifyContent: "center",
                    alignItems: "center",
                    width: "50%",
                  }}
                >
                  <input
                    type="text"
                    value={tmpCode}
                    onChange={(event) => {
                      setTmpCode(event.target.value);
                    }}
                    style={{
                      ...styles.input,
                      width: "90%",
                      marginBottom: 0,
                      marginTop: 0,
                      marginRight: "20px",
                    }}
                    placeholder={"Inserisci il codice del voucher"}
                  />
                  <button
                    style={{
                      ...styles.button,
                      opacity: tmpCode === "" ? 0.4 : 1,
                      height: "90%",
                      width: "20%",
                    }}
                    disabled={tmpCode === ""}
                    onClick={() => {
                      handleCodeInsert(tmpCode);
                    }}
                  >
                    <img
                      alt="Add icon"
                      src={add}
                      style={{
                        width: 10,
                        objectFit: "contain",
                        margin: "0",
                      }}
                    />
                  </button>
                </div>
              )}

              {codesList?.length > 0 && (
                <>
                  <div style={{ marginTop: 40, width: "50%" }}>
                    <p style={styles.label}>
                      <b>
                        {codesList?.length}
                        {" codic"}
                        {codesList?.length < 2 ? "e" : "i"}
                        {" scannerizzat"}
                        {codesList?.length < 2 ? "o" : "i"}
                        {" per la categoria selezionata"}
                      </b>
                    </p>
                    <div style={{ ...styles.row, marginTop: 50 }}>
                      <div style={{ flex: 3, width: 300, marginRight: 80 }}>
                        <div>
                          {codesList?.map((result, key) => {
                            return (
                              <CodeEntry
                                key={key}
                                value={result.code}
                                type={result.type}
                                onDelete={deleteCode}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div
                        style={{
                          flex: 1,
                        }}
                      >
                        {codesList?.length > 0 &&
                          typesList?.map((res, key) => {
                            return (
                              <div key={key} style={styles.typeContainer}>
                                <div style={styles.typeHeader}>
                                  <p style={styles.typeTitle}>{res.type}</p>
                                </div>
                                <div style={styles.typeBody}>{res.count}</div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div>
                      <button
                        style={{
                          ...styles.button,
                          marginTop: 20,
                          backgroundColor: colors?.highlight,
                        }}
                        disabled={codesList?.length === 0}
                        onClick={() => {
                          handleValidate();
                        }}
                      >
                        <b>CONVALIDA</b>
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default CodesScanner;
