import React, { useEffect, useState, useContext } from "react";
import { CheckoutContext, UserContext, OrgContext } from "../../contexts";
import { itemsService, reportService } from "../../_services";
import Summary from "./summary";
import Footer from "./footer";
import moment from "moment";
import "moment/locale/it";
import ItemsList from "./itemsList";
import Profiling from "./profiling";
import EndSale from "./endSale";
import checked from "./../../img/reserved.png";
import error from "./../../img/closeButton.png";
import ReactLoading from "react-loading";
import RegisterEntrance from "./registerEntrance";
moment.locale("it");

function isEmpty(value) {
  return value === "" || value === null || value === undefined;
}

function emptyVisitor(visitor) {
  return (
    isEmpty(visitor) ||
    Object.keys(visitor).length === 0 ||
    isNaN(visitor.CAP) ||
    (isEmpty(visitor.CAP) &&
      isEmpty(visitor.codice_biglietto) &&
      isEmpty(visitor.email) &&
      isEmpty(visitor.nascita) &&
      isEmpty(visitor.sesso))
  );
}

const Checkout = (props) => {
  const [categoriesList, setCategoriesList] = useState([]); // list of categories
  const [shoppingCart, setShoppingCart] = useState([]);
  const [saleSummary, setSaleSummary] = useState([]);
  const [isTicket, setIsTicket] = useState(true);
  const [status, setStatus] = useState("list");
  const [showWarning, setShowWarning] = useState(false);
  const [isCodeNotFound, setIsCodeNotFound] = useState(false);
  const [discountApplied, setDiscountApplied] = useState(false);
  const [saleFeedback, setSaleFeedback] = useState(undefined);
  const [showFeedback, setShowFeedback] = useState(false);
  const [payment, setPayment] = useState("Contanti");
  const [membershipCard, setMembershipCard] = useState(undefined);

  const colors = useContext(UserContext)?.colors;
  const user = useContext(UserContext)?.user;
  const customProfiling = useContext(CheckoutContext)?.customProfiling;
  const updateCustomProfiling = useContext(CheckoutContext)?.updateInfo;
  const ticketsList = useContext(CheckoutContext)?.ticketsList;
  const settings = useContext(OrgContext)?.org?.settings;

  const [atLeastOneItem, setAtLeastOneItem] = useState(false);

  const [categoriesLoaded, setCategoriesLoaded] = useState(false);

  const getCategories = () => {
    setStatus("loading");
    setCategoriesLoaded(false);

    itemsService
      .getCategories()
      .then((categoriesList) => {
        //Select the first category of the list
        categoriesList.sort((a, b) => a.id - b.id);
        setCategoriesList(categoriesList);
        setCategoriesLoaded(true); //used in ItemsList to differentiate between no items and loading
        setStatus("list");
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  const preventNext = () => {
    const checkInfo = () => {
      let prevent = false;
      if (settings?.profilazione_visitatori === true) {
        for (let item of shoppingCart) {
          prevent = //TODO: remove item.categoria_articolo === "BIGLIETTI"
            (item.categoria_articolo.nome_categoria.toUpperCase() ===
              "BIGLIETTI" &&
              (!item.visitatore?.additional_info ||
                item.visitatore?.additional_info.some(
                  (field) => field.mandatory === true && isEmpty(field.value)
                ))) ||
            prevent;
        }
      } else {
        prevent = false;
      }

      return prevent;
    };

    return (
      (shoppingCart.some((item) => item.visitatore?.warning === true) ||
        checkInfo()) &&
      status === "profiling"
    );
  };

  const removeItemFromShoppingCart = (id) => {
    if (shoppingCart.filter((x) => x.id === id).length > 0) {
      const temp = [...shoppingCart];
      const keys = temp.map((el) => el.id);
      temp.splice(keys.lastIndexOf(id), 1);

      if (temp.length === 0) {
        setStatus("list");
      }

      setShoppingCart(temp);
    }
  };

  const removeAllOfItemFromShoppingCart = (id) => {
    var occurences = shoppingCart.filter((x) => x.id === id).length;
    if (occurences > 0) {
      var tempInRemove = [...shoppingCart];
      for (var i = 0; i < occurences; i++) {
        var keys = tempInRemove.map((el) => el.id);
        tempInRemove.splice(keys.lastIndexOf(id), 1);
      }

      if (tempInRemove.length === 0) {
        setStatus("list");
      }

      setShoppingCart(tempInRemove);
    }
  };

  const body = () => {
    switch (status) {
      case "list":
        return (
          <ItemsList
            categoriesList={categoriesList}
            shoppingCart={shoppingCart}
            setShoppingCart={setShoppingCart}
            setIsTicket={setIsTicket}
            categoriesLoaded={categoriesLoaded}
          />
        );
      case "profiling":
        return (
          <Profiling
            shoppingCart={shoppingCart}
            showWarning={showWarning}
            setShowWarning={setShowWarning}
            setIsCodeNotFound={setIsCodeNotFound}
          />
        );
      case "endsale":
        return (
          <EndSale
            shoppingCart={shoppingCart}
            discountApplied={discountApplied}
            setDiscountApplied={setDiscountApplied}
            payment={payment}
            setPayment={setPayment}
            membershipCard={membershipCard}
            setMembershipCard={setMembershipCard}
          />
        );
      case "registerentrance":
        return <RegisterEntrance setStatus={setStatus} />;

      case "loading":
        return (
          <div style={styles.loaderBox}>
            <ReactLoading
              type={"spinningBubbles"}
              color={colors?.primary}
              height={50}
              width={50}
            />
          </div>
        );
      default:
        break;
    }
  };

  const getFirstVisitor = (shoppingCart) => {
    let i = 0;
    for (i = 0; i < shoppingCart.length; i++) {
      if (!emptyVisitor(shoppingCart[i].visitatore)) {
        return shoppingCart[i].visitatore;
      }
    }
    return null;
  };

  const sell = () => {
    setStatus("loading");
    let visitor = getFirstVisitor(shoppingCart);
    let order = [];

    for (let obj of shoppingCart) {
      let item = {};
      item.visitatore = !isEmpty(obj.visitatore) ? obj.visitatore : visitor;
      item.articolo = obj;
      item.quantita = 1;
      item.operatore = user.username;
      item.prezzo =
        obj.prezzo_scontato >= 0 ? obj.prezzo_scontato : obj.prezzo_articolo;

      order.push(item);
    }
    itemsService
      .sell(order, payment, membershipCard?.numero)
      .then((response) => {
        setSaleFeedback(response);
        if (response) {
          setShoppingCart([]);
          getCategories();
          setStatus("list");
        } else {
          setStatus("endsale");
        }
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  const preventCreateVisitors = () => {
    return (
      !customProfiling ||
      customProfiling?.some(
        (field) => field.mandatory === true && isEmpty(field.value)
      )
    );
  };

  const createVisitors = () => {
    if (!preventCreateVisitors()) {
      setStatus("loading");
      let body = ticketsList.map((ticket) => {
        return { codice_biglietto: ticket, additional_info: customProfiling };
      });
      reportService.createVisitors(body).then((response) => {
        setSaleFeedback(response);
        if (response) {
          setStatus("list");
          updateCustomProfiling(null);
        }
      });
    }
  };

  // check if there is at least one item in the "Inventario"
  const checkAtLeastOneItem = () => {
    //set atLeastOneItem true as soon as an item is found in the "Inventario"
    let i = 0;
    for (i = 0; i < categoriesList.length; i++) {
      if (categoriesList[i].lista_articoli.length > 0) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    let c = 0; //counter to populare the array
    let found = false; //flag to check if the new item is already in the cart

    const saleSummary = shoppingCart.reduce((acc, value) => {
      // Check if the cart already contains an item with the same id
      for (let i = 0; i < c; i++) {
        if (acc[i][0].id === value.id) {
          acc[i].push(value);
          found = true;
          // if an item with this id is already in the cart, push it
          //  in the corresponding cell and do not increase the counter
        }
      }
      // if there is no item with this id in the cart yet, after pushing it
      //  in an empty cell, increase the counter
      if (!found) {
        acc[c] = [];
        acc[c].push(value);
        c++;
      }
      found = false; //reset the flag
      return acc;
    }, {});

    var values = Object.keys(saleSummary).map(function (key) {
      return saleSummary[key];
    });

    setSaleSummary(values);

    if (shoppingCart.length === 0) {
      setShowWarning(false);
    }
  }, [shoppingCart]);

  useEffect(() => {
    if (!isEmpty(saleFeedback)) {
      setShowFeedback(true);
    }
  }, [saleFeedback]);

  useEffect(() => {
    if (showFeedback) {
      setTimeout(() => {
        setShowFeedback(false);
      }, 5000);
    } else {
      setSaleFeedback(undefined);
    }
  }, [showFeedback]);

  useEffect(() => {
    setShoppingCart([]);
    setStatus("list");
  }, [user]);

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isNetwork]);

  useEffect(() => {
    setAtLeastOneItem(checkAtLeastOneItem());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesList]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      width: props.iframe ? "100%" : "85%",
      height: "100%",
      position: "absolute",
      right: 0,
      alignItems: "center",
      overflow: "hidden",
    },
    column: {
      display: "flex",
      height: "100%",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
    },
    body: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      height: "100%",
      alignItems: "center",
      overflow: "hidden",
    },
    feedback: {
      position: "absolute",
      top: 0,
      width: "100%",
      backgroundColor: saleFeedback ? colors?.green : colors?.red,
      height: 100,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    loaderBox: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.body}>
        {showFeedback && (
          <div style={styles.feedback}>
            <img
              alt={"Feedback icon"}
              src={saleFeedback ? checked : error}
              style={{
                width: 20,
                padding: 10,
                borderRadius: 30,
              }}
            ></img>
            <p
              style={{
                color: colors?.white,
                fontWeight: "600",
                marginLeft: 10,
              }}
            >
              {saleFeedback
                ? "Vendita conclusa correttamente"
                : "Si è verificato un errore, non è stato possibile concludere la vendita"}
            </p>
          </div>
        )}
        {body()}
        {shoppingCart.length > 0 && (
          <Summary
            saleSummary={saleSummary}
            status={status}
            setStatus={setStatus}
            setShowWarning={setShowWarning}
            isCodeNotFound={isCodeNotFound}
            preventNext={preventNext}
            removeItem={removeItemFromShoppingCart}
            removeAllOfItem={removeAllOfItemFromShoppingCart}
            sell={sell}
            isTicket={shoppingCart.some(
              (el) =>
                el.categoria_articolo.nome_categoria.toUpperCase() ===
                "BIGLIETTI"
            )}
          />
        )}
      </div>
      {atLeastOneItem && (
        <Footer
          total={shoppingCart.reduce(
            (tot, current) =>
              tot +
              (current.prezzo_scontato >= 0
                ? current.prezzo_scontato
                : current.prezzo_articolo),
            0
          )}
          status={status}
          isTicket={isTicket}
          setLoader={setStatus}
          categoriesList={categoriesList}
          createVisitors={createVisitors}
          preventCreateVisitors={ticketsList.length < 1}
        />
      )}
    </div>
  );
};

export default Checkout;
