import React, { useState, useContext } from "react";
import pencil from "./../img/modify_gray.png";
import { settingsService } from "../_services";
import { UserContext } from "../contexts";

function updateInfoNetwork(name, setName) {
  settingsService
    .updateInfoNetwork(name)
    .then((response) => {
      setName(response.nome);
    })
    .catch((errors) => {
      if (errors.statusCode === 401 || errors.statusCode === 403) {
        this.props.history.push("/");
      }
    });
}

const NetworkInfo = ({ isAdmin, entity }) => {
  const colors = useContext(UserContext)?.colors;

  const [name, setName] = useState(entity?.nome);
  const [modify, setModify] = useState(false);

  React.useEffect(() => {
    setName(entity?.nome);
    setModify(false);
  }, [entity]);

  const styles = {
    infoSection: {
      marginBottom: 30,
      width: "100%",
    },
    labelTable: {
      fontSize: 12,
      color: colors?.lightgray,
      textAlign: "left",
    },
    field: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    label: {
      fontSize: 11,
      textAlign: "left",
      flex: 2,
      color: colors?.darkgray,
    },
    inputBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flex: 3,
    },
    textInput: {
      outline: "none",
      border: "1px solid lightgray",
      width: 230,
      height: 20,
      borderRadius: 10,
      marginRight: 10,
      marginLeft: 10,
      padding: 5,
      textAlign: "center",
    },
    smallTextInput: {
      outline: "none",
      border: "1px solid lightgray",
      width: 30,
      height: 20,
      borderRadius: 10,
      marginRight: 10,
      marginLeft: 10,
      padding: 5,
      textAlign: "center",
    },
    header: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
    text: {
      fontSize: 14,
      textAlign: "left",
      flex: 3,
    },
  };
  return (
    <div style={styles.infoSection}>
      <div style={styles.header}>
        <div style={styles.labelTable}>Info rete</div>
        {isAdmin === true && (
          <img
            alt="Modify"
            src={pencil}
            style={{
              width: 15,
              objectFit: "contain",
              marginLeft: 15,
              cursor: "pointer",
            }}
            onClick={() => {
              if (modify) {
                updateInfoNetwork(name, setName);
              }
              setModify(!modify);
            }}
          />
        )}
      </div>
      <div style={styles.field}>
        <p style={styles.label}>Nome </p>
        {modify ? (
          <div style={styles.inputBox}>
            <input
              type="text"
              style={styles.textInput}
              value={name}
              onChange={(event) => setName(event.target.value)}
              placeholder="Nome rete"
            />
          </div>
        ) : (
          <p style={styles.text}>{name}</p>
        )}
      </div>
    </div>
  );
};

export default NetworkInfo;
