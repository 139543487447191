import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import { UserContext } from "../../../../contexts";
import { activitiesService } from "../../../../_services";
import Select, { components } from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";
import ReactLoading from "react-loading";

const getTitle = (action) => {
  switch (action) {
    case "create":
      return "Nuova disponibilità";
    case "modify":
      return "Gestisci disponibilità";
    default:
      break;
  }
};

const minTime = new Date();
minTime.setHours(8, 0, 0);
const maxTime = new Date();
maxTime.setHours(20, 0, 0);
const Modal = ({
  action,
  slot,
  activitiesList,
  activitiesSelected,
  setActivitiesSelected,
  setShowModal,
  create,
  modify,
  remove,
  checkOverlappingAvailabilities,
}) => {
  const colors = useContext(UserContext)?.colors;
  const [infoEnti, setInfoEnti] = useState([]);
  const [activities, setActivities] = useState([]);
  const [listSelected, setListSelected] = useState(activitiesSelected);

  const [start, setStart] = useState(
    action === "create" ? slot.start : slot.datetime_inizio
  );
  const [end, setEnd] = useState(
    action === "create" ? slot.end : slot.datetime_fine
  );

  const [maxDuration, setMaxDuration] = useState(0);

  const preventClick = () => {
    return listSelected?.length < 1 || listSelected === null;
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isMobile, setIsMobile] = useState(false);

  const [isRepeated, setIsRepeated] = useState(false);
  const [endingTime, setEndingTime] = useState("");

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setMaxDuration(new Date(end).getTime() - new Date(start).getTime());
  }, [start, end]);

  useEffect(() => {
    setListSelected(activitiesSelected);
  }, [activitiesSelected]);

  useEffect(() => {
    const getInfoEnte = (id) => {
      activitiesService.getInfoEnte(id).then((info) => {
        setInfoEnti((infoEnti) => [...infoEnti, info]);
      });
    };

    if (slot.prenotazioni_attivitas?.length > 0) {
      for (let res of slot.prenotazioni_attivitas) {
        res.info_enti !== null
          ? getInfoEnte(res.info_enti)
          : setInfoEnti((infoEnti) => [...infoEnti, ""]);
      }
    }
  }, [slot]);

  useEffect(() => {
    //Bool used to check if there's at leat one activity avaialble
    var atLeastOne = false;
    let activitiesFormatted = activitiesList.map((act) => {
      if (parseInt(act.durata) * 60000 <= maxDuration) {
        atLeastOne = true;
        return { value: act.id, label: act.nome };
      } else {
        return {};
      }
    });

    if (atLeastOne) {
      activitiesFormatted.forEach((element, index) => {
        if (element === undefined) {
          activitiesFormatted.splice(index, 1);
        }
      });
      setActivities(activitiesFormatted);
    } else {
      setActivities([]);
    }
  }, [activitiesList, setActivities, maxDuration]);

  useEffect(() => {
    if (windowSize.innerWidth > 800) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize.innerWidth]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  function triggerCreate() {
    setLoader(true);
    create(isRepeated, endingTime);
  }

  function triggerModify() {
    setLoader(true);
    modify();
  }

  function triggerRemove() {
    setLoader(true);
    remove();
  }

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>Nessuna attività con durata adatta</span>
      </components.NoOptionsMessage>
    );
  };

  const checkReservation = (reservations) => {
    if (reservations.length < 1) {
      return true;
    } else {
      let check = true;
      reservations.forEach((res) => {
        check = !res.flag_confermato;
      });
      return check;
    }
  };

  const styles = {
    background: {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 300,
      width: isMobile ? "92%" : "",
    },
    container: {
      borderRadius: 10,
      width: isMobile ? "100%" : "40vw",
      backgroundColor: "white",
      display: "flex",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 10%)",
      flexDirection: "column",
    },
    row: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      flex: 1,
      justifyContent: "center",
    },
    column: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      flex: 1,
    },
    label: {
      color: "gray",
      fontSize: 13,
      textAlign: "left",
    },
    textInput: {
      borderRadius: 5,
      border: 0,
      outline: "none",
      textAlign: "center",
      width: "80%",
    },
    title: {
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      height: 60,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      backgroundColor: colors?.darkgray,
    },
    button: {
      outline: "none",
      border: "none",
      borderRadius: 10,
      height: 50,
      width: 100,
    },
    content: {
      fontSize: 14,
    },
    datePicker: {
      borderColor: colors?.primary,
      color: colors?.primary,
      height: "100%",
      width: "30%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      fontSize: 15,
      outline: "none",
      border: "1px solid lightgray",
      borderRadius: 5,
    },
  };
  return (
    <div style={styles.background}>
      <div style={styles.container}>
        <div style={styles.title}>{getTitle(action)}</div>
        <div style={{ ...styles.row, marginTop: 30 }}>
          <div style={styles.column}>
            <div style={{ ...styles.label, textAlign: "center" }}>Giorno</div>
            <div style={{ ...styles.content, marginTop: 15 }}>
              {moment(
                action === "create" ? slot?.start : slot?.datetime_inizio
              ).format("DD/MM/yyyy")}
            </div>
          </div>
          <div style={styles.column}>
            <div style={{ ...styles.label, textAlign: "center" }}>
              Ora inizio
            </div>
            <div style={styles.content}>
              {/* {moment(
                action === "create" ? slot?.start : slot?.datetime_inizio
              ).format("HH:mm")} */}
              <input
                type="time"
                min={minTime}
                max={maxTime}
                step="1800"
                value={moment(start).format("HH:mm")}
                required
                style={{
                  ...styles.textInput,
                  border: "1px solid lightgray",
                  width: isMobile ? "65%" : "50%",
                  marginTop: 15,
                }}
                onChange={(event) => {
                  let tempStart = new Date(
                    action === "create" ? slot.start : slot.datetime_inizio
                  );
                  tempStart.setHours(
                    parseInt(event.target.value.split(":")[0]),
                    parseInt(event.target.value.split(":")[1])
                  );
                  if (
                    new Date(tempStart) <
                    new Date(
                      action === "create" ? slot.end : slot.datetime_fine
                    )
                  ) {
                    slot.start = tempStart;
                    if (action !== "create") {
                      slot.end = slot.datetime_fine;
                    }
                    let verifiedSlot = checkOverlappingAvailabilities(slot);
                    if (verifiedSlot !== null) {
                      setStart(verifiedSlot.start);
                    }
                  }
                }}
              />
            </div>
          </div>
          <div style={styles.column}>
            <div style={{ ...styles.label, textAlign: "center" }}>Ora fine</div>
            <div style={styles.content}>
              {/* {moment(
                action === "create" ? slot?.end : slot?.datetime_fine
              ).format("HH:mm")} */}
              <input
                type="time"
                min={minTime}
                max={maxTime}
                step="1800"
                value={moment(end).format("HH:mm")}
                required
                style={{
                  ...styles.textInput,
                  border: "1px solid lightgray",
                  width: isMobile ? "65%" : "50%",
                  marginTop: 15,
                }}
                onChange={(event) => {
                  let tempEnd = new Date(
                    action === "create" ? slot.end : slot.datetime_fine
                  );

                  tempEnd.setHours(
                    parseInt(event.target.value.split(":")[0]),
                    parseInt(event.target.value.split(":")[1])
                  );
                  if (
                    new Date(
                      action === "create"
                        ? slot.start
                        : slot.datetime_inizio < new Date(tempEnd)
                    )
                  ) {
                    slot.end = tempEnd;
                    if (action !== "create") {
                      slot.start = slot.datetime_inizio;
                    }
                    let verifiedSlot = checkOverlappingAvailabilities(slot);
                    if (verifiedSlot !== null) {
                      setEnd(verifiedSlot.end);
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ ...styles.row, marginTop: 15, marginBottom: 10 }}>
          <div style={{ ...styles.column, alignItems: "center" }}>
            <div style={{ ...styles.label, textAlign: "center" }}>
              {action === "create" ? "Seleziona attività" : "Attività"}
            </div>
            <div style={{ ...styles.textInput, marginTop: 15 }}>
              <Select
                placeholder="Attività"
                defaultValue={slot.attivitas?.map((act) => {
                  return { value: act.id, label: act.nome };
                })}
                isMulti
                name="activities"
                options={activities}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(event) => {
                  var temp = [];
                  event.forEach((element) => {
                    temp.push(element.value);
                  });
                  temp.sort();
                  setActivitiesSelected(temp);
                }}
                isDisabled={slot.prenotazioni_attivitas?.length > 0}
                components={{ NoOptionsMessage }}
              />
            </div>
          </div>
          {slot.prenotazioni_attivitas?.length > 0 && (
            <div style={styles.column}>
              <div style={{ ...styles.label, textAlign: "center" }}>
                Prenotazioni
              </div>
              <div>
                {slot.prenotazioni_attivitas.map((res, key) => {
                  return (
                    <p key={key} style={styles.content}>
                      {moment(res.datetime_inizio).format("HH:mm") +
                        " - " +
                        moment(res.datetime_fine).format("HH:mm") +
                        " " +
                        (res.info_enti !== null
                          ? infoEnti.find((ente) => ente.id === res.info_enti)
                              ?.ragione_sociale
                          : res.cognome + " " + res.nome)}
                    </p>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {action === "create" && (
          <>
            <div
              style={{
                ...styles.row,
                marginTop: 15,
                marginBottom: isRepeated ? 0 : 30,
              }}
            >
              <div style={{ ...styles.row, alignItems: "center" }}>
                <input
                  type="checkbox"
                  onChange={() => setIsRepeated(!isRepeated)}
                  checked={isRepeated}
                />
                <p
                  style={{
                    ...styles.label,
                    width: 250,
                    textAlign: "left",
                  }}
                >
                  La disponibilità si ripete settimanalmente
                </p>
              </div>
            </div>
            {isRepeated && (
              <div style={{ ...styles.row, marginBottom: 30 }}>
                <div style={{ ...styles.column, alignItems: "center" }}>
                  <p style={styles.label}>Fino al</p>
                  <div style={styles.datePicker}>
                    <ReactDatePicker
                      name="endingTime"
                      dateFormat="dd/MM/yyyy"
                      locale="it"
                      className={"event-custom-input-full-width"}
                      minDate={
                        new Date(
                          new Date(slot.start).setDate(
                            new Date(slot.start).getDate() + 7
                          )
                        ) //The minDate is the same date of the week selected, but of the following week
                      }
                      filterDate={
                        (date) =>
                          date.getDay() === new Date(slot.start).getDay() //Only the same day of the week
                      }
                      selected={endingTime}
                      onChange={(date) => {
                        setEndingTime(date);
                      }}
                      placeholderText="Seleziona data"
                      popperPlacement="bottom-start"
                    />
                  </div>

                  <p
                    style={{
                      ...styles.label,
                      marginRight: "20px",
                      marginLeft: "20px",
                      textAlign: "center",
                    }}
                  >
                    Attenzione! Non verranno create disponibilità in
                    sovrapposizione ad altre già esistenti
                  </p>
                </div>
              </div>
            )}
          </>
        )}
        {loader ? (
          <div
            style={{
              ...styles.row,
              justifyContent: "center",
              marginBottom: 30,
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={colors?.primary}
              height={50}
              width={50}
            />
          </div>
        ) : (
          <div
            style={{
              ...styles.row,
              justifyContent: "space-around",
              marginBottom: 30,
            }}
          >
            <button
              style={{ ...styles.button }}
              onClick={() => setShowModal(false)}
            >
              ANNULLA
            </button>
            <button
              style={{
                ...styles.button,
                backgroundColor: colors?.darkgray,
                opacity: preventClick() && 0.6,
                color: colors?.white,
              }}
              disabled={preventClick()}
              onClick={() =>
                action === "create" ? triggerCreate() : triggerModify()
              }
            >
              {action === "create" ? "CREA" : "SALVA"}
            </button>
            {action === "modify" &&
              checkReservation(slot.prenotazioni_attivitas) && (
                <button
                  style={{
                    ...styles.button,
                    border: "1px solid",
                    borderColor: colors?.darkgray,
                    backgroundColor: colors?.white,
                    color: colors?.darkgray,
                  }}
                  onClick={() => triggerRemove()}
                >
                  ELIMINA
                </button>
              )}
          </div>
        )}
      </div>
    </div>
  );
};
export default Modal;
