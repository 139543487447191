import React from "react";
import colors from "./../../museumColors";
import cross from "./../../img/closeButton.png";

const ErrorStep = ({ name, width, restart, errorMessage }) => {
  const styles = {
    body: {
      flex: 1,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: width > 1000 ? "row" : "column",
      justifyContent: "center",
      alignItems: "center",
    },
    button: {
      display: "flex",
      backgroundColor: "red",
      width: 80,
      height: 80,
      borderRadius: 50,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    title: {
      color: colors.darkgray,
      fontWeight: "800",
      width: width > 1000 ? "60%" : "85%",
      textAlign: "left",
      fontSize: 25,
      marginBottom: 0,
    },
    subtitle: {
      color: colors.gray,
      fontWeight: "500",
      width: width > 1000 ? "60%" : "85%",
      textAlign: "left",
    },
    text: {
      width: width > 1000 ? "60%" : "85%",
      textAlign: "left",
      fontSize: 13,
    },
    column: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    label: {
      fontSize: 12,
      width: "60%",
    },
    restartButton: {
      outline: "none",
      border: "none",
      display: "flex",
      backgroundColor: "transparent",
      width: "70%",
      height: 50,
      alignItems: "center",
      justifyContent: "flex-end",
      cursor: "pointer",
      color: colors.gray,
      marginTop: 50,
      fontSize: 12,
      textDecoration: "underline",
    },
  };
  return (
    <div style={styles.body}>
      <div
        style={{
          ...styles.column,
          flex: 1,
          alignItems: "flex-end",
          justifyContent: width > 1000 ? "center" : "flex-end",
        }}
      >
        <div style={styles.column}>
          <div style={styles.button}>
            <img
              alt="Error icon"
              src={cross}
              style={{ height: 35, objectFit: "contain" }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          ...styles.column,
          flex: 2,
        }}
      >
        <p style={styles.title}>Si è verificato un errore</p>
        <p style={styles.subtitle}>
          L'acquisto della card <b>{name}</b> non è andato a buon fine.
        </p>
        <p style={styles.text}>Ci scusiamo per il disagio.</p>
        {/*<p style={styles.text}>Errore: {errorMessage}</p>*/}
        <button style={styles.restartButton} onClick={restart}>
          {" "}
          Effettua nuovamente l'acquisto
        </button>
      </div>
    </div>
  );
};

export default ErrorStep;
