import React, { useState, useEffect, useContext, useRef } from "react";
import { activitiesService, settingsService } from "../../../_services";
import logo from "./../../../img/logo_white.png";
import user from "./../../../img/user_secondary.png";
import ReactLoading from "react-loading";
import ActivityCategory from "./activityCategory";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import { LanguageContext } from "../../../containers/language";
import ActivitiesCalendar from "./activitiesCalendar";

const AllActivities = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [info, setInfo] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [speakers, setSpeakers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [orgId, setOrgId] = useState();

  const { dictionary } = useContext(LanguageContext);

  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          observer.disconnect();
          resolve(document.querySelector(selector));
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }
  const divRef = useRef();
  useEffect(() => {
    waitForElm(".thisRef").then(() => {
      if (
        info !== undefined &&
        !!info.descrizione_museo &&
        divRef.current !== undefined &&
        divRef.current !== null
      ) {
        divRef.current.innerHTML = info.descrizione_museo;
      }
    });
  }, [info]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      alignItems: "center",
    },
    loaderBox: {
      flex: 1,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    topBar: {
      width: "100%",
      height: 60,
      backgroundColor: "black",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    header: { height: height * 0.2, width: width, objectFit: "cover" },

    button: {
      outline: "none",
      backgroundColor: "white",
      border: "none",
      borderRadius: 50,
      width: 200,
      padding: 10,
      fontWeight: "800",
      cursor: "pointer",
    },
    arrowBack: {
      height: 30,
      objectFit: "contain",
      outline: "none",
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: 10,
    },

    responsiveRow: {
      display: "flex",
      flexDirection: width > 1000 ? "row" : "column",
      width: "100%",
      height: "100%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    column: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    leftColumn: {
      height: width > 1000 && "100%",
      width: width > 1000 && "20%",
      backgroundColor: "black",
      display: "flex",
      flexDirection: "column",
      padding: 20,
      overflow: width > 1000 && "scroll",
      paddingBottom: 40,
    },
    body: {
      flex: 1,
      height: "100%",
      paddingLeft: width > 1000 && 20,
      paddingRigth: 20,
    },
    rightColumn: {
      height: width > 1000 && "100%",
      width: width > 1000 && "20%",
      display: "flex",
      flexDirection: "column",
      padding: 20,
      overflow: width > 1000 && "scroll",
      paddingBottom: 40,
    },
    description: {
      fontSize: 14,
      textAlign: "left",
      margin: 0,
      color: "white",
      lineHeight: 1.4,
    },
    label: {
      fontSize: 12,
      textAlign: "left",
      color: "white",
      fontWeight: 800,
    },
    slot: {
      borderRadius: 5,
      backgroundColor: "#f0f0f0",
      margin: 5,
      height: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
    ticket: {
      borderRadius: 5,
      backgroundColor: "#f0f0f0",
      margin: 5,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      cursor: "pointer",
    },
    reserveButton: {
      outline: "none",
      backgroundColor: "#8b0000",
      color: "white",
      border: "none",
      borderRadius: 5,
      padding: 20,
      marginTop: 40,
      fontWeight: "800",
      cursor: "pointer",
    },
    dataColumn: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      marginLeft: 20,
      marginRight: 20,
    },
    title: { fontSize: 18, fontWeight: 700, textAlign: "left", marginTop: 0 },
    back: {
      fontSize: 10,
      marginLeft: 5,
      cursor: "pointer",
    },
    buttonBack: {
      outline: "none",
      backgroundColor: "transparent",
      border: "none",
      fontSize: 20,
      cursor: "pointer",
    },
    feedback: {
      fontSize: 25,
      textAlign: "center",
      fontWeight: "600",
    },
  };

  const renderBody = () => {
    return (
      <div
        style={{
          ...styles.column,
          height: "95vh",
          overflowY: "scroll",
        }}
      >
        {/* {width > 800 && ( */}
        <Tabs
          value={tabIndex}
          variant="fullWidth"
          TabIndicatorProps={{
            style: { background: info?.colore || "black" },
          }}
          onChange={(event, newValue) => {
            setTabIndex(newValue);
          }}
        >
          <Tab label={dictionary.activitiesList} />
          <Tab label={dictionary.calendar} />
        </Tabs>
        {/* // )} */}
        {tabIndex === 0 ? (
          categories.map((category, key) => {
            return (
              <ActivityCategory
                category={category}
                width={width}
                info={info}
                match={props.match}
                key={key}
              />
            );
          })
        ) : (
          <ActivitiesCalendar orgId={orgId} match={props.match} />
        )}
      </div>
    );
  };

  const renderRightColumn = () => {
    return (
      <div style={{ ...styles.column, overflowY: "scroll" }} id="scroll">
        <p style={styles.title}>Relator{speakers.length > 1 ? "i" : "e"}</p>
        <div style={styles.column}>
          {speakers.length > 0 ? (
            speakers.map((speaker, key) => {
              return (
                <div
                  key={key}
                  style={{
                    ...styles.row,
                    margin: 10,
                    // cursor: "pointer",
                    marginBottom: 20,
                  }}
                >
                  <img
                    alt={"Foto relatore"}
                    src={speaker.photo?.url || user}
                    style={{
                      width: 50,
                      height: 50,
                      borderRadius: 50,
                      objectFit: "cover",
                    }}
                  ></img>
                  <p style={{ marginLeft: 10, fontWeight: 500 }}>
                    {speaker.name + " " + speaker.surname}
                  </p>
                </div>
              );
            })
          ) : (
            <p style={{ fontSize: 14, textAlign: "left" }}>Nessun relatore</p>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const getInfo = () => {
      settingsService.getAllMuseums().then((list) => {
        let museum = list.find(
          (mus) => mus.identificativo_museo === props.match.params.museum
        );

        settingsService.getMuseumFromId(museum.id_museo).then((infoMuseum) => {
          setInfo(infoMuseum);
          setOrgId(museum.id_org);
          getSpeakers(museum.id_org);
          setActivityCategories(museum.id_org);
        });
      });
    };

    const getSpeakers = (orgId) => {
      activitiesService.getOrgSpeakers(orgId).then((speakers) => {
        setSpeakers(speakers);
        setLoading(false);
      });
    };

    const setActivityCategories = (orgId) => {
      activitiesService
        .getActivityCategories(orgId)
        .then((categories) => setCategories(categories));
    };

    getInfo();
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
      const newHeight = window.innerHeight;
      setHeight(newHeight);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, [props]);

  return (
    <div style={styles.container}>
      {!loading && (
        <div style={styles.topBar}>
          <img
            alt={"Logo museo"}
            src={info?.logo_light.url}
            style={{
              height: "80%",
              objectFit: "contain",
              marginLeft: width > 1000 && 50,
            }}
          ></img>
          <img
            alt={"Logo EMMA"}
            src={logo}
            style={{
              height: "50%",
              objectFit: "contain",
              marginRight: width > 1000 && 50,
            }}
          ></img>
        </div>
      )}
      {!loading && info?.cover?.url !== undefined && (
        <img alt={"Header"} src={info?.cover?.url} style={styles.header}></img>
      )}
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#303030"}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <div style={styles.responsiveRow}>
          <div style={styles.leftColumn}>
            <div style={styles.column}>
              <p
                style={{
                  fontSize: 18,
                  color: "white",
                  fontWeight: 700,
                  marginTop: 20,
                }}
              >
                {info.nome}
              </p>
            </div>
            <div style={styles.column}>
              <p style={styles.label}>Informazioni</p>
              <p style={styles.description}>
                <div className="thisRef" ref={divRef}>
                  {
                    //Custom content will be programmatically inserted in div below
                  }
                  <div></div>
                </div>
              </p>
            </div>
            <div style={styles.column}>
              <p style={styles.label}>Indirizzo</p>
              <p style={styles.description}>
                {info.indirizzo_museo +
                  ", " +
                  info.citta_museo +
                  " - " +
                  info.CAP_museo}
              </p>
            </div>
            <div style={styles.row}>
              <p style={styles.label}>E-mail</p>
              <p style={{ ...styles.description, marginLeft: 35 }}>
                {info.email_museo}
              </p>
            </div>
            <div style={styles.row}>
              <p style={styles.label}>Telefono</p>
              <p style={{ ...styles.description, marginLeft: 20 }}>
                {info.numero_telefono_museo}
              </p>
            </div>
          </div>
          <div style={styles.body}>{renderBody()}</div>
          {width > 800 && tabIndex === 0 && (
            <div style={styles.rightColumn}>{renderRightColumn()}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default AllActivities;
