import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateCalendar = (props) => {
  const [date, setDate] = useState(
    props.prevDate ? new Date(props.prevDate) : new Date()
  );
  const [mounting, setMounting] = useState(true);

  const handleDateSelected = useCallback(
    (date) => {
      setDate(date);
      props.dateSelected(date);
    },
    [props]
  );

  //This useEffect has been implemented to avoid errors during the first render
  useEffect(() => {
    if (mounting) {
      setMounting(false);
    } else {
      handleDateSelected(date);
    }
  }, [props.isAnnual, date, mounting, handleDateSelected]);

  const styles = {
    container: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      margin: 1,
    },
  };

  return (
    <div style={styles.container}>
      <DatePicker
        name="calendar"
        dateFormat={props.isAnnual ? "yyyy" : "MM/yyyy"}
        className={"event-custom-input-multiple"}
        locale="it"
        maxDate={new Date()}
        selected={date}
        onChange={(date) => handleDateSelected(date)}
        placeholderText={props.isAnnual ? "Scegli anno" : "Scegli mese"}
        showMonthYearPicker={props.isAnnual ? false : true}
        showYearPicker={props.isAnnual ? true : false}
        popperPlacement="bottom"
        disabled={props.isDisabled ? true : false}
      />
    </div>
  );
};

export default DateCalendar;
