import React, { useRef, useState, useContext, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import { itemsService, reportService } from "../../_services";
import arrowDown from "./../../img/arrow_down.png";
import arrowUp from "./../../img/arrow_up.png";
import filterIcon from "./../../img/filter.png";
import ReactLoading from "react-loading";
import "./../../customizer.css";
import Pagination from "react-js-pagination";
import DownloadButton from "../../components/buttons/downloadButton";
import { UserContext, OrgContext } from "../../contexts";
import RemoveEntrance from "./removeEntrance";
import Entrance from "../../components/entrance";
import refreshInputs from "./../../img/closeButton.png";
import DateCalendar from "../analytics/dateCalendar";
import { styled } from "@material-ui/core/styles";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function isEmpty(value) {
  return value === "" || value === null || value === undefined;
}

const itemsPerPageOptions = [10, 20, 50, 100];

const EntrancesHistory = ({ iframe }) => {
  const user = useContext(UserContext)?.user;
  const colors = useContext(UserContext)?.colors;
  const org = useContext(OrgContext)?.org;
  const prevOrg = usePrevious(org);

  const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiAutocomplete-inputRoot": {
      color: colors?.white,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors?.white,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: colors?.white,
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: colors?.white,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: colors?.white,
      },
      "& .MuiButtonBase-root": {
        color: colors?.white,
      },
    },
    "& .MuiInputLabel-outlined": {
      color: colors?.white,
    },
    "&:hover .MuiInputLabel-outlined": {
      color: colors?.white,
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: colors?.white,
    },
    "& .MuiInputLabel-outlined.Mui-disabled": {
      color: colors?.white,
    },
  });

  const [entrancesReport, setEntrancesReport] = useState([]);
  const [startingDate, setStartingDate] = useState(
    moment().subtract(30, "days")
  );
  const [endingDate, setEndingDate] = useState(moment());
  const [showDateSelection, setShowDateSelection] = useState(false);
  const [showMonthSelection, setShowMonthSelection] = useState(false);
  const [item, setItem] = useState("");
  const [code, setCode] = useState("");
  const [operator, setOperator] = useState("");
  const [sellValidity, setSellValidity] = useState("");
  const sellValidityList = [
    { value: "", label: "Tutte le vendite" },
    { value: 1, label: "Vendite valide" },
    { value: 2, label: "Vendite eliminate" },
  ];
  const [filterSelected, setFilterSelected] = useState("none");
  const [showDeleteEntrance, setShowDeleteEntrance] = useState(false);
  const [entranceSelected, setEntranceSelected] = useState(null);
  const [loader, setLoader] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const prevActivePage = usePrevious(activePage);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const prevItemsPerPage = usePrevious(itemsPerPage);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [filterApplied, setFilterApplied] = useState(false);
  const prevFilterApplied = usePrevious(filterApplied);
  const labels = [
    "Codice biglietto",
    "Tipologia",
    "Data ingresso",
    "Ingressi rimanenti",
    "Data vendita biglietto",
    "Scadenza biglietto",
    user?.flag_network ? "Museo" : "Operatore",
  ];

  const defaultFilters = {
    datetime_vendita_gte: moment().subtract(30, "days").format("yyyy-MM-DD"),
    datetime_vendita_lt: moment().format("yyyy-MM-DD"),
  };

  const [filters, setFilters] = useState(defaultFilters);
  const [filterPayload, setFilterPayload] = useState({});
  const filterFields = [
    {
      identifier: "date",
      label: "Data ingresso",
      var: startingDate || endingDate,
    },
    { identifier: "item", label: "Tipologia biglietto", var: item },
    { identifier: "code", label: "Codice biglietto", var: code },
    {
      identifier: "operator",
      label: user?.flag_network ? "Museo" : "Operatore",
      var: operator,
    },
    { identifier: "sellValidity", label: "Validità", var: sellValidity },
  ];

  const [tipologiaList, setTipologiaList] = useState([]);
  const [operatoreList, setOperatoreList] = useState([]);

  const [inputsRecap, setInputsRecap] = useState("");
  var inputsArray = [
    startingDate,
    endingDate,
    item,
    code,
    operator,
    sellValidity,
  ];
  const generateInputsRecap = () => {
    //for every used filter, add a part to the string of filters recap
    var vocalDays = [1, 8, 11]; //used to deal with days starting with a vocal
    var recapString = "";
    if (startingDate !== undefined) {
      recapString +=
        (vocalDays.includes(moment(startingDate).date()) ? "Dall'" : "Dal ") +
        moment(startingDate).locale("it").format("D MMMM YYYY");
    }
    if (endingDate !== undefined) {
      if (moment(endingDate).isSame(new Date(), "day")) {
        recapString += " a oggi";
      } else {
        recapString +=
          (vocalDays.includes(moment(endingDate).date()) ? " all'" : " al ") +
          moment(endingDate).locale("it").format("D MMMM YYYY");
      }
    }
    if (item !== "") {
      recapString += ', Tipologia biglietto contiene "' + item.trim() + '"';
    }
    if (code !== "") {
      recapString += ', Codice biglietto contiene "' + code.trim() + '"';
    }
    if (operator !== "") {
      recapString +=
        ", " +
        (user?.flag_network ? "Museo" : "Operatore") +
        ' contiene "' +
        operator.trim() +
        '"';
    }
    if (sellValidity !== "") {
      recapString +=
        ", soltanto " + sellValidityList[sellValidity].label.toLowerCase();
    }
    return recapString;
  };

  useEffect(() => {
    setInputsRecap(generateInputsRecap());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputsArray]);

  const [currentFiltersRecap, setCurrentFiltersRecap] = useState("");
  const generateCurrentFiltersRecap = () => {
    var recapString = "Filtri della ricerca corrente: ";
    recapString +=
      generateInputsRecap().substring(0, 1).toLocaleLowerCase() +
      generateInputsRecap().substring(1);
    return recapString;
  };

  const filterLabel = (fil, key) => {
    return (
      <div
        style={{
          ...styles.singleFilter,
          outline:
            !isEmpty(fil.var) && filterSelected === "none"
              ? "solid 3px"
              : "solid 1px",
          opacity:
            filterSelected === "none" || filterSelected === fil.identifier
              ? 1
              : 0.3,
        }}
        onClick={(e) =>
          setFilterSelected(
            filterSelected === fil.identifier ? "none" : fil.identifier
          )
        }
        key={key}
      >
        <p
          style={{
            margin: "5px 10px",
            fontWeight:
              !isEmpty(fil.var) && filterSelected === "none"
                ? "bold"
                : "normal",
          }}
        >
          {fil.label}
        </p>
        <img
          alt="Arrow icon"
          src={filterSelected === fil.identifier ? arrowUp : arrowDown}
          style={{ width: 10, objectFit: "contain", marginRight: 10 }}
        />
      </div>
    );
  };

  const showFilter = () => {
    switch (filterSelected) {
      case "date":
        return (
          <div
            style={{
              marginBottom: 20,
              display: "flex",
              flexDirection: "row",
              fontSize: 13,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                opacity: filterApplied === false ? 1 : 0.3,
              }}
            >
              {/* "Last 30 days" button, highlighted even if the free selection corresponds */}
              <button
                onClick={() => {
                  setEndingDate(moment());
                  setStartingDate(moment().subtract(30, "days"));
                  setShowMonthSelection(false);
                  setShowDateSelection(false);
                }}
                style={{
                  ...styles.button,
                  marginRight: 10,
                  backgroundColor:
                    moment(endingDate).isSame(moment(), "day") &&
                    moment(startingDate).isSame(
                      moment().subtract(30, "days"),
                      "day"
                    )
                      ? colors?.primary
                      : colors?.gray,
                  cursor: filterApplied === false ? "pointer" : "default",
                }}
                disabled={filterApplied}
              >
                Ultimi 30 giorni
              </button>
              {/* "Select a month" button */}
              <button
                onClick={() => {
                  setShowMonthSelection(true);
                  setShowDateSelection(false);
                }}
                style={{
                  ...styles.button,
                  marginRight: 10,
                  backgroundColor: showMonthSelection
                    ? colors?.primary
                    : colors?.gray,
                  cursor: filterApplied === false ? "pointer" : "default",
                }}
                disabled={filterApplied}
              >
                Scelta mese
              </button>
              {/* "Free selection" button */}
              <button
                onClick={() => {
                  setShowMonthSelection(false);
                  setShowDateSelection(true);
                }}
                style={{
                  ...styles.button,
                  marginRight: 20,
                  backgroundColor: showDateSelection
                    ? colors?.primary
                    : colors?.gray,
                  cursor: filterApplied === false ? "pointer" : "default",
                }}
                disabled={filterApplied}
              >
                Scelta manuale
              </button>
            </div>
            {showDateSelection && (
              <>
                <p style={{ marginRight: 10 }}>Dal</p>
                <input
                  type="date"
                  name="startingDate"
                  disabled={filterApplied}
                  max={moment(new Date()).format("yyyy-MM-DD")}
                  value={moment(startingDate).format("yyyy-MM-DD")}
                  onChange={(event) => {
                    setStartingDate(event.target.value);
                  }}
                  placeholder="Dal"
                  style={styles.dateText}
                />
                <p style={{ marginRight: 10, marginLeft: 10 }}>al</p>
                <input
                  type="date"
                  name="endingDate"
                  disabled={filterApplied}
                  min={moment(startingDate).format("yyyy-MM-DD")}
                  max={moment(new Date()).format("yyyy-MM-DD")}
                  value={moment(endingDate).format("yyyy-MM-DD")}
                  onChange={(event) => setEndingDate(event.target.value)}
                  placeholder="al"
                  style={styles.dateText}
                />
              </>
            )}
            {showMonthSelection && (
              <DateCalendar
                isAnnual={false}
                isDisabled={filterApplied}
                prevDate={endingDate}
                dateSelected={(date) => {
                  var dDate = new Date(date);
                  var today = moment(new Date()).format("yyyy-MM-DD");
                  var first = moment(dDate.setDate(1)).format("yyyy-MM-DD");
                  setStartingDate(first);
                  var last = moment(
                    new Date(dDate.getFullYear(), dDate.getMonth() + 1, 0)
                  ).format("yyyy-MM-DD");
                  if (last > today) {
                    last = today;
                  }
                  setEndingDate(last);
                }}
              />
            )}
          </div>
        );

      case "item":
        return (
          <StyledAutocomplete
            id="combo-box-demo"
            freeSolo
            autoSelect
            options={tipologiaList}
            sx={styles.importedFilterSize}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tipologia biglietto"
                variant="outlined"
                sx={{ opacity: filterApplied ? 0.5 : 1 }}
              />
            )}
            onChange={(event) => {
              setItem(
                event.target.value
                  ? event.target.value
                  : event.target.outerText
                  ? event.target.outerText
                  : ""
              );
            }}
            value={!isEmpty(item) ? item : ""}
            disabled={filterApplied}
          />
        );
      case "code":
        return (
          <StyledAutocomplete
            id="combo-box-demo"
            freeSolo
            autoSelect
            options={[]}
            sx={styles.importedFilterSize}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Codice biglietto"
                variant="outlined"
                sx={{ opacity: filterApplied ? 0.5 : 1 }}
              />
            )}
            onChange={(event) => {
              setCode(
                event.target.value
                  ? event.target.value
                  : event.target.outerText
                  ? event.target.outerText
                  : ""
              );
            }}
            value={!isEmpty(code) ? code : ""}
            disabled={filterApplied}
          />
        );
      case "operator":
        return (
          <StyledAutocomplete
            id="combo-box-demo"
            freeSolo
            autoSelect
            options={operatoreList}
            sx={styles.importedFilterSize}
            renderInput={(params) => (
              <TextField
                {...params}
                label={user?.flag_network ? "Museo" : "Operatore"}
                variant="outlined"
                sx={{ opacity: filterApplied ? 0.5 : 1 }}
              />
            )}
            onChange={(event) => {
              setOperator(
                event.target.value
                  ? event.target.value
                  : event.target.outerText
                  ? event.target.outerText
                  : ""
              );
            }}
            value={!isEmpty(operator) ? operator : ""}
            disabled={filterApplied}
          />
        );
      case "sellValidity":
        return (
          <select
            name={
              isEmpty(sellValidity)
                ? sellValidityList[0].label
                : sellValidityList[sellValidity].label
            }
            style={styles.textInput}
            value={
              isEmpty(sellValidity)
                ? sellValidityList[0].value
                : sellValidityList[sellValidity].value
            }
            disabled={filterApplied}
            onChange={(event) => {
              event.target.selectedIndex === 0
                ? setSellValidity("")
                : setSellValidity(
                    sellValidityList[event.target.selectedIndex].value
                  );
            }}
          >
            {sellValidityList.map((opt, key) => (
              <option key={key} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
        );
      case "none":
        break;
      default:
        break;
    }
  };

  const areThereFilters = () => {
    return (
      !isEmpty(startingDate) ||
      !isEmpty(endingDate) ||
      !isEmpty(item) ||
      !isEmpty(operator) ||
      !isEmpty(code) ||
      !isEmpty(sellValidity)
    );
  };

  const startSearch = () => {
    setActivePage(1);
    setFilterApplied(true);
  };

  const resetFilters = () => {
    setFilters(defaultFilters);
    setShowDateSelection(false);
    setShowMonthSelection(false);
    setFilterPayload({});
    setStartingDate(moment().subtract(30, "days"));
    setEndingDate(moment());
    setItem("");
    setCode("");
    setOperator("");
    setSellValidity("");
    setFilterSelected("none");
  };

  const filtersAlreadyReset = () => {
    return (
      moment(startingDate).date() === moment().subtract(30, "days").date() &&
      moment(endingDate).date() === moment().date() &&
      item === "" &&
      code === "" &&
      operator === "" &&
      sellValidity === ""
    );
  };

  const deleteEntrance = (id) => {
    setShowDeleteEntrance(false);
    setLoader(true);
    reportService
      .deleteEntrance(id)
      .then((history) => {
        setEntrancesReport(history);
        setLoader(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  const getPdf = () => {
    setLoader(true);
    let filename =
      (startingDate === endingDate
        ? "report_" + moment(new Date(startingDate)).format("yyyy-MM-DD")
        : "report_" +
          moment(new Date(startingDate)).format("yyyy-MM-DD") +
          "_" +
          moment(new Date(endingDate)).format("yyyy-MM-DD")) +
      (item !== "" ? "_" + item : "") +
      (operator !== "" ? "_" + operator : "");
    let filters = [];
    if (item !== "") {
      filters.push({ "Tipologia biglietto": item });
    }
    if (code !== "") {
      filters.push({ "Codice biglietto": code });
    }
    if (operator !== "") {
      filters.push({ Operatore: operator });
    }
    reportService
      .getEntrancesReportPdf(
        org,
        moment(new Date(startingDate)).format("yyyy-MM-DD"),
        moment(new Date(endingDate)).format("yyyy-MM-DD"),
        filename,
        entrancesReport,
        filters,
        filterPayload
      )
      .then((file) => {
        setLoader(false);
        var a = document.createElement("a");
        a.href = file;
        a.target = "_blank";
        a.download = filename + ".pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  const getExcel = () => {
    setLoader(true);
    let filename =
      (startingDate === endingDate
        ? "report_" + moment(new Date(startingDate)).format("yyyy-MM-DD")
        : "report_" +
          moment(new Date(startingDate)).format("yyyy-MM-DD") +
          "_" +
          moment(new Date(endingDate)).format("yyyy-MM-DD")) +
      (item !== "" ? "_" + item : "") +
      (operator !== "" ? "_" + operator : "");
    let filters = [];
    if (item !== "") {
      filters.push({ "Tipologia biglietto": item });
    }
    if (code !== "") {
      filters.push({ "Codice biglietto": code });
    }
    if (operator !== "") {
      filters.push({ Operatore: operator });
    }
    reportService
      .getEntrancesReportExcel(
        org,
        moment(new Date(startingDate)).format("yyyy-MM-DD"),
        moment(new Date(endingDate)).format("yyyy-MM-DD"),
        filename,
        entrancesReport,
        filters,
        filterPayload
      )
      .then((file) => {
        setLoader(false);
        var a = document.createElement("a");
        a.href = file;
        a.download = filename + ".xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  useEffect(() => {
    if (!isEmpty(entranceSelected)) {
      setShowDeleteEntrance(true);
    }
  }, [entranceSelected]);

  useEffect(() => {
    if (!showDeleteEntrance) {
      setEntranceSelected(null);
    }
  }, [showDeleteEntrance]);

  useEffect(() => {
    const getHistory = () => {
      setLoader(true);
      reportService
        .countEntrances(org, filters)
        .then((tot) => setTotalItemsCount(tot));
      reportService
        .getEntrances(
          org,
          filters,
          itemsPerPage * activePage - itemsPerPage,
          itemsPerPage
        )
        .then((history) => {
          setEntrancesReport(history);
          setCurrentFiltersRecap(generateCurrentFiltersRecap());
          setLoader(false);
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            this.props.history.push("/");
          }
        });
    };

    if (
      prevFilterApplied !== filterApplied &&
      filterApplied !== false &&
      prevFilterApplied !== undefined
    ) {
      getHistory();
      setFilterSelected("none");
    }
    if (
      (prevActivePage !== activePage && prevActivePage !== undefined) ||
      (prevItemsPerPage !== itemsPerPage && prevItemsPerPage !== undefined)
    ) {
      getHistory();
    }
    if (prevOrg !== org) {
      getHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activePage,
    prevActivePage,
    itemsPerPage,
    prevItemsPerPage,
    prevOrg,
    org,
    filterApplied,
    prevFilterApplied,
    filters,
  ]);

  useEffect(() => {
    if (!isEmpty(startingDate)) {
      filters.datetime_vendita_gte = moment(new Date(startingDate)).format(
        "yyyy-MM-DD"
      );
    } else {
      delete filters.datetime_vendita_gte;
    }
  }, [startingDate, filters]);

  useEffect(() => {
    if (!isEmpty(endingDate)) {
      var nextDay = new Date(endingDate);
      nextDay.setDate(nextDay.getDate());
      filters.datetime_vendita_lt = moment(new Date(nextDay)).format(
        "yyyy-MM-DD"
      );
    } else {
      delete filters.datetime_vendita_lt;
    }
  }, [endingDate, filters]);

  useEffect(() => {
    if (!isEmpty(operator)) {
      filters.operatore_contains = encodeURIComponent(operator.trim());
      filterPayload.operatore_contains = encodeURIComponent(operator.trim());
    } else {
      delete filters.operatore_contains;
      delete filterPayload.operatore_contains;
    }
  }, [operator, filters, filterPayload]);

  useEffect(() => {
    if (!isEmpty(item)) {
      filters.tipologia_biglietto_contains = encodeURIComponent(item.trim());
      filterPayload.tipologia_biglietto_contains = encodeURIComponent(
        item.trim()
      );
    } else {
      delete filters.tipologia_biglietto_contains;
      delete filterPayload.tipologia_biglietto_contains;
    }
  }, [filters, item, filterPayload]);

  useEffect(() => {
    if (!isEmpty(code)) {
      filters.codice_biglietto_contains = encodeURIComponent(code.trim());
      filterPayload.codice_biglietto_contains = encodeURIComponent(code.trim());
    } else {
      delete filters.codice_biglietto_contains;
      delete filterPayload.codice_biglietto_contains;
    }
  }, [filters, code, filterPayload]);

  useEffect(() => {
    if (!isEmpty(sellValidity)) {
      if (sellValidity === 1) {
        filters.deleted_eq = false;
        filterPayload.deleted_eq = false;
      } else if (sellValidity === 2) {
        filters.deleted_eq = true;
        filterPayload.deleted_eq = true;
      }
    } else {
      delete filters.deleted_eq;
      delete filterPayload.deleted_eq;
    }
  }, [sellValidity, filters, filterPayload]);

  useEffect(() => {
    itemsService.getTipsIngressi(org.id).then((res) => {
      let tempListOperatori = [];
      let tempListTipologies = [];

      if (Object.keys(res).length !== 0) {
        for (let el of res.operatore) {
          tempListOperatori.push({
            value: el.toLowerCase(),
            label: el,
          });
        }
        tempListOperatori = tempListOperatori.sort((a, b) =>
          a.value.localeCompare(b.value)
        );

        for (let el of res.tipologia) {
          tempListTipologies.push({
            value: el.toLowerCase(),
            label: el,
          });
        }
        tempListTipologies = tempListTipologies.sort((a, b) =>
          a.value.localeCompare(b.value)
        );
      }

      setTipologiaList(tempListTipologies);
      setOperatoreList(tempListOperatori);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      width: iframe ? "100%" : "88%",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors?.white,
      position: "absolute",
      right: 0,
    },
    bottomSection: {
      flex: 5,
      overflowY: "scroll",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    box: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: colors?.secondary,
      borderRadius: 20,
      margin: 20,
      alignItems: "center",
      justifyContent: "center",
    },
    label: {
      color: colors?.white,
      fontSize: 20,
    },
    value: {
      color: colors?.white,
      fontSize: 30,
      fontWeight: "700",
    },
    scrollerX: {
      overflowX: "scroll",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    labelTable: {
      width: 170,
      fontSize: 12,
      color: colors?.lightgray,
    },
    header: {
      display: "flex",
      flexDirection: "row",
      marginTop: 10,
    },
    importedFilterSize: {
      width: 500,
      marginBottom: "5px",
    },
    button: {
      backgroundColor: colors?.primary,
      width: 80,
      height: 50,
      borderRadius: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textDecorationLine: "none",
      border: "none",
      color: colors?.white,
      fontWeight: "800",
      cursor: "pointer",
    },
    textInput: {
      borderRadius: 5,
      border: 0,
      outline: "none",
      textAlign: "center",
      padding: 10,
      marginRight: 5,
      fontFamily: "Arial",
      fontSize: "15px",
      color: "#555555",
      width: 500,
    },
    dateText: {
      borderRadius: 5,
      border: 0,
      outline: "none",
      textAlign: "center",
      padding: 10,
      paddingTop: 8,
      paddingBottom: 8,
      marginRight: 5,
      fontFamily: "Arial",
      flex: 1,
      width: 200,
      cursor: filterApplied === false ? "pointer" : "default",
    },
    filterSection: {
      backgroundColor: colors?.secondary,
      width: "100%",
      paddingTop: 20,
      paddingBottom: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    singleFilter: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color: colors?.white,
      fontWeight: "bold",
      margin: "10px 10px 10px 0px",
      cursor: "pointer",
      outline: "solid 1px",
      minHeight: 50,
      borderRadius: 5,
    },
    inputsRecap: {
      color: colors?.white,
      fontWeight: "bold",
      textAlign: "left",
    },
    filterBox: {
      width: "90%",
      display: "flex",
      alignItems: "flex-start",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
  };
  return loader ? (
    <div style={styles.container}>
      <ReactLoading
        type={"spinningBubbles"}
        color={colors?.primary}
        height={50}
        width={50}
      />
    </div>
  ) : (
    <div style={styles.container}>
      <div style={styles.filterSection}>
        <div
          style={{
            width: "90%",
            marginLeft: 70,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <img
                  alt="Filter icon"
                  src={filterIcon}
                  style={{ width: 10, objectFit: "contain" }}
                />
                <p
                  style={{
                    color: colors?.white,
                    fontWeight: "bold",
                    margin: 0,
                    marginLeft: 10,
                  }}
                >
                  FILTRA
                </p>
              </div>
              {!filterApplied && !filtersAlreadyReset() && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => resetFilters()}
                >
                  <img
                    alt="Refresh inputs"
                    src={refreshInputs}
                    style={{
                      width: 8,
                      height: 8,
                    }}
                  ></img>
                  <p
                    style={{
                      color: colors?.white,
                      marginBlock: 0,
                      marginInline: 5,
                      fontSize: 11,
                      textDecoration: "underline",
                    }}
                  >
                    Ripristina filtri di default
                  </p>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {filterFields.map((filter, key) => {
                  return filterLabel(filter, key);
                })}
              </div>
              <div>
                {areThereFilters() && (
                  <button
                    style={styles.button}
                    onClick={() =>
                      filterApplied ? setFilterApplied(false) : startSearch()
                    }
                  >
                    {filterApplied ? "MODIFICA FILTRI" : "FILTRA"}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div style={styles.filterBox}>{showFilter()}</div>
          <div style={{ width: "90%" }}>
            <p
              style={{
                ...styles.inputsRecap,
                marginBottom: 0,
                fontWeight: "normal",
                fontStyle: "italic",
                fontSize: 11,
              }}
            >
              Riepilogo filtri selezionati
            </p>
            <p style={{ ...styles.inputsRecap, marginBlock: 5 }}>
              {inputsRecap}
            </p>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "90%" }}>
        <div>
          <p
            style={{
              color: colors?.gray,
              fontSize: 11,
              textAlign: "left",
              marginTop: 20,
              marginBottom: 0,
            }}
          >
            {currentFiltersRecap}
          </p>
        </div>
        <div>
          <p
            style={{
              color: colors?.gray,
              fontSize: 11,
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            {totalItemsCount +
              " ingress" +
              (totalItemsCount === 1 ? "o" : "i") +
              " trovat" +
              (totalItemsCount === 1 ? "o" : "i")}
          </p>
        </div>
      </div>
      <div id="scroll" style={styles.bottomSection}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            margin: entrancesReport.length !== 0 ? "0px auto" : "none",
          }}
        >
          <div id="scroll" style={styles.scrollerX}>
            <div style={styles.header}>
              {entrancesReport.length === 0 ? (
                <div style={styles.row}>
                  <p style={{ ...styles.labelTable, marginBlock: 30 }}>
                    Nessun risultato
                  </p>
                </div>
              ) : (
                labels.map((label, key) => {
                  return (
                    <div style={styles.labelTable} key={key}>
                      <p>{label}</p>
                    </div>
                  );
                })
              )}{" "}
            </div>
            <div>
              {entrancesReport?.map((entrance, key) => {
                return (
                  <Entrance
                    key={key}
                    entrance={entrance}
                    setEntranceSelected={setEntranceSelected}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {showDeleteEntrance && (
          <RemoveEntrance
            entrance={entranceSelected}
            closeModal={() => {
              setShowDeleteEntrance(false);
            }}
            deleteEntrance={deleteEntrance}
            colors={colors}
          />
        )}
        {entrancesReport.length > 0 && (
          <DownloadButton getPdf={getPdf} getExcel={getExcel} colors={colors} />
        )}
      </div>
      <div style={{ width: "100%" }}>
        {entrancesReport.length > 0 &&
          totalItemsCount > itemsPerPageOptions[0] && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridAutoRows: "1fr",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <select
                  name="options"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: 15,
                    height: 50,
                    width: 70,
                    padding: 10,
                    textAlign: "left",
                    marginRight: 10,
                    marginLeft: 40,
                  }}
                  value={itemsPerPage}
                  onChange={(event) => {
                    setItemsPerPage(parseInt(event.target.value));
                    setActivePage(1);
                  }}
                >
                  {itemsPerPageOptions.map((option, key) => {
                    return (
                      <option key={key} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
                <p style={{ color: colors?.gray, fontSize: 12 }}>
                  elementi per pagina
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => setActivePage(pageNumber)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
export default EntrancesHistory;
