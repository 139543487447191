import React from "react";
import moment from "moment";
import users from "./../../../img/user_secondary.png";
const EventInfo = ({ event, idSelected, setEventSelected, colors }) => {
  const styles = {
    reservation: {
      flexDirection: "row",
      display: "flex",
      width: "100%",
      height: 40,
      alignItems: "center",
      justifyContent: "space-around",
      cursor: "pointer",
    },
    contacts: {
      flexDirection: "column",
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    info: {
      flex: 1,
      flexDirection: "row",
      display: "flex",
      alignItems: "center",
      fontSize: 14,
      marginLeft: 15,
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      cursor: "pointer",
    },
    textInput: {
      borderRadius: 5,
      border: 0,
      outline: "none",
      textAlign: "center",
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
      width: 30,
    },
  };
  return (
    <div
      style={{
        width: "100%",
        marginBottom: 20,
        backgroundColor: idSelected === event.id && colors.quaternary,
      }}
    >
      <div
        style={{ ...styles.reservation }}
        onClick={() => setEventSelected(event)}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "90%",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <p style={{ ...styles.info, flex: 3}}>
            {moment(event.ora_inizio).format("HH:mm")}
          </p>
          <p style={{ ...styles.info, flex: 30, alignItems: "center" }}>
            {event.nome_evento}
          </p>
          <div style={{ ...styles.info, flex: 1 }}>
            <img alt="Reserved icon" src={users} style={{ height: 15 }} />
            <p style={{ marginLeft: 10 }}>{event.posti_prenotati}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventInfo;
