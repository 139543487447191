import React, { useEffect, useState } from "react";
import CodesScanner from "../../components/codesScanner";
import ReactLoading from "react-loading";

const VouchersValidator = ({ vouchersCategories, colors, orgId }) => {
  const [chosenVouchersCategory, setChosenVouchersCategory] = useState(null);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (vouchersCategories.length > 0) {
      setChosenVouchersCategory(vouchersCategories[0]);
      setLoader(false);
    } else {
      setLoader(true);
    }
  }, [vouchersCategories]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      overflowX: "scroll",
      marginBottom: 20,
    },
    label: {
      fontSize: 13,
      color: colors.gray,
    },
    menu: {
      border: "1px solid lightgray",
      width: 150,
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 10,
      color: colors.darkgray,
      borderRadius: 20,
      backgroundColor: "transparent",
      outline: "none",
    },
    rowTop: {
      width: "90%",
      height: 150,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      borderBottom: "1px solid darkgray",
    },
  };

  return loader ? (
    <div
      style={{
        display: "flex",
        marginTop: 40,
        alignItems: "center",
        justifyContent: "center",
        width: 1200,
      }}
    >
      <ReactLoading
        type={"spinningBubbles"}
        color={colors.darkgray}
        height={50}
        width={50}
      />
    </div>
  ) : (
    <div style={styles.container}>
      <div style={styles.body}>
        <div style={styles.rowTop}>
          <div style={styles.selectBox}>
            <p style={styles.label}>
              Seleziona la categoria per la quale convalidare i voucher
            </p>
            <select
              name={chosenVouchersCategory?.nome}
              style={styles.menu}
              value={chosenVouchersCategory?.id}
              onChange={(event) => {
                setChosenVouchersCategory(
                  vouchersCategories[event.target.selectedIndex]
                );
              }}
            >
              {vouchersCategories.map((type, key) => (
                <option key={key} value={type.id}>
                  {type.nome}
                </option>
              ))}
            </select>
          </div>
        </div>

        <CodesScanner orgId={orgId} category={chosenVouchersCategory} />
      </div>
    </div>
  );
};

export default VouchersValidator;
