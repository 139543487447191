import config from "../config";
import { handleResponse, authHeader } from "../_helpers";

export const customersService = {
  createCustomer,
  deleteCustomer,
  getCustomers,
  getCustomerFromPartitaIVA,
  getCustomerFromFiscalCode,
  updateCustomer,
  countCustomers,
  getVisitors,
  countVisitors,
  updateVisitor,
  createVisitor,
  deleteVisitor,
};

function createCustomer(customer) {
  var ragione_sociale = customer.ragione_sociale;
  var codice_fiscale = customer.codice_fiscale;
  var codice_destinatario = customer.codice_destinatario;
  var telefono = customer.telefono;
  var nazione = customer.nazione;
  var provincia = customer.provincia;
  var comune = customer.comune;
  var cap = customer.cap;
  var via = customer.via;

  // not mandatory
  var cig = customer.cig;
  var pec = customer.pec;
  var cup = customer.cup;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      ragione_sociale,
      codice_fiscale,
      codice_destinatario,
      telefono,
      nazione,
      provincia,
      comune,
      cap,
      via,
      cig,
      pec,
      cup,
    }),
  };

  return fetch(`${config.api.url}/info_entis`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteCustomer(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/info_entis/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function getCustomers(start, limit, searchKey) {
  const _start = start === undefined ? 0 : start;
  const _limit = limit === undefined ? -1 : limit;
  const _searchKey = searchKey === undefined ? "" : searchKey;

  const requestOptions = { method: "GET", headers: authHeader() };
  if (_searchKey === "") {
    return fetch(
      `${config.api.url}/info_entis?_start=${_start}&_limit=${_limit}`,
      requestOptions
    ).then(handleResponse);
  } else {
    return fetch(
      `${config.api.url}/info_entis?_start=${_start}&_limit=${_limit}&ragione_sociale_contains=${_searchKey}`,
      requestOptions
    ).then(handleResponse);
  }
}

function getCustomerFromPartitaIVA(partitaIVA) {
  const _partitaIVA =
    partitaIVA === undefined ? "00000000000000000" : partitaIVA;

  const requestOptions = { method: "GET", headers: authHeader() };

  return fetch(
    `${config.api.url}/info_entis?partita_iva=${_partitaIVA}`,
    requestOptions
  ).then(handleResponse);
}

function getCustomerFromFiscalCode(fiscalCode) {
  const _fiscalCode = fiscalCode === undefined ? "0000000" : fiscalCode;

  const requestOptions = { method: "GET", headers: authHeader() };

  return fetch(
    `${config.api.url}/info_entis?codice_fiscale=${_fiscalCode}`,
    requestOptions
  ).then(handleResponse);
}

function updateCustomer(customer) {
  var ragione_sociale = customer.ragione_sociale;
  var codice_fiscale = customer.codice_fiscale;
  var codice_destinatario = customer.codice_destinatario;
  var telefono = customer.telefono;
  var nazione = customer.nazione;
  var provincia = customer.provincia;
  var comune = customer.comune;
  var cap = customer.cap;
  var via = customer.via;

  // not mandatory
  var cig = customer.cig;
  var pec = customer.pec;
  var cup = customer.cup;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      ragione_sociale,
      codice_fiscale,
      codice_destinatario,
      telefono,
      nazione,
      provincia,
      comune,
      cap,
      via,
      cig,
      pec,
      cup,
    }),
  };

  return fetch(`${config.api.url}/info_entis/${customer.id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return false;
    });
}

function countCustomers(searchKey) {
  const requestOptions = { method: "GET", headers: authHeader() };
  var req = `${config.api.url}/info_entis/count`;
  const _searchKey = searchKey;
  if (_searchKey) {
    req = req + `?ragione_sociale_contains=${_searchKey}`;
  }
  return fetch(req, requestOptions).then(handleResponse);
}

function getVisitors(orgId, start, limit, searchKey) {
  const _start = start === undefined ? 0 : start;
  const _limit = limit === undefined ? -1 : limit;
  const _searchKey = searchKey === undefined ? "" : searchKey;

  var qs = require("qs");

  const query = qs.stringify({
    _where: {
      _or: [
        { nome_contains: _searchKey },
        { cognome_contains: _searchKey },
        { email_contains: _searchKey },
      ],
    },
  });

  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/info-visitatoris?_start=${_start}&_limit=${_limit}&organisation=${orgId}&${query}`,
    requestOptions
  ).then(handleResponse);
}

function countVisitors(orgId, searchKey) {
  var qs = require("qs");

  const _searchKey = searchKey === undefined ? "" : searchKey;
  const query = qs.stringify({
    _where: {
      _or: [
        { nome_contains: _searchKey },
        { cognome_contains: _searchKey },
        { email_contains: _searchKey },
      ],
    },
  });

  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/info-visitatoris/count?organisation=${orgId}&${query}`,
    requestOptions
  ).then(handleResponse);
}

function updateVisitor(visitor) {
  var nome = visitor.nome;
  var cognome = visitor.cognome;
  var email = visitor.email;
  var codice_fiscale = visitor.codice_fiscale;
  var data_nascita = visitor.data_nascita;
  var via = visitor.via;
  var comune = visitor.comune;
  var provincia = visitor.provincia;
  var cap = visitor.cap;
  var nazione = visitor.nazione;
  var genere = visitor.genere;
  var additional_infos = visitor.additional_infos;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      nome,
      cognome,
      codice_fiscale,
      email,
      data_nascita,
      via,
      comune,
      provincia,
      cap,
      nazione,
      genere,
      additional_infos,
    }),
  };

  return fetch(
    `${config.api.url}/info-visitatoris/${visitor.id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return false;
    });
}

function createVisitor(orgId, visitor) {
  var organisation = orgId;
  var nome = visitor.nome;
  var cognome = visitor.cognome;
  var email = visitor.email;
  var codice_fiscale = visitor.codice_fiscale;
  var data_nascita = visitor.data_nascita;
  var via = visitor.via;
  var comune = visitor.comune;
  var provincia = visitor.provincia;
  var cap = visitor.cap;
  var nazione = visitor.nazione;
  var genere = visitor.genere;
  var additional_infos = visitor.additional_infos;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      organisation,
      nome,
      cognome,
      codice_fiscale,
      email,
      data_nascita,
      via,
      comune,
      provincia,
      cap,
      nazione,
      genere,
      additional_infos,
    }),
  };

  return fetch(`${config.api.url}/info-visitatoris`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteVisitor(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/info-visitatoris/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}
