import React, { useState, useEffect } from "react";
import logo from "./../img/logo_white.png";
import show from "./../img/show.png";
import { authenticationService } from "../_services";
import colors from "../museumColors";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const ChangePassword = (props) => {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [password, setPassword] = useState("");
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isMobile, setIsMobile] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (windowSize.innerWidth > 800) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize.innerWidth]);

  const handleSubmit = (e) => {
    e.preventDefault();
    changePassword();
  };

  const goToLogin = () => {
    props.history.push("/");
  };
  /**
   * Call backend for login
   */
  const changePassword = () => {
    authenticationService
      .changePassword(props.match.params.code, password, confirmPassword)
      .then((response) => {
        if (response === false) {
          setShowError(true);
        } else {
          // login andato a buon fine
          setShowButton(true);
        }
      })
      .catch((errors) => {
        // c'è stato un errore, mostra il messaggio
        setShowError(true);
      });
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.secondary,
      overflow: "scroll",
    },
    header: {
      display: isMobile ? "" : "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "100px",
      marginTop: "75px",
    },
    mainContent: {
      display: "flex",
      width: "80%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 10,
      height: "60%",
    },
    content: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    textInput: {
      outline: "none",
      padding: 10,
      textAlign: "left",
      width: "60%",
      border: "1px solid lightgray",
      borderRadius: 5,
      fontFamily: "inherit",
      resize: "none",
      backgroundColor: colors.quaternary,
    },
    field: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      marginTop: 20,
    },
    label: {
      color: colors.primary,
      width: "150px",
      marginRight: 40,
    },
    password: {
      color: colors.primary,
      fontStyle: "italic",
      fontSize: 13,
    },
    message: {
      color: colors.white,
      fontWeight: 700,
      fontSize: 13,
      width: "100%",
      backgroundColor: "rgba(277,277,277,0.3)",
    },
    popup: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: 30,
      height: 200,
      backgroundColor: colors.white,
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <img
          alt="App logo"
          src={logo}
          style={{
            width: isMobile
              ? windowSize.innerWidth / 3
              : windowSize.innerWidth / 8,
            objectFit: "contain",
          }}
        />
      </div>
      <div style={styles.mainContent}>
        <div
          style={{
            width: "70%",
            height: "87%",
            marginTop: 20,
            marginRight: 20,
            backgroundColor: colors.white,
            padding: 30,
            borderRadius: 10,
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1 style={{ color: colors.primary }}>{"Bentornato in EMMA"}</h1>
          {!showButton && (
            <h3 style={{ color: colors.secondary }}>
              Compila i campi sottostanti per recuperare la password:
            </h3>
          )}
          {showError && (
            <div style={styles.message}>
              <p style={{ color: colors.primary }}>
                ATTENZIONE! Impossibile resettare la password.
              </p>{" "}
            </div>
          )}
          {showButton ? (
            <div style={styles.message}>
              <p
                style={{
                  color: colors.primary,
                  margin: 40,
                  marginBottom: 15,
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                La nuova password è stata salvata correttamente.
              </p>
              <p
                style={{
                  color: colors.primary,
                  margin: 40,
                  marginTop: 0,
                  fontSize: 15,
                  fontWeight: "lighter",
                }}
              >
                Esegui il login con le tue nuove credenziali.
              </p>
              <button onClick={() => goToLogin()} style={styles.button}>
                VAI AL LOGIN
              </button>
            </div>
          ) : (
            <div style={styles.content}>
              <form
                style={{ width: "80%", margin: 30 }}
                onSubmit={handleSubmit}
              >
                <div style={styles.field}>
                  <p style={styles.label}>Nuova Password</p>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    autoComplete="on"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder="Nuova password"
                    style={styles.textInput}
                  />
                  {password !== "" && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowPassword(!showPassword);
                      }}
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        outline: "none",
                        cursor: "pointer",
                        marginLeft: -50,
                      }}
                    >
                      <img
                        alt="Show password"
                        src={show}
                        style={{ width: 20, objectFit: "contain" }}
                      />
                    </button>
                  )}
                </div>
                <div style={styles.field}>
                  <p style={styles.label}>Conferma Password</p>
                  <input
                    type={showConfirmation ? "text" : "password"}
                    name="confirmPassword"
                    autoComplete="on"
                    value={confirmPassword || ""}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                    placeholder="Conferma Password"
                    style={styles.textInput}
                  />
                  {confirmPassword !== "" && (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowConfirmation(!showConfirmation);
                      }}
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        outline: "none",
                        cursor: "pointer",
                        marginLeft: -50,
                      }}
                    >
                      <img
                        alt="Show password"
                        src={show}
                        style={{ width: 20, objectFit: "contain" }}
                      />
                    </button>
                  )}
                </div>
              </form>
              {confirmPassword !== "" && password !== confirmPassword && (
                <div
                  style={{
                    color: colors.primary,
                    fontSize: 13,
                    marginBottom: "20px",
                  }}
                >
                  Le password non corrispondono.
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <button
                  onClick={() => changePassword()}
                  style={{
                    ...styles.button,
                    opacity:
                      confirmPassword &&
                      password &&
                      password === confirmPassword &&
                      !showButton
                        ? 1
                        : 0.3,
                  }}
                  disabled={
                    !(
                      confirmPassword &&
                      password &&
                      password === confirmPassword &&
                      !showButton
                    )
                  }
                >
                  AGGIORNA
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{ display: "flex", flex: 2 }}></div>
    </div>
  );
};

export default ChangePassword;
