import React, { Component } from "react";
import museum from "./../../museumColors";
import network from "../../networkColors";
import SubBar from "./cardsBar";
import CardsManager from "./cardsManager/cardsManager";
import CardsCreator from "./cardsCreator/cardsCreator";
import CardsActivator from "./cardsActivator/cardsActivator";
import InvoiceManager from "./invoiceManager/invoiceManager";
import { cardsService } from "../../_services";

export default class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0, // screen width
      height: 0, // screen height,
      loader: true,
      colors: this.props.isNetwork ? network : museum,
      selection:
        props.index !== undefined && !isNaN(parseInt(props.index))
          ? parseInt(props.index)
          : props.permissions?.tutte
          ? 1
          : props.permissions?.nuova
          ? 2
          : props.permissions?.attiva
          ? 3
          : props.permissions?.fatture
          ? 4
          : 1,
      cardTypes: [],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.selected = (id) => {
      const { cardTypes } = this.state;
      switch (id) {
        case 1:
          return (
            <CardsManager
              colors={this.state.colors}
              cardTypes={cardTypes}
              orgId={this.props.orgId}
            />
          );
        case 2:
          return (
            <CardsCreator
              colors={this.state.colors}
              cardTypes={cardTypes}
              orgId={this.props.orgId}
            />
          );
        case 3:
          return (
            <CardsActivator
              colors={this.state.colors}
              cardTypes={cardTypes}
              orgId={this.props.orgId}
            />
          );
        case 4:
          return (
            <InvoiceManager
              colors={this.state.colors}
              cardTypes={cardTypes}
              orgId={this.props.orgId}
            />
          );
        default:
          break;
      }
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.getCardTypes();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isNetwork !== this.props.isNetwork) {
      this.setState({
        colors: this.props.isNetwork ? network : museum,
        loader: true,
      });
    }
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getCardTypes() {
    cardsService.getCardTypes(this.props.orgId).then((cards) => {
      this.setState({
        cardTypes: cards,
      });
    });
  }

  render() {
    const { colors, selection } = this.state;
    const styles = {
      container: {
        display: "flex",
        flexDirection: "row",
        height: "100%",
        width: this.props.iframe ? "100%" : "85%",
        position: "absolute",
        right: 0,
        alignItems: "center",
        overflow: "scroll",
      },
    };
    return (
      <div style={styles.container}>
        {!this.props.iframe && (
          <SubBar
            colors={colors}
            selection={selection}
            setSelection={(section) => this.setState({ selection: section })}
            isNetwork={this.props.isNetwork}
            cardTypes={this.state.cardTypes}
            permissions={this.props.permissions}
          />
        )}
        {this.selected(selection)}
      </div>
    );
  }
}
