import React, { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../../../contexts";
import swap from "../../../img/swap.png";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const CrossedFiltersBox = (props) => {
  const colors = useContext(UserContext)?.colors;

  //Being a crossed-filters chart, it needs two distinct cateogories for filtering data
  const [firstSelection, setFirstSelection] = useState("");
  const prevFirstSelection = usePrevious(firstSelection);
  const [secondSelection, setSecondSelection] = useState("");
  const prevSecondSelection = usePrevious(secondSelection);

  //The following two indexes are used to avoid the same filter in the two select components
  const [firstIndex, setFirstIndex] = useState(0);
  const [secondIndex, setSecondIndex] = useState(0);

  //These values are modified each time a new index is selected to disable the options
  const [firstDisabled, setFirstDisabled] = useState(3);
  const [secondDisabled, setSecondDisabled] = useState(3);

  const [filters, setFilters] = useState([]);

  const swapSelections = () => {
    setFirstSelection(filters[filters?.length - 1 - secondIndex]);

    let reversed = filters.slice(0).reverse();
    setSecondSelection(reversed[filters?.length - 1 - firstIndex]);

    setFirstIndex(filters?.length - 1 - secondIndex);
    setSecondIndex(filters?.length - 1 - firstIndex);
  };

  //Selecting the same categories in both filters would be useless.
  //We have listed the categories in reversed order in the two filters so that it is easier to distinguish them
  useEffect(() => {
    setSecondDisabled(filters?.length - 1 - firstIndex);
  }, [firstIndex, filters]);

  useEffect(() => {
    setFirstDisabled(filters?.length - 1 - secondIndex);
  }, [secondIndex, filters]);

  //Each time a new filter is selected, the update of the chart is triggered
  useEffect(() => {
    if (
      prevFirstSelection !== undefined &&
      prevFirstSelection !== firstSelection &&
      firstSelection !== "" &&
      firstSelection !== undefined
    ) {
      props.handleFirstSelection(firstSelection);
    }

    if (
      prevSecondSelection !== undefined &&
      prevSecondSelection !== secondSelection &&
      secondSelection !== "" &&
      secondSelection !== undefined
    ) {
      props.handleSecondSelection(secondSelection);
    }
  }, [
    firstSelection,
    prevFirstSelection,
    secondSelection,
    prevSecondSelection,
    props,
  ]);

  useEffect(() => {
    if (props.contentFilters) {
      let tmpList = props.contentFilters;
      setFirstSelection(tmpList[0]);
      setSecondSelection(tmpList[tmpList.length - 1]);

      setFilters(tmpList);
    }
  }, [props.contentFilters]);

  const styles = {
    container: {
      backgroundColor: colors?.darkBackground,
      width: "98%",
      display: "flex",
      flexDirection: "column",
      margin: 10,
      borderRadius: 10,
    },
    header: {
      backgroundColor: colors?.secondary,
      height: 50,
      alignItems: "center",
      justifyContent: "space-between",
      display: "flex",
      outline: "none",
      border: "none",
      borderRadius: 10,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    title: {
      color: colors?.white,
      marginLeft: 30,
      fontSize: 18,
    },
    dropdown: {
      backgroundColor: colors?.primary,
      borderColor: colors?.primary,
      color: colors?.white,
      height: 40,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      margin: 1,
      marginRight: 10,
      borderRadius: 10,
      fontSize: 15,
      padding: 10,
    },
    lable: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    body: {
      margin: 10,
    },
    button: {
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
      outline: "none",
      marginRight: "20px",
      marginLeft: "15px",
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <p style={styles.title}>{props.title}</p>
        <div style={styles.lable}>
          <div style={styles.lable}>
            <select
              name={"Filtro 1"}
              style={styles.dropdown}
              value={firstSelection}
              onChange={(chosenCategory) => {
                setFirstSelection(chosenCategory.target.value);
                setFirstIndex(chosenCategory.target.selectedIndex);
              }}
            >
              {filters &&
                filters.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={item}
                      disabled={index === firstDisabled}
                    >
                      {item.charAt(0).toUpperCase() + item.slice(1)}
                    </option>
                  );
                })}
            </select>
          </div>
          <button style={styles.button} onClick={() => swapSelections()}>
            <img
              alt="Swap icon"
              src={swap}
              style={{ width: 25, objectFit: "contain" }}
            />
          </button>
          <div style={styles.lable}>
            <select
              name={"Filtro 2"}
              style={styles.dropdown}
              value={secondSelection}
              onChange={(chosenCategory) => {
                setSecondSelection(chosenCategory.target.value);
                setSecondIndex(chosenCategory.target.selectedIndex);
              }}
            >
              {filters &&
                filters
                  .slice(0)
                  .reverse()
                  .map((item, index) => {
                    return (
                      <option
                        key={index + 1}
                        value={item}
                        disabled={index === secondDisabled}
                      >
                        {item.charAt(0).toUpperCase() + item.slice(1)}
                      </option>
                    );
                  })}
            </select>
          </div>
        </div>
      </div>
      <div style={styles.body}>{props.body}</div>
    </div>
  );
};

export default CrossedFiltersBox;
