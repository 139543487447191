import React from "react";
import moment from "moment";
import "moment/locale/it";
moment.locale("it");

const titles = [
  "",
  "Card",
  "Venduta il",
  "Dove",
  "Metodo di pagamento",
  "Acquirente",
  "Tipologia",
];
const Table = ({
  cards,
  colors,
  setOpenRecord,
  tab,
  activePage,
  itemsPerPage,
}) => {
  const styles = {
    table: {
      textAlign: "center",
      borderCollapse: "collapse",
      border: "1px solid #ddd",
      width: "100%",
      overflow: "scroll",
    },
    headerCell: {
      border: "1px solid #ddd",
      padding: "8px",
      paddingTop: "12px",
      paddingBottom: "12px",
      textAlign: "center",
      backgroundColor: colors.verylightgray,
      fontSize: 12,
      color: colors.gray,
    },
    field: {
      border: "1px solid #ddd",
      padding: "8px",
      fontSize: 12,
    },
    row: {
      cursor: "pointer",
    },
  };

  const camelCase = (str) => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  const renderTableHeader = (titles) => {
    return titles.map((key, index) => {
      return (
        <th style={styles.headerCell} key={index}>
          {key.toUpperCase()}
        </th>
      );
    });
  };

  const renderTableData = () => {
    return cards.map((card, key) => {
      const {
        tessera,
        nome,
        cognome,
        created_at,
        luogo_vendita,
        data_fattura,
        metodo_pagamento,
        rinnovo,
      } = card;
      return (
        <tr key={key} style={styles.row} onClick={() => setOpenRecord(card)}>
          <td style={styles.field}>
            {key + (activePage - 1) * itemsPerPage + 1}
          </td>
          <td style={styles.field}>{tessera}</td>

          <td style={styles.field}>
            {moment(new Date(created_at)).format("DD/MM/yyyy, HH:mm")}
          </td>
          <td style={styles.field}>{camelCase(luogo_vendita)}</td>
          <td style={styles.field}>
            {camelCase(metodo_pagamento) === "Sospeso"
              ? "Sospeso di cassa"
              : camelCase(metodo_pagamento)}
          </td>
          <td style={styles.field}>
            {camelCase(nome) + " " + camelCase(cognome)}
          </td>
          <td style={styles.field}>{camelCase(rinnovo)}</td>
          {tab === 1 && (
            <td style={styles.field}>
              {moment(new Date(data_fattura)).format("DD/MM/yyyy, HH:mm")}
            </td>
          )}
        </tr>
      );
    });
  };
  return (
    <table style={styles.table}>
      <tbody>
        <tr>
          {renderTableHeader(tab === 1 ? [...titles, "Emessa il"] : titles)}
        </tr>
        {renderTableData()}
      </tbody>
    </table>
  );
};

export default Table;
