import React, { useEffect, useState, useContext } from "react";
import { UserContext, OrgContext } from "../../contexts";
import { LanguageContext } from "../../containers/language";
import { settingsService, cardsService } from "../../_services";
import ReactLoading from "react-loading";
import moment from "moment";
import "moment/locale/it";

function isEmpty(value) {
  return value === "" || value === null || value === undefined;
}

function dateInPast(firstDate, secondDate) {
  if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
    return true;
  }
  return false;
}
const EndSale = React.memo(
  ({
    shoppingCart,
    discountApplied,
    setDiscountApplied,
    payment,
    setPayment,
    membershipCard,
    setMembershipCard,
  }) => {
    const colors = useContext(UserContext)?.colors;
    const user = useContext(UserContext)?.user;
    const modules = useContext(OrgContext)?.org?.moduli;
    const [netModules, setNetModules] = useState([]);

    const { dictionary } = useContext(LanguageContext);

    const [discountCode, setDiscountCode] = useState("");
    const [message, setMessage] = useState("");
    const [membershipCardNumber, setMembershipCardNumber] = useState("");
    const [cardMessage, setCardMessage] = useState("");
    const [loader, setLoader] = useState(false);

    useEffect(() => {
      setLoader(true);
      if (user.org_rete && user.org_rete !== undefined) {
        settingsService.getOrganisationFromId(user.org_rete).then((netOrg) => {
          setNetModules(netOrg.moduli);
          setLoader(false);
        });
      } else {
        setLoader(false);
      }
    }, [user.org_rete]);

    const styles = {
      container: {
        width: "80%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "column",
      },
      box: {
        display: "flex",
        flexDirection: "column",
        width: "80%",
        boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
      },
      header: {
        height: 50,
        backgroundColor: colors?.quaternary,
        boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      body: {
        padding: 30,
      },
      textInput: {
        outline: "none",
        border: "1px solid lightgray",
        width: "80%",
        height: 20,
        borderRadius: 10,
        marginRight: 50,
        fontSize: 12,
        padding: 10,
        textAlign: "center",
      },
      button: {
        textAlign: "left",
        color: colors?.white,
        backgroundColor: colors?.darkgray,
        padding: 10,
        borderRadius: 10,
        fontSize: 13,
        cursor: "pointer",
      },
    };

    const checkDiscount = (discountCode) => {
      settingsService
        .checkDiscount(discountCode)
        .then((discounts) => {
          if (discounts.length > 0) {
            let discount = discounts[0];
            if (discount.flag_abilitato) {
              for (let item of shoppingCart) {
                if (discount.categorie.includes(item.categoria_articolo.id)) {
                  let discountedPrice =
                    item.prezzo_articolo -
                    (item.prezzo_articolo * discount.sconto) / 100;
                  discountedPrice = Math.round(discountedPrice * 100) / 100;
                  item.prezzo_scontato = discountedPrice;
                }
              }
              setDiscountApplied(true);
              setMessage("");
            } else {
              setMessage("Codice disabilitato");
              setDiscountApplied(false);
            }
          } else {
            setMessage("Codice inesistente");
            setDiscountApplied(false);
          }
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            this.props.history.push("/");
          }
        });
    };

    const removeDiscountCode = () => {
      setDiscountCode("");
      for (let item of shoppingCart) {
        item.prezzo_scontato = undefined;
      }
    };

    const checkCard = (cardNumber) => {
      cardsService
        .checkCardNumber(cardNumber, user.org_museo)
        .then((response) => {
          if (response.length > 0) {
            if (dateInPast(new Date(), new Date(response[0].scadenza))) {
              setMembershipCard(response[0]);
              setCardMessage("");
            } else {
              setCardMessage(
                "Errore. Card scaduta il " +
                  moment(new Date(response[0].scadenza)).format("DD/MM/YYYY")
              );
              setMembershipCard(undefined);
            }
          } else if (user.org_rete) {
            cardsService
              .checkCardNumber(cardNumber, user.org_rete)
              .then((response) => {
                if (response.length > 0) {
                  if (dateInPast(new Date(), new Date(response[0].scadenza))) {
                    setMembershipCard(response[0]);
                    setCardMessage("");
                  } else {
                    setCardMessage(
                      "Errore. Card scaduta il " +
                        moment(new Date(response[0].scadenza)).format(
                          "DD/MM/YYYY"
                        )
                    );
                    setMembershipCard(undefined);
                  }
                } else {
                  setCardMessage("Errore. Card inesistente");
                  setMembershipCard(undefined);
                }
              });
          } else {
            setCardMessage("Errore. Card inesistente");
            setMembershipCard(undefined);
          }
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            this.props.history.push("/");
          }
        });
    };

    const removeMembershipCard = () => {
      setMembershipCard(undefined);
      setMembershipCardNumber("");
    };

    useEffect(() => {
      if (isEmpty(discountCode)) {
        setDiscountApplied(false);
      }
    }, [discountCode, setDiscountApplied]);

    return (
      <div style={styles.container}>
        {loader ? (
          <>
            <ReactLoading
              type={"spinningBubbles"}
              color={colors.darkgray}
              height={50}
              width={50}
            />
          </>
        ) : (
          <>
            <div style={styles.box}>
              <div style={styles.header}>
                <p
                  style={{
                    width: "85%",
                    textAlign: "left",
                    color: colors?.gray,
                    fontSize: 13,
                  }}
                >
                  {dictionary.paymentMethod}
                </p>
              </div>
              <div style={styles.body}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: 13,
                    }}
                  >
                    <input
                      type="radio"
                      value="Cash"
                      checked={payment === "Contanti"}
                      onChange={() => setPayment("Contanti")}
                    />{" "}
                    {dictionary.cash}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: 13,
                    }}
                  >
                    <input
                      type="radio"
                      value="Pos"
                      checked={payment === "POS"}
                      onChange={() => setPayment("POS")}
                    />{" "}
                    {dictionary.pos}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: 13,
                    }}
                  >
                    <input
                      type="radio"
                      value="Sospeso"
                      checked={payment === "Sospeso di cassa"}
                      onChange={() => setPayment("Sospeso di cassa")}
                    />{" "}
                    {dictionary.cashPending}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: 13,
                    }}
                  >
                    <input
                      type="radio"
                      value="Bonifico"
                      checked={payment === "Bonifico"}
                      onChange={() => setPayment("Bonifico")}
                    />{" "}
                    {dictionary.transfer}
                  </div>
                </div>
              </div>
            </div>
            <div style={styles.box}>
              <div style={styles.header}>
                <p
                  style={{
                    width: "85%",
                    textAlign: "left",
                    color: colors?.gray,
                    fontSize: 13,
                  }}
                >
                  {"Codice sconto"}
                </p>
              </div>
              <div style={styles.body}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {discountApplied ? (
                      <p
                        style={{
                          color: colors?.darkgray,
                          fontSize: 12,
                          width: "85%",
                          textAlign: "left",
                        }}
                      >
                        Codice sconto <b>{discountCode}</b> applicato.
                      </p>
                    ) : (
                      <input
                        type="text"
                        style={styles.textInput}
                        value={discountCode}
                        onChange={(event) =>
                          setDiscountCode(event.target.value.toUpperCase())
                        }
                        placeholder={dictionary.discountCode}
                      />
                    )}
                    <p
                      style={styles.button}
                      onClick={() =>
                        discountApplied
                          ? removeDiscountCode()
                          : checkDiscount(discountCode)
                      }
                    >
                      {discountApplied ? "Rimuovi" : dictionary.apply}
                    </p>
                  </div>
                  {message !== "" && (
                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        color: colors?.highlight,
                        fontSize: 11,
                        margin: 0,
                        marginTop: 2,
                      }}
                    >
                      {message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {(modules.map((mod) => mod.id).includes(10) ||
              netModules.map((mod) => mod.id).includes(10)) && (
              <div style={styles.box}>
                <div style={styles.header}>
                  <p
                    style={{
                      width: "85%",
                      textAlign: "left",
                      color: colors?.gray,
                      fontSize: 13,
                    }}
                  >
                    {"Fidelity Card"}
                  </p>
                </div>
                <div style={styles.body}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {membershipCard ? (
                        <p
                          style={{
                            color: colors?.darkgray,
                            fontSize: 12,
                            width: "85%",
                            textAlign: "left",
                          }}
                        >
                          Tessera n° <b>{membershipCard.numero}</b> intestata a{" "}
                          <b>
                            {membershipCard.nome} {membershipCard.cognome}
                          </b>{" "}
                          riconosciuta.
                        </p>
                      ) : (
                        <input
                          type="text"
                          style={styles.textInput}
                          value={membershipCardNumber}
                          onChange={(event) =>
                            setMembershipCardNumber(
                              event.target.value.toLowerCase()
                            )
                          }
                          placeholder={dictionary.membershipCard}
                        />
                      )}
                      <p
                        style={styles.button}
                        onClick={() =>
                          membershipCard
                            ? removeMembershipCard()
                            : checkCard(membershipCardNumber)
                        }
                      >
                        {membershipCard ? "Rimuovi" : dictionary.verify}
                      </p>
                    </div>
                    {cardMessage !== "" && (
                      <p
                        style={{
                          width: "100%",
                          textAlign: "center",
                          color: colors?.highlight,
                          fontSize: 11,
                          margin: 0,
                          marginTop: 2,
                        }}
                      >
                        {cardMessage}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
);

export default EndSale;
