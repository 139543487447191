import React, { useState } from "react";

const SubButton = ({
  icon,
  mod,
  selectionColor,
  hoverColor,
  onClick,
  selection,
  size,
}) => {
  const [color, setColor] = useState("transparent");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        backgroundColor: selection === mod.id ? selectionColor : color,
      }}
      onClick={onClick}
      onMouseEnter={() => setColor(hoverColor)}
      onMouseLeave={() => setColor("transparent")}
    >
      <img
        alt="Settings icon"
        src={icon}
        style={{
          width: size,
          objectFit: "contain",
          marginLeft: 15,
          marginRight: 15,
        }}
      />
      <p style={{ color: "white", fontSize: 12, textAlign: "left" }}>
        {mod.name}
      </p>
    </div>
  );
};

export default SubButton;
