import React, { useState, useEffect, useContext } from "react";
import { OrgContext } from "../../../contexts";
import close from "./../../../img/closeButton.png";
import "react-datepicker/dist/react-datepicker.css";
import "./../../../customizer.css";
import ReactLoading from "react-loading";
import { itemsService } from "../../../_services";

function isTicket(categoriesList, id) {
  var result = false;
  for (let i = 0; i < categoriesList.length; i++) {
    if (
      categoriesList[i].id === id &&
      categoriesList[i].nome_categoria.toUpperCase() === "BIGLIETTI"
    ) {
      result = true;
    }
  }
  return result;
}

function isBook(categoriesList, id) {
  var result = false;
  for (let i = 0; i < categoriesList.length; i++) {
    if (
      categoriesList[i].id === id &&
      categoriesList[i].nome_categoria.toUpperCase() === "LIBRI"
    ) {
      result = true;
    }
  }
  return result;
}

function getLowestId(categoriesList) {
  var lowestId = categoriesList[0].id;
  for (let i = 0; i < categoriesList.length; i++) {
    if (categoriesList[i].id < lowestId) {
      lowestId = categoriesList[i].id;
    }
  }
  return lowestId;
}

const ModifyItem = ({
  item,
  closeModal,
  categoriesList,
  saveData,
  colors,
  isNetwork,
}) => {
  const [step, setStep] = useState(1);
  const [category, setCategory] = useState(
    item ? item.categoria_articolo.id : getLowestId(categoriesList)
  );
  const [details, setDetails] = useState(item ? item.nome_articolo : "");
  const [author, setAuthor] = useState(item ? item.autore : "");
  const [price, setPrice] = useState(item ? item.prezzo_articolo : "");
  const [loader, setLoader] = useState(false);
  const [entrances, setEntrances] = useState(item ? item.num_ingressi : 0);
  const [validity, setValidity] = useState(item ? item.validita : 0);
  const [startingName, setStartingName] = useState(
    item ? item.nome_articolo : ""
  );
  const [alreadyUsedName, setAlreadyUsedName] = useState(false);
  const org = useContext(OrgContext)?.org;

  useEffect(() => {
    setStartingName(item ? item.nome_articolo : "");
  }, [item]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 30,
      margin: "auto",
      width: 600,
      zIndex: 300,
      justifyContent: "center",
    },
    loaderBox: {
      display: "flex",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      zIndex: 300,
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      display: "flex",
      width: "100%",
      height: 60,
      backgroundColor: colors.primary,
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    content: {
      width: "100%",
      maxHeight: 300,
      flexDirection: "column",
      backgroundColor: colors.white,
      display: "flex",
      alignItems: "center",
      overflow: "scroll",
    },
    textInput: {
      border: "1px solid lightgray",
      borderRadius: 10,
      width: "100%",
      padding: 18,
      outline: "none",
    },
    field: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginTop: 20,
    },
    footer: {
      display: "flex",
      width: "100%",
      height: 120,
      backgroundColor: colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      marginTop: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    disabledButton: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      marginTop: 40,
      border: "none",
      outline: "none",
      opacity: 0.7,
    },
    menu: {
      border: "1px solid lightgray",
      width: "100%",
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 10,
      color: colors.darkgray,
      borderRadius: 20,
    },
    label: {
      fontSize: 15,
      color: colors.lightgray,
      margin: 20,
      width: 400,
      textAlign: "left",
    },
    box: {
      width: 400,
      display: "flex",
    },
    warning: {
      color: "red",
      marginLeft: 10,
      fontSize: 14,
      marginTop: 40,
      width: 400,
      textAlign: "left",
    },
  };
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: colors.gray,
          opacity: 0.7,
        }}
      />
      {loader ? (
        <div style={styles.loaderBox}>
          <ReactLoading
            type={"spinningBubbles"}
            color={colors.primary}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <div style={styles.container}>
          <div style={styles.header}>
            <p style={{ color: colors.white, fontSize: 20 }}>
              MODIFICA ARTICOLO
            </p>
            <button
              onClick={closeModal}
              style={{
                position: "absolute",
                right: 30,
                marginTop: "auto",
                marginBottom: "auto",
                border: "none",
                backgroundColor: "transparent",
                outline: "none",
                cursor: "pointer",
              }}
            >
              <img
                alt="App logo"
                src={close}
                style={{ width: 15, objectFit: "contain" }}
              />
            </button>
          </div>
          {step === 1 && (
            <>
              <div style={styles.content} id="scroll">
                {alreadyUsedName && (
                  <p style={styles.warning}>
                    Il nome scelto per{" "}
                    {isTicket(categoriesList, category)
                      ? "il biglietto è già stato usato per un altro biglietto"
                      : "l'articolo è già stato usato per un altro articolo nella stessa categoria"}
                  </p>
                )}
                {!isTicket(categoriesList, category) && (
                  <div style={styles.field}>
                    <p style={styles.label}>Categoria</p>
                    <div style={styles.box}>
                      <select
                        name="user"
                        style={styles.menu}
                        value={category}
                        onChange={(event) => setCategory(event.target.value)}
                      >
                        {categoriesList.map((cat, key) => {
                          return (
                            <option key={key} value={cat.id}>
                              {cat.nome_categoria}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}
                <div style={styles.field}>
                  <p style={styles.label}>
                    {isBook(categoriesList, category) ? "Titolo" : "Articolo"}
                  </p>
                  <div style={styles.box}>
                    <input
                      value={details}
                      onChange={(event) => setDetails(event.target.value)}
                      placeholder="Nome articolo"
                      style={styles.textInput}
                    />
                  </div>
                </div>
                {isBook(categoriesList, category) && author && (
                  <div style={styles.field}>
                    <p style={styles.label}>Autore</p>
                    <div style={styles.box}>
                      <input
                        value={author}
                        onChange={(event) => setAuthor(event.target.value)}
                        placeholder="Autore"
                        style={styles.textInput}
                      />
                    </div>
                  </div>
                )}
                <div style={styles.field}>
                  <p style={styles.label}>Prezzo</p>
                  <div
                    style={{
                      width: 400,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ marginRight: 30 }}>€</p>
                    <input
                      value={price}
                      onChange={(event) => {
                        setPrice(event.target.value.replace(/[^0-9.]/, ""));
                      }}
                      placeholder="Prezzo"
                      style={styles.textInput}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div style={styles.content} id="scroll">
                <div
                  style={{
                    ...styles.field,
                    flexDirection: "row",
                    width: "70%",
                  }}
                >
                  <p style={{ ...styles.label, flex: 1 }}>
                    {isNetwork
                      ? "Numero di ingressi per museo consentiti con lo stesso biglietto"
                      : "Numero di ingressi consentiti con lo stesso biglietto"}
                  </p>
                  <div
                    style={{
                      width: 90,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      value={entrances}
                      onChange={(event) => setEntrances(event.target.value)}
                      placeholder="Ingressi"
                      style={{ ...styles.textInput, flex: 1 }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    ...styles.field,
                    flexDirection: "row",
                    width: "70%",
                  }}
                >
                  <p style={{ ...styles.label, flex: 1 }}>
                    Validità dalla data d'acquisto
                  </p>
                  <div
                    style={{
                      width: 90,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      value={validity}
                      onChange={(event) => setValidity(event.target.value)}
                      placeholder="Giorni"
                      style={{ ...styles.textInput, flex: 1 }}
                    />
                  </div>
                  <p style={{ marginLeft: 10 }}>giorni</p>
                </div>
              </div>
            </>
          )}
          <div style={styles.footer}>
            <button
              onClick={() => {
                if (isTicket(categoriesList, category) && step === 1) {
                  setStep(2);
                } else {
                  setLoader(true);
                  itemsService
                    .getItems(org.id, 0, -1, category, details, author)
                    .then((res) => {
                      if (res.length === 0 || startingName === details) {
                        setAlreadyUsedName(false);
                        saveData(
                          details,
                          author,
                          parseInt(category),
                          price && parseFloat(price),
                          isTicket(categoriesList, category)
                            ? parseInt(entrances)
                            : null,
                          isTicket(categoriesList, category)
                            ? parseInt(validity)
                            : null
                        );
                      } else {
                        setAlreadyUsedName(true);
                      }
                      setLoader(false);
                    });
                }
              }}
              style={
                (step === 1 && details === undefined) ||
                (step === 2 && price < 0)
                  ? styles.disabledButton
                  : styles.button
              }
              disabled={
                (step === 1 && details === undefined) ||
                (step === 2 && price < 0)
              }
            >
              {isTicket(categoriesList, category) && step === 1
                ? "AVANTI"
                : "MODIFICA"}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ModifyItem;
