import React from "react";
import moment from "moment";
import add from "./../../../../img/add.png";
import placeholder from "./../../../../img/placeholder_img.png";
import "moment/locale/it";
moment.locale("it");

const ActivityBox = ({ activity, addFlag, toggleInfo, onAdd, colors }) => {
  const styles = {
    container: {
      gridArea: "act",
      display: "flex",
      flexDirection: "column",
      width: 220,
      height: 280,
      borderRadius: 30,
      alignItems: "center",
      boxShadow: "1px 2px 1px 1px #A9A9A9",
    },
    addContainer: {
      display: "flex",
      flexDirection: "column",
      width: 220,
      height: 280,
      backgroundColor: colors?.secondary,
      borderRadius: 30,
      justifyContent: "center",
      alignItems: "center",
      opacity: 0.4,
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      display: "flex",
      flexDirection: "column",
      width: 250,
      height: 300,
      borderRadius: 30,
      justifyContent: "center",
      alignItems: "center",
      margin: 10,
      cursor: "pointer",
    },
    header: {
      height: 110,
      width: "100%",
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
    },
    body: {
      width: "100%",
      height: 180,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderBottomLeftRadius: 30,
      borderBottomRightRadius: 30,
    },
    title: {
      color: colors?.primary,
      maxWidth: 180,
      fontWeight: "700",
      fontSize: 18,
      overflow: "hidden",
      maxHeight: 40,
      margin: 10,
    },
    data: {
      color: colors?.primary,
      width: "80%",
      fontSize: 12,
      textAlign: "center",
    },
    location: {
      color: colors?.secondary,
      maxWidth: 200,
    },
  };

  return (
    <button
      style={styles.button}
      onClick={() => (addFlag ? onAdd() : toggleInfo())}
    >
      {addFlag ? (
        <div style={styles.addContainer}>
          <img
            alt="Add icon"
            src={add}
            style={{ width: 65, objectFit: "contain" }}
          />
        </div>
      ) : (
        <div style={styles.container}>
          <div style={styles.header}>
            <img
              alt="Header"
              src={activity?.immagine ? activity.immagine.url : placeholder}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderTopLeftRadius: 30,
                borderTopRightRadius: 30,
              }}
            />
          </div>
          <div style={styles.body}>
            <p style={styles.title}>{activity?.nome}</p>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <p style={styles.data}>
                {activity?.abstract?.substring(0, 100)}
                {activity?.abstract?.length > 100 && "..."}
              </p>
            </div>
          </div>
        </div>
      )}
    </button>
  );
};

export default ActivityBox;
