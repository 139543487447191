import React, { useState, useEffect, useCallback, useContext } from "react";
import AddButton from "../../components/buttons/addButton";
import Category from "./category";
import AddCategory from "../../components/popup/addCategory";
import ModifyCategory from "../../components/popup/modifyCategory";
import RemoveCategory from "../../components/popup/removeCategory";
import AddItem from "./popup/addItem";
import RemoveItem from "./popup/removeItem";
import { settingsService, itemsService } from "../../_services";
import EndOperation from "../../components/popup/endOperation";
import ModifyItem from "./popup/modifyItem";
import AddFromFile from "./popup/addFromFile";
import ReactLoading from "react-loading";
import DownloadInventoryButton from "../../components/buttons/downloadButton";
import ModifyStockItem from "./popup/modifyStockItem";
import { UserContext } from "../../contexts";

const ModifyStock = (props) => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [purchaseMethods, setPurchaseMethods] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showAddItem, setShowAddItem] = useState(false);
  const [existing, setExisting] = useState(null);
  const [isbnFound, setIsbnFound] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [showModify, setShowModify] = useState(false);
  const [showDeleteItem, setShowDeleteItem] = useState(false);
  const [showModifyItem, setShowModifyItem] = useState(false);
  const [showAddStockItem, setShowAddStockItem] = useState(false);
  const [flagAddStockItem, setFlagAddStockItem] = useState(false);
  const [showModifyStockItem, setShowModifyStockItem] = useState(false);
  const [showAddExcel, setShowAddExcel] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [showEndOperation, setShowEndOperation] = useState(false);
  const [operationResult, setOperationResult] = useState(true);
  const [operation, setOperation] = useState(null);
  const [resultWarning, setResultWarning] = useState(null);
  const [settingsId, setSettingsId] = useState(null);
  const [loader, setLoader] = useState(true);
  const ready = useState(false);
  const colors = useContext(UserContext)?.colors;

  const getCategories = useCallback(() => {
    itemsService
      .getCategories()
      .then((categoriesList) => {
        setCategoriesList(categoriesList);
        itemsService
          .getPurchaseMethods()
          .then((methods) => {
            setPurchaseMethods(methods);
            setLoader(false);
          })
          .catch((errors) => {
            if (errors.statusCode === 401 || errors.statusCode === 403) {
              props.history.push("/");
            }
          });
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  }, [props.history]);

  const getOrgSettings = useCallback(() => {
    settingsService
      .getOrganisation()
      .then((org) => {
        setSettingsId(org.settings.id);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  }, [props.history]);

  useEffect(() => {
    getCategories();
    getOrgSettings();
  }, [getCategories, getOrgSettings]);

  const getPdf = () => {
    setLoader(true);
    itemsService
      .getInventoryPdf()
      .then((file) => {
        setLoader(false);
        var a = document.createElement("a");
        a.href = file.url;
        a.target = "_blank";
        a.download = "inventario.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const getExcel = () => {
    setLoader(true);
    itemsService
      .getInventoryExcel()
      .then((file) => {
        setLoader(false);
        var a = document.createElement("a");
        a.href = file.url;
        a.download = "inventario.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onAdd = (newCategory) => {
    setLoader(true);
    toggleAdd();
    let nome_categoria = newCategory;
    itemsService
      .createCategory(nome_categoria)
      .then(() => {
        getCategories();
        setShowEndOperation(true);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onModify = (newName) => {
    setLoader(true);
    toggleModify(null);
    let id = itemSelected.id;
    let nome_categoria = newName;
    itemsService
      .modifyCategory(id, nome_categoria)
      .then(() => {
        getCategories();
        setShowEndOperation(true);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onDelete = () => {
    setLoader(true);
    toggleDelete(null);
    let id = itemSelected.id;
    itemsService
      .deleteCategory(id)
      .then(() => {
        getCategories();
        setShowEndOperation(true);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onAddItem = (
    codice_articolo,
    nome_articolo,
    autore,
    categoria_articolo,
    modalita_acquisto,
    venditore_articolo,
    quantita_ordine,
    percentuale_guadagno,
    scadenza_conto_vendita,
    costo_articolo,
    prezzo_articolo,
    num_ingressi,
    validita
  ) => {
    setLoader(true);
    if (!flagAddStockItem) {
      toggleAddItem();
    } else {
      toggleAddStockItem();
    }
    itemsService
      .createItem(
        codice_articolo,
        nome_articolo,
        autore,
        parseInt(categoria_articolo),
        parseInt(modalita_acquisto),
        venditore_articolo,
        parseInt(quantita_ordine),
        parseFloat(percentuale_guadagno),
        scadenza_conto_vendita,
        parseFloat(costo_articolo),
        parseFloat(prezzo_articolo),
        parseInt(num_ingressi),
        parseInt(validita)
      )
      .then((response) => {
        if (response.error !== undefined) {
          setResultWarning(response.error);
          setOperationResult(false);
        } else {
          setOperationResult(true);
        }
        getCategories();
        setShowEndOperation(true);
        setExisting(null);
        setIsbnFound(null);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onAddArticlePurchase = (
    modalita_acquisto,
    scadenza_conto_vendita,
    quantita,
    venditore,
    percentuale_guadagno,
    costo_articolo,
    giacenza,
    codice_articolo,
    flag,
    id,
    nome_articolo,
    autore,
    categoria_articolo,
    prezzo_articolo
  ) => {
    setLoader(true);
    if (!flagAddStockItem) {
      toggleAddItem();
    } else {
      toggleAddStockItem();
    }
    let everythingsOk = true;
    if (flag) {
      itemsService
        .modifyItem(
          id,
          nome_articolo,
          autore,
          categoria_articolo,
          prezzo_articolo,
          null,
          null
        )
        .then((response) => {
          if (response === false) {
            everythingsOk = false;
          }
        });
    }
    itemsService
      .addArticlePurchase(
        modalita_acquisto,
        scadenza_conto_vendita,
        quantita,
        venditore,
        percentuale_guadagno,
        costo_articolo,
        giacenza,
        codice_articolo
      )
      .then((response) => {
        if (!everythingsOk || response === false) {
          setOperationResult(false);
        } else {
          setOperationResult(true);
        }
        setShowEndOperation(true);
        getCategories();
        setExisting(null);
        setIsbnFound(null);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onDecreaseQuantity = (codice_articolo, quantita_ordine) => {
    setLoader(true);
    toggleModifyStockItem();
    itemsService
      .decreaseQuantity(codice_articolo, parseInt(quantita_ordine))
      .then(() => {
        getCategories();
        setShowEndOperation(true);
        setExisting(null);
        setIsbnFound(null);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onModifyItem = (
    nome_articolo,
    autore,
    categoria_articolo,
    prezzo_articolo,
    num_ingressi,
    validita
  ) => {
    setLoader(true);
    if (itemSelected.deleted !== true) {
      toggleModifyItem();
    } else {
      toggleAddItem();
    }
    let id = itemSelected.id;
    itemsService
      .modifyItem(
        id,
        nome_articolo,
        autore,
        categoria_articolo,
        prezzo_articolo,
        num_ingressi,
        validita
      )
      .then(() => {
        getCategories();
        setShowEndOperation(true);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onDeleteItem = () => {
    setLoader(true);
    toggleDeleteItem();
    let id = itemSelected.id;
    itemsService
      .deleteItem(id)
      .then(() => {
        getCategories();
        setShowEndOperation(true);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const updateOrder = (id, order) => {
    itemsService.updateOrderCategory(id, order).catch((errors) => {
      if (errors.statusCode === 401 || errors.statusCode === 403) {
        props.history.push("/");
      }
    });
  };

  const updateAscendant = (id, ascendant) => {
    itemsService.updateAscendantCategory(id, ascendant).catch((errors) => {
      if (errors.statusCode === 401 || errors.statusCode === 403) {
        props.history.push("/");
      }
    });
  };

  const toggleAdd = () => {
    if (!showAdd) {
      setOperation(0);
    }
    setShowAdd(!showAdd);
  };

  const toggleModify = (cat) => {
    setItemSelected(cat);
    if (!showModify) {
      setOperation(1);
    }
    setShowModify(!showModify);
  };

  const toggleDelete = (cat) => {
    setItemSelected(cat);
    if (!showDelete) {
      setOperation(2);
    }
    setShowDelete(!showDelete);
  };

  const toggleAddItem = () => {
    if (!showAddItem) {
      setOperation(0);
    } else {
      setItemSelected(null);
    }
    setShowAddItem(!showAddItem);
  };

  const toggleModifyItem = (item) => {
    if (!showModifyItem) {
      setItemSelected(item);
      setOperation(1);
    } else {
      setItemSelected(null);
    }
    setShowModifyItem(!showModifyItem);
  };

  const toggleDeleteItem = (item) => {
    if (!showDeleteItem) {
      setItemSelected(item);
      setOperation(2);
    } else {
      setItemSelected(null);
    }
    setShowDeleteItem(!showDeleteItem);
  };

  const toggleAddStockItem = (item) => {
    if (!showAddStockItem) {
      setItemSelected(item);
      setOperation(6);
    } else {
      setItemSelected(null);
    }
    setShowAddStockItem(!showAddStockItem);
    setFlagAddStockItem(!flagAddStockItem);
  };

  const toggleModifyStockItem = (item) => {
    if (!showModifyStockItem) {
      setItemSelected(item);
      setOperation(4);
    } else {
      setItemSelected(null);
    }
    setShowModifyStockItem(!showModifyStockItem);
  };

  const toggleEndOperation = () => {
    setShowEndOperation(!showEndOperation);
  };

  const toggleAddExcel = () => {
    if (!showAddExcel) {
      setOperation(0);
    } else {
      getCategories();
      window.location.reload(true);
    }
    setShowAddExcel(!showAddExcel);
  };

  const checkCode = (isbn) => {
    setLoader(true);
    itemsService.findItem(isbn).then((itemFound) => {
      if (itemFound === -1) {
        setExisting(false);
        let categoryId = categoriesList.filter(
          (cat) => cat.nome_categoria.toLowerCase() === "libri"
        )[0].id;
        itemsService
          .checkCode(isbn)
          .then((bookInfo) => {
            if (bookInfo !== 1) {
              let book = {};
              book.nome_articolo = bookInfo.title;
              book.autore = bookInfo.authors ? bookInfo.authors[0] : null;
              let categoria_articolo = {};
              categoria_articolo.id = categoryId;
              categoria_articolo.nome_categoria = "Libri";
              book.categoria_articolo = categoria_articolo;
              setItemSelected(book);
              setIsbnFound(true);
              setLoader(false);
            } else {
              setIsbnFound(false);
              setLoader(false);
            }
          })
          .catch((errors) => {
            if (errors.statusCode === 401 || errors.statusCode === 403) {
              props.history.push("/");
            }
          });
      } else {
        setExisting(true);
        setItemSelected(itemFound);
        setLoader(false);
      }
    });
  };

  const setUnknown = () => {
    setExisting(false);
    setIsbnFound(false);
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    button: {
      outline: "none",
      border: "none",
      display: "flex",
      backgroundColor: colors?.primary,
      width: 80,
      height: 80,
      borderRadius: 50,
      alignItems: "center",
      justifyContent: "center",
      textDecorationLine: "none",
      color: colors?.white,
      fontWeight: "800",
      cursor: "pointer",
    },
    loading: {
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  return (
    <div style={loader ? styles.loading : null}>
      <AddButton
        flag={categoriesList.length > 0}
        toggleAdd={() => toggleAdd()}
        toggleAddItem={() => toggleAddItem()}
        toggleAddExcel={() => toggleAddExcel()}
        colors={colors}
      />
      {loader ? (
        <div style={styles.container}>
          <ReactLoading
            type={"spinningBubbles"}
            color={colors?.primary}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <div style={styles.container}>
          {categoriesList.map((cat, key) => {
            return (
              <Category
                key={key}
                onDelete={() => toggleDelete(cat)}
                onModify={() => toggleModify(cat)}
                onDeleteItem={toggleDeleteItem}
                onModifyItem={toggleModifyItem}
                onAddStockItem={toggleAddStockItem}
                onModifyStockItem={toggleModifyStockItem}
                category={cat}
                purchaseMethods={purchaseMethods}
                updateAscendant={(ascendente) =>
                  updateAscendant(cat.id, ascendente)
                }
                updateOrder={(ordine) => updateOrder(cat.id, ordine)}
                colors={colors}
              />
            );
          })}
        </div>
      )}
      <DownloadInventoryButton
        ready={ready}
        getPdf={getPdf}
        getExcel={getExcel}
        colors={colors}
      />
      {showAdd && (
        <AddCategory closeModal={toggleAdd} saveData={onAdd} colors={colors} />
      )}
      {showModify && (
        <ModifyCategory
          name={itemSelected.nome_categoria}
          closeModal={toggleModify}
          saveData={onModify}
          colors={colors}
        />
      )}
      {showDelete && (
        <RemoveCategory
          name={itemSelected.nome_categoria}
          closeModal={toggleDelete}
          saveData={onDelete}
          colors={colors}
        />
      )}
      {showAddItem && (
        <AddItem
          closeModal={toggleAddItem}
          item={itemSelected}
          categoriesList={categoriesList}
          checkCode={checkCode}
          existing={existing}
          isbnFound={isbnFound}
          saveData={onAddItem}
          modifyDeletedTicket={onModifyItem}
          addArticlePurchase={onAddArticlePurchase}
          setUnknown={setUnknown}
          colors={colors}
          isNetwork={props.isNetwork}
        />
      )}
      {showModifyItem && (
        <ModifyItem
          closeModal={toggleModifyItem}
          item={itemSelected}
          categoriesList={categoriesList}
          saveData={onModifyItem}
          colors={colors}
          isNetwork={props.isNetwork}
        />
      )}
      {showDeleteItem && (
        <RemoveItem
          name={itemSelected.nome_articolo}
          closeModal={toggleDeleteItem}
          categoriesList={categoriesList}
          existing={existing}
          saveData={onDeleteItem}
          colors={colors}
        />
      )}
      {showAddStockItem && (
        <AddItem
          closeModal={toggleAddStockItem}
          item={itemSelected}
          categoriesList={categoriesList}
          checkCode={checkCode}
          existing={existing}
          isbnFound={isbnFound}
          saveData={onAddItem}
          modifyDeletedTicket={onModifyItem}
          addArticlePurchase={onAddArticlePurchase}
          setUnknown={setUnknown}
          colors={colors}
          isNetwork={props.isNetwork}
          addNewPurchase={true}
          prevFlag={true}
          prevStep={1}
        />
      )}
      {showModifyStockItem && (
        <ModifyStockItem
          item={itemSelected}
          closeModal={toggleModifyStockItem}
          colors={colors}
          decreaseQuantity={onDecreaseQuantity}
        />
      )}
      {showAddExcel && (
        <AddFromFile
          closeModal={toggleAddExcel}
          settingsId={settingsId}
          colors={colors}
        />
      )}
      {showEndOperation && (
        <EndOperation
          flag={operationResult}
          operation={operation}
          closeModal={toggleEndOperation}
          colors={colors}
          warning={resultWarning}
        />
      )}
    </div>
  );
};

export default ModifyStock;
