import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts";
import ReactLoading from "react-loading";
import LineChart from "./lineChart";
import DoughnutChart from "./doughnutChart";
import Accesses from "./accesses";
import CrossedFilters from "./crossedFilters";
import BarChart from "./barChart";

const DataVisualization = (props) => {
  const colors = useContext(UserContext)?.colors;

  const [tab, setTab] = useState(props.tab);

  //We need to throw to the parent the info that this is not the report page, so that the correct top bar is shown
  useEffect(() => {
    props.setIsReport(false);
    setTab(props.tab);
  }, [props]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      right: 0,
      alignItems: "center",
      overflow: "scroll",
    },
    row: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  };
  return props.loader ? (
    //Show a loader while getting data from the DB
    <ReactLoading
      type={"spinningBubbles"}
      color={colors?.primary}
      height={50}
      width={50}
    />
  ) : (
    <div style={styles.container}>
      <div style={{ ...styles.row, justifyContent: "center" }}>
        <Accesses
          visitorsData={props.visitorsData}
          totVis={props.totVis}
          tab={tab}
        />
      </div>
      {!props.hideLine && (
        <div style={styles.row}>
          <LineChart
            temporalFilters={props.filters}
            isAnnual={props.isAnnual}
            tab={tab}
          />
        </div>
      )}
      <div style={styles.row}>
        <DoughnutChart
          temporalFilters={props.filters}
          isAnnual={props.isAnnual}
          isFree={props.isFree}
          tab={tab}
        />
      </div>
      <div style={styles.row}>
        <BarChart
          temporalFilters={props.filters}
          isAnnual={props.isAnnual}
          isFree={props.isFree}
          tab={tab}
        />
      </div>
      <div style={styles.row}>
        <CrossedFilters
          temporalFilters={props.filters}
          isFree={props.isFree}
          tab={tab}
        />
      </div>
    </div>
  );
};

export default DataVisualization;
