import React, { useEffect, useState } from "react";
import trashsec from "./../../img/delete_secondary.png";
import pen from "./../../img/modify_secondary.png";
import duplicate from "./../../img/duplicate.png";
import moment from "moment";

function calculateTimeDifference(startTime, endTime) {
  // calculate total duration
  var duration = moment.duration(endTime.diff(startTime));
  // duration in minutes
  var minutes = parseInt(duration.asMinutes());
  return minutes;
}

const TurnInfo = ({
  turn,
  idSelected,
  onModifyTurn,
  onCopyTurn,
  onDeleteTurn,
  staffList,
  colors,
}) => {
  const [openModify, setOpenModify] = useState(false);
  const [user, setUser] = useState(turn.id_user);
  const [starting, setStarting] = useState(turn.ora_inizio);
  const [ending, setEnding] = useState(turn.ora_fine);
  const [task, setTask] = useState(turn.mansione);
  const [color, setColor] = useState("transparent");

  const [dateLimit, setDateLimit] = useState(new Date());

  const [timeError, setTimeError] = useState(false);

  useEffect(() => {
    let tmpDate = dateLimit;
    tmpDate.setHours(0, 0, 0, 0);
    setDateLimit(tmpDate);

    setTimeError(
      starting !== "" &&
        ending !== "" &&
        (moment(starting).format("HH:mm") !== "00:00" ||
          moment(ending).format("HH:mm") !== "00:00") &&
        starting >= ending
    );
  }, [starting, ending, dateLimit]);

  const preventTurnCreation = () => {
    return timeError || task === "";
  };

  const styles = {
    turn: {
      flexDirection: "row",
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-around",
    },
    contacts: {
      flexDirection: "column",
      display: "flex",
      width: "90%",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 0,
    },
    info: {
      flex: 1,
      flexDirection: "column",
      display: "flex",
      fontSize: 14,
      marginLeft: 15,
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      cursor: "pointer",
    },
    textInput: {
      borderRadius: 5,
      border: 0,
      outline: "none",
      textAlign: "center",
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
      width: 30,
    },
    menu: {
      border: "1px solid lightgray",
      width: "100%",
      padding: 10,
      color: colors.darkgray,
      borderRadius: 10,
      backgroundColor: colors.white,
      outline: "none",
      marginBottom: 10,
    },
  };
  React.useEffect(() => {
    setUser(turn.id_user);
    setStarting(turn.ora_inizio);
    setEnding(turn.ora_fine);
    setTask(turn.mansione);
  }, [turn]);

  return (
    <div
      style={{
        backgroundColor: idSelected === turn.id ? colors.quaternary : color,
        width: "100%",
        paddingTop: 5,
        paddingBottom: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginBottom: 3,
      }}
      onMouseEnter={() => setColor(colors.quaternary)}
      onMouseLeave={() => setColor("transparent")}
    >
      <div style={styles.turn}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: openModify ? "90%" : "70%",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {openModify ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                margin: 5,
              }}
            >
              <select
                name="user"
                style={styles.menu}
                value={user}
                onChange={(event) => setUser(event.target.value)}
              >
                {staffList.map((user, key) => {
                  return (
                    <option key={key} value={user.id}>
                      {user.username}
                    </option>
                  );
                })}
              </select>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <input
                  type="time"
                  name="starting"
                  value={moment(starting).format("HH:mm")}
                  onChange={(event) => {
                    let hour = event.target.value.split(":")[0];
                    let minutes = event.target.value.split(":")[1];
                    let temp = new Date(turn.ora_inizio);
                    temp.setHours(hour, minutes, 0, 0);
                    setStarting(temp);
                  }}
                  style={{
                    ...styles.textInput,
                    paddingTop: 5,
                    paddingBottom: 5,
                    margin: 0,
                    fontFamily: "Arial",
                    width: "35%",
                  }}
                />
                <input
                  type="time"
                  name="ending"
                  value={moment(ending).format("HH:mm")}
                  onChange={(event) => {
                    let hour = event.target.value.split(":")[0];
                    let minutes = event.target.value.split(":")[1];
                    let temp = new Date(turn.ora_inizio);
                    temp.setHours(hour, minutes, 0, 0);
                    setEnding(temp.toISOString());
                  }}
                  style={{
                    ...styles.textInput,
                    paddingTop: 5,
                    paddingBottom: 5,
                    margin: 0,
                    fontFamily: "Arial",
                    width: "35%",
                  }}
                />
              </div>
              {timeError && (
                <p
                  style={{
                    color: colors.highlight,
                    fontSize: 11,
                    marginLeft: 25,
                    marginRight: 25,
                    textAlign: "left",
                  }}
                >
                  L'orario di fine deve essere successivo all'orario di inizio
                  del turno.
                </p>
              )}
              <textarea
                type="text"
                name="task"
                value={task}
                onChange={(event) =>
                  setTask(event.target.value.replace("\n", ""))
                }
                placeholder="Mansione"
                style={{
                  ...styles.textInput,
                  width: "93%",
                  height: 80,
                  textAlign: "left",
                  padding: 10,
                  fontFamily: "Arial",
                  resize: "none",
                }}
              />
            </div>
          ) : (
            <div style={styles.info}>
              {Math.floor(
                calculateTimeDifference(
                  moment(starting, "HH:mm:ss a"),
                  moment(ending, "HH:mm:ss a")
                ) / 60
              ) > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    height: "100%",
                    borderRadius: 5,
                    fontSize: 12,
                  }}
                >
                  <p style={{ textAlign: "center", marginBottom: 0 }}>
                    {moment(starting).format("HH:mm")}
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      marginBottom: 0,
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  >
                    -
                  </p>
                  <p style={{ textAlign: "center", marginBottom: 0 }}>
                    {moment(ending).format("HH:mm")}
                  </p>
                </div>
              )}

              <div
                style={{
                  display: "flex",
                  flex: 3,
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <p
                  style={{
                    flex: 2,
                    textAlign: "left",
                    marginBottom: 0,
                  }}
                >
                  {turn.nome_user}
                </p>
              </div>
            </div>
          )}
        </div>
        {!openModify && (
          <div>
            {new Date(starting) > dateLimit && (
              <button
                style={styles.button}
                onClick={() => {
                  setOpenModify(!openModify);
                }}
              >
                <img
                  alt="Open Modify Event Button"
                  src={pen}
                  style={{ height: 17 }}
                />
              </button>
            )}
            <button
              style={styles.button}
              onClick={() => {
                onCopyTurn(turn, user, starting, ending, task);
              }}
            >
              <img
                alt="Open Modify Event Button"
                src={duplicate}
                style={{ height: 17 }}
              />
            </button>
            {new Date(starting) > dateLimit && (
              <button
                style={styles.button}
                onClick={() => onDeleteTurn(turn.id)}
              >
                <img
                  alt="Delete Event Button"
                  src={trashsec}
                  style={{ height: 17 }}
                />
              </button>
            )}
          </div>
        )}
      </div>
      {!openModify && (
        <div style={styles.contacts}>
          {turn.mansione && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginLeft: 15,
              }}
            >
              <p
                style={{ fontSize: 11, width: 40, textAlign: "left", flex: 1 }}
              >
                {task}
              </p>

              {Math.floor(
                calculateTimeDifference(
                  moment(starting, "HH:mm:ss a"),
                  moment(ending, "HH:mm:ss a")
                ) / 60
              ) > 0 && (
                <p style={{ fontSize: 11, textAlign: "left", flex: 1 }}>
                  {Math.floor(
                    calculateTimeDifference(
                      moment(starting, "HH:mm:ss a"),
                      moment(ending, "HH:mm:ss a")
                    ) / 60
                  ) + " h"}{" "}
                  {calculateTimeDifference(
                    moment(starting, "HH:mm:ss a"),
                    moment(ending, "HH:mm:ss a")
                  ) %
                    60 !==
                    0 &&
                    (calculateTimeDifference(
                      moment(starting, "HH:mm:ss a"),
                      moment(ending, "HH:mm:ss a")
                    ) %
                      60) +
                      " m"}
                </p>
              )}
            </div>
          )}
        </div>
      )}
      {openModify && (
        <button
          style={{
            ...styles.button,
            width: 200,
            backgroundColor: colors.secondary,
            color: colors.white,
            padding: 10,
            borderRadius: 15,
            margin: 30,
            cursor: "pointer",
            opacity: preventTurnCreation() ? 0.5 : 1,
          }}
          onClick={() => {
            onModifyTurn(parseInt(turn.id), user, starting, ending, task);
            setOpenModify(!openModify);
          }}
          disabled={preventTurnCreation()}
        >
          CONFERMA MODIFICA
        </button>
      )}
    </div>
  );
};

export default TurnInfo;
