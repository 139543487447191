import React, { useState, useContext, useEffect, useRef } from "react";
import { UserContext, OrgContext } from "../../../contexts";
import ReactLoading from "react-loading";
import { reportService } from "../../../_services";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const LinkedFiltersBox = (props) => {
  const colors = useContext(UserContext)?.colors;
  const org = useContext(OrgContext)?.org;

  //Being a crossed-filters chart, it needs two distinct cateogories for filtering data
  const [firstSelection, setFirstSelection] = useState(props.contentFilters[0]);
  const prevFirstSelection = usePrevious(firstSelection);
  const [secondSelection, setSecondSelection] = useState("");
  const prevSecondSelection = usePrevious(secondSelection);

  const [filters, setFilters] = useState([]);

  const [loader, setLoader] = useState(false);

  const [isFirst, setIsFirst] = useState(true);

  const [noObjects, setNoObjects] = useState(false);

  useEffect(() => {
    if (
      (prevFirstSelection !== undefined &&
        prevFirstSelection !== firstSelection &&
        firstSelection !== "" &&
        firstSelection !== undefined) ||
      isFirst
    ) {
      setLoader(true);
      setIsFirst(false);
      props.handleFirstSelection(firstSelection);
      reportService
        .getObjects(
          org,
          props.temporalFilters.start,
          props.temporalFilters.end,
          firstSelection
        )
        .then((objects) => {
          if (objects.labels.length <= 0) {
            setNoObjects(true);
          } else {
            setNoObjects(false);
          }
          setFilters(objects.labels);
          setSecondSelection(objects.labels[0]);
          setLoader(false);
        });
    }

    if (
      prevSecondSelection !== undefined &&
      prevSecondSelection !== secondSelection &&
      secondSelection !== "" &&
      secondSelection !== undefined
    ) {
      props.handleSecondSelection(secondSelection);
    }
  }, [
    org,
    firstSelection,
    prevFirstSelection,
    secondSelection,
    prevSecondSelection,
    props,
    isFirst,
  ]);

  const styles = {
    container: {
      backgroundColor: colors?.darkBackground,
      width: "98%",
      display: "flex",
      flexDirection: "column",
      margin: 10,
      borderRadius: 10,
    },
    header: {
      backgroundColor: colors?.secondary,
      height: 50,
      alignItems: "center",
      justifyContent: "space-between",
      display: "flex",
      outline: "none",
      border: "none",
      borderRadius: 10,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    title: {
      color: colors?.white,
      marginLeft: 30,
      fontSize: 18,
    },
    dropdown: {
      backgroundColor: colors?.primary,
      borderColor: colors?.primary,
      color: colors?.white,
      height: 40,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      margin: 1,
      marginRight: 10,
      borderRadius: 10,
      fontSize: 15,
      padding: 10,
    },
    lable: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    body: {
      margin: 10,
    },
    button: {
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
      outline: "none",
      marginRight: "20px",
      marginLeft: "15px",
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <p style={styles.title}>{props.title}</p>
        <div style={styles.lable}>
          <div style={styles.lable}>
            <select
              name={"Filtro 1"}
              style={styles.dropdown}
              value={firstSelection}
              onChange={(chosenCategory) => {
                setFirstSelection(chosenCategory.target.value);
              }}
            >
              {props.contentFilters &&
                props.contentFilters.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item.charAt(0).toUpperCase() + item.slice(1)}
                    </option>
                  );
                })}
            </select>
          </div>
          <div style={styles.lable}>
            {props.loader ? (
              <ReactLoading
                type={"spinningBubbles"}
                color={colors?.primary}
                height={50}
                width={50}
              />
            ) : loader ? (
              <div style={{ margin: "10px" }}>
                <ReactLoading
                  type={"spinningBubbles"}
                  color={colors?.primary}
                  height={20}
                  width={20}
                />
              </div>
            ) : noObjects ? (
              <h5 style={{ color: colors?.white, marginRight: "10px" }}>
                Nessun oggetto
              </h5>
            ) : (
              <select
                name={"Filtro 2"}
                style={styles.dropdown}
                value={secondSelection}
                onChange={(chosenCategory) => {
                  setSecondSelection(chosenCategory.target.value);
                }}
              >
                {filters &&
                  filters.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item?.charAt(0).toUpperCase() + item?.slice(1)}
                      </option>
                    );
                  })}
              </select>
            )}
          </div>
        </div>
      </div>
      <div style={styles.body}>{props.body}</div>
    </div>
  );
};

export default LinkedFiltersBox;
