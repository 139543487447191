import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../contexts";
import { Calendar, momentLocalizer } from "react-big-calendar";
import ReactLoading from "react-loading";
import { eventsService } from "../../../_services";
import moment from "moment";

const localizer = momentLocalizer(moment);

function isEmpty(value) {
  return value === null || value === undefined || value === "";
}
const ReservationCalendar = ({
  dateSelected,
  setDateSelected,
  setIsClosed,
  setOnBooking,
  setIdSelected,
  setEventSelected,
  setSelectedVisits,
  eventsList,
  setEventsList,
  visitsList,
  setVisitsList,
}) => {
  const [closingDays, setClosingDays] = useState([]);
  const [loader, setLoader] = useState(true);
  const colors = useContext(UserContext)?.colors;

  const onNavigate = (date) => {
    setLoader(true);
    setDateSelected(date);
    setEventSelected(null);
  };

  const onSelectEvent = (event) => {
    setEventSelected(event);
    setOnBooking(false);
  };

  const onSelectSlot = (slot) => {
    setDateSelected(slot.start);
  };

  useEffect(() => {
    if (!isEmpty(dateSelected)) {
      const newDate = moment(dateSelected).format("yyyy-MM-DD");

      const getClosingDays = (date) => {
        eventsService
          .getClosingDay(date)
          .then((closingDays) => {
            setClosingDays(closingDays);
            setIsClosed(
              closingDays.some(
                (day) => day === moment(date).format("yyyy-MM-DD")
              )
            );
          })
          .catch((errors) => {
            if (errors.statusCode === 401 || errors.statusCode === 403) {
              this.props.history.push("/");
            }
          });
      };

      const getEvents = (date) => {
        eventsService
          .getEvents(date)
          .then((events) => {
            events.forEach((event) => {
              event.ora_inizio = new Date(event.ora_inizio);
              event.ora_fine = new Date(event.ora_fine);
            });
            setEventsList(events);
            setLoader(false);
          })
          .catch((errors) => {
            if (errors.statusCode === 401 || errors.statusCode === 403) {
              this.props.history.push("/");
            }
          });
      };

      const getVisits = (date) => {
        eventsService
          .getVisits(date)
          .then((visits) => {
            visits.forEach((visit) => {
              visit.ora_inizio = new Date(visit.ora_inizio);
              visit.ora_fine = new Date(visit.ora_fine);
              visit.nome_evento =
                visit.cognome_partecipante + " x " + visit.numero_partecipanti;
            });
            setSelectedVisits(
              visits.filter(
                (visit) =>
                  moment(visit.ora_inizio).format("yyyy-MM-DD") ===
                  moment(date).format("yyyy-MM-DD")
              )
            );
            setVisitsList(visits);
          })
          .catch((errors) => {
            if (errors.statusCode === 401 || errors.statusCode === 403) {
              this.props.history.push("/");
            }
          });
      };
      //Check if moment(dateSelected).format("yyyy-MM-DD") instead of dateSelected
      getEvents(newDate);
      getVisits(newDate);
      getClosingDays(newDate);
      setOnBooking(false);
    }
  }, [
    dateSelected,
    setOnBooking,
    setIsClosed,
    setSelectedVisits,
    setVisitsList,
    setEventsList,
  ]);

  useEffect(() => {
    setDateSelected(new Date());
  }, [setDateSelected]);

  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        height: "100%",
        width: "100%",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: "90%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {loader ? (
          <ReactLoading
            type={"spinningBubbles"}
            color={colors?.primary}
            height={50}
            width={50}
          />
        ) : (
          <Calendar
            date={moment(dateSelected).toDate()}
            localizer={localizer}
            events={[...eventsList, ...visitsList]}
            titleAccessor="nome_evento"
            startAccessor="ora_inizio"
            endAccessor="ora_fine"
            resourceAccessor="id"
            selectable={true}
            onNavigate={(date) => onNavigate(date)}
            onDrillDown={(date) => onNavigate(date)}
            onSelectEvent={(event) => {
              if (event.flag_visita) {
                if (
                  moment(event.ora_inizio).format("yyyy-MM-DD") !==
                  moment(dateSelected).format("yyyy-MM-DD")
                ) {
                  onNavigate(event.ora_inizio);
                }
              } else {
                onSelectEvent(event);
              }
              setIdSelected(event.id);
            }}
            onSelectSlot={(slot) => onSelectSlot(slot)}
            messages={{
              next: "Successivo",
              previous: "Precedente",
              today: "Oggi",
              month: "Mese",
              week: "Settimana",
              day: "Giorno",
            }}
            views={["month", "week", "day"]}
            min={new Date(2021, 1, 1, 7, 0, 0)}
            scrollToTime={new Date()}
            style={{
              height: "95%",
              width: "95%",
              maxWidth: 900,
            }}
            eventPropGetter={(event, start, end, isSelected) => {
              let newStyle;
              if (!event.flag_visita) {
                newStyle = {
                  backgroundColor: isSelected
                    ? colors?.primary
                    : colors?.secondary,
                  color: colors?.white,
                  borderRadius: 5,
                  fontSize: 13,
                  outline: "none",
                  border: "2px solid #ffffff",
                };
              } else {
                newStyle = {
                  backgroundColor: isSelected ? colors?.primary : colors?.white,
                  color: isSelected ? colors?.white : colors?.primary,
                  borderRadius: 5,
                  outline: "none",
                  border: "2px solid #ffffff",
                  fontSize: 13,
                };
              }
              return {
                className: "",
                style: newStyle,
              };
            }}
            dayPropGetter={(day) => {
              let newStyle = {
                backgroundColor: "#f6f6f6",
                color: colors?.white,
                opacity: 1,
              };
              if (
                (closingDays.indexOf(moment(day).format("yyyy-MM-DD")) !== -1 &&
                  new Date(day).getMonth() ===
                    new Date(dateSelected).getMonth()) ||
                moment(day).add(1, "days") < moment()
              ) {
                return {
                  className: "",
                  style: newStyle,
                };
              } else {
                return null;
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ReservationCalendar;
