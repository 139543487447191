import React, { useRef, useEffect } from "react";

export default function Paypal({
  nome,
  prezzo,
  manageOrderResponse,
  enableLoading,
}) {
  const paypal = useRef();

  let response = {};
  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: nome,
                amount: {
                  currency_code: "EUR",
                  value: prezzo,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          enableLoading();
          const details = await actions.order.capture();

          response.status = true;
          response.data = details;
          manageOrderResponse(response);
        },
        onError: async (err) => {
          enableLoading();
          response.status = false;
          response.data = err;
          manageOrderResponse(response);
        },
      })
      .render(paypal.current);
  }, [nome, prezzo, manageOrderResponse, response, enableLoading]);

  return <div ref={paypal}></div>;
}
