import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../contexts";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./../../index.css";
import SubBar from "../../components/subBar";
import anagrafica from "./../../img/users.png";
import vendite from "./../../img/vendite.png";
import SalesHistory from "./salesHistory";
import EntrancesHistory from "./entrancesHistory";

const History = ({ isNetwork, history, permissions, iframe, index }) => {
  const [selection, setSelection] = useState(0);

  useEffect(() => {
    if (index !== undefined && !isNaN(parseInt(index))) {
      setSelection(parseInt(index));
    }
  }, [index]);

  const colors = useContext(UserContext)?.colors;
  const user = useContext(UserContext)?.user;

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      width: iframe ? "100%" : "85%",
      position: "absolute",
      right: 0,
      alignItems: "center",
    },
  };

  let buttons = [
    {
      icon: vendite,
      name: "Vendite",
      section: (
        <SalesHistory isNetwork={isNetwork} history={history} iframe={iframe} />
      ),
    },
  ];

  if (permissions) {
    buttons = [];
    if (permissions?.vendite) {
      buttons.push({
        icon: vendite,
        name: "Vendite",
        section: (
          <SalesHistory
            isNetwork={isNetwork}
            history={history}
            iframe={iframe}
          />
        ),
      });
    }

    if (permissions?.ingressi) {
      buttons.push({
        icon: anagrafica,
        name: "Ingressi",
        section: (
          <EntrancesHistory
            isNetwork={isNetwork}
            history={history}
            iframe={iframe}
          />
        ),
      });
    }
  }

  const selected = (id) => {
    return buttons[id].section;
  };

  return (
    <div style={styles.container}>
      {!iframe && (
        <SubBar
          colors={colors}
          selection={selection}
          setSelection={(selection) => setSelection(selection)}
          isNetwork={user?.flag_network}
          buttons={buttons}
        />
      )}
      {selected(selection)}
    </div>
  );
};

export default History;
