import React, { createContext, useState, useCallback, useMemo } from "react";
import { settingsService } from "../_services";
// create context
const OrgContext = createContext();

const OrgContextProvider = ({ children }) => {
  // the value that will be given to the context
  const [org, setOrg] = useState(null);

  // sign out the user, memoized
  const signout = useCallback(() => {
    setOrg(null);
  }, []);

  // Refresh the user status, memoized
  const fetch = useCallback(() => {
    const fetch = () => {
      settingsService
        .getOrganisation()
        .then((org) => setOrg(org))
        .catch((error) => {});
    };

    fetch();
  }, []);

  const contextValue = useMemo(
    () => ({
      org,
      fetch,
      signout,
    }),
    [org, fetch, signout]
  );

  return (
    // the Provider gives access to the context to its children
    <OrgContext.Provider value={contextValue}>{children}</OrgContext.Provider>
  );
};

export { OrgContext, OrgContextProvider };
