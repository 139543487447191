import React, { useEffect, useContext, useState, useRef } from "react";
import { authenticationService, usersService } from "../_services";
import SideBar from "../components/sideBar";
import Checkout from "../modules/checkout/checkout";
import Inventory from "../modules/inventory/inventory";
import Report from "../modules/report/report";
import Event from "../modules/events/events";
import Settings from "../modules/settings";
import Reservation from "../modules/reservations/reservations";
import Staff from "../modules/staff/staff";
import AddToNetwork from "../modules/addToNetwork";
import Cards from "../modules/cards/cards";
import InterventionRegister from "../modules/interventionRegister/interventions";
import { UserContext, OrgContext } from "../contexts";
import ReactLoading from "react-loading";
import Activities from "../modules/activities/activities/activities";
import Spaces from "../modules/spaces/spaces";
import Analytics from "../modules/analytics/analytics";
import Customers from "../modules/customers/customers";
import Vouchers from "../modules/vouchers/vouchers";
import History from "../modules/historian/history";
import defaultPermissions from "../assets/default_permissions.json";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function renderSwitch(
  param,
  user,
  history,
  colors,
  userPermissions,
  iframe,
  index2
) {
  if (user?.flag_network && user.org_rete === null) {
    return <AddToNetwork />;
  } else {
    switch (param) {
      case 1:
        return (
          <Checkout
            isNetwork={user?.flag_network}
            history={history}
            iframe={iframe}
          />
        );
      case 2:
        return (
          <Inventory
            isNetwork={user?.flag_network}
            history={history}
            permissions={userPermissions?.[2]}
            iframe={iframe}
          />
        );
      case 3:
        return (
          <Report
            isNetwork={user?.flag_network}
            history={history}
            iframe={iframe}
          />
        );
      case 4:
        return (
          <History
            isNetwork={user?.flag_network}
            history={history}
            permissions={userPermissions?.[4]}
            iframe={iframe}
            index={index2}
          />
        );
      case 5:
        return (
          <Event
            isNetwork={user?.flag_network}
            history={history}
            permissions={userPermissions?.[5]}
            iframe={iframe}
          />
        );
      case 6:
        return (
          <Activities
            isNetwork={user?.flag_network}
            history={history}
            permissions={userPermissions?.[6]}
            iframe={iframe}
            index={index2}
          />
        );
      case 7:
        return (
          <Reservation
            isNetwork={user?.flag_network}
            history={history}
            permissions={userPermissions?.[7]}
            iframe={iframe}
            index={index2}
          />
        );
      case 8:
        return (
          <Staff
            isNetwork={user?.flag_network}
            history={history}
            iframe={iframe}
          />
        );
      case 9:
        return (
          <Analytics
            isNetwork={user?.flag_network}
            history={history}
            permissions={userPermissions?.[9]}
            iframe={iframe}
            index={index2}
          />
        );
      case 10:
        return (
          <Cards
            isNetwork={user?.flag_network}
            history={history}
            orgId={user.organisation}
            permissions={userPermissions?.[10]}
            iframe={iframe}
            index={index2}
          />
        );
      case 11:
        return (
          <Spaces
            isNetwork={user?.flag_network}
            history={history}
            iframe={iframe}
          />
        );
      case 12:
        return (
          <Customers
            isNetwork={user?.flag_network}
            history={history}
            permissions={userPermissions?.[12]}
            iframe={iframe}
            index={index2}
          />
        );
      case 13:
        return (
          <InterventionRegister
            isNetwork={user?.flag_network}
            history={history}
            iframe={iframe}
          />
        );
      case 14:
        return (
          <Vouchers
            isNetwork={user?.flag_network}
            history={history}
            orgId={user.org_museo}
            permissions={userPermissions?.[14]}
            iframe={iframe}
            index={index2}
          />
        );
      case 101:
        return (
          <Settings
            isNetwork={user?.flag_network}
            history={history}
            iframe={iframe}
            index={index2}
          />
        );
      default:
        return (
          <div
            style={{
              flex: 1,
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={colors?.primary}
              height={50}
              width={50}
            />
          </div>
        );
    }
  }
}

async function logout(history, userSignout, orgSignout) {
  authenticationService.logout();
  userSignout();
  orgSignout();
  localStorage.removeItem("currentModule");
  history.push("/");
}

const Dashboard = (props) => {
  const user = useContext(UserContext)?.user;
  const fetchUser = useContext(UserContext)?.fetch;
  const userSignout = useContext(UserContext)?.signout;
  const fetchOrg = useContext(OrgContext)?.fetch;
  const orgSignout = useContext(UserContext)?.signout;
  const colors = useContext(UserContext)?.colors;
  const organisation = useContext(OrgContext).org;
  localStorage.getItem("currentModule")
    ? localStorage.getItem("currentModule")
    : localStorage.setItem(
        "currentModule",
        organisation?.moduli.sort((a, b) => a.number - b.number)[0].number
      );

  const [width, setWidth] = useState(window.innerWidth);
  const [selection, setSelection] = useState(
    localStorage.getItem("currentModule")
  );
  const [isNetwork, setIsNetwork] = useState(undefined);
  const prevIsNetwork = usePrevious({ isNetwork });
  const prevOrganisation = usePrevious({ organisation });
  const [userPermissions, setUserPermissions] = useState(undefined);
  const [filteredModuli, setFilteredModuli] = useState([]);

  if (user && userPermissions === undefined) {
    usersService
      .getPermissions(user?.role.name)
      .then((response) => {
        setUserPermissions(response);
      })
      .catch((error) => {
        console.error("Error during service worker registration:", error);
        setUserPermissions(defaultPermissions);
      });
  }

  let tmpOrg = organisation?.moduli;

  useEffect(() => {
    if (userPermissions && tmpOrg) {
      let moduli = tmpOrg.filter(
        (mod) =>
          userPermissions[mod.number] && userPermissions[mod.number].enabled
      );
      setFilteredModuli(moduli);
      return localStorage.setItem(
        "currentModule",
        moduli.sort((a, b) => a.number - b.number)[0]?.number
      );
    } else {
      return;
    }
  }, [userPermissions, tmpOrg]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors?.white,
    },
  };

  useEffect(() => {
    fetchUser();
    fetchOrg();
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, [fetchUser, fetchOrg]);

  useEffect(() => {
    if (filteredModuli.length > 0) {
      if (user !== null) {
        setIsNetwork(user.flag_network);
      }

      if (selection !== 101) {
        setSelection(
          filteredModuli.sort((a, b) => a.number - b.number)[0].number
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, filteredModuli]);

  useEffect(() => {
    if (filteredModuli.length > 0 && props.match.params.index !== undefined) {
      localStorage.setItem("currentModule", props.match.params.index);
      setSelection(
        isNaN(parseInt(localStorage.getItem("currentModule")))
          ? filteredModuli.sort((a, b) => a.number - b.number)[0].number
          : parseInt(localStorage.getItem("currentModule"))
      );
    }
  }, [filteredModuli, selection, props.history, props.match.params, colors]);

  useEffect(() => {
    if (
      prevOrganisation?.organisation?.id !== organisation?.id &&
      prevOrganisation?.organisation !== undefined &&
      filteredModuli.length > 0
    ) {
      let item = parseInt(localStorage.getItem("currentModule"));
      let modules = filteredModuli.map((a) => {
        return a.id;
      }, []);
      if (item === 101 || modules.includes(item)) {
        setSelection(item);
        // } else if ((item === 41 || item === 42) && modules.includes(4)) {
        //   setSelection(item);
      } else {
        setSelection(
          filteredModuli.sort((a, b) => a.number - b.number)[0].number
        );
      }
    }
  }, [organisation, prevOrganisation, selection, filteredModuli]);

  useEffect(() => {
    if (
      prevIsNetwork?.isNetwork !== isNetwork &&
      prevIsNetwork?.isNetwork !== undefined
    ) {
      usersService.toggleNetwork(isNetwork).then((response) => {
        //Checks consistency between backend and frontend editing
        if (response.flag_network === isNetwork) {
          fetchUser();
          fetchOrg();
        } else {
        }
      });
    }
  }, [prevIsNetwork, isNetwork, fetchUser, fetchOrg]);

  const changeOrg = (orgId) => {
    authenticationService.changeOrg(user.email, orgId).then((response) => {
      fetchUser();
      fetchOrg();
      window.location.reload(true);
    });
  };

  return (
    <div style={styles.container}>
      {isNaN(parseInt(props.match.params.index)) && (
        <SideBar
          width={width}
          selection={selection}
          onSelect={(selection) => {
            localStorage.setItem("currentModule", selection);
            setSelection(parseInt(localStorage.getItem("currentModule")));
          }}
          exit={() => logout(props.history, userSignout, orgSignout)}
          toggleNetwork={() => setIsNetwork(!isNetwork)}
          permissions={userPermissions}
          changeOrg={changeOrg}
        />
      )}
      {renderSwitch(
        selection,
        user,
        props.history,
        colors,
        userPermissions,
        !isNaN(parseInt(props.match.params.index)),
        props.match.params.index2
      )}
    </div>
  );
};

export default Dashboard;
