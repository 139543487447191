import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { OrgContext, UserContext } from "../../../contexts";
import DataBox from "../boxes/dataBox";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { reportService } from "../../../_services";
import ReactLoading from "react-loading";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const BarChart = (props) => {
  const org = useContext(OrgContext)?.org;
  const colors = useContext(UserContext)?.colors;

  //The selected data category
  const [selection, setSelection] = useState("");
  const prevSelection = usePrevious(selection);

  const prevTab = usePrevious(props.tab);

  const [contentFilters, setContentFilters] = useState([]);

  const [dateFilter, setDateFilter] = useState(
    props.isFree ? "DataAperta" : props.isAnnual ? "Annuale" : "Mensile"
  );

  const [loader, setLoader] = useState(false);

  //The render options of the chart. These can be changed as explained in chart.js doc
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            var label =
              context.label +
              ": " +
              context.raw +
              (selection === "eta" || selection === "Età" ? "%" : "");
            return label;
          },
        },
      },
    },
    scales: {
      yAxis: {
        ticks: {
          callback: function (value) {
            if (value % 1 === 0) {
              return value;
            }
            return "";
          },
        },
      },
    },
  };

  //The structure of the chart's data. Labels is the array of the identifiers of each dataset, while label identifies the meaning of the data shown
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        borderWidth: 1,
      },
    ],
  });

  //Handle the change of data category selected
  const handleSelection = (selection) => {
    setSelection(selection);
  };

  const generatePastelColor = () => {
    let R = Math.floor(Math.random() * 127 + 127);
    let G = Math.floor(Math.random() * 127 + 127);
    let B = Math.floor(Math.random() * 127 + 127);

    let rgb = (R << 16) + (G << 8) + B;
    return `#${rgb.toString(16)}`;
  };

  const getGraphStats = useCallback(() => {
    setLoader(true);
    reportService
      .getGraphStats(
        org,
        selection,
        dateFilter,
        props.temporalFilters.start,
        props.temporalFilters.end,
        props.tab
      )
      .then((data) => {
        var labels = [];
        var dataList = [];
        var backgroundColor = [];

        if (selection === "Età" || selection === "eta") {
          for (const label in data.dailyAverage) {
            labels.push(label);
            dataList.push(data.dailyAverage[label]);
            backgroundColor.push(generatePastelColor());
          }
        } else {
          for (const label in data.totals) {
            labels.push(label);
            dataList.push(data.totals[label]);
            backgroundColor.push(generatePastelColor());
          }
        }

        var datasets = [
          {
            data: dataList,
            backgroundColor: backgroundColor,
            borderWidth: 1,
          },
        ];

        setData({ labels, datasets });
        setLoader(false);
      });
  }, [
    dateFilter,
    org,
    props.temporalFilters.end,
    props.temporalFilters.start,
    selection,
    props.tab,
  ]);

  useEffect(() => {
    let tmpFilters = [];
    if (props.tab === "bookshop") {
      org.configurazioni.bookshop.forEach((element) => {
        if (element.type !== 1) {
          tmpFilters.push(element.name);
        }
      });
    } else {
      org.configurazioni.biglietteria.forEach((element) => {
        if (element.type !== 1) {
          tmpFilters.push(element.name);
        }
      });
    }
    setContentFilters(tmpFilters);
    setSelection(tmpFilters[0]);
  }, [org, props.tab]);

  useEffect(() => {
    if (
      selection !== "" &&
      selection !== undefined &&
      (prevSelection !== selection ||
        (props.tab !== "" && props.tab !== undefined && prevTab !== props.tab))
    ) {
      getGraphStats();
    }
  }, [selection, prevSelection, props.tab, prevTab, getGraphStats]);

  useEffect(() => {
    setDateFilter(
      props.isFree ? "DataAperta" : props.isAnnual ? "Annuale" : "Mensile"
    );
  }, [props.isFree, props.isAnnual]);

  return (
    <DataBox
      title={
        props.tab === "bookshop" ? "Dettaglio Clienti" : "Dettaglio Visitatori "
      }
      // maintainAspectRatio: false gives the opportunity to handle the chart's dimension
      body={
        loader ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "150px",
              paddingBottom: "150px",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={colors?.primary}
              height={50}
              width={50}
            />
          </div>
        ) : (
          <Bar
            data={data}
            options={{ ...options, maintainAspectRatio: false }}
            height={"350%"}
          />
        )
      }
      isMean={false}
      handleSelection={(selection) => handleSelection(selection)}
      contentFilters={contentFilters}
    />
  );
};

export default BarChart;
