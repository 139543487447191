import React from "react";
import moment from "moment";
import "moment/locale/it";
moment.locale("it");

const titles = ["", "Nome", "Cognome", "Email"];
const Table = ({
  customers,
  colors,
  setOpenRecord,
  activePage,
  itemsPerPage,
}) => {
  const styles = {
    table: {
      textAlign: "center",
      borderCollapse: "collapse",
      border: "1px solid #ddd",
      width: "100%",
      overflow: "scroll",
    },
    headerCell: {
      border: "1px solid #ddd",
      padding: "8px",
      paddingTop: "12px",
      paddingBottom: "12px",
      textAlign: "center",
      backgroundColor: colors.verylightgray,
      fontSize: 12,
      color: colors.gray,
    },
    field: {
      border: "1px solid #ddd",
      padding: "8px",
      fontSize: 12,
    },
    row: {
      cursor: "pointer",
    },
  };

  const renderTableHeader = (titles) => {
    return titles.map((key, index) => {
      return (
        <th style={styles.headerCell} key={index}>
          {key.toUpperCase()}
        </th>
      );
    });
  };

  const renderTableData = () => {
    return customers.map((customer, key) => {
      return (
        <tr
          key={key}
          style={styles.row}
          onClick={() => setOpenRecord(customer)}
        >
          <td style={styles.field}>
            {key + (activePage - 1) * itemsPerPage + 1}
          </td>
          <td style={styles.field}>{customer.nome}</td>
          <td style={styles.field}>{customer.cognome}</td>
          <td style={styles.field}>{customer.email}</td>
        </tr>
      );
    });
  };
  return (
    <table style={styles.table}>
      <tbody>
        <tr>{renderTableHeader(titles)}</tr>
        {renderTableData()}
      </tbody>
    </table>
  );
};

export default Table;
