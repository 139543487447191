import React, { Component } from "react";
import ReactLoading from "react-loading";
import { usersService } from "../_services";
import colors from "../networkColors";

export default class AddToNetwork extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: null,
      loader: true,
    };
    this.generateCode = this.generateCode.bind(this);
  }
  componentDidMount() {
    this.generateCode();
  }
  generateCode() {
    usersService
      .getNetworkCode()
      .then((response) => this.setState({ code: response, loader: false }))
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  }

  render() {
    const { loader, code } = this.state;
    const styles = {
      container: {
        display: "flex",
        flexDirection: "column",
        width: "85%",
        height: "100%",
        position: "absolute",
        right: 0,
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      },
      title: {
        color: colors.primary,
      },
      caption: {
        color: colors.secondary,
      },
      code: {
        color: colors.darkgray,
        marginTop: 80,
        fontSize: 80,
        fontWeight: "bold",
      },
    };
    return loader ? (
      <ReactLoading
        type={"spinningBubbles"}
        color={colors.primary}
        height={50}
        width={50}
      />
    ) : (
      <div style={styles.container}>
        <h1 style={styles.title}>Entra a far parte di una rete museale</h1>
        <h4 style={styles.caption}>
          Comunica il codice sottostante all'amministratore della rete museale
          di cui vuoi entrare a far parte
        </h4>
        <p style={styles.code}>{code}</p>
      </div>
    );
  }
}
