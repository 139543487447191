import React from "react";
import ArchiveEventBox from "./archiveEventBox";
import trash from "./../../../img/delete_gray.png";
import restore from "./../../../img/restore.png";

const ArchiveCategory = ({
  category,
  eventsList,
  colors,
  toggleCopy,
  onDelete,
  onRecover,
  toggleInfo,
}) => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: 3,
      borderBottom: "3px solid",
      borderColor: colors?.secondary,
    },
    title: {
      color: colors?.secondary,
      marginLeft: 30,
      fontWeight: "700",
    },
    alignHorizontal: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      marginRight: 30,
    },
    scroller: {
      overflowX: "scroll",
      display: "flex",
      alignItems: "flex-start",
    },
    row: {
      display: "inline-flex",
    },
  };

  return (
    <div style={{ width: "100%" }}>
      <div style={styles.container}>
        <p style={styles.title}>{category.nome_categoria.toUpperCase()}</p>
        <div style={styles.alignHorizontal}>
          <>
            <button
              onClick={() => onDelete()}
              style={{
                border: "none",
                backgroundColor: "transparent",
                outline: "none",
                cursor: "pointer",
              }}
            >
              <img
                alt="Delete icon"
                src={trash}
                style={{ width: 15, objectFit: "contain", margin: 10 }}
              />
            </button>
            {!category.active && (
              <button
                onClick={() => onRecover()}
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                <img
                  alt="Modify icon"
                  src={restore}
                  style={{ width: 20, objectFit: "contain", margin: 10 }}
                />
              </button>
            )}
          </>
        </div>
      </div>
      <div id="scroll" style={styles.scroller}>
        <div style={styles.row}>
          {eventsList.length > 0 &&
            eventsList
              .sort(function (a, b) {
                return new Date(b.ora_inizio) - new Date(a.ora_inizio);
              })
              .map((item, key) => (
                <ArchiveEventBox
                  key={key}
                  event={item}
                  toggleCopy={() => toggleCopy(item)}
                  toggleInfo={() => toggleInfo(item)}
                  colors={colors}
                />
              ))}
        </div>
      </div>
    </div>
  );
};

export default ArchiveCategory;
