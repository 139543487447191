import React, { useEffect, useState, useContext } from "react";
import { OrgContext } from "../../../contexts";
import ReactLoading from "react-loading";
import close from "./../../../img/closeButton.png";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it";
import Switch from "react-switch";
import "./../../../customizer.css";
import "./../../../scrollbar.css";
import { itemsService } from "../../../_services";

registerLocale("it", it);

const CustomInput = ({ value, onClick }) => (
  <button className="custom-input" onClick={onClick}>
    {value}
  </button>
);

function isTicket(categoriesList, id) {
  var result = false;
  for (let i = 0; i < categoriesList.length; i++) {
    if (
      categoriesList[i].id === id &&
      categoriesList[i].nome_categoria.toUpperCase() === "BIGLIETTI"
    ) {
      result = true;
    }
  }
  return result;
}

function isBook(categoriesList, id) {
  var result = false;
  for (let i = 0; i < categoriesList.length; i++) {
    if (
      categoriesList[i].id === id &&
      categoriesList[i].nome_categoria.toUpperCase() === "LIBRI"
    ) {
      result = true;
    }
  }
  return result;
}

function getLowestId(categoriesList) {
  var lowestId = categoriesList[0].id;
  for (let i = 0; i < categoriesList.length; i++) {
    if (categoriesList[i].id < lowestId) {
      lowestId = categoriesList[i].id;
    }
  }
  return lowestId;
}

function restoreName(nameString) {
  if (nameString.includes("_DEL")) {
    var splitName = nameString.split("_DEL");
    return splitName[0];
  } else {
    return nameString;
  }
}

function hasThreeDecimals(number) {
  if (
    number.toString().includes(".") &&
    number.toString().split(".")[1].length > 2
  ) {
    return true;
  } else {
    return false;
  }
}

const AddItem = ({
  closeModal,
  item,
  categoriesList,
  checkCode,
  existing,
  saveData,
  modifyDeletedTicket,
  addArticlePurchase,
  isbnFound,
  setUnknown,
  colors,
  isNetwork,
  addNewPurchase,
  prevFlag,
  prevStep,
}) => {
  const [flag, setFlag] = useState(prevFlag ? prevFlag : true);
  const [step, setStep] = useState(prevStep ? prevStep : flag ? 0 : 1);
  const [category, setCategory] = useState(
    item ? item.categoria_articolo.id : getLowestId(categoriesList)
  );
  const [details, setDetails] = useState(
    item ? restoreName(item.nome_articolo) : ""
  );
  const [author, setAuthor] = useState("");
  const [code, setCode] = useState("");
  const [seller, setSeller] = useState("");
  const [price, setPrice] = useState("");
  const [qty, setQty] = useState("");
  const [cost, setCost] = useState(0);
  const [percentage, setPercentage] = useState(100);
  const [deadline, setDeadline] = useState(new Date());
  const [type, setType] = useState("1");
  const [isEnabled, setEnabled] = useState(false);
  const [loader, setLoader] = useState(false);
  const [entrances, setEntrances] = useState("1");
  const [validity, setValidity] = useState("365");
  const [existingButDeleted, setExistingButDeleted] = useState(false);
  const [alreadyUsedName, setAlreadyUsedName] = useState(false);
  const [usingCode, setUsingCode] = useState(false);
  const org = useContext(OrgContext)?.org;

  useEffect(() => {
    if (item) {
      if (item.deleted === true) {
        setExistingButDeleted(true);
      } else {
        setExistingButDeleted(false);
      }
    }
  }, [item]);

  useEffect(() => {
    if (isbnFound === true) {
      setCategory(
        categoriesList.find(
          (cat) => cat.nome_categoria.toUpperCase() === "LIBRI"
        ).id
      );
    } else {
      getLowestId(categoriesList);
    }
  }, [isbnFound, categoriesList, setCategory]);

  useEffect(() => {
    setLoader(true);
    setCategory(
      item ? item.categoria_articolo.id : getLowestId(categoriesList)
    );
    setDetails(item ? restoreName(item.nome_articolo) : "");
    setAuthor(item ? item.autore : "");
    setPrice(item ? item.prezzo_articolo : "");
    setLoader(false);
  }, [item, categoriesList]);

  function resetSelectionsToDefault() {
    setCategory(getLowestId(categoriesList));
    setDetails("");
    setAuthor("");
    setCode("");
    setSeller("");
    setPrice("");
    setQty("");
    setCost("");
    setPercentage("");
    setDeadline(new Date());
    setType("1");
    setEntrances("1");
    setValidity("365");
  }

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 30,
      margin: "auto",
      width: 600,
      zIndex: 300,
      justifyContent: "center",
    },
    header: {
      display: "flex",
      width: "100%",
      height: 60,
      backgroundColor: colors.primary,
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    content: {
      width: "100%",
      maxHeight: 300,
      flexDirection: "column",
      backgroundColor: colors.white,
      display: "flex",
      alignItems: "center",
      overflowY: "scroll",
    },
    textInput: {
      border: "1px solid lightgray",
      borderRadius: 10,
      width: "100%",
      padding: 18,
      outline: "none",
    },
    field: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginTop: 20,
    },
    footer: {
      display: "flex",
      width: "100%",
      height: 120,
      backgroundColor: colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    footer2buttons: {
      display: "flex",
      width: "100%",
      height: 120,
      backgroundColor: colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: addNewPurchase ? "flex-end" : "space-between",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      marginTop: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    buttonLeft: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginLeft: 40,
      marginBottom: 40,
      marginTop: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    disabledButton: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      marginTop: 40,
      border: "none",
      outline: "none",
      opacity: 0.7,
    },
    menu: {
      border: "1px solid lightgray",
      width: "100%",
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 20,
      paddingBottom: 20,
      color: colors.darkgray,
      borderRadius: 20,
      backgroundColor: "transparent",
      outline: "none",
    },
    label: {
      fontSize: 15,
      color: colors.lightgray,
      margin: 20,
      width: 400,
      textAlign: "left",
    },
    message: {
      fontSize: 15,
      color: colors.lightgray,
      marginTop: 40,
      width: 400,
      textAlign: "left",
    },
    nearLabel: {
      fontSize: 15,
      color: colors.lightgray,
      margin: 20,
      marginBottom: 10,
      width: 400,
      textAlign: "left",
    },
    halfLabel: {
      fontSize: 15,
      color: colors.lightgray,
      margin: 20,
      width: "100%",
      textAlign: "left",
    },
    box: {
      width: 400,
      display: "flex",
    },
    halfBox: {
      width: "100%",
      display: "flex",
    },
    loading: {
      display: "grid",
      flexDirection: "column",
      position: "relative",
      top: 0,
      left: 0,
      right: 0,
      bottom: 30,
      margin: "auto",
      width: 600,
      zIndex: 300,
      justifyContent: "center",
    },
    warning: {
      color: "red",
      marginLeft: 10,
      fontSize: 14,
      marginTop: 40,
      width: 400,
      textAlign: "left",
    },
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: "100%",
          height: "100%",
          backgroundColor: colors.gray,
          opacity: 0.7,
        }}
      />
      {loader ? (
        <div style={styles.loading}>
          <ReactLoading
            type={"spinningBubbles"}
            color={colors.primary}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <div style={styles.container}>
          <div style={styles.header}>
            <p style={{ color: colors.white, fontSize: 20 }}>
              AGGIUNGI{" "}
              {addNewPurchase || (existing === true && flag && item)
                ? "CARICO"
                : "ARTICOLO"}
            </p>
            <button
              onClick={closeModal}
              style={{
                position: "absolute",
                right: 30,
                marginTop: "auto",
                marginBottom: "auto",
                border: "none",
                backgroundColor: "transparent",
                outline: "none",
                cursor: "pointer",
              }}
            >
              <img
                alt="Close button"
                src={close}
                style={{ width: 15, objectFit: "contain" }}
              />
            </button>
          </div>
          {!addNewPurchase && (
            <div
              style={{
                height: 60,
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <button
                onClick={() => {
                  if (!flag) resetSelectionsToDefault();
                  setStep(0);
                  setFlag(true);
                }}
                style={{
                  display: "flex",
                  flex: 1,
                  cursor: "pointer",
                  backgroundColor: flag ? colors.white : colors.secondary,
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                  outline: "none",
                }}
              >
                <p style={{ color: flag ? colors.secondary : colors.white }}>
                  Con codice a barre
                </p>
              </button>
              <button
                onClick={() => {
                  if (flag) resetSelectionsToDefault();
                  setStep(1);
                  setFlag(false);
                  setUnknown();
                }}
                style={{
                  display: "flex",
                  flex: 1,
                  cursor: "pointer",
                  backgroundColor: flag ? colors.secondary : colors.white,
                  alignItems: "center",
                  justifyContent: "center",
                  border: "none",
                  outline: "none",
                }}
              >
                <p style={{ color: flag ? colors.white : colors.secondary }}>
                  Senza codice a barre
                </p>
              </button>
            </div>
          )}

          {!addNewPurchase && step === 0 && (
            <>
              <div style={styles.content}>
                <div style={styles.field}>
                  <p style={styles.label}>Codice</p>
                  <div style={styles.box}>
                    <input
                      value={code}
                      onChange={(event) => setCode(event.target.value)}
                      placeholder="Codice"
                      style={styles.textInput}
                    />
                  </div>
                </div>
              </div>
              <div style={styles.footer}>
                <button
                  onClick={() => {
                    checkCode(code);
                    setStep(1);
                  }}
                  style={
                    code.length <= 0 ? styles.disabledButton : styles.button
                  }
                  disabled={code.length <= 0}
                >
                  AVANTI
                </button>
              </div>
            </>
          )}
          {!addNewPurchase &&
            step === 1 &&
            flag &&
            ((existing === null && isbnFound !== true) ||
              (existing === false && isbnFound === null)) && (
              <div
                style={{
                  width: "100%",
                  flex: 6,
                  flexDirection: "column",
                  backgroundColor: colors.white,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={colors.primary}
                  height={50}
                  width={50}
                />
              </div>
            )}

          {!addNewPurchase &&
            step === 1 &&
            existing === false &&
            isbnFound === false && (
              <>
                <div
                  style={{ ...styles.content, overflowY: "scroll" }}
                  id="scroll"
                >
                  {flag && (
                    <p style={styles.message}>
                      Non è stato trovato alcun articolo corrispondente al
                      codice inserito.
                    </p>
                  )}
                  {alreadyUsedName && (
                    <p style={styles.warning}>
                      Il nome scelto per{" "}
                      {isTicket(categoriesList, category)
                        ? "il biglietto è già stato usato per un altro biglietto (con codice: " +
                          usingCode +
                          ")"
                        : isBook(categoriesList, category) && author
                        ? 'il libro ("' +
                          details.trim() +
                          '", di ' +
                          author.trim() +
                          ") è già stato usato per un altro libro (con codice: " +
                          usingCode +
                          ")"
                        : "l'articolo (\"" +
                          details.trim() +
                          '") è già stato usato nella stessa categoria selezionata per un altro articolo (con codice: ' +
                          usingCode +
                          ")"}
                    </p>
                  )}
                  <div style={styles.field}>
                    <p style={styles.label}>Categoria</p>
                    <div style={styles.box}>
                      <select
                        name="user"
                        style={styles.menu}
                        value={category}
                        onChange={(event) => {
                          setCategory(parseInt(event.target.value));
                          setAuthor("");
                        }}
                      >
                        {categoriesList.map((cat, key) => {
                          return (
                            <option key={key} value={cat.id}>
                              {cat.nome_categoria}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {!isBook(categoriesList, category) && (
                    <div style={styles.field}>
                      <p style={styles.label}>Nome</p>
                      <div style={styles.box}>
                        <input
                          value={details}
                          onChange={(event) => setDetails(event.target.value)}
                          placeholder="Nome articolo"
                          style={styles.textInput}
                        />
                      </div>
                    </div>
                  )}
                  {isBook(categoriesList, category) && (
                    <>
                      <div style={styles.field}>
                        <p style={styles.label}>Titolo</p>
                        <div style={styles.box}>
                          <input
                            value={details}
                            onChange={(event) => setDetails(event.target.value)}
                            placeholder="Titolo"
                            style={styles.textInput}
                          />
                        </div>
                      </div>
                      <div style={styles.field}>
                        <p style={styles.label}>Autore</p>
                        <div style={styles.box}>
                          <input
                            value={author}
                            onChange={(event) => setAuthor(event.target.value)}
                            placeholder="Autore"
                            style={styles.textInput}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div style={styles.footer}>
                  <button
                    onClick={() => {
                      setLoader(true);
                      itemsService
                        .getItems(org.id, 0, -1, category, details, author)
                        .then((res) => {
                          if (res.length === 0) {
                            setAlreadyUsedName(false);
                            setUsingCode("");
                            isTicket(categoriesList, category)
                              ? setStep(3)
                              : setStep(2);
                          } else {
                            setAlreadyUsedName(true);
                            setUsingCode(res[0].codice_articolo);
                          }
                          setLoader(false);
                        });
                    }}
                    style={
                      details === "" ||
                      (isBook(categoriesList, category) && author === "")
                        ? styles.disabledButton
                        : styles.button
                    }
                    disabled={
                      details === "" ||
                      (isBook(categoriesList, category) && author === "")
                    }
                  >
                    AVANTI
                  </button>
                </div>
              </>
            )}
          {!addNewPurchase &&
            step === 1 &&
            existing === false &&
            isbnFound === true && (
              <>
                <div
                  style={{ ...styles.content, overflowY: "scroll" }}
                  id="scroll"
                >
                  <div style={styles.field}>
                    <p style={styles.nearLabel}>Categoria</p>
                    <div style={styles.box}>
                      <p>{item?.categoria_articolo.nome_categoria}</p>
                    </div>
                  </div>
                  <div style={styles.field}>
                    <p style={styles.nearLabel}>Titolo</p>
                    <div style={styles.box}>
                      <input
                        value={details}
                        onChange={(event) => setDetails(event.target.value)}
                        placeholder="Titolo"
                        style={styles.textInput}
                      />
                    </div>
                  </div>
                  <div style={styles.field}>
                    <p style={styles.nearLabel}>Autore</p>
                    <div style={styles.box}>
                      <input
                        value={author}
                        onChange={(event) => setAuthor(event.target.value)}
                        placeholder="Autore"
                        style={styles.textInput}
                      />
                    </div>
                  </div>
                  {alreadyUsedName && (
                    <p style={styles.warning}>
                      {"Il nome scelto per l'articolo è già stato usato nella stessa categoria per un altro articolo (con codice: " +
                        usingCode +
                        ")"}
                    </p>
                  )}
                </div>
                <div style={styles.footer}>
                  <button
                    onClick={() => {
                      setLoader(true);
                      setCategory(item.categoria_articolo.id);
                      itemsService
                        .getItems(org.id, 0, -1, category, details, author)
                        .then((res) => {
                          if (res.length === 0) {
                            setAlreadyUsedName(false);
                            setUsingCode("");
                            isTicket(categoriesList, category)
                              ? setStep(3)
                              : setStep(2);
                          } else {
                            setAlreadyUsedName(true);
                            setUsingCode(res[0].codice_articolo);
                          }
                          setLoader(false);
                        });
                    }}
                    style={
                      details === undefined
                        ? styles.disabledButton
                        : styles.button
                    }
                    disabled={details === undefined}
                  >
                    AVANTI
                  </button>
                </div>
              </>
            )}
          {(addNewPurchase ||
            (step === 1 && existing === true && flag && item)) && (
            <>
              <div
                style={{ ...styles.content, overflowY: "scroll" }}
                id="scroll"
              >
                {existingButDeleted ? (
                  <>
                    {alreadyUsedName && (
                      <p style={styles.warning}>
                        Il nome scelto per{" "}
                        {isTicket(categoriesList, category)
                          ? "il biglietto è già stato usato per un altro biglietto (con codice: " +
                            usingCode +
                            ")"
                          : "l'articolo è già stato usato nella stessa categoria selezionata per un altro articolo (con codice: " +
                            usingCode +
                            ")"}
                      </p>
                    )}
                    {isTicket(categoriesList, category) ? (
                      <div style={styles.field}>
                        <p style={styles.nearLabel}>Categoria</p>
                        <div style={styles.box}>
                          <p>{item.categoria_articolo.nome_categoria}</p>
                        </div>
                      </div>
                    ) : (
                      <div style={styles.field}>
                        <p style={styles.label}>Categoria</p>
                        <div style={styles.box}>
                          <select
                            name="user"
                            style={styles.menu}
                            value={category}
                            onChange={(event) =>
                              setCategory(parseInt(event.target.value))
                            }
                          >
                            {categoriesList.map((cat, key) => {
                              return (
                                <option key={key} value={cat.id}>
                                  {cat.nome_categoria}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    )}
                    {!isBook(categoriesList, category) && (
                      <div style={styles.field}>
                        <p style={styles.label}>Nome</p>
                        <div style={styles.box}>
                          <input
                            value={details}
                            onChange={(event) => setDetails(event.target.value)}
                            placeholder="Nome articolo"
                            style={styles.textInput}
                          />
                        </div>
                      </div>
                    )}
                    {isBook(categoriesList, category) && (
                      <>
                        <div style={styles.field}>
                          <p style={styles.label}>Titolo</p>
                          <div style={styles.box}>
                            <input
                              value={details}
                              onChange={(event) =>
                                setDetails(event.target.value)
                              }
                              placeholder="Titolo"
                              style={styles.textInput}
                            />
                          </div>
                        </div>
                        <div style={styles.field}>
                          <p style={styles.label}>Autore</p>
                          <div style={styles.box}>
                            <input
                              value={author}
                              onChange={(event) =>
                                setAuthor(event.target.value)
                              }
                              placeholder="Autore"
                              style={styles.textInput}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    {isTicket(categoriesList, category) && (
                      <>
                        <div
                          style={{
                            ...styles.field,
                            flexDirection: "row",
                            width: "70%",
                          }}
                        >
                          <p style={{ ...styles.label, flex: 1 }}>
                            {isNetwork
                              ? "Numero di ingressi per museo consentiti con lo stesso biglietto"
                              : "Numero di ingressi consentiti con lo stesso biglietto"}
                          </p>
                          <div
                            style={{
                              width: 90,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <input
                              value={entrances}
                              onChange={(event) =>
                                setEntrances(
                                  event.target.value.replace(/[^\d]/, "")
                                )
                              }
                              placeholder="Ingressi"
                              style={{ ...styles.textInput, flex: 1 }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            ...styles.field,
                            flexDirection: "row",
                            width: "70%",
                          }}
                        >
                          <p style={{ ...styles.label, flex: 1 }}>
                            Validità dalla data d'acquisto
                          </p>
                          <div
                            style={{
                              width: 90,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <input
                              value={validity}
                              onChange={(event) =>
                                setValidity(
                                  event.target.value.replace(/[^\d]/, "")
                                )
                              }
                              placeholder="Giorni"
                              style={{ ...styles.textInput, flex: 1 }}
                            />
                          </div>
                          <p style={{ marginLeft: 10 }}>giorni</p>
                        </div>
                      </>
                    )}
                    <div style={styles.field}>
                      <p style={styles.label}>Prezzo</p>
                      <div
                        style={{
                          width: 400,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p style={{ marginRight: 30 }}>€</p>
                        <input
                          value={price}
                          onChange={(event) => {
                            var tempPrice = event.target.value.replace(
                              /[^0-9.]/,
                              ""
                            );
                            setPrice(tempPrice);
                            if (tempPrice === "") {
                              setCost(0.0);
                              setPercentage(100);
                            }
                            if (percentage === "") {
                              setPercentage(100);
                            }
                            if (cost === "") {
                              setCost(0);
                            }
                            if (isEnabled) {
                              tempPrice !== "" && parseInt(tempPrice) !== 0
                                ? setCost(
                                    (
                                      tempPrice -
                                      (tempPrice * percentage) / 100
                                    ).toFixed(2)
                                  )
                                : setCost(0.0);
                            } else {
                              tempPrice !== "" && parseInt(tempPrice) !== 0
                                ? setPercentage(
                                    (
                                      ((tempPrice - cost) * 100) /
                                      tempPrice
                                    ).toFixed(2)
                                  )
                                : setPercentage(100);
                            }
                          }}
                          placeholder="Prezzo"
                          style={styles.textInput}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={styles.field}>
                      <p style={styles.nearLabel}>Categoria</p>
                      <div style={styles.box}>
                        <p>{item.categoria_articolo.nome_categoria}</p>
                      </div>
                    </div>
                    <div style={styles.field}>
                      <p style={styles.nearLabel}>Nome</p>
                      <div style={styles.box}>
                        <p>{item.nome_articolo}</p>
                      </div>
                    </div>
                    {isBook(categoriesList, category) && (
                      <>
                        <div style={styles.field}>
                          <p style={styles.nearLabel}>Autore</p>
                          <div style={styles.box}>
                            <p>{item.autore}</p>
                          </div>
                        </div>
                      </>
                    )}
                    <div style={styles.field}>
                      <p style={styles.nearLabel}>Prezzo</p>
                      <div style={styles.box}>
                        <p>€ {item.prezzo_articolo.toFixed(2)}</p>
                      </div>
                    </div>
                  </>
                )}

                {!isTicket(categoriesList, category) && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: 400,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginTop: 20,
                          flex: 2,
                          marginRight: 20,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <label style={{ marginRight: 30 }}>
                            <input
                              type="radio"
                              value={1}
                              checked={type === "1"}
                              onChange={(event) => {
                                setType(event.target.value);
                              }}
                              style={{ marginTop: 30, marginRight: 10 }}
                            />
                            Conto vendita
                          </label>
                          <label>
                            <input
                              type="radio"
                              value={2}
                              checked={type === "2"}
                              onChange={(event) => {
                                setType(event.target.value);
                                setDeadline(new Date());
                              }}
                              style={{ marginTop: 30, marginRight: 10 }}
                            />
                            Acquisto
                          </label>
                        </div>
                      </div>
                    </div>
                    {type === "1" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: 400,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: 20,
                            flex: 1,
                          }}
                        >
                          <p style={styles.halfLabel}>Scadenza conto vendita</p>
                          <div style={styles.halfBox}>
                            <DatePicker
                              selected={deadline}
                              onChange={(date) => setDeadline(date)}
                              dateFormat="dd/MM/yyyy"
                              minDate={new Date()}
                              locale="it"
                              customInput={<CustomInput />}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div style={styles.field}>
                      <p style={styles.label}>Quantità</p>
                      <div style={styles.box}>
                        <input
                          value={qty}
                          onChange={(event) =>
                            setQty(event.target.value.replace(/[^\d]/, ""))
                          }
                          placeholder="Numero"
                          style={styles.textInput}
                        />
                      </div>
                    </div>
                    <div style={styles.field}>
                      <p style={styles.label}>Fornitore</p>
                      <div style={styles.box}>
                        <input
                          value={seller}
                          onChange={(event) => setSeller(event.target.value)}
                          placeholder="Fornitore"
                          style={styles.textInput}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                        marginTop: 30,
                        width: 400,
                      }}
                    >
                      <div style={{ flex: 3 }}>
                        <p
                          style={{
                            ...styles.label,
                            width: "auto",
                            marginLeft: 0,
                            marginRight: 0,
                          }}
                        >
                          Percentuale di guadagno
                        </p>
                        <div style={{ ...styles.box, width: "auto" }}>
                          {isEnabled ? (
                            <p>
                              {price !== "" &&
                              price !== null &&
                              price !== undefined &&
                              parseInt(price) !== 0
                                ? parseFloat(
                                    (((price - cost) * 100) / price).toFixed(2)
                                  )
                                : 100}
                              %
                            </p>
                          ) : (
                            <input
                              value={percentage}
                              onChange={(event) => {
                                var current = event.target.value.replace(
                                  /[^0-9.]/,
                                  ""
                                );
                                current = hasThreeDecimals(current)
                                  ? parseFloat(current).toFixed(2)
                                  : current;
                                setPercentage(current);
                                setCost(
                                  price !== "" &&
                                    price !== null &&
                                    price !== undefined
                                    ? (price - (price * current) / 100).toFixed(
                                        2
                                      )
                                    : 0.0
                                );
                              }}
                              placeholder="Percentuale"
                              style={styles.textInput}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          flex: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 50,
                        }}
                      >
                        <Switch
                          onChange={() => {
                            setPercentage(
                              price !== ""
                                ? (((price - cost) * 100) / price).toFixed(2)
                                : 0
                            );
                            setCost(
                              price !== ""
                                ? (price - (price * percentage) / 100).toFixed(
                                    2
                                  )
                                : 0
                            );
                            setEnabled(!isEnabled);
                          }}
                          checked={isEnabled}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onColor={"#808080"}
                          width={30}
                          height={20}
                        />
                      </div>
                      <div style={{ flex: 3 }}>
                        <p
                          style={{
                            ...styles.label,
                            width: "auto",
                            marginLeft: 0,
                            marginRight: 0,
                          }}
                        >
                          Costo di carico
                        </p>
                        <div style={{ ...styles.box, width: "auto" }}>
                          {isEnabled ? (
                            <input
                              value={cost}
                              onChange={(event) => {
                                var current = event.target.value.replace(
                                  /[^0-9.]/,
                                  ""
                                );
                                current = hasThreeDecimals(current)
                                  ? parseFloat(current).toFixed(2)
                                  : current;
                                setCost(current);
                                setPercentage(
                                  price !== "" &&
                                    price !== null &&
                                    price !== undefined
                                    ? (
                                        ((price - current) * 100) /
                                        price
                                      ).toFixed(2)
                                    : 100
                                );
                              }}
                              placeholder="Costo"
                              style={styles.textInput}
                            />
                          ) : (
                            <p>
                              €{" "}
                              {price !== "" &&
                              price !== null &&
                              price !== undefined
                                ? parseFloat(
                                    (
                                      price -
                                      (price * percentage) / 100
                                    ).toFixed(2)
                                  )
                                : 0.0}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div style={styles.footer2buttons}>
                {!addNewPurchase && (
                  <button
                    onClick={() => {
                      setStep(0);
                      resetSelectionsToDefault();
                      setAlreadyUsedName(false);
                      setUsingCode("");
                    }}
                    style={styles.buttonLeft}
                  >
                    INDIETRO
                  </button>
                )}
                {isTicket(categoriesList, category) ? (
                  existingButDeleted ? (
                    <button
                      onClick={() => {
                        setLoader(true);
                        itemsService
                          .getItems(org.id, 0, -1, category, details, author)
                          .then((res) => {
                            if (res.length === 0) {
                              setAlreadyUsedName(false);
                              setUsingCode("");
                              // no article with the same name already in the same category
                              modifyDeletedTicket(
                                //-> saveData of ModifyItem
                                details, //nome_articolo
                                null, //autore
                                parseInt(category), //categoria_articolo
                                price && parseFloat(price), //prezzo_articolo
                                parseInt(entrances), //num_ingressi
                                parseInt(validity) //validita
                              );
                            } else {
                              setAlreadyUsedName(true);
                              setUsingCode(res[0].codice_articolo);
                            }
                            setLoader(false);
                          });
                      }}
                      style={
                        details === "" ||
                        price === "" ||
                        entrances === "" ||
                        parseInt(entrances) <= 0 ||
                        validity === "" ||
                        parseInt(validity) <= 0
                          ? styles.disabledButton
                          : styles.button
                      }
                      disabled={
                        details === "" ||
                        price === "" ||
                        entrances === "" ||
                        parseInt(entrances) <= 0 ||
                        validity === "" ||
                        parseInt(validity) <= 0
                      }
                    >
                      SALVA
                    </button>
                  ) : (
                    <button onClick={() => closeModal()} style={styles.button}>
                      CHIUDI
                    </button>
                  )
                ) : (
                  <button
                    onClick={() => {
                      if (existingButDeleted) {
                        setLoader(true);
                        itemsService
                          .getItems(org.id, 0, -1, category, details, author)
                          .then((res) => {
                            if (res.length === 0) {
                              setAlreadyUsedName(false);
                              setUsingCode("");

                              // no article with the same name already in the same category
                              let finalDeadline;
                              if (parseInt(type) === 2) {
                                finalDeadline = null;
                              } else {
                                finalDeadline = deadline;
                              }
                              addArticlePurchase(
                                parseInt(type), //modalita_acquisto
                                finalDeadline, //scadenza_conto_vendita
                                parseInt(qty), //quantita
                                seller, //venditore
                                parseFloat(percentage), //percentuale_guadagno
                                parseFloat(cost), //costo_articolo
                                parseInt(qty), //giacenza
                                item.codice_articolo, //codice_articolo
                                existingButDeleted, //flag
                                item.id, //id
                                details, //nome_articolo
                                author, //autore
                                parseInt(category), //categoria_articolo
                                price && parseFloat(price)
                              );
                            } else {
                              setAlreadyUsedName(true);
                              setUsingCode(res[0].codice_articolo);
                            }
                            setLoader(false);
                          });
                      } else {
                        setLoader(false);
                        let finalDeadline;
                        if (parseInt(type) === 2) {
                          finalDeadline = null;
                        } else {
                          finalDeadline = deadline;
                        }
                        addArticlePurchase(
                          parseInt(type), //modalita_acquisto
                          finalDeadline, //scadenza_conto_vendita
                          parseInt(qty), //quantita
                          seller, //venditore
                          parseFloat(percentage), //percentuale_guadagno
                          parseFloat(cost), //costo_articolo
                          parseInt(qty), //giacenza
                          item.codice_articolo, //codice_articolo
                          existingButDeleted, //flag == false
                          null,
                          null,
                          null,
                          null,
                          null
                        );
                      }
                    }}
                    style={
                      details === "" ||
                      seller === "" ||
                      price === "" ||
                      percentage === "" ||
                      cost === "" ||
                      qty === "" ||
                      parseInt(qty) === 0 ||
                      parseFloat(cost) < 0 ||
                      parseFloat(cost) > parseFloat(price)
                        ? styles.disabledButton
                        : styles.button
                    }
                    disabled={
                      details === "" ||
                      seller === "" ||
                      price === "" ||
                      percentage === "" ||
                      cost === "" ||
                      qty === "" ||
                      parseInt(qty) === 0 ||
                      parseFloat(cost) < 0 ||
                      parseFloat(cost) > parseFloat(price)
                    }
                  >
                    SALVA
                  </button>
                )}
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div
                style={{ ...styles.content, overflowY: "scroll" }}
                id="scroll"
              >
                <div
                  style={{ display: "flex", flexDirection: "row", width: 400 }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      marginTop: 20,
                      flex: 2,
                      marginRight: 20,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <label style={{ marginRight: 30 }}>
                        <input
                          type="radio"
                          value={1}
                          checked={type === "1"}
                          onChange={(event) => {
                            setType(event.target.value);
                          }}
                          style={{ marginTop: 30, marginRight: 10 }}
                        />
                        Conto vendita
                      </label>
                      <label>
                        <input
                          type="radio"
                          value={2}
                          checked={type === "2"}
                          onChange={(event) => {
                            setType(event.target.value);
                          }}
                          style={{ marginTop: 30, marginRight: 10 }}
                        />
                        Acquisto
                      </label>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      flex: 1,
                      marginTop: 20,
                      marginLeft: 20,
                    }}
                  >
                    <p style={styles.halfLabel}>Quantità</p>
                    <div style={styles.halfBox}>
                      <input
                        value={qty}
                        onChange={(event) =>
                          setQty(event.target.value.replace(/[^\d]/, ""))
                        }
                        placeholder="Numero"
                        style={styles.textInput}
                      />
                    </div>
                  </div>
                </div>
                {type === "1" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: 400,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 20,
                        flex: 3,
                        marginRight: 20,
                      }}
                    >
                      <p style={styles.halfLabel}>Scadenza conto vendita</p>
                      <div style={styles.halfBox}>
                        <DatePicker
                          selected={deadline}
                          onChange={(date) => setDeadline(date)}
                          dateFormat="dd/MM/yyyy"
                          minDate={new Date()}
                          locale="it"
                          customInput={<CustomInput />}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                <div style={styles.field}>
                  <p style={styles.label}>Fornitore</p>
                  <div style={styles.box}>
                    <input
                      value={seller}
                      onChange={(event) => setSeller(event.target.value)}
                      placeholder="Fornitore"
                      style={styles.textInput}
                    />
                  </div>
                </div>
              </div>
              <div style={styles.footer2buttons}>
                <button
                  onClick={() => {
                    setStep(1);
                    setAlreadyUsedName(false);
                    setUsingCode("");
                  }}
                  style={styles.buttonLeft}
                >
                  INDIETRO
                </button>
                <button
                  onClick={() => setStep(3)}
                  style={
                    seller.length <= 0 ||
                    (!isTicket(categoriesList, category) && qty === "")
                      ? styles.disabledButton
                      : styles.button
                  }
                  disabled={
                    seller.length <= 0 ||
                    (!isTicket(categoriesList, category) && qty === "")
                  }
                >
                  AVANTI
                </button>
              </div>
            </>
          )}
          {step === 3 && (
            <>
              <div style={styles.content}>
                {isTicket(categoriesList, category) && (
                  <div
                    style={{
                      ...styles.field,
                      flexDirection: "row",
                      width: "70%",
                    }}
                  >
                    <p style={{ ...styles.label, flex: 1 }}>
                      {isNetwork
                        ? "Numero di ingressi per museo consentiti con lo stesso biglietto"
                        : "Numero di ingressi consentiti con lo stesso biglietto"}
                    </p>
                    <div
                      style={{
                        width: 90,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        value={entrances}
                        onChange={(event) =>
                          setEntrances(event.target.value.replace(/[^\d]/, ""))
                        }
                        placeholder="Ingressi"
                        style={{ ...styles.textInput, flex: 1 }}
                      />
                    </div>
                  </div>
                )}
                {isTicket(categoriesList, category) && (
                  <div
                    style={{
                      ...styles.field,
                      flexDirection: "row",
                      width: "70%",
                    }}
                  >
                    <p style={{ ...styles.label, flex: 1 }}>
                      Validità dalla data d'acquisto
                    </p>
                    <div
                      style={{
                        width: 90,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <input
                        value={validity}
                        onChange={(event) =>
                          setValidity(event.target.value.replace(/[^\d]/, ""))
                        }
                        placeholder="Giorni"
                        style={{ ...styles.textInput, flex: 1 }}
                      />
                    </div>
                    <p style={{ marginLeft: 10 }}>giorni</p>
                  </div>
                )}
                <div style={styles.field}>
                  <p style={styles.label}>Prezzo</p>
                  <div
                    style={{
                      width: 400,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <p style={{ marginRight: 30 }}>€</p>
                    <input
                      value={price}
                      onChange={(event) => {
                        var tempPrice = event.target.value.replace(
                          /[^0-9.]/,
                          ""
                        );
                        setPrice(tempPrice);
                        if (tempPrice === "") {
                          setCost(0.0);
                          setPercentage(100);
                        }
                        if (percentage === "") {
                          setPercentage(100);
                        }
                        if (cost === "") {
                          setCost(0);
                        }
                        if (isEnabled) {
                          tempPrice !== "" && parseInt(tempPrice) !== 0
                            ? setCost(
                                (
                                  tempPrice -
                                  (tempPrice * percentage) / 100
                                ).toFixed(2)
                              )
                            : setCost(0.0);
                        } else {
                          tempPrice !== "" && parseInt(tempPrice) !== 0
                            ? setPercentage(
                                (
                                  ((tempPrice - cost) * 100) /
                                  tempPrice
                                ).toFixed(2)
                              )
                            : setPercentage(100);
                        }
                      }}
                      placeholder="Prezzo"
                      style={styles.textInput}
                    />
                  </div>
                </div>
                {!isTicket(categoriesList, category) && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                      marginTop: 30,
                      width: 400,
                    }}
                  >
                    <div style={{ flex: 3 }}>
                      <p
                        style={{
                          ...styles.label,
                          width: "auto",
                          marginLeft: 0,
                          marginRight: 0,
                        }}
                      >
                        Percentuale di guadagno
                      </p>
                      <div style={{ ...styles.box, width: "auto" }}>
                        {isEnabled ? (
                          <p>
                            {price !== "" &&
                            price !== null &&
                            price !== undefined &&
                            parseFloat(price) !== parseFloat(0)
                              ? parseFloat(
                                  (((price - cost) * 100) / price).toFixed(2)
                                )
                              : 100}
                            %
                          </p>
                        ) : (
                          <input
                            value={percentage}
                            onChange={(event) => {
                              var current = event.target.value.replace(
                                /[^0-9.]/,
                                ""
                              );
                              current = hasThreeDecimals(current)
                                ? parseFloat(current).toFixed(2)
                                : current;
                              setPercentage(current);
                              setCost(
                                price !== "" &&
                                  price !== null &&
                                  price !== undefined
                                  ? (price - (price * current) / 100).toFixed(2)
                                  : 0.0
                              );
                            }}
                            placeholder="Percentuale"
                            style={styles.textInput}
                          />
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        flex: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 50,
                      }}
                    >
                      <Switch
                        onChange={() => {
                          setPercentage(
                            price !== ""
                              ? (((price - cost) * 100) / price).toFixed(2)
                              : 0
                          );
                          setCost(
                            price !== ""
                              ? (price - (price * percentage) / 100).toFixed(2)
                              : 0
                          );
                          setEnabled(!isEnabled);
                        }}
                        checked={isEnabled}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        onColor={"#808080"}
                        width={30}
                        height={20}
                      />
                    </div>
                    <div style={{ flex: 3 }}>
                      <p
                        style={{
                          ...styles.label,
                          width: "auto",
                          marginLeft: 0,
                          marginRight: 0,
                        }}
                      >
                        Costo di carico
                      </p>
                      <div style={{ ...styles.box, width: "auto" }}>
                        {isEnabled ? (
                          <input
                            value={cost}
                            onChange={(event) => {
                              var current = event.target.value.replace(
                                /[^0-9.]/,
                                ""
                              );
                              current = hasThreeDecimals(current)
                                ? parseFloat(current).toFixed(2)
                                : current;
                              setCost(current);
                              setPercentage(
                                price !== "" &&
                                  price !== null &&
                                  price !== undefined
                                  ? (((price - current) * 100) / price).toFixed(
                                      2
                                    )
                                  : 100
                              );
                            }}
                            placeholder="Costo"
                            style={styles.textInput}
                          />
                        ) : (
                          <p>
                            €{" "}
                            {price !== "" &&
                            price !== null &&
                            price !== undefined
                              ? (price - (price * percentage) / 100).toFixed(2)
                              : 0.0}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div style={styles.footer2buttons}>
                <button
                  onClick={() => {
                    setStep(1);
                    setAlreadyUsedName(false);
                    setUsingCode("");
                  }}
                  style={styles.buttonLeft}
                >
                  INDIETRO
                </button>
                <button
                  onClick={() => {
                    setLoader(true);
                    itemsService
                      .getItems(org.id, 0, -1, category, details, author)
                      .then((res) => {
                        if (res.length === 0) {
                          setAlreadyUsedName(false);
                          setUsingCode("");

                          // no article with the same name already in the same category
                          let finalCost;
                          let finalPercentage;
                          if (cost === "") {
                            finalCost = (
                              price -
                              (price * percentage) / 100
                            ).toFixed(2);
                          } else {
                            finalCost = cost;
                          }
                          if (parseInt(percentage) === 0) {
                            finalPercentage = (
                              ((price - finalCost) * 100) /
                              price
                            ).toFixed(2);
                          } else {
                            finalPercentage = percentage;
                          }
                          saveData(
                            code === "" ? null : code,
                            details,
                            author,
                            parseInt(category),
                            parseInt(type),
                            seller,
                            parseInt(qty),
                            parseFloat(finalPercentage),
                            deadline,
                            parseFloat(finalCost),
                            price && parseFloat(price),
                            isTicket(categoriesList, category)
                              ? parseInt(entrances)
                              : null,
                            isTicket(categoriesList, category)
                              ? parseInt(validity)
                              : null
                          );
                        } else {
                          setAlreadyUsedName(true);
                          setUsingCode(res[0].codice_articolo);
                        }
                        setLoader(false);
                      });
                  }}
                  style={
                    (isTicket(categoriesList, category) && price === "") ||
                    (!isTicket(categoriesList, category) &&
                      (details === "" ||
                        price === "" ||
                        price === undefined ||
                        percentage === "" ||
                        cost === "" ||
                        qty === "" ||
                        parseInt(qty) === 0 ||
                        parseFloat(cost) < 0 ||
                        parseFloat(cost) > parseFloat(price)))
                      ? styles.disabledButton
                      : styles.button
                  }
                  disabled={
                    (isTicket(categoriesList, category) && price === "") ||
                    (!isTicket(categoriesList, category) &&
                      (details === "" ||
                        price === "" ||
                        price === undefined ||
                        percentage === "" ||
                        cost === "" ||
                        qty === "" ||
                        parseInt(qty) === 0 ||
                        parseFloat(cost) < 0 ||
                        parseFloat(cost) > parseFloat(price)))
                  }
                >
                  SALVA
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default AddItem;
