import React, { useState, useContext } from "react";
import close from "./../../img/closeButton.png";
import ReactLoading from "react-loading";
import moment from "moment";
import { UserContext } from "../../contexts";

const NotifyCancelReservation = ({ reservations, closeModal, saveData }) => {
  const [loader, setLoader] = useState(false);
  const colors = useContext(UserContext)?.colors;

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      width: 500,
      maxHeight: 720,
      zIndex: 300,
    },
    header: {
      display: "flex",
      width: "100%",
      height: 80,
      backgroundColor: colors?.primary,
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    content: {
      width: "100%",
      backgroundColor: colors?.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    field: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      width: "100%",
      marginLeft: 50,
      marginRight: 50,
    },
    footer: {
      display: "flex",
      width: "100%",
      height: 80,
      backgroundColor: colors?.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      backgroundColor: colors?.primary,
      color: colors?.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    cancelButton: {
      borderWidth: 1,
      borderColor: colors?.primary,
      backgroundColor: colors?.white,
      color: colors?.primary,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
  };
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: "auto",
          backgroundColor: colors?.gray,
          opacity: 0.3,
        }}
      />
      {loader ? (
        <ReactLoading
          type={"spinningBubbles"}
          color={colors?.primary}
          height={50}
          width={50}
        />
      ) : (
        <div style={styles.container}>
          <div style={styles.header}>
            <p style={{ color: colors?.white, fontSize: 20 }}>
              CAMBIO ORARIO DI APERTURA
            </p>
            <button
              onClick={closeModal}
              style={{
                position: "absolute",
                right: 30,
                marginTop: "auto",
                marginBottom: "auto",
                border: "none",
                backgroundColor: "transparent",
                outline: "none",
                cursor: "pointer",
              }}
            >
              <img
                alt="App logo"
                src={close}
                style={{ width: 15, objectFit: "contain" }}
              />
            </button>
          </div>
          <div style={styles.content}>
            <div style={styles.field}>
              <p style={{ fontSize: 15, color: colors?.lightgray }}>
                Attenzione!
              </p>
              <p style={{ textAlign: "left", color: colors?.darkgray }}>
                Modificando l'orario di apertura del museo, verranno annullate
                le prenotazioni per le seguenti visite:
              </p>
              <ul
                style={{ overflowY: "scroll", maxHeight: 300, width: "100%" }}
              >
                {reservations.map((res, key) => {
                  return (
                    <li key={key}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <p
                          style={{ textAlign: "left", color: colors?.darkgray }}
                        >
                          <b>
                            {"Giorno " +
                              moment(res.giorno_prenotazione).format(
                                "DD/MM/yyyy"
                              ) +
                              " alle ore " +
                              moment(res.ora_inizio).format("HH:mm")}
                          </b>
                        </p>
                        <p
                          style={{ textAlign: "left", color: colors?.darkgray }}
                        >
                          {res.nome_partecipante +
                            " " +
                            res.cognome_partecipante +
                            " per " +
                            res.numero_partecipanti +
                            " persone"}
                        </p>
                      </div>
                    </li>
                  );
                })}
              </ul>
              <br />
              <p style={{ textAlign: "left", color: colors?.darkgray }}>
                I visitatori saranno avvisati automaticamente via email
                dell'annullamento della loro prenotazione. <br /> Sei sicuro di
                voler procedere?
              </p>
            </div>
          </div>
          <div style={styles.footer}>
            <button onClick={closeModal} style={styles.cancelButton}>
              ANNULLA
            </button>
            <button
              onClick={() => {
                setLoader(true);
                saveData();
              }}
              style={styles.button}
            >
              CAMBIA ORARIO
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default NotifyCancelReservation;
