import React from "react";
import moment from "moment";
import "moment/locale/it";
moment.locale("it");

function isEmpty(field) {
  if (field === "" || field === null || field === undefined) {
    return " - ";
  } else {
    return field;
  }
}

function isEmptySection(section) {
  if (section === "" || section === null || section === undefined) {
    return "";
  } else {
    return section;
  }
}

const getCustomFields = (styles, fields) => {
  let customContent = [];

  for (let i = 0; i < fields?.length; i += 3) {
    customContent.push(
      <div style={styles.row} key={i}>
        <div style={styles.selectBox}>
          <p style={styles.label}>{fields[i].name}</p>
          <p style={styles.text}>{isEmpty(fields[i].value)}</p>
        </div>

        {fields[i + 1]?.value && (
          <div style={styles.selectBox}>
            <p style={styles.label}>{fields[i + 1]?.name}</p>
            <p style={styles.text}>{isEmpty(fields[i + 1]?.value)}</p>
          </div>
        )}

        {fields[i + 2]?.value && (
          <div style={styles.selectBox}>
            <p style={styles.label}>{fields[i + 2]?.name}</p>
            <p style={styles.text}>{isEmpty(fields[i + 2]?.value)}</p>
          </div>
        )}
      </div>
    );
  }

  return customContent;
};

const RequestForm = ({ colors, booking }) => {
  const styles = {
    label: {
      fontSize: 13,
      color: colors.gray,
    },
    selectBox: {
      marginTop: 5,
      marginBottom: 5,
      width: "100%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginTop: 10,
      marginBottom: 10,
    },
    textInput: {
      outline: "none",
      border: "1px solid lightgray",
      width: "80%",
      height: 20,
      borderRadius: 10,
      marginRight: 10,
      marginLeft: 10,
      padding: 5,
      textAlign: "center",
    },
    text: {
      fontSize: 13,
      margin: 0,
    },
    title: {
      fontSize: 13,
      fontWeight: "600",
      color: colors.darkgray,
      width: "90%",
      textAlign: "left",
    },
  };

  return (
    <>
      <div style={{ ...styles.row, marginTop: 30, backgroundColor: "#ececec" }}>
        <p style={styles.title}>Dati prenotazione</p>
      </div>
      <div style={styles.row}>
        <div style={styles.selectBox}>
          <p style={styles.label}>Data e ora</p>
          <p style={styles.text}>
            {moment(new Date(booking.datetime_inizio)).format(
              "DD/MM/yyyy, HH:mm"
            )}
          </p>
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>Numero partecipanti</p>
          <p style={styles.text}>{isEmpty(booking.numero_partecipanti)}</p>
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>Numero accompagnatori</p>
          <p style={styles.text}>{isEmpty(booking.numero_accompagnatori)}</p>
        </div>
      </div>
      <div style={styles.row}>
        <div style={styles.selectBox}>
          <p style={styles.label}>Referente</p>
          <p style={styles.text}>
            {isEmpty(booking.nome) + " " + isEmpty(booking.cognome)}
          </p>
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>Email referente</p>
          <p style={styles.text}>{isEmpty(booking.email)}</p>
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>Telefono referente</p>
          <p style={styles.text}>{isEmpty(booking.numero_telefono)}</p>
        </div>
      </div>
      <div style={styles.row}>
        <div style={styles.selectBox}>
          <p style={styles.label}>Etá media gruppo</p>
          <p style={styles.text}>{isEmpty(booking.gruppo.fascia_eta)}</p>
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>Tipologia gruppo</p>
          <p style={styles.text}>{isEmpty(booking.gruppo.tipologia)}</p>
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>Relatore</p>
          <p style={styles.text}>
            {booking.relatori
              ? isEmpty(booking.relatori.name) +
                " " +
                isEmpty(booking.relatori.surname)
              : "-"}
          </p>
        </div>
      </div>
      {booking.gruppo.tipologia === "Scuola" && (
        <div style={styles.row}>
          <div style={styles.selectBox}>
            <p style={styles.label}>Grado scuola</p>
            <p style={styles.text}>{isEmpty(booking.gruppo.grado_scuola)}</p>
          </div>
          <div style={styles.selectBox}>
            <p style={styles.label}>Classe scuola</p>
            <p style={styles.text}>
              {isEmpty(booking.gruppo.classe_scuola) +
                isEmptySection(booking.gruppo.sezione)}
            </p>
          </div>
          <div style={styles.selectBox}>
            <p style={styles.label}>Tariffa scelta</p>
            <p style={styles.text}>
              {booking.biglietto.name + ", " + booking.biglietto.price + "€"}
            </p>
          </div>
        </div>
      )}
      {booking.gruppo.tipologia !== "Scuola" && (
        <div style={styles.row}>
          <div style={styles.selectBox}>
            <p style={styles.label}>Tariffa scelta</p>
            <p style={styles.text}>
              {booking.biglietto.name + ", " + booking.biglietto.price + "€"}
            </p>
          </div>
        </div>
      )}

      {booking.valori?.length > 0 && (
        <>
          <div
            style={{ ...styles.row, marginTop: 30, backgroundColor: "#ececec" }}
          >
            <p style={styles.title}>Dati aggiuntivi</p>
          </div>
          {getCustomFields(styles, booking.valori)}
        </>
      )}

      {booking.info_enti && (
        <>
          <div
            style={{ ...styles.row, marginTop: 30, backgroundColor: "#ececec" }}
          >
            <p style={styles.title}>Dati di fatturazione</p>
          </div>
          <div style={styles.row}>
            <div style={styles.selectBox}>
              <p style={styles.label}>Ragione sociale/Nome e Cognome</p>
              <p style={styles.text}>
                {isEmpty(booking.info_enti.ragione_sociale)}
              </p>
            </div>
            <div style={styles.selectBox}>
              <p style={styles.label}>Indirizzo</p>
              <p style={styles.text}>
                {isEmpty(booking.info_enti.via) +
                  " " +
                  isEmpty(booking.info_enti.comune) +
                  " " +
                  isEmpty(booking.info_enti.provincia) +
                  " " +
                  isEmpty(booking.info_enti.cap) +
                  " " +
                  isEmpty(booking.info_enti.nazione)}
              </p>
            </div>
            <div style={styles.selectBox}>
              <p style={styles.label}>Telefono</p>
              <p style={styles.text}>{isEmpty(booking.info_enti.telefono)}</p>
            </div>
          </div>
          <div style={styles.row}>
            <div style={styles.selectBox}>
              <p style={styles.label}>Partita IVA</p>
              <p style={styles.text}>
                {isEmpty(booking.info_enti.partita_iva)}
              </p>
            </div>
            <div style={styles.selectBox}>
              <p style={styles.label}>Codice fiscale</p>
              <p style={styles.text}>
                {isEmpty(booking.info_enti.codice_fiscale)}
              </p>
            </div>
            <div style={styles.selectBox}>
              <p style={styles.label}>Codice destinatario</p>
              <p style={styles.text}>
                {isEmpty(booking.info_enti.codice_destinatario)}
              </p>
            </div>
          </div>
          <div style={styles.row}>
            <div style={styles.selectBox}>
              <p style={styles.label}>PEC</p>
              <p style={styles.text}>{isEmpty(booking.info_enti.pec)}</p>
            </div>
            <div style={styles.selectBox}>
              <p style={styles.label}>CUP</p>
              <p style={styles.text}>{isEmpty(booking.info_enti.cup)}</p>
            </div>
            <div style={styles.selectBox}>
              <p style={styles.label}>CIG</p>
              <p style={styles.text}>{isEmpty(booking.info_enti.cig)}</p>
            </div>{" "}
          </div>
        </>
      )}
    </>
  );
};

export default RequestForm;
