import React from "react";
import AddCategory from "../../../components/popup/addCategory";
import ModifyCategory from "../../../components/popup/modifyCategory";
import RemoveSchemaEvent from "../popup/removeSchemaEvent";
import RemoveEvent from "../popup/removeEvent";
import EndOperation from "../../../components/popup/endOperation";
import DisableEventCategory from "../popup/disableEventCategory";
import GenerateInvitation from "../../../components/popup/generateInvitation";
import HandleWaitingList from "../../../components/popup/handleWaitingList";

const PopupManager = ({
  showAddCategory,
  showModifyCategory,
  showDisableCategory,
  showDeleteEvent,
  showDelete,
  endOperation,
  showGenerate,
  showWaitingListPopup,
  onHandleWaitingList,
  toggleHideWaiting,
  colors,
  toggleAddCategory,
  onAddCategory,
  eventSelected,
  toggleModifyCategory,
  onModifyCategory,
  toggleDisableCategory,
  onDisableCategory,
  toggleDeleteEvent,
  onDeleteEvent,
  toggleDeleteSchema,
  onDeleteSchema,
  operationResult,
  operation,
  toggleEndOperation,
  toggleShowGenerate,
  onGenerateInvitations,
  invitationLoader,
  waitingLoader,
}) => {
  return (
    <div>
      {showAddCategory && (
        <AddCategory
          closeModal={toggleAddCategory}
          saveData={onAddCategory}
          colors={colors}
        />
      )}
      {showModifyCategory && (
        <ModifyCategory
          name={eventSelected ? eventSelected.nome_categoria : ""}
          closeModal={toggleModifyCategory}
          saveData={onModifyCategory}
          colors={colors}
        />
      )}
      {showDisableCategory && (
        <DisableEventCategory
          name={eventSelected ? eventSelected.nome_categoria : ""}
          closeModal={toggleDisableCategory}
          saveData={onDisableCategory}
          colors={colors}
        />
      )}
      {showDeleteEvent && (
        <RemoveEvent
          date={eventSelected.ora_inizio}
          closeModal={toggleDeleteEvent}
          saveData={onDeleteEvent}
          colors={colors}
        />
      )}
      {showDelete && (
        <RemoveSchemaEvent
          name={eventSelected.nome_evento}
          closeModal={toggleDeleteSchema}
          saveData={onDeleteSchema}
          colors={colors}
        />
      )}
      {endOperation && (
        <EndOperation
          flag={operationResult}
          operation={operation}
          closeModal={toggleEndOperation}
          colors={colors}
        />
      )}
      {showGenerate && (
        <GenerateInvitation
          capacity={eventSelected.posti_totali}
          closeModal={toggleShowGenerate}
          colors={colors}
          saveData={onGenerateInvitations}
          loader={invitationLoader}
        />
      )}
      {showWaitingListPopup && (
        <HandleWaitingList
          closeModal={toggleHideWaiting}
          colors={colors}
          saveData={onHandleWaitingList}
          loader={waitingLoader}
        />
      )}
    </div>
  );
};

export default PopupManager;
