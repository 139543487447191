import React, { useState, useContext, useEffect } from "react";
import SubBar from "./analyticsBar";
import Reports from "./report/report";
import DataVisualization from "./anagraphic/dataVisualization";
import { eventsService, reportService } from "../../_services";
import { OrgContext, UserContext } from "../../contexts";
import TopBar from "./topBars/topBar";
import moment from "moment";
import SalesVisualization from "./sales/salesVisualization";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import { LanguageContext } from "../../containers/language";
import UsersBehaviorVisualization from "./usersBehavior/usersBehaviorVisualization";

//Load the page corresponding to the option selected in the analysticsBar
function handleSelection(
  selection,
  setIsReport,
  resetFilters,
  visitorsLoader,
  salesLoader,
  visitorsData,
  categoriesList,
  hideLine,
  filters,
  isAnnual,
  isFree,
  visitors,
  salesCategories,
  tabIndex
) {
  switch (selection) {
    case 1:
      return (
        <DataVisualization
          loader={visitorsLoader}
          visitorsData={visitorsData}
          setIsReport={setIsReport}
          hideLine={hideLine}
          filters={filters}
          isAnnual={isAnnual}
          isFree={isFree}
          totVis={visitors}
          tab={tabIndex === 0 ? "visitatori" : "bookshop"}
        />
      );
    case 2:
      return (
        <SalesVisualization
          loader={salesLoader}
          setIsReport={setIsReport}
          hideLine={hideLine}
          filters={filters}
          isAnnual={isAnnual}
          isFree={isFree}
          salesCategories={salesCategories}
        />
      );
    case 3:
      return <UsersBehaviorVisualization filters={filters} />;
    case 4:
      return <></>;
    case 5:
      return (
        <Reports
          setIsReport={setIsReport}
          categoriesList={categoriesList}
          resetFilters={resetFilters}
        />
      );
    default:
      break;
  }
}

const Analytics = (props) => {
  const org = useContext(OrgContext)?.org;

  //Multiple loaders due to the different requests. One loader for each possible page
  const [visitorsLoader, setVisitorsLoader] = useState(true);
  const [salesLoader, setSalesLoader] = useState(true);

  //The page selected
  const [selection, setSelection] = useState(
    org.settings.profilazione_visitatori ? 1 : 2
  );

  useEffect(() => {
    let index = props.index;
    if (index !== undefined && !isNaN(parseInt(index))) {
      setSelection(parseInt(index));
    } else {
      props.permissions?.anagrafica && org.settings.profilazione_visitatori
        ? setSelection(1)
        : props.permissions?.vendite
        ? setSelection(2)
        : props.permissions?.user_behavior
        ? setSelection(3)
        : setSelection(2);
    }
  }, [props.permissions, org.settings.profilazione_visitatori, props.index]);

  //The list of events' cateories
  const [categoriesList, setCategories] = useState([]);

  //startingDate and endingDate used to identify the time period within which elaborate the data
  const now = new Date();
  const [startingDate, setStartingDate] = useState(
    new Date(now.getFullYear(), now.getMonth(), 1)
  );
  const [endingDate, setEndingDate] = useState(new Date());

  //The info about visitors in the specified time period
  const [visitorsData, setVisitorsData] = useState(null);

  //The cound of visitors within the specified time period
  const [visitorsCount, setVisitorsCount] = useState(0);

  //The list of sales categories which haveat least one sale in the selected time period
  const [salesCategories, setSalesCategories] = useState([]);

  //Flags used to define the X-axis for line graphs
  const [isAnnual, setIsAnnual] = useState(false);
  const [isFree, setIsFree] = useState(false);

  //Flag used to check if the selected page is the report one: in that case, the topBar used for the other pages won't be used
  //as a custom bar is integrated into the report page itself
  const [isReport, setIsReport] = useState(false);

  //Default filter for when the analytics are open for the first time
  const defaultFilters = {
    start: moment(startingDate).format("yyyy-MM-DD"),
    end: moment(endingDate).format("yyyy-MM-DD"),
  };

  const [filters, setFilters] = useState(defaultFilters);

  const resetFilters = () => {
    setStartingDate(new Date(now.getFullYear(), now.getMonth(), 1));
    setEndingDate(new Date());
  };

  //When selecting free date as time periode, hide the line graphs as it is not possible to handle the x-axis division of data
  const [hideLine, setHideLine] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);
  const { dictionary } = useContext(LanguageContext);
  const colors = useContext(UserContext)?.colors;

  //When the page is first rendered, we set the list of events' categories
  useEffect(() => {
    eventsService
      .getEventCategories()
      .then((categoriesList) => {
        setCategories(categoriesList);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  }, [props.history]);

  //Each time a new time period is selected, we set the time filters used to get the visitors data
  useEffect(() => {
    setFilters({
      start: moment(startingDate).format("YYYY-MM-DD"),
      end: moment(endingDate).format("YYYY-MM-DD"),
    });
  }, [startingDate, endingDate]);

  //Each time the time filters are updated, we requeste the visitors data within that period
  useEffect(() => {
    if (selection === 1) {
      setVisitorsLoader(true);
      //The if is necessary to avoid sending the geVisitorsData request while initializing
      if (filters.start === filters.end || filters.start !== filters.end) {
        reportService
          .getVisitorsData(
            org,
            filters.start,
            filters.end,
            -1,
            tabIndex === 0 ? "visitatori" : "bookshop"
          )
          .then((data) => {
            setVisitorsData(data);

            if (data.length === 0) {
              setVisitorsCount(0);
            } else {
              setVisitorsCount(data.totVis);
            }
            setVisitorsLoader(false);
          })
          .catch((errors) => {
            if (errors.statusCode === 401 || errors.statusCode === 403) {
              this.props.history.push("/");
            }
          });
      }
    } else if (selection === 2) {
      setSalesLoader(true);
      reportService
        .getSalesCategories(org, filters.start, filters.end)
        .then((cat) => {
          setSalesCategories(cat);
          setSalesLoader(false);
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            this.props.history.push("/");
          }
        });
    }
  }, [filters, org, selection, tabIndex]);

  //If the user selects an event as the time filter, we define the new starting and ending date
  const handleChosenEvent = (event) => {
    if (event !== undefined) {
      setStartingDate(moment(event.ora_inizio).format("yyyy-MM-DD"));
      setEndingDate(moment(event.ora_fine).format("yyyy-MM-DD"));
    }
  };

  //Update the starting and ending date when the user selects freely both the former and the latter
  const handleChosenDate = (startingDate, endingDate) => {
    setIsFree(true);
    setStartingDate(moment(startingDate).format("yyyy-MM-DD"));
    setEndingDate(moment(endingDate).format("yyyy-MM-DD"));
  };

  //Update the starting and ending dates when selecting the monthly or annual date filter
  const dateSelected = (date, isAnnual) => {
    var first_d = new Date();
    var last_d = new Date();
    var today = new Date();

    setIsAnnual(isAnnual);
    setIsFree(false);

    //If the filter selected is "Annuale" (annual)
    if (isAnnual) {
      //The first day is 1st January of that year
      first_d.setFullYear(date.getFullYear());
      first_d.setMonth(0);
      first_d.setDate(1);

      //If we are considering the current year, set today as last day
      if (date.getFullYear() === today.getFullYear()) {
        last_d = today;
      } else {
        //Set the ending date as the last day of that year
        last_d.setFullYear(date.getFullYear());
        last_d.setMonth(11);
        last_d.setDate(31);
      }
    }
    //Else the filter selected is "Mensile" (monthly)
    else {
      first_d = new Date(date.getFullYear(), date.getMonth(), 1);

      //If we are considering the current month, set today as last day
      if (
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth()
      ) {
        last_d = today;
      } else {
        last_d = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      }
    }

    setStartingDate(moment(first_d).format("yyyy-MM-DD"));
    setEndingDate(moment(last_d).format("yyyy-MM-DD"));
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      width: props.iframe ? "100%" : "85%",
      position: "absolute",
      right: 0,
      alignItems: "center",
    },
    body: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflow: "scroll",
    },
    padder: {
      width: "100%",
      paddingTop: selection === 1 ? 0 : "10px",
    },
    content: {
      width: "100%",
      height: "100%",
      overflow: "scroll",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: selection === 1 ? 0 : "60px",
    },
    rowTop: {
      alignItems: "start",
      color: colors?.primary,
      width: "100%",
      marginTop: "60px",
    },
  };

  return (
    <div style={styles.container}>
      {org.settings.profilazione_visitatori && !props.iframe && (
        <SubBar
          selection={selection}
          setSelection={(section) => setSelection(section)}
          permissions={props.permissions}
        />
      )}
      <div style={styles.body}>
        <div style={styles.padder}>
          {selection === 1 && (
            <div style={styles.rowTop}>
              <Tabs
                value={tabIndex}
                variant="fullWidth"
                TabIndicatorProps={{ style: { background: colors?.secondary } }}
                onChange={(event, newValue) => {
                  setTabIndex(newValue);
                }}
              >
                <Tab label={dictionary.visitors} />
                <Tab label={"Clienti Bookshop"} />
              </Tabs>
            </div>
          )}
          {!isReport && (
            <TopBar
              dateSelected={(date, isAnnual) => dateSelected(date, isAnnual)}
              setDate={(startingDate, endingDate) =>
                handleChosenDate(startingDate, endingDate)
              }
              categoriesList={categoriesList}
              setChosenEvent={(event) => handleChosenEvent(event)}
              hideLine={setHideLine}
              startingDate={startingDate}
              endingDate={endingDate}
              selection={selection}
              tabIndex={tabIndex}
            />
          )}
        </div>
        <div style={styles.content}>
          {handleSelection(
            selection,
            setIsReport,
            resetFilters,
            visitorsLoader,
            salesLoader,
            visitorsData,
            categoriesList,
            hideLine,
            filters,
            isAnnual,
            isFree,
            visitorsData !== null ? visitorsCount : 0,
            salesCategories,
            tabIndex
          )}
        </div>
      </div>
    </div>
  );
};

export default Analytics;
