import { BehaviorSubject } from "rxjs";
import config from "../config";
import { handleResponse } from "../_helpers";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);
const currentTokenSubject = new BehaviorSubject(
  localStorage.getItem("currentToken")
);

export const authenticationService = {
  login,
  loginWithOrg,
  changeOrg,
  signup,
  forgotPassword,
  changePassword,
  emailValidation,
  logout,
  getOrgs,
  currentUser: currentUserSubject.asObservable(),
  currentToken: currentTokenSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  get currentTokenValue() {
    return currentTokenSubject.value;
  },
  getMultiOrgToken,
  acceptMultiOrg,
  declineMultiOrg,
};

async function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      identifier: email,
      password: password,
    }),
  };

  const response = await fetch(`${config.api.url}/auth/local`, requestOptions);
  const response_1 = await handleResponse(response);
  const user = response_1.user;
  const jwt = response_1.jwt;
  return setLoginInfo(user, jwt);
}

async function loginWithOrg(email, password, orgId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      identifier: email,
      password: password,
      orgId: orgId,
    }),
  };

  const response = await fetch(
    `${config.api.url}/auth/local/withOrgId`,
    requestOptions
  );
  const response_1 = await handleResponse(response);
  const user = response_1.user;

  const jwt = response_1.jwt;
  return setLoginInfo(user, jwt);
}

async function changeOrg(email, orgId) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      identifier: email,
      orgId: orgId,
    }),
  };

  const response = await fetch(
    `${config.api.url}/auth/local/changeOrg`,
    requestOptions
  );
  const response_1 = await handleResponse(response);
  const user = response_1.user;

  const jwt = response_1.jwt;
  return setLoginInfo(user, jwt);
}

async function getOrgs(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      identifier: email,
      password: password,
    }),
  };

  const orgs = await fetch(
    `${config.api.url}/auth/loginAndGetOrgs`,
    requestOptions
  );
  const orgs_1 = await handleResponse(orgs);
  const user = orgs_1.user;
  const jwt = orgs_1.jwt;
  setLoginInfo(user, jwt);
  return orgs_1;
}

async function signup(
  nome,
  nome_museo,
  indirizzo_museo,
  CAP_museo,
  citta_museo,
  numero_telefono_museo,
  email_museo,
  admin_username,
  admin_email,
  admin_pw,
  capienza_massima,
  timezone,
  moduli,
  descrizione,
  //colore,
  logoLight,
  logoDark,
  cover
) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      nome,
      nome_museo,
      indirizzo_museo,
      CAP_museo,
      citta_museo,
      numero_telefono_museo,
      email_museo,
      admin_username,
      admin_email,
      admin_pw,
      capienza_massima,
      timezone,
      moduli,
      descrizione,
      //colore,
      logoLight,
      logoDark,
      cover,
    }),
  };

  try {
    const response = await fetch(`${config.api.url}/museis`, requestOptions);
    const response_1 = await handleResponse(response);
    return response_1;
  } catch (error) {
    return error;
  }
}

async function forgotPassword(email) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  };

  try {
    const response = await fetch(
      `${config.api.url}/auth/forgot-password`,
      requestOptions
    );
    const response_1 = await handleResponse(response);
    return response_1;
  } catch (error) {
    return false;
  }
}

async function changePassword(code, password, passwordConfirmation) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      code,
      password,
      passwordConfirmation,
    }),
  };

  try {
    const response = await fetch(
      `${config.api.url}/auth/reset-password`,
      requestOptions
    );
    const response_1 = await handleResponse(response);
    return response_1;
  } catch (error) {
    return false;
  }
}

async function emailValidation(email) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  };

  try {
    const response = await fetch(
      `${config.api.url}/auth/send-email-confirmation`,
      requestOptions
    );
    const response_1 = await handleResponse(response);
    return response_1;
  } catch (error) {
    return false;
  }
}

async function getMultiOrgToken(token) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const response = await fetch(
      `${config.api.url}/multiorg-registration-tokens?token=${token}`,
      requestOptions
    );
    const response_1 = await handleResponse(response);
    return response_1;
  } catch (error) {
    return false;
  }
}

async function acceptMultiOrg(jwt) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      jwt,
    }),
  };

  try {
    const response = await fetch(
      `${config.api.url}/multiorg-registration-tokens/confirm`,
      requestOptions
    );
    const response_1 = await handleResponse(response);
    return response_1;
  } catch (error) {
    return false;
  }
}

async function declineMultiOrg(jwt) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      jwt,
    }),
  };

  try {
    const response = await fetch(
      `${config.api.url}/multiorg-registration-tokens/refuse`,
      requestOptions
    );
    const response_1 = await handleResponse(response);
    return response_1;
  } catch (error) {
    return false;
  }
}

/**
 * Stores in local storare the info about the logged user.
 * @param {Object} user
 * @param {String} jwt
 * @returns {Object} the user object given as parameter
 */
function setLoginInfo(user, jwt) {
  if (typeof user !== "object" || (typeof user === "object" && user === null)) {
    throw new Error(
      "[_services][authentication][setLoginInfo] user is empty",
      user
    );
  }
  if (typeof jwt !== "string" || (typeof jwt === "string" && jwt === "")) {
    throw new Error(
      "[_services][authentication][setLoginInfo] jwt is empty",
      jwt
    );
  }
  localStorage.setItem("currentUser", JSON.stringify(user));
  localStorage.setItem("currentToken", jwt);
  currentUserSubject.next(user);
  currentTokenSubject.next(jwt);
  return user;
}

/**
 * Logs out the user removing the user and jwt from local storage.
 */
function logout() {
  localStorage.removeItem("currentUser");
  localStorage.removeItem("currentToken");
  currentUserSubject.next(null);
  currentTokenSubject.next(null);
}
