import React, { useContext } from "react";
import SubButton from "../../components/buttons/subButton";
import anagrafica from "./../../img/users.png";
import vendite from "./../../img/vendite.png";
import users from "./../../img/users.png";
// import ingressi from "./../../img/ingressi.png";
// import report from "./../../img/report.png";
import anagrafica_selected from "./../../img/users_selected.png";
import vendite_selected from "./../../img/vendite_selected.png";
import users_selected from "./../../img/users_selected.png";
// import ingressi_selected from "./../../img/ingressi_selected.png";
// import report_selected from "./../../img/report_selected.png";
import { UserContext } from "../../contexts";

const AnalyticsBar = (props) => {
  const colors = useContext(UserContext)?.colors;

  const styles = {
    sideBar: {
      width: "15%",
      backgroundColor: colors?.darkgray,
      height: "100%",
      boxShadow: "2px 0 4px 0 rgb(0 0 0 / 20%)",
    },
  };
  return (
    <div style={styles.sideBar}>
      {props.permissions?.anagrafica && (
        <SubButton
          icon={anagrafica}
          selectedIcon={anagrafica_selected}
          mod={{ id: 1, name: "Anagrafica" }}
          colors={colors}
          onClick={() => props.setSelection(1)}
          selection={props.selection}
          size={18}
          selectionColor={colors.gray}
          hoverColor={colors.mediumgray}
          selectionTextColor={colors?.tertiary}
        />
      )}
      {props.permissions?.vendite && (
        <SubButton
          icon={vendite}
          selectedIcon={vendite_selected}
          mod={{ id: 2, name: "Vendite" }}
          colors={colors}
          onClick={() => props.setSelection(2)}
          selection={props.selection}
          size={18}
          selectionColor={colors.gray}
          hoverColor={colors.mediumgray}
          selectionTextColor={colors?.tertiary}
        />
      )}
      {props.permissions?.user_behavior && (
        <SubButton
          icon={users}
          selectedIcon={users_selected}
          mod={{ id: 3, name: "Comportamento Utenti" }}
          colors={colors}
          onClick={() => props.setSelection(3)}
          selection={props.selection}
          size={18}
          selectionColor={colors.gray}
          hoverColor={colors.mediumgray}
          selectionTextColor={colors?.tertiary}
        />
      )}
      {/* <SubButton
          icon={ingressi}
          selectedIcon={ingressi_selected}
          mod={{ id: 4, name: "Ingressi" }}
          colors={colors}
          onClick={() => props.setSelection(4)}
          selection={props.selection}
          size={20}
          selectionColor={colors.gray}
          hoverColor={colors.mediumgray}
          selectionTextColor={colors?.tertiary}
        /> */}
      {/* <SubButton
        icon={report}
        selectedIcon={report_selected}
        mod={{ id: 5, name: "Report" }}
        colors={colors}
        onClick={() => props.setSelection(5)}
        selection={props.selection}
        size={20}
        selectionColor={colors.gray}
        hoverColor={colors.mediumgray}
        selectionTextColor={colors?.tertiary}
      /> */}
    </div>
  );
};

export default AnalyticsBar;
