import React, { useState, useEffect } from "react";
import colors from "./../../../../museumColors";
import logo from "./../../../../img/logo_white.png";
import LoginForm from "./loginForm";
import SignupForm from "./signupForm";
import { useParams } from "react-router-dom";

//@material-ui/tab
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

const SpeakerLogin = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [isMandatory, setIsMandatory] = useState(false);

  const [user, setUser] = useState(null);

  let { orgId } = useParams();
  const [hideSignup, setHideSignup] = useState(true);

  const [tabIndex, setTabIndex] = useState(0);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (windowSize.innerWidth > 800) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }

    if (orgId === undefined) {
      setHideSignup(true);
    } else {
      setHideSignup(false);
    }

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [orgId, windowSize.innerWidth]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const toggleMandatory = () => {
    setIsMandatory(!isMandatory);
  };

  //This use effect imposes that the signup tab (on mobile) is shown after trying to login with an account without a speaker related
  useEffect(() => {
    if (isMandatory) {
      setTabIndex(1);
    }
  }, [isMandatory]);

  const handleExistingUser = (user) => {
    setUser(user);
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      alignItems: "center",
      backgroundColor: colors.secondary,
      overflow: "scroll",
    },
    header: {
      display: isMobile ? "" : "flex",
      flex: isMobile ? 1 : 4,
      alignItems: "center",
      justifyContent: "center",
      marginTop: 30,
    },
    content: {
      display: "flex",
      width: "80%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 10,
      // height: "60%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },
  };

  return (
    <div style={styles.container}>
      <a style={styles.header} href="https://www.rnb4culture.com/emma/">
        <img
          alt="App logo"
          src={logo}
          style={{
            width: isMobile
              ? windowSize.innerWidth / 3
              : windowSize.innerWidth / 8,
            objectFit: "contain",
          }}
        />
      </a>
      <div style={styles.content}>
        {!isMobile ? (
          <div style={styles.row}>
            {!isMandatory && (
              <LoginForm
                history={props.history}
                isMandatory={isMandatory}
                setIsMandatory={toggleMandatory}
                setUser={(user) => handleExistingUser(user)}
                hideSignup={hideSignup}
                setHideSignup={setHideSignup}
              />
            )}
            {!hideSignup && (
              <SignupForm
                history={props.history}
                isMandatory={isMandatory}
                setIsMandatory={toggleMandatory}
                user={user}
                orgId={orgId}
              />
            )}
          </div>
        ) : (
          <>
            {tabIndex === 0 && (
              <LoginForm
                history={props.history}
                isMandatory={isMandatory}
                setIsMandatory={toggleMandatory}
                setUser={(user) => handleExistingUser(user)}
                hideSignup={hideSignup}
                setHideSignup={setHideSignup}
                isMobile={isMobile}
              />
            )}
            {tabIndex === 1 && (
              <SignupForm
                history={props.history}
                isMandatory={isMandatory}
                setIsMandatory={toggleMandatory}
                user={user}
                orgId={orgId}
                isMobile={isMobile}
              />
            )}
            {!hideSignup && (
              <Tabs
                variant="fullWidth"
                value={tabIndex}
                TabIndicatorProps={{ style: { background: colors.primary } }}
                onChange={(event, newValue) => {
                  setTabIndex(newValue);
                }}
                style={{
                  backgroundColor: "white",
                  position: "fixed",
                  bottom: "0",
                  right: "0",
                  left: "0",
                }}
              >
                <Tab label={"Login"} />
                <Tab label={"Registrazione"} />
              </Tabs>
            )}
          </>
        )}
      </div>
      <div style={{ display: "flex", flex: 1 }}></div>
    </div>
  );
};

export default SpeakerLogin;
