import React, { useState, useContext, useEffect } from "react";
import colors from "./../../../../museumColors";
import emma from "./../../../../img/emma.png";
import show from "./../../../../img/show.png";
import RetrievePassword from "../../../../components/popup/retrievePassword";
import {
  activitiesService,
  authenticationService,
} from "../../../../_services";
import { UserContext } from "../../../../contexts";
import ReactLoading from "react-loading";

const LoginForm = (props) => {
  const user = useContext(UserContext)?.user;

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [openRetrievePassword, setOpenRetrievePassword] = useState(false);
  const [showWrongEmail, setShowWrongEmail] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(true);

  const [logLoader, setLogLoader] = useState(false);

  //Bool used to handle the case in which a user has no Speaker linked
  const [noSpeaker, setNoSpeaker] = useState(props.isMandatory);

  const toggleRetrievePassword = () => {
    setShowConfirmation(false);
    setOpenRetrievePassword(!openRetrievePassword);
  };

  useEffect(() => {
    setNoSpeaker(props.isMandatory);
    if (!props.isMandatory) {
      setShowError(false);
    }
  }, [props.isMandatory]);

  useEffect(() => {
    if (user) {
      //window.location = "http://localhost:3000/relatore/dashboard/"; //"https://emma4culture.com/relatore/dashboard";
    }
    setLoader(false);
  }, [user]);

  const retrievePassword = (email) => {
    authenticationService.forgotPassword(email).then((response) => {
      if (response) {
        setShowWrongEmail(false);
        setShowConfirmation(true);
      } else {
        setShowWrongEmail(true);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // login();
  };

  /**
   * Call backend for login
   */
  const login = () => {
    setMessage("");
    setShowError(false);

    let user = null;

    setLogLoader(true);

    authenticationService
      .login(email, password)
      .then((response) => {
        user = response;
        // login andato a buon fine
        activitiesService
          .getSpeakerFromUser(response.id)
          .then((response) => {
            setLogLoader(false);
            if (response.length > 0) {
              window.location = `${process.env.REACT_APP_WEB_URL}/relatore/dashboard/`; //"https://emma4culture.com/relatore/dashboard" + speaker[0].id;
            } else {
              props.setIsMandatory();
              props.setHideSignup(false);
              props.setUser(user);
              // c'è stato un errore, mostra il messaggio
              setShowError(true);
              setMessage("Nessun relatore associato a questo account. Crealo");
            }
          })
          .catch((errors) => {
            setLogLoader(false);
            props.setIsMandatory();
            props.setHideSignup(false);
            props.setUser(user);
            // c'è stato un errore, mostra il messaggio
            setShowError(true);
            setMessage(errors.message[0].messages.message);
          });
      })
      .catch((errors) => {
        setLogLoader(false);
        // c'è stato un errore, mostra il messaggio
        setShowError(true);
        setMessage(errors.message[0].messages.message);
      });
  };

  const styles = {
    content: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    textInput: {
      outline: "none",
      padding: 10,
      textAlign: "left",
      width: "60%",
      border: "1px solid lightgray",
      borderRadius: 5,
      fontFamily: "inherit",
      resize: "none",
      backgroundColor: colors.quaternary,
    },
    field: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      marginTop: 20,
    },
    label: {
      color: colors.primary,
      width: 100,
      marginRight: 40,
    },
    password: {
      color: colors.primary,
      fontStyle: "italic",
      fontSize: 13,
    },
    message: {
      color: colors.white,
      fontWeight: 700,
      fontSize: 13,
      width: "100%",
      backgroundColor: "rgba(277,277,277,0.3)",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
  };

  return (
    <div
      style={{
        width: props.isMobile ? "90%" : props.hideSignup ? "75%" : "40%",
        height: props.isMobile ? "100%" : "87%",
        marginTop: props.isMobile ? 10 : 20,
        marginRight: props.isMobile ? 0 : 20,
        marginBottom: props.isMobile ? "15%" : 0,
        backgroundColor: colors.white,
        padding: 30,
        borderRadius: 10,
        opacity: noSpeaker ? 0.6 : 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        alt="Presentation"
        src={emma}
        style={{
          width: props.hideSignup ? "25%" : "40%",
          objectFit: "contain",
        }}
      />
      <h1 style={{ color: colors.primary }}>Hai già un account?</h1>
      <h3 style={{ color: colors.secondary }}>
        Accedi inserendo le tue credenziali
      </h3>
      {showError && (
        <div style={styles.message}>
          <p style={{ color: colors.red }}>ATTENZIONE: {message}</p>{" "}
        </div>
      )}
      <div style={styles.content}>
        {loader ? (
          <ReactLoading
            type={"spinningBubbles"}
            color={colors?.primary}
            height={50}
            width={50}
          />
        ) : (
          <>
            <form
              style={{ width: props.isMobile ? "100%" : "80%", margin: 5 }}
              onSubmit={handleSubmit}
            >
              <div style={styles.field}>
                <p style={styles.label}>Email</p>
                <input
                  type="email"
                  name="email"
                  autoComplete="on"
                  value={email}
                  onChange={(event) =>
                    setEmail(event.target.value.toLowerCase())
                  }
                  placeholder="Email"
                  style={styles.textInput}
                  disabled={noSpeaker}
                />
              </div>
              <div style={styles.field}>
                <p style={styles.label}>Password</p>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  autoComplete="on"
                  value={password || ""}
                  onChange={(event) => setPassword(event.target.value)}
                  placeholder="Password"
                  style={styles.textInput}
                  disabled={noSpeaker}
                />
                {password !== "" && (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPassword(!showPassword);
                    }}
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      outline: "none",
                      cursor: "pointer",
                      marginLeft: -50,
                    }}
                    disabled={noSpeaker}
                  >
                    <img
                      alt="Show password"
                      src={show}
                      style={{ width: 20, objectFit: "contain" }}
                    />
                  </button>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                {logLoader ? (
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={colors?.primary}
                    height={50}
                    width={50}
                  />
                ) : (
                  <button
                    onClick={() => login()}
                    style={{
                      ...styles.button,
                      opacity: !email || !password ? 0.3 : 1,
                    }}
                    disabled={!email || !password || noSpeaker}
                  >
                    ACCEDI
                  </button>
                )}
              </div>
            </form>
            <button
              onClick={() => toggleRetrievePassword()}
              style={{
                border: "none",
                backgroundColor: "transparent",
                outline: "none",
                cursor: "pointer",
              }}
              disabled={noSpeaker}
            >
              <p style={styles.password}>Hai dimenticato la password?</p>
            </button>
            {openRetrievePassword && (
              <RetrievePassword
                closeModal={toggleRetrievePassword}
                retrievePassword={retrievePassword}
                showWrongEmail={showWrongEmail}
                showConfirmation={showConfirmation}
                colors={colors}
              />
            )}
            <div style={{ display: "flex", flex: 1 }}></div>
          </>
        )}
      </div>
    </div>
  );
};

export default LoginForm;
