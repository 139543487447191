import React from "react";
import arrowDown from "./../../../../img/arrow_down_dark.png";
import arrowUp from "./../../../../img/arrow_up_dark.png";
import moment from "moment";
import "moment/locale/it";
moment.locale("it");

const titles = [
  "ID",
  "Conferma Relatore",
  "Conferma Museo",
  "Attività",
  "Relatore",
  "Data e ora attività",
  "Cliente",
];

const Table = ({
  bookings,
  colors,
  setOpenRecord,
  activePage,
  itemsPerPage,
  updateOrder,
  ordine,
  ascendente,
}) => {
  const styles = {
    table: {
      textAlign: "center",
      borderCollapse: "collapse",
      border: "1px solid #ddd",
      width: "100%",
      overflow: "scroll",
    },
    headerCell: {
      border: "1px solid #ddd",
      padding: "8px",
      paddingTop: "12px",
      paddingBottom: "12px",
      textAlign: "center",
      backgroundColor: colors.verylightgray,
      fontSize: 12,
      color: colors.gray,
      justifyContent: "center",
      alignItems: "center",
    },
    field: {
      border: "1px solid #ddd",
      padding: "8px",
      fontSize: 12,
    },
    row: {
      cursor: "pointer",
    },
  };

  const renderTableHeader = (titles) => {
    return titles.map((key, index) => {
      return (
        <th
          style={{
            ...styles.headerCell,
            color: ordine === key ? colors.darkgray : colors.gray,
            cursor: key !== "ID" ? "pointer" : "",
          }}
          key={index}
          onClick={() => {
            if (key !== "ID") {
              if (ordine === key) {
                updateOrder(ordine, !ascendente);
              } else {
                updateOrder(key, true);
              }
            }
          }}
        >
          {key.toUpperCase()}
          {ordine === key && (
            <img
              alt="Order Arrow icon"
              src={ascendente ? arrowUp : arrowDown}
              style={{
                width: 10,
                objectFit: "contain",
                marginLeft: "10px",
                marginBottom: "1px",
              }}
            />
          )}
        </th>
      );
    });
  };

  const checkSpeakerStatus = (status) => {
    switch (status) {
      case "pending":
        return "In attesa di conferma";
      case "confirmed":
        return "Approvata";
      case "refused":
        return "Respinta";
      default:
        return "";
    }
  };

  const checkMuseumStatus = (status) => {
    switch (status) {
      case "pending":
        return "In attesa di conferma";
      case "confirmed":
        return "Approvata";
      case "refused":
        return "Respinta";
      default:
        return "";
    }
  };

  const renderTableData = () => {
    return bookings.map((booking, key) => {
      return (
        <tr
          key={key}
          style={styles.row}
          onClick={() => {
            setOpenRecord(booking);
          }}
        >
          <td style={styles.field}>
            {key + (activePage - 1) * itemsPerPage + 1}
          </td>
          <td style={styles.field}>
            {checkSpeakerStatus(booking.speaker_feedback)}
          </td>
          <td style={styles.field}>
            {checkMuseumStatus(booking.org_feedback)}
          </td>
          <td style={styles.field}>{booking.attivita?.nome}</td>
          <td style={styles.field}>
            {booking.relatori
              ? booking.relatori.surname + " " + booking.relatori.name
              : "-"}
          </td>
          <td style={{ ...styles.field, alignItems: "column" }}>
            {moment(new Date(booking.datetime_inizio)).format(
              "DD/MM/yyyy, HH:mm"
            )}
          </td>
          <td style={styles.field}>{booking.cognome + " " + booking.nome}</td>
        </tr>
      );
    });
  };
  return (
    <table style={styles.table}>
      <tbody>
        <tr>{renderTableHeader(titles)}</tr>
        {renderTableData()}
      </tbody>
    </table>
  );
};

export default Table;
