import React, { useContext, useState } from "react";
import ticket from "./../img/ticket.png";
import { LanguageContext } from "../containers/language";
import { UserContext } from "../contexts";

const RegisterEntranceButton = ({ toggleEntrance }) => {
  const { dictionary } = useContext(LanguageContext);
  const colors = useContext(UserContext)?.colors;

  const [open, setOpen] = useState(false);
  const styles = {
    button: {
      outline: "none",
      border: "none",
      display: "flex",
      backgroundColor: colors?.highlight,
      width: 70,
      height: 70,
      borderRadius: 15,
      alignItems: "center",
      justifyContent: "center",
      color: colors?.white,
      cursor: "pointer",
    },
    buttonBox: {
      display: "flex",
      position: "absolute",
      flexDirection: "column",
      left: 90,
      bottom: 12,
      alignItems: "center",
      justifyContent: "center",
      width: 110,
    },
    hoverLabel: {
      textAlign: "center",
      fontSize: 12,
      maxWidth: 180,
    },
  };
  return (
    <div
      style={styles.buttonBox}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      {open && <p style={styles.hoverLabel}>{dictionary.questionHaveTicket}</p>}
      <button
        style={styles.button}
        onClick={() => {
          setOpen(!open);
          toggleEntrance();
        }}
      >
        <img
          alt="Ticket icon"
          src={ticket}
          style={{ height: 35, objectFit: "contain" }}
        />
      </button>
    </div>
  );
};

export default RegisterEntranceButton;
