import React, { useEffect, useState } from "react";
import close from "./../../img/closeButton.png";
import ReactLoading from "react-loading";

const GenerateInvitation = ({
  capacity,
  closeModal,
  saveData,
  colors,
  mainLoader,
}) => {
  const [loader, setLoader] = useState(mainLoader);
  const [errorNumber, setErrorNumber] = useState(false);
  const [errorNegative, setErrorNegative] = useState(false);
  const [number, setNumber] = useState("");

  useEffect(() => {
    setLoader(mainLoader);
  }, [mainLoader]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      width: 500,
      height: 300,
      zIndex: 300,
    },
    loaderBox: {
      display: "flex",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      zIndex: 300,
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors.primary,
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    content: {
      width: "100%",
      flex: 6,
      backgroundColor: colors.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    field: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginLeft: 50,
      marginRight: 50,
      marginTop: 20,
      marginBottom: 20,
    },
    footer: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    cancelButton: {
      borderWidth: 1,
      borderColor: colors.primary,
      backgroundColor: colors.white,
      color: colors.primary,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    textInput: {
      borderRadius: 5,
      border: "1px solid lightgray",
      outline: "none",
      textAlign: "center",
      margin: 10,
      padding: 10,
      width: "30%",
    },
    errorMessage: {
      color: colors.highlight,
      fontSize: 10,
      fontWeight: "bold",
      marginTop: -6,
    },
  };
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: colors.gray,
          opacity: 0.3,
        }}
      />
      {loader ? (
        <div style={styles.loaderBox}>
          <ReactLoading
            type={"spinningBubbles"}
            color={colors.primary}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <div style={styles.container}>
          <div style={styles.header}>
            <p style={{ color: colors.white, fontSize: 20 }}>GENERA INVITI</p>
            <button
              onClick={closeModal}
              style={{
                position: "absolute",
                right: 30,
                marginTop: "auto",
                marginBottom: "auto",
                border: "none",
                backgroundColor: "transparent",
                outline: "none",
                cursor: "pointer",
              }}
            >
              <img
                alt="App logo"
                src={close}
                style={{ width: 15, objectFit: "contain" }}
              />
            </button>
          </div>
          <div style={styles.content}>
            <div style={styles.field}>
              <p
                style={{
                  fontSize: 13,
                  color: colors.lightgray,
                  textAlign: "left",
                  marginBottom: 10,
                }}
              >
                Un link personale permette ad un solo visitatore ed,
                eventualmente, ad un suo ospite, di registrarsi all'evento.{" "}
                <br /> Genera tanti link quanti sono gli invitati al tuo evento
                e inseriscili nella e-mail di invito.{" "}
              </p>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ textAlign: "left", color: colors.darkgray }}>
                  Genera{" "}
                </p>
                <input
                  type="number"
                  name="number"
                  value={number}
                  onChange={(e) => {
                    setNumber(
                      parseInt(e.target.value) > capacity ||
                        parseInt(e.target.value) < 1
                        ? ""
                        : e.target.value
                    );
                    setErrorNumber(
                      parseInt(e.target.value) > capacity ? true : false
                    );
                    setErrorNegative(
                      parseInt(e.target.value) < 1 ? true : false
                    );
                  }}
                  placeholder="Numero inviti"
                  style={{ ...styles.textInput }}
                />
                <p style={{ textAlign: "left", color: colors.darkgray }}>
                  {" "}
                  link di invito.
                </p>
              </div>
              {errorNumber && (
                <p style={styles.errorMessage}>
                  Solo {capacity} posti totali disponibili.
                </p>
              )}
              {errorNegative && (
                <p style={styles.errorMessage}>
                  Inserire un numero maggiore di 0
                </p>
              )}
            </div>
          </div>
          <div style={styles.footer}>
            <button onClick={closeModal} style={styles.cancelButton}>
              ANNULLA
            </button>
            <button
              onClick={() => {
                saveData(number);
              }}
              style={{ ...styles.button, opacity: number !== "" ? 1 : 0.5 }}
              disabled={number !== "" ? false : true}
            >
              GENERA
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default GenerateInvitation;
