import React from "react";
import SubButton from "../../components/buttons/subButton";
import activate from "./../../img/activate.png";
import cards from "./../../img/cards.png";

const VouchersBar = ({
  colors,
  isNetwork,
  selection,
  setSelection,
  vouchersCategories,
  permissions,
}) => {
  const styles = {
    sideBar: {
      width: 200,
      backgroundColor: colors?.darkgray,
      height: "100%",
      boxShadow: "2px 0 4px 0 rgb(0 0 0 / 20%)",
    },
  };

  return (
    <div style={styles.sideBar}>
      <>
        {permissions?.tutti && (
          <SubButton
            icon={cards}
            mod={{ id: 1, name: "Tutti i voucher" }}
            colors={colors}
            onClick={() => setSelection(1)}
            selection={selection}
            size={18}
            selectionColor={colors.gray}
            hoverColor={colors.mediumgray}
          />
        )}
        {vouchersCategories.length > 0 && permissions?.convalida && (
          <SubButton
            icon={activate}
            mod={{ id: 2, name: "Convalida voucher" }}
            colors={colors}
            onClick={() => setSelection(2)}
            selection={selection}
            size={18}
            selectionColor={colors.gray}
            hoverColor={colors.mediumgray}
          />
        )}
      </>
    </div>
  );
};

export default VouchersBar;
