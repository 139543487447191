import config from "../config";
import { handleResponse, authHeader } from "../_helpers";

export const activitiesService = {
  createSpeaker,
  deleteSpeaker,
  getSpeakers,
  getOrgSpeakers,
  updateSpeaker,
  countSpeakers,
  createSpace,
  deleteSpace,
  getSpaces,
  updateSpace,
  countSpaces,
  updateInvoice,
  getActivityAvailabilities,
  getAllAvailabilities,
  getSpeakerAvailabilities,
  createOnline,
  getActivities,
  createSpeakerAvailability,
  modifySpeakerAvailability,
  deleteSpeakerAvailability,
  getInfoEnte,
  getSpeakerFromUser,
  getActivityCategories,
  getSpeakerReservations,
  sendEmail,
};

function createSpeaker(speaker, user) {
  var name = speaker.name;
  var surname = speaker.surname;
  var email = speaker.email;
  var mobile = speaker.mobile;
  var description = speaker.description;
  var photo = speaker.photo;
  var color = speaker.color;
  var show_email = speaker.show_email;
  var show_mobile = speaker.show_mobile;
  var organisation = user.org_museo;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      name,
      surname,
      email,
      mobile,
      description,
      photo,
      color,
      show_email,
      show_mobile,
      organisation,
      user,
    }),
  };

  return fetch(`${config.api.url}/relatoris`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteSpeaker(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/relatoris/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function getSpeakers(start, limit, searchKey, orgId) {
  var qs = require("qs");

  const _start = start === undefined ? 0 : start;
  const _limit = limit === undefined ? -1 : limit;
  const _searchKey = searchKey === undefined ? "" : searchKey;

  const requestOptions = { method: "GET", headers: authHeader() };
  if (_searchKey === "") {
    return fetch(
      `${config.api.url}/relatoris/?_start=${_start}&_limit=${_limit}&organisation=${orgId}`,
      requestOptions
    ).then(handleResponse);
  } else {
    const query = qs.stringify({
      _where: {
        _or: [{ name_contains: _searchKey }, { surname_contains: _searchKey }],
      },
    });
    return fetch(
      `${config.api.url}/relatoris/?_start=${_start}&_limit=${_limit}&organisation=${orgId}&${query}`,
      requestOptions
    ).then(handleResponse);
  }
}

function getInfoEnte(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/info_entis/${id}`, requestOptions).then(
    handleResponse
  );
}

function getActivityCategories(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/categorie_attivita/?organisation=${id}`,
    requestOptions
  ).then(handleResponse);
}

function getSpeakerReservations(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/prenotazioni_attivita/?relatori=${id}&flag_confermato=true`,
    requestOptions
  ).then(handleResponse);
}

function getOrgSpeakers(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/relatoris/?organisation=${id}`,
    requestOptions
  ).then(handleResponse);
}

function getSpeakerAvailabilities(speaker, date) {
  var formattedDate = new Date(date),
    y = formattedDate.getFullYear(),
    m = formattedDate.getMonth();
  var firstDay = new Date(y, m, 1).toISOString();
  var lastDay = new Date(y, m + 1, 1).toISOString();
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/disponibilita_relatoris/?relatori=${speaker.id}&datetime_inizio_gte=${firstDay}&datetime_inizio_lt=${lastDay}&_limit=-1`,
    requestOptions
  ).then(handleResponse);
}

function createSpeakerAvailability(
  datetime_inizio,
  datetime_fine,
  relatori,
  attivitas,
  isRepeated,
  endingTime,
  organisation
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      datetime_inizio,
      datetime_fine,
      relatori,
      attivitas,
      isRepeated,
      endingTime,
      organisation,
    }),
  };

  return fetch(`${config.api.url}/disponibilita_relatoris`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function modifySpeakerAvailability(
  id,
  datetime_inizio,
  datetime_fine,
  attivitas
) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      datetime_inizio,
      datetime_fine,
      attivitas,
    }),
  };

  return fetch(
    `${config.api.url}/disponibilita_relatoris/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteSpeakerAvailability(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };

  return fetch(
    `${config.api.url}/disponibilita_relatoris/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function updateSpeaker(speaker) {
  var name = speaker.name;
  var surname = speaker.surname;
  var email = speaker.email;
  var mobile = speaker.mobile;
  var description = speaker.description;
  var photo = speaker.photo;
  var color = speaker.color;
  var show_email = speaker.show_email;
  var show_mobile = speaker.show_mobile;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      name,
      surname,
      email,
      mobile,
      description,
      photo,
      color,
      show_email,
      show_mobile,
    }),
  };

  return fetch(`${config.api.url}/relatoris/${speaker.id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return false;
    });
}

function countSpeakers(searchKey, orgId) {
  var qs = require("qs");
  const requestOptions = { method: "GET", headers: authHeader() };
  const _searchKey = searchKey;
  const query = qs.stringify({
    _where: {
      _or: [{ name_contains: _searchKey }, { surname_contains: _searchKey }],
    },
  });
  return fetch(
    `${config.api.url}/relatoris/count?${query}&organisation=${orgId}`,
    requestOptions
  ).then(handleResponse);
}

function createSpace(space) {
  var nome = space.nome;
  var descrizione = space.descrizione;
  var posizione = space.posizione;
  var capienza = space.capienza;

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      nome,
      descrizione,
      posizione,
      capienza,
    }),
  };

  return fetch(`${config.api.url}/spazis`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteSpace(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/spazis/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function getSpaces(start, limit, searchKey) {
  const _start = start === undefined ? 0 : start;
  const _limit = limit === undefined ? -1 : limit;
  const _searchKey = searchKey === undefined ? "" : searchKey;

  const requestOptions = { method: "GET", headers: authHeader() };
  if (_searchKey === "") {
    return fetch(
      `${config.api.url}/spazis/?_start=${_start}&_limit=${_limit}`,
      requestOptions
    ).then(handleResponse);
  } else {
    return fetch(
      `${config.api.url}/spazis/?_start=${_start}&_limit=${_limit}&nome_contains=${_searchKey}`,
      requestOptions
    ).then(handleResponse);
  }
}

function updateSpace(space) {
  var nome = space.nome;
  var descrizione = space.descrizione;
  var posizione = space.posizione;
  var capienza = space.capienza;

  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      nome,
      descrizione,
      posizione,
      capienza,
    }),
  };

  return fetch(`${config.api.url}/spazis/${space.id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return false;
    });
}

function countSpaces(searchKey) {
  var qs = require("qs");
  const requestOptions = { method: "GET", headers: authHeader() };
  const _searchKey = searchKey;
  const query = qs.stringify({
    _where: {
      _or: [{ nome_contains: _searchKey }],
    },
  });
  return fetch(`${config.api.url}/spazis/count?${query}`, requestOptions).then(
    handleResponse
  );
}

function updateInvoice(id, fattura_emessa, numero_fattura) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      fattura_emessa,
      numero_fattura,
    }),
  };

  return fetch(
    `${config.api.url}/prenotazioni_attivita/update/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      console.log(error);
      return false;
    });
}

function getActivityAvailabilities(activity, orgId, date) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/attivita/availability/${activity}/${orgId}/${date}`,
    requestOptions
  ).then(handleResponse);
}

function getAllAvailabilities(orgId, date) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/attivita/availabilities/${orgId}/${date}`,
    requestOptions
  ).then(handleResponse);
}

function getActivities(orgId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/attivita/?organisation=${orgId}`,
    requestOptions
  ).then(handleResponse);
}

function getSpeakerFromUser(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/relatoris/?user=${id}`, requestOptions).then(
    handleResponse
  );
}

function createOnline(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(body),
  };

  return fetch(`${config.api.url}/prenotazioni_attivita/online`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function sendEmail(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/prenotazioni_attivita/email/${id}`,
    requestOptions
  ).then(handleResponse);
}
