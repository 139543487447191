import React, { useContext, useEffect } from "react";
import { UserContext } from "../../../contexts";
import ReactLoading from "react-loading";
import ProfitLineChart from "./profitLineChart";
import StackedBarChart from "./stackedBarChart";
import DoughnutChart from "./doughnutChart";
import BarChart from "./barChart";
import CrossedFilters from "./crossedFilters";
import SalesLineChart from "./salesLineChart";
import { OrgContext } from "../../../contexts";
import Buyers from "./buyers";

const SalesVisualization = (props) => {
  const colors = useContext(UserContext)?.colors;
  const org = useContext(OrgContext)?.org;

  useEffect(() => {
    props.setIsReport(false);
  }, [props]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      right: 0,
      alignItems: "center",
      overflow: "scroll",
    },
    row: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  };
  return props.loader ? (
    <ReactLoading
      type={"spinningBubbles"}
      color={colors?.primary}
      height={50}
      width={50}
    />
  ) : (
    <div style={styles.container}>
      {!props.hideLine && (
        <div style={styles.row}>
          <SalesLineChart
            temporalFilters={props.filters}
            isAnnual={props.isAnnual}
          />
        </div>
      )}
      {!props.hideLine && props.salesCategories.length > 0 && (
        <div style={styles.row}>
          <StackedBarChart
            temporalFilters={props.filters}
            isAnnual={props.isAnnual}
            categories={props.salesCategories}
          />
        </div>
      )}
      {props.salesCategories.length > 0 && (
        <div style={styles.row}>
          <BarChart
            temporalFilters={props.filters}
            isAnnual={props.isAnnual}
            isFree={props.isFree}
            categories={props.salesCategories}
          />
        </div>
      )}
      {props.salesCategories.length > 0 && (
        <div style={styles.row}>
          <DoughnutChart
            temporalFilters={props.filters}
            isAnnual={props.isAnnual}
            isFree={props.isFree}
            categories={props.salesCategories}
          />
        </div>
      )}
      {org.settings.biglietti_con_codice && (
        <Buyers temporalFilters={props.filters} org={org} />
      )}
      {org.settings.profilazione_visitatori &&
        props.salesCategories.length > 0 && (
          <div style={styles.row}>
            <CrossedFilters
              temporalFilters={props.filters}
              isFree={props.isFree}
              categories={props.salesCategories}
            />
          </div>
        )}
      {!props.hideLine && props.salesCategories.length > 0 && (
        <div style={styles.row}>
          <ProfitLineChart
            temporalFilters={props.filters}
            isAnnual={props.isAnnual}
            categories={props.salesCategories}
          />
        </div>
      )}
    </div>
  );
};

export default SalesVisualization;
