import React, { useState } from "react";
import Paypal from "../../components/buttons/paypal";
import ReactLoading from "react-loading";
import colors from "./../../museumColors";
import moment from "moment";
import "moment/locale/it";
moment.locale("it");

const ThirdStep = ({
  width,
  chosenCard,
  chosenTarget,
  manageOrderResponse,
  renewal,
  cardToRenew,
}) => {
  const [loading, setLoading] = useState(false);

  const styles = {
    body: {
      flex: 1,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    label: {
      fontSize: 13,
      color: colors.gray,
      marginBottom: 30,
    },
    rowTop: {
      width: "90%",
      height: 100,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      borderBottom: "1px solid black",
    },
    rowBottom: {
      flex: 1,
      width: "95%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflow: "scroll",
      marginTop: 50,
      marginBottom: 50,
    },
    cardImg: {
      height: "60%",
      objectFit: "contain",
      border: "1px solid darkgray",
      borderRadius: 15,
    },
    type: {
      textAlign: "left",
      marginBottom: 0,
      color: colors.darkgray,
      fontWeight: "500",
      marginTop: 10,
      fontSize: 15,
      marginRight: 5,
    },
    target: {
      textAlign: "left",
      marginBottom: 0,
      color: colors.gray,
      fontSize: 10,
      fontWeight: "500",
      marginTop: 10,
      marginRight: 5,
    },
    loaderBox: {
      flex: 1,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    layer: {
      width: "100%",
      height: "100%",
      backgroundColor: "rgb(0, 0, 0, 0.2)",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 400,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paypal: {
      visibility: loading ? "hidden" : "visible",
      width: "50%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
  };
  return (
    <div style={styles.body}>
      <div style={styles.rowTop}>
        {width > 1000 && (
          <img
            style={styles.cardImg}
            src={chosenTarget?.img.url}
            alt="Tessere"
          ></img>
        )}
        <div
          style={{
            width: "60%",
            display: "flex",
            flexDirection: "row",
            justifyContent: width > 1000 ? "flex-end" : "flex-start",
            alignItems: "flex-end",
            textAlign: "left",
          }}
        >
          <p style={styles.type}>{chosenCard?.nome}</p>
          <p style={styles.target}>{chosenTarget?.nome}</p>
        </div>
        {width > 1000 && (
          <div
            style={{
              width: "60%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              textAlign: "left",
            }}
          >
            <p
              style={{
                ...styles.target,
                fontSize: 10,
                fontWeight: "500",
                marginTop: 10,
                marginRight: 5,
              }}
            >
              {renewal
                ? "Nuova scadenza"
                : chosenTarget?.validita === "giorni"
                ? "Validità"
                : "Valida fino al"}
            </p>
            <p
              style={{
                ...styles.type,
                fontWeight: "500",
                marginTop: 10,
                fontSize: 15,
              }}
            >
              {renewal
                ? moment(new Date(cardToRenew?.scadenza))
                    .add(cardToRenew.target_tessere?.rinnovo, "days")
                    .format("DD MMM yyyy")
                : chosenTarget?.validita === "giorni"
                ? chosenTarget?.giorni + " giorni"
                : moment(new Date(chosenTarget?.data_scadenza)).format(
                    "DD MMM yyyy"
                  )}
            </p>
          </div>
        )}
        <div
          style={{
            width: "60%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            textAlign: "left",
          }}
        >
          <p
            style={{
              ...styles.target,
              fontSize: 10,
              fontWeight: "500",
              marginTop: 10,
              marginRight: 5,
            }}
          >
            Prezzo
          </p>
          <p
            style={{
              ...styles.type,
              fontWeight: "500",
              marginTop: 10,
              fontSize: 15,
            }}
          >
            {chosenTarget?.prezzo.toFixed(2)}
          </p>
        </div>
      </div>
      <div style={styles.rowBottom}>
        {loading && (
          <div style={styles.layer}>
            <div style={styles.loaderBox}>
              <p
                style={{
                  width: "80%",
                  color: colors.darkgray,
                  fontSize: 13,
                  marginBottom: 10,
                }}
              >
                {renewal
                  ? "Stiamo rinnovando la tua card"
                  : "Stiamo generando la tua card"}{" "}
                <b>{chosenCard?.nome + " - " + chosenTarget?.nome}</b>...
              </p>
              <ReactLoading
                type={"spinningBubbles"}
                color={colors.darkgray}
                height={50}
                width={50}
              />
            </div>
          </div>
        )}
        <div style={styles.paypal}>
          <p style={styles.label}>Seleziona metodo di pagamento</p>
          <Paypal
            nome={
              renewal
                ? "Rinnovo " +
                  cardToRenew.tipologie_tessere?.nome +
                  " - " +
                  cardToRenew.target_tessere?.nome
                : "Acquisto " + chosenCard?.nome + " - " + chosenTarget?.nome
            }
            prezzo={
              renewal
                ? cardToRenew.target_tessere?.prezzo
                : chosenTarget?.prezzo
            }
            manageOrderResponse={manageOrderResponse}
            enableLoading={() => setLoading(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default ThirdStep;
