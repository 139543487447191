import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/it";
import BillingForm from "./billingForm";
import { cardsService } from "../../_services";
moment.locale("it");

const RenewalForm = ({ colors, card, formFunctions }) => {
  const [cardGroup, setCardGroup] = useState([]);
  const styles = {
    menu: {
      border: "1px solid lightgray",
      width: 150,
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 10,
      color: colors.darkgray,
      borderRadius: 20,
      backgroundColor: "transparent",
      outline: "none",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "50%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    container: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      overflow: "scroll",
    },
    subcontainer: {
      width: "100%",
      height: "100%",
      paddingBottom: 20,
      paddingTop: 20,
    },
    goBackButton: {
      width: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    arrow: {
      height: 13,
      objectFit: "contain",
    },
    cardImg: {
      width: "40%",
      objectFit: "contain",
      border: "1px solid darkgray",
      borderRadius: 15,
    },
    type: {
      fontSize: 18,
      fontWeight: "700",
      textAlign: "center",
      marginBottom: 0,
      marginTop: 50,
      color: colors.darkgray,
    },
    target: {
      fontSize: 18,
      fontWeight: "700",
      textAlign: "center",
      marginBottom: 0,
      marginTop: 50,
      color: colors.gray,
    },
    description: {
      fontSize: 13,
      width: "100%",
      textAlign: "left",
    },
  };

  useEffect(() => {
    if (card.gruppo_tessere) {
      cardsService
        .getCardGroup(card.gruppo_tessere.id)
        .then((response) => setCardGroup(response.tesseres));
    }
  }, [card]);

  return (
    <div style={styles.container}>
      <div style={styles.subcontainer}>
        <div style={{ ...styles.row, justifyContent: "space-between" }}>
          <div style={{ ...styles.column, width: "30%" }}>
            <img
              style={styles.cardImg}
              src={card.target_tessere?.img.url}
              alt="Tessera"
            ></img>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-end",
                textAlign: "left",
              }}
            >
              <p style={styles.type}>{card.target_tessere?.nome}</p>
              <p style={styles.target}>{card.target_tessere?.nome}</p>
            </div>
            <p style={styles.description}>{card.target_tessere?.descrizione}</p>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-end",
                textAlign: "left",
              }}
            >
              <p
                style={{
                  ...styles.target,
                  fontSize: 10,
                  fontWeight: "500",
                  marginTop: 10,
                  marginRight: 5,
                }}
              >
                {"Nuova scadenza"}
              </p>
              <p
                style={{
                  ...styles.type,
                  fontWeight: "500",
                  marginTop: 10,
                  fontSize: 15,
                }}
              >
                {moment(new Date(card.scadenza))
                  .add(card.target_tessere?.rinnovo, "days")
                  .format("DD MMM yyyy")}
              </p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-end",
                textAlign: "left",
              }}
            >
              <p
                style={{
                  ...styles.target,
                  fontSize: 10,
                  fontWeight: "500",
                  marginTop: 10,
                  marginRight: 5,
                }}
              >
                Prezzo
              </p>
              <p
                style={{
                  ...styles.type,
                  fontWeight: "500",
                  marginTop: 10,
                  fontSize: 15,
                }}
              >
                {card.target_tessere?.prezzo.toFixed(2)}
              </p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-end",
                textAlign: "left",
              }}
            >
              <p
                style={{
                  ...styles.target,
                  fontSize: 10,
                  fontWeight: "500",
                  marginTop: 10,
                  marginRight: 5,
                }}
              >
                Numero intestatari
              </p>
              <p
                style={{
                  ...styles.type,
                  fontWeight: "500",
                  marginTop: 10,
                  fontSize: 15,
                }}
              >
                {card.target_tessere?.max_componenti}
              </p>
            </div>
            {cardGroup.length > 0 && (
              <div
                style={{
                  width: "100%",
                  marginTop: 40,
                  marginBottom: 40,
                  borderBottom: "1px solid darkgray",
                }}
              ></div>
            )}
            {cardGroup.length > 0 && (
              <div
                style={{
                  backgroundColor: "rgb(235, 174, 52, 0.5)",
                  padding: 20,
                }}
              >
                <p style={{ ...styles.description }}>
                  Rinnovando questa tessera verr
                  {cardGroup.length > 2 ? "anno" : "à"} automaticamente rinnovat
                  {cardGroup.length > 2 ? "e" : "a"} anche l
                  {cardGroup.length > 2 ? "e" : "a"} tesser
                  {cardGroup.length > 2 ? "e" : "a"} di
                </p>
                <ul
                  style={{
                    listStyle: "none",
                    padding: 0,
                  }}
                >
                  {cardGroup
                    .filter((ca) => ca.numero !== card.numero)
                    .map((c, key) => {
                      return (
                        <li
                          key={key}
                          style={{
                            ...styles.type,
                            fontWeight: "500",
                            marginTop: 10,
                            fontSize: 13,
                            textAlign: "left",
                          }}
                        >
                          {c.nome} {c.cognome} - {c.numero}
                        </li>
                      );
                    })}
                </ul>
              </div>
            )}
          </div>

          <div
            style={{
              ...styles.column,
              width: "60%",
              paddingBottom: 20,
              marginBottom: 20,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-end",
                textAlign: "left",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  textAlign: "left",
                }}
              >
                <p
                  style={{
                    ...styles.target,
                    fontSize: 10,
                    fontWeight: "500",
                    marginTop: 10,
                    marginRight: 5,
                  }}
                >
                  Numero card
                </p>
                <p
                  style={{
                    ...styles.type,
                    fontWeight: "500",
                    marginTop: 10,
                    fontSize: 15,
                  }}
                >
                  {card.numero}
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  textAlign: "left",
                }}
              >
                <p
                  style={{
                    ...styles.target,
                    fontSize: 10,
                    fontWeight: "500",
                    marginTop: 10,
                    marginRight: 5,
                  }}
                >
                  Titolare
                </p>
                <p
                  style={{
                    ...styles.type,
                    fontWeight: "500",
                    marginTop: 10,
                    fontSize: 15,
                  }}
                >
                  {card.nome + " " + card.cognome}
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "flex-end",
                  textAlign: "left",
                }}
              >
                <p
                  style={{
                    ...styles.target,
                    fontSize: 10,
                    fontWeight: "500",
                    marginTop: 10,
                    marginRight: 5,
                    color: new Date(card.scadenza) < new Date() && "red",
                  }}
                >
                  {new Date(card.scadenza) < new Date()
                    ? "Scaduta il "
                    : "In scadenza il "}
                </p>
                <p
                  style={{
                    ...styles.type,
                    fontWeight: "500",
                    marginTop: 10,
                    fontSize: 15,
                    color: new Date(card.scadenza) < new Date() && "red",
                  }}
                >
                  {moment(new Date(card.scadenza)).format("DD MMM yyyy")}
                </p>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                marginTop: 40,
                marginBottom: 40,
                borderBottom: "1px solid darkgray",
              }}
            ></div>
            <div
              style={{
                width: "100%",
                backgroundColor: colors.verylightgray,
              }}
            >
              <BillingForm
                colors={colors}
                formFunctions={formFunctions}
                renewal={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenewalForm;
