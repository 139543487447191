import React from "react";
import moment from "moment";
import "moment/locale/it";
moment.locale("it");

const Table = ({
  vouchersList,
  chosenVouchersCategory,
  validityFilter,
  colors,
  activePage,
  itemsPerPage,
}) => {
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  // category === 'Tutte' && validity !== 'Ancora validi'
  const completeTitlesList = [
    "",
    "Categoria",
    "Codice",
    "Tipologia",
    "Scadenza",
    "Utilizzo",
    "Operatore",
  ];
  // category !== 'Tutte' && validity !== 'Ancora validi'
  const noCategoryTitlesList = [
    "",
    "Codice",
    "Tipologia",
    "Scadenza",
    "Utilizzo",
    "Operatore",
  ];
  // category === 'Tutte' && validity === 'Ancora validi'
  const stillValidTitlesList = [
    "",
    "Categoria",
    "Codice",
    "Tipologia",
    "Scadenza",
  ];
  // category !== 'Tutte' && validity === 'Ancora validi'
  const noCategoryStillValdTitlesList = ["", "Codice", "Tipologia", "Scadenza"];

  const styles = {
    table: {
      textAlign: "center",
      borderCollapse: "collapse",
      border: "1px solid #ddd",
      width: "100%",
      overflow: "scroll",
    },
    headerCell: {
      border: "1px solid #ddd",
      padding: "8px",
      paddingTop: "12px",
      paddingBottom: "12px",
      textAlign: "center",
      backgroundColor: colors.verylightgray,
      fontSize: 12,
      color: colors.gray,
    },
    field: {
      border: "1px solid #ddd",
      padding: "8px",
      fontSize: 12,
    },
  };

  const camelCase = (str) => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  const renderTableHeader = () => {
    var titlesList = [];
    if (chosenVouchersCategory.id === -1 && validityFilter.id !== 1) {
      titlesList = completeTitlesList;
    } else if (chosenVouchersCategory.id !== -1 && validityFilter.id !== 1) {
      titlesList = noCategoryTitlesList;
    } else if (chosenVouchersCategory.id === -1 && validityFilter.id === 1) {
      titlesList = stillValidTitlesList;
    } else {
      titlesList = noCategoryStillValdTitlesList;
    }

    return titlesList.map((key, index) => {
      return (
        <th style={styles.headerCell} key={index}>
          {key.toUpperCase()}
        </th>
      );
    });
  };

  const renderTableData = () => {
    return vouchersList.map((voucher, key) => {
      return (
        <tr key={key}>
          <td style={styles.field}>
            {key + (activePage - 1) * itemsPerPage + 1}
          </td>
          {chosenVouchersCategory.id === -1 && (
            <td style={styles.field}>
              {voucher.categoria_voucher
                ? camelCase(voucher.categoria_voucher.nome)
                : ""}
            </td>
          )}
          <td style={styles.field}>
            {voucher.codice ? camelCase(voucher.codice) : ""}
          </td>
          <td style={styles.field}>
            {voucher.tipologia ? camelCase(voucher.tipologia) : ""}
          </td>
          <td
            style={{
              ...styles.field,
              color: voucher.scadenza
                ? new Date(voucher.scadenza) < today && "red"
                : "black",
            }}
          >
            {voucher.scadenza
              ? moment(new Date(voucher.scadenza)).format("DD/MM/yyyy")
              : ""}
          </td>
          {validityFilter.id !== 1 && (
            <>
              <td style={styles.field}>
                {voucher.data_utilizzo
                  ? moment(new Date(voucher.data_utilizzo)).format("DD/MM/yyyy")
                  : "-"}
              </td>
              <td style={styles.field}>
                {voucher.operatore ? camelCase(voucher.operatore) : "-"}
              </td>
            </>
          )}
        </tr>
      );
    });
  };
  return (
    <table style={styles.table}>
      <tbody>
        <tr>{renderTableHeader()}</tr>
        {renderTableData()}
      </tbody>
    </table>
  );
};

export default Table;
