import React, { useState } from 'react';
import close from './../../img/closeButton.png'
import './../../style.css'

const AddMuseum = ({ closeModal, checkCode, response, colors }) => {
    const [code, setCode] = useState("");
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
            width: 600,
            height: 400
        },
        header: {
            display: 'flex',
            width: '100%',
            flex: 2,
            backgroundColor: colors.primary,
            alignItems: 'center',
            justifyContent: 'center',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10
        },
        content: {
            width: '100%',
            flex: 6,
            flexDirection: 'column',
            backgroundColor: colors.white,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        field: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            width: '70%',
            marginLeft: 50,
            marginRight: 50,
        },
        footer: {
            display: 'flex',
            width: '100%',
            flex: 2,
            backgroundColor: colors.white,
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        button: {
            backgroundColor: colors.primary,
            color: colors.white,
            padding: 20,
            borderRadius: 20,
            marginRight: 40,
            marginBottom: 40,
            border: 'none',
            outline: 'none',
            cursor: 'pointer'
        },
        label: {
            fontSize: 15,
            color: colors.lightgray,
            margin: 20,
            width: 150
        },
        message: {
            backgroundColor: colors.white,
            padding: 15,
            fontSize: 13,
            color: colors.highlight
        }
    }
    return (
        <>
            <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: colors.gray, opacity: 0.7 }} />
            <div style={styles.container}>
                <div style={styles.header}>
                    <p style={{ color: colors.white, fontSize: 20 }}>AGGIUNGI MUSEO</p>
                    <button onClick={closeModal} style={{ position: 'absolute', right: 30, marginTop: 'auto', marginBottom: 'auto', border: 'none', backgroundColor: 'transparent', outline: 'none', cursor: 'pointer' }}>
                        <img alt="Close button" src={close} style={{ width: 15, objectFit: 'contain' }} /></button>
                </div>
                {response === null &&
                    <div style={styles.content}>
                        <p style={{
                            fontSize: 15,
                            marginBottom: 40,
                            width: '70%',
                            color: colors.lightgray, 
                        }}>Per aggiungere un museo alla tua rete, inserisci qui il suo codice</p>
                        <div style={styles.field}>
                            <p style={styles.label}>Codice</p>
                            <div style={{ width: 300, display: 'flex' }}>
                                <input
                                    value={code}
                                    onChange={(event) => setCode(event.target.value)}
                                    placeholder="Codice"
                                    style={{
                                        border: '1px solid lightgray',
                                        borderRadius: 10,
                                        width: '60%',
                                        padding: 18,
                                        outline: 'none'
                                    }}
                                />
                            </div>
                        </div>
                    </div>}
                {response?.status === true &&
                    <div style={styles.content}>
                        <p style={{
                            fontSize: 15,
                            marginBottom: 40,
                            width: '70%'
                        }}>Il museo è stato aggiunto alla rete.</p>
                    </div>}
                    {response?.status === false &&
                    <div style={styles.content}>
                        <p style={{
                            fontSize: 15,
                            marginBottom: 40,
                            width: '70%'
                        }}>Errore. {response.message !== "" && response.message}</p>
                    </div>}
                <div style={styles.footer}>
                    <button onClick={() => {
                        if(response === null){
                            checkCode(code)
                        }else{
                            setCode("")
                            closeModal()
                        }  
                    }} disabled={code === ""} style={{ ...styles.button, opacity: code === "" && 0.7 }}>{response !== null ? "OK" : "AGGIUNGI"}</button>
                </div>
            </div>
        </>
    )

}



export default AddMuseum;

