import React from "react";
import moment from "moment";
import "moment/locale/it";
import star from "./../../../img/is_admin.png";

moment.locale("it");

const titlesAllCards = [
  "",
  "Card",
  "N° card",
  "VIP",
  "Nome",
  "Cognome",
  "Data di nascita",
  "Email",
  "Scadenza",
];

const titles = [
  "",
  "N° card",
  "VIP",
  "Nome",
  "Cognome",
  "Data di nascita",
  "Email",
  "Scadenza",
];
const Table = ({ allCards, cards, colors, setOpenRecord, activePage, itemsPerPage }) => {
  const styles = {
    table: {
      textAlign: "center",
      borderCollapse: "collapse",
      border: "1px solid #ddd",
      width: "100%",
      overflow: "scroll",
    },
    headerCell: {
      border: "1px solid #ddd",
      padding: "8px",
      paddingTop: "12px",
      paddingBottom: "12px",
      textAlign: "center",
      backgroundColor: colors.verylightgray,
      fontSize: 12,
      color: colors.gray,
    },
    field: {
      border: "1px solid #ddd",
      padding: "8px",
      fontSize: 12,
    },
    row: {
      cursor: "pointer",
    },
  };

  const camelCase = (str) => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  const renderTableHeader = () => {
    return (allCards ? titlesAllCards : titles).map((key, index) => {
      return (
        <th style={styles.headerCell} key={index}>
          {key.toUpperCase()}
        </th>
      );
    });
  };

  const renderTableData = () => {
    return cards.map((card, key) => {
      const {
        numero,
        nome,
        cognome,
        email,
        nascita,
        scadenza,
        vip,
        target_tessere,
      } = card;
      return (
        <tr key={key} style={styles.row} onClick={() => setOpenRecord(card)}>
          <td style={styles.field}>{key + ((activePage-1) * itemsPerPage) + 1}</td>
          {allCards && (
            <td style={styles.field}>{camelCase(target_tessere.nome)}</td>
          )}
          <td style={styles.field}>{numero}</td>
          <td style={styles.field}>
            {vip ? (
              <img
                alt="Star icon"
                src={star}
                style={{ width: 15, objectFit: "contain" }}
              />
            ) : (
              ""
            )}
          </td>
          <td style={styles.field}>{camelCase(nome)}</td>
          <td style={styles.field}>{camelCase(cognome)}</td>
          <td style={styles.field}>
            {moment(new Date(nascita)).format("DD/MM/yyyy")}
          </td>
          <td style={styles.field}>{email}</td>
          <td
            style={{
              ...styles.field,
              color: new Date(scadenza) < new Date() && "red",
            }}
          >
            {moment(new Date(scadenza)).format("DD/MM/yyyy")}
          </td>
        </tr>
      );
    });
  };
  return (
    <table style={styles.table}>
      <tbody>
        <tr>{renderTableHeader()}</tr>
        {renderTableData()}
      </tbody>
    </table>
  );
};

export default Table;
