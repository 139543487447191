import React, { useContext } from "react";
import SubButton from "../../components/buttons/subButton";
import user from "./../../img/user.png";
import groups from "./../../img/users.png";
import { UserContext } from "../../contexts";
import { LanguageContext } from "../../containers/language";

const CustomersBar = (props) => {
  const colors = useContext(UserContext)?.colors;

  const { dictionary } = useContext(LanguageContext);

  const styles = {
    sideBar: {
      width: 200,
      backgroundColor: colors?.darkgray,
      height: "100%",
      boxShadow: "2px 0 4px 0 rgb(0 0 0 / 20%)",
    },
  };
  return (
    <div style={styles.sideBar}>
      {props.permissions?.visitatori && (
        <SubButton
          icon={user}
          mod={{ id: 1, name: dictionary.singleVisitors }}
          onClick={() => props.setSelection(1)}
          selection={props.selection}
          size={16}
          selectionColor={colors?.gray}
          hoverColor={colors?.mediumgray}
        />
      )}
      {props.permissions?.gruppi && (
        <SubButton
          icon={groups}
          mod={{ id: 2, name: dictionary.groups }}
          onClick={() => props.setSelection(2)}
          selection={props.selection}
          size={18}
          selectionColor={colors?.gray}
          hoverColor={colors?.mediumgray}
        />
      )}
    </div>
  );
};

export default CustomersBar;
