import React, { useState, useContext, useEffect, useRef } from "react";
import { SketchPicker } from "react-color";
import { settingsService } from "../_services";
import pencil from "./../img/modify_gray.png";
import { UserContext } from "../contexts";
import ReactLoading from "react-loading";

function onSubmitLogoLight(tempLogoLight, id, setLogoLight) {
  const formData = new FormData();
  formData.append("files", tempLogoLight);
  formData.append("refId", id);
  formData.append("ref", "musei");
  formData.append("field", "logo_light");
  if (tempLogoLight) {
    settingsService
      .upload(formData)
      .then((logoLight) => setLogoLight(logoLight[0]))
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  }
}

function onSubmitLogoDark(tempLogoDark, id, setLogoDark) {
  const formData = new FormData();
  formData.append("files", tempLogoDark);
  formData.append("refId", id);
  formData.append("ref", "musei");
  formData.append("field", "logo_dark");
  if (tempLogoDark) {
    settingsService
      .upload(formData)
      .then((logoDark) => setLogoDark(logoDark[0]))
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  }
}

function onSubmitCover(tempCover, id, setCover) {
  const formData = new FormData();
  formData.append("files", tempCover);
  formData.append("refId", id);
  formData.append("ref", "musei");
  formData.append("field", "cover");
  if (tempCover) {
    settingsService
      .upload(formData)
      .then((cover) => setCover(cover[0]))
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  }
}

const MuseumInfo = ({ entity }) => {
  const colors = useContext(UserContext)?.colors;
  const user = useContext(UserContext)?.user;

  const [name, setName] = useState(entity?.nome);
  const [address, setAddress] = useState(entity?.indirizzo_museo);
  const [zip, setZip] = useState(entity?.CAP_museo);
  const [city, setCity] = useState(entity?.citta_museo);
  const [email, setEmail] = useState(entity?.email_museo);
  const [tel, setTel] = useState(entity?.numero_telefono_museo);
  const [descrizione_museo, setDescrizioneMuseo] = useState(
    entity?.descrizione_museo
  );
  const [visitDuration, setVisitDuration] = useState(entity?.durata_visita_min);
  const [visitFrequency, setVisitFrequency] = useState(
    entity?.frequenza_slot_min
  );
  const [capacity, setCapacity] = useState(entity?.capienza_massima);
  const [modify, setModify] = useState(false);
  const [color, setColor] = useState(entity?.colore);
  const [tempLogoLight, setTempLogoLight] = useState(null);
  const [logoLight, setLogoLight] = useState(entity?.logo_light);
  const [tempLogoDark, setTempLogoDark] = useState(null);
  const [logoDark, setLogoDark] = useState(entity?.logo_dark);
  const [tempCover, setTempCover] = useState(null);
  const [cover, setCover] = useState(entity?.cover);

  const [info, setInfo] = useState(entity);

  const [lightLogoError, setLightLogoError] = useState(false);
  const [darkLogoError, setDarkLogoError] = useState(false);
  const [coverError, setCoverError] = useState(false);

  const [loader, setLoader] = useState(false);

  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          observer.disconnect();
          resolve(document.querySelector(selector));
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  const divRef = useRef();
  useEffect(() => {
    waitForElm(".thisRef").then(() => {
      if (
        !!descrizione_museo &&
        divRef.current !== undefined &&
        divRef.current !== null
      ) {
        divRef.current.innerHTML = descrizione_museo;
      }
    });
  }, [descrizione_museo, loader]);

  function updateInfoMuseum(
    name,
    address,
    zip,
    city,
    email,
    tel,
    descrizione_museo,
    visitDuration,
    visitFrequency,
    capacity,
    color,
    setInfo
  ) {
    setLoader(true);
    settingsService
      .updateInfoMuseum(
        name,
        address,
        zip,
        city,
        email,
        tel,
        descrizione_museo,
        visitDuration,
        visitFrequency,
        capacity,
        color
      )
      .then((response) => {
        setInfo(response);
        setLoader(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  }

  function onImageChangeLight(event, setTempLogoLight) {
    const tmpFileUploaded = event.target.files[0];
    if (tmpFileUploaded) {
      if (tmpFileUploaded.size < 1000000) {
        setTempLogoLight(tmpFileUploaded);
        setLightLogoError(false);
      } else {
        console.log(
          "Dimensione dell'immagine scelta: " +
            (tmpFileUploaded.size / 1000000).toFixed(2) +
            "... MB (superiore a 1 MB)"
        );
        setLightLogoError(true);
      }
    } else {
      setLightLogoError(false);
    }
  }

  function onImageChangeDark(event, setTempLogoDark) {
    const tmpFileUploaded = event.target.files[0];
    if (tmpFileUploaded) {
      if (tmpFileUploaded.size < 1000000) {
        setTempLogoDark(tmpFileUploaded);
        setDarkLogoError(false);
      } else {
        console.log(
          "Dimensione dell'immagine scelta: " +
            (tmpFileUploaded.size / 1000000).toFixed(2) +
            "... MB (superiore a 1 MB)"
        );
        setDarkLogoError(true);
      }
    } else {
      setDarkLogoError(false);
    }
  }

  function onCoverChange(event, setTempCover) {
    const tmpFileUploaded = event.target.files[0];
    if (tmpFileUploaded) {
      if (tmpFileUploaded.size < 1000000) {
        setTempCover(tmpFileUploaded);
        setCoverError(false);
      } else {
        console.log(
          "Dimensione dell'immagine scelta: " +
            (tmpFileUploaded.size / 1000000).toFixed(2) +
            "... MB (superiore a 1 MB)"
        );
        setCoverError(true);
      }
    } else {
      setCoverError(false);
    }
  }

  React.useEffect(() => {
    setName(info?.nome);
    setAddress(info?.indirizzo_museo);
    setZip(info?.CAP_museo);
    setCity(info?.citta_museo);
    setEmail(info?.email_museo);
    setTel(info?.numero_telefono_museo);
    setDescrizioneMuseo(info?.descrizione_museo);
    setVisitDuration(info?.durata_visita_min);
    setVisitFrequency(info?.frequenza_slot_min);
    setCapacity(info?.capienza_massima);
    setColor(info?.colore);
    setLogoLight(info?.logo_light);
    setLogoDark(info?.logo_dark);
    setCover(info?.cover);
    setModify(false);
  }, [info]);

  React.useEffect(() => {
    setInfo(entity);
  }, [entity]);

  const styles = {
    infoSection: {
      marginBottom: 30,
      marginRight: 5,
      width: "40%",
    },
    labelTable: {
      marginBottom: 15,
      fontSize: 14,
      color: colors?.lightgray,
      textAlign: "left",
      justifyContent: "center",
      display: "flex",
      flexDirection: modify ? "column" : "row",
      alignItems: "center",
    },
    field: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    label: {
      fontSize: 11,
      textAlign: "left",
      width: "30%",
      color: colors?.darkgray,
      marginRight: 5,
      marginTop: 10,
      marginBottom: 10,
    },
    errorLabel: {
      fontSize: 10,
      textAlign: "left",
      width: "95%",
      color: colors?.red,
      marginBottom: 5,
      marginRight: 5,
    },
    inputBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flex: 3,
    },
    textInput: {
      outline: "none",
      border: "1px solid lightgray",
      width: 300,
      height: 20,
      fontFamily: "inherit",
      borderRadius: 10,
      marginRight: 10,
      marginLeft: 10,
      padding: 5,
      textAlign: "left",
    },
    smallTextInput: {
      outline: "none",
      border: "1px solid lightgray",
      width: 30,
      height: 20,
      borderRadius: 10,
      marginRight: 10,
      marginLeft: 10,
      padding: 5,
      textAlign: "center",
    },
    header: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
    text: {
      fontSize: 14,
      textAlign: "left",
      flex: 3,
      marginRight: 10,
    },
    noImageText: {
      fontSize: 12,
      textAlign: "left",
      flex: 3,
      marginRight: 10,
      fontStyle: "italic",
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: colors?.lightgray,
      cursor: "pointer",
      borderRadius: 15,
      padding: "11px",
      marginTop: "20px",
    },
  };
  return (
    <div style={styles.infoSection}>
      <div style={styles.header}>
        <div style={styles.labelTable}>
          Info museo
          {(user?.role.id === 1 || user?.role.id === 67) &&
            !loader &&
            !modify && (
              <img
                alt="Modify"
                src={pencil}
                style={{
                  width: 15,
                  objectFit: "contain",
                  marginLeft: 15,
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  setModify(!modify);
                }}
              />
            )}
          {(user?.role.id === 1 || user?.role.id === 67) && modify && (
            <button
              style={styles.button}
              onClick={() => {
                var tempDescrizioneMuseo = descrizione_museo.replace(
                  /\r\n|\r|\n/g,
                  "<br/>"
                );
                setDescrizioneMuseo(tempDescrizioneMuseo);
                onSubmitLogoLight(tempLogoLight, entity?.id, setLogoLight);
                onSubmitLogoDark(tempLogoDark, entity?.id, setLogoDark);
                onSubmitCover(tempCover, entity?.id, setCover);
                updateInfoMuseum(
                  name,
                  address,
                  zip,
                  city,
                  email,
                  tel,
                  tempDescrizioneMuseo,
                  visitDuration,
                  visitFrequency,
                  capacity,
                  color,
                  setInfo
                );
                setModify(!modify);
              }}
            >
              Salva
            </button>
          )}
        </div>
      </div>
      {loader ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={colors.primary}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <>
          <div style={styles.field}>
            <p style={styles.label}>Nome </p>
            {modify ? (
              <div style={styles.inputBox}>
                <input
                  type="text"
                  style={styles.textInput}
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  placeholder="Nome museo"
                />
              </div>
            ) : (
              <p style={styles.text}>{name}</p>
            )}
          </div>
          <div style={styles.field}>
            <p style={styles.label}>Indirizzo </p>
            {modify ? (
              <div style={styles.inputBox}>
                <input
                  type="text"
                  style={styles.textInput}
                  value={address}
                  onChange={(event) => setAddress(event.target.value)}
                  placeholder="Indirizzo museo"
                />
              </div>
            ) : (
              <p style={styles.text}>{address}</p>
            )}
          </div>
          <div style={styles.field}>
            <p style={styles.label}>CAP </p>
            {modify ? (
              <div style={styles.inputBox}>
                <input
                  type="text"
                  style={styles.textInput}
                  value={zip}
                  onChange={(event) => setZip(event.target.value)}
                  placeholder="CAP museo"
                />
              </div>
            ) : (
              <p style={styles.text}>{zip}</p>
            )}
          </div>
          <div style={styles.field}>
            <p style={styles.label}>Città </p>
            {modify ? (
              <div style={styles.inputBox}>
                <input
                  type="text"
                  style={styles.textInput}
                  value={city}
                  onChange={(event) => setCity(event.target.value)}
                  placeholder="Città museo"
                />
              </div>
            ) : (
              <p style={styles.text}>{city}</p>
            )}
          </div>
          <div style={styles.field}>
            <p style={styles.label}>Email </p>
            {modify ? (
              <div style={styles.inputBox}>
                <input
                  type="text"
                  style={styles.textInput}
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="Email museo"
                />
              </div>
            ) : (
              <p style={styles.text}>{email}</p>
            )}
          </div>
          <div style={styles.field}>
            <p style={styles.label}>Telefono </p>
            {modify ? (
              <div style={styles.inputBox}>
                <input
                  type="text"
                  style={styles.textInput}
                  value={tel}
                  onChange={(event) => setTel(event.target.value)}
                  placeholder="Telefono museo"
                />
              </div>
            ) : (
              <p style={styles.text}>{tel}</p>
            )}
          </div>
          <div style={styles.field}>
            <p style={styles.label}>Descrizione </p>
            {modify ? (
              <div style={styles.inputBox}>
                <textarea
                  value={descrizione_museo.replace(/<br*\/?>/gi, "\n")}
                  onChange={(event) => {
                    setDescrizioneMuseo(event.target.value);
                  }}
                  style={{ ...styles.textInput, resize: "none", height: 100 }}
                  rows={Math.ceil(descrizione_museo.length / 100)}
                />
              </div>
            ) : (
              <p style={styles.text}>
                <div className="thisRef" ref={divRef}>
                  {
                    //Custom content will be programmatically inserted in div below
                  }
                  <div></div>
                </div>
              </p>
            )}
          </div>
          <div style={styles.field}>
            <p style={styles.label}>Durata della visita </p>
            {modify ? (
              <div style={styles.inputBox}>
                <input
                  type="text"
                  style={styles.smallTextInput}
                  value={visitDuration}
                  onChange={(event) =>
                    setVisitDuration(
                      event.target.value === ""
                        ? 0
                        : parseInt(event.target.value)
                    )
                  }
                  placeholder="60"
                />
                <p style={{ fontSize: 15, textAlign: "left" }}> minuti</p>
              </div>
            ) : (
              <p style={styles.text}>{visitDuration}</p>
            )}
          </div>
          <div style={styles.field}>
            <p style={styles.label}>Frequenza ingressi </p>
            <p
              style={{
                fontSize: 11,
                textAlign: "left",
                marginRight: 5,
              }}
            >
              Permetti ingressi ogni{" "}
            </p>
            {modify ? (
              <div style={styles.inputBox}>
                <input
                  type="text"
                  style={styles.smallTextInput}
                  value={visitFrequency}
                  onChange={(event) =>
                    setVisitFrequency(
                      event.target.value === ""
                        ? 0
                        : parseInt(event.target.value)
                    )
                  }
                  placeholder="15"
                />
                <p style={{ fontSize: 15, textAlign: "left" }}> minuti</p>
              </div>
            ) : (
              <p style={styles.text}>{visitFrequency} minuti</p>
            )}
          </div>
          <div style={styles.field}>
            <p style={styles.label}>Capienza massima </p>
            {modify ? (
              <div style={styles.inputBox}>
                <input
                  type="text"
                  style={styles.smallTextInput}
                  value={capacity}
                  onChange={(event) =>
                    setCapacity(
                      event.target.value === ""
                        ? 0
                        : parseInt(event.target.value)
                    )
                  }
                  placeholder="100"
                />
                <p style={{ fontSize: 15, textAlign: "left" }}> persone</p>
              </div>
            ) : (
              <p style={styles.text}>{capacity}</p>
            )}
          </div>
          <div style={styles.field}>
            <p style={styles.label}>Colore museo</p>
            {modify ? (
              <div style={{ ...styles.inputBox, marginBottom: 20 }}>
                <SketchPicker
                  color={color}
                  onChangeComplete={(e) => setColor(e.hex)}
                />
              </div>
            ) : (
              <div
                style={{ backgroundColor: color, height: 20, width: 20 }}
              ></div>
            )}
          </div>
          {modify && lightLogoError && (
            <p style={styles.errorLabel}>
              {
                "L'immagine non può eccedere la dimensione di 1MB (non verrà salvata)"
              }
            </p>
          )}
          <div style={{ ...styles.field, marginBottom: 5 }}>
            <p style={styles.label}>Logo chiaro museo</p>
            {modify ? (
              <div style={styles.inputBox}>
                <input
                  type="file"
                  name="logoLight"
                  onChange={(e) => onImageChangeLight(e, setTempLogoLight)}
                  alt="image"
                />
                <br />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "left",
                }}
              >
                {logoLight?.url ? (
                  <img
                    alt="Logo light"
                    src={logoLight.url}
                    style={{ width: 80, objectFit: "contain" }}
                  />
                ) : (
                  <p style={styles.noImageText}>Nessuna immagine</p>
                )}
              </div>
            )}
          </div>
          {modify && darkLogoError && (
            <p style={styles.errorLabel}>
              {
                "L'immagine non può eccedere la dimensione di 1MB (non verrà salvata)"
              }
            </p>
          )}
          <div style={{ ...styles.field, marginBottom: 5 }}>
            <p style={styles.label}>Logo scuro museo</p>
            {modify ? (
              <div style={styles.inputBox}>
                <input
                  type="file"
                  name="logoDark"
                  onChange={(e) => onImageChangeDark(e, setTempLogoDark)}
                  alt="image"
                />
                <br />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "left",
                }}
              >
                {logoDark?.url ? (
                  <img
                    alt="Logo dark"
                    src={logoDark.url}
                    style={{ width: 80, objectFit: "contain" }}
                  />
                ) : (
                  <p style={styles.noImageText}>Nessuna immagine</p>
                )}
              </div>
            )}
          </div>
          {coverError && (
            <p style={styles.errorLabel}>
              {
                "L'immagine non può eccedere la dimensione di 1MB (non verrà salvata)"
              }
            </p>
          )}
          <div style={{ ...styles.field, marginBottom: 5 }}>
            <p style={styles.label}>Cover museo</p>
            {modify ? (
              <div style={styles.inputBox}>
                <input
                  type="file"
                  name="cover"
                  onChange={(e) => onCoverChange(e, setTempCover)}
                  alt="image"
                />
                <br />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "left",
                }}
              >
                {cover?.url ? (
                  <img
                    alt="Cover"
                    src={cover.url}
                    style={{ width: 80, objectFit: "contain" }}
                  />
                ) : (
                  <p style={styles.noImageText}>Nessuna immagine</p>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MuseumInfo;
