import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts";
import ReactLoading from "react-loading";
import { OrgContext } from "../../../contexts";
import { reportService } from "../../../_services";
import ObjectsInteractionsChart from "./objectsInteractionsChart";
import ClickedObjectsChart from "./clickedObjectsChart";
import InteractionsTimeChart from "./interactionsTimeChart";
import SectionsInteractionsChart from "./sectionsInteractionsChart";
import ObjectsInteractionsPercentage from "./objectsInteractionsPercentage";
import DifferentInteractionsCountChart from "./differentInteractionsCountChart";
import DifferentInteractionsPercentage from "./differentInteractionsPercentage";
import ObjectsInteractionsTimeChart from "./objectsInteractionsTimeChart";

const UsersBehaviorVisualization = (props) => {
  const colors = useContext(UserContext)?.colors;
  const org = useContext(OrgContext)?.org;
  const [sections, setSections] = useState([]);
  const [clickSections, setClickSections] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setLoader(true);
    //Get list of all sections
    reportService
      .getSections(org, props.filters.start, props.filters.end)
      .then((data) => {
        setSections(data);

        //Get list of sections that contain at least one clicked object
        reportService
          .getSections(org, props.filters.start, props.filters.end, "click")
          .then((data) => {
            setClickSections(data);
            setLoader(false);
          })
          .catch((errors) => {
            if (errors.statusCode === 401 || errors.statusCode === 403) {
              this.props.history.push("/");
            }
          });
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  }, [props.filters, org]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      right: 0,
      alignItems: "center",
      overflow: "scroll",
    },
    row: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  };

  return loader ? (
    <ReactLoading
      type={"spinningBubbles"}
      color={colors?.primary}
      height={50}
      width={50}
    />
  ) : (
    <div style={styles.container}>
      {sections.sections.length > 0 && (
        <div style={styles.row}>
          <ObjectsInteractionsChart
            temporalFilters={props.filters}
            sections={sections.sections}
          />
        </div>
      )}
      {sections.sections.length > 0 && (
        <div style={styles.row}>
          <ObjectsInteractionsPercentage
            temporalFilters={props.filters}
            sections={sections.sections}
          />
        </div>
      )}
      <div style={styles.row}>
        <SectionsInteractionsChart temporalFilters={props.filters} />
      </div>
      {sections.sections.length > 0 && (
        <div style={styles.row}>
          <DifferentInteractionsCountChart
            temporalFilters={props.filters}
            sections={sections.sections}
          />
        </div>
      )}
      {sections.sections.length > 0 && (
        <div style={styles.row}>
          <DifferentInteractionsPercentage
            temporalFilters={props.filters}
            sections={sections.sections}
          />
        </div>
      )}
      {sections.sections.length > 0 && (
        <div style={styles.row}>
          <ClickedObjectsChart
            temporalFilters={props.filters}
            sections={clickSections.sections}
          />
        </div>
      )}
      {sections.sections.length > 0 && (
        <div style={styles.row}>
          <ObjectsInteractionsTimeChart
            temporalFilters={props.filters}
            sections={sections.sections}
          />
        </div>
      )}
      <div style={styles.row}>
        <InteractionsTimeChart temporalFilters={props.filters} />
      </div>
    </div>
  );
};

export default UsersBehaviorVisualization;
