import React, { useState, useEffect, useContext } from "react";
import { itemsService, settingsService } from "../_services";
import ReactLoading from "react-loading";
import Discount from "./discount";
import AddDiscount from "./popup/addDiscount";
import { UserContext } from "../contexts";

function getDiscountsList(setDiscountsList, setLoader) {
  settingsService
    .getDiscounts()
    .then((list) => {
      setDiscountsList(list);
      setLoader(false);
    })
    .catch((errors) => {
      if (errors.statusCode === 401 || errors.statusCode === 403) {
        this.props.history.push("/");
      }
    });
}

function createDiscount(
  code,
  discount,
  categories,
  setDiscountsList,
  setLoader,
  setResponse
) {
  setLoader(true);
  settingsService
    .createDiscount(code, discount, categories)
    .then((response) => {
      if (response.status) {
        setDiscountsList(response.items);
        setResponse(response.items);
      } else {
        setResponse(response.message);
      }
      setLoader(false);
    })
    .catch((errors) => {
      if (errors.statusCode === 401 || errors.statusCode === 403) {
        this.props.history.push("/");
      }
    });
}

function deleteDiscount(id, setDiscountsList, setLoader) {
  setLoader(true);
  settingsService
    .deleteDiscount(id)
    .then((response) => {
      setDiscountsList(response);
      setLoader(false);
    })
    .catch((errors) => {
      if (errors.statusCode === 401 || errors.statusCode === 403) {
        this.props.history.push("/");
      }
    });
}

const DiscountsManager = () => {
  const colors = useContext(UserContext)?.colors;
  const user = useContext(UserContext)?.user;

  const [discountsList, setDiscountsList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [categoriesList, setCategoriesList] = useState([]);
  const [addDiscount, showAddDiscount] = useState(false);
  const [response, setResponse] = useState("");

  //Get discounts list
  useEffect(() => {
    setLoader(true);
    getDiscountsList(setDiscountsList, setLoader);
  }, [user.flag_network]);

  //Get categories list
  useEffect(() => {
    itemsService
      .getCategories()
      .then((response) => {
        setCategoriesList(response);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  }, []);

  const styles = {
    usersSection: {
      width: "100%",
    },
    scrollerY: {
      overflowY: "scroll",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    headerTable: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      marginTop: 10,
    },
    labelTable: {
      fontSize: 12,
      color: colors?.lightgray,
      textAlign: "left",
      flex: 1,
    },
    field: {
      flex: 1,
      fontSize: 15,
      textAlign: "left",
    },
    menu: {
      border: "1px solid lightgray",
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 10,
      color: colors?.darkgray,
      borderRadius: 20,
      backgroundColor: "transparent",
      outline: "none",
    },
    button: {
      border: "none",
      backgroundColor: "transparent",
      outline: "none",
      cursor: "pointer",
    },
    newUser: {
      display: "flex",
      width: "100%",
      alignItems: "flex-start",
      color: colors?.gray,
      marginTop: 10,
      marginBottom: 10,
      border: "none",
      backgroundColor: "transparent",
      outline: "none",
      cursor: "pointer",
      padding: 0,
    },
    inputBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: 250,
    },
    textInput: {
      outline: "none",
      border: "1px solid lightgray",
      width: 150,
      height: 20,
      borderRadius: 10,
      padding: 5,
      textAlign: "center",
    },
  };

  return (
    <div style={styles.usersSection}>
      <div style={styles.scrollerY}>
        <div style={styles.headerTable}>
          <p style={styles.labelTable}>{"Codice sconto"}</p>
          <p style={styles.labelTable}>{"Categorie"}</p>
          <p style={styles.labelTable}>{"Sconto"}</p>
          <p style={styles.labelTable}>{"Abilitato"}</p>
          <p style={styles.labelTable}>{"Elimina"}</p>
        </div>
        {loader && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 250,
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={colors?.primary}
              height={50}
              width={50}
            />
          </div>
        )}
        {discountsList.map((discount, key) => {
          return (
            <div key={key}>
              <Discount
                discount={discount}
                categoriesList={categoriesList}
                colors={colors}
                deleteDiscount={(id) =>
                  deleteDiscount(id, setDiscountsList, setLoader)
                }
              />
            </div>
          );
        })}
      </div>
      <button style={styles.newUser} onClick={() => showAddDiscount(true)}>
        Crea codice sconto
      </button>
      {addDiscount && (
        <AddDiscount
          colors={colors}
          response={response}
          categoriesList={categoriesList}
          closeModal={() => {
            showAddDiscount(false);
            setResponse("");
          }}
          addDiscount={(code, discount, categories) =>
            createDiscount(
              code,
              discount,
              categories,
              setDiscountsList,
              setLoader,
              setResponse
            )
          }
        />
      )}
    </div>
  );
};

export default DiscountsManager;
