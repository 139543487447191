import React, { useState } from "react";
import close from "./../../img/closeButton.png";
import "./../../style.css";

const AddDiscount = ({
  closeModal,
  addDiscount,
  response,
  colors,
  categoriesList,
}) => {
  const [code, setCode] = useState("");
  const [discount, setDiscount] = useState("");
  const [categories, setCategories] = useState([]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      width: 600,
      height: 500,
    },
    header: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors.primary,
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    content: {
      width: "100%",
      flex: 9,
      flexDirection: "column",
      backgroundColor: colors.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    field: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "70%",
      marginLeft: 50,
      marginRight: 50,
      marginBottom: 10,
    },
    footer: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    label: {
      fontSize: 15,
      color: colors.lightgray,
      margin: 20,
      width: 150,
    },
    message: {
      backgroundColor: colors.white,
      padding: 15,
      fontSize: 13,
      color: colors.highlight,
    },
  };
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: colors.gray,
          opacity: 0.7,
        }}
      />
      <div style={styles.container}>
        <div style={styles.header}>
          <p style={{ color: colors.white, fontSize: 20 }}>
            CREA CODICE SCONTO
          </p>
          <button
            onClick={closeModal}
            style={{
              position: "absolute",
              right: 30,
              marginTop: "auto",
              marginBottom: "auto",
              border: "none",
              backgroundColor: "transparent",
              outline: "none",
              cursor: "pointer",
            }}
          >
            <img
              alt="Close button"
              src={close}
              style={{ width: 15, objectFit: "contain" }}
            />
          </button>
        </div>
        {response === "" ? (
          <div style={styles.content}>
            <div style={styles.field}>
              <p style={styles.label}>Codice</p>
              <div style={{ width: 300, display: "flex" }}>
                <input
                  value={code}
                  onChange={(event) =>
                    setCode(event.target.value.toUpperCase())
                  }
                  placeholder="Codice"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: 10,
                    width: "60%",
                    padding: 18,
                    outline: "none",
                  }}
                />
              </div>
            </div>
            <div style={styles.field}>
              <p style={styles.label}>Sconto</p>
              <div
                style={{ width: 300, display: "flex", alignItems: "center" }}
              >
                <input
                  value={discount}
                  onChange={(event) =>
                    setDiscount(event.target.value.replace(/[^0-9.]/, ""))
                  }
                  placeholder="Sconto"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: 10,
                    width: "40%",
                    padding: 18,
                    outline: "none",
                    marginRight: 20,
                  }}
                />
                %
              </div>
            </div>
            <div style={styles.field}>
              <p style={styles.label}>Categorie scontate</p>
              <div
                style={{
                  width: 300,
                  maxHeight: 170,
                  display: "flex",
                  alignItems: "center",
                  overflow: "scroll",
                  flexDirection: "column",
                }}
              >
                {categoriesList.map((cat) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={() => {
                          if (
                            !categories.some(
                              (category) => category === parseInt(cat.id)
                            )
                          ) {
                            setCategories((categories) => [
                              ...categories,
                              parseInt(cat.id),
                            ]);
                          } else {
                            setCategories(
                              categories.filter((id) => id !== parseInt(cat.id))
                            );
                          }
                        }}
                        value={cat.id}
                        checked={categories.some(
                          (category) => category === cat.id
                        )}
                      />
                      <p
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                          marginLeft: 10,
                        }}
                      >
                        {cat.nome_categoria}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : Array.isArray(response) ? (
          <div style={styles.content}>
            <p
              style={{
                fontSize: 15,
                marginBottom: 40,
                width: "70%",
              }}
            >
              Il codice sconto è stato creato correttamente ed è attivo.
            </p>
          </div>
        ) : (
          <div style={styles.content}>
            <p
              style={{
                fontSize: 15,
                marginBottom: 40,
                width: "70%",
              }}
            >
              Errore. {response}
            </p>
          </div>
        )}
        <div style={styles.footer}>
          <button
            onClick={() => {
              if (response !== "") {
                closeModal();
                setCode("");
                setDiscount("");
                setCategories([]);
              } else {
                addDiscount(code, discount, categories);
              }
            }}
            disabled={code === "" || discount === "" || categories.length === 0}
            style={{
              ...styles.button,
              opacity:
                code === "" || discount === "" || categories.length === 0
                  ? 0.3
                  : 1,
            }}
          >
            {response !== "" ? "OK" : "CREA"}
          </button>
        </div>
      </div>
    </>
  );
};

export default AddDiscount;
