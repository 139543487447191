import React, { useContext, useEffect, useState } from "react";
import Field from "../../components/field";
import { OrgContext, UserContext } from "../../contexts";

const CustomProfilingForm = (props) => {
  const org = useContext(OrgContext)?.org;
  const colors = useContext(UserContext)?.colors;
  const [filteredCustomProfiling, setFilteredCustomProfiling] = useState(
    props.customProfiling?.filter((field) => field.type !== -1)
  );

  const styles = {
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
    column: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: 10,
    },
  };

  const addHiddenFields = (list) => {
    for (let newField of list) {
      let existingField = props.customProfiling.find(
        (field) => field.name === newField.name
      );
      if (!existingField) {
        props.customProfiling.push(newField);
      } else {
        existingField.value = newField?.value;
      }
    }
  };

  useEffect(() => {
    if (!props.customProfiling) {
      if (props.isTicket) {
        for (let f of org.configurazioni.biglietteria) {
          delete f.value;
        }
        let temp = org.configurazioni.biglietteria;
        props.update(JSON.parse(JSON.stringify(temp)));
        setFilteredCustomProfiling(temp?.filter((field) => field.type !== -1));
      } else {
        for (let f of org.configurazioni.bookshop) {
          delete f.value;
        }
        let temp = org.configurazioni.bookshop;
        props.update(JSON.parse(JSON.stringify(temp)));
        setFilteredCustomProfiling(temp?.filter((field) => field.type !== -1));
      }
    }
  }, [org, props]);

  return (
    <div style={styles.container}>
      <div style={styles.column}>
        {props.customProfiling
          ?.slice(0, Math.ceil(filteredCustomProfiling?.length / 2))
          .map((field, key) => {
            if (field.type !== -1) {
              return (
                <Field
                  key={key}
                  field={field}
                  colors={colors}
                  addField={(newField) => {
                    field.value = newField;
                    props.update(props.customProfiling);
                  }}
                  addHiddenFields={(hiddenFields) =>
                    addHiddenFields(hiddenFields)
                  }
                  listLenght={props.tickets}
                />
              );
            } else {
              return null;
            }
          })}
      </div>
      <div style={styles.column}>
        {props.customProfiling
          ?.slice(
            Math.ceil(filteredCustomProfiling?.length / 2),
            filteredCustomProfiling?.lenght
          )
          .map((field, key) => {
            if (field.type !== -1) {
              return (
                <Field
                  key={key}
                  field={field}
                  colors={colors}
                  addField={(newField) => {
                    field.value = newField;
                    props.update(props.customProfiling);
                  }}
                  addHiddenFields={(hiddenFields) =>
                    addHiddenFields(hiddenFields)
                  }
                  listLenght={props.tickets}
                />
              );
            } else {
              return null;
            }
          })}
      </div>
    </div>
  );
};

export default CustomProfilingForm;
