import React, { useState } from "react";
import close from "./../../../img/closeButton.png";
import { itemsService, settingsService } from "../../../_services";
import ReactLoading from "react-loading";
import module from "../../../assets/module.xlsx";

const AddFromFile = ({ closeModal, settingsId, colors }) => {
  const [file, setFile] = useState();
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      width: 500,
      height: 300,
      zIndex: 300,
    },
    header: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors.primary,
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    content: {
      width: "100%",
      flex: 6,
      backgroundColor: colors.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    field: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      width: "100%",
      marginLeft: 50,
      marginRight: 50,
    },
    footer: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      margin: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    message: {
      fontSize: 15,
      color: colors.primary,
      margin: 40,
      textAlign: "left",
      marginBottom: 20,
    },
    feedback: {
      fontSize: 20,
      color: colors.primary,
      margin: 50,
      marginTop: 10,
      fontWeight: "700",
    },
  };
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: colors.gray,
          opacity: 0.3,
        }}
      />
      <div style={styles.container}>
        <div style={styles.header}>
          <p style={{ color: colors.white, fontSize: 20 }}>
            IMPORTA INVENTARIO DA FILE EXCEL
          </p>
          <button
            onClick={() => {
              closeModal();
              setMessage("");
            }}
            style={{
              position: "absolute",
              right: 30,
              marginTop: "auto",
              marginBottom: "auto",
              border: "none",
              backgroundColor: "transparent",
              outline: "none",
              cursor: "pointer",
            }}
          >
            <img
              alt="Close button"
              src={close}
              style={{ width: 15, objectFit: "contain" }}
            />
          </button>
        </div>
        <div style={styles.content}>
          <p style={styles.message}>
            Scarica{" "}
            <a
              href={module}
              style={{ color: colors.secondary, cursor: "pointer" }}
              download="EMMA-template.xlsx"
            >
              qui
            </a>{" "}
            il modello Excel e compilalo con i dati del tuo inventario.
            <br />
            <br />
            Una volta finito, caricalo qua sotto. Al resto penserà EMMA.
          </p>
          {message !== "" ? (
            <p style={styles.feedback}>{message}</p>
          ) : (
            <form
              style={{
                marginTop: 20,
                alignItems: "left",
                textAlign: "left",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {loader ? (
                <ReactLoading
                  type={"spinningBubbles"}
                  color={colors.primary}
                  height={50}
                  width={50}
                />
              ) : (
                <input
                  type="file"
                  name="file"
                  onChange={(event) => setFile(event.target.files[0])}
                  alt="excel file"
                  accept=".xlsx, .xls"
                />
              )}
            </form>
          )}
        </div>
        <div style={styles.footer}>
          {message !== "" ? (
            <div style={{ height: 10 }}></div>
          ) : (
            file && (
              <button
                onClick={() => {
                  const formData = new FormData();
                  formData.append("files", file);
                  formData.append("refId", settingsId);
                  formData.append("ref", "settings");
                  formData.append("field", "inventario");
                  setLoader(true);
                  settingsService.upload(formData).then(() => {
                    itemsService.createInventory().then((response) => {
                      setLoader(false);
                      setMessage(response.message);
                    });
                  });
                }}
                style={styles.button}
              >
                IMPORTA INVENTARIO
              </button>
            )
          )}
        </div>
      </div>
    </>
  );
};
export default AddFromFile;
