import React, { useState, useEffect } from "react";
import colors from "./../museumColors";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./../index.css";
import {
  itemsService,
  reservationsService,
  settingsService,
} from "../_services";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it";
import ReactLoading from "react-loading";
import back from "./../img/arrow_back.png";
import arrow from "./../img/arrow_down_dark.png";
import back_dark from "./../img/arrow_back_dark.png";
import { bounce } from "react-animations";
import styled, { keyframes } from "styled-components";
import Paypal from "../components/buttons/paypal";

const Bounce = styled.div`
  animation: 2s ${keyframes`${bounce}`} infinite;
`;

registerLocale("it", it);
const CustomInput = ({ value, onClick }) => (
  <button className="custom-input-res" onClick={onClick}>
    {value}
  </button>
);

const localizer = momentLocalizer(moment);

function checkColor(color) {
  var c = color.substring(1); // strip #
  var rgb = parseInt(c, 16); // convert rrggbb to decimal
  var r = (rgb >> 16) & 0xff; // extract red
  var g = (rgb >> 8) & 0xff; // extract green
  var b = (rgb >> 0) & 0xff; // extract blue
  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  if (luma < 80) {
    return colors?.white;
  } else {
    return colors?.darkgray;
  }
}

const VisitorReservation = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [closingDays, setClosingDays] = useState([]);
  const [dateSelected, setDateSelected] = useState(new Date());
  const [availableSlots, setAvailableSlots] = useState([]);
  const [ticketsList, setTicketsList] = useState([]);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedTicket, setSelectedTicket] = useState({});
  const [step, setStep] = useState(1);
  const [info, setInfo] = useState(null);
  const [response, setResponse] = useState("");
  const [loader, setLoader] = useState(true);
  const [loaderCreation, setLoaderCreation] = useState(false);
  const [titleColor, setTitleColor] = useState(colors?.darkgray);
  const [orgId, setOrgId] = useState(null);
  const [isClosingDay, setIsClosingDay] = useState(false);
  const [calendarLoader, setCalendarLoader] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorNumber, setErrorNumber] = useState(false);
  const [availablePlaces, setAvailablePlaces] = useState(0);

  const getClosingDays = (date) => {
    reservationsService
      .getOnlineClosingDay(orgId, moment(date).format("yyyy-MM-DD"))
      .then((closingDays) => {
        if (closingDays.indexOf(moment(date).format("yyyy-MM-DD")) !== -1) {
          setIsClosingDay(true);
        } else {
          setIsClosingDay(false);
        }
        setClosingDays(closingDays);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const getAvailableSlot = (date) => {
    reservationsService
      .getOnlineAvailableSlot(orgId, moment(date).format("yyyy-MM-DD"))
      .then((slots) => {
        if (slots.length > 0) {
          setAvailableSlots(slots);
          setSelectedTime(slots[0].ora_inizio);
        } else {
          var tomorrow = new Date();
          tomorrow.setDate(new Date(date).getDate() + 1);
          setDateSelected(tomorrow);
          getAvailability(tomorrow);
        }
        setCalendarLoader(false);
      });
  };

  const getAvailability = (date) => {
    getAvailableSlot(date);
    getClosingDays(date);
  };

  const getTickets = (orgId) => {
    itemsService.getTicketsPublic(orgId).then((items) => {
      setTicketsList(items);
      setSelectedTicket(items[0].id);
    });
  };

  const onClickButton = () => {
    switch (step) {
      case 1:
        setStep(2);
        break;
      case 2:
        if (isEmail) {
          setShowError(false);
          var totale =
            number *
            ticketsList.find((t) => t.id === selectedTicket).prezzo_articolo;

          if (totale > 0) {
            setStep(3);
          } else {
            let response = {
              status: true,
              message:
                "La tua prenotazione è stata registrata correttamente. Abbiamo inviato un'email all'indirizzo " +
                email +
                " con la conferma della tua prenotazione. ",
            };
            reserveVisit(response);
          }
        } else {
          setShowError(true);
        }
        break;
      case 3:
        break;
      case 4:
        resetData();
        setStep(1);
        break;
      default:
        setStep(1);
        break;
    }
  };

  const resetData = () => {
    setName("");
    setSurname("");
    setNumber("");
    setEmail("");
    setMobile("");
    setSelectedTicket(ticketsList[0]);
    setSelectedTime("");
  };

  const reserveVisit = (response) => {
    if (response.status) {
      setLoaderCreation(true);
      setStep(4);
      let parts = selectedTime.split(":");
      let datetime = new Date(
        dateSelected.getFullYear(),
        dateSelected.getMonth(),
        dateSelected.getDate(),
        parts[0],
        parts[1]
      ).toISOString();
      reservationsService
        .createOnlineReservation(
          orgId,
          name,
          surname,
          parseInt(number),
          email,
          mobile,
          datetime
        )
        .then((response) => {
          setResponse(response);
          let ticketInfo = ticketsList.find((t) => t.id === selectedTicket);

          let order = [];

          for (var i = 0; i < parseInt(number); i++) {
            let item = {};
            let visitor = {};
            visitor.codice_biglietto = new Date().getTime().toString();

            item.visitatore = visitor;
            //FIX ME with item.articolo = obj;
            //item.articolo = obj.id;
            item.articolo = ticketInfo;
            item.quantita = 1;
            item.operatore = "Sito";
            item.prezzo = ticketInfo.prezzo_articolo;
            order.push(item);
          }

          let payment = "Online";

          itemsService.sell(order, payment).then((response) => {
            setLoaderCreation(false);
          });
        });
    } else {
      var errorResponse = {};
      errorResponse.status = false;
      errorResponse.message = "Si è verificato un errore durante il pagamento.";
      setResponse(errorResponse);
      setLoaderCreation(false);
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let error = re.test(String(email).toLowerCase());
    if (email === "") {
      setShowError(false);
    }
    setIsEmail(error);

    return error;
  };

  useEffect(() => {
    setAvailablePlaces(
      info?.capacity -
        availableSlots.find((s) => s.ora_inizio === selectedTime)
          ?.numero_persone
    );
  }, [selectedTime, info, availableSlots]);

  useEffect(() => {
    if (orgId !== null) {
      getAvailability(dateSelected);
      getTickets(orgId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, dateSelected]);

  useEffect(() => {
    const getInfo = () => {
      settingsService.getAllMuseums().then((list) => {
        let museum = list.find(
          (mus) => mus.identificativo_museo === props.match.params.museum
        );
        if (museum !== undefined) {
          const script = document.createElement("script");

          script.src =
            "https://www.paypal.com/sdk/js?client-id=" +
            museum.paypalId +
            "&currency=EUR";
          script.async = true;

          document.body.appendChild(script);
        }
        setInfo(museum);
        setLoader(false);
        setCalendarLoader(true);
        setTitleColor(checkColor(museum.colore_museo));
        setOrgId(museum.id_org);
      });
    };

    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
      const newHeight = window.innerHeight;
      setHeight(newHeight);
    };
    window.addEventListener("resize", updateWindowDimensions);
    getInfo();

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, [props.match.params.museum]);

  const styles = {
    container: {
      width: "100vw",
      height: "100vh",
    },
    subcontainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
    museum: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      flex: 1,
      height: "100%",
      width: "100%",
      boxShadow: "2px 0 4px 0 rgb(0 0 0 / 20%)",
      flexDirection: "column",
    },
    content: {
      flex: 4,
      width: "100%",
      backgroundColor: info?.colore_museo,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    calendarBox: {
      width: "85%",
      height: "70%",
      borderRadius: 15,
      backgroundColor: colors?.white,
      boxShadow: "2px 0 4px 0 rgb(0 0 0 / 20%)",
      marginTop: 20,
      display: "flex",
      justifyContent: width > 1000 ? "space-around" : "center",
      alignItems: "center",
    },
    eventsBox: {
      width: "85%",
      height: "70%",
      marginTop: 20,
      display: "flex",
      alignItems: "center",
      borderRadius: 15,
    },
    label: {
      color: colors?.darkgray,
      width: "80%",
      fontSize: 13,
      textAlign: "left",
    },
    menu: {
      border: "1px solid lightgray",
      width: "88%",
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
      color: colors?.darkgray,
      borderRadius: 5,
      backgroundColor: colors?.white,
      outline: "none",
    },
    textInput: {
      borderRadius: 5,
      border: "1px solid lightgray",
      outline: "none",
      textAlign: "center",
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
      width: "80%",
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      cursor: "pointer",
      boxShadow: "2px 0 4px 0 rgb(0 0 0 / 20%)",
    },
    bmBurgerButton: {
      position: "fixed",
      width: "36px",
      height: "30px",
      left: "36px",
      top: "36px",
    },
    bmBurgerBars: {
      background: "#373a47",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },
    bmMenu: {
      background: "#373a47",
      padding: "2.5em 0 0",
      fontSize: "1.15em",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#b8b7ad",
      padding: "0.8em 0 0",
      display: "flex",
      flexDirection: "column",
    },
    bmItem: {
      display: "inline-block",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
    menuButton: {
      outline: "none",
      backgroundColor: "transparent",
      border: "none",
      paddingTop: 15,
      paddingBottom: 15,
      width: "100%",
      color: "white",
      fontSize: 18,
      textDecoration: "none",
    },
    errorMessage: {
      color: colors?.highlight,
      fontSize: 10,
      fontWeight: "bold",
      marginTop: -6,
    },
  };

  return loader ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ReactLoading
        type={"spinningBubbles"}
        color={colors?.darkgray}
        height={50}
        width={50}
      />
    </div>
  ) : (
    <div style={styles.container}>
      {/*<Menu styles={styles}>
        <a
          style={{ ...styles.menuButton, backgroundColor: "black" }}
          href={entrancesUri}
        >
          INGRESSI
        </a>
        <a
          style={{ ...styles.menuButton, backgroundColor: "transparent" }}
          href={eventsUri}
        >
          EVENTI
        </a>
  </Menu>*/}
      <div style={width > 1400 ? styles.subcontainer : { height: "100%" }}>
        <div style={styles.museum}>
          <img
            alt="Logo"
            src={info?.logo_light.url}
            style={{
              width: 180,
              objectFit: "contain",
              margin: 10,
              marginBottom: height * 0.4,
            }}
          />
          {width < 1400 && (
            <Bounce style={{ marginBottom: 40, position: "absolute" }}>
              <img
                alt="Logo"
                src={arrow}
                style={{ width: 18, objectFit: "contain" }}
              />
            </Bounce>
          )}
        </div>
        <div style={styles.content}>
          {step === 2 && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setStep(1)}
            >
              <img
                alt="Arrow icon"
                src={titleColor === colors?.white ? back : back_dark}
                style={{
                  width: 13,
                  objectFit: "contain",
                  marginLeft: 30,
                  marginRight: 10,
                }}
              />
              <p style={{ color: titleColor, fontSize: 12, fontWeight: "600" }}>
                Indietro
              </p>
            </div>
          )}
          <h1 style={{ color: titleColor, textAlign: "center", margin: 0 }}>
            {step === 1
              ? "Acquista un ingresso"
              : step === 2
              ? "Inserisci i tuoi dati"
              : response.status
              ? "Prenotazione effettuata"
              : ""}
          </h1>
          <div
            style={{
              ...styles.calendarBox,
              flexDirection: width > 1000 ? "row" : "column",
            }}
          >
            {step === 1 &&
              (width > 1000 ? (
                <Calendar
                  date={moment(dateSelected).toDate()}
                  events={[]}
                  localizer={localizer}
                  onNavigate={(date) => {
                    if (
                      moment(date).format("yyyy-MM-DD") >=
                      moment(new Date()).format("yyyy-MM-DD")
                    ) {
                      setDateSelected(date);
                      setCalendarLoader(true);

                      getAvailability(date);
                    }
                  }}
                  onSelectSlot={(slot) => {
                    if (
                      moment(slot.start).format("yyyy-MM-DD") >=
                      moment(new Date()).format("yyyy-MM-DD")
                    ) {
                      setDateSelected(slot.start);
                      setCalendarLoader(true);
                      getAvailability(slot.start);
                    }
                  }}
                  selectable={true}
                  views={["month"]}
                  messages={{
                    next: "Successivo",
                    previous: "Precedente",
                    today: "Oggi",
                  }}
                  popup={true}
                  min={new Date()}
                  scrollToTime={new Date()}
                  style={{ height: height * 0.6, width: "60%" }}
                  dayPropGetter={(day) => {
                    let newStyle = {
                      backgroundColor: "#f6f6f6",
                      color: colors?.white,
                      opacity: 1,
                    };
                    if (
                      closingDays.indexOf(moment(day).format("yyyy-MM-DD")) !==
                        -1 ||
                      moment(day).format("yyyy-MM-DD") <
                        moment(new Date()).format("yyyy-MM-DD")
                    ) {
                      return {
                        className: "",
                        style: newStyle,
                      };
                    } else if (
                      moment(day).format("yyyy-MM-DD") ===
                      moment(dateSelected).format("yyyy-MM-DD")
                    ) {
                      return {
                        style: {
                          backgroundColor: info?.colore_museo,
                        },
                      };
                    }
                  }}
                />
              ) : (
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    overflowY: "scroll",
                    marginTop: 20,
                  }}
                >
                  <label style={{ ...styles.label, width: "90%" }}>
                    Scegli una data
                  </label>
                  <DatePicker
                    selected={dateSelected}
                    onChange={(date) => {
                      setDateSelected(date);
                      getAvailability(date);
                    }}
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    locale="it"
                    customInput={<CustomInput />}
                  />
                </div>
              ))}
            <div
              style={{
                width: width < (step === 1 ? 1000 : 1400) ? "90%" : "30%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                overflowY: "scroll",
              }}
            >
              {step === 2 && (
                <>
                  <p style={{ color: colors?.darkgray, fontSize: 18 }}>
                    <b>{number}</b> bigliett
                    {number > 1 ? "i" : "o"}{" "}
                    <b>
                      {
                        ticketsList.find((t) => t.id === selectedTicket)
                          .nome_articolo
                      }{" "}
                    </b>
                    <br />
                    <b>{moment(dateSelected).format("D MMMM yyyy")}</b> ore{" "}
                    <b>{selectedTime}</b>
                  </p>
                  <p style={{ color: colors?.darkgray, fontSize: 18 }}>
                    Totale:{" "}
                    <b>
                      €
                      {(
                        number *
                        ticketsList.find((t) => t.id === selectedTicket)
                          .prezzo_articolo
                      ).toFixed(2)}
                    </b>
                  </p>
                </>
              )}
              {calendarLoader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={colors?.darkgray}
                    height={50}
                    width={50}
                  />
                </div>
              ) : isClosingDay ? (
                <p
                  style={{
                    color: colors?.darkgray,
                    fontWeight: "600",
                    fontSize: 25,
                  }}
                >
                  CHIUSO
                </p>
              ) : (
                <form
                  style={{
                    width: "100%",
                    maxHeight: height * 0.4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    overflowY: "scroll",
                    marginTop: 20,
                  }}
                >
                  {step === 1 && (
                    <>
                      <label
                        style={{
                          ...styles.label,
                          width: width < 1000 && "90%",
                        }}
                      >
                        Scegli un orario
                      </label>
                      <select
                        name="time"
                        style={styles.menu}
                        value={selectedTime}
                        onChange={(event) => {
                          setSelectedTime(event.target.value);
                        }}
                      >
                        {availableSlots.map((slot, key) => {
                          return (
                            <option key={key} value={slot.ora_inizio}>
                              {slot.ora_inizio}
                            </option>
                          );
                        })}
                      </select>
                      <br />
                      <label
                        style={{
                          ...styles.label,
                          width: width < 1000 && "90%",
                        }}
                      >
                        Scegli una tipologia biglietto
                      </label>
                      <select
                        name="ticket"
                        style={styles.menu}
                        value={selectedTicket}
                        onChange={(event) => {
                          setSelectedTicket(parseInt(event.target.value));
                          setNumber("");
                        }}
                      >
                        {ticketsList.map((tick, key) => {
                          return (
                            <option key={key} value={tick.id}>
                              {tick.nome_articolo} - €
                              {tick.prezzo_articolo.toFixed(2)}
                            </option>
                          );
                        })}
                      </select>
                      <br />
                      <label
                        style={{
                          ...styles.label,
                          width: width < 1000 && "90%",
                        }}
                      >
                        Numero di biglietti
                      </label>
                      {errorNumber && (
                        <p style={{ color: "gray", fontSize: 9 }}>
                          Solo {availablePlaces} posti disponibili per questo
                          orario
                        </p>
                      )}
                      <input
                        type="number"
                        name="number"
                        value={number}
                        onChange={(event) => {
                          if (
                            (parseInt(event.target.value) <= availablePlaces &&
                              parseInt(event.target.value) > 0) ||
                            event.target.value === ""
                          ) {
                            setNumber(event.target.value);
                            setErrorNumber(false);
                          } else {
                            setErrorNumber(true);
                          }
                        }}
                        placeholder="Numero biglietti"
                        style={{ ...styles.textInput }}
                      />
                    </>
                  )}
                  {step === 2 && (
                    <>
                      <label style={styles.label}>Nome</label>
                      <input
                        type="text"
                        name="name"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        placeholder="Nome"
                        style={{ ...styles.textInput }}
                      />
                      <label style={styles.label}>Cognome</label>
                      <input
                        type="text"
                        name="surname"
                        value={surname}
                        onChange={(event) => setSurname(event.target.value)}
                        placeholder="Cognome"
                        style={{ ...styles.textInput }}
                      />
                      {/*<label style={styles.label}>Numero partecipanti</label>
                      <input
                        type="number"
                        name="number"
                        value={number}
                        onChange={(event) =>
                            setNumber(event.target.value)
                            setErrorNumber(false)
                        }
                        placeholder="Numero partecipanti"
                        style={{ ...styles.textInput }}
                      />*/}
                      <label style={styles.label}>Email</label>
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(event) => {
                          setEmail(event.target.value);
                          validateEmail(event.target.value);
                        }}
                        placeholder="Email"
                        style={{ ...styles.textInput }}
                      />
                      {showError && !isEmail && (
                        <p
                          style={{
                            color: colors?.highlight,
                            marginTop: 2,
                            fontSize: 10,
                            width: "72%",
                          }}
                        >
                          Email non valida
                        </p>
                      )}
                      <label style={styles.label}>Telefono</label>
                      <input
                        type="tel"
                        name="mobile"
                        value={mobile}
                        onChange={(event) =>
                          setMobile(event.target.value.replace(/[^\d]/, ""))
                        }
                        placeholder="Cellulare"
                        style={{ ...styles.textInput }}
                      />
                    </>
                  )}
                  {step === 3 &&
                    (loaderCreation ? (
                      <ReactLoading
                        type={"spinningBubbles"}
                        color={info?.colore_museo}
                        height={50}
                        width={50}
                      />
                    ) : (
                      <div
                        style={{
                          width: "80%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <h1
                          style={{
                            color: titleColor,
                            textAlign: "center",
                            margin: 0,
                            marginBottom: 20,
                          }}
                        >
                          Seleziona una modalità di pagamento
                        </h1>
                        <Paypal
                          nome={
                            number + " ingress" + (number > 1)
                              ? "i"
                              : "o" +
                                " " +
                                ticketsList.find((t) => t.id === selectedTicket)
                                  .nome_articolo +
                                " per il giorno " +
                                moment(dateSelected).format("D MMMM yyyy") +
                                ", " +
                                selectedTime
                          }
                          prezzo={
                            number *
                            ticketsList.find((t) => t.id === selectedTicket)
                              .prezzo_articolo
                          }
                          manageOrderResponse={reserveVisit}
                          enableLoading={() => setLoaderCreation(true)}
                        />
                      </div>
                    ))}
                  {step === 4 &&
                    (loaderCreation ? (
                      <ReactLoading
                        type={"spinningBubbles"}
                        color={info?.colore_museo}
                        height={50}
                        width={50}
                      />
                    ) : (
                      <div>
                        <img
                          alt="Logo"
                          src={info?.logo.url}
                          style={{
                            width: 120,
                            objectFit: "contain",
                            margin: 10,
                          }}
                        />
                        <p style={{ color: colors?.darkgray }}>
                          {response.message}
                        </p>
                      </div>
                    ))}
                  {!loaderCreation && step !== 3 && (
                    <button
                      type="button"
                      style={{
                        ...styles.button,
                        width: 200,
                        backgroundColor: colors?.darkgray,
                        color: colors?.white,
                        padding: 10,
                        borderRadius: 15,
                        margin: 30,
                        opacity: (
                          step === 2
                            ? name === "" ||
                              surname === "" ||
                              number === "" ||
                              number === "0" ||
                              email === "" ||
                              mobile === ""
                            : selectedTime === "" || number === ""
                        )
                          ? 0.5
                          : 1,
                      }}
                      disabled={
                        step === 2
                          ? name === "" ||
                            surname === "" ||
                            number === "" ||
                            number === "0" ||
                            email === "" ||
                            mobile === ""
                          : selectedTime === "" || number === ""
                      }
                      onClick={() => onClickButton()}
                    >
                      {step === 1
                        ? "SELEZIONA"
                        : step === 2
                        ? number *
                            ticketsList.find((t) => t.id === selectedTicket)
                              .prezzo_articolo >
                          0
                          ? "VAI AL PAGAMENTO"
                          : "PRENOTA"
                        : "TORNA AL CALENDARIO"}
                    </button>
                  )}
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitorReservation;
