import React, { useEffect, useState, useContext, useCallback } from "react";
import { OrgContext, UserContext } from "../../../contexts";
import search from "../../../img/search.png";
import refresh from "../../../img/refresh.png";
import add from "../../../img/add_black.png";
import download from "../../../img/download_dark.png";
import { LanguageContext } from "../../../containers/language";
import Table from "./table";
import ReactLoading from "react-loading";
import { customersService } from "../../../_services/customers.service";
import Pagination from "react-js-pagination";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import SingleVisitorPage from "./singleVisitorPage";

const itemsPerPageOptions = [10, 20, 50, 100];

function isEmpty(field) {
  return field === "" || field === null || field === undefined;
}

const SingleVisitorsManager = () => {
  const colors = useContext(UserContext)?.colors;
  const org = useContext(OrgContext)?.org;

  const { dictionary } = useContext(LanguageContext);
  const [openRecord, setOpenRecord] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [tempSearchKey, setTempSearchKey] = useState("");
  const [customers, setCustomers] = useState([]);
  const [excelCustomers, setExcelCustomers] = useState([]);

  const [isCreation, setIsCreation] = useState(false);

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [customersLoaded, setCustomersLoaded] = useState(false);

  const [newCustomer, setNewCustomer] = useState({
    nome: "",
    cognome: "",
    codice_fiscale: "",
    email: "",
    born_date: "",
    indirizzo: "",
    luogo_di_nascita: "",
    additional_infos: [],
  });

  const getCustomers = (start, limit, searchKey) => {
    setCustomersLoaded(false);

    // count all, then get between limits
    customersService.countVisitors(org.id, searchKey).then((allCustomers) => {
      setTotalItemsCount(allCustomers);

      customersService
        .getVisitors(org.id, start, limit, searchKey)
        .then((customers) => {
          setCustomers(customers);

          let tmpCustomers = [];

          for (let i = 0; i < customers.length; i++) {
            let customer = customers[i];
            customer.eta = customer.data_nascita
              ? new Date().getFullYear() - customer.data_nascita?.split("-")[0]
              : "";
            for (let j = 0; j < customer.additional_infos?.length; j++) {
              let field = {
                [customer.additional_infos[j].name]:
                  customer.additional_infos[j].value,
              };
              customer = { ...customer, ...field };
            }
            tmpCustomers.push(customer);
          }
          setExcelCustomers(tmpCustomers);
          setCustomersLoaded(true);
        });
    });
  };

  const getVisitorsProfiling = useCallback(() => {
    let tmpCustomer = newCustomer;
    let profiling = org.configurazioni.visitatori;
    let fields = [];

    for (let i = 0; i < profiling?.length; i++) {
      fields.push({
        name: profiling[i].name,
        type: profiling[i].type,
        value: profiling[i].default,
      });
    }

    tmpCustomer.additional_infos = fields;

    setNewCustomer(tmpCustomer);
  }, [newCustomer, org.configurazioni.visitatori]);

  useEffect(() => {
    getVisitorsProfiling();
  }, [getVisitorsProfiling]);

  const startingSearch = () => {
    // reset searchKey
    let currentSearchKey = "";
    setSearchKey(currentSearchKey);

    let tempActivePage = 1;
    setActivePage(tempActivePage);

    getCustomers(
      itemsPerPage * tempActivePage - itemsPerPage,
      itemsPerPage,
      currentSearchKey
    );

    getVisitorsProfiling();
  };

  useEffect(() => {
    getCustomers(
      itemsPerPage * activePage - itemsPerPage,
      itemsPerPage,
      searchKey
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, itemsPerPage, openRecord]);

  useEffect(() => {
    const handleSubmit = () => {
      setSearchKey(tempSearchKey);

      let tempActivePage = 1;
      setActivePage(tempActivePage);

      getCustomers(
        itemsPerPage * tempActivePage - itemsPerPage,
        itemsPerPage,
        tempSearchKey
      );
    };

    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, tempSearchKey]);

  const camelCase = (str) => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  const filterColumns = (data) => {
    // Get column names
    const columns = Object.keys(data[0]);

    const filterColsByKey = columns.filter(
      (c) =>
        c === "nome" ||
        c === "cognome" ||
        c === "genere" ||
        c === "email" ||
        c === "codice_fiscale" ||
        c === "eta" ||
        c === "via" ||
        c === "comune" ||
        c === "cap" ||
        c === "provincia" ||
        c === "nazione" ||
        c === "nome"
    );

    return filterColsByKey; // OR return columns
  };

  const getLabel = (col) => {
    switch (col) {
      case "codice_fiscale":
        return "Codice fiscale";
      case "eta":
        return "Età";
      default:
        return col;
    }
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      overflowX: "scroll",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    rowBottom: {
      display: "flex",
      justifyContent: "center",
      width: !isEmpty(openRecord) ? "100%" : "90%",
      overflow: "scroll",
    },
    subcontainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    inputSearch: {
      borderRadius: 40,
      border: "1px solid #F0F0F0",
      width: "50%",
      height: 40,
      display: "flex",
      flexDirection: "row",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
    goBackButton: {
      width: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    saveButton: {
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: colors.darkgray,
      margin: 30,
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 15,
    },
    filterButton: {
      border: "1px solid #F0F0F0",
      outline: "none",
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
    addButton: {
      display: "flex",
      flexDirection: "row",
      border: "none",
      borderRadius: 5,
      backgroundColor: colors.white,
      alignItems: "center",
      justifyContent: "center",
      padding: "10px",
      cursor: "pointer",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.body}>
        <div style={styles.rowBottom}>
          {!isEmpty(openRecord) ? (
            <SingleVisitorPage
              setOpenRecord={setOpenRecord}
              customer={openRecord}
              isCreation={isCreation}
              setIsCreation={setIsCreation}
              getCustomers={startingSearch}
              profiling={org.configurazioni.visitatori}
            />
          ) : (
            <div style={styles.subcontainer}>
              <div style={{ ...styles.row, marginTop: 20 }}>
                <div
                  style={{
                    ...styles.row,
                    justifyContent: "start",
                    width: "100%",
                  }}
                >
                  {totalItemsCount > 1 || totalItemsCount === 0 ? (
                    <p
                      style={{
                        color: colors?.gray,
                        fontSize: 11,
                        textAlign: "left",
                      }}
                    >
                      {totalItemsCount} visitatori singoli trovati
                    </p>
                  ) : (
                    <p
                      style={{
                        color: colors?.gray,
                        fontSize: 11,
                        textAlign: "left",
                      }}
                    >
                      {totalItemsCount} visitatore singolo trovato
                    </p>
                  )}
                </div>
              </div>
              <div
                style={{
                  ...styles.row,
                  justifyContent: "space-between",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <div style={styles.inputSearch}>
                  <img
                    alt="Search icon"
                    src={search}
                    style={{ width: 30, objectFit: "contain", margin: 10 }}
                  />
                  <input
                    type="text"
                    style={{
                      border: 0,
                      width: "100%",
                      borderRadius: 40,
                      outline: "none",
                    }}
                    value={tempSearchKey}
                    onChange={(event) => {
                      setTempSearchKey(event.target.value);
                    }}
                    placeholder={dictionary.search}
                  />
                  <button
                    style={{
                      ...styles.filterButton,
                    }}
                    onClick={() => {
                      setTempSearchKey("");
                      setSearchKey("");
                      startingSearch(); //come back to the original result search (i.e. the ones at the page opening)
                    }}
                  >
                    <img
                      alt="Refresh icon"
                      src={refresh}
                      style={{ width: 18, objectFit: "contain", margin: 2 }}
                    />
                  </button>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <button
                    style={styles.addButton}
                    onClick={() => {
                      setIsCreation(true);
                      setOpenRecord(newCustomer);
                    }}
                  >
                    <img
                      src={add}
                      style={{ height: 13, objectFit: "contain" }}
                      alt={"Add customer"}
                    />
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.darkgray,
                        margin: 0,
                        marginLeft: 5,
                        fontWeight: "600",
                      }}
                    >
                      Nuovo cliente
                    </p>
                  </button>
                  {customers.length > 0 && (
                    <ExcelFile
                      filename={"Info_Clienti-Singoli_visitatori"}
                      element={
                        <button style={styles.addButton}>
                          <img
                            src={download}
                            style={{ height: 13, objectFit: "contain" }}
                            alt={"Download excel"}
                          />
                          <p
                            style={{
                              fontSize: 12,
                              color: colors.darkgray,
                              margin: 0,
                              marginLeft: 5,
                              fontWeight: "600",
                            }}
                          >
                            {dictionary.downloadExcel}
                          </p>
                        </button>
                      }
                    >
                      <ExcelSheet data={excelCustomers} name={"Visitatori"}>
                        {excelCustomers.length > 0 &&
                          filterColumns(excelCustomers).map((col, key) => {
                            return (
                              <ExcelColumn
                                key={key}
                                label={camelCase(getLabel(col))}
                                value={col}
                              />
                            );
                          })}
                      </ExcelSheet>
                    </ExcelFile>
                  )}
                </div>
              </div>
              <div
                id="scroll"
                style={{ overflowY: customersLoaded ? "scroll" : "hidden" }}
              >
                {customers.length > 0 && customersLoaded ? (
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Table
                      customers={customers}
                      colors={colors}
                      setOpenRecord={setOpenRecord}
                      activePage={activePage}
                      itemsPerPage={itemsPerPage}
                    />
                  </div>
                ) : customersLoaded ? (
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: 14,
                      color: colors.gray,
                      marginTop: 40,
                    }}
                  >
                    {dictionary.noCustomers}
                  </p>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      marginTop: 80,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ReactLoading
                      type={"spinningBubbles"}
                      color={colors?.primary}
                      height={50}
                      width={50}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{ width: "90%" }}>
        {customers.length > 0 &&
          totalItemsCount > itemsPerPageOptions[0] &&
          customersLoaded &&
          isEmpty(openRecord) && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridAutoRows: "1fr",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <select
                  name="options"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: 15,
                    height: 50,
                    width: 70,
                    padding: 10,
                    textAlign: "left",
                    marginRight: 10,
                  }}
                  value={itemsPerPage}
                  onChange={(event) => {
                    setItemsPerPage(parseInt(event.target.value));
                    setActivePage(1);
                  }}
                >
                  {itemsPerPageOptions.map((option, key) => {
                    return (
                      <option key={key} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
                <p style={{ color: colors?.gray, fontSize: 12 }}>
                  {dictionary.pageElements}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => setActivePage(pageNumber)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default SingleVisitorsManager;
