import React from "react";
import RemoveEventCategory from "../popup/removeEventCategory";
import RecoverCategory from "../popup/recoverCategory";
import EndOperation from "../../../components/popup/endOperation";

const PopupManager = ({
  showDeleteCategory,
  showRecoverCategory,
  onDeleteCategory,
  onRecoverCategory,
  categorySelected,
  toggleDeleteCategory,
  toggleRecoverCategory,
  operation,
  endOperation,
  toggleEndOperation,
  operationResult,
  colors,
}) => {
  return (
    <div>
      {showDeleteCategory && (
        <RemoveEventCategory
          name={categorySelected ? categorySelected.nome_categoria : ""}
          closeModal={toggleDeleteCategory}
          saveData={onDeleteCategory}
          colors={colors}
        />
      )}
      {showRecoverCategory && (
        <RecoverCategory
          name={categorySelected.nome_categoria}
          closeModal={toggleRecoverCategory}
          saveData={onRecoverCategory}
          colors={colors}
        />
      )}
      {endOperation && (
        <EndOperation
          flag={operationResult}
          operation={operation}
          closeModal={toggleEndOperation}
          colors={colors}
        />
      )}
    </div>
  );
};

export default PopupManager;
