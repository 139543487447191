import React from "react";

const Report = () => {
  return <></>;
};

export default Report;

// import React, { useContext, useEffect, useState, useRef } from "react";
// import { UserContext } from "../../../contexts";
// import ReportsTopBar from "../topBars/reportsTopBar";
// import moment from "moment";
// import Pagination from "react-js-pagination";
// import refresh from "./../../../img/refresh.png";
// import { reportService, usersService } from "../../../_services";

// function usePrevious(value) {
//   const ref = useRef();
//   useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// }

// const Report = (props) => {
//     const colors = useContext(UserContext)?.colors;
//     const user = useContext(UserContext)?.user;
//     const prevUser = usePrevious(user);

//     const [museumList, setMuseumList] = useState([]);

//     const [startingDate, setStartingDate] = useState(new Date());
//     const [endingDate, setEndingDate] = useState(new Date());
//     const [filter, setFilter] = useState(-1);

//     const [lastUpdate, setLastUpdate] = useState(moment());
//     const prevLastUpdate = usePrevious(lastUpdate);

//     const itemsPerPageOptions = [10, 20, 50, 100];
//     const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
//     const [totalItemsCount, setTotalItemsCount] = useState(0);

//     const [reportsList, setReportsList] = useState([]);

//     const [activePage, setActivePage] = useState(1);

//     const labels = [
//       "Data Iniziale",
//       "Data Finale",
//       "Evento",
//       "Tipologia",
//       "Data Richiesta",
//       "Stato"
//     ];

//     const getMuseums = () => {
//       usersService
//         .getMuseums()
//         .then((list) => setMuseumList(list))
//         .catch((errors) => {
//           if (errors.statusCode === 401 || errors.statusCode === 403) {
//             this.props.history.push("/");
//           }
//         });
//     };

//     useEffect(() => {
//         props.setIsReport(true);
//         props.resetFilters();

//         if (prevUser?.flag_network !== user?.flag_network) {
//           if (user?.flag_network) {
//             getMuseums();
//           } else {
//             setFilter(-1);
//           }
//         }
//     }, [])

//     const handleChosenDate= (startingDate, endingDate) => {
//         setStartingDate(moment(startingDate).format("yyyy-MM-DD"),);
//         setEndingDate(moment(endingDate).format("yyyy-MM-DD"),);
//       };

//     const dateSelected = (date, isAnnual) => {
//         var first_d = new Date();
//         var last_d = new Date();

//         last_d.setFullYear(date.getFullYear());
//         if(isAnnual){
//           first_d.setFullYear(date.getFullYear());
//           first_d.setMonth(0);
//           first_d.setDate(1);

//           //Set the ending date as the last day of the year
//           last_d.setMonth(11);
//           last_d.setDate(31);
//         }
//         else{
//           first_d = new Date(date.getFullYear(), date.getMonth(), 1);
//           last_d = new Date(date.getFullYear(), date.getMonth() + 1, 0);
//         }

//         setStartingDate(moment(first_d).format("yyyy-MM-DD"));
//         setEndingDate(moment(last_d).format("yyyy-MM-DD"),);
//       }

//       const handleChosenEvent= (event) => {
//         if(event !== undefined){
//           setStartingDate(moment(event.ora_inizio).format("yyyy-MM-DD"));
//           setEndingDate(moment(event.ora_fine).format("yyyy-MM-DD"));
//         }
//       };

//       const styles = {
//         body: {
//           width: "100%",
//           height: "100%",
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           overflow: "scroll",
//         },
//         padder: {
//           width: "97%",
//         },
//         bottomSection: {
//           flex: 5,
//           overflowX: "scroll",
//           display: "flex",
//           flexDirection: "column",
//           width: "100%",
//         },
//         row: {
//           display: "flex",
//           flexDirection: "row",
//           alignItems: "center",
//           justifyContent: "space-between",
//           marginTop: 20,
//           marginBottom: 5,
//         },
//         labelTable: {
//           width: 170,
//           fontSize: 12,
//           color: colors?.lightgray,
//         },
//         menu: {
//           border: "1px solid colors.primary",
//           width: "90%",
//           padding: 10,
//           color: colors?.tertiary,
//           borderRadius: 10,
//           backgroundColor: colors?.white,
//           outline: "none",
//         },
//         scrollerX: {
//           overflowX: "scroll",
//           display: "flex",
//           flexDirection: "column",
//         },
//       };

//     return (
//         <div style={styles.body}>
//           <div style={styles.padder}>
//               <ReportsTopBar
//                   dateSelected={(date, isAnnual) => dateSelected(date, isAnnual)}
//                   setDate={(startingDate, endingDate) => handleChosenDate( startingDate, endingDate)}
//                   categoriesList={props.categoriesList}
//                   setChosenEvent={(event) => handleChosenEvent(event)}
//               />
//               {user?.flag_network && (
//                 <select
//                   name="filter"
//                   style={styles.menu}
//                   value={filter}
//                   onChange={(event) => setFilter(parseInt(event.target.value))}
//                 >
//                   <option value={-1}>{"Tutti i musei"}</option>
//                   {museumList.map((museum, key) => {
//                     return (
//                       <option key={key} value={museum.id}>
//                         {museum.nome}
//                       </option>
//                     );
//                   })}
//                 </select>
//               )}
//           </div>
//             <div style={styles.bottomSection}>
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   flexDirection: "column",
//                 }}
//               >
//                 <div style={{ ...styles.row, width: "90%",  }}>
//                   <p
//                     style={{
//                       color: colors?.gray,
//                       fontSize: 11,
//                       textAlign: "left",
//                     }}
//                   >
//                     {totalItemsCount} report trovati
//                   </p>

//                   <div style={{ ...styles.row, justifyContent: "flex-end" }}>
//                     <p
//                       style={{
//                         textAlign: "right",
//                         fontSize: 11,
//                         color: colors.gray,
//                         marginRight: 10,
//                       }}
//                     >
//                       Ultimo aggiornamento alle {lastUpdate.format("HH:mm")}
//                     </p>
//                     <img
//                       alt="Refresh"
//                       src={refresh}
//                       style={{
//                         width: 14,
//                         height: 14,
//                         cursor: "pointer",
//                       }}
//                       onClick={() => console.log("Import Data")}//importData()}
//                     ></img>
//                   </div>
//                 </div>
//               <div style={styles.scrollerX}>
//                 <div style={styles.header}>
//                   {reportsList.length === 0 ? (
//                     <p style={styles.labelTable}>Nessun Report</p>
//                   ) : (
//                     labels.map((label, key) => {
//                       return (
//                         <div key={key}>
//                           <p style={styles.labelTable}>{label}</p>
//                         </div>
//                       );
//                     })
//                   )}{" "}
//                 </div>
//                 {/* {reportsList?.map((sale, key) => {
//                   return (
//                     <Sale key={key} sale={sale} setSaleSelected={setSaleSelected} />
//                   );
//                 })} */}
//               </div>
//             </div>
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 width: "100%",
//                 justifyContent: "center",
//               }}
//             >
//               {reportsList.length > 0 && (
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "row",
//                     marginTop: 10,
//                     justifyContent: "center",
//                   }}
//                 >
//                   <Pagination
//                     activePage={activePage}
//                     itemsCountPerPage={itemsPerPage}
//                     totalItemsCount={totalItemsCount}
//                     pageRangeDisplayed={5}
//                     onChange={(pageNumber) => setActivePage(pageNumber)}
//                     itemClass="page-item"
//                     linkClass="page-link"
//                   />
//                 </div>
//               )}
//               <div
//                 style={{
//                   display: "flex",
//                   width: "100%",
//                   flexDirection: "row",
//                   justifyContent: "left",
//                   alignItems: "center",
//                   marginLeft: 40,
//                   marginBottom: 40,
//                 }}
//               >
//                 <select
//                   name="options"
//                   style={{
//                     border: "1px solid lightgray",
//                     borderRadius: 15,
//                     height: 50,
//                     width: 70,
//                     padding: 10,
//                     textAlign: "left",
//                     marginRight: 10,
//                   }}
//                   value={itemsPerPage}
//                   onChange={(event) => {
//                     setItemsPerPage(parseInt(event.target.value));
//                     setActivePage(1);
//                   }}
//                 >
//                   {itemsPerPageOptions.map((option, key) => {
//                     return (
//                       <option key={key} value={option}>
//                         {option}
//                       </option>
//                     );
//                   })}
//                 </select>
//                 <p style={{ color: colors?.gray, fontSize: 12 }}>
//                   elementi per pagina
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//     );
// }

// export default Report;
