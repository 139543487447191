import React, { Component } from "react";
import colors from "../museumColors";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./../index.css";
import { reservationsService, settingsService } from "../_services";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it";
import ReactLoading from "react-loading";
import back from "./../img/arrow_back.png";
import arrow from "./../img/arrow_down_dark.png";
import back_dark from "./../img/arrow_back_dark.png";
import { bounce } from "react-animations";
import styled, { keyframes } from "styled-components";
import EventBoxOnline from "../components/eventBoxOnline";
import { slide as Menu } from "react-burger-menu";

const Bounce = styled.div`
  animation: 2s ${keyframes`${bounce}`} infinite;
`;

registerLocale("it", it);

function checkColor(color) {
  var c = color.substring(1); // strip #
  var rgb = parseInt(c, 16); // convert rrggbb to decimal
  var r = (rgb >> 16) & 0xff; // extract red
  var g = (rgb >> 8) & 0xff; // extract green
  var b = (rgb >> 0) & 0xff; // extract blue
  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  if (luma < 80) {
    return colors.white;
  } else {
    return colors.darkgray;
  }
}

export default class VisitorEventReservation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      closingDays: [],
      dateSelected: new Date(),
      availableSlots: [],
      name: "",
      surname: "",
      number: "",
      email: "",
      mobile: "",
      selectedTime: "",
      step: 1,
      info: null,
      response: "",
      loader: true,
      loaderCreation: false,
      titleColor: colors.darkgray,
      arrow: back,
      id_org: null,
      isClosingDay: false,
      calendarLoader: false,
      isEmail: false,
      showError: false,
      events: [],
      errorNumber: false,
      orgLink: "",
    };
    this.getInfo = this.getInfo.bind(this);
    this.getAvailability = this.getAvailability.bind(this);
    this.getEvents = this.getEvents.bind(this);
    this.reserveEvent = this.reserveEvent.bind(this);
    this.resetData = this.resetData.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.getInfo(this.state.dateSelected);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getEvents(date) {
    this.setState({ calendarLoader: true });
    reservationsService
      .getOnlineEvents(this.state.orgId, moment(date).format("yyyy-MM-DD"))
      .then((events) => {
        this.setState({ events: events, calendarLoader: false });
      });
  }

  getAvailability(date) {
    this.getEvents(date);
  }

  getInfo(date) {
    settingsService.getAllMuseums().then((list) => {
      let museum = list.find(
        (mus) => mus.identificativo_museo === this.props.match.params.museum
      );
      this.setState(
        {
          info: museum,
          loader: false,
          titleColor: checkColor(museum.colore_museo),
          orgId: museum.id_org,
          orgLink: museum.identificativo_museo,
        },
        () => {
          this.getAvailability(date);
        }
      );
    });
  }

  onClickButton() {
    const { step, isEmail } = this.state;
    switch (step) {
      case 1:
        this.setState({ step: 2 });
        break;
      case 2:
        if (isEmail) {
          this.setState({ showError: false, loaderCreation: true });
          this.reserveEvent();
        } else {
          this.setState({ showError: true });
        }
        break;
      case 3:
        this.resetData();
        this.setState({ step: 1 });
        break;
      default:
        this.setState({ step: 1 });
        break;
    }
  }

  resetData() {
    this.setState({
      name: "",
      surname: "",
      number: "",
      email: "",
      mobile: "",
      selectedTime: "",
    });
    this.getEvents(new Date());
  }

  reserveEvent() {
    const {
      event,
      name,
      surname,
      mobile,
      email,
      number,
      selectedTime,
      dateSelected,
      orgId,
    } = this.state;
    this.setState({ step: 3 });
    let parts = selectedTime.split(":");
    let datetime = new Date(
      dateSelected.getFullYear(),
      dateSelected.getMonth(),
      dateSelected.getDate(),
      parts[0],
      parts[1]
    ).toISOString();
    reservationsService
      .createOnlineEventReservation(
        event.id,
        orgId,
        name,
        surname,
        parseInt(number),
        email,
        mobile,
        datetime
      )
      .then((response) =>
        this.setState({ response: response, loaderCreation: false })
      );
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let error = re.test(String(email).toLowerCase());
    if (email === "") {
      this.setState({ showError: false });
    }
    this.setState({ isEmail: error });

    return error;
  }

  onSelectSlot(slot) {
    this.setState({ dateSelected: slot.start });
  }

  render() {
    const {
      height,
      selectedTime,
      name,
      surname,
      mobile,
      email,
      number,
      step,
      width,
      info,
      response,
      loader,
      titleColor,
      loaderCreation,
      calendarLoader,
      showError,
      isEmail,
      events,
      event,
      errorNumber,
      orgLink,
    } = this.state;
    const styles = {
      container: {
        width: "100vw",
        height: "100vh",
      },
      subcontainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      },
      museum: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        flex: 1,
        height: "100%",
        width: "100%",
        boxShadow: "2px 0 4px 0 rgb(0 0 0 / 20%)",
        flexDirection: "column",
      },
      content: {
        flex: 4,
        width: "100%",
        backgroundColor: this.state.info?.colore_museo,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      calendarBox: {
        width: "85%",
        height: "70%",
        borderRadius: 15,
        backgroundColor: colors.white,
        boxShadow: "2px 0 4px 0 rgb(0 0 0 / 20%)",
        marginTop: 20,
        display: "flex",
        justifyContent: width > 1000 ? "space-around" : "center",
        alignItems: "center",
      },
      eventsBox: {
        width: "85%",
        height: "70%",
        marginTop: 20,
        display: "flex",
        alignItems: "center",
        borderRadius: 15,
      },
      label: {
        color: colors.darkgray,
        width: "80%",
        fontSize: 13,
        textAlign: "left",
      },
      menu: {
        border: "1px solid lightgray",
        width: "88%",
        marginTop: 10,
        marginBottom: 10,
        padding: 10,
        color: colors.darkgray,
        borderRadius: 5,
        backgroundColor: colors.white,
        outline: "none",
      },
      textInput: {
        borderRadius: 5,
        border: "1px solid lightgray",
        outline: "none",
        textAlign: "center",
        marginTop: 10,
        marginBottom: 10,
        padding: 10,
        width: "80%",
      },
      button: {
        outline: "none",
        border: 0,
        backgroundColor: "transparent",
        cursor: "pointer",
        boxShadow: "2px 0 4px 0 rgb(0 0 0 / 20%)",
      },
      bmBurgerButton: {
        position: "fixed",
        width: "36px",
        height: "30px",
        left: "36px",
        top: "36px",
      },
      bmBurgerBars: {
        background: "#373a47",
      },
      bmBurgerBarsHover: {
        background: "#a90000",
      },
      bmCrossButton: {
        height: "24px",
        width: "24px",
      },
      bmCross: {
        background: "#bdc3c7",
      },
      bmMenuWrap: {
        position: "fixed",
        height: "100%",
      },
      bmMenu: {
        background: "#373a47",
        padding: "2.5em 0 0",
        fontSize: "1.15em",
      },
      bmMorphShape: {
        fill: "#373a47",
      },
      bmItemList: {
        color: "#b8b7ad",
        padding: "0.8em 0 0",
        display: "flex",
        flexDirection: "column",
      },
      bmItem: {
        display: "inline-block",
      },
      bmOverlay: {
        background: "rgba(0, 0, 0, 0.3)",
      },
      menuButton: {
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        paddingTop: 15,
        paddingBottom: 15,
        width: "100%",
        color: "white",
        fontSize: 18,
        textDecoration: "none",
      },
      errorMessage: {
        color: colors.highlight,
        fontSize: 10,
        fontWeight: "bold",
        marginTop: -6,
      },
    };

    var eventsUri = `${process.env.REACT_APP_WEB_URL}/` + orgLink + "/eventi";
    var entrancesUri =
      `${process.env.REACT_APP_WEB_URL}/` + orgLink + "/ingressi";

    return loader ? (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ReactLoading
          type={"spinningBubbles"}
          color={colors.darkgray}
          height={50}
          width={50}
        />
      </div>
    ) : (
      <div style={styles.container}>
        <Menu styles={styles}>
          <a
            style={{ ...styles.menuButton, backgroundColor: "transparent" }}
            href={entrancesUri}
          >
            INGRESSI
          </a>
          <a
            style={{ ...styles.menuButton, backgroundColor: "black" }}
            href={eventsUri}
          >
            EVENTI
          </a>
        </Menu>
        <div style={width > 1400 ? styles.subcontainer : { height: "100%" }}>
          <div style={styles.museum}>
            <img
              alt="Logo"
              src={info?.logo.url}
              style={{
                width: 180,
                objectFit: "contain",
                margin: 10,
                marginBottom: height * 0.4,
              }}
            />
            {width < 1400 && (
              <Bounce style={{ marginBottom: 40, position: "absolute" }}>
                <img
                  alt="Logo"
                  src={arrow}
                  style={{ width: 18, objectFit: "contain" }}
                />
              </Bounce>
            )}
          </div>
          <div style={styles.content}>
            {step === 2 && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => this.setState({ step: 1 })}
              >
                <img
                  alt="Arrow icon"
                  src={titleColor === colors.white ? back : back_dark}
                  style={{
                    width: 13,
                    objectFit: "contain",
                    marginLeft: 30,
                    marginRight: 10,
                  }}
                />
                <p
                  style={{ color: titleColor, fontSize: 12, fontWeight: "600" }}
                >
                  Indietro
                </p>
              </div>
            )}
            <h1 style={{ color: titleColor, textAlign: "center", margin: 0 }}>
              {step === 1
                ? "Prenota un evento"
                : step === 2
                ? "Inserisci i tuoi dati"
                : response.status
                ? "Prenotazione effettuata"
                : ""}
            </h1>
            <div
              style={{
                ...styles.eventsBox,
                flexDirection: "column",
                width: width > 1200 ? width * 0.65 : width * 0.85,
                overflow: "scroll",
                backgroundColor: step > 1 && "white",
                justifyContent:
                  step > 1 && (width > 700 ? "space-around" : "center"),
              }}
            >
              {step === 1 &&
                events.map((event, key) => {
                  return (
                    <EventBoxOnline
                      key={key}
                      width={width}
                      event={event}
                      addFlag={false}
                      selectEvent={(event) => {
                        this.setState({
                          event: event,
                          dateSelected: new Date(event.ora_inizio),
                          selectedTime: moment(event.ora_inizio).format(
                            "HH:mm"
                          ),
                          step: 2,
                        });
                      }}
                      colors={colors}
                    />
                  );
                })}
              <div
                style={{
                  width: width < (step === 1 ? 700 : 1400) ? "90%" : "30%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  overflowY: "scroll",
                }}
              >
                {step === 2 && (
                  <p style={{ color: colors.darkgray, fontSize: 20 }}>
                    Prenotazione per l'evento <br />
                    <b>{event.nome_evento}</b>
                    <br />
                    del giorno{" "}
                    <b>
                      {moment(this.state.dateSelected).format("D MMMM yyyy")}
                    </b>{" "}
                    <br />
                    alle ore <b>{selectedTime}</b>
                  </p>
                )}
                {calendarLoader ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <ReactLoading
                      type={"spinningBubbles"}
                      color={colors.white}
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <form
                    style={{
                      width: "100%",
                      maxHeight: height * 0.4,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      overflowY: "scroll",
                      marginTop: 20,
                    }}
                  >
                    {step === 2 && (
                      <>
                        <label style={styles.label}>Nome</label>
                        <input
                          type="text"
                          name="name"
                          value={name}
                          onChange={(event) =>
                            this.setState({ name: event.target.value })
                          }
                          placeholder="Nome"
                          style={{ ...styles.textInput }}
                        />
                        <label style={styles.label}>Cognome</label>
                        <input
                          type="text"
                          name="surname"
                          value={surname}
                          onChange={(event) =>
                            this.setState({ surname: event.target.value })
                          }
                          placeholder="Cognome"
                          style={{ ...styles.textInput }}
                        />
                        <label style={styles.label}>Numero partecipanti</label>
                        <input
                          type="number"
                          name="number"
                          value={number}
                          onChange={(e) =>
                            event.posti_prenotati + parseInt(e.target.value) >
                            event.posti_totali
                              ? this.setState({ errorNumber: true, number: "" })
                              : this.setState({
                                  number: e.target.value,
                                  errorNumber: false,
                                })
                          }
                          placeholder="Numero partecipanti"
                          style={{ ...styles.textInput }}
                        />
                        {errorNumber && (
                          <p style={styles.errorMessage}>
                            Solo {event.posti_totali - event.posti_prenotati}{" "}
                            posti disponibili.
                          </p>
                        )}
                        <label style={styles.label}>Email</label>
                        <input
                          type="email"
                          name="email"
                          value={email}
                          onChange={(event) => {
                            this.setState({ email: event.target.value });
                            this.validateEmail(event.target.value);
                          }}
                          placeholder="Email"
                          style={{ ...styles.textInput }}
                        />
                        {showError && !isEmail && (
                          <p
                            style={{
                              color: colors.highlight,
                              marginTop: 2,
                              fontSize: 10,
                              width: "72%",
                            }}
                          >
                            Email non valida
                          </p>
                        )}

                        <label style={styles.label}>Telefono</label>
                        <input
                          type="tel"
                          name="mobile"
                          value={mobile}
                          onChange={(event) =>
                            this.setState({
                              mobile: event.target.value.replace(/[^\d]/, ""),
                            })
                          }
                          placeholder="Cellulare"
                          style={{ ...styles.textInput }}
                        />
                      </>
                    )}
                    {step === 3 &&
                      (loaderCreation ? (
                        <ReactLoading
                          type={"spinningBubbles"}
                          color={info?.colore_museo}
                          height={50}
                          width={50}
                        />
                      ) : (
                        <div>
                          <img
                            alt="Logo"
                            src={info?.logo.url}
                            style={{
                              width: 120,
                              objectFit: "contain",
                              margin: 10,
                            }}
                          />
                          <p style={{ color: colors.darkgray }}>
                            {response.message}
                          </p>
                        </div>
                      ))}
                    {!loaderCreation && step > 1 && (
                      <button
                        type="button"
                        style={{
                          ...styles.button,
                          width: 200,
                          backgroundColor: colors.darkgray,
                          color: colors.white,
                          padding: 10,
                          borderRadius: 15,
                          margin: 30,
                          opacity: (
                            step === 2
                              ? name === "" ||
                                surname === "" ||
                                number === "" ||
                                number === "0" ||
                                email === "" ||
                                mobile === ""
                              : selectedTime === ""
                          )
                            ? 0.5
                            : 1,
                        }}
                        disabled={
                          step === 2
                            ? name === "" ||
                              surname === "" ||
                              number === "" ||
                              number === "0" ||
                              email === "" ||
                              mobile === ""
                            : selectedTime === ""
                        }
                        onClick={() => this.onClickButton()}
                      >
                        {step === 1
                          ? "SELEZIONA"
                          : step === 2
                          ? "PRENOTA"
                          : "NUOVA PRENOTAZIONE"}
                      </button>
                    )}
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
