let museum = {
  primary: "#55433B",
  secondary: "#BDA79E",
  tertiary: "#ded3ce",
  quaternary: "#f5f1f0",
  quinquary: "#332823",
  highlight: "#800000",
  lightred: "#bf7f7f",
  verylightgray: "#f6f6f6",
  lightgray: "#BEBEBE",
  gray: "#A9A9A9",
  warning: "#DAA520",
  lightwarning: "#e8c979",
  mediumgray: "#444444",
  darkgray: "#303030",
  white: "#FFFFFF",
  historyHighlight: "#a88b80",
  darkHistoryHighlight: "#826559",
  inventoryItemHighlight: "#F5F5F5",
  green: "#a0c2a0",
  red: "#c36666",
  background: "#FFF7EC",
  darkBackground: "#edebe6",
};

module.exports = museum;
