import { authenticationService } from "../_services";

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        authenticationService.logout();
      }

      return Promise.reject(data);
    }

    return data;
  });
}

export function handleRegistrationResponse(response) {
  if (response.ok) {
    return true;
  }

  return Promise.reject(response);
}

export function handleResponseWithoutError(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      return Promise.reject(data);
    }
    return data;
  });
}

export function handleAxiosResponse(response) {
  const data = response.data;
  if (response.statusText !== "OK") {
    if ([401, 403].indexOf(response.status) !== -1) {
      // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
      authenticationService.logout();
    }

    return Promise.reject(data);
  }

  return Promise.resolve(data);
}

export function handleFailure(error) {
  // TODO implement some error handling
  return error;
}
