import config from "../config";
import { handleResponse, authHeader } from "../_helpers";

export const interventionService = {
  createRecord,
  deleteRecord,
  getRecords,
  updateRecord,
  countRecords,
};

function createRecord(organisation, titolo, segnalazione) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({ organisation, titolo, segnalazione }),
  };

  return fetch(`${config.api.url}/intervention-registers`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteRecord(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/intervention-registers/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function getRecords(start, limit, searchKey, statusFilter, organisation) {
  const _start = start === undefined ? 0 : start;
  const _limit = limit === undefined ? -1 : limit;
  const _searchKey = searchKey === undefined ? "" : searchKey;

  var request = `${config.api.url}/intervention-registers?organisation=${organisation}&_start=${_start}&_limit=${_limit}&_sort=id:DESC`;

  if (_searchKey !== "") {
    request += `&titolo_contains=${_searchKey}`;
  }

  if (statusFilter !== null) {
    request += `&stato_contains=${statusFilter}`;
  }

  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(request, requestOptions).then(handleResponse);
}

function updateRecord(id, param) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(param),
  };

  return fetch(`${config.api.url}/intervention-registers/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return false;
    });
}

function countRecords(searchKey, statusFilter, organisation) {
  const _searchKey = searchKey === undefined ? "" : searchKey;

  var request = `${config.api.url}/intervention-registers/count?organisation=${organisation}`;

  if (_searchKey !== "") {
    request += `&titolo_contains=${_searchKey}`;
  }

  if (statusFilter !== null) {
    request += `&stato_contains=${statusFilter}`;
  }

  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(request, requestOptions).then(handleResponse);
}
