import config from "../config";
import { handleResponse, authHeader } from "../_helpers";

export const settingsService = {
  getSettings,
  getSettingsFromId,
  getMuseumFromId,
  updateSetting,
  updateActivitiesSetting,
  updateEmailList,
  updateInfoMuseum,
  updateClosingDays,
  updateInfoNetwork,
  upload,
  getTimetable,
  updateTimetable,
  checkReservations,
  getOrganisation,
  getOrganisationFromId,
  getMuseumInfo,
  getNetworkInfo,
  getDiscounts,
  createDiscount,
  deleteDiscount,
  getAllMuseums,
  checkDiscount,
  toggleDiscountAvailability,
  getMuseum,
  getSettingsByOrgName,
};

function getMuseum() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/museis`, requestOptions).then(handleResponse);
}

function getSettings() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/settings`, requestOptions).then(
    handleResponse
  );
}

function getSettingsFromId(orgId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/settings/${orgId}`, requestOptions).then(
    handleResponse
  );
}

function getTimetable() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/museis/orario`, requestOptions).then(
    handleResponse
  );
}

function getOrganisation() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/organisations`, requestOptions).then(
    handleResponse
  );
}

function getOrganisationFromId(orgId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/organisations/${orgId}`, requestOptions).then(
    handleResponse
  );
}

function getAllMuseums() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/organisations/musei`, requestOptions).then(
    handleResponse
  );
}

function getMuseumFromId(identificativo) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/museis/${identificativo}`,
    requestOptions
  ).then(handleResponse);
}

function getMuseumInfo() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/museis`, requestOptions).then(handleResponse);
}

function getNetworkInfo() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/retis`, requestOptions).then(handleResponse);
}

function getDiscounts() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/codici_scontos`, requestOptions).then(
    handleResponse
  );
}

function updateInfoMuseum(
  nome,
  indirizzo_museo,
  CAP_museo,
  citta_museo,
  email_museo,
  numero_telefono_museo,
  descrizione_museo,
  durata_visita_min,
  frequenza_slot_min,
  capienza_massima,
  colore
) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      nome,
      indirizzo_museo,
      CAP_museo,
      citta_museo,
      email_museo,
      numero_telefono_museo,
      descrizione_museo,
      durata_visita_min,
      frequenza_slot_min,
      capienza_massima,
      colore,
    }),
  };

  return fetch(`${config.api.url}/museis`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function updateClosingDays(chiusure_straordinarie) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      chiusure_straordinarie,
    }),
  };

  return fetch(`${config.api.url}/museis`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function updateInfoNetwork(nome) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      nome,
    }),
  };

  return fetch(`${config.api.url}/retis`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function updateSetting(
  biglietti_con_codice,
  profilazione_visitatori,
  avviso_articoli_esaurimento,
  giacenza_limite,
  email_dipendenti,
  flag_email_report,
  email_report,
  strict_slot_capacity
) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      biglietti_con_codice,
      profilazione_visitatori,
      avviso_articoli_esaurimento,
      giacenza_limite,
      email_dipendenti,
      flag_email_report,
      email_report,
      strict_slot_capacity,
    }),
  };

  return fetch(`${config.api.url}/settings`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function updateActivitiesSetting(
  tempo_preparazione,
  richiesta_bloccante,
  spazio_bloccante,
  conferma_unica,
  conferma_al_feedback,
  info_pagamento,
  email_amministrazione
) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      tempo_preparazione,
      richiesta_bloccante,
      spazio_bloccante,
      conferma_unica,
      conferma_al_feedback,
      info_pagamento,
      email_amministrazione,
    }),
  };

  return fetch(`${config.api.url}/settings/activity`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function updateEmailList(email_report) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      email_report,
    }),
  };

  return fetch(`${config.api.url}/settings`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function checkReservations(orari_museo) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      orari_museo,
    }),
  };

  return fetch(`${config.api.url}/museis/checkVisite`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function createDiscount(codice, sconto, categorie) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      codice,
      sconto,
      categorie,
    }),
  };

  return fetch(`${config.api.url}/codici_scontos`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function toggleDiscountAvailability(id, flag_abilitato) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      flag_abilitato,
    }),
  };

  return fetch(`${config.api.url}/codici_scontos/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function checkDiscount(codice) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };

  return fetch(
    `${config.api.url}/codici_scontos/?codice=${codice}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteDiscount(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/codici_scontos/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function updateTimetable(orari_museo) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      orari_museo,
    }),
  };

  return fetch(`${config.api.url}/museis/orario`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function upload(formData) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
    },
    body: formData,
  };

  return fetch(`${config.api.url}/upload`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function getSettingsByOrgName(name) {
  name = encodeURIComponent(name);
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/organisations/settingsByOrg/${name}`,
    requestOptions
  ).then(handleResponse);
}
