import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../contexts";
import EventCategory from "./eventCategory";
import {
  eventsService,
  reservationsService,
  settingsService,
} from "../../../_services";
import add from "./../../../img/add.png";
import moment from "moment";
import ReactLoading from "react-loading";
import PopupManager from "./popupManager";
import ShowEvent from "./showEvent";

const EventsManager = ({ props }) => {
  const [categoriesList, setCategoriesList] = useState([]); // list of categories
  const [neatCategoriesList, setNeatCategoriesList] = useState([]);
  const [addTickets, setAddTickets] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [showAddSchema, setShowAddSchema] = useState(false);
  const [showCopySchema, setShowCopySchema] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDisableCategory, setShowDisableCategory] = useState(false);
  const [showModifyCategory, setShowModifyCategory] = useState(false);
  const [showDeleteEvent, setShowDeleteEvent] = useState(false);
  const [showGenerate, setShowGenerate] = useState(false);
  const [eventSelected, setEventSelected] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const [endOperation, setEndOperation] = useState(false); //if end operation popup is shown or not
  const [operationResult] = useState(true); //outcome of CRUD operations
  const [operation, setOperation] = useState(null);
  const [event, setEvent] = useState(null);
  const [closingDays, setClosingDays] = useState([]);
  const [loader, setLoader] = useState(true);
  const [schemaEventOnDelete, setSchemaEventOnDelete] = useState(null);
  const [hover, setHover] = useState(false);
  const colors = useContext(UserContext)?.colors;

  const [flagModify, setFlagModify] = useState(false);

  const [sideBarLoader, setSideBarLoader] = useState(false);
  const [imgLoader, setImgLoader] = useState(false);
  const [invitationLoader, setInvitationLoader] = useState(false);

  //Flag used to check if the selected event has at least one child event with waiting list
  const [waitingList, setWaitingList] = useState(false);

  //After modifying an event, if the waitingList flag is set true, ask the user if (s)he wants to make bookings flow from the waiting lists
  const [showWaitingListPopup, setShowWaitingListPopup] = useState(false);
  const [waitingLoader, setWaitingLoader] = useState(false);

  const [waitingNum, setWaitingNum] = useState(0);

  useEffect(() => {
    if (event !== null) {
      setFlagModify(false);
      if (addTickets === true) {
        setFlagModify(true);
      }
      if (showCopySchema) {
        setFlagModify(true);
      }
    } else if (showAddSchema) {
      setFlagModify(true);
    } else {
      setFlagModify(false);
    }
  }, [event, addTickets, showCopySchema, showAddSchema]);

  useEffect(() => {
    if (event && event?.flag_attesa) {
      let check = event.eventi.some((ev) => {
        return ev.lista_attesa.length > 0;
      });
      setWaitingList(check);
    } else {
      setWaitingList(false);
    }
  }, [event]);

  useEffect(() => {
    const getEvents = () => {
      eventsService
        .getEventCategories()
        .then((categoriesList) => {
          setCategoriesList(categoriesList);
          let tempList = [];
          for (let i = 0; i < categoriesList.length; i++) {
            let temp = {};
            temp.id = categoriesList[i].id;
            temp.name = categoriesList[i].nome_categoria;
            tempList.push(temp);
          }
          setNeatCategoriesList(tempList);
          setLoader(false);
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            navigate();
          }
        });
    };

    const getClosingDays = (date) => {
      eventsService
        .getClosingDay(date)
        .then((closingDays) => setClosingDays(closingDays))
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            navigate();
          }
        });
    };

    const navigate = async () => {
      props.history.push("/");
    };

    getEvents();
    getClosingDays(moment(new Date()).format("yyyy-MM-DD"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showAddCategory) {
      setOperation(0);
    }
  }, [showAddCategory]);

  useEffect(() => {
    if (showAddSchema) {
      setOperation(0);
    } else {
      setEventSelected(null);
    }
  }, [showAddSchema]);

  useEffect(() => {
    if (showModifyCategory) {
      setOperation(1);
    }
  }, [showModifyCategory]);

  useEffect(() => {
    if (showDisableCategory) {
      setOperation(7);
    }
  }, [showDisableCategory]);

  useEffect(() => {
    if (showDelete) {
      setOperation(2);
    }
  }, [showDelete]);

  useEffect(() => {
    if (showDeleteEvent) {
      setOperation(2);
    } else {
      setEventSelected(null);
    }
  }, [showDeleteEvent]);

  const onAddCategory = (newCategory) => {
    let nome_categoria = newCategory;
    eventsService
      .createCategory(nome_categoria)
      .then((newCategoriesList) => {
        let tempList = [];
        for (let i = 0; i < newCategoriesList.length; i++) {
          let temp = {};
          temp.id = newCategoriesList[i].id;
          temp.name = newCategoriesList[i].nome_categoria;
          tempList.push(temp);
        }

        setCategoriesList(newCategoriesList);
        setNeatCategoriesList(tempList);
        toggleAddCategory();
        setEndOperation(true);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onAddTicketsType = (nome, prezzo, disponibilita, id_evento) => {
    eventsService
      .createTicketsType(nome, prezzo, disponibilita, id_evento)
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          // props.history.push("/");
        }
      });
  };

  const onAddSchema = (
    nome_evento,
    titolo_email,
    luogo_evento,
    posti_totali,
    descrizione_evento,
    categoria_evento,
    gestione_esterna,
    link_esterno,
    ora_inizio,
    ora_fine,
    giorni_ripetizione,
    flag_bambini,
    prenotabile,
    colore,
    ticketsType,
    prenotabile_card,
    pathToUpload,
    uniqueEvent,
    eventImage,
    ticketsList,
    flag_attesa
  ) => {
    eventsService
      .createEvent(
        nome_evento,
        titolo_email,
        luogo_evento,
        parseInt(posti_totali),
        descrizione_evento,
        categoria_evento,
        gestione_esterna,
        link_esterno,
        ora_inizio,
        ora_fine,
        giorni_ripetizione,
        flag_bambini,
        prenotabile,
        prenotabile_card,
        colore,
        ticketsType,
        uniqueEvent,
        eventImage,
        flag_attesa
      )
      .then((newCategoriesList) => {
        setSideBarLoader(false);

        let selectedEvent;
        for (let i = 0; i < newCategoriesList.length; i++) {
          let temp = newCategoriesList[i].lista_eventi.find(
            (event) =>
              event.nome_evento === nome_evento &&
              new Date(event.ora_inizio).toUTCString() ===
                new Date(ora_inizio).toUTCString()
          );
          if (temp !== undefined) {
            selectedEvent = temp;
          }
        }
        if (pathToUpload) {
          uploadImg(selectedEvent.id, pathToUpload, true, categoria_evento);
        }

        setCategoriesList(newCategoriesList);
        setShowAddSchema(false);
        toggleAddSchema(categoria_evento);
        setEndOperation(false);
        setAddTickets(true);
        setEvent(selectedEvent);

        if (showCopySchema) {
          //If ticketList.length > 0, it means that we are copying an event
          if (ticketsList.length > 0) {
            ticketsList.forEach((ticket) => {
              onAddTicketsType(
                ticket.nome,
                ticket.prezzo,
                ticket.disponibilita,
                selectedEvent.id
              );
            });
          }
          closeInfo();
        }
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onModifyCategory = (newName) => {
    let id = eventSelected.id;
    let nome_categoria = newName;
    eventsService
      .modifyCategory(id, nome_categoria)
      .then((newCategoriesList) => {
        setCategoriesList(newCategoriesList);
        toggleModifyCategory(null);
        setEndOperation(true);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onModifySchema = (
    id,
    nome_evento,
    titolo_email,
    luogo_evento,
    posti_totali,
    descrizione_evento,
    categoria_evento,
    gestione_esterna,
    link_esterno,
    ora_inizio,
    ora_fine,
    giorni_ripetizione,
    flag_bambini,
    prenotabile,
    colore,
    ticketsType,
    prenotabile_card,
    flag_attesa
  ) => {
    if (addTickets) {
      setOperation(0);
    } else {
      setOperation(1);
    }
    eventsService
      .modifyEvent(
        id,
        nome_evento,
        titolo_email,
        luogo_evento,
        parseInt(posti_totali),
        descrizione_evento,
        categoria_evento,
        gestione_esterna,
        link_esterno,
        ora_inizio,
        ora_fine,
        giorni_ripetizione,
        flag_bambini,
        prenotabile,
        colore,
        prenotabile_card,
        ticketsType,
        flag_attesa
      )
      .then((newCategoriesList) => {
        setCategoriesList(newCategoriesList);
        if (event.posti_totali < posti_totali && waitingList) {
          setWaitingNum(posti_totali - event.posti_totali);
          setShowWaitingListPopup(true);
        } else {
          setEndOperation(true);
          setEvent(null);
          setAddTickets(false);
          setShowAddSchema(false);
          setShowWaitingListPopup(false);
        }
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onDisableCategory = () => {
    let id = eventSelected.id;
    eventsService
      .disableEventCategory(id)
      .then((newCategoriesList) => {
        setShowDisableCategory(false);
        setCategoriesList(newCategoriesList);
        toggleDisableCategory(null);
        setEndOperation(true);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onDeleteSchema = () => {
    let id = eventSelected.id;
    eventsService
      .deleteSchemaEvent(id)
      .then((newCategoriesList) => {
        setShowDelete(false);
        setCategoriesList(newCategoriesList);
        toggleDeleteSchema(null);
        setEndOperation(true);
        setEvent(null);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onDeleteTicketsType = (id) => {
    eventsService
      .deleteTicketsType(id)
      .then((res) => {
        return res;
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onGenerateInvitations = (num) => {
    setInvitationLoader(true);
    const num_ingressi = 2;
    let id = eventSelected.id;
    reservationsService
      .createEventInvitations(id, num, num_ingressi)
      .then((response) => {
        var a = document.createElement("a");
        a.href = response.url;
        a.target = "_blank";
        a.download = "inviti.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
        setShowGenerate(false);
        setEventSelected(null);
        setInvitationLoader(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onDeleteEvent = () => {
    eventsService
      .deleteEvent(eventSelected.id)
      .then((newCategoriesList) => {
        setCategoriesList(newCategoriesList);
        let retrievedSchema = null;
        for (let i = 0; i < newCategoriesList.length; i++) {
          newCategoriesList[i].lista_eventi.find(
            (event) => event.id === schemaEventOnDelete.id
          );
          if (
            newCategoriesList[i].lista_eventi.find(
              (event) => event.id === schemaEventOnDelete.id
            )
          ) {
            retrievedSchema = newCategoriesList[i].lista_eventi.find(
              (event) => event.id === schemaEventOnDelete.id
            );
          }
        }
        setShowDeleteEvent(false);
        toggleDeleteEvent();
        setEndOperation(true);
        setEvent(retrievedSchema);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onHandleWaitingList = () => {
    setWaitingLoader(true);
    reservationsService
      .shiftWaitingList(event.id, waitingNum)
      .then(() => {
        eventsService
          .getEventCategories()
          .then((categoriesList) => {
            setCategoriesList(categoriesList);
            let tempList = [];
            for (let i = 0; i < categoriesList.length; i++) {
              let temp = {};
              temp.id = categoriesList[i].id;
              temp.name = categoriesList[i].nome_categoria;
              tempList.push(temp);
            }
            setNeatCategoriesList(tempList);
            setWaitingLoader(false);
            setEndOperation(true);
            setEvent(null);
            setAddTickets(false);
            setShowAddSchema(false);
            setShowWaitingListPopup(false);
          })
          .catch((errors) => {
            if (errors.statusCode === 401 || errors.statusCode === 403) {
              props.history.push("/");
            }
          });
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const downloadRes = (id) => {
    setLoader(true);
    reservationsService
      .getResDoc(id)
      .then((file) => {
        setLoader(false);
        var a = document.createElement("a");
        a.href = file.url;
        a.target = "_blank";
        a.download = "prenotazioni.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const toggleAddCategory = () => {
    setShowAddCategory(!showAddCategory);
  };

  const toggleAddSchema = (category, forceNotShowAddSchema = false) => {
    setShowAddSchema(forceNotShowAddSchema ? false : !showAddSchema);
    setCategorySelected(category);
  };

  const toggleModifyCategory = (cat) => {
    setEventSelected(cat);
    setShowModifyCategory(!showModifyCategory);
  };

  const toggleDisableCategory = (cat) => {
    setEventSelected(cat);
    setShowDisableCategory(!showDisableCategory);
  };

  const toggleDeleteSchema = (event) => {
    setEventSelected(event);
    setShowDelete(!showDelete);
  };

  const toggleDeleteEvent = (event, schemaEvent) => {
    setEventSelected(event);
    setShowDeleteEvent(!showDeleteEvent);
    setSchemaEventOnDelete(schemaEvent);
  };

  const toggleEndOperation = () => {
    setEndOperation(!endOperation);
  };

  const toggleShowGenerate = (event) => {
    setInvitationLoader(!invitationLoader);
    setEventSelected(event);
    setShowGenerate(!showGenerate);
  };

  const toggleInfo = (eventChosen) => {
    if (event === eventChosen) {
      setEvent(null);
      setShowAddSchema(false);
      setShowCopySchema(false);
    } else {
      if (!showAddSchema) {
        setEvent(eventChosen);
        setShowAddSchema(false);
        setShowCopySchema(false);
      }
    }
  };

  const toggleCopy = (eventChosen) => {
    if (event === eventChosen) {
      setEvent(null);
      setShowAddSchema(false);
      setShowCopySchema(false);
    } else if (!showAddSchema) {
      setEvent(eventChosen);
      setShowAddSchema(false);
      setShowCopySchema(true);
    }
  };

  const toggleHideWaiting = () => {
    setEndOperation(true);
    setEvent(null);
    setAddTickets(false);
    setShowAddSchema(false);
    setShowWaitingListPopup(false);
  };

  const closeInfo = () => {
    setEvent(null);
    setShowAddSchema(false);
    setShowCopySchema(false);
    setAddTickets(false);
  };

  const uploadImg = (id, img, isCreation, eventCategory = null) => {
    setImgLoader(true);

    const formData = new FormData();
    formData.append("files", img);
    formData.append("refId", id);
    formData.append("ref", "schema_eventi");
    formData.append("field", "immagine_evento");

    settingsService
      .upload(formData)
      .then((event) => {
        if (event !== false) {
          eventsService.getEventCategories().then((categoriesList) => {
            let selectedEvent;
            for (let i = 0; i < categoriesList.length; i++) {
              let temp = categoriesList[i].lista_eventi.find(
                (event) => event.id === id
              );
              if (temp !== undefined) {
                selectedEvent = temp;
              }
            }
            setCategoriesList(categoriesList);
            if (event) {
              setEvent(selectedEvent);
            }
            let tempList = [];
            for (let i = 0; i < categoriesList.length; i++) {
              let temp = {};
              temp.id = categoriesList[i].id;
              temp.name = categoriesList[i].nome_categoria;
              tempList.push(temp);
            }
            setNeatCategoriesList(tempList);
            setLoader(false);
            if (isCreation) {
              toggleAddSchema(eventCategory, true);
              setEndOperation(!isCreation);
            }

            setImgLoader(false);
            return true;
          });
        } else {
          setImgLoader(false);
          return false;
        }
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    mainView: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      overflowX: "scroll",
    },
    button: {
      backgroundColor: colors?.primary,
      width: 80,
      height: 80,
      borderRadius: 50,
      alignItems: "center",
      outline: "none",
      border: "none",
      justifyContent: "center",
      cursor: "pointer",
    },
    buttonBox: {
      outline: "none",
      border: "none",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      zIndex: 200,
      right: 40,
      bottom: 40,
    },
    hoverLabel: {
      textAlign: "center",
      fontSize: 12,
      maxWidth: 80,
    },
  };

  return (
    <div style={styles.container}>
      {loader ? (
        <ReactLoading
          type={"spinningBubbles"}
          color={colors?.primary}
          height={50}
          width={50}
        />
      ) : (
        <div style={styles.mainView}>
          {categoriesList.length > 0 &&
            categoriesList
              .sort((a, b) => {
                const nameA = a.nome_categoria.toUpperCase();
                const nameB = b.nome_categoria.toUpperCase();
                if (nameA > nameB) {
                  return 1;
                }
                if (nameA < nameB) {
                  return -1;
                }
                return 0;
              })
              .map((cat, key) => {
                if (cat.active) {
                  return (
                    <EventCategory
                      key={key}
                      onDelete={() => toggleDisableCategory(cat)}
                      onModify={() => toggleModifyCategory(cat)}
                      onAddSchema={() => toggleAddSchema(cat)}
                      toggleCopy={toggleCopy}
                      category={cat}
                      eventsList={cat.lista_eventi}
                      toggleInfo={toggleInfo}
                      colors={colors}
                    />
                  );
                }

                // Add a return statement for the case when cat.active is false
                return null;
              })}
        </div>
      )}
      <PopupManager
        showAddCategory={showAddCategory}
        showModifyCategory={showModifyCategory}
        showDisableCategory={showDisableCategory}
        showDeleteEvent={showDeleteEvent}
        showDelete={showDelete}
        endOperation={endOperation}
        showGenerate={showGenerate}
        showWaitingListPopup={showWaitingListPopup}
        toggleHideWaiting={toggleHideWaiting}
        colors={colors}
        toggleAddCategory={toggleAddCategory}
        onAddCategory={onAddCategory}
        eventSelected={eventSelected}
        toggleModifyCategory={toggleModifyCategory}
        onModifyCategory={onModifyCategory}
        toggleDisableCategory={toggleDisableCategory}
        onDisableCategory={onDisableCategory}
        toggleDeleteEvent={toggleDeleteEvent}
        onDeleteEvent={onDeleteEvent}
        toggleDeleteSchema={toggleDeleteSchema}
        onDeleteSchema={onDeleteSchema}
        operationResult={operationResult}
        operation={operation}
        toggleEndOperation={toggleEndOperation}
        toggleShowGenerate={toggleShowGenerate}
        onGenerateInvitations={onGenerateInvitations}
        invitationLoader={invitationLoader}
        waitingLoader={waitingLoader}
        onHandleWaitingList={onHandleWaitingList}
      />
      <div
        style={styles.buttonBox}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {hover && <p style={styles.hoverLabel}>Crea nuova categoria eventi</p>}
        <button style={styles.button} onClick={() => toggleAddCategory()}>
          <img
            alt="Add icon"
            src={add}
            style={{ width: 35, objectFit: "contain" }}
          />
        </button>
      </div>
      {(event !== null || showAddSchema) && (
        <ShowEvent
          closingDays={closingDays}
          event={event}
          categories={neatCategoriesList}
          categorySelected={categorySelected}
          onDeleteEvent={toggleDeleteEvent}
          onDeleteSchema={toggleDeleteSchema}
          onModifySchema={onModifySchema}
          onCreateSchema={onAddSchema}
          onDeleteTicketsType={onDeleteTicketsType}
          closeInfo={closeInfo}
          flagModify={flagModify}
          flagAdd={showAddSchema}
          onShowGenerate={toggleShowGenerate}
          onDownloadRes={downloadRes}
          uploadImg={uploadImg}
          colors={colors}
          addTickets={addTickets}
          isCopying={showCopySchema}
          barLoader={sideBarLoader}
          imageLoader={imgLoader}
        />
      )}
    </div>
  );
};

export default EventsManager;
