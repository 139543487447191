import React, { useState } from "react";
import close from "./../../../img/closeButton.png";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import it from "date-fns/locale/it";
import "./../../../customizer.css";
import ReactLoading from "react-loading";
import Switch from "react-switch";

registerLocale("it", it);

const CustomInput = ({ value, onClick }) => (
  <button className="custom-input" onClick={onClick}>
    {value}
  </button>
);

const defaultDate = "1970-01-01T00:00:00.000Z";

const ModifyPurchase = ({ purchase, closePurchaseModal, saveData, colors }) => {
  const [price, setPrice] = useState(
    purchase.acquisti_articoli.prezzo_articolo
  );
  const [isEnabled, setEnabled] = useState(false);
  const [unitaryCost, setUnitaryCost] = useState(purchase.costo_articolo);
  const [percentage, setPercentage] = useState(purchase.percentuale_guadagno);
  const [purchaseMethod, setPurchaseMethod] = useState(
    purchase.modalita_acquisto !== null ? purchase.modalita_acquisto.id : null
  );
  const [deadline, setDeadline] = useState(
    new Date(
      purchase.scadenza_conto_vendita !== defaultDate &&
      purchase.scadenza_conto_vendita !== null
        ? purchase.scadenza_conto_vendita
        : moment().format()
    )
  );
  const [seller, setSeller] = useState(purchase.venditore);
  const [quantity, setQuantity] = useState(purchase.quantita);
  const [loader, setLoader] = useState(false);
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 30,
      margin: "auto",
      width: 600,
      zIndex: 300,
      justifyContent: "center",
    },
    loaderBox: {
      display: "flex",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      zIndex: 300,
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      display: "flex",
      width: "100%",
      height: 60,
      backgroundColor: colors.primary,
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    content: {
      width: "100%",
      maxHeight: 300,
      flexDirection: "column",
      backgroundColor: colors.white,
      display: "flex",
      alignItems: "center",
      overflow: "scroll",
    },
    textInput: {
      border: "1px solid lightgray",
      borderRadius: 10,
      width: "100%",
      padding: 18,
      outline: "none",
    },
    field: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginTop: 20,
    },
    footer: {
      display: "flex",
      width: "100%",
      height: 120,
      backgroundColor: colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      marginTop: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    disabledButton: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      marginTop: 40,
      border: "none",
      outline: "none",
      opacity: 0.7,
    },
    menu: {
      border: "1px solid lightgray",
      width: "100%",
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 10,
      color: colors.darkgray,
      borderRadius: 20,
    },
    label: {
      fontSize: 15,
      color: colors.lightgray,
      margin: 20,
      width: 400,
      textAlign: "left",
    },
    box: {
      width: 400,
      display: "flex",
    },
    halfLabel: {
      fontSize: 15,
      color: colors.lightgray,
      margin: 20,
      width: "100%",
      textAlign: "left",
    },
    halfBox: {
      width: "100%",
      display: "flex",
    },
  };
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: colors.gray,
          opacity: 0.7,
        }}
      />
      {loader ? (
        <div style={styles.loaderBox}>
          <ReactLoading
            type={"spinningBubbles"}
            color={colors.primary}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <div style={styles.container}>
          <div style={styles.header}>
            <p style={{ color: colors.white, fontSize: 20 }}>MODIFICA CARICO</p>
            <button
              onClick={closePurchaseModal}
              style={{
                position: "absolute",
                right: 30,
                marginTop: "auto",
                marginBottom: "auto",
                border: "none",
                backgroundColor: "transparent",
                outline: "none",
                cursor: "pointer",
              }}
            >
              <img
                alt="Close"
                src={close}
                style={{ width: 15, objectFit: "contain" }}
              />
            </button>
          </div>
          <div id="scroll" style={styles.content}>
            <div style={{ display: "flex", flexDirection: "row", width: 400 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginTop: 20,
                  flex: 2,
                  marginRight: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <label style={{ marginRight: 30 }}>
                    <input
                      type="radio"
                      value={1}
                      checked={purchaseMethod === 1}
                      onChange={(event) => {
                        setPurchaseMethod(parseInt(event.target.value));
                      }}
                      style={{ marginTop: 30, marginRight: 10 }}
                    />
                    Conto vendita
                  </label>
                  <label>
                    <input
                      type="radio"
                      value={2}
                      checked={purchaseMethod === 2}
                      onChange={(event) => {
                        setPurchaseMethod(parseInt(event.target.value));
                        setDeadline(new Date());
                      }}
                      style={{ marginTop: 30, marginRight: 10 }}
                    />
                    Acquisto
                  </label>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "row", width: 400 }}>
              {purchaseMethod === 1 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 20,
                    flex: 3,
                    marginRight: 20,
                  }}
                >
                  <p style={styles.halfLabel}>Scadenza conto vendita</p>
                  <div style={styles.halfBox}>
                    <DatePicker
                      selected={deadline ? deadline : new Date()}
                      onChange={(date) => setDeadline(date)}
                      dateFormat="dd/MM/yyyy"
                      minDate={moment()}
                      locale="it"
                      customInput={<CustomInput />}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div style={styles.field}>
              <p style={styles.label}>Venditore</p>
              <div style={styles.box}>
                <input
                  value={seller}
                  onChange={(event) => setSeller(event.target.value)}
                  placeholder="Venditore"
                  style={styles.textInput}
                />
              </div>
            </div>
            <div style={styles.field}>
              <p style={styles.label}>Quantità</p>
              <div style={styles.box}>
                <input
                  value={quantity}
                  onChange={(event) =>
                    setQuantity(event.target.value.replace(/[^\d]/, ""))
                  }
                  placeholder="Numero"
                  style={styles.textInput}
                />
              </div>
              {quantity !== "" &&
                quantity > 0 &&
                quantity < purchase.quantita - purchase.giacenza && (
                  <p
                    style={{
                      color: colors.highlight,
                      marginTop: 5,
                      fontSize: 12,
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    La quantità non può essere minore di{" "}
                    {purchase.quantita - purchase.giacenza}
                  </p>
                )}
              {quantity !== "" && parseInt(quantity) === 0 && (
                <p
                  style={{
                    color: colors.highlight,
                    marginTop: 5,
                    fontSize: 12,
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  La quantità deve essere maggiore di 0
                </p>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                marginTop: 30,
                width: 400,
              }}
            >
              <div style={{ flex: 3 }}>
                <p
                  style={{
                    ...styles.label,
                    width: "auto",
                    marginLeft: 0,
                    marginRight: 0,
                  }}
                >
                  Percentuale di guadagno
                </p>
                <div style={{ ...styles.box, width: "auto" }}>
                  {isEnabled ? (
                    <p>
                      {price !== ""
                        ? parseFloat(
                            (((price - unitaryCost) * 100) / price).toFixed(2)
                          )
                        : 0}
                      %
                    </p>
                  ) : (
                    <input
                      value={percentage}
                      onChange={(event) =>
                        setPercentage(event.target.value.replace(/[^0-9.]/, ""))
                      }
                      placeholder="Percentuale"
                      style={styles.textInput}
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  flex: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 50,
                }}
              >
                <Switch
                  onChange={() => {
                    setPrice(purchase.acquisti_articoli.prezzo_articolo);
                    setPercentage(
                      price !== ""
                        ? (((price - unitaryCost) * 100) / price).toFixed(2)
                        : 0
                    );
                    setUnitaryCost(
                      price !== ""
                        ? (price - (price * percentage) / 100).toFixed(2)
                        : 0
                    );
                    setEnabled(!isEnabled);
                  }}
                  checked={isEnabled}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor={"gray"}
                  width={30}
                  height={20}
                />
              </div>
              <div style={{ flex: 3 }}>
                <p
                  style={{
                    ...styles.label,
                    width: "auto",
                    marginLeft: 0,
                    marginRight: 0,
                  }}
                >
                  Costo unitario
                </p>
                <div style={{ ...styles.box, width: "auto" }}>
                  {isEnabled ? (
                    <input
                      value={unitaryCost}
                      onChange={(event) =>
                        setUnitaryCost(
                          event.target.value.replace(/[^0-9.]/, "")
                        )
                      }
                      placeholder="Costo"
                      style={styles.textInput}
                    />
                  ) : (
                    <p>€ {(price - (price * percentage) / 100).toFixed(2)}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div style={styles.footer}>
            <button
              onClick={() => {
                setLoader(true);
                let finalCost;
                let finalPercentage;
                let finalDeadline;
                finalCost = unitaryCost;
                finalPercentage = percentage;
                finalDeadline = deadline;
                if (finalCost !== purchase.costo_articolo) {
                  finalPercentage = ((price - finalCost) * 100) / price;
                }
                if (finalPercentage !== purchase.percentuale_guadagno) {
                  finalCost = (price - (price * finalPercentage) / 100).toFixed(
                    2
                  );
                }
                if (purchaseMethod === 2) {
                  //"Acquisto"
                  finalDeadline = null;
                }
                let newGiacenza =
                  purchase.giacenza - (purchase.quantita - quantity);
                let artCode = purchase.acquisti_articoli.codice_articolo;

                //save new data (only if cost >= 0, seller is present and new quantity is positive and >= {old quantity - old giacenza})
                saveData(
                  parseInt(purchaseMethod),
                  seller,
                  parseInt(quantity),
                  finalDeadline,
                  parseFloat(finalCost),
                  parseFloat(finalPercentage),
                  parseInt(newGiacenza),
                  artCode
                );
              }}
              style={
                unitaryCost < 0 ||
                unitaryCost === "" ||
                seller === "" ||
                quantity === "" ||
                parseInt(quantity) === 0 ||
                quantity < purchase.quantita - purchase.giacenza ||
                percentage === ""
                  ? styles.disabledButton
                  : styles.button
              }
              disabled={
                unitaryCost < 0 ||
                unitaryCost === "" ||
                seller === "" ||
                quantity === "" ||
                parseInt(quantity) === 0 ||
                quantity < purchase.quantita - purchase.giacenza ||
                percentage === ""
              }
            >
              MODIFICA
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ModifyPurchase;
