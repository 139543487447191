import React, { useState, useEffect } from "react";
import colors from "./../../../../museumColors";
import logo from "./../../../../img/logo_white.png";
import emma from "./../../../../img/emma.png";
import { usersService } from "../../../../_services";

const RedirectLogin = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isMobile, setIsMobile] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const confirmation = params.get("confirmation");

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (windowSize.innerWidth > 800) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize.innerWidth]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  function redirect(isSpeaker) {
    setShowError(false);
    usersService
      .confirmUser(confirmation)
      .then((response) => {
        if (isSpeaker) {
          window.location = `${process.env.REACT_APP_WEB_URL}/relatore/login`;
        } else {
          window.location = `${process.env.REACT_APP_WEB_URL}/dashboard`;
        }
      })
      .catch((errors) => {
        setShowError(true);
      });
  }

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      alignItems: "center",
      backgroundColor: colors.secondary,
      overflow: "scroll",
    },
    header: {
      display: isMobile ? "" : "flex",
      flex: isMobile ? 1 : 4,
      alignItems: "center",
      justifyContent: "center",
      marginTop: 30,
    },
    content: {
      display: "flex",
      width: "80%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 10,
    },
    buttonText: {
      color: colors.primary,
      fontStyle: "italic",
      fontSize: 13,
      width: "250px",
    },
    disclaimer: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      marginTop: isMobile ? "20px" : "",
      borderRadius: 20,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    message: {
      color: colors.white,
      fontWeight: 700,
      fontSize: 13,
      width: "100%",
      backgroundColor: "rgba(277,277,277,0.3)",
    },
  };

  return (
    <div style={styles.container}>
      <a style={styles.header} href="https://www.rnb4culture.com/emma/">
        <img
          alt="App logo"
          src={logo}
          style={{
            width: isMobile
              ? windowSize.innerWidth / 3
              : windowSize.innerWidth / 8,
            objectFit: "contain",
          }}
        />
      </a>
      <div style={styles.content}>
        <div
          style={{
            width: isMobile ? "90%" : "60%",
            height: isMobile ? "100%" : "87%",
            marginTop: isMobile ? 10 : 20,
            marginRight: isMobile ? 0 : 20,
            backgroundColor: colors.white,
            padding: 30,
            borderRadius: 10,
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="Presentation"
            src={emma}
            style={{ width: "25%", objectFit: "contain" }}
          />
          <h1 style={{ color: colors.primary }}>Benvenuto in EMMA</h1>
          <h3 style={{ color: colors.secondary, marginBottom: "50px" }}>
            A quale piattaforma vuoi accedere?
          </h3>
          {showError && (
            <div style={styles.message}>
              <p style={{ color: colors.red }}>
                ATTENZIONE: non é stato possibile confermare il tuo account.
                Verifica che il link sia corretto o contatta il museo
              </p>{" "}
            </div>
          )}
          <div style={styles.disclaimer}>
            <div>
              <button
                onClick={() => {
                  redirect(false);
                }}
                style={{ ...styles.button, paddingLeft: 30, paddingRight: 30 }}
              >
                MUSEO
              </button>
              <p style={styles.buttonText}>
                Fai click qui se sei un operatore museale
              </p>
            </div>
            {!isMobile && (
              <div
                style={{
                  height: "100%",
                  width: "2px",
                  backgroundColor: colors.primary,
                  margin: "50px",
                }}
              />
            )}
            <div>
              <button
                onClick={() => {
                  redirect(true);
                }}
                style={styles.button}
              >
                RELATORE
              </button>
              <p style={styles.buttonText}>Fai click qui se sei un relatore</p>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flex: 1 }}></div>
    </div>
  );
};

export default RedirectLogin;
