import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../../../../contexts";
import search from "../../../../img/search.png";
import add from "../../../../img/add_black.png";
import link from "../../../../img/link.png";
import completed from "../../../../img/completed.png";
import refresh from "../../../../img/refresh.png";
import { LanguageContext } from "../../../../containers/language";
import Table from "./table";
import SpeakerPage from "./speakerPage";
import ReactLoading from "react-loading";
import { activitiesService } from "../../../../_services/activities.service";
import Pagination from "react-js-pagination";

const itemsPerPageOptions = [10, 20, 50, 100];

const newSpeaker = {
  name: "",
  surname: "",
  mobile: "",
  email: "",
  description: "",
  color: "#FFFFFF",
  show_email: false,
  show_mobile: false,
  photo: null,
};

function isEmpty(field) {
  return field === "" || field === null || field === undefined;
}

function unsecuredCopyToClipboard(text) {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  try {
    document.execCommand("copy");
  } catch (err) {
    console.error("Unable to copy to clipboard", err);
  }
  document.body.removeChild(textArea);
}

const SpeakersManager = () => {
  const colors = useContext(UserContext)?.colors;
  const { dictionary } = useContext(LanguageContext);
  const [openRecord, setOpenRecord] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [tempSearchKey, setTempSearchKey] = useState("");
  const [speakers, setSpeakers] = useState([]);

  const [isCreation, setIsCreation] = useState(false);

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [speakersLoaded, setSpeakersLoaded] = useState(false);

  //Flag used to check if the link for self-registration of speakers has already been copied
  const [isCopied, setIsCopied] = useState(false);
  const me = useContext(UserContext)?.user;
  const linkCopied =
    `${process.env.REACT_APP_WEB_URL}/relatore/login/` + me?.org_museo;

  const getSpeakers = (start, limit, searchKey) => {
    setSpeakersLoaded(false);

    activitiesService
      .countSpeakers(searchKey, me.organisation)
      .then((allSpeakers) => {
        setTotalItemsCount(allSpeakers);
      });

    activitiesService
      .getSpeakers(start, limit, searchKey, me.organisation)
      .then((speakers) => {
        setSpeakers(speakers);
        setSpeakersLoaded(true);
      });
  };

  const startingSearch = () => {
    // reset searchKey
    let currentSearchKey = "";
    setSearchKey(currentSearchKey);

    let tempActivePage = 1;
    setActivePage(tempActivePage);

    getSpeakers(
      itemsPerPage * tempActivePage - itemsPerPage,
      itemsPerPage,
      currentSearchKey
    );
  };

  useEffect(() => {
    getSpeakers(
      itemsPerPage * activePage - itemsPerPage,
      itemsPerPage,
      searchKey
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, itemsPerPage]);

  useEffect(() => {
    const handleSubmit = () => {
      setSearchKey(tempSearchKey);

      let tempActivePage = 1;
      setActivePage(tempActivePage);
      getSpeakers(
        itemsPerPage * tempActivePage - itemsPerPage,
        itemsPerPage,
        tempSearchKey
      );
    };

    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, tempSearchKey]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      overflowX: "scroll",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    rowBottom: {
      display: "flex",
      justifyContent: "center",
      width: !isEmpty(openRecord) ? "100%" : "90%",
      height: "95%",
    },
    subcontainer: {
      width: "100%",
      height: "100%",
    },
    inputSearch: {
      borderRadius: 40,
      border: "1px solid #F0F0F0",
      width: "50%",
      height: 40,
      display: "flex",
      flexDirection: "row",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
    goBackButton: {
      width: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    saveButton: {
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: colors.darkgray,
      margin: 30,
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 15,
    },
    filterButton: {
      border: "1px solid #F0F0F0",
      outline: "none",
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
    addButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.body}>
        <div style={styles.rowBottom}>
          {!isEmpty(openRecord) ? (
            <SpeakerPage
              setOpenRecord={setOpenRecord}
              speaker={openRecord}
              isCreation={isCreation}
              setIsCreation={setIsCreation}
              getSpeakers={startingSearch}
            />
          ) : (
            <div style={styles.subcontainer}>
              <div
                style={{
                  ...styles.row,
                  justifyContent: "space-between",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <div style={styles.inputSearch}>
                  <img
                    alt="Search icon"
                    src={search}
                    style={{ width: 30, objectFit: "contain", margin: 10 }}
                  />
                  <input
                    type="text"
                    style={{
                      border: 0,
                      width: "100%",
                      borderRadius: 40,
                      outline: "none",
                    }}
                    value={tempSearchKey}
                    onChange={(event) => {
                      setTempSearchKey(event.target.value);
                    }}
                    placeholder={dictionary.search}
                  />
                  <button
                    style={{
                      ...styles.filterButton,
                    }}
                    onClick={() => {
                      setTempSearchKey("");
                      setSearchKey("");
                      startingSearch(); //come back to the original result search (i.e. the ones at the page opening)
                    }}
                  >
                    <img
                      alt="Refresh icon"
                      src={refresh}
                      style={{ width: 18, objectFit: "contain", margin: 2 }}
                    />
                  </button>
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <button
                    style={styles.addButton}
                    onClick={() => {
                      setIsCreation(true);
                      setOpenRecord(newSpeaker);
                    }}
                  >
                    <img
                      src={add}
                      style={{ height: 13, objectFit: "contain" }}
                      alt={"Add speaker"}
                    />
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.darkgray,
                        margin: 0,
                        marginLeft: 5,
                        fontWeight: "600",
                      }}
                    >
                      Nuovo Relatore
                    </p>
                  </button>
                  <button
                    style={styles.addButton}
                    onClick={() => {
                      if (window.isSecureContext && navigator.clipboard) {
                        navigator.clipboard.writeText(linkCopied);
                      } else {
                        unsecuredCopyToClipboard(linkCopied);
                      }
                      setIsCopied(true);
                    }}
                  >
                    <img
                      src={!isCopied ? link : completed}
                      style={{
                        height: !isCopied ? 17 : 20,
                        objectFit: "contain",
                      }}
                      alt={"Add speaker"}
                    />
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.darkgray,
                        margin: 0,
                        marginLeft: 5,
                        fontWeight: "600",
                      }}
                    >
                      {!isCopied
                        ? "Copia link registrazione Relatore"
                        : "Link copiato!"}
                    </p>
                  </button>
                </div>
              </div>
              {speakers.length > 0 ? (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <Table
                    speakers={speakers}
                    colors={colors}
                    setOpenRecord={setOpenRecord}
                    activePage={activePage}
                    itemsPerPage={itemsPerPage}
                  />
                </div>
              ) : speakersLoaded ? (
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    color: colors.gray,
                    marginTop: 40,
                  }}
                >
                  {dictionary.noSpeakers}
                </p>
              ) : (
                <div
                  style={{
                    display: "flex",
                    marginTop: 40,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={colors?.primary}
                    height={50}
                    width={50}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div style={{ width: "90%" }}>
        {speakers.length > 0 &&
          totalItemsCount > itemsPerPageOptions[0] &&
          isEmpty(openRecord) && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridAutoRows: "1fr",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <select
                  name="options"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: 15,
                    height: 50,
                    width: 70,
                    padding: 10,
                    textAlign: "left",
                    marginRight: 10,
                  }}
                  value={itemsPerPage}
                  onChange={(event) => {
                    setItemsPerPage(parseInt(event.target.value));
                    setActivePage(1);
                  }}
                >
                  {itemsPerPageOptions.map((option, key) => {
                    return (
                      <option key={key} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
                <p style={{ color: colors?.gray, fontSize: 12 }}>
                  {dictionary.pageElements}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => setActivePage(pageNumber)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default SpeakersManager;
