import React from "react";
import moment from "moment";
import "moment/locale/it";
moment.locale("it");

function validateEmail(email, setEmailError) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let error = re.test(String(email).toLowerCase());
  if (email === "") {
    setEmailError(false);
  } else {
    setEmailError(!error);
  }
  return error;
}

const Form = ({ colors, formFunctions, onModify }) => {
  const styles = {
    label: {
      fontSize: 13,
      color: colors.gray,
    },
    selectBox: {
      marginTop: 5,
      marginBottom: 5,
      width: "100%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    textInput: {
      outline: "none",
      border: "1px solid lightgray",
      width: "80%",
      height: 20,
      borderRadius: 10,
      marginRight: 10,
      marginLeft: 10,
      padding: 5,
      textAlign: "center",
    },
    text: {
      fontSize: 13,
      margin: 0,
    },
    description: {
      fontSize: 10,
      width: "80%",
      textAlign: "right",
      color: colors.darkgray,
      margin: 10,
    },
    button: {
      outline: "none",
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      fontSize: 10,
      padding: 0,
    },
  };

  const profilationSentence = () => {
    return (
      <p style={{ ...styles.description, textAlign: "left" }}>
        Presa visione dell' <b>Informativa Privacy</b>, autorizza al trattamento
        da parte del Titolare dei suoi Dati Personali per finalità di{" "}
        <b>profilazione</b> dei suoi interessi e preferenze, per migliorare la
        conoscenza dei suoi bisogni ed aspettative rispetto all’offerta fornita
        dal Titolare.
      </p>
    );
  };

  const marketingSentence = () => {
    return (
      <p style={{ ...styles.description, textAlign: "left" }}>
        Ha preso visione dell' <b>Informativa Privacy</b>, riguardante il
        trattamento da parte del Titolare dei suoi Dati Personali per finalità
        di <b>marketing</b> al fine di inviargli newsletter e fornirgli
        informazioni su iniziative promozionali relative a prodotti ed eventi
        attraverso modalità di contatto automatizzate (i.e. posta elettronica)
      </p>
    );
  };

  return (
    <>
      <div style={styles.selectBox}>
        {!onModify && formFunctions.cardNumber && (
          <p style={styles.label}>Numero tessera</p>
        )}
        {onModify ? (
          <>
            <p style={styles.label}>Numero tessera</p>
            <input
              type="text"
              style={styles.textInput}
              value={formFunctions.cardNumber}
              onChange={(event) => {
                formFunctions.setCardNumber(event.target.value.toLowerCase());
                formFunctions.setExistingCardNumberError(false);
              }}
              placeholder="Numero tessera"
            ></input>
            {formFunctions.existingCardNumberError && (
              <p
                style={{
                  color: colors.highlight,
                  marginTop: 2,
                  fontSize: 10,
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Tessera già assegnata
              </p>
            )}
          </>
        ) : (
          <p style={styles.text}>{formFunctions.cardNumber}</p>
        )}
      </div>
      <div style={styles.row}>
        <div style={styles.selectBox}>
          <p style={styles.label}>Nome</p>
          {onModify ? (
            <input
              type="text"
              style={styles.textInput}
              value={formFunctions.name}
              onChange={(event) => formFunctions.setName(event.target.value)}
              placeholder="Nome"
            ></input>
          ) : (
            <p style={styles.text}>{formFunctions.name}</p>
          )}
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>Cognome</p>
          {onModify ? (
            <input
              type="text"
              style={styles.textInput}
              value={formFunctions.surname}
              onChange={(event) => formFunctions.setSurname(event.target.value)}
              placeholder="Cognome"
            ></input>
          ) : (
            <p style={styles.text}>{formFunctions.surname}</p>
          )}
        </div>
      </div>
      <div style={styles.row}>
        <div style={styles.selectBox}>
          <p style={styles.label}>Email</p>
          {onModify ? (
            <input
              type="text"
              style={styles.textInput}
              value={formFunctions.email}
              onChange={(event) => {
                formFunctions.setEmail(event.target.value);
                validateEmail(event.target.value, formFunctions.setEmailError);
              }}
              placeholder="Email"
            ></input>
          ) : (
            <p style={styles.text}>{formFunctions.email}</p>
          )}
          {formFunctions.emailError && (
            <p
              style={{
                color: colors.highlight,
                marginTop: 2,
                fontSize: 10,
                width: "100%",
                textAlign: "center",
              }}
            >
              Email non valida
            </p>
          )}
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>Data di nascita</p>
          {onModify ? (
            <input
              type="date"
              name="birth"
              max={moment(new Date()).format("yyyy-MM-DD")}
              value={moment(formFunctions.birth).format("yyyy-MM-DD")}
              onChange={(event) => formFunctions.setBirth(event.target.value)}
              placeholder="Data di nascita"
              style={styles.textInput}
            />
          ) : (
            <p style={styles.text}>
              {moment(formFunctions.birth).format("DD/MM/yyyy")}
            </p>
          )}
        </div>
      </div>
      <div style={styles.row}>
        <div style={styles.selectBox}>
          <p style={styles.label}>Indirizzo</p>
          {onModify ? (
            <input
              type="text"
              style={styles.textInput}
              value={formFunctions.address}
              onChange={(event) => {
                formFunctions.setAddress(event.target.value);
              }}
              placeholder="Indirizzo"
            ></input>
          ) : (
            <p style={styles.text}>{formFunctions.address}</p>
          )}
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>Città</p>
          {onModify ? (
            <input
              type="text"
              style={styles.textInput}
              value={formFunctions.city}
              onChange={(event) => {
                formFunctions.setCity(event.target.value);
              }}
              placeholder="Città"
            ></input>
          ) : (
            <p style={styles.text}>{formFunctions.city}</p>
          )}
        </div>
      </div>
      <div style={styles.row}>
        <div style={styles.selectBox}>
          <p style={styles.label}>Provincia</p>
          {onModify ? (
            <input
              type="text"
              style={styles.textInput}
              value={formFunctions.county}
              onChange={(event) => {
                if (event.target.value.length <= 2) {
                  formFunctions.setCounty(event.target.value.toUpperCase());
                }
              }}
              placeholder="Provincia"
            ></input>
          ) : (
            <p style={styles.text}>{formFunctions.county}</p>
          )}
        </div>
        <div style={styles.selectBox}>
          <p style={styles.label}>CAP</p>
          {onModify ? (
            <input
              type="text"
              style={styles.textInput}
              value={formFunctions.zip}
              onChange={(event) => {
                formFunctions.setZip(event.target.value.replace(/[^\d]/, ""));
              }}
              placeholder="CAP"
            ></input>
          ) : (
            <p style={styles.text}>{formFunctions.zip}</p>
          )}
        </div>
      </div>
      <div
        style={{
          ...styles.row,
          marginTop: 40,
        }}
      >
        <input
          type="checkbox"
          disabled={!onModify}
          checked={formFunctions.profilationCheckBox}
          onChange={(event) =>
            formFunctions.setProfilationCheckBox(event.target.checked)
          }
        />
        {profilationSentence()}
      </div>
      <div style={styles.row}>
        <input
          type="checkbox"
          disabled={!onModify}
          checked={formFunctions.marketingCheckBox}
          onChange={(event) =>
            formFunctions.setMarketingCheckBox(event.target.checked)
          }
        />
        {marketingSentence()}
      </div>
    </>
  );
};

export default Form;
