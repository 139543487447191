import React, { useState, useContext } from "react";
import { UserContext } from "./../../../contexts";
import ActivityBox from "../activities/list/activityBox";
import arrowDown from "./../../../img/arrow_down.png";
import arrowUp from "./../../../img/arrow_up.png";

const ActivityCategory = (props) => {
  const [open, setOpen] = useState(false);

  const { category, width, info, match } = props;

  const colors = useContext(UserContext)?.colors;

  function checkColor(color) {
    var c = color.substring(1); // strip #
    var rgb = parseInt(c, 16); // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff; // extract red
    var g = (rgb >> 8) & 0xff; // extract green
    var b = (rgb >> 0) & 0xff; // extract blue
    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    if (luma < 80) {
      return "white";
    } else {
      return "black";
    }
  }

  const getGrid = () => {
    if (width < 600) {
      return `
        'act'
       `;
    } else if (width < 850 && width > 600) {
      return `
            'act act '
           `;
    } else if (width < 1000 && width > 850) {
      return `
          'act act act'
         `;
    } else if (width < 1500 && width > 1000) {
      return `
        'act act'
       `;
    } else if (width < 1900 && width > 1500) {
      return `
          'act act act'
         `;
    } else if (width < 2100 && width > 2000) {
      return `
          'act act act act'
         `;
    } else if (width > 2100) {
      return `
            'act act act act act'
           `;
    }
  };

  const styles = {
    column: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      marginTop: "20px",
    },
    category: {
      width: "100%",
      backgroundColor: info?.colore || "#efefef",
      color: info?.colore ? checkColor(info?.colore) : "#343434",
      display: "flex",
      justifyContent: "spaceBetween",
      alignItems: "center",
      cursor: "pointer",
    },
    box: {
      display: "grid",
      gridGap: "10px",
      justifyItems: "center",
      gridTemplateColumns: "repeat(auto-fit, 300px)",
      gridTemplateAreas: getGrid(),
    },
  };

  return (
    <div style={styles.column}>
      <div style={styles.category} onClick={() => setOpen(!open)}>
        <p
          style={{
            width: "100%",
            textAlign: "left",
            paddingLeft: 40,
            paddingRight: 40,
            fontWeight: "700",
            fontSize: 15,
          }}
        >
          {category.nome.toUpperCase()}
        </p>
        <img
          alt="Arrow icon"
          src={open ? arrowUp : arrowDown}
          style={{ width: 15, objectFit: "contain", marginRight: "20px" }}
        />
      </div>
      {open && (
        <div style={{ ...styles.column, margin: 0 }}>
          <div style={styles.box}>
            {category.attivitas.map((activity, key) => {
              return (
                <ActivityBox
                  key={key}
                  activity={activity}
                  colors={colors}
                  toggleInfo={() => {
                    window.location =
                      `${process.env.REACT_APP_WEB_URL}/` +
                      match.params.museum +
                      "/activity/" +
                      activity.id;
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ActivityCategory;
