import React, { Component } from "react";
import museum from "./../../museumColors";
import network from "../../networkColors";
import VouchersBar from "./vouchersBar";
import VouchersValidator from "./vouchersValidator";
import AllVouchers from "./allVouchers";
import { vouchersService } from "../../_services/vouchers.service";

export default class Vouchers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0, // screen width
      height: 0, // screen height,
      loader: true,
      colors: this.props.isNetwork ? network : museum,
      selection:
        props.index !== undefined && !isNaN(parseInt(props.index))
          ? parseInt(props.index)
          : props.permissions?.tutti
          ? 1
          : props.permissions?.convalida
          ? 2
          : 1,
      vouchersCategories: [],
      vouchersCategoriesLoaded: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.selected = (id) => {
      const { vouchersCategories } = this.state;
      switch (id) {
        case 1:
          return (
            <AllVouchers
              colors={this.state.colors}
              vouchersCategories={vouchersCategories}
              vouchersCategoriesLoaded={this.state.vouchersCategoriesLoaded}
              orgId={this.props.orgId}
            />
          );
        case 2:
          return (
            <VouchersValidator
              colors={this.state.colors}
              vouchersCategories={vouchersCategories}
              orgId={this.props.orgId}
            />
          );
        default:
          break;
      }
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.getVouchersCategories();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isNetwork !== this.props.isNetwork) {
      this.setState({
        colors: this.props.isNetwork ? network : museum,
        loader: true,
      });
    }
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  getVouchersCategories() {
    this.setState({
      vouchersCategoriesLoaded: false,
    });
    vouchersService
      .getVouchersCategories(this.props.orgId)
      .then((categories) => {
        this.setState({
          vouchersCategories: categories,
        });
        this.setState({
          vouchersCategoriesLoaded: true,
        });
      });
  }

  render() {
    const { colors, selection } = this.state;
    const styles = {
      container: {
        display: "flex",
        flexDirection: "row",
        height: "100%",
        width: this.props.iframe ? "100%" : "85%",
        position: "absolute",
        right: 0,
        alignItems: "center",
        overflow: "scroll",
      },
    };
    return (
      <div style={styles.container}>
        {!this.props.iframe && (
          <VouchersBar
            colors={colors}
            selection={selection}
            setSelection={(section) => this.setState({ selection: section })}
            isNetwork={this.props.isNetwork}
            vouchersCategories={this.state.vouchersCategories}
            permissions={this.props.permissions}
          />
        )}
        {this.selected(selection)}
      </div>
    );
  }
}
