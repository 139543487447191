import React, { useState, useContext } from "react";
import { UserContext } from "../../../contexts";
import { LanguageContext } from "../../../containers/language";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import arrow from "../../../img/arrow_back_dark.png";
import modify from "../../../img/modify_black.png";
import close from "../../../img/closeButton_dark.png";
import deleteIcon from "../../../img/delete_darkgray.png";
import { customersService } from "../../../_services/customers.service";
import ReactLoading from "react-loading";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import EndOperation from "../../../components/popup/endOperation";
import DeleteCustomer from "../../../components/popup/deleteCustomer";

const CustomerPage = (props) => {
  const colors = useContext(UserContext)?.colors;
  const { dictionary } = useContext(LanguageContext);
  const [tabIndex, setTabIndex] = useState(0);
  var customer = JSON.parse(JSON.stringify(props.customer));

  const [newBusinessName, setNewBusinessName] = useState(
    customer.ragione_sociale
  );
  const [newPartitaIVA, setNewPartitaIVA] = useState(customer.partita_iva);
  const [newFiscalCode, setNewFiscalCode] = useState(customer.codice_fiscale);
  const [newDestinationCode, setNewDestinationCode] = useState(
    customer.codice_destinatario
  );
  const [newNumber, setNewNumber] = useState(customer.telefono);
  const [newState, setNewState] = useState(customer.nazione);
  const [newCounty, setNewCounty] = useState(customer.provincia);
  const [newCity, setNewCity] = useState(customer.comune);
  const [newZipCode, setNewZipCode] = useState(customer.cap);
  const [newStreet, setNewStreet] = useState(customer.via);
  // not mandatory
  const [newCig, setNewCig] = useState(customer.cig || "");
  const [newPec, setNewPec] = useState(customer.pec || "");
  const [newCup, setNewCup] = useState(customer.cup || "");

  //We intialiaze this as the isCreation bool because the page's appereance during the creation is the same as while modifying a speaker
  const [isEditing, setIsEditing] = useState(props.isCreation);

  const isCreation = props.isCreation;
  //Bool used during creation to check that all required fields have been compiled
  const [notFilled, setNotFilled] = useState(false);

  const [loader, setLoader] = useState(false);
  //const [view, setView] = useState("month");

  //Flag used to show the popup for confirming the delete of the speaker
  const [deleteCustomer, setDeleteCustomer] = useState(false);

  //Bool used to show the result of operations
  const [endOperation, setEndOperation] = useState(false);

  const resetValues = () => {
    setNewBusinessName(customer.ragione_sociale);
    setNewPartitaIVA(customer.partita_iva);
    setNewFiscalCode(customer.codice_fiscale);
    setNewDestinationCode(customer.codice_destinatario);
    setNewNumber(customer.telefono);
    setNewState(customer.nazione);
    setNewCounty(customer.provincia);
    setNewCity(customer.comune);
    setNewZipCode(customer.cap);
    setNewStreet(customer.via);
    setNewCig(customer.cig || "");
    setNewPec(customer.pec || "");
    setNewCup(customer.cup || "");
    setNotFilled(false);
  };

  const createCustomer = () => {
    if (newBusinessName !== "" && newBusinessName !== null) {
      customer.ragione_sociale = newBusinessName;
    }
    if (newPartitaIVA !== "" && newPartitaIVA !== null) {
      customer.partita_iva = newPartitaIVA;
    }
    if (newFiscalCode !== "" && newFiscalCode !== null) {
      customer.codice_fiscale = newFiscalCode;
    }
    if (newDestinationCode !== "" && newDestinationCode !== null) {
      customer.codice_destinatario = newDestinationCode;
    }
    if (newNumber !== "" && newNumber !== null) {
      customer.telefono = newNumber;
    }
    if (newState !== "" && newState !== null) {
      customer.nazione = newState;
    }
    if (newCounty !== "" && newCounty !== null) {
      customer.provincia = newCounty;
    }
    if (newCity !== "" && newCity !== null) {
      customer.comune = newCity;
    }
    if (newZipCode !== "" && newZipCode !== null) {
      customer.cap = newZipCode;
    }
    if (newStreet !== "" && newStreet !== null) {
      customer.via = newStreet;
    }

    // not mandatory
    if (newCig !== "" && newCig !== null) {
      customer.cig = newCig;
    }
    if (newPec !== "" && newPec !== null) {
      customer.pec = newPec;
    }
    if (newCup !== "" && newCup !== null) {
      customer.cup = newCup;
    }

    setLoader(true);
    customersService
      .createCustomer(customer)
      .then(() => {
        setLoader(false);
        props.getCustomers();
        props.setOpenRecord(null);
        props.setIsCreation(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  const updateCustomer = () => {
    if (newBusinessName !== "" && newBusinessName !== null) {
      customer.ragione_sociale = newBusinessName;
    }
    if (newPartitaIVA !== "" && newPartitaIVA !== null) {
      customer.partita_iva = newPartitaIVA;
    }
    if (newFiscalCode !== "" && newFiscalCode !== null) {
      customer.codice_fiscale = newFiscalCode;
    }
    if (newDestinationCode !== "" && newDestinationCode !== null) {
      customer.codice_destinatario = newDestinationCode;
    }
    if (newNumber !== "" && newNumber !== null) {
      customer.telefono = newNumber;
    }
    if (newState !== "" && newState !== null) {
      customer.nazione = newState;
    }
    if (newCounty !== "" && newCounty !== null) {
      customer.provincia = newCounty;
    }
    if (newCity !== "" && newCity !== null) {
      customer.comune = newCity;
    }
    if (newZipCode !== "" && newZipCode !== null) {
      customer.cap = newZipCode;
    }
    if (newStreet !== "" && newStreet !== null) {
      customer.via = newStreet;
    }

    // not mandatory
    if (newCig !== "" && newCig !== null) {
      customer.cig = newCig;
    }
    if (newPec !== "" && newPec !== null) {
      customer.pec = newPec;
    }
    if (newCup !== "" && newCup !== null) {
      customer.cup = newCup;
    }

    setLoader(true);
    customersService.updateCustomer(customer).then(() => {
      setLoader(false);
      props.getCustomers();
      props.setOpenRecord(null);
    });
  };

  const toggleDeleteCustomer = () => {
    setDeleteCustomer(!deleteCustomer);
  };

  const toggleEndOperation = () => {
    setEndOperation(!endOperation);
  };

  const handleDelete = () => {
    customersService
      .deleteCustomer(customer.id)
      .then(() => {
        props.getCustomers();
        props.setIsCreation(false);
        props.setOpenRecord(null);
      })
      .catch(() => {
        setDeleteCustomer(false);
        toggleEndOperation();
      });
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      overflowX: "scroll",
    },
    rowTop: {
      alignItems: "start",
      color: colors?.primary,
      width: "100%",
    },
    rowBottom: {
      display: "flex",
      justifyContent: "center",
      width: "90%",
      overflow: "scroll",
    },
    subcontainer: {
      width: "100%",
      paddingBottom: 20,
      paddingTop: 20,
    },
    goBackButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    arrow: {
      height: 13,
      objectFit: "contain",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      paddingBottom: "50px",
    },
    firstColumn: {
      display: "flex",
      flexDirection: "column",
      width: "33.3%",
      justifyContent: "left",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "center",
    },
    avatar: {
      backgroundColor: colors.gray,
      width: "250px",
      height: "250px",
    },
    label: {
      color: colors?.primary,
      fontSize: 15,
      textAlign: "left",
    },
    textInput: {
      outline: "none",
      padding: 10,
      textAlign: "left",
      width: "93%",
      border: "1px solid lightgray",
      borderRadius: 5,
    },
    saveButton: {
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: colors.darkgray,
      margin: 30,
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 15,
    },
  };

  return (
    <div style={styles.container}>
      {deleteCustomer && (
        <DeleteCustomer
          name={newBusinessName}
          closeModal={toggleDeleteCustomer}
          saveData={() => handleDelete()}
          colors={colors}
        />
      )}
      {endOperation && (
        <EndOperation
          flag={false}
          operation={2}
          closeModal={toggleEndOperation}
          colors={colors}
        />
      )}
      <div style={styles.body}>
        {!isCreation && (
          <div style={styles.rowTop}>
            <Tabs
              value={tabIndex}
              variant="fullWidth"
              TabIndicatorProps={{ style: { background: colors?.secondary } }}
              onChange={(event, newValue) => {
                setTabIndex(newValue);
              }}
            >
              <Tab label={"Info"} />
            </Tabs>
          </div>
        )}
        <div style={styles.rowBottom}>
          <div style={styles.subcontainer}>
            <div style={{ ...styles.row, justifyContent: "space-between" }}>
              <button
                style={styles.goBackButton}
                onClick={() => {
                  props.setIsCreation(false);
                  props.setOpenRecord(null);
                }}
                disabled={loader}
              >
                <img src={arrow} style={styles.arrow} alt={"Go back"} />
                <p
                  style={{
                    fontSize: 12,
                    color: colors.darkgray,
                    margin: 0,
                    marginLeft: 5,
                    fontWeight: "600",
                  }}
                >
                  Indietro
                </p>
              </button>
              {!isEditing ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <button
                    style={styles.goBackButton}
                    onClick={() => setIsEditing(!isEditing)}
                  >
                    <img src={modify} style={styles.arrow} alt={"Go back"} />
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.darkgray,
                        margin: 0,
                        marginLeft: 5,
                        fontWeight: "600",
                      }}
                    >
                      Modifica
                    </p>
                  </button>
                  <button
                    style={styles.goBackButton}
                    onClick={() => setDeleteCustomer(!deleteCustomer)}
                  >
                    <img src={deleteIcon} style={styles.arrow} alt={"Delete"} />
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.darkgray,
                        margin: 0,
                        marginLeft: 5,
                        fontWeight: "600",
                      }}
                    >
                      Elimina
                    </p>
                  </button>
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <button
                    style={styles.goBackButton}
                    onClick={() => {
                      setIsEditing(!isEditing);
                      resetValues();
                    }}
                  >
                    <img src={close} style={styles.arrow} alt={"Go back"} />
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.darkgray,
                        margin: 0,
                        marginLeft: 5,
                        fontWeight: "600",
                      }}
                    >
                      Annulla
                    </p>
                  </button>
                </div>
              )}
            </div>
            <div style={{ ...styles.row, justifyContent: "left" }}>
              <div style={styles.firstColumn}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {/* Ragione sociale */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                    }}
                  >
                    <p style={styles.label}>
                      {dictionary.customerBusinessName}
                    </p>
                    <input
                      type="text"
                      name="customerBusinessName"
                      placeholder={dictionary.customerBusinessName}
                      value={newBusinessName}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewBusinessName(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={styles.row}>
              <div style={styles.column}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {/* Partita IVA */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>{dictionary.customerPartitaIVA}</p>
                    <input
                      type="text"
                      name="customerPartitaIVA"
                      placeholder={dictionary.customerPartitaIVA}
                      value={newPartitaIVA}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewPartitaIVA(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                  {/* Codice fiscale */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>{dictionary.customerFiscalCode}</p>
                    <input
                      type="text"
                      name="customerFiscalCode"
                      placeholder={dictionary.customerFiscalCode}
                      value={newFiscalCode}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewFiscalCode(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                  {/* Codice destinatario */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>
                      {dictionary.customerDestinationCode}
                    </p>
                    <input
                      type="text"
                      name="customerDestinationCode"
                      placeholder={dictionary.customerDestinationCode}
                      value={newDestinationCode}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewDestinationCode(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={styles.row}>
              <div style={styles.column}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {/* Telefono */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>{dictionary.customerNumber}</p>
                    <input
                      type="number"
                      name="customerNumber"
                      placeholder={dictionary.customerNumber}
                      value={newNumber}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewNumber(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                  {/* Nazione */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>{dictionary.customerState}</p>
                    <input
                      type="text"
                      name="customerState"
                      placeholder={dictionary.customerState}
                      value={newState}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewState(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                  {/* Provincia */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>{dictionary.customerCounty}</p>
                    <input
                      type="text"
                      name="customerCounty"
                      placeholder={dictionary.customerCounty}
                      value={newCounty}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewCounty(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={styles.row}>
              <div style={styles.column}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {/* Comune */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>{dictionary.customerCity}</p>
                    <input
                      type="text"
                      name="customerCity"
                      placeholder={dictionary.customerCity}
                      value={newCity}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewCity(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                  {/* CAP */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>{dictionary.customerZipCode}</p>
                    <input
                      type="text"
                      name="customerZipCode"
                      placeholder={dictionary.customerZipCode}
                      value={newZipCode}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewZipCode(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                  {/* Via */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>{dictionary.customerStreet}</p>
                    <input
                      type="text"
                      name="customerStreet"
                      placeholder={dictionary.customerStreet}
                      value={newStreet}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewStreet(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Not mandatory */}
            <div style={styles.row}>
              <div style={styles.column}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {/* CIG */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>{dictionary.customerCig}</p>
                    <input
                      type="text"
                      name="customerCig"
                      placeholder={dictionary.customerCig}
                      value={newCig ? newCig : !isCreation ? "-" : ""}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewCig(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                  {/* PEC */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>{dictionary.customerPec}</p>
                    <input
                      type="text"
                      name="customerPec"
                      placeholder={dictionary.customerPec}
                      value={newPec ? newPec : !isCreation ? "-" : ""}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewPec(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                  {/* CUP */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>{dictionary.customerCup}</p>
                    <input
                      type="text"
                      name="customerCup"
                      placeholder={dictionary.customerCup}
                      value={newCup ? newCup : !isCreation ? "-" : ""}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewCup(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                </div>
              </div>
            </div>
            {isEditing && (
              <div style={{ ...styles.column, alignItems: "center" }}>
                {notFilled && (
                  <p style={{ ...styles.label, color: colors?.highlight }}>
                    {dictionary.notFilledSpace}
                  </p>
                )}
                <div style={styles.row}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {!loader && (
                      <button
                        style={{
                          ...styles.saveButton,
                          backgroundColor: colors.darkgray,
                        }}
                        onClick={() => {
                          var tmpNotFilled = notFilled;

                          if (
                            isCreation &&
                            // madatory fields
                            (newBusinessName === "" ||
                              newFiscalCode === "" ||
                              newDestinationCode === "" ||
                              newNumber === "" ||
                              newState === "" ||
                              newCounty === "" ||
                              newCity === "" ||
                              newZipCode === "" ||
                              newStreet === "")
                          ) {
                            tmpNotFilled = true;
                            setNotFilled(true);
                          }
                          if (!tmpNotFilled) {
                            if (isCreation) {
                              createCustomer();
                            } else {
                              updateCustomer();
                            }
                          }
                        }}
                        disabled={isCreation && notFilled}
                      >
                        <p
                          style={{
                            fontSize: 12,
                            color: colors.white,
                            margin: 0,
                            fontWeight: "600",
                          }}
                        >
                          Salva
                        </p>
                      </button>
                    )}
                    {loader && (
                      <ReactLoading
                        type={"spinningBubbles"}
                        color={colors?.primary}
                        height={50}
                        width={50}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerPage;
