import React from "react";
import close from "./../../img/closeButton.png";

// action ={0: create, 1:modify, 2:delete}
const EndOperation = ({ flag, operation, closeModal, colors, warning }) => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      width: 500,
      height: 300,
      zIndex: 300,
    },
    header: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors.primary,
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    content: {
      width: "100%",
      flex: 6,
      backgroundColor: colors.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    field: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginLeft: 50,
      marginRight: 50,
    },
    footer: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
  };
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: colors.gray,
          opacity: 0.3,
        }}
      />
      <div style={styles.container}>
        <div style={styles.header}>
          {operation === 0 ? (
            <p style={{ color: colors.white, fontSize: 20 }}>
              {flag ? "CONFERMA CREAZIONE" : "ERRORE CREAZIONE"}
            </p>
          ) : null}
          {operation === 1 ? (
            <p style={{ color: colors.white, fontSize: 20 }}>
              {flag ? "CONFERMA MODIFICA" : "ERRORE MODIFICA"}
            </p>
          ) : null}
          {operation === 2 ? (
            <p style={{ color: colors.white, fontSize: 20 }}>
              {flag ? "CONFERMA ELIMINAZIONE" : "ERRORE ELIMINAZIONE"}
            </p>
          ) : null}
          {operation === 3 ? (
            <p style={{ color: colors.white, fontSize: 20 }}>
              {flag ? "CONFERMA CREAZIONE EVENTO" : "ERRORE CREAZIONE EVENTO"}
            </p>
          ) : null}
          {operation === 4 ? (
            <p style={{ color: colors.white, fontSize: 20 }}>
              {flag ? "CONFERMA MODIFICA QUANTITÀ" : "ERRORE MODIFICA QUANTITÀ"}
            </p>
          ) : null}
          {operation === 5 ? (
            <p style={{ color: colors.white, fontSize: 20 }}>
              {flag ? "CONFERMA MODIFICA ACQUISTO" : "ERRORE MODIFICA ACQUISTO"}
            </p>
          ) : null}
          {operation === 6 ? (
            <p style={{ color: colors.white, fontSize: 20 }}>
              {flag ? "CONFERMA AGGIUNTA CARICO" : "ERRORE AGGIUNTA"}
            </p>
          ) : null}
          {operation === 7 ? (
            <p style={{ color: colors.white, fontSize: 20 }}>
              {flag ? "CONFERMA DISABILITAZIONE" : "ERRORE DISABILITAZIONE"}
            </p>
          ) : null}
          {operation === 8 ? (
            <p style={{ color: colors.white, fontSize: 20 }}>
              {flag ? "CONFERMA ABILITAZIONE" : "ERRORE ABILITAZIONE"}
            </p>
          ) : null}
          <button
            onClick={closeModal}
            style={{
              position: "absolute",
              right: 30,
              marginTop: "auto",
              marginBottom: "auto",
              border: "none",
              backgroundColor: "transparent",
              outline: "none",
              cursor: "pointer",
            }}
          >
            <img
              alt="App logo"
              src={close}
              style={{ width: 15, objectFit: "contain" }}
            />
          </button>
        </div>
        <div style={styles.content}>
          <div style={styles.field}>
            {operation === 0 ? (
              <p style={{ textAlign: "left", color: colors.darkgray }}>
                {flag
                  ? "Creazione conclusa con successo."
                  : "La creazione non è andata a buon fine" + warning
                  ? " (" + warning + ")."
                  : ". Riprova."}
              </p>
            ) : null}
            {operation === 1 ? (
              <p style={{ textAlign: "left", color: colors.darkgray }}>
                {flag
                  ? "Modifica conclusa con successo."
                  : "La modifica non è andata a buon fine. Riprova."}
              </p>
            ) : null}
            {operation === 2 ? (
              <p style={{ textAlign: "left", color: colors.darkgray }}>
                {flag
                  ? "Eliminazione conclusa con successo."
                  : "L'eliminazione non è andata a buon fine. Riprova."}
              </p>
            ) : null}
            {operation === 3 ? (
              <p style={{ textAlign: "left", color: colors.darkgray }}>
                {flag
                  ? "Creazione evento conclusa con successo. Aggiungi i biglietti disponibili."
                  : "La creazione evento non è andata a buon fine. Riprova."}
              </p>
            ) : null}
            {operation === 4 ? (
              <p style={{ textAlign: "left", color: colors.darkgray }}>
                {flag
                  ? "Modifica della quantità conclusa con successo."
                  : "La modifica della quantità in inventario non è andata a buon fine. Riprova."}
              </p>
            ) : null}
            {operation === 5 ? (
              <p style={{ textAlign: "left", color: colors.darkgray }}>
                {flag
                  ? "Aggiornamento dei dettagli dell'acquisto conclusa con successo."
                  : "La modifica dei dettagli dell'acquisto non è andata a buon fine. Riprova."}
              </p>
            ) : null}
            {operation === 6 ? (
              <p style={{ textAlign: "left", color: colors.darkgray }}>
                {flag
                  ? "Aggiunta carico conclusa con successo."
                  : "L'aggiunta del carico non è andata a buon fine. Riprova."}
              </p>
            ) : null}
            {operation === 7 ? (
              <p style={{ textAlign: "left", color: colors.darkgray }}>
                {flag
                  ? "Disabilitazione conclusa con successo."
                  : "La disabilitazione non è andata a buon fine. Riprova."}
              </p>
            ) : null}
            {operation === 8 ? (
              <p style={{ textAlign: "left", color: colors.darkgray }}>
                {flag
                  ? "Abilitazione conclusa con successo."
                  : "L'abilitazione non è andata a buon fine. Riprova."}
              </p>
            ) : null}
          </div>
        </div>
        <div style={styles.footer}>
          <button onClick={closeModal} style={styles.button}>
            OK
          </button>
        </div>
      </div>
    </>
  );
};

export default EndOperation;
