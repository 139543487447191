import React, { useContext } from "react";
import { UserContext, OrgContext } from "../../contexts";
import { LanguageContext } from "../../containers/language";
import back from "../../img/arrow_back.png";
import trash from "../../img/delete_darkgray.png";
import reduce from "../../img/reduce_dark.png";

const Summary = ({
  saleSummary,
  status,
  setStatus,
  setShowWarning,
  isCodeNotFound,
  preventNext,
  removeItem,
  removeAllOfItem,
  sell,
  isTicket,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const colors = useContext(UserContext)?.colors;
  const settings = useContext(OrgContext)?.org?.settings;

  const styles = {
    column: {
      display: "flex",
      height: "100%",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-around",
      alignItems: "center",
    },
    button: {
      backgroundColor: colors?.quinquary,
      borderRadius: 10,
      padding: 5,
      color: colors?.white,
      fontSize: 12,
      height: 50,
      cursor: "pointer",
      border: "none",
      outline: "none",
    },
  };

  const nextStatus = () => {
    switch (status) {
      case "list":
        if (!settings?.profilazione_visitatori && !isTicket) {
          return "endsale";
        } else {
          return "profiling";
        }
      case "profiling":
        return "endsale";
      default:
        break;
    }
  };

  const previousStatus = () => {
    switch (status) {
      case "list":
        return "list";
      case "profiling":
        return "list";
      case "endsale":
        return "profiling";
      default:
        break;
    }
  };
  return (
    <div
      style={{
        ...styles.column,
        width: "20%",
        backgroundColor: colors.quaternary,
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 5,
          overflow: "scroll",
          width: "100%",
        }}
      >
        {saleSummary.map((items, key) => {
          return (
            <div key={key} style={{ width: "100%" }}>
              <div
                style={{
                  ...styles.column,
                  width: "100%",
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                {key !== 0 && (
                  <p
                    style={{
                      borderTop: "1px solid black",
                      width: "85%",
                      textAlign: "end",
                      fontWeight: "bold",
                      fontSize: 18,
                      color: colors.darkgray,
                    }}
                  ></p>
                )}
                <div
                  style={{
                    ...styles.row,
                    width: "85%",
                    marginBottom: 10,
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      ...styles.row,
                      color: colors.darkgray,
                    }}
                  >
                    <p
                      style={{
                        fontSize: 12,
                        margin: 0,
                      }}
                    >
                      <b style={{ fontSize: 15 }}>{items.length}</b> x
                    </p>
                    <p
                      style={{
                        flex: 4,
                        paddingLeft: 5,
                        fontSize: 12,
                        margin: 0,
                        fontWeight: "bold",
                        textAlign: "left",
                      }}
                    >
                      {items[0].nome_articolo}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: 10,
                    }}
                  >
                    <button
                      onClick={() => {
                        removeItem(items[0].id);
                      }}
                      style={{
                        cursor: "pointer",
                        border: "none",
                        outline: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        alt={"Decrease button"}
                        src={reduce}
                        style={{
                          width: 14,
                          height: 14,
                          objectFit: "contain",
                          color: colors?.warning,
                        }}
                      ></img>
                    </button>
                    <button
                      onClick={() => {
                        removeAllOfItem(items[0].id);
                      }}
                      style={{
                        cursor: "pointer",
                        border: "none",
                        outline: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        alt={"Remove button"}
                        src={trash}
                        style={{ width: 14, height: 14, objectFit: "contain" }}
                      ></img>
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    ...styles.row,
                    width: "85%",
                    color: colors.darkgray,
                  }}
                >
                  <p style={{ fontSize: 10, flex: 1, margin: 0 }}>
                    {items[0].categoria_articolo.nome_categoria.toUpperCase()}
                  </p>
                  {items[0].prezzo_scontato !== undefined &&
                  items[0].prezzo_scontato !== null ? (
                    <div style={{ flex: 1, fontSize: 10, marginTop: 0 }}>
                      <p
                        style={{
                          fontSize: 10,
                          margin: 0,
                          textDecorationLine: "line-through",
                          textDecorationStyle: "solid",
                        }}
                      >
                        € {(items.length * items[0].prezzo_articolo).toFixed(2)}
                      </p>
                      <p style={{ margin: 0, fontSize: 10 }}>
                        € {(items.length * items[0].prezzo_scontato).toFixed(2)}
                      </p>
                    </div>
                  ) : (
                    <p style={{ fontSize: 10, flex: 1, margin: 0 }}>
                      € {(items.length * items[0].prezzo_articolo).toFixed(2)}
                    </p>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {status !== "loading" && (
        <div style={{ ...styles.row }}>
          {status !== "list" && (
            <button
              onClick={() => setStatus(previousStatus())}
              style={{
                ...styles.button,
                margin: 20,
                marginRight: 0,
                flex: 1,
              }}
            >
              <img
                alt={"Back button"}
                src={back}
                style={{ width: 14, height: 14, objectFit: "contain" }}
              ></img>
            </button>
          )}
          <button
            onClick={() =>
              status === "endsale"
                ? sell()
                : !preventNext()
                ? setStatus(nextStatus())
                : setShowWarning(true)
            }
            style={{
              ...styles.button,
              flex: 4,
              margin: 20,
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 20,
              paddingRight: 20,
              opacity: status === "profiling" && isCodeNotFound ? 0.7 : 1,
              cursor:
                status === "profiling" && isCodeNotFound
                  ? "default"
                  : "pointer",
            }}
            disabled={status === "profiling" && isCodeNotFound}
          >
            <p style={{ verticalAlign: "middle" }}>
              {status === "endsale"
                ? dictionary.endSale.toUpperCase()
                : "AVANTI"}
            </p>
          </button>
        </div>
      )}
    </div>
  );
};

export default Summary;
