import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../../contexts";
import { reservationsService } from "../../../_services";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "react-vis/dist/style.css";
import RemoveReservation from "../popup/removeReservation";
import PaymentPopup from "../popup/paymentPopup";
import GenerateInvitation from "../../../components/popup/generateInvitation";
import "./../../../index.css";
import Calendar from "./calendar";
import EventSideColumn from "./eventSideColumn";
import SideColumn from "./sideColumn";
import RemoveWLReservation from "../popup/removeWLReservation";

function isEmpty(value) {
  return value === null || value === undefined || value === "";
}

const ReservationManager = ({ history }) => {
  const [eventsList, setEventsList] = useState([]);
  const [visitsList, setVisitsList] = useState([]);
  const [selectedVisits, setSelectedVisits] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [eventSelected, setEventSelected] = useState(null);
  const [dateSelected, setDateSelected] = useState(null);
  const [onBooking, setOnBooking] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [reservationSelected, setReservationSelected] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [showDeleteAccessReservation, setShowDeleteAccessReservation] =
    useState(false);
  const [showDeleteEventWLReservation, setShowDeleteEventWLReservation] =
    useState(false);
  const [showDeleteEventReservation, setShowDeleteEventReservation] =
    useState(false);
  const [showGenerate, setShowGenerate] = useState(false);
  const [idSelected, setIdSelected] = useState(null);

  const colors = useContext(UserContext)?.colors;

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    calendarBox: {
      display: "flex",
      height: "100%",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
    },
    infoBox: {
      width: 600,
      height: "100%",
      backgroundColor: colors?.tertiary,
    },
    header: {
      height: 100,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    body: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderBottomLeftRadius: 30,
      borderBottomRightRadius: 30,
    },
    footer: {
      display: "flex",
      flexDirection: "row",
    },
    backButton: {
      position: "absolute",
      top: 15,
      right: 385,
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      alignSelf: "left",
      zIndex: 100,
      cursor: "pointer",
    },
    titleBox: {
      width: "100%",
      backgroundColor: colors?.secondary,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      color: colors?.white,
      fontWeight: "700",
      fontSize: 20,
    },
    subtitle: {
      width: "100%",
      backgroundColor: colors?.primary,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      marginTop: 3,
    },
    fieldBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    field: {
      fontSize: 15,
    },
    location: {
      color: colors?.white,
      fontSize: 11,
    },
    row: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
    },
    label: {
      color: colors?.secondary,
      width: "80%",
      fontSize: 13,
      textAlign: "left",
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      cursor: "pointer",
    },
    textInput: {
      borderRadius: 5,
      border: 0,
      outline: "none",
      textAlign: "center",
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
      width: "80%",
    },
    errorMessage: {
      color: colors?.highlight,
      fontSize: 10,
      fontWeight: "bold",
      marginTop: -6,
    },
    list: {
      width: "100%",
      height: "calc(100vh - 530px)",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      overflowY: "scroll",
    },
    menu: {
      border: "1px solid lightgray",
      width: "88%",
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
      color: colors?.darkgray,
      borderRadius: 5,
      backgroundColor: colors?.white,
      outline: "none",
    },
  };

  const toggleDeleteEventWLReservation = (reservation, eventId) => {
    setShowDeleteEventWLReservation(!showDeleteEventWLReservation);

    if (showDeleteEventWLReservation) {
      setReservationSelected(null);
      setEventId(null);
    } else {
      setReservationSelected(reservation);
      setEventId(eventId);
    }
  };

  const toggleDeleteAccessReservation = (reservation, eventId) => {
    setShowDeleteAccessReservation(!showDeleteAccessReservation);

    if (showDeleteAccessReservation) {
      setReservationSelected(null);
      setEventId(null);
    } else {
      setReservationSelected(reservation);
      setEventId(eventId);
    }
  };

  const toggleDeleteEventReservation = (reservation, eventId) => {
    setShowDeleteEventReservation(!showDeleteEventReservation);

    if (showDeleteEventReservation) {
      setReservationSelected(null);
      setEventId(null);
    } else {
      setReservationSelected(reservation);
      setEventId(eventId);
    }
  };

  const togglePaymentPopup = (reservation, eventId) => {
    if (!reservation?.pagato) {
      setShowPaymentPopup(!showPaymentPopup);
      if (showPaymentPopup) {
        setReservationSelected(null);
        setEventId(null);
      } else {
        setReservationSelected(reservation);
        setEventId(eventId);
      }
    }
  };

  const deleteAccessReservation = () => {
    reservationsService
      .deleteAccessReservation(reservationSelected.id)
      .then((visits) => {
        visits.forEach((visit) => {
          visit.ora_inizio = new Date(visit.ora_inizio);
          visit.ora_fine = new Date(visit.ora_fine);
          visit.nome_evento =
            visit.cognome_partecipante + " x " + visit.numero_partecipanti;
        });
        setVisitsList(visits);
        setSelectedVisits(
          visits.filter(
            (visit) =>
              moment(visit.ora_inizio).format("yyyy-MM-DD") ===
              moment(dateSelected).format("yyyy-MM-DD")
          )
        );
        toggleDeleteAccessReservation(null, null);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          history.push("/");
        }
      });
  };

  const deleteEventReservation = (reservations) => {
    reservationsService
      .deleteEventReservation(reservations)
      .then((events) => {
        events.forEach((event) => {
          event.ora_inizio = new Date(event.ora_inizio);
          event.ora_fine = new Date(event.ora_fine);
        });
        setEventsList(events);
        setEventSelected(events.filter((e) => e.id === eventId)[0]);
        toggleDeleteEventReservation(null, null);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          history.push("/");
        }
      });
  };

  const deleteEventWLReservation = () => {
    reservationsService
      .deleteEventWLReservation(reservationSelected.id)
      .then((events) => {
        events.forEach((event) => {
          event.ora_inizio = new Date(event.ora_inizio);
          event.ora_fine = new Date(event.ora_fine);
        });
        setEventsList(events);
        setEventSelected(events.filter((e) => e.id === eventId)[0]);
        toggleDeleteEventWLReservation(null, null);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          history.push("/");
        }
      });
  };

  const onGenerateInvitations = (num) => {
    const num_ingressi = 2;
    let id = eventSelected.id;
    reservationsService
      .createEventInvitations(id, num, num_ingressi)
      .then((response) => {
        var a = document.createElement("a");
        a.href = response.url;
        a.target = "_blank";
        a.download = "inviti.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
        setShowGenerate(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          history.push("/");
        }
      });
  };

  const confirmPayment = (reservationId, payment) => {
    reservationsService
      .confirmpurchase(reservationId, payment)
      .then((response) => {
        reservationSelected.pagato = true;
        reservationSelected.metodo_pagamento = payment;
        setReservationSelected(reservationSelected);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          history.push("/");
        }
      });
  };

  const closeInfoEvent = () => {
    setEventSelected(null);
    setDateSelected(new Date());
  };

  useEffect(() => {
    setSelectedEvents(
      eventsList.filter(
        (event) =>
          moment(event.ora_inizio).format("yyyy-MM-DD") ===
          moment(dateSelected).format("yyyy-MM-DD")
      )
    );
  }, [eventsList, dateSelected]);

  useEffect(() => {
    if (!isEmpty(dateSelected)) {
      setSelectedEvents([]);
      setSelectedVisits([]);
    }
  }, [dateSelected, history]);

  return (
    <div style={styles.container}>
      <div style={styles.calendarBox}>
        <Calendar
          dateSelected={dateSelected}
          setDateSelected={setDateSelected}
          eventsList={eventsList}
          setEventsList={setEventsList}
          visitsList={visitsList}
          setVisitsList={setVisitsList}
          setIsClosed={setIsClosed}
          setOnBooking={setOnBooking}
          setIdSelected={setIdSelected}
          setEventSelected={setEventSelected}
          setSelectedVisits={setSelectedVisits}
        />
        {showDeleteAccessReservation && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RemoveReservation
              reservation={reservationSelected}
              closeModal={toggleDeleteAccessReservation}
              saveData={deleteAccessReservation}
              colors={colors}
            />
          </div>
        )}
        {showPaymentPopup && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PaymentPopup
              reservation={reservationSelected}
              closeModal={togglePaymentPopup}
              saveData={confirmPayment}
              colors={colors}
            />
          </div>
        )}
        {showDeleteEventReservation && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RemoveReservation
              reservation={reservationSelected}
              closeModal={toggleDeleteEventReservation}
              saveData={deleteEventReservation}
              colors={colors}
            />
          </div>
        )}
        {showDeleteEventWLReservation && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RemoveWLReservation
              reservation={reservationSelected}
              closeModal={toggleDeleteEventReservation}
              saveData={deleteEventWLReservation}
              colors={colors}
            />
          </div>
        )}
      </div>
      {eventSelected && (
        <EventSideColumn
          eventSelected={eventSelected}
          idSelected={idSelected}
          setIdSelected={setIdSelected}
          setEventSelected={setEventSelected}
          showGenerate={showGenerate}
          setShowGenerate={setShowGenerate}
          onBooking={onBooking}
          setOnBooking={setOnBooking}
          closeInfoEvent={closeInfoEvent}
          setEventsList={setEventsList}
          setVisitsList={setVisitsList}
          toggleDeleteEventWLReservation={toggleDeleteEventWLReservation}
          togglePaymentPopup={togglePaymentPopup}
          toggleDeleteEventReservation={toggleDeleteEventReservation}
        />
      )}
      {!eventSelected && (
        <SideColumn
          history={history}
          dateSelected={dateSelected}
          isClosed={isClosed}
          onBooking={onBooking}
          setOnBooking={setOnBooking}
          selectedVisits={selectedVisits}
          setVisitsList={setVisitsList}
          setSelectedVisits={setSelectedVisits}
          setEventsList={setEventsList}
          setEventSelected={setEventSelected}
          setShowDeleteAccessReservation={setShowDeleteAccessReservation}
          showDeleteAccessReservation={showDeleteAccessReservation}
          setReservationSelected={setReservationSelected}
          setEventId={setEventId}
          idSelected={idSelected}
          setIdSelected={setIdSelected}
          selectedEvents={selectedEvents}
        />
      )}
      {showGenerate && (
        <GenerateInvitation
          capacity={eventSelected.posti_totali}
          closeModal={() => setShowGenerate(!showGenerate)}
          colors={colors}
          saveData={onGenerateInvitations}
        />
      )}
    </div>
  );
};

export default ReservationManager;
