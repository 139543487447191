import React, { useState } from "react";
import info from "./../../img/info.png";
import infoGray from "./../../img/info_gray.png";

const InfoButton = ({ text, colors, width, backgroundColor, dark }) => {
  const [open, setOpen] = useState(false);
  const [coordinates, setCoordinates] = useState(false);
  const styles = {
    buttonBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: 20,
      marginRight: 2,
      cursor: "pointer",
    },
    hoverLabel: {
      textAlign: "center",
      fontSize: 12,
      width: width,
      position: "absolute",
      color: colors?.darkgray,
      zIndex: 100,
      backgroundColor: backgroundColor,
      borderRadius: 10,
      padding: 20,
      top: coordinates.y,
      left: coordinates.x,
    },
  };
  return (
    <div
      style={styles.buttonBox}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      onMouseMove={(e) => {
        setCoordinates({
          x: width === 200 ? e.pageX - window.innerWidth + 150 : e.pageX - 200,
          y: e.pageY - 50,
        });
      }}
    >
      {open && <p style={styles.hoverLabel}>{text}</p>}
      <img
        alt="Info icon"
        src={dark ? infoGray : info}
        style={{ height: 13, objectFit: "contain" }}
      />
    </div>
  );
};

export default InfoButton;
