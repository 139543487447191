let network = {
  primary: "#006A54",
  secondary: "#7fb4a9",
  tertiary: "#cce1dc",
  quaternary: "#eaf3f1",
  quinquary: "#003f32",
  highlight: "#6a0016",
  verylightgray: "#f6f6f6",
  lightgray: "#BEBEBE",
  gray: "#A9A9A9",
  mediumgray: "#444444",
  darkgray: "#303030",
  white: "#FFFFFF",
  historyHighlight: "#009d7c",
  darkHistoryHighlight: "#008468",
  inventoryItemHighlight: "#F5F5F5",
  green: "#a0c2a0",
  red: "#c36666",
  background: "##FFF7EC",
  darkBackground: "#E8E0C6",
};

module.exports = network;
