import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../../../contexts";
import search from "../../../img/search.png";
import refresh from "../../../img/refresh.png";
import add from "../../../img/add_black.png";
import download from "../../../img/download_dark.png";
import { LanguageContext } from "../../../containers/language";
import Table from "./table";
import CustomerPage from "./customerPage";
import ReactLoading from "react-loading";
import { customersService } from "../../../_services/customers.service";
import Pagination from "react-js-pagination";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";
import moment from "moment";
import "moment/locale/it";
moment.locale("it");

const itemsPerPageOptions = [10, 20, 50, 100];

const newCustomer = {
  ragione_sociale: "",
  partita_iva: "",
  codice_fiscale: "",
  codice_destinatario: "",
  telefono: "",
  nazione: "",
  provincia: "",
  comune: "",
  cap: "",
  via: "",
  cig: "",
  pec: "",
  cup: "",
};

function isEmpty(field) {
  return field === "" || field === null || field === undefined;
}

const CustomersManager = () => {
  const colors = useContext(UserContext)?.colors;

  const { dictionary } = useContext(LanguageContext);
  const [openRecord, setOpenRecord] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [tempSearchKey, setTempSearchKey] = useState("");
  const [customers, setCustomers] = useState([]);

  const [isCreation, setIsCreation] = useState(false);

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [customersLoaded, setCustomersLoaded] = useState(false);

  const getCustomers = (start, limit, searchKey) => {
    setCustomersLoaded(false);

    customersService
      .countCustomers(encodeURIComponent(searchKey.trim()))
      .then((allCustomers) => {
        setTotalItemsCount(allCustomers);
      });

    customersService
      .getCustomers(start, limit, encodeURIComponent(searchKey.trim()))
      .then((customers) => {
        setCustomers(customers);
        setCustomersLoaded(true);
      });
  };

  const startingSearch = () => {
    // reset searchKey
    let currentSearchKey = "";
    setSearchKey(currentSearchKey);

    let tempActivePage = 1;
    setActivePage(tempActivePage);

    getCustomers(
      itemsPerPage * tempActivePage - itemsPerPage,
      itemsPerPage,
      currentSearchKey
    );
  };

  useEffect(() => {
    getCustomers(
      itemsPerPage * activePage - itemsPerPage,
      itemsPerPage,
      searchKey
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, itemsPerPage, openRecord]);

  useEffect(() => {
    const handleSubmit = () => {
      setSearchKey(tempSearchKey);
      let tempActivePage = 1;
      setActivePage(tempActivePage);
      getCustomers(
        itemsPerPage * tempActivePage - itemsPerPage,
        itemsPerPage,
        tempSearchKey
      );
    };

    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, tempSearchKey]);

  const camelCase = (str) => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  const filterColumns = (data) => {
    // Get column names
    const columns = Object.keys(data[0]);

    const filterColsByKey = columns.filter(
      (c) =>
        c !== "created_at" &&
        c !== "created_by" &&
        c !== "id" &&
        c !== "indirizzo_fatturazione" &&
        c !== "intenstatario_fatturazione" &&
        c !== "nome" &&
        c !== "organisation" &&
        c !== "prenotazioni_attivitas" &&
        c !== "split_payment" &&
        c !== "split_payment_mod" &&
        c !== "updated_at" &&
        c !== "created_by" &&
        c !== "visitatori_gruppi"
    );

    return filterColsByKey; // OR return columns
  };

  const getLabel = (col) => {
    switch (col) {
      case "codice_destinatario":
        return "Codice destinatario";
      case "codice_fiscale":
        return "Codice fiscale";
      case "partita_iva":
        return "Partita iva";
      case "ragione_sociale":
        return "Ragione sociale";
      default:
        return col;
    }
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      overflowX: "scroll",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    rowBottom: {
      display: "flex",
      justifyContent: "center",
      width: !isEmpty(openRecord) ? "100%" : "90%",
      overflow: "scroll",
    },
    subcontainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    inputSearch: {
      borderRadius: 40,
      border: "1px solid #F0F0F0",
      width: "50%",
      height: 40,
      display: "flex",
      flexDirection: "row",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
    goBackButton: {
      width: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    saveButton: {
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: colors.darkgray,
      margin: 30,
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 15,
    },
    filterButton: {
      border: "1px solid #F0F0F0",
      outline: "none",
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
    addButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.body}>
        <div style={styles.rowBottom}>
          {!isEmpty(openRecord) ? (
            <CustomerPage
              setOpenRecord={setOpenRecord}
              customer={openRecord}
              isCreation={isCreation}
              setIsCreation={setIsCreation}
              getCustomers={startingSearch}
            />
          ) : (
            <div style={styles.subcontainer}>
              <div style={{ ...styles.row, marginTop: 20 }}>
                <div
                  style={{
                    ...styles.row,
                    justifyContent: "start",
                    width: "100%",
                  }}
                >
                  {totalItemsCount > 1 || totalItemsCount === 0 ? (
                    <p
                      style={{
                        color: colors?.gray,
                        fontSize: 11,
                        textAlign: "left",
                      }}
                    >
                      {totalItemsCount} clienti trovati
                    </p>
                  ) : (
                    <p
                      style={{
                        color: colors?.gray,
                        fontSize: 11,
                        textAlign: "left",
                      }}
                    >
                      {totalItemsCount} cliente trovato
                    </p>
                  )}
                </div>
              </div>
              <div
                style={{
                  ...styles.row,
                  justifyContent: "space-between",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <div style={styles.inputSearch}>
                  <img
                    alt="Search icon"
                    src={search}
                    style={{ width: 30, objectFit: "contain", margin: 10 }}
                  />
                  <input
                    type="text"
                    style={{
                      border: 0,
                      width: "100%",
                      borderRadius: 40,
                      outline: "none",
                    }}
                    value={tempSearchKey}
                    onChange={(event) => {
                      setTempSearchKey(event.target.value);
                    }}
                    placeholder={dictionary.search}
                  />
                  <button
                    style={{
                      ...styles.filterButton,
                    }}
                    onClick={() => {
                      setTempSearchKey("");
                      setSearchKey("");
                      startingSearch(); //come back to the original result search (i.e. the ones at the page opening)
                    }}
                  >
                    <img
                      alt="Refresh icon"
                      src={refresh}
                      style={{ width: 18, objectFit: "contain", margin: 2 }}
                    />
                  </button>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <button
                    style={styles.addButton}
                    onClick={() => {
                      setIsCreation(true);
                      setOpenRecord(newCustomer);
                    }}
                  >
                    <img
                      src={add}
                      style={{ height: 13, objectFit: "contain" }}
                      alt={"Add customer"}
                    />
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.darkgray,
                        margin: 0,
                        marginLeft: 5,
                        fontWeight: "600",
                      }}
                    >
                      Nuovo cliente
                    </p>
                  </button>
                  {customers.length > 0 && (
                    <ExcelFile
                      filename={"Info_Clienti-Gruppi"}
                      element={
                        <button style={styles.addButton}>
                          <img
                            src={download}
                            style={{ height: 13, objectFit: "contain" }}
                            alt={"Download excel"}
                          />
                          <p
                            style={{
                              fontSize: 12,
                              color: colors.darkgray,
                              margin: 0,
                              marginLeft: 5,
                              fontWeight: "600",
                            }}
                          >
                            {dictionary.downloadExcel}
                          </p>
                        </button>
                      }
                    >
                      <ExcelSheet data={customers} name={"Gruppi"}>
                        {customers.length > 0 &&
                          filterColumns(customers).map((col, key) => {
                            return (
                              <ExcelColumn
                                key={key}
                                label={camelCase(getLabel(col))}
                                value={col}
                              />
                            );
                          })}
                      </ExcelSheet>
                    </ExcelFile>
                  )}
                </div>
              </div>
              <div
                id="scroll"
                style={{ overflowY: customersLoaded ? "scroll" : "hidden" }}
              >
                {customersLoaded ? (
                  customers.length > 0 ? (
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <Table
                        customers={customers}
                        colors={colors}
                        setOpenRecord={setOpenRecord}
                        activePage={activePage}
                        itemsPerPage={itemsPerPage}
                      />
                    </div>
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 14,
                        color: colors.gray,
                        marginTop: 40,
                      }}
                    >
                      {dictionary.noCustomers}
                    </p>
                  )
                ) : (
                  <div
                    style={{
                      display: "flex",
                      marginTop: 40,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ReactLoading
                      type={"spinningBubbles"}
                      color={colors?.primary}
                      height={50}
                      width={50}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{ width: "90%" }}>
        {customers.length > 0 &&
          totalItemsCount > itemsPerPageOptions[0] &&
          isEmpty(openRecord) && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridAutoRows: "1fr",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <select
                  name="options"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: 15,
                    height: 50,
                    width: 70,
                    padding: 10,
                    textAlign: "left",
                    marginRight: 10,
                  }}
                  value={itemsPerPage}
                  onChange={(event) => {
                    setItemsPerPage(parseInt(event.target.value));
                    setActivePage(1);
                  }}
                >
                  {itemsPerPageOptions.map((option, key) => {
                    return (
                      <option key={key} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
                <p style={{ color: colors?.gray, fontSize: 12 }}>
                  {dictionary.pageElements}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => setActivePage(pageNumber)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default CustomersManager;
