import React, { useState, useContext } from "react";
import { UserContext } from "../../contexts";

const ModuleButton = ({ selection, mod, onSelect }) => {
  const colors = useContext(UserContext).colors;

  const [color, setColor] = useState("transparent");

  const styles = {
    button: {
      color: colors?.white,
      width: "100%",
      paddingTop: 15,
      paddingBottom: 15,
      fontSize: 15,
      marginBottom: 2,
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    subButton: {
      color: colors?.white,
      width: "100%",
      paddingTop: 15,
      paddingBottom: 15,
      fontSize: 13,
      marginBottom: 2,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
  };

  return (
    <div style={{ width: "100%" }}>
      <button
        onMouseEnter={() => setColor(colors?.quinquary)}
        onMouseLeave={() => setColor("transparent")}
        onClick={() => {
          onSelect(mod.number);
        }}
        style={{
          ...styles.button,
          backgroundColor: selection === mod.number ? colors?.secondary : color,
        }}
      >
        {mod.nome_modulo.toUpperCase()}
      </button>
    </div>
  );
};

export default ModuleButton;
