import React from "react";
import close from "./../../../../img/closeButton.png";

const MailTakenPopup = ({ closeModal, colors }) => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      width: 500,
      height: 300,
      zIndex: 300,
    },
    header: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors.primary,
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    content: {
      width: "100%",
      flex: 6,
      backgroundColor: colors.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    field: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginLeft: 50,
      marginRight: 50,
    },
    footer: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
  };
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: colors.gray,
          opacity: 0.3,
        }}
      />
      <div style={styles.container}>
        <div style={styles.header}>
            <p style={{ color: colors.white, fontSize: 20 }}>
                {"ERRORE EMAIL"}
            </p>
            <button
                onClick={closeModal}
                style={{
                    position: "absolute",
                    right: 30,
                    marginTop: "auto",
                    marginBottom: "auto",
                    border: "none",
                    backgroundColor: "transparent",
                    outline: "none",
                    cursor: "pointer",
                }}
            >
                <img
                    alt="Close"
                    src={close}
                    style={{ width: 15, objectFit: "contain" }}
                />
            </button>
        </div>
        <div style={styles.content}>
          <div style={styles.field}>
            <p style={{ textAlign: "left", color: colors.darkgray }}>
                {"L'email inserita é già stata utlizzata. Riprovare"}
            </p>
          </div>
        </div>
        <div style={styles.footer}>
          <button onClick={closeModal} style={styles.button}>
            OK
          </button>
        </div>
      </div>
    </>
  );
};

export default MailTakenPopup;
