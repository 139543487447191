import React from "react";
import InterventionsRegister from "./interventionRegister";

const Interventions = ({ history, iframe }) => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      width: iframe ? "100%" : "85%",
      position: "absolute",
      right: 0,
      overflow: "scroll",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <InterventionsRegister history={history} />
      </div>
    </div>
  );
};

export default Interventions;
