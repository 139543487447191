import React, { useState, useEffect, useContext } from "react";
import trash from "./../img/delete_gray.png";
import admin from "./../img/is_admin.png";
import { usersService } from "../_services";
import AddUser from "./../components/popup/addUser";
import AddMuseum from "./../components/popup/addMuseum";
import ChangeRole from "./../components/popup/changeRole";
import RemoveUser from "./popup/removeUser";
import ReactLoading from "react-loading";
import { OrgContext, UserContext } from "../contexts";

function getFirstAdminUser(newUsers) {
  var found = false;
  var firstAdminUser;
  newUsers.forEach((el) => {
    if (!found && el.role.id === 1) {
      found = true;
      firstAdminUser = el;
    }
  });
  if (found) {
    return firstAdminUser;
  } else {
    return newUsers[0];
  }
}

function getCurrentUser(me, newUsers) {
  var found = false;
  var currentUser;
  newUsers.forEach((el) => {
    if (el.id === me.id) {
      found = true;
      currentUser = el;
    }
  });
  if (found) {
    return currentUser;
  } else {
    return newUsers[0];
  }
}

function getConfirmedtUsers(users) {
  let confirmedUsers = [];
  for (let i = 0; i < users.length; i++) {
    if (users[i].confirmed !== false) {
      confirmedUsers.push(users[i]);
    }
  }
  return confirmedUsers;
}

function saveUserInfo(name, email, password, role, setNewUser) {
  setNewUser({ name: name, email: email, password: password, role: role });
}

function addUser(
  newUser,
  setUserList,
  setShowEmailError,
  setShowConfirmation,
  me,
  setNewUser
) {
  setShowEmailError(false);
  usersService
    .createUser(
      newUser.name,
      newUser.email,
      newUser.password,
      newUser.role,
      me?.org_museo,
      me?.org_rete
    )
    .then((response) => {
      setNewUser({});
      if (Array.isArray(response) === false) {
        setShowEmailError(true);
      } else {
        setShowEmailError(false);
        setUserList(response);
        setShowConfirmation(true);
      }
    })
    .catch((errors) => {
      if (errors.statusCode === 401 || errors.statusCode === 403) {
        this.props.history.push("/");
      }
    });
}

function getUsers(me, setUserList, setFirstOrgUser, setLoader) {
  setLoader(true);
  usersService
    .getUsers()
    .then((users) => {
      let newUsers = getConfirmedtUsers(users);
      if (me?.flag_network) {
        let current = getCurrentUser(me, newUsers);
        setUserList(newUsers);
        setFirstOrgUser(current.id);
      } else {
        let firstAdmin = getFirstAdminUser(newUsers);
        setUserList(newUsers);
        setFirstOrgUser(firstAdmin.id);
      }
      setLoader(false);
    })
    .catch((errors) => {
      if (errors.statusCode === 401 || errors.statusCode === 403) {
        this.props.history.push("/");
      }
    });
}

function changeRole(
  me,
  newRole,
  setRoleConfirmationPopup,
  setUserList,
  setFirstOrgUser,
  setLoader,
  rolesList,
  org
) {
  let roleToCopy = rolesList.find((e) => {
    return e.id === parseInt(newRole.role);
  });

  let oldRole;
  for (const key in newRole.user.org_roles_map) {
    if (
      newRole.user.org_roles_map.hasOwnProperty(key) &&
      parseInt(key) === parseInt(org.id)
    ) {
      oldRole = newRole.user.org_roles_map[key];
      oldRole.role.id = parseInt(newRole.role);
      oldRole.role.name = roleToCopy.name;
      oldRole.role.type = roleToCopy.type;
      oldRole.role.description = roleToCopy.description;

      newRole.user.org_roles_map[key] = oldRole;
    }
  }
  usersService
    .modifyUser(me?.org_museo, newRole.user.org_roles_map, newRole.user.id)
    .then(() => {
      getUsers(me, setUserList, setFirstOrgUser, setLoader);
      setRoleConfirmationPopup(false);
    });
}

function checkCode(
  me,
  code,
  setLoader,
  setResponse,
  setUserList,
  setFirstOrgUser
) {
  setLoader(true);
  usersService
    .addToNetwork(code)
    .then((response) => {
      setResponse(response);
      setLoader(false);
      if (response.status) {
        usersService
          .getUsers()
          .then((users) => {
            let newUsers = getConfirmedtUsers(users);
            if (me?.flag_network) {
              let current = getCurrentUser(me, newUsers);
              setUserList(newUsers);
              setFirstOrgUser(current.id);
            } else {
              let firstAdmin = getFirstAdminUser(newUsers);
              setUserList(newUsers);
              setFirstOrgUser(firstAdmin.id);
            }
            setLoader(false);
          })
          .catch((errors) => {
            if (errors.statusCode === 401 || errors.statusCode === 403) {
              this.props.history.push("/");
            }
          });
      }
    })
    .catch((errors) => {
      if (errors.statusCode === 401 || errors.statusCode === 403) {
        this.props.history.push("/");
      }
    });
}

function deleteUser(user, setUserToDelete, setUserList, me, logout) {
  usersService
    .deleteUser(user.id)
    .then((response) => {
      if (me?.id === user.id) {
        setUserToDelete(null);
        logout();
      } else {
        //TODO: change when PUT returns new list
        setUserList(response);
        setUserToDelete(null);
      }
    })
    .catch((errors) => {
      if (errors.statusCode === 401 || errors.statusCode === 403) {
        this.props.history.push("/");
      }
    });
}

function deleteMuseum(user, setUserToDelete, setUserList, me) {
  usersService
    .deleteMuseum(user.id)
    .then((response) => {
      setUserToDelete(null);
      setUserList(response);
    })
    .catch((errors) => {
      if (errors.statusCode === 401 || errors.statusCode === 403) {
        this.props.history.push("/");
      }
    });
}

function getRole(user, org) {
  for (const key in user.org_roles_map) {
    if (
      user.org_roles_map.hasOwnProperty(key) &&
      parseInt(key) === parseInt(org.id)
    ) {
      return user.org_roles_map[key];
    }
  }
}

const UsersManager = ({ amIAdmin, logout }) => {
  const colors = useContext(UserContext)?.colors;
  const me = useContext(UserContext)?.user;
  const org = useContext(OrgContext)?.org;

  const [userList, setUserList] = useState([]);
  const [firstOrgUser, setFirstOrgUser] = useState(null);
  const [addUserPopup, setAddUserPopup] = useState(false);
  const [addMuseumPopup, setAddMuseumPopup] = useState(false);
  const [deleteUserPopup, setDeleteUserPopup] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [roleConfirmationPopup, setRoleConfirmationPopup] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [newUser, setNewUser] = useState({});
  const [newRole, setNewRole] = useState({});
  const [response, setResponse] = useState(null);
  const [loader, setLoader] = useState(false);

  //Get users list
  useEffect(() => {
    getUsers(me, setUserList, setFirstOrgUser, setLoader);
  }, [me, me.flag_network]);

  //Get roles list
  useEffect(() => {
    usersService
      .getRoles()
      .then((response) => {
        const filteredRoles = response.roles.filter(
          (role) => role.name !== "Superadmin"
        );
        setRolesList({ roles: filteredRoles });
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  }, []);

  //Add user
  useEffect(() => {
    if (newUser.name !== undefined) {
      addUser(
        newUser,
        setUserList,
        setShowEmailError,
        setShowConfirmation,
        me,
        setNewUser
      );
    }
  }, [newUser, userList, me]);

  //Reset messages when user creation popup toggles
  useEffect(() => {
    setShowConfirmation(false);
    setShowEmailError(false);
  }, [addUserPopup]);

  //Show popup when new role is selected
  useEffect(() => {
    if (newRole.user !== undefined) {
      setRoleConfirmationPopup(true);
    }
  }, [newRole]);

  //Toggle popup when new user to be deleted is selected
  useEffect(() => {
    if (userToDelete === null) {
      setDeleteUserPopup(false);
    } else {
      setDeleteUserPopup(true);
    }
  }, [userToDelete]);

  const sendEmail = (email, role, multiOrg) => {
    usersService
      .multiOrgEmail(email, me?.org_museo, role)
      .then((response) => {
        if (!multiOrg) {
          setNewUser({});
          if (Array.isArray(response) === false) {
            setShowEmailError(true);
          } else {
            setShowEmailError(false);
            setUserList(response);
            setShowConfirmation(true);
          }
        } else {
          setShowConfirmation(true);
        }
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });

    // me?.org_museo,
  };

  const styles = {
    usersSection: {
      width: "60%",
    },
    scrollerY: {
      overflowY: "scroll",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    headerTable: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      marginTop: 10,
    },
    labelTable: {
      flex: 1,
      fontSize: 12,
      color: colors?.lightgray,
      textAlign: "left",
    },
    field: {
      flex: 1,
      fontSize: 15,
      textAlign: "left",
    },
    menu: {
      border: "1px solid lightgray",
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 10,
      color: colors?.darkgray,
      borderRadius: 20,
      backgroundColor: "transparent",
      outline: "none",
    },
    button: {
      border: "none",
      backgroundColor: "transparent",
      outline: "none",
      cursor: "pointer",
    },
    newUser: {
      display: "flex",
      width: "100%",
      alignItems: "flex-start",
      color: colors?.gray,
      marginTop: 10,
      marginBottom: 10,
      border: "none",
      backgroundColor: "transparent",
      outline: "none",
      cursor: "pointer",
      padding: 0,
    },
  };

  return (
    <div style={styles.usersSection}>
      <div style={styles.scrollerY}>
        <div style={styles.headerTable}>
          <p style={styles.labelTable}>
            {me?.flag_network ? "Musei" : "Utenti"}
          </p>
          <p style={styles.labelTable}>
            {me?.flag_network ? "Amministratore" : "Permessi"}
          </p>
        </div>
        {loader ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 250,
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={colors?.primary}
                height={50}
                width={50}
              />
            </div>
          </>
        ) : (
          <>
            {userList?.map((user, key) => {
              return (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p style={styles.field}>
                    {me?.flag_network ? user.nome : user.username}
                  </p>
                  {me?.flag_network ? (
                    user.isAdmin && (
                      <div style={{ flex: 1, display: "flex" }}>
                        <img
                          alt="Admin"
                          src={admin}
                          style={{
                            width: 20,
                            objectFit: "contain",
                            marginLeft: 35,
                          }}
                        />
                      </div>
                    )
                  ) : (
                    <>
                      {(me?.role.id === 1 || me?.role.id === 67) && (
                        <div style={{ flex: 1, display: "flex" }}>
                          {user.id !== firstOrgUser ? (
                            <select
                              name={user.id}
                              style={styles.menu}
                              value={getRole(user, org)?.role.id}
                              onChange={(event) => {
                                setNewRole({
                                  user: user,
                                  role: event.target.value,
                                });
                              }}
                              disabled={
                                Object.keys(user.org_roles_map).length === 0
                              }
                            >
                              <option value={1}>Amministratore</option>
                              <option value={3}>Operatore</option>
                              <option value={34} disabled>
                                Relatore
                              </option>
                            </select>
                          ) : (
                            <p style={styles.field}>
                              {getRole(user, org)?.role.name}
                            </p>
                          )}
                        </div>
                      )}
                      {me?.role.id === 3 && (
                        <p style={styles.field}>
                          {getRole(user, org)?.role.name}
                        </p>
                      )}
                    </>
                  )}
                  {me?.flag_network
                    ? amIAdmin &&
                      !user.isAdmin && (
                        <button
                          style={styles.button}
                          onClick={() => setUserToDelete(user)}
                        >
                          <img
                            alt="Delete icon"
                            src={trash}
                            style={{
                              width: 15,
                              objectFit: "contain",
                              margin: 10,
                            }}
                          />
                        </button>
                      )
                    : (me?.role.id === 1 || me?.role.id === 67) &&
                      user.id !== firstOrgUser && (
                        <button
                          style={styles.button}
                          onClick={() => setUserToDelete(user)}
                        >
                          <img
                            alt="Delete icon"
                            src={trash}
                            style={{
                              width: 15,
                              objectFit: "contain",
                              margin: 10,
                            }}
                          />
                        </button>
                      )}
                </div>
              );
            })}
          </>
        )}
      </div>
      {me?.flag_network
        ? amIAdmin && (
            <div
              style={{
                display: "flex",
                marginTop: "10px",
              }}
            >
              <button
                style={styles.newUser}
                onClick={() => setAddMuseumPopup(true)}
              >
                Aggiungi museo alla rete
              </button>
            </div>
          )
        : (me?.role.id === 1 || me?.role.id === 67) && (
            <div
              style={{
                display: "flex",
                marginTop: "10px",
              }}
            >
              <button
                style={styles.newUser}
                onClick={() => setAddUserPopup(true)}
              >
                Crea nuovo utente
              </button>
            </div>
          )}
      {addUserPopup && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AddUser
            rolesList={rolesList}
            closeModal={() => setAddUserPopup(false)}
            saveData={(name, email, password, role) =>
              saveUserInfo(name, email, password, role, setNewUser)
            }
            sendEmail={(email, role, multiOrg) =>
              sendEmail(email, role, multiOrg)
            }
            showConfirmation={showConfirmation}
            showEmailError={showEmailError}
            colors={colors}
            me={me}
          />
        </div>
      )}
      {roleConfirmationPopup && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ChangeRole
            flag={newRole}
            closeModal={() => setRoleConfirmationPopup(false)}
            confirm={() =>
              changeRole(
                me,
                newRole,
                setRoleConfirmationPopup,
                setUserList,
                setFirstOrgUser,
                setLoader,
                rolesList.roles,
                org
              )
            }
            colors={colors}
          />
        </div>
      )}
      {addMuseumPopup && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AddMuseum
            closeModal={() => setAddMuseumPopup(false)}
            checkCode={(code) =>
              checkCode(
                me,
                code,
                setLoader,
                setResponse,
                setUserList,
                setFirstOrgUser
              )
            }
            response={response}
            colors={colors}
          />
        </div>
      )}
      {deleteUserPopup && (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RemoveUser
            closeModal={() => setUserToDelete(null)}
            saveData={() =>
              me?.flag_network
                ? deleteMuseum(userToDelete, setUserToDelete, setUserList)
                : deleteUser(
                    userToDelete,
                    setUserToDelete,
                    setUserList,
                    me,
                    logout
                  )
            }
            colors={colors}
          />
        </div>
      )}
    </div>
  );
};

export default UsersManager;
