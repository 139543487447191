import config from "../config";
import { handleResponse, authHeader } from "../_helpers";

export const vouchersService = {
  getVouchersCategories,
  getVouchers,
  checkCode,
  countVouchers,
  validateVouchers,
  importVouchers,
};

function getVouchersCategories(orgId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/categoria-vouchers?organisation=${orgId}`,
    requestOptions
  ).then(handleResponse);
}

function getVouchers(
  category,
  orgId,
  start,
  limit,
  validityFilter,
  hasKeyword,
  searchKey
) {
  const _category = category === undefined ? -1 : category;
  const _start = start === undefined ? 0 : start;
  const _limit = limit === undefined ? -1 : limit;
  const _validityFilter = validityFilter === undefined ? 0 : validityFilter;

  const requestOptions = { method: "GET", headers: authHeader() };

  var req = `${config.api.url}/vouchers?organisation=${orgId}&_start=${_start}&_limit=${_limit}&_sort=id:ASC`;

  if (_category !== -1) {
    req = req + `&categoria_voucher=${_category}`;
  }

  if (_validityFilter === 1) {
    //not used yet
    req = req + `&data_utilizzo_null=true`;
  } else if (_validityFilter === 2) {
    //already used
    req = req + `&data_utilizzo_null=false`;
  } else {
    //
  }

  if (hasKeyword) {
    const _searchKey = searchKey === undefined ? "" : searchKey;
    req = req + `&codice_contains=${_searchKey}`;
  }

  return fetch(req, requestOptions).then(handleResponse);
}

function countVouchers(category, orgId, validityFilter, hasKeyword, searchKey) {
  const _category = category === undefined ? -1 : category;
  const _validityFilter = validityFilter === undefined ? 0 : validityFilter;

  const requestOptions = { method: "GET", headers: authHeader() };

  var req = `${config.api.url}/vouchers/count?organisation=${orgId}`;

  if (_category !== -1) {
    req = req + `&categoria_voucher=${_category}`;
  }

  if (_validityFilter === 1) {
    //not used yet
    req = req + `&data_utilizzo_null=true`;
  } else if (_validityFilter === 2) {
    //already used
    req = req + `&data_utilizzo_null=false`;
  } else {
    //
  }

  if (hasKeyword) {
    const _searchKey = searchKey === undefined ? "" : searchKey;
    req = req + `&codice_contains=${_searchKey}`;
  }

  return fetch(req, requestOptions).then(handleResponse);
}

function checkCode(orgId, voucherCode, voucherCategory) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      voucherCode,
      orgId,
      voucherCategory,
    }),
  };
  return fetch(`${config.api.url}/vouchers/check`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function validateVouchers(vouchers) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      vouchers,
    }),
  };
  return fetch(`${config.api.url}/vouchers/validate`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function importVouchers(fileUrl) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      fileUrl,
    }),
  };

  return fetch(`${config.api.url}/vouchers/import`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}
