import React, { useState, useContext, useEffect } from "react";
import avatar from "./../img/users.png";
import muse from "./../img/museum_gray.png";
import {
  settingsService,
  authenticationService,
  usersService,
} from "../_services";
import Timetable from "../components/timetable";
import ClosingDays from "../components/closingDays";
import NotifyCancelReservation from "../components/popup/notifyCancelReservation";
import MuseumInfo from "../components/museumInfo";
import pencil from "./../img/modify.png";
import NetworkInfo from "../components/networkInfo";
import UsersManager from "../components/usersManager";
import SettingsManager from "../components/settingsManager";
import SettingsBar from "../components/settingsBar";
import DiscountsManager from "../components/discountsManager";
import { UserContext, OrgContext } from "../contexts";

const Settings = ({ history, iframe, index }) => {
  const colors = useContext(UserContext)?.colors;
  const org = useContext(OrgContext)?.org;
  const user = useContext(UserContext)?.user;

  const [entity, setEntity] = useState({});
  const [userEmail, setUserEmail] = useState(user?.email);
  const [changeEmail, setChangeEmail] = useState(false);
  const [timetable, setTimetable] = useState(null);
  const [notifyCancelReservation, setNotifyCancelReservation] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [tempTimetable, setTempTimetable] = useState({});
  const [amIAdmin, setAmIAdmin] = useState(false);
  const [selection, setSelection] = useState(1);

  useEffect(() => {
    if (index !== undefined && !isNaN(parseInt(index))) {
      setSelection(parseInt(index));
    }
  }, [index]);

  const selected = (id) => {
    switch (id) {
      case 2:
        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "row",
              overflow: "scroll",
            }}
          >
            {user.flag_network ? (
              <NetworkInfo isAdmin={amIAdmin} entity={entity} />
            ) : (
              <MuseumInfo entity={entity} />
            )}
            {timetable && !user.flag_network && (
              <Timetable
                timetable={timetable}
                save={checkReservations}
                amIAdmin={user.role?.id === 1 || user.role?.id === 67}
              />
            )}
            {/* {closingDays && !user.flag_network && ( //TODO */}
            {!user.flag_network && (
              <ClosingDays
                entity={entity}
                amIAdmin={user.role?.id === 1 || user.role?.id === 67}
              />
            )}
          </div>
        );
      case 3:
        return (
          <UsersManager
            amIAdmin={amIAdmin}
            logout={() => {
              authenticationService.logout();
              history.push("/");
            }}
          />
        );
      case 1:
        return <SettingsManager />;
      case 4:
        return <DiscountsManager />;
      default:
        break;
    }
  };

  const getTimetable = () => {
    settingsService
      .getTimetable()
      .then((timetable) => {
        setTimetable(timetable);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          history.push("/");
        }
      });
  };

  const checkReservations = (orari_museo) => {
    setTempTimetable(orari_museo);
    settingsService
      .checkReservations(orari_museo)
      .then((newTimetable) => {
        if (Array.isArray(newTimetable)) {
          setReservations(newTimetable);
          setNotifyCancelReservation(true);
        } else {
          setTimetable(newTimetable);
        }
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          history.push("/");
        }
      });
  };

  const updateTimetable = () => {
    settingsService
      .updateTimetable(tempTimetable)
      .then((newTimetable) => {
        setTimetable(newTimetable);
        setNotifyCancelReservation(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          history.push("/");
        }
      });
  };

  const modifyEmail = () => {
    setChangeEmail(true);
  };

  const saveEmail = () => {
    usersService
      .modifyEmail(userEmail)
      .then((response) => {
        setChangeEmail(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          history.push("/");
        }
      });
  };

  const onChangeEmail = (event) => {
    setUserEmail(event.target.value);
  };

  const toggleNotifyCancel = () => {
    getTimetable();
    setReservations([]);
    setNotifyCancelReservation(false);
  };

  useEffect(() => {
    const getTimetable = () => {
      settingsService
        .getTimetable()
        .then((timetable) => {
          setTimetable(timetable);
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            history.push("/");
          }
        });
    };

    const checkIfAdmin = () => {
      usersService
        .checkIfAdmin()
        .then((response) => setAmIAdmin(response))
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            history.push("/");
          }
        });
    };

    if (!user.flag_network) {
      getTimetable();
    } else {
      checkIfAdmin();
    }
  }, [user.flag_network, history]);

  useEffect(() => {
    if (!user.flag_network) {
      settingsService.getMuseumInfo(org.id_entita).then((response) => {
        setEntity(response);
      });
    } else {
      settingsService.getNetworkInfo(org.id_entita).then((response) => {
        setEntity(response);
      });
    }
  }, [org, user.flag_network]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      width: iframe ? "100%" : "85%",
      position: "absolute",
      right: 0,
      alignItems: "center",
      overflow: "scroll",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginBottom: 20,
      backgroundColor: colors.lightgray,
      boxShadow: "2px 0 4px 0 rgb(0 0 0 / 20%)",
    },
    infoBox: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      marginLeft: 30,
      justifyContent: "center",
    },
    emailBox: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      marginRight: 40,
      justifyContent: "center",
      alignItems: "flex-end",
    },
    name: {
      fontSize: 20,
      marginBottom: 5,
    },
    role: {
      fontSize: 10,
      marginTop: 5,
    },
    modifyEmail: {
      color: colors.darkgray,
      fontSize: 10,
      border: "none",
      backgroundColor: "transparent",
      outline: "none",
      cursor: "pointer",
    },
    inputColumn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      marginLeft: 40,
      marginBottom: 10,
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    line: {
      width: "100%",
      borderBottom: "1px solid lightgray",
      marginTop: 30,
      marginBottom: 30,
    },
    content: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflow: "scroll",
    },
  };

  return (
    <div style={styles.container}>
      {!iframe && (
        <SettingsBar
          selection={selection}
          setSelection={(section) => setSelection(section)}
        />
      )}
      <div style={styles.content}>
        <div style={styles.header}>
          {user.flag_network ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 15,
                marginLeft: 40,
              }}
            >
              <img
                alt="Avatar"
                src={muse}
                style={{ width: 30, objectFit: "contain" }}
              />
              <div style={styles.infoBox}>
                <div style={styles.name}>Rete Museale</div>
                <div style={styles.role}>{user?.username}</div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: 15,
                marginLeft: 40,
              }}
            >
              <img
                alt="Avatar"
                src={avatar}
                style={{ width: 30, objectFit: "contain" }}
              />
              <div style={styles.infoBox}>
                <div style={styles.name}>{user?.username}</div>
                <div style={styles.role}>{user?.role.name}</div>
              </div>
            </div>
          )}
          <div style={styles.emailBox}>
            {changeEmail ? (
              <>
                <button style={styles.modifyEmail} onClick={() => saveEmail()}>
                  Salva
                </button>
                <input
                  type="text"
                  style={{
                    outline: "none",
                    border: "1px solid lightgray",
                    width: 250,
                    borderRadius: 40,
                    padding: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    marginTop: 5,
                  }}
                  value={userEmail}
                  onChange={onChangeEmail}
                  placeholder="Email"
                />
              </>
            ) : (
              <>
                <img
                  alt="Modify"
                  src={pencil}
                  style={{
                    width: 10,
                    marginBottom: 2,
                    objectFit: "contain",
                    marginLeft: 55,
                    cursor: "pointer",
                  }}
                  onClick={modifyEmail}
                />
                <div>{userEmail}</div>
              </>
            )}
          </div>
        </div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            width: "90%",
            height: "100%",
          }}
        >
          {selected(selection)}
        </div>
        {notifyCancelReservation && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NotifyCancelReservation
              reservations={reservations}
              closeModal={toggleNotifyCancel}
              saveData={updateTimetable}
            />{" "}
          </div>
        )}
      </div>
    </div>
  );
};
export default Settings;
