import React, { useState, useRef, useEffect, useContext } from "react";
import modify from "./../../img/modify.png";
import trash from "./../../img/delete.png";
import arrowDown from "./../../img/arrow_down.png";
import arrowUp from "./../../img/arrow_up.png";
import orderArrowDown from "./../../img/order_arrow_down.png";
import orderArrowUp from "./../../img/order_arrow_up.png";
import Item from "./item";
import { LanguageContext } from "../../containers/language";
import search from "./../../img/search.png";
import "../../scrollbar.css";

function mapLabel(name) {
  switch (name) {
    case "articolo":
      return "nome_articolo";
    case "tipologia":
      return "nome_articolo";
    case "autore":
      return "autore";
    case "prezzo":
      return "prezzo_articolo";
    case "giacenza":
      return "giacenza_articolo";
    case "ingressi consentiti":
      return "num_ingressi";
    case "validità":
      return "validita";
    default:
      return "-";
  }
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Category = ({
  onDelete,
  onModify,
  category,
  purchaseMethods,
  onDeleteItem,
  onModifyItem,
  onAddStockItem,
  onModifyStockItem,
  updateAscendant,
  updateOrder,
  colors,
}) => {
  const [open, setOpen] = useState(false);
  const labels = ["Articolo", "Autore", "Codice", "Prezzo", "Giacenza"];
  const [ordine, setOrdine] = useState(category.ordine);
  const [ascendente, setAscendente] = useState(category.ascendente);
  const prevOrdine = usePrevious(ordine);
  const prevAscendente = usePrevious(ascendente);

  const { dictionary } = useContext(LanguageContext);
  const [inputValue, setInputValue] = useState("");

  const [filteredList, setFilteredList] = useState([]);

  React.useEffect(() => {
    if (prevOrdine !== undefined && prevOrdine !== ordine) {
      updateOrder(ordine);
    }
  }, [ordine, prevOrdine, updateOrder]);

  React.useEffect(() => {
    if (prevAscendente !== undefined && prevAscendente !== ascendente) {
      updateAscendant(ascendente);
    }
  }, [ascendente, prevAscendente, updateAscendant]);

  useEffect(() => {
    if (inputValue !== "") {
      setFilteredList(
        category.lista_articoli.filter((item) => {
          if (item.deleted !== true) {
            if (category.nome_categoria.toUpperCase() === "LIBRI") {
              return (
                item?.nome_articolo
                  .toLowerCase()
                  .includes(inputValue.toLowerCase()) ||
                (item.autore &&
                  item?.autore
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())) ||
                item?.codice_articolo.includes(inputValue.toLowerCase())
              );
            } else {
              return item?.nome_articolo
                .toLowerCase()
                .includes(inputValue.toLowerCase());
            }
          } else {
            return null;
          }
        })
      );
    } else {
      setFilteredList(
        category.lista_articoli.filter((item) => {
          if (item.deleted !== true) {
            return item;
          } else {
            return null;
          }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const styles = {
    container: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: colors.secondary,
      marginTop: 3,
      cursor: "pointer",
    },
    title: {
      color: colors.white,
      marginLeft: 30,
    },
    alignHorizontal: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      marginRight: 30,
    },
    content: {
      width: "100%",
    },
    scrollerX: {
      overflow: "scroll",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
    },
    label: {
      fontSize: 13,
      color: colors.lightgray,
      textAlign: "center",
    },
    labelWithArrow: {
      fontSize: 13,
      color: colors.lightgray,
      textAlign: "center",
      marginRight: 10,
    },
    labelBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: 180,
      margin: 20,
      cursor: "pointer",
    },
    nameLabelBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: 250,
      margin: 20,
      cursor: "pointer",
    },
    emptyListWarning: {
      fontSize: 15,
      textAlign: "center",
      margin: 20,
    },
    header: {
      display: "flex",
      flexDirection: "row",
      marginTop: 10,
      marginLeft: 5,
    },
    inputSearch: {
      borderRadius: 40,
      border: "1px solid #F0F0F0",
      width: "90%",
      height: 40,
      marginTop: 40,
      marginBottom: 0,
      display: "flex",
      flexDirection: "row",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
    topBar: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    scroller: {
      overflowY: "scroll",
    },
  };

  return (
    <>
      <div style={styles.container} onClick={() => setOpen(!open)}>
        <p style={styles.title}>{category.nome_categoria.toUpperCase()}</p>
        <div style={styles.alignHorizontal}>
          {open &&
            category.nome_categoria.toUpperCase() !== "BIGLIETTI" &&
            category.nome_categoria.toUpperCase() !== "LIBRI" && (
              <>
                <button
                  onClick={(e) => {
                    onDelete();
                    e.stopPropagation();
                  }}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  <img
                    alt="Delete icon"
                    src={trash}
                    style={{ width: 15, objectFit: "contain", margin: 10 }}
                  />
                </button>
                <button
                  onClick={(e) => {
                    onModify();
                    e.stopPropagation();
                  }}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  <img
                    alt="Modify icon"
                    src={modify}
                    style={{ width: 15, objectFit: "contain", margin: 10 }}
                  />
                </button>
              </>
            )}
          <img
            alt="Arrow icon"
            src={open ? arrowUp : arrowDown}
            style={{ width: 15, objectFit: "contain", margin: 10 }}
          />
        </div>
      </div>
      {open && (
        <div style={styles.content}>
          <div style={styles.topBar}>
            <div style={styles.inputSearch}>
              <img
                alt="Search icon"
                src={search}
                style={{ width: 30, objectFit: "contain", margin: 10 }}
              />
              <input
                type="text"
                style={{
                  border: 0,
                  width: "100%",
                  borderRadius: 40,
                  outline: "none",
                }}
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
                placeholder={
                  category.nome_categoria.toUpperCase() === "LIBRI"
                    ? dictionary.searchInsideCategoryBooks
                    : dictionary.searchInsideCategory
                }
              ></input>
            </div>
          </div>
          <div>
            {filteredList.length !== 0 ? (
              <div style={styles.header}>
                {category.nome_categoria.toUpperCase() === "BIGLIETTI" ? (
                  <>
                    <div
                      style={styles.nameLabelBox}
                      onClick={() => {
                        if (ordine === mapLabel("tipologia")) {
                          setAscendente(!ascendente);
                        } else {
                          setOrdine(mapLabel("tipologia"));
                          setAscendente(true);
                        }
                      }}
                    >
                      <p
                        style={
                          ordine === "nome_articolo"
                            ? styles.labelWithArrow
                            : styles.label
                        }
                      >
                        Tipologia
                      </p>
                      {ordine === "nome_articolo" && (
                        <img
                          alt="Order Arrow icon"
                          src={ascendente ? orderArrowUp : orderArrowDown}
                          style={{ width: 10, objectFit: "contain" }}
                        />
                      )}
                    </div>
                    <div
                      style={styles.labelBox}
                      onClick={() => {
                        if (ordine === mapLabel("prezzo")) {
                          setAscendente(!ascendente);
                        } else {
                          setOrdine(mapLabel("prezzo"));
                          setAscendente(true);
                        }
                      }}
                    >
                      <p
                        style={
                          ordine === "prezzo_articolo"
                            ? styles.labelWithArrow
                            : styles.label
                        }
                      >
                        Prezzo
                      </p>
                      {ordine === "prezzo_articolo" && (
                        <img
                          alt="Order Arrow icon"
                          src={ascendente ? orderArrowUp : orderArrowDown}
                          style={{ width: 10, objectFit: "contain" }}
                        />
                      )}
                    </div>
                    <div
                      style={styles.labelBox}
                      onClick={() => {
                        if (ordine === mapLabel("ingressi consentiti")) {
                          setAscendente(!ascendente);
                        } else {
                          setOrdine(mapLabel("ingressi consentiti"));
                          setAscendente(true);
                        }
                      }}
                    >
                      <p
                        style={
                          ordine === "num_ingressi"
                            ? styles.labelWithArrow
                            : styles.label
                        }
                      >
                        Ingressi consentiti
                      </p>
                      {ordine === "num_ingressi" && (
                        <img
                          alt="Order Arrow icon"
                          src={ascendente ? orderArrowUp : orderArrowDown}
                          style={{ width: 10, objectFit: "contain" }}
                        />
                      )}
                    </div>
                    <div
                      style={styles.labelBox}
                      onClick={() => {
                        if (ordine === mapLabel("validità")) {
                          setAscendente(!ascendente);
                        } else {
                          setOrdine(mapLabel("validità"));
                          setAscendente(true);
                        }
                      }}
                    >
                      <p
                        style={
                          ordine === "validita"
                            ? styles.labelWithArrow
                            : styles.label
                        }
                      >
                        Validità
                      </p>
                      {ordine === "validita" && (
                        <img
                          alt="Order Arrow icon"
                          src={ascendente ? orderArrowUp : orderArrowDown}
                          style={{ width: 10, objectFit: "contain" }}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  labels.map((label, key) => {
                    return label === "Autore" ? (
                      category.nome_categoria.toUpperCase() === "LIBRI" ? (
                        <div
                          key={key}
                          style={styles.labelBox}
                          onClick={() => {
                            if (ordine === mapLabel(label.toLowerCase())) {
                              setAscendente(!ascendente);
                            } else {
                              setOrdine(mapLabel(label.toLowerCase()));
                              setAscendente(true);
                            }
                          }}
                        >
                          <p
                            style={
                              ordine === mapLabel(label.toLowerCase())
                                ? styles.labelWithArrow
                                : styles.label
                            }
                          >
                            {label}
                          </p>
                          {ordine === mapLabel(label.toLowerCase()) && (
                            <img
                              alt="Order Arrow icon"
                              src={ascendente ? orderArrowUp : orderArrowDown}
                              style={{ width: 10, objectFit: "contain" }}
                            />
                          )}
                        </div>
                      ) : null
                    ) : label === "Codice" ? (
                      category.nome_categoria.toUpperCase() === "LIBRI" ? (
                        <div
                          key={key}
                          style={{ ...styles.labelBox, cursor: "default" }}
                        >
                          <p style={styles.label}>{label}</p>
                        </div>
                      ) : null
                    ) : label !== "Giacenza" ? (
                      <div
                        key={key}
                        style={
                          label === "Articolo"
                            ? styles.nameLabelBox
                            : styles.labelBox
                        }
                        onClick={() => {
                          if (ordine === mapLabel(label.toLowerCase())) {
                            setAscendente(!ascendente);
                          } else {
                            setOrdine(mapLabel(label.toLowerCase()));
                            setAscendente(true);
                          }
                        }}
                      >
                        <p
                          style={
                            ordine === mapLabel(label.toLowerCase())
                              ? styles.labelWithArrow
                              : styles.label
                          }
                        >
                          {label}
                        </p>
                        {ordine === mapLabel(label.toLowerCase()) && (
                          <img
                            alt="Order Arrow icon"
                            src={ascendente ? orderArrowUp : orderArrowDown}
                            style={{ width: 10, objectFit: "contain" }}
                          />
                        )}
                      </div>
                    ) : (
                      <div
                        key={key}
                        style={{ ...styles.labelBox, cursor: "default" }}
                      >
                        <p style={styles.label}>{label}</p>
                      </div>
                    );
                  })
                )}
              </div>
            ) : (
              <p style={styles.emptyListWarning}>Nessun risultato</p>
            )}
            <div
              style={{ ...styles.scroller, maxHeight: 400, width: "100%" }}
              id="scroll"
            >
              {filteredList.length !== 0 &&
                filteredList
                  .sort((a, b) => {
                    if (ascendente) {
                      if (a[ordine] > b[ordine]) {
                        return 1;
                      } else {
                        return -1;
                      }
                    } else {
                      if (a[ordine] < b[ordine]) {
                        return 1;
                      } else {
                        return -1;
                      }
                    }
                  })
                  .map((item, key) => (
                    <Item
                      key={key}
                      item={item}
                      isTicket={
                        category.nome_categoria.toUpperCase() === "BIGLIETTI"
                      }
                      isBook={category.nome_categoria.toUpperCase() === "LIBRI"}
                      onDelete={() => onDeleteItem(item)}
                      onModify={() => onModifyItem(item)}
                      onAddStock={() => onAddStockItem(item)}
                      onModifyStock={() => onModifyStockItem(item)}
                      colors={colors}
                    />
                  ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Category;
