import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import share from "./../../img/share.png";
import { UserContext, OrgContext } from "../../contexts";

const ShareButton = ({ report, monthReport }) => {
  const [hoverShare, setHoverShare] = useState(false);

  const org = useContext(OrgContext)?.org;
  const colors = useContext(UserContext)?.colors;
  const [categories, setCategories] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const checkAnyPerformance = (performanceObject) => {
    if (
      performanceObject !== undefined &&
      Object.keys(performanceObject).length !== 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (report !== undefined) {
      setCategories((prevCategories) => {
        let tmpCat = [...prevCategories];
        Object.keys(report.categories).forEach((key) => {
          tmpCat.push(
            "_" +
              key +
              "_" +
              ": " +
              report.categories[key].tot +
              ", €" +
              report.categories[key].incassi.toFixed(2) +
              "%0a"
          );
          if (checkAnyPerformance(report.categories[key].performances)) {
            Object.keys(report.categories[key].performances).forEach((pKey) => {
              tmpCat.push(
                "- _" +
                  pKey +
                  "_" +
                  ": " +
                  report.categories[key].performances[pKey].tot +
                  ", €" +
                  report.categories[key].performances[pKey].incassi.toFixed(2) +
                  "%0a"
              );
            });
          }
        });
        return tmpCat;
      });

      setPaymentMethods((prevPaymentMethods) => {
        let tmpMethods = [...prevPaymentMethods];
        Object.keys(report.paymentMethods).forEach((key) => {
          tmpMethods.push(
            "_" +
              key +
              "_" +
              ": € " +
              report.paymentMethods[key].incassi.toFixed(2) +
              "%0a"
          );
        });
        return tmpMethods;
      });
    }
  }, [report]);

  const styles = {
    button: {
      outline: "none",
      border: "none",
      display: "flex",
      backgroundColor: colors.primary,
      width: 80,
      height: 80,
      borderRadius: 50,
      alignItems: "center",
      justifyContent: "center",
      textDecorationLine: "none",
      color: colors.white,
      fontWeight: "800",
      cursor: "pointer",
    },
    buttonBox: {
      position: "absolute",
      zIndex: 200,
      left: 40,
      bottom: 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    hoverLabel: {
      textAlign: "center",
      fontSize: 12,
      maxWidth: 80,
    },
  };

  const prevMonth =
    monthReport?.revenue > 0
      ? (
          (monthReport?.revenue / monthReport?.numberOfDays) *
          monthReport?.numberOfDaysOfCurrentMonth
        ).toFixed(2)
      : "0.00";

  const text =
    "*REPORT " +
    org?.nome.toUpperCase() +
    "*%0a*" +
    moment(new Date()).format("DD MMM yyyy") +
    "*" +
    "%0a" +
    "%0a" +
    "_Totale giornaliero:_ €" +
    (report?.revenue ? report?.revenue?.toFixed(2) : "0.00") +
    "%0a_Totale mensile:_ €" +
    (monthReport?.revenue ? monthReport?.revenue?.toFixed(2) : "0.00") +
    "%0a_Previsionale mensile:_ €" +
    prevMonth +
    "%0a" +
    "%0a" +
    categories.join("") +
    "%0a" +
    paymentMethods.join("") +
    "%0a_supplied by Emma_";

  return (
    <>
      <div
        style={styles.buttonBox}
        onMouseEnter={() => setHoverShare(true)}
        onMouseLeave={() => setHoverShare(false)}
      >
        {hoverShare && (
          <p style={styles.hoverLabel}>Condividi report su whatsapp</p>
        )}
        <a
          style={styles.button}
          href={"https://wa.me/?text=" + text}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            alt="Share icon"
            src={share}
            style={{ width: 35, objectFit: "contain" }}
          ></img>
        </a>
      </div>
    </>
  );
};

export default ShareButton;
