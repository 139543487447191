import React, { useEffect, useState } from "react";
import ReactExport from "react-export-excel";
import moment from "moment";
import downloadExcel from "../../img/excel.png";

const camelCase = (str) => {
  return str.substring(0, 1).toUpperCase() + str.substring(1);
};

const getLabel = (col) => {
  switch (col) {
    case "nome_partecipante":
      return "Nome";
    case "cognome_partecipante":
      return "Cognome";
    case "numero_telefono":
      return "Numero";
    case "numero_partecipanti":
      return "Partecipanti";
    case "created_at":
      return "Data e ora prenotazione";
    default:
      return !col.includes("_") ? col : col.replace("_", " ");
  }
};

const GetExcel = ({ event }) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [reservations, setReservations] = useState([]);
  const [waitingList, setWaitingList] = useState([]);

  const [title, setTitle] = useState("");

  const filterColumns = (data) => {
    // Get column names
    const columns = Object.keys(data[0]);

    const filterColsByKey = columns.filter(
      (c) =>
        c !== "id" &&
        c !== "organisation" &&
        c !== "mezzo_pubblicita" &&
        c !== "ora_inizio" &&
        c !== "ora_fine" &&
        c !== "giorno_prenotazione" &&
        c !== "main_evento" &&
        c !== "flag_visita" &&
        c !== "evento" &&
        c !== "created_by" &&
        c !== "updated_by" &&
        c !== "accreditato" &&
        c !== "codice" &&
        c !== "datetime_accredito" &&
        c !== "visitatore" &&
        c !== "pagato" &&
        c !== "note" &&
        c !== "prezzo" &&
        c !== "metodo_pagamento" &&
        c !== "eventoNew" &&
        c !== "updated_at" &&
        c !== "bambini" &&
        c !== "gruppo"
    );

    return filterColsByKey; // OR return columns
  };

  const styles = {
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      cursor: "pointer",
    },
  };

  useEffect(() => {
    setTitle(
      event.nome_evento + " - " + moment(event.ora_inizio).format("DDMMMyyyy")
    );
    let reservations = event.prenotazioni_evento;
    reservations.sort((a, b) => a.id - b.id);

    reservations.forEach((res) => {
      res.created_at = moment(res.created_at).format("YYYY-MM-DD, HH:mm");
      for (let key in res) {
        if (res[key] === null) {
          res[key] = "";
        }
      }
    });

    setReservations(reservations);
    let waitingList = event.lista_attesa.sort((a, b) => (a.id > b.id ? 1 : -1));
    setWaitingList(waitingList);
  }, [event]);

  return (
    <div style={{ alignItems: "center", justifyContent: "center" }}>
      {reservations.length > 0 ? (
        waitingList.length > 0 ? (
          <ExcelFile
            filename={"Prenotati " + title}
            element={
              <button style={styles.button}>
                <img
                  alt="Download Excel"
                  src={downloadExcel}
                  style={{ height: 20, objectFit: "contain" }}
                />
              </button>
            }
          >
            <ExcelSheet data={reservations} name={"Lista prenotati"}>
              {reservations.length > 0 &&
                filterColumns(reservations).map((col, key) => {
                  return (
                    <ExcelColumn
                      key={key}
                      label={camelCase(getLabel(col))}
                      value={col}
                    />
                  );
                })}
            </ExcelSheet>
            <ExcelSheet data={waitingList} name={"Lista attesa"}>
              {waitingList.length > 0 &&
                filterColumns(waitingList).map((col, key) => {
                  return (
                    <ExcelColumn
                      key={key}
                      label={camelCase(getLabel(col))}
                      value={col}
                    />
                  );
                })}
            </ExcelSheet>
          </ExcelFile>
        ) : (
          <ExcelFile
            filename={"Prenotati " + title}
            element={
              <button style={styles.button}>
                <img
                  alt="Download Excel"
                  src={downloadExcel}
                  style={{ height: 20, objectFit: "contain" }}
                />
              </button>
            }
          >
            <ExcelSheet data={reservations} name={"Lista prenotati"}>
              {reservations.length > 0 &&
                filterColumns(reservations).map((col, key) => {
                  return (
                    <ExcelColumn
                      key={key}
                      label={camelCase(getLabel(col))}
                      value={col}
                    />
                  );
                })}
            </ExcelSheet>
          </ExcelFile>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default GetExcel;
