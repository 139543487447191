import moment from "moment";
import config from "../config";
import { handleResponse, authHeader } from "../_helpers";

export const cardsService = {
  getCardTypes,
  getCardTypesPerOrg,
  getCards,
  getCardsSoldOnline,
  getCardSales,
  countInvoiceCards,
  getCardGroup,
  createCardSale,
  createSaleError,
  createCards,
  activateCards,
  updateCard,
  checkCardNumber,
  markInvoiceAs,
  renewCards,
  countCards,
  renewCardsOnline,
  findCard,
  getTypeFromId,
};

function getCardGroup(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/gruppo_tesseres/${id}`, requestOptions).then(
    handleResponse
  );
}

function getCardTypesPerOrg(orgId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/tipologie_tesseres?organisation=${orgId}`,
    requestOptions
  ).then(handleResponse);
}

function getTypeFromId(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/tipologie_tesseres?id=${id}`,
    requestOptions
  ).then(handleResponse);
}

function getCardTypes(orgId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/tipologie_tesseres?organisation=${orgId}`,
    requestOptions
  ).then(handleResponse);
}

function getCards(
  card,
  target,
  orgId,
  start,
  limit,
  vipFilter,
  hasKeyword,
  searchKey,
  expirationFilter
) {
  var qs = require("qs");

  const _card = card === undefined ? 0 : card;
  const _start = start === undefined ? 0 : start;
  const _limit = limit === undefined ? -1 : limit;
  const _target = target === undefined ? -1 : target;
  const _hasKeyword = hasKeyword === undefined ? false : hasKeyword;
  const _vipFilter = vipFilter === undefined ? false : vipFilter;
  const _expirationFilter =
    expirationFilter === undefined ? 0 : expirationFilter;

  const requestOptions = { method: "GET", headers: authHeader() };

  var req = `${config.api.url}/tesseres?target_tessere.tipologie_tessere=${_card}&organisation=${orgId}&_start=${_start}&_limit=${_limit}&_sort=id:ASC`;

  if (_target !== -1) {
    req = req + `&target_tessere=${_target}`;
  }
  if (_hasKeyword === true) {
    const _searchKey = searchKey;
    const query = qs.stringify({
      _where: {
        _or: [
          { numero_contains: _searchKey },
          { nome_contains: _searchKey },
          { cognome_contains: _searchKey },
          { email_contains: _searchKey },
        ],
      },
    });
    req = req + `&${query}`;
  }
  if (_vipFilter === true) {
    const _vipFilter = vipFilter;
    req = req + `&vip=${_vipFilter}`;
  }

  var today = moment(new Date().setHours(0, 0, 0, 0)).format("YYYY-MM-DD");
  if (_expirationFilter === 1) {
    //expired
    req = req + `&scadenza_lt=${today}`;
  } else if (_expirationFilter === 2) {
    //valid
    req = req + `&scadenza_gte=${today}`;
  } else {
    //
  }

  return fetch(req, requestOptions).then(handleResponse);
}

function countCards(
  card,
  target,
  orgId,
  vipFilter,
  hasKeyword,
  searchKey,
  expirationFilter
) {
  var qs = require("qs");
  const _card = card === undefined ? 0 : card;

  const _target = target === undefined ? -1 : target;
  const _hasKeyword = hasKeyword === undefined ? false : hasKeyword;
  const _vipFilter = vipFilter === undefined ? false : vipFilter;
  const _expirationFilter =
    expirationFilter === undefined ? 0 : expirationFilter;

  const requestOptions = { method: "GET", headers: authHeader() };

  var req = `${config.api.url}/tesseres/count?target_tessere.tipologie_tessere=${_card}&organisation=${orgId}&_sort=id:ASC`;

  if (_target !== -1) {
    req = req + `&target_tessere=${_target}`;
  }
  if (_hasKeyword === true) {
    const _searchKey = searchKey;
    const query = qs.stringify({
      _where: {
        _or: [
          { numero_contains: _searchKey },
          { nome_contains: _searchKey },
          { cognome_contains: _searchKey },
          { email_contains: _searchKey },
        ],
      },
    });
    req = req + `&${query}`;
  }
  if (_vipFilter === true) {
    const _vipFilter = vipFilter;
    req = req + `&vip=${_vipFilter}`;
  }

  var today = moment(new Date().setHours(0, 0, 0, 0)).format("YYYY-MM-DD");
  if (_expirationFilter === 1) {
    //expired
    req = req + `&scadenza_lt=${today}`;
  } else if (_expirationFilter === 2) {
    //valid
    req = req + `&scadenza_gte=${today}`;
  } else {
    //
  }

  return fetch(req, requestOptions).then(handleResponse);
}

function getCardSales(orgId, start, limit, invoice, searchKey) {
  var qs = require("qs");

  const _start = start === undefined ? 0 : start;
  const _limit = limit === undefined ? -1 : limit;
  const _invoice = invoice; // === undefined ? false : invoice;
  const _searchKey = searchKey === undefined ? "" : searchKey;

  const requestOptions = { method: "GET", headers: authHeader() };
  if (_searchKey === "") {
    if (invoice !== undefined) {
      return fetch(
        `${config.api.url}/vendita_tesseres?organisation=${orgId}&_start=${_start}&_limit=${_limit}&fattura=${_invoice}&vip=false`,
        requestOptions
      ).then(handleResponse);
    } else {
      return fetch(
        `${config.api.url}/vendita_tesseres?organisation=${orgId}&_start=${_start}&_limit=${_limit}&vip=false`,
        requestOptions
      ).then(handleResponse);
    }
  } else {
    const query = qs.stringify({
      _where: {
        _or: [{ nome_contains: _searchKey }, { cognome_contains: _searchKey }],
      },
    });
    if (invoice !== undefined) {
      return fetch(
        `${config.api.url}/vendita_tesseres?organisation=${orgId}&_start=${_start}&_limit=${_limit}&fattura=${_invoice}&${query}&vip=false`,
        requestOptions
      ).then(handleResponse);
    } else {
      return fetch(
        `${config.api.url}/vendita_tesseres?organisation=${orgId}&_start=${_start}&_limit=${_limit}&${query}&vip=false`,
        requestOptions
      ).then(handleResponse);
    }
  }
}

function countInvoiceCards(orgId, invoice, searchKey) {
  var qs = require("qs");

  const _invoice = invoice === undefined ? false : invoice;
  const _searchKey = searchKey === undefined ? "" : searchKey;

  const requestOptions = { method: "GET", headers: authHeader() };
  if (_searchKey === "") {
    return fetch(
      `${config.api.url}/vendita_tesseres/count?organisation=${orgId}&fattura=${_invoice}&vip=false`,
      requestOptions
    ).then(handleResponse);
  } else {
    const query = qs.stringify({
      _where: {
        _or: [{ nome_contains: _searchKey }, { cognome_contains: _searchKey }],
      },
    });
    return fetch(
      `${config.api.url}/vendita_tesseres/count?organisation=${orgId}&fattura=${_invoice}&${query}&vip=false`,
      requestOptions
    ).then(handleResponse);
  }
}

function checkCardNumber(numero, orgId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    orgId !== undefined
      ? `${config.api.url}/tesseres?numero=${numero}&organisation=${orgId}`
      : `${config.api.url}/tesseres?numero=${numero}`,
    requestOptions
  ).then(handleResponse);
}

function findCard(orgId, numero, cognome) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/tesseres?numero=${numero}&&cognome_contains=${cognome}&&organisation=${orgId}`,
    requestOptions
  ).then(handleResponse);
}

function getCardsSoldOnline(orgId, searchKey, expirationFilter) {
  var qs = require("qs");
  const _expirationFilter =
    expirationFilter === undefined ? 2 : expirationFilter;

  const requestOptions = { method: "GET", headers: authHeader() };

  var req = `${config.api.url}/vendita_tesseres?organisation=${orgId}&luogo_vendita=online&_limit=-1`;

  if (searchKey !== undefined && searchKey !== "") {
    const _searchKey = searchKey;
    const query = qs.stringify({
      _where: {
        _or: [
          { codice_contains: _searchKey },
          { nome_contains: _searchKey },
          { cognome_contains: _searchKey },
          { email_contains: _searchKey },
        ],
      },
    });
    req = req + `&${query}`;
  }

  var today = moment(
    new Date(new Date().setHours(0, 0, 0, 0)).setHours(0, 0, 0, 0)
  ).format("YYYY-MM-DD");
  if (_expirationFilter === 1) {
    //expired
    req = req + `&scadenza_lt=${today}`;
  } else if (_expirationFilter === 2) {
    //valid
    req = req + `&scadenza_gte=${today}`;
  } else {
    //
  }

  return fetch(req, requestOptions).then(handleResponse);
}

function createCardSale(
  orgId,
  target_tessere,
  email,
  nome,
  cognome,
  //nascita,
  indirizzo,
  hasVAT,
  codice_fiscale,
  partita_iva,
  sdi,
  pagamento,
  luogo_vendita,
  prezzo,
  consenso_profilazione,
  consenso_marketing,
  metodo_pagamento,
  rinnovo,
  regalo,
  gift_form
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(
      hasVAT
        ? {
            orgId,
            target_tessere,
            email,
            nome,
            cognome,
            //nascita,
            indirizzo,
            partita_iva,
            sdi,
            pagamento,
            prezzo,
            luogo_vendita,
            consenso_profilazione,
            consenso_marketing,
            metodo_pagamento,
            rinnovo,
            regalo,
            gift_form,
          }
        : {
            orgId,
            target_tessere,
            email,
            nome,
            cognome,
            //nascita,
            indirizzo,
            codice_fiscale,
            pagamento,
            prezzo,
            luogo_vendita,
            consenso_profilazione,
            consenso_marketing,
            metodo_pagamento,
            rinnovo,
            regalo,
            gift_form,
          }
    ),
  };

  return fetch(`${config.api.url}/vendita_tesseres`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function createSaleError(
  orgId,
  target_tessere,
  email,
  nome,
  cognome,
  //nascita,
  indirizzo,
  hasVAT,
  codice_fiscale,
  partita_iva,
  sdi,
  pagamento,
  errore
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(
      hasVAT
        ? {
            orgId,
            target_tessere,
            email,
            nome,
            cognome,
            //nascita,
            indirizzo,
            partita_iva,
            sdi,
            pagamento,
            errore,
          }
        : {
            orgId,
            target_tessere,
            email,
            nome,
            cognome,
            //nascita,
            indirizzo,
            codice_fiscale,
            pagamento,
            errore,
          }
    ),
  };

  return fetch(`${config.api.url}/errore_venditas`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function createCards(target_tessere, tessere, dati_fatturazione) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      target_tessere,
      tessere,
      dati_fatturazione,
    }),
  };

  return fetch(`${config.api.url}/tesseres`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function activateCards(target_tessere, tessere, vendita_tessere) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      target_tessere,
      tessere,
      vendita_tessere,
    }),
  };

  return fetch(`${config.api.url}/tesseres`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function renewCards(target_tessere, tessera, dati_fatturazione) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      target_tessere,
      tessera,
      dati_fatturazione,
    }),
  };

  return fetch(`${config.api.url}/tesseres/renew`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function renewCardsOnline(orgId, target_tessere, tessera, dati_fatturazione) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      orgId,
      target_tessere,
      tessera,
      dati_fatturazione,
    }),
  };

  return fetch(`${config.api.url}/tesseres/renewOnline`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function updateCard(
  id,
  numero,
  nome,
  cognome,
  email,
  nascita,
  indirizzo,
  consenso_profilazione,
  consenso_marketing
) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      numero,
      nome,
      cognome,
      email,
      nascita,
      indirizzo,
      consenso_profilazione,
      consenso_marketing,
    }),
  };

  return fetch(`${config.api.url}/tesseres/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function markInvoiceAs(id, fattura, data_fattura) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({ fattura, data_fattura }),
  };

  return fetch(`${config.api.url}/vendita_tesseres/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}
