import React from "react";

const Week = ({
  lunedi,
  martedi,
  mercoledi,
  giovedi,
  venerdi,
  sabato,
  domenica,
  allowEdit,
  setLunedi,
  setMartedi,
  setMercoledi,
  setGiovedi,
  setVenerdi,
  setSabato,
  setDomenica,
  colors,
}) => {
  const styles = {
    selected: {
      width: 30,
      height: 30,
      borderRadius: 15,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.primary,
      margin: 5,
      outline: "none",
      border: "none",
      cursor: "pointer",
    },
    unselected: {
      width: 30,
      height: 30,
      borderRadius: 15,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.secondary,
      margin: 5,
      outline: "none",
      border: "none",
      cursor: "pointer",
    },
    text: {
      color: colors.white,
      fontWeight: "700",
    },
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "space-around",
        justifyContent: "center",
      }}
    >
      {allowEdit && (
        <>
          <button
            style={lunedi ? styles.selected : styles.unselected}
            onClick={() => setLunedi(!lunedi)}
            disabled={!allowEdit}
          >
            <p style={styles.text}>L</p>
          </button>
          <button
            style={martedi ? styles.selected : styles.unselected}
            onClick={() => setMartedi(!martedi)}
            disabled={!allowEdit}
          >
            <p style={styles.text}>M</p>
          </button>
          <button
            style={mercoledi ? styles.selected : styles.unselected}
            onClick={() => setMercoledi(!mercoledi)}
            disabled={!allowEdit}
          >
            <p style={styles.text}>M</p>
          </button>
          <button
            style={giovedi ? styles.selected : styles.unselected}
            onClick={() => setGiovedi(!giovedi)}
            disabled={!allowEdit}
          >
            <p style={styles.text}>G</p>
          </button>
          <button
            style={venerdi ? styles.selected : styles.unselected}
            onClick={() => setVenerdi(!venerdi)}
            disabled={!allowEdit}
          >
            <p style={styles.text}>V</p>
          </button>
          <button
            style={sabato ? styles.selected : styles.unselected}
            onClick={() => setSabato(!sabato)}
            disabled={!allowEdit}
          >
            <p style={styles.text}>S</p>
          </button>
          <button
            style={domenica ? styles.selected : styles.unselected}
            onClick={() => setDomenica(!domenica)}
            disabled={!allowEdit}
          >
            <p style={styles.text}>D</p>
          </button>
        </>
      )}
    </div>
  );
};

export default Week;
