import React, { useEffect, useState, useContext } from "react";
import ReactExport from "react-export-excel";
import { UserContext, OrgContext } from "../../contexts";
import search from "../../img/search.png";
import refresh from "../../img/refresh.png";
import add from "../../img/add_black.png";
import download from "../../img/download.png";
import { LanguageContext } from "../../containers/language";
import Table from "./table";
import InterventionPage from "./interventionPage";
import ReactLoading from "react-loading";
import { interventionService } from "../../_services";
import Pagination from "react-js-pagination";
import moment from "moment";
import "moment/locale/it";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const itemsPerPageOptions = [10, 20, 50, 100];

function isEmpty(field) {
  return field === "" || field === null || field === undefined;
}

const InterventionRegister = () => {
  const colors = useContext(UserContext)?.colors;
  const org = useContext(OrgContext)?.org;
  const newRecord = {
    segnalazione: {
      info: org.configurazioni.intervento_segnalazione,
    },
  };
  const { dictionary } = useContext(LanguageContext);
  const [openRecord, setOpenRecord] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [tempSearchKey, setTempSearchKey] = useState("");
  const [records, setRecords] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [reportReady, setReportReady] = useState(false);

  const [isCreation, setIsCreation] = useState(false);

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [recordsLoaded, setRecordsLoaded] = useState(false);

  const [statusFilter, setStatusFilter] = useState("");
  const statusFiltersList = [
    { value: "", label: "Tutti gli stati" },
    { value: 1, label: "Non risolte" },
    { value: 2, label: "Risolte" },
    { value: 3, label: "Intervento in corso" },
    { value: 4, label: "Nuove" },
  ];

  const resetNewRecord = () => {
    for (let item of newRecord.segnalazione.info) {
      item.value = null;
    }
  };

  const translateStatus = (statusFilter) => {
    switch (statusFilter) {
      case 1:
        return "Non risolta";
      case 2:
        return "Risolta";
      case 3:
        return "Intervento in corso";
      case 4:
        return "Nuova";
      default:
        return null;
    }
  };

  const translateStatusForReportName = (statusFilter) => {
    switch (statusFilter) {
      case 1:
        return "Non-risolti";
      case 2:
        return "Risolti";
      case 3:
        return "In-corso";
      case 4:
        return "Nuovi";
      default:
        return "";
    }
  };

  const getReportColumns = (records) => {
    var tempColumnsList = [];
    var headerS = [];
    var header1L = [];
    var header2L = [];
    headerS = ["Identificativo", "Titolo", "Data creazione", "Stato"];

    for (var item of records) {
      if (item.segnalazione) {
        for (var param of item.segnalazione.info) {
          if (!headerS.includes(param.name)) headerS.push(param.name);
        }

        if (item.intervento1L) {
          if (!header1L.includes("1L - Stato")) header1L.push("1L - Stato");
          if (!header1L.includes("1L - Apertura"))
            header1L.push("1L - Apertura");
          if (!header1L.includes("1L - Chiusura"))
            header1L.push("1L - Chiusura");
          for (var param1 of item.intervento1L.info) {
            if (!header1L.includes("1L - " + param1.name))
              header1L.push("1L - " + param1.name);
          }

          if (item.intervento2L) {
            if (!header2L.includes("2L - Stato")) header2L.push("2L - Stato");
            if (!header2L.includes("2L - Apertura"))
              header2L.push("2L - Apertura");
            if (!header2L.includes("2L - Chiusura"))
              header2L.push("2L - Chiusura");
            for (var param2 of item.intervento2L.info) {
              if (!header2L.includes("2L - " + param2.name))
                header2L.push("2L - " + param2.name);
            }
          }
        }
      }
    }

    tempColumnsList = headerS.concat(header1L).concat(header2L);
    return tempColumnsList;
  };

  const getReportRow = (reportColumns, record) => {
    var colS = [];
    var firstFixedColS = [
      "Identificativo",
      "Titolo",
      "Data creazione",
      "Stato",
    ];
    var col1L = [];
    var firstFixedCol1L = ["Stato", "Apertura", "Chiusura"];
    var col2L = [];
    var firstFixedCol2L = firstFixedCol1L;

    var colSTotLength = 0;
    var col1LTotLength = 0;
    var col2LTotLength = 0;

    for (let c of reportColumns) {
      if (c.includes("1L - ")) {
        col1LTotLength++;
        if (!firstFixedCol1L.includes(c.split("1L - ")[1]))
          col1L.push(c.split("1L - ")[1]);
      } else if (c.includes("2L - ")) {
        col2LTotLength++;
        if (!firstFixedCol2L.includes(c.split("2L - ")[1]))
          col2L.push(c.split("2L - ")[1]);
      } else {
        colSTotLength++;
        if (!firstFixedColS.includes(c)) colS.push(c);
      }
    }

    var tempData = [];
    tempData = [record.id, record.titolo];

    if (record.segnalazione) {
      tempData.push(
        record.segnalazione.data_creazione
          ? moment(record.segnalazione.data_creazione).format(
              "DD/MM/yyyy HH:mm"
            )
          : "-"
      );
      tempData.push(
        record.segnalazione.stato ? record.segnalazione.stato : "-"
      );
      for (let t0 of colS) {
        record.segnalazione.info.find((el) => el.name.includes(t0)) &&
        record.segnalazione.info.find((el) => el.name.includes(t0)).value
          ? tempData.push(
              record.segnalazione.info.find((el) => el.name.includes(t0)).value
            )
          : tempData.push("-");
      }

      if (record.intervento1L) {
        tempData.push(
          record.intervento1L.stato ? record.intervento1L.stato : "-"
        );
        tempData.push(
          record.intervento1L.data_creazione
            ? moment(record.intervento1L.data_creazione).format(
                "DD/MM/yyyy HH:mm"
              )
            : "-"
        );
        tempData.push(
          record.intervento1L.data_chiusura
            ? moment(record.intervento1L.data_chiusura).format(
                "DD/MM/yyyy HH:mm"
              )
            : "-"
        );
        for (let t1 of col1L) {
          record.intervento1L.info.find((el) => el.name.includes(t1)) &&
          record.intervento1L.info.find((el) => el.name.includes(t1)).value
            ? tempData.push(
                record.intervento1L.info.find((el) => el.name.includes(t1))
                  .value
              )
            : tempData.push("-");
        }

        if (record.intervento2L) {
          tempData.push(
            record.intervento2L.stato ? record.intervento2L.stato : "-"
          );
          tempData.push(
            record.intervento2L.data_creazione
              ? moment(record.intervento2L.data_creazione).format(
                  "DD/MM/yyyy HH:mm"
                )
              : "-"
          );
          tempData.push(
            record.intervento2L.data_chiusura
              ? moment(record.intervento2L.data_chiusura).format(
                  "DD/MM/yyyy HH:mm"
                )
              : "-"
          );
          for (let t2 of col2L) {
            record.intervento2L.info.find((el) => el.name.includes(t2)) &&
            record.intervento2L.info.find((el) => el.name.includes(t2)).value
              ? tempData.push(
                  record.intervento2L.info.find((el) => el.name.includes(t2))
                    .value
                )
              : tempData.push("-");
          }
        } else {
          for (let i = 0; i < col2LTotLength; i++) {
            tempData.push("-");
          }
        }
      } else {
        for (let i = 0; i < col1LTotLength + col2LTotLength; i++) {
          tempData.push("-");
        }
      }
    } else {
      for (
        let i = 0;
        i < colSTotLength + col1LTotLength + col2LTotLength;
        i++
      ) {
        tempData.push("-");
      }
    }
    return tempData;
  };

  const getRecords = (start, limit, searchKey, statusFilter) => {
    var tempStatusFilter = translateStatus(statusFilter);

    setRecordsLoaded(false);

    interventionService
      .countRecords(searchKey, tempStatusFilter, org.id)
      .then((allRecords) => {
        setTotalItemsCount(allRecords);

        interventionService
          .getRecords(start, limit, searchKey, tempStatusFilter, org.id)
          .then((records) => {
            setRecords(records);
            setRecordsLoaded(true);
          });
      });

    setReportReady(false);
    interventionService
      .getRecords(0, -1, searchKey, tempStatusFilter, org.id)
      .then((records) => {
        var finalColumns = getReportColumns(records);

        const finalData = records.map((item) => {
          return getReportRow(finalColumns, item);
        });

        setExcelData([{ columns: finalColumns, data: finalData }]);
        setReportReady(true);
      });
  };

  const startingSearch = () => {
    // reset searchKey
    let currentSearchKey = "";
    setSearchKey(currentSearchKey);

    let tempActivePage = 1;
    setActivePage(tempActivePage);

    getRecords(
      itemsPerPage * tempActivePage - itemsPerPage,
      itemsPerPage,
      currentSearchKey,
      statusFilter
    );
  };

  useEffect(() => {
    if (!openRecord) {
      let tempActivePage = 1;
      setActivePage(tempActivePage);
      getRecords(
        itemsPerPage * tempActivePage - itemsPerPage,
        itemsPerPage,
        searchKey,
        statusFilter
      );
    } else {
      setTempSearchKey("");
      setSearchKey("");
      setStatusFilter("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, searchKey, statusFilter, openRecord]);

  useEffect(() => {
    if (!openRecord) {
      getRecords(
        itemsPerPage * activePage - itemsPerPage,
        itemsPerPage,
        searchKey,
        statusFilter
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  useEffect(() => {
    const handleSubmit = () => {
      setSearchKey(tempSearchKey);

      let tempActivePage = 1;
      setActivePage(tempActivePage);
      getRecords(
        itemsPerPage * tempActivePage - itemsPerPage,
        itemsPerPage,
        tempSearchKey,
        statusFilter
      );
    };

    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, tempSearchKey]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      overflowX: "scroll",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    rowBottom: {
      display: "flex",
      justifyContent: "center",
      width: !isEmpty(openRecord) ? "100%" : "90%",
      overflow: "scroll",
    },
    subcontainer: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    inputSearch: {
      borderRadius: 40,
      border: "1px solid #F0F0F0",
      width: "50%",
      height: 40,
      display: "flex",
      flexDirection: "row",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
    goBackButton: {
      width: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    saveButton: {
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: colors.darkgray,
      margin: 30,
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 15,
    },
    filterButton: {
      border: "1px solid #F0F0F0",
      outline: "none",
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
    addButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    downloadButton: {
      border: "none",
      outline: "none",
      backgroundColor: colors.darkgray,
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      width: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    menu: {
      border: "1px solid lightgray",
      width: 165,
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 10,
      color: colors.darkgray,
      borderRadius: 20,
      backgroundColor: "transparent",
      outline: "none",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.body}>
        <div style={styles.rowBottom}>
          {!isEmpty(openRecord) ? (
            <InterventionPage
              setOpenRecord={setOpenRecord}
              openRecord={openRecord}
              isCreation={isCreation}
              setIsCreation={setIsCreation}
              getRecords={startingSearch}
            />
          ) : (
            <div style={styles.subcontainer}>
              <div style={{ ...styles.row, marginTop: 20 }}>
                <div
                  style={{
                    ...styles.row,
                    justifyContent: "start",
                    width: "100%",
                  }}
                >
                  {totalItemsCount > 1 || totalItemsCount === 0 ? (
                    <p
                      style={{
                        color: colors?.gray,
                        fontSize: 11,
                        textAlign: "left",
                      }}
                    >
                      {totalItemsCount} segnalazioni trovate
                    </p>
                  ) : (
                    <p
                      style={{
                        color: colors?.gray,
                        fontSize: 11,
                        textAlign: "left",
                      }}
                    >
                      {totalItemsCount} segnalazione trovata
                    </p>
                  )}
                </div>
              </div>

              <div
                style={{
                  ...styles.row,
                  justifyContent: "space-between",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <div style={styles.inputSearch}>
                  <img
                    alt="Search icon"
                    src={search}
                    style={{ width: 30, objectFit: "contain", margin: 10 }}
                  />
                  <input
                    type="text"
                    style={{
                      border: 0,
                      width: "100%",
                      borderRadius: 40,
                      outline: "none",
                    }}
                    value={tempSearchKey}
                    onChange={(event) => {
                      setTempSearchKey(event.target.value);
                    }}
                    placeholder={dictionary.search}
                  />
                  <button
                    style={{
                      ...styles.filterButton,
                    }}
                    onClick={() => {
                      setTempSearchKey("");
                      setSearchKey("");
                      startingSearch(); //come back to the original result search (i.e. the ones at the page opening)
                    }}
                  >
                    <img
                      alt="Refresh icon"
                      src={refresh}
                      style={{ width: 18, objectFit: "contain", margin: 2 }}
                    />
                  </button>
                </div>

                <div>
                  <select
                    name={
                      isEmpty(statusFilter)
                        ? statusFiltersList[0].label
                        : statusFiltersList[statusFilter].label
                    }
                    style={styles.menu}
                    value={
                      isEmpty(statusFilter)
                        ? statusFiltersList[0].value
                        : statusFiltersList[statusFilter].value
                    }
                    onChange={(event) => {
                      event.target.selectedIndex === 0
                        ? setStatusFilter("")
                        : setStatusFilter(
                            statusFiltersList[event.target.selectedIndex].value
                          );
                    }}
                  >
                    {statusFiltersList.map((opt, key) => (
                      <option key={key} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div style={styles.buttonBox}>
                  {records?.length > 0 && reportReady && (
                    <ExcelFile
                      filename={
                        "Interventi" +
                        (!isEmpty(statusFilter)
                          ? "_" + translateStatusForReportName(statusFilter)
                          : "") +
                        (searchKey !== ""
                          ? "_Titolo-contiene-" + searchKey
                          : "")
                      }
                      element={
                        <button
                          style={{ ...styles.downloadButton, marginLeft: 10 }}
                        >
                          <img
                            alt="Download icon"
                            src={download}
                            style={{ width: 15, objectFit: "contain" }}
                          />
                        </button>
                      }
                    >
                      <ExcelSheet
                        dataSet={excelData}
                        name={
                          "Interventi" +
                          (!isEmpty(statusFilter)
                            ? ", " + translateStatusForReportName(statusFilter)
                            : "") +
                          (searchKey !== ""
                            ? ", Titolo-contiene-" + searchKey
                            : "")
                        }
                      />
                    </ExcelFile>
                  )}
                </div>

                <button
                  style={styles.addButton}
                  onClick={() => {
                    setIsCreation(true);
                    resetNewRecord();
                    setOpenRecord(newRecord);
                  }}
                >
                  <img
                    src={add}
                    style={{ height: 13, objectFit: "contain" }}
                    alt={"Add intervention"}
                  />
                  <p
                    style={{
                      fontSize: 12,
                      color: colors.darkgray,
                      margin: 0,
                      marginLeft: 5,
                      fontWeight: "600",
                    }}
                  >
                    Nuova segnalazione
                  </p>
                </button>
              </div>
              <div
                id="scroll"
                style={{ overflowY: recordsLoaded ? "scroll" : "hidden" }}
              >
                {recordsLoaded ? (
                  records.length > 0 ? (
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <Table
                        records={records}
                        colors={colors}
                        setOpenRecord={setOpenRecord}
                        activePage={activePage}
                        itemsPerPage={itemsPerPage}
                      />
                    </div>
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 14,
                        color: colors.gray,
                        marginTop: 80,
                      }}
                    >
                      Nessuna segnalazione
                    </p>
                  )
                ) : (
                  <div
                    style={{
                      display: "flex",
                      marginTop: 40,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ReactLoading
                      type={"spinningBubbles"}
                      color={colors?.primary}
                      height={50}
                      width={50}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{ width: "90%" }}>
        {records.length > 0 &&
          recordsLoaded &&
          totalItemsCount > itemsPerPageOptions[0] &&
          isEmpty(openRecord) && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridAutoRows: "1fr",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <select
                  name="options"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: 15,
                    height: 50,
                    width: 70,
                    padding: 10,
                    textAlign: "left",
                    marginRight: 10,
                  }}
                  value={itemsPerPage}
                  onChange={(event) => {
                    setItemsPerPage(parseInt(event.target.value));
                    setActivePage(1);
                  }}
                >
                  {itemsPerPageOptions.map((option, key) => {
                    return (
                      <option key={key} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
                <p style={{ color: colors?.gray, fontSize: 12 }}>
                  {dictionary.pageElements}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => setActivePage(pageNumber)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default InterventionRegister;
