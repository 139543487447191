import React, { useState, useContext } from "react";
import trash from "./../img/delete_gray.png";
import pencil from "./../img/modify_gray.png";
import moment from "moment";
import network from "./../img/network_gray.png";
import { UserContext, OrgContext } from "../contexts";

const Sale = ({ sale, setSaleSelected, setModifySelected }) => {
  const [hover, setHover] = useState(false);
  const [coordinates, setCoordinates] = useState(false);
  const [hoverNetwork, setHoverNetwork] = useState(false);

  const user = useContext(UserContext)?.user;
  const colors = useContext(UserContext)?.colors;

  const org = useContext(OrgContext)?.org;

  const styles = {
    field: {
      width: 170,
      fontSize: 15,
    },
    label: {
      position: "absolute",
      backgroundColor: colors?.quaternary,
      paddingLeft: 15,
      paddingRight: 15,
      borderRadius: 15,
      top: coordinates.y,
      left: coordinates.x,
      zIndex: 100,
      color: colors?.darkgray,
    },
  };
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ cursor: sale.deleted !== true && "pointer" }}
    >
      {hover && sale.deleted && (
        <div style={styles.label}>
          <p>Vendita annullata</p>
        </div>
      )}
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignItems: "center",
          color: sale.deleted === true && colors?.highlight,
          cursor: "default",
        }}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onMouseMove={(e) => setCoordinates({ x: e.pageX - 200, y: e.pageY })}
      >
        {hoverNetwork && !sale.deleted && (
          <div style={styles.label}>
            <p>Rete museale</p>
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              ...styles.field,
              width: 200,
            }}
          >
            <p style={{ paddingLeft: 5, paddingRight: 5 }}>
              {sale.articolo.nome_articolo}
            </p>
            {!user?.flag_network && sale.flag_network && (
              <img
                alt="Network Icon"
                src={network}
                style={{ objectFit: "contain", width: 15, marginLeft: 10 }}
                onMouseOver={() => setHoverNetwork(true)}
                onMouseLeave={() => setHoverNetwork(false)}
                onMouseMove={(e) =>
                  setCoordinates({ x: e.pageX - 200, y: e.pageY })
                }
              />
            )}
          </div>
          <div style={styles.field}>
            <p>{sale.articolo.categoria_articolo}</p>
          </div>
          <div style={{ ...styles.field, width: 80 }}>
            <p>
              {sale.articolo.quantita === null ? 1 : sale.articolo.quantita}
            </p>
          </div>
          <div style={{ ...styles.field, width: 80 }}>
            <p>
              €{" "}
              {sale.articolo.prezzo_articolo === null
                ? 0
                : sale.articolo.prezzo_articolo}
            </p>
          </div>
          <div style={styles.field}>
            <p>
              {moment(new Date(sale.datetime_vendita)).format(
                "DD/MM/yyyy, HH:mm"
              )}
            </p>
          </div>
          <div style={{ ...styles.field, width: 140 }}>
            <p>{sale.metodo_pagamento}</p>
          </div>
          <div style={styles.field}>
            <p>
              {sale.operatore !== null &&
              sale.operatore !== undefined &&
              sale.operatore !== ""
                ? sale.operatore
                : " - "}
            </p>
          </div>
          {sale.fornitore !== undefined && (
            <div style={styles.field}>
              <p>
                {sale.fornitore !== null && sale.fornitore !== ""
                  ? sale.fornitore
                  : " - "}
              </p>
            </div>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {!user?.flag_network &&
            (sale.deleted === false &&
            setSaleSelected !== undefined &&
            (!org.settings.viva_ticket ||
              sale.articolo.categoria_articolo !== "Biglietti") ? (
              <>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setModifySelected(sale);
                  }}
                  style={{
                    zIndex: 200,
                    border: "none",
                    backgroundColor: "transparent",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  <img
                    alt="Modify icon"
                    src={pencil}
                    style={{ width: 12, objectFit: "contain", margin: 10 }}
                  />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setSaleSelected(sale);
                  }}
                  style={{
                    zIndex: 200,
                    border: "none",
                    backgroundColor: "transparent",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  <img
                    alt="Delete icon"
                    src={trash}
                    style={{ width: 12, objectFit: "contain", margin: 10 }}
                  />
                </button>
              </>
            ) : (
              <div style={{ width: 45 }}></div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Sale;
