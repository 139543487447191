import React, { useCallback, useEffect, useState, useContext } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./../../../index.css";
import {
  activitiesService,
  eventsService,
  reservationsService,
  staffService,
} from "../../../_services";
import "react-vis/dist/style.css";
import RemoveReservation from "../popup/removeReservation";
import ReactLoading from "react-loading";
import people from "./../../../img/capacity.png";
import calendar from "./../../../img/calendar.png";
import time from "./../../../img/time.png";
import reserved from "./../../../img/reserved.png";
import close from "./../../../img/closeButton.png";
import users from "./../../../img/users.png";
import placeholder from "./../../../img/placeholder_img.png";

import download from "./../../../img/download_res.png";
import download_dark from "./../../../img/download_dark.png";
import CreateActivityReservation from "./popup/createActivityReservation";
import { UserContext } from "../../../contexts";
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";

const localizer = momentLocalizer(moment);

const ActivitiesResManager = (props) => {
  const [activitiesList, setActivitiesList] = useState([]);
  const [allActivities, setAllActivities] = useState([]);
  const [closingDays, setClosingDays] = useState([]);
  const [eventSelected, setEventSelected] = useState(null);
  const [operatorsList, setOperatorsList] = useState([]);
  const [dateSelected, setDateSelected] = useState(new Date());
  const [onBooking, setOnBooking] = useState(false);
  const [reservationSelected, setReservationSelected] = useState(null);
  const [showDeleteActivityReservation, setShowDeleteActivityReservation] =
    useState(false);
  const [loader, setLoader] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(null);
  const colors = useContext(UserContext)?.colors;
  const [showActivityRes, setShowActivityRes] = useState(false);
  const [reservationInfo, setReservationInfo] = useState(null);
  const [showOnly, setShowOnly] = useState(false);
  const [sendConfirmed, setSendConfirmed] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState("month");

  const CustomToolbar = (toolbar) => {
    const styles = {
      button: {
        marginLeft: "20px",
        marginRight: "20px",
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        fontSize: 20,
      },
      row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        margin: 0,
        width: "100%",
      },
      back: {
        fontSize: 10,
        marginLeft: 5,
        cursor: "pointer",
      },
      buttonBack: {
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        fontSize: 20,
        cursor: "pointer",
      },
      viewButton: {
        color: "#373a3c",
        display: "inline-block",
        margin: 0,
        textAlign: "center",
        verticalAlign: "middle",
        background: "none",
        backgroundImage: "none",
        border: "1px solid #ccc",
        padding: "0.375rem 1rem",
        borderRadius: "4px",
        lineHeight: "normal",
        whiteSpace: "nowrap",
      },
    };

    const goToBack = () => {
      if (view === "month") {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      } else if (view === "day") {
        toolbar.date.setDate(toolbar.date.getDate() - 1);
      }

      toolbar.onNavigate("prev");
    };

    const goToNext = () => {
      if (view === "month") {
        toolbar.date.setDate(1);
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      } else if (view === "day") {
        toolbar.date.setDate(toolbar.date.getDate() + 1);
      }
      toolbar.onNavigate("next");
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span>
          {view === "day" && <b>{date.format("DD")} </b>}
          <b>{date.format("MMMM")}</b>
          <b> {date.format("YYYY")}</b>
        </span>
      );
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "10px",
        }}
      >
        {view === "day" ? (
          <div style={styles.row}>
            <button style={styles.buttonBack} onClick={() => setView("month")}>
              &#8249;
            </button>
            <p style={styles.back} onClick={() => setView("month")}>
              Torna al calendario
            </p>
          </div>
        ) : (
          <div style={{ width: "100%" }} />
        )}
        <div style={{ width: "100%" }}>
          <button
            style={{ ...styles.button, marginLeft: 0 }}
            onClick={goToBack}
          >
            &#8249;
          </button>
          <label>{label()}</label>
          <button
            style={{ ...styles.button, marginRight: 0 }}
            onClick={goToNext}
          >
            &#8250;
          </button>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <button
            onClick={() => setView("month")}
            style={{
              ...styles.viewButton,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              backgroundColor: view === "month" && "#e6e6e6",
              borderColor: view === "month" && "#adadad",
              boxShadow:
                view === "month" && "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
            }}
          >
            Mese
          </button>
          <button
            onClick={() => setView("day")}
            style={{
              ...styles.viewButton,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              backgroundColor: view === "day" && "#e6e6e6",
              borderColor: view === "day" && "#adadad",
              boxShadow:
                view === "day" && "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
            }}
          >
            Giorno
          </button>
        </div>
      </div>
    );
  };

  const getClosingDays = useCallback(
    (date) => {
      eventsService
        .getClosingDay(date)
        .then((closingDays) => {
          setClosingDays(closingDays);
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            props.history.push("/");
          }
        });
    },
    [props.history]
  );

  useEffect(() => {
    getClosingDays(moment(new Date()).format("yyyy-MM-DD"));
  }, [getClosingDays]);

  const refreshReservations = (newList) => {
    let temp = newList;
    for (let i = 0; i < temp.length; i++) {
      temp[i].datetime_inizio = new Date(temp[i].datetime_inizio);
      temp[i].datetime_fine = new Date(temp[i].datetime_fine);
    }

    var filtered = [];
    temp.forEach((act) => {
      if (
        act.speaker_feedback !== "refused" &&
        act.org_feedback !== "refused"
      ) {
        filtered.push(act);
      }
    });
    setActivitiesList(filtered);
  };

  const getActivities = () => {
    setLoader(true);
    eventsService.getActivityCategories().then((list) => {
      setAllActivities(list);
      setLoader(false);
    });
  };

  const getActivitiesRes = useCallback(
    (date) => {
      eventsService
        .getActivitiesRes(date)
        .then((activities) => {
          let temp = activities;
          for (let i = 0; i < temp.length; i++) {
            temp[i].datetime_inizio = new Date(temp[i].datetime_inizio);
            temp[i].datetime_fine = new Date(temp[i].datetime_fine);
            temp[i].ora_inizio =
              temp[i].datetime_inizio.getHours() +
              ":" +
              (temp[i].datetime_inizio.getMinutes() < 10 ? "0" : "") +
              temp[i].datetime_inizio.getMinutes();
            temp[i].ora_fine =
              temp[i].datetime_fine.getHours() +
              ":" +
              (temp[i].datetime_fine.getMinutes() < 10 ? "0" : "") +
              temp[i].datetime_fine.getMinutes();
          }

          var filtered = [];
          temp.forEach((act) => {
            if (
              act.speaker_feedback !== "refused" &&
              act.org_feedback !== "refused"
            ) {
              filtered.push(act);
            }
          });
          setActivitiesList(filtered);

          setLoader(false);
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            props.history.push("/");
          }
        });
    },
    [props.history]
  );

  const getOperators = () => {
    staffService.getStaff().then((staff) => {
      setOperatorsList(staff);
    });
  };

  const refreshActivitySelected = (id) => {
    onSelectEvent(activitiesList.filter((res) => res.id === id)[0]);
  };

  const onSelectEvent = (event) => {
    setEventSelected(event);
    setDateSelected(event.datetime_inizio);

    setOnBooking(false);
  };

  const onNavigate = (date) => {
    setDateSelected(date);
    setCurrentMonth(new Date(date).getMonth());
    getClosingDays(moment(date).format("yyyy-MM-DD"));
    getActivitiesRes(moment(date).format("yyyy-MM-DD"));
    setOnBooking(false);
  };

  const onSelectDate = (date) => {
    onNavigate(date);
    setDateSelected(date);
    setEventSelected(null);
  };

  useEffect(() => {
    var date = new Date();
    setCurrentMonth(date.getMonth());
    getClosingDays(moment(date).format("yyyy-MM-DD"));
    getActivitiesRes(moment(date).format("yyyy-MM-DD"));
    setOnBooking(false);
    getActivities();
    getOperators();
  }, [getActivitiesRes, getClosingDays]);

  const closeInfoEvent = () => {
    setEventSelected(null);
    setDateSelected(new Date());
  };

  const toggleDeleteActivityReservation = (reservation) => {
    setReservationSelected(reservation);
    setShowActivityRes(false);
    setShowDeleteActivityReservation(!showDeleteActivityReservation);
  };

  const deleteActivityReservation = () => {
    reservationsService
      .deleteActivityReservation(reservationSelected)
      .then((response) => {
        setShowActivityRes(false);
        setShowDeleteActivityReservation(false);
        setReservationSelected(null);
        setEventSelected(null);

        let temp = response;
        for (let i = 0; i < temp.length; i++) {
          temp[i].datetime_inizio = new Date(temp[i].datetime_inizio);
          temp[i].datetime_fine = new Date(temp[i].datetime_fine);
        }

        var filtered = [];
        temp.forEach((act) => {
          if (
            act.speaker_feedback !== "refused" &&
            act.org_feedback !== "refused"
          ) {
            filtered.push(act);
          }
        });
        setActivitiesList(filtered);

        setLoader(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const withoutTime = (date) => {
    var d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
  };

  const onSelectSlot = (slot) => {
    setDateSelected(slot.start);
  };

  const onDownloadRes = (id) => {
    setLoader(true);

    reservationsService
      .getResDoc(id)
      .then((file) => {
        setLoader(false);
        var a = document.createElement("a");
        a.href = file.url;
        a.target = "_blank";
        a.download = "prenotazioni.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const toggleShowReservation = () => {
    reservationsService.getReservationInfo(eventSelected.id).then((info) => {
      setShowOnly(true);
      setReservationInfo(info);
      setShowActivityRes(!showActivityRes);
    });
  };

  function sendEmail(id, setIsLoading) {
    setIsLoading(true);
    activitiesService.sendEmail(id).then((response) => {
      if (response) {
        setSendConfirmed(true);
      } else {
        setSendConfirmed(false);
      }
      setIsLoading(false);
    });
  }

  const camelCase = (str) => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  const filterColumns = (data) => {
    // Get column names
    const columns = Object.keys(data[0]);

    const filterColsByKey = columns.filter(
      (c) =>
        c !== "attivita" &&
        c !== "biglietto" &&
        c !== "cognome" &&
        c !== "datetime_inizio" &&
        c !== "datetime_fine" &&
        c !== "disponibilita_relatori" &&
        c !== "ente" &&
        c !== "fattura" &&
        c !== "flag_confermato" &&
        c !== "gruppo" &&
        c !== "immagine_evento" &&
        c !== "info_enti" &&
        c !== "nome" &&
        c !== "operatori" &&
        c !== "org_feedback" &&
        c !== "relatori" &&
        c !== "spazio" &&
        c !== "speaker_feedback" &&
        c !== "valori"
    );

    return filterColsByKey; // OR return columns
  };

  const getLabel = (col) => {
    switch (col) {
      case "giorno_prenotazione":
        return "Data prenotata";
      case "codice":
        return "Codice prenotazione";
      case "numero_partecipanti":
        return "Partecipanti";
      case "Email":
        return "Email referente";
      case "numero_telefono":
        return "Numero referente";
      case "numero_accompagnatori":
        return "Accompagnatori";
      case "fattura_emessa":
        return "Fattura emessa";
      case "numero_fattura":
        return "Numero fattura";
      case "nome_evento":
        return "Evento";
      case "fascia_eta":
        return "Fascia età";
      case "grado_scuola":
        return "Grado scuola";
      case "classe_scuola":
        return "Classe";
      case "tariffa_scelta":
        return "Tariffa scelta";
      case "ragione_sociale":
        return "Ragione sociale";
      case "indirizzo_fatturazione":
        return "Indirizzo fatturazione";
      case "telefono_fatturazione":
        return "Telefono fatturazione";
      case "codice_fiscale":
        return "Codice Fiscale/Partita IVA";
      case "codice_destinatario":
        return "Codice destinatario";
      case "ora_inizio":
        return "Ora inizio";
      case "ora_fine":
        return "Ora fine";
      default:
        return col;
    }
  };

  const selectedEvent = (event) => {
    if (event.flag_visita) {
      onSelectDate(event.datetime_inizio);
    } else {
      onSelectEvent(event);
    }
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    calendarBox: {
      display: "flex",
      height: "95%",
      width: "95%",
      flex: 5,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    infoBox: {
      flex: 2,
      height: "100%",
      width: "30%",
      backgroundColor: colors.tertiary,
    },
    header: {
      height: 100,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    body: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderBottomLeftRadius: 30,
      borderBottomRightRadius: 30,
    },
    footer: {
      display: "flex",
      flexDirection: "row",
    },
    backButton: {
      position: "absolute",
      top: 15,
      right: 15,
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      alignSelf: "left",
      zIndex: 100,
      cursor: "pointer",
    },
    titleBox: {
      width: "100%",
      backgroundColor: colors.secondary,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      color: colors.white,
      fontWeight: "700",
      fontSize: 20,
    },
    subtitle: {
      width: "100%",
      backgroundColor: colors.primary,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      marginTop: 3,
    },
    fieldBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    field: {
      fontSize: 15,
    },
    location: {
      color: colors.white,
      fontSize: 11,
    },
    row: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
    },
    label: {
      color: colors.secondary,
      width: "80%",
      fontSize: 13,
      textAlign: "left",
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      cursor: "pointer",
    },
    textInput: {
      borderRadius: 5,
      border: 0,
      outline: "none",
      textAlign: "center",
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
      width: "80%",
    },
    errorMessage: {
      color: colors.highlight,
      fontSize: 10,
      fontWeight: "bold",
      marginTop: -6,
    },
    list: {
      width: "100%",
      height: "calc(100vh - 530px)",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      overflowY: "scroll",
    },
    menu: {
      border: "1px solid lightgray",
      width: "88%",
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
      color: colors.darkgray,
      borderRadius: 5,
      backgroundColor: colors.white,
      outline: "none",
    },
  };

  return loader ? (
    <div style={styles.container}>
      <ReactLoading
        type={"spinningBubbles"}
        color={colors.primary}
        height={50}
        width={50}
      />
    </div>
  ) : (
    <div style={styles.container}>
      <div
        style={{
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          height: "90%",
          width: eventSelected ? "70%" : "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={styles.calendarBox}>
          {activitiesList.length > 0 && (
            <div
              style={{
                ...styles.button,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                marginRight: "5px",
                marginBottom: "10px",
              }}
            >
              <ExcelFile
                filename={
                  "Prenotazioni attività " +
                  moment(dateSelected).format("MM - yyyy")
                }
                element={
                  <>
                    <button
                      style={{
                        ...styles.button,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        width: "100%",
                        marginRight: "4%",
                      }}
                    >
                      <img
                        src={download_dark}
                        style={{ height: 20, objectFit: "contain" }}
                        alt={"Bookings list"}
                      />
                      <p
                        style={{
                          fontSize: 16,
                          color: colors.darkgray,
                          margin: 0,
                          marginLeft: 5,
                          fontWeight: "600",
                        }}
                      >
                        Scarica lista prenotazioni
                      </p>
                    </button>
                  </>
                }
              >
                <ExcelSheet
                  data={activitiesList}
                  name={
                    "Prenotazioni attività " +
                    moment(dateSelected).format("MM - yyyy")
                  }
                >
                  {activitiesList.length > 0 &&
                    filterColumns(activitiesList).map((col, key) => {
                      return (
                        <ExcelColumn
                          key={key}
                          label={camelCase(getLabel(col))}
                          value={col}
                        />
                      );
                    })}
                </ExcelSheet>
              </ExcelFile>
            </div>
          )}

          <Calendar
            date={moment(dateSelected).toDate()}
            localizer={localizer}
            events={activitiesList}
            titleAccessor="nome_evento"
            startAccessor="datetime_inizio"
            endAccessor="datetime_fine"
            resourceAccessor="id"
            selectable={true}
            onNavigate={(date) => onNavigate(date)}
            onDrillDown={(date) => onSelectDate(date)}
            onSelectEvent={(event) => {
              selectedEvent(event);
            }}
            onSelectSlot={(slot) => onSelectSlot(slot)}
            messages={{
              next: "Successivo",
              previous: "Precedente",
              today: "Oggi",
              month: "Mese",
              week: "Settimana",
              day: "Giorno",
            }}
            views={["month", "week", "day"]}
            view={view}
            onShowMore={(events, date) => {
              setDateSelected(date);
              setView("day");
            }}
            components={{
              toolbar: CustomToolbar,
            }}
            min={new Date(2021, 1, 1, 7, 0, 0)}
            scrollToTime={new Date()}
            style={{
              height: "95%",
              width: "100%",
            }}
            eventPropGetter={(event, start, end, isSelected) => {
              let newStyle;
              if (!event.flag_confermato) {
                newStyle = {
                  backgroundColor: isSelected
                    ? colors.lightwarning
                    : colors.warning,
                  color: colors.white,
                  borderRadius: 5,
                  fontSize: 13,
                  outline: "none",
                  border: "2px solid #ffffff",
                };
              } else {
                newStyle = {
                  backgroundColor: isSelected
                    ? colors.primary
                    : colors.secondary,
                  color: colors.white,
                  borderRadius: 5,
                  fontSize: 13,
                  outline: "none",
                  border: "2px solid #ffffff",
                };
              }
              return {
                className: "",
                style: newStyle,
              };
            }}
            dayPropGetter={(day) => {
              let newStyle = {
                backgroundColor: "#f6f6f6",
                color: colors.white,
                opacity: 1,
              };
              if (
                (closingDays.indexOf(moment(day).format("yyyy-MM-DD")) !== -1 &&
                  new Date(day).getMonth() === currentMonth) ||
                moment(day).add(1, "days") < moment()
              ) {
                return {
                  className: "",
                  style: newStyle,
                };
              } else {
                return null;
              }
            }}
          />
          {showDeleteActivityReservation && (
            <div
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RemoveReservation
                reservation={reservationSelected}
                closeModal={toggleDeleteActivityReservation}
                saveData={deleteActivityReservation}
                colors={colors}
              />
            </div>
          )}
        </div>
      </div>
      {eventSelected && (
        <div style={styles.infoBox}>
          <div style={styles.header}>
            <button style={styles.backButton} onClick={closeInfoEvent}>
              <img
                alt="Back Button"
                src={close}
                style={{ height: 20, objectFit: "cover" }}
              />
            </button>
            <img
              alt="Header"
              src={
                eventSelected.immagine_evento !== null
                  ? eventSelected.immagine_evento.url
                  : placeholder
              }
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
            />
          </div>
          <div style={styles.titleBox}>
            <p style={styles.title}>{eventSelected.nome_evento}</p>
          </div>
          <div style={styles.subtitle}>
            <div style={styles.fieldBox}>
              <img
                alt="Date"
                src={calendar}
                style={{ height: 15, marginRight: 10 }}
              />
              <p style={styles.location}>
                {moment(eventSelected.datetime_inizio).format("D MMMM")}
              </p>
            </div>
            <div style={styles.fieldBox}>
              <img
                alt="Time"
                src={time}
                style={{ height: 12, marginRight: 10 }}
              />
              <p style={styles.location}>
                {moment(eventSelected.datetime_inizio).format("HH:mm") +
                  " - " +
                  moment(eventSelected.datetime_fine).format("HH:mm")}
              </p>
            </div>
          </div>
          <div style={styles.subtitle}>
            <div style={styles.fieldBox}>
              <img
                alt="Reserved"
                src={reserved}
                style={{ height: 12, marginRight: 10 }}
              />
              <p style={styles.location}>
                {eventSelected?.numero_partecipanti}
              </p>
              <p style={{ ...styles.location, marginLeft: 5 }}> partecipanti</p>
            </div>{" "}
            <div style={styles.fieldBox}>
              <img
                alt="User"
                src={users}
                style={{ height: 12, marginRight: 10 }}
              />
              <p style={styles.location}>
                {eventSelected?.numero_accompagnatori}
              </p>
              <p style={{ ...styles.location, marginLeft: 5 }}>
                {" "}
                accompagnatori
              </p>
            </div>
            {eventSelected.posti_totali && (
              <div style={styles.fieldBox}>
                <img
                  alt="Capacity"
                  src={people}
                  style={{ height: 12, marginRight: 10 }}
                />
                <p style={styles.location}>{eventSelected.posti_totali}</p>
                <p style={{ ...styles.location, marginLeft: 5 }}> posti</p>
              </div>
            )}
          </div>
          <div style={{ ...styles.fieldBox, marginTop: 20 }}>
            {eventSelected.prenotazioni_evento?.length > 0 && (
              <button
                style={{ ...styles.button, color: colors.white }}
                onClick={() => onDownloadRes(eventSelected.id)}
              >
                <img
                  alt="Download icon"
                  src={download}
                  style={{ height: 15, objectFit: "contain" }}
                />
              </button>
            )}
          </div>
          <div style={styles.body}>
            <div style={{ width: "100%" }}>
              {eventSelected?.attivita === undefined ? (
                withoutTime(eventSelected.datetime_inizio) >=
                  withoutTime(new Date()) &&
                (!onBooking &&
                eventSelected?.prenotazioni_evento?.length > 0 &&
                eventSelected?.prenotazioni_evento
                  .map((pren) => pren.numero_partecipanti)
                  .reduce((prev, next) => prev + next) >=
                  eventSelected.posti_totali ? (
                  <>
                    <p
                      style={{
                        ...styles.button,
                        width: "100%",
                        backgroundColor: colors.secondary,
                        color: colors.white,
                        paddingTop: 10,
                        paddingBottom: 10,
                        textAlign: "center",
                      }}
                    >
                      POSTI TERMINATI
                    </p>
                    <button
                      style={{
                        ...styles.button,
                        width: 200,
                        backgroundColor: colors.secondary,
                        color: colors.white,
                        padding: 10,
                        borderRadius: 15,
                        margin: 20,
                      }}
                    >
                      AGGIUNGI IN LISTA D'ATTESA
                    </button>
                  </>
                ) : (
                  !onBooking && (
                    <button
                      style={{
                        ...styles.button,
                        width: 200,
                        backgroundColor: colors.secondary,
                        color: colors.white,
                        padding: 10,
                        borderRadius: 15,
                        margin: 20,
                      }}
                    >
                      PRENOTA
                    </button>
                  )
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {eventSelected?.flag_confermato ? (
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: colors.secondary,
                        color: colors.white,
                      }}
                    >
                      <p style={{ paddingTop: 10, paddingBottom: 10 }}>
                        Prenotazione confermata
                      </p>
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          width: "100%",
                          backgroundColor:
                            eventSelected?.speaker_feedback === "confirmed"
                              ? colors.primary
                              : colors.warning,
                          color: colors.white,
                          marginBottom: 10,
                        }}
                      >
                        <p style={{ paddingTop: 10, paddingBottom: 10 }}>
                          {eventSelected?.speaker_feedback === "confirmed"
                            ? "Confermata dal relatore"
                            : eventSelected?.speaker_feedback === "refused"
                            ? "Rifiutata dal relatore"
                            : "In attesa della conferma del relatore"}
                        </p>
                      </div>{" "}
                      <div
                        style={{
                          width: "100%",
                          backgroundColor:
                            eventSelected?.org_feedback === "confirmed"
                              ? colors.primary
                              : colors.warning,
                          color: colors.white,
                        }}
                      >
                        <p style={{ paddingTop: 10, paddingBottom: 10 }}>
                          {eventSelected?.org_feedback === "confirmed"
                            ? "Confermata dal museo"
                            : eventSelected?.org_feedback === "refused"
                            ? "Rifiutata dal museo"
                            : "In attesa della conferma del museo"}
                        </p>
                      </div>
                    </>
                  )}
                  <div style={{ width: "85%" }}>
                    <div style={styles.field}>
                      <p style={styles.label}>Cliente</p>
                      <p style={{ textAlign: "left", fontSize: 14 }}>
                        {eventSelected?.ente.nome}
                      </p>
                    </div>
                    {eventSelected.relatori !== null && (
                      <div style={styles.field}>
                        <p style={styles.label}>Relatore</p>
                        <p style={{ textAlign: "left", fontSize: 14 }}>
                          {eventSelected?.relatori.name +
                            " " +
                            eventSelected?.relatori.surname}
                        </p>
                      </div>
                    )}
                    <div style={styles.field}>
                      <p style={styles.label}>Spazio</p>
                      <p style={{ textAlign: "left", fontSize: 14 }}>
                        {eventSelected?.spazio?.nome || "-"}
                      </p>
                    </div>
                    <div style={styles.field}>
                      <p style={styles.label}>Tariffa</p>
                      <p style={{ textAlign: "left", fontSize: 14 }}>
                        {eventSelected?.biglietto.name +
                          ", " +
                          eventSelected?.biglietto.price +
                          "€"}
                      </p>
                    </div>
                    {eventSelected?.operatori.length > 0 && (
                      <div style={styles.field}>
                        <p style={styles.label}>Operatore/i</p>
                        {eventSelected?.operatori.map((op) => {
                          return (
                            <p style={{ textAlign: "left", fontSize: 14 }}>
                              {op.nome_user}
                            </p>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 20,
                      width: "100%",
                    }}
                  >
                    <button
                      style={{
                        ...styles.button,
                        width: "50%",
                        backgroundColor: colors.primary,
                        color: colors.white,
                        padding: 10,
                        borderRadius: 15,
                      }}
                      onClick={toggleShowReservation}
                    >
                      DETTAGLI
                    </button>
                    {isLoading ? (
                      <div
                        style={{
                          marginTop: 15,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ReactLoading
                          type={"spinningBubbles"}
                          color={colors?.darkgray}
                          height={25}
                          width={25}
                        />
                      </div>
                    ) : (
                      <button
                        style={{
                          ...styles.button,
                          width: "50%",
                          backgroundColor: colors.secondary,
                          color: colors.white,
                          padding: 10,
                          borderRadius: 15,
                          margin: "10px",
                        }}
                        onClick={() =>
                          sendEmail(eventSelected.id, setIsLoading)
                        }
                      >
                        {sendConfirmed === true
                          ? "Email inviata!"
                          : sendConfirmed === false
                          ? "Errore"
                          : "Invia e-mail prenotazione"}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {showActivityRes && (
        <CreateActivityReservation
          closeModal={() => setShowActivityRes(false)}
          colors={colors}
          list={allActivities}
          operatorsList={operatorsList}
          refresh={refreshReservations}
          onModify={false}
          activitySelected={reservationInfo}
          showReservation={showOnly}
          onDeleteReservation={toggleDeleteActivityReservation}
          refreshActivitySelected={refreshActivitySelected}
          canDelete={true}
        />
      )}
    </div>
  );
};

export default ActivitiesResManager;
