import React, { useState, useEffect, useRef, useCallback } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { activitiesService } from "../../../_services";
import ReactLoading from "react-loading";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./../../../index.css";
const localizer = momentLocalizer(moment);

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function shadeColor(color, percent) {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R / 10) * 10;
  G = Math.round(G / 10) * 10;
  B = Math.round(B / 10) * 10;

  var RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

function checkColor(color) {
  if (color !== undefined) {
    var c = color.substring(1); // strip #
    var rgb = parseInt(c, 16); // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff; // extract red
    var g = (rgb >> 8) & 0xff; // extract green
    var b = (rgb >> 0) & 0xff; // extract blue
    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    if (luma < 80) {
      return "white";
    } else {
      return "#171515";
    }
  } else {
    return "white";
  }
}

const ActivitiesCalendar = (props) => {
  const [dateSelected, setDateSelected] = useState(new Date());
  const prevDateSelected = usePrevious({ dateSelected });
  const [view, setView] = useState("month");
  const [availabilities, setAvailabilities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    setLoading(true);
    activitiesService
      .getAllAvailabilities(props.orgId, new Date())
      .then((availabilities) => {
        let tmp = [];
        availabilities.forEach((element) => {
          element.attivitas.forEach((act) => {
            act.datetime_inizio = element.datetime_inizio;
            act.datetime_fine = element.datetime_fine;
            act.relatori = element.relatori;
            act.mainId = element.id;
            tmp.push(act);
          });
        });
        setAvailabilities(tmp);
        setLoading(false);
      });

    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, [props.orgId]);

  const onNavigate = (date) => {
    setDateSelected(date);
  };

  const onSelectSlot = (slot) => {
    setDateSelected(slot.start);
  };

  const getAvailabilities = useCallback(() => {
    setLoading(true);
    activitiesService
      .getAllAvailabilities(props.orgId, dateSelected)
      .then((availabilities) => {
        let tmp = [];
        availabilities.forEach((element) => {
          element.attivitas.forEach((act) => {
            act.datetime_inizio = element.datetime_inizio;
            act.datetime_fine = element.datetime_fine;
            act.relatori = element.relatori;
            act.mainId = element.id;
            tmp.push(act);
          });
        });
        setAvailabilities(tmp);
        setLoading(false);
      });
  }, [dateSelected, props]);

  useEffect(() => {
    let prevMonth = new Date(prevDateSelected?.dateSelected).getMonth();
    let currentMonth = new Date(dateSelected).getMonth();
    if (prevMonth !== currentMonth) {
      getAvailabilities();
    }
  }, [dateSelected, getAvailabilities, prevDateSelected]);

  const CustomToolbar = (toolbar) => {
    const styles = {
      button: {
        marginLeft: "20px",
        marginRight: "20px",
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        fontSize: 20,
      },
      row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        margin: 0,
      },
      back: {
        fontSize: 10,
        marginLeft: 5,
        cursor: "pointer",
      },
      buttonBack: {
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        fontSize: 20,
        cursor: "pointer",
      },
      viewButton: {
        color: "#373a3c",
        display: "inline-block",
        margin: 0,
        textAlign: "center",
        verticalAlign: "middle",
        background: "none",
        backgroundImage: "none",
        border: "1px solid #ccc",
        padding: "0.375rem 1rem",
        borderRadius: "4px",
        lineHeight: "normal",
        whiteSpace: "nowrap",
      },
    };

    const goToBack = () => {
      if (view === "month") {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      } else if (view === "day") {
        toolbar.date.setDate(toolbar.date.getDate() - 1);
      }

      toolbar.onNavigate("prev");
    };

    const goToNext = () => {
      if (view === "month") {
        toolbar.date.setDate(1);
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      } else if (view === "day") {
        toolbar.date.setDate(toolbar.date.getDate() + 1);
      }
      toolbar.onNavigate("next");
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span>
          {view === "day" && <b>{date.format("DD")} </b>}
          <b>{date.format("MMMM")}</b>
          <b> {date.format("YYYY")}</b>
        </span>
      );
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "10px",
        }}
      >
        {view === "day" ? (
          <div style={{ ...styles.row, width: width > 800 && "100%" }}>
            <button style={styles.buttonBack} onClick={() => setView("month")}>
              &#8249;
            </button>
            <p style={styles.back} onClick={() => setView("month")}>
              Torna al calendario
            </p>
          </div>
        ) : (
          width > 800 && <div style={{ width: "100%" }} />
        )}
        <div style={{ width: "100%" }}>
          <button
            style={{ ...styles.button, marginLeft: 0 }}
            onClick={goToBack}
          >
            &#8249;
          </button>
          <label>{label()}</label>
          <button
            style={{ ...styles.button, marginRight: 0 }}
            onClick={goToNext}
          >
            &#8250;
          </button>
        </div>

        <div
          style={{
            width: width > 800 && "100%",
            display: "flex",
            justifyContent: "end",
          }}
        >
          {width > 800 && (
            <>
              <button
                onClick={() => setView("month")}
                style={{
                  ...styles.viewButton,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  backgroundColor: view === "month" && "#e6e6e6",
                  borderColor: view === "month" && "#adadad",
                  boxShadow:
                    view === "month" && "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
                }}
              >
                Mese
              </button>
              <button
                onClick={() => setView("day")}
                style={{
                  ...styles.viewButton,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  backgroundColor: view === "day" && "#e6e6e6",
                  borderColor: view === "day" && "#adadad",
                  boxShadow:
                    view === "day" && "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
                }}
              >
                Giorno
              </button>
            </>
          )}
        </div>
      </div>
    );
  };

  const CustomEvent = (event) => {
    return (
      <div
        style={{
          display: "block",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {event.event.nome +
          " - " +
          event.event.relatori.name +
          " " +
          event.event.relatori.surname}
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundColor: "#FFFFFF",
        padding: "10px",
        display: "flex",
        height: view === "month" ? "65vh" : "80vh",
        width: "100%",
      }}
    >
      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#303030"}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <Calendar
          date={moment(dateSelected).toDate()}
          localizer={localizer}
          events={availabilities}
          startAccessor={(event) => {
            return new Date(event.datetime_inizio);
          }}
          endAccessor={(event) => {
            return new Date(event.datetime_fine);
          }}
          resourceAccessor="id"
          selectable={true}
          onNavigate={(date) => setDateSelected(new Date(date))}
          onDrillDown={(date) => onNavigate(date)}
          onSelectEvent={(event) => {
            if (moment(event.datetime_inizio) > moment()) {
              if (view === "month") {
                setView("day");
              } else if (view === "day") {
                const dateToSend = new Date(event.datetime_inizio); // Replace this with your date
                const dateString = dateToSend.toISOString();
                window.location =
                  `${process.env.REACT_APP_WEB_URL}/` +
                  props.match.params.museum +
                  "/activity/" +
                  event.id +
                  "?id=" +
                  event.id +
                  "&mainId=" +
                  event.mainId +
                  `&date=${encodeURIComponent(dateString)}`;
              }
              setDateSelected(event.datetime_inizio);
            }
          }}
          onShowMore={(events, date) => {
            setView("day");
            setDateSelected(date);
          }}
          onSelectSlot={(slot) => onSelectSlot(slot)}
          messages={{
            next: "Successivo",
            previous: "Precedente",
            today: "Oggi",
            month: "Mese",
            week: "Settimana",
            day: "Giorno",
            showMore: (eventCount) =>
              width <= 800 ? `+ ${eventCount}` : `+ ${eventCount} eventi`,
          }}
          components={{
            toolbar: CustomToolbar,
            event: CustomEvent,
          }}
          dayLayoutAlgorithm="no-overlap"
          popup={true}
          scrollToTime={new Date()}
          style={{
            height: "100%",
            width: "100%",
            maxWidth: width <= 800 ? width * 0.95 : width * 0.7,
          }}
          view={view}
          defaultView={view}
          dayPropGetter={(date) => {
            let dayStyle = {
              backgroundColor:
                moment(date).add(1, "days") < moment()
                  ? "#F6F6F6"
                  : "transparent",
            };
            return {
              className: "",
              style: dayStyle,
            };
          }}
          eventPropGetter={(event, start, end, isSelected) => {
            let newStyle = {
              backgroundColor: event.relatori.color,
              color: checkColor(event.relatori.color),
              borderRadius: 5,
              outline: "none",
              borderTop: "2px solid",
              borderBottom: "2px solid",
              borderRightWidth: width > 800 ? "8px solid" : "0",
              borderLeftWidth: width > 800 ? "8px solid" : "0",
              borderColor:
                isSelected && view === "day"
                  ? shadeColor(event.relatori.color, -15)
                  : event.relatori.color,
              fontSize: 13,
            };
            return {
              className: "",
              style: newStyle,
            };
          }}
        />
      )}
    </div>
  );
};

export default ActivitiesCalendar;
