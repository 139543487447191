import React from "react";
import close from "./../../../img/closeButton.png";
import moment from "moment";

const ShowReservation = ({ activity, closeModal, colors }) => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      width: "80%",
      height: "80%",
      zIndex: 300,
    },
    header: {
      display: "flex",
      width: "100%",
      height: 80,
      backgroundColor: colors?.primary,
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    content: {
      width: "100%",
      flex: 6,
      backgroundColor: colors?.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
    field: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginLeft: 50,
      marginRight: 50,
    },
    footer: {
      display: "flex",
      width: "100%",
      height: 120,
      backgroundColor: colors?.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      backgroundColor: colors?.primary,
      color: colors?.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    menu: {
      border: "1px solid lightgray",
      width: "80%",
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
      color: colors?.darkgray,
      borderRadius: 5,
      backgroundColor: colors?.white,
      outline: "none",
    },
    dataColumn: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      width: "100%",
      padding: 20,
      overflow: "scroll",
    },
    title: {
      marginBottom: 40,
      fontSize: 15,
      fontWeight: "700",
      color: colors?.darkgray,
    },
    propButton: {
      border: 0,
      outline: "none",
      borderRadius: 20,
      backgroundColor: colors?.primary,
      height: 20,
      width: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: 20,
    },
  };
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: colors?.gray,
          opacity: 0.3,
        }}
      />
      <div style={styles.container}>
        <div style={styles.header}>
          <p style={{ color: colors?.white, fontSize: 20 }}>
            {"PRENOTAZIONE ATTIVITÀ"}
          </p>
          <button
            onClick={closeModal}
            style={{
              position: "absolute",
              right: 30,
              marginTop: "auto",
              marginBottom: "auto",
              border: "none",
              backgroundColor: "transparent",
              outline: "none",
              cursor: "pointer",
            }}
          >
            <img
              alt="App logo"
              src={close}
              style={{ width: 15, objectFit: "contain" }}
            />
          </button>
        </div>
        <div style={styles.content}>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <div style={styles.dataColumn}>
              <p style={styles.title}>Dati Prenotazione</p>
              {[
                { name: "Attività", value: activity.nome },
                ...activity.info_prenotazione_default,
                ...activity.info_gruppo,
              ].map((field, key) => {
                return (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 5,
                      width: "100%",
                    }}
                  >
                    <p
                      style={{
                        flex: 1,
                        margin: 5,
                        marginRight: 10,
                        fontSize: 12,
                        color: colors?.gray,
                      }}
                    >
                      {field.name}
                    </p>
                    <p
                      style={{
                        flex: 1,
                        margin: 5,
                        marginRight: 10,
                        fontSize: 12,
                      }}
                    >
                      {field.type === 7
                        ? moment(field.value).format("DD/MM/yyyy")
                        : field.type === 8
                        ? moment(field.value).format("DD/MM/yyyy HH:mm")
                        : field.value}
                    </p>
                  </div>
                );
              })}
            </div>
            <div style={styles.dataColumn}>
              <p style={styles.title}>Dati Aggiuntivi</p>
              {activity.info_prenotazione_custom.map((field, key) => {
                return (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 5,
                      width: "100%",
                    }}
                  >
                    <p
                      style={{
                        flex: 1,
                        margin: 5,
                        marginRight: 10,
                        fontSize: 12,
                        color: colors?.gray,
                      }}
                    >
                      {field.name}
                    </p>
                    <p
                      style={{
                        flex: 1,
                        margin: 5,
                        marginRight: 10,
                        fontSize: 12,
                      }}
                    >
                      {field.type === 7
                        ? moment(field.value).format("DD/MM/yyyy")
                        : field.type === 8
                        ? moment(field.value).format("DD/MM/yyyy HH:mm")
                        : field.value}
                    </p>
                  </div>
                );
              })}
            </div>
            <div style={styles.dataColumn}>
              <p style={styles.title}>Dati Fatturazione</p>
              {activity.info_ente.map((field, key) => {
                return (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 5,
                      width: "100%",
                    }}
                  >
                    <p
                      style={{
                        flex: 1,
                        margin: 5,
                        marginRight: 10,
                        fontSize: 12,
                        color: colors?.gray,
                      }}
                    >
                      {field.name}
                    </p>
                    <p
                      style={{
                        flex: 1,
                        margin: 5,
                        marginRight: 10,
                        fontSize: 12,
                      }}
                    >
                      {field.type === 7
                        ? moment(field.value).format("DD/MM/yyyy")
                        : field.type === 8
                        ? moment(field.value).format("DD/MM/yyyy HH:mm")
                        : field.value}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShowReservation;
