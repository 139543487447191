import React, { useContext } from "react";
import RegisterEntranceButton from "../../components/registerEntranceButton";
import { LanguageContext } from "../../containers/language";
import { OrgContext, UserContext } from "../../contexts";
import BarcodeButton from "./barcodeButton";
import { itemsService } from "../../_services";
import back from "../../img/arrow_back.png";

const Footer = ({
  total,
  status,
  isTicket,
  setLoader,
  createVisitors,
  preventCreateVisitors,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const colors = useContext(UserContext)?.colors;
  const settings = useContext(OrgContext)?.org?.settings;

  /*
  // Retrieve barcode recap document
  */
  const getPdf = () => {
    let previousStatus = status;
    setLoader("loading");
    itemsService
      .getBarcodeDoc()
      .then((file) => {
        setLoader(previousStatus);
        var a = document.createElement("a");
        a.href = file.url;
        a.target = "_blank";
        a.download = "vetrina.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  const styles = {
    footer: {
      display: "flex",
      flex: 1,
      width: "100%",
      height: "25%",
      background: colors?.secondary,
      alignItems: "center",
      justifyContent: "flex-end",
      boxShadow: "0 0 4px 2px rgb(0 0 0 / 10%)",
    },
    total: {
      color: colors?.white,
      marginTop: 25,
      marginBottom: 25,
      marginLeft: 80,
      marginRight: 30,
    },
    currency: { color: colors?.white, fontWeight: "700", fontSize: 30 },
    button: {
      outline: "none",
      border: "none",
      display: "flex",
      backgroundColor: colors?.primary,
      width: 70,
      height: 70,
      borderRadius: 15,
      alignItems: "center",
      justifyContent: "center",
      color: colors?.white,
      cursor: "pointer",
    },
    buttonBox: {
      display: "flex",
      position: "absolute",
      flexDirection: "column",
      left: 90,
      bottom: 12,
      alignItems: "center",
      justifyContent: "center",
      width: 110,
    },
    saveInfoButton: {
      backgroundColor: colors?.darkgray,
      borderRadius: 10,
      padding: 5,
      color: colors?.white,
      fontSize: 15,
      height: 45,
      width: 140,
      cursor: "pointer",
      border: "none",
      outline: "none",
      marginTop: 25,
      marginBottom: 25,
    },
  };
  return (
    <div style={styles.footer}>
      {isTicket && settings?.viva_ticket ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginRight: 50,
          }}
        >
          <button
            style={{
              ...styles.saveInfoButton,
              visibility: preventCreateVisitors && "hidden",
            }}
            onClick={createVisitors}
            disabled={preventCreateVisitors}
          >
            SALVA
          </button>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginRight: 50,
          }}
        >
          <p style={styles.total}>{dictionary.total}</p>
          <p style={styles.currency}>€ {total.toFixed(2)}</p>
        </div>
      )}
      <BarcodeButton getPdf={getPdf} />
      {isTicket &&
        !settings?.viva_ticket &&
        (status !== "registerentrance" ? (
          <RegisterEntranceButton
            toggleEntrance={() => setLoader("registerentrance")}
          />
        ) : (
          <div style={styles.buttonBox}>
            <button onClick={() => setLoader("list")} style={styles.button}>
              <img
                alt={"Back button"}
                src={back}
                style={{ height: 35, objectFit: "contain" }}
              ></img>
            </button>
          </div>
        ))}
    </div>
  );
};

export default Footer;
