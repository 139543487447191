import React, { useEffect, useState, useContext } from "react";
import ReactExport from "react-export-excel";
import { cardsService } from "../../../_services";
import search from "../../../img/search.png";
import download from "../../../img/download.png";
import users from "../../../img/users.png";
import arrow from "../../../img/arrow_back_dark.png";
import refresh from "../../../img/refresh.png";
import vip from "../../../img/is_admin.png";
import { LanguageContext } from "../../../containers/language";
import ReactLoading from "react-loading";
import Table from "./table";
import Form from "../form";
import moment from "moment";
import "moment/locale/it";
import RenewalForm from "../renewalForm";
import Success from "../success";
import Failure from "../failure";

import Pagination from "react-js-pagination";

moment.locale("it");
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const itemsPerPageOptions = [10, 20, 50, 100];

function calculateAge(birth) {
  var dob = new Date(birth);
  //calculate month difference from current date in time
  var month_diff = Date.now() - dob.getTime();

  //convert the calculated difference in date format
  var age_dt = new Date(month_diff);

  //extract year from date
  var year = age_dt.getUTCFullYear();

  //now calculate the age of the user
  var age = Math.abs(year - 1970);
  return age;
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

function isEmpty(field) {
  return field === "" || field === null || field === undefined;
}

const CardsManager = ({ cardTypes, colors, orgId }) => {
  const [chosenCard, setChosenCard] = useState(null);
  const [chosenTarget, setChosenTarget] = useState(null);
  const [cards, setCards] = useState([]);
  const [allCards, setAllCards] = useState([]);
  const [openRecord, setOpenRecord] = useState(null);
  const [cardNumber, setCardNumber] = useState("");
  const [tempCardNumber, setTempCardNumber] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [birth, setBirth] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [zip, setZip] = useState("");
  const [taxCode, setTaxCode] = useState("");
  const [sdiCode, setSdiCode] = useState("");
  const [hasVAT, setHasVAT] = useState(false);
  const [profilationCheckBox, setProfilationCheckBox] = useState(false);
  const [marketingCheckBox, setMarketingCheckBox] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [existingCardNumberError, setExistingCardNumberError] = useState(false);
  const [onModify, setOnModify] = useState(false);
  const [onRenewal, setOnRenewal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [renewalEnded, setRenewalEnded] = useState(false);
  const [renewalFeedback, setRenewalFeedback] = useState(null);

  // filters
  const [tempSearchKey, setTempSearchKey] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [vipFilter, setVipFilter] = useState(false);
  const [hasKeyword, setHasKeyword] = useState(false);

  const expirationsList = [
    { id: 0, name: "Tutte" },
    { id: 1, name: "Scadute" },
    { id: 2, name: "Valide" },
  ];
  const [expirationFilter, setExpirationFilter] = useState(null);

  //Billing info
  const [bname, setbName] = useState("");
  const [bsurname, setbSurname] = useState("");
  const [bemail, setbEmail] = useState("");
  const [bbirth, setbBirth] = useState("");
  const [baddress, setbAddress] = useState("");
  const [bcity, setbCity] = useState("");
  const [bcounty, setbCounty] = useState("");
  const [bzip, setbZip] = useState("");
  const [btaxCode, setbTaxCode] = useState("");
  const [bsdiCode, setbSdiCode] = useState("");
  const [bhasVAT, setbHasVAT] = useState(false);
  const [bemailError, setbEmailError] = useState(false);
  const [useCardData, setUseCardData] = useState(false);
  const [taxCodeError, setTaxCodeError] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("Contanti");
  const [isVip, setIsVip] = useState(false);

  const [excelData, setExcelData] = useState([]);
  const [excelDataByGroup, setExcelDataByGroup] = useState([]);

  // Pagination
  const [activePage, setActivePage] = useState(1);
  //const prevActivePage = usePrevious(activePage);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  //const prevItemsPerPage = usePrevious(itemsPerPage);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const [cardsLoaded, setCardsLoaded] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const { dictionary } = useContext(LanguageContext);

  const getCards = (
    card,
    target,
    orgId,
    start,
    limit,
    vipFilter,
    hasKeyword,
    searchKey,
    expirationFilterId
  ) => {
    setCardsLoaded(false);
    // count the results for the pagination
    cardsService
      .countCards(
        card,
        target,
        orgId,
        vipFilter,
        hasKeyword,
        searchKey,
        expirationFilterId
      )
      .then((tot) => setTotalItemsCount(tot));

    //get the results and set them
    cardsService
      .getCards(
        card,
        target,
        orgId,
        start,
        limit,
        vipFilter,
        hasKeyword,
        searchKey,
        expirationFilterId
      )
      .then((cards) => {
        const deconstructedCards = JSON.parse(JSON.stringify(cards));
        setCards(deconstructedCards);
        setCardsLoaded(true);
      });
  };

  const getAllCards = (
    card,
    target,
    orgId,
    vipFilter,
    hasKeyword,
    searchKey,
    expirationFilterId
  ) => {
    //get the results and set them
    cardsService
      .getCards(
        card,
        target,
        orgId,
        0,
        -1,
        vipFilter,
        hasKeyword,
        searchKey,
        expirationFilterId
      )
      .then((allCards) => {
        var finalColumns = [
          "Numero",
          "Nome",
          "Cognome",
          "Data di nascita",
          "Email",
          "Data di creazione",
          "Data di scadenza",
          "Consenso profilazione",
          "Consenso marketing",
          "VIP",
        ];
        const finalData = allCards.map((item) => {
          const birthDate = moment(item.nascita).format("DD/MM/YYYY");
          const creationDate = moment(item.created_at).format("DD/MM/YYYY");
          const expirationDate = moment(item.scadenza).format("DD/MM/YYYY");
          const res = [
            item.numero.toString(),
            item.nome,
            item.cognome,
            birthDate,
            item.email,
            creationDate,
            expirationDate,
            item.consenso_profilazione ? "Sì" : "No",
            item.consenso_marketing ? "Sì" : "No",
            item.vip ? "Sì" : "",
          ];
          return res;
        });
        setExcelData([{ columns: finalColumns, data: finalData }]);

        const deconstructedCards = JSON.parse(JSON.stringify(allCards));
        setAllCards(deconstructedCards);
      });
  };

  const modifyCard = () => {
    setButtonLoader(true);
    let indirizzo = {};
    indirizzo.via = address;
    indirizzo.citta = city;
    indirizzo.provincia = county;
    indirizzo.cap = zip;
    if (tempCardNumber !== cardNumber) {
      cardsService.checkCardNumber(cardNumber).then((response) => {
        if (response.length === 0) {
          cardsService
            .updateCard(
              openRecord.id,
              cardNumber,
              name,
              surname,
              email,
              birth,
              indirizzo,
              profilationCheckBox,
              marketingCheckBox
            )
            .then(() => {
              getCards(
                chosenCard.id,
                chosenTarget.id,
                orgId,
                itemsPerPage * activePage - itemsPerPage,
                itemsPerPage,
                vipFilter,
                hasKeyword,
                searchKey,
                expirationFilter.id
              );
              setButtonLoader(false);
              setOnModify(false);
            });
        } else {
          setExistingCardNumberError(true);
          setButtonLoader(false);
        }
      });
    } else {
      cardsService
        .updateCard(
          openRecord.id,
          cardNumber,
          name,
          surname,
          email,
          birth,
          indirizzo,
          profilationCheckBox,
          marketingCheckBox
        )
        .then(() => {
          getCards(
            chosenCard.id,
            chosenTarget.id,
            orgId,
            itemsPerPage * activePage - itemsPerPage,
            itemsPerPage,
            vipFilter,
            hasKeyword,
            searchKey,
            expirationFilter.id
          );
          //setOpenRecord(null);
          setOnModify(false);
          setButtonLoader(false);
        });
    }
  };

  const preventSaveInfo = () => {
    return (
      isEmpty(name) ||
      isEmpty(surname) ||
      isEmpty(email) ||
      emailError === true ||
      isEmpty(birth) ||
      isEmpty(address) ||
      isEmpty(city) ||
      isEmpty(county) ||
      isEmpty(zip) ||
      existingCardNumberError === true ||
      !marketingCheckBox
    );
  };

  const camelCase = (str) => {
    return str.substring(0, 1).toUpperCase() + str.substring(1);
  };

  const refreshData = () => {
    setCardNumber("");
    setTempCardNumber("");
    setName("");
    setSurname("");
    setEmail("");
    setBirth("");
    setAddress("");
    setCity("");
    setCounty("");
    setZip("");
    setTaxCode("");
    setSdiCode("");
    setHasVAT(false);
    setProfilationCheckBox(false);
    setMarketingCheckBox(false);
  };

  const refreshbData = () => {
    setbName("");
    setbSurname("");
    setbEmail("");
    setbBirth("");
    setbAddress("");
    setbCity("");
    setbCounty("");
    setbZip("");
    setbTaxCode("");
    setbSdiCode("");
    setbHasVAT(false);
  };

  const form = {
    cardNumber: cardNumber,
    setCardNumber: setCardNumber,
    existingCardNumberError: existingCardNumberError,
    setExistingCardNumberError: setExistingCardNumberError,
    name: name,
    setName: setName,
    surname: surname,
    setSurname: setSurname,
    email: email,
    setEmail: setEmail,
    birth: birth,
    setBirth: setBirth,
    emailError: emailError,
    setEmailError: setEmailError,
    address: address,
    setAddress: setAddress,
    city: city,
    setCity: setCity,
    county: county,
    setCounty: setCounty,
    zip: zip,
    setZip: setZip,
    taxCode: taxCode,
    setTaxCode: setTaxCode,
    sdiCode: sdiCode,
    setSdiCode: setSdiCode,
    hasVAT: hasVAT,
    setHasVAT: setHasVAT,
    profilationCheckBox: profilationCheckBox,
    setProfilationCheckBox: setProfilationCheckBox,
    marketingCheckBox: marketingCheckBox,
    setMarketingCheckBox: setMarketingCheckBox,
  };

  const billingForm = {
    name: bname,
    setName: setbName,
    surname: bsurname,
    setSurname: setbSurname,
    email: bemail,
    setEmail: setbEmail,
    birth: bbirth,
    setBirth: setbBirth,
    emailError: bemailError,
    setEmailError: setbEmailError,
    address: baddress,
    setAddress: setbAddress,
    city: bcity,
    setCity: setbCity,
    county: bcounty,
    setCounty: setbCounty,
    zip: bzip,
    setZip: setbZip,
    taxCode: btaxCode,
    setTaxCode: setbTaxCode,
    taxCodeError: taxCodeError,
    setTaxCodeError: setTaxCodeError,
    sdiCode: bsdiCode,
    setSdiCode: setbSdiCode,
    hasVAT: bhasVAT,
    setHasVAT: setbHasVAT,
    useCardData: useCardData,
    setUseCardData: setUseCardData,
    paymentMethod: paymentMethod,
    setPaymentMethod: setPaymentMethod,
    isVip: isVip,
    setIsVip: setIsVip,
  };

  const renewCards = () => {
    setLoading(true);
    cardsService
      .renewCards(
        openRecord.target_tessere.id,
        JSON.stringify(openRecord),
        JSON.stringify(formatBillingInfo())
      )
      .then((response) => {
        setLoading(false);
        setRenewalFeedback(response);
        setRenewalEnded(true);
      });
  };

  const formatBillingInfo = () => {
    let owner = {};
    owner.nome = bname;
    owner.cognome = bsurname;
    owner.email = bemail;
    let indirizzo = {};
    indirizzo.via = baddress;
    indirizzo.citta = bcity;
    indirizzo.provincia = bcounty;
    indirizzo.cap = parseInt(bzip);
    owner.indirizzo = indirizzo;
    bhasVAT
      ? (owner.partita_iva = btaxCode)
      : (owner.codice_fiscale = btaxCode);
    owner.sdi = bsdiCode;
    owner.metodo_pagamento = paymentMethod;
    owner.vip = isVip;
    return owner;
  };

  const preventSaveBillingInfo = () => {
    return (
      isEmpty(bname) ||
      isEmpty(bsurname) ||
      isEmpty(bemail) ||
      bemailError === true ||
      isEmpty(baddress) ||
      isEmpty(bcity) ||
      isEmpty(bcounty) ||
      isEmpty(bzip) ||
      isEmpty(btaxCode) ||
      taxCodeError === true
    );
  };

  const restart = () => {
    getCards(
      chosenCard.id,
      chosenTarget.id,
      orgId,
      itemsPerPage * activePage - itemsPerPage,
      itemsPerPage,
      vipFilter,
      hasKeyword,
      searchKey,
      expirationFilter.id
    );
    getAllCards(
      chosenCard.id,
      chosenTarget.id,
      orgId,
      vipFilter,
      hasKeyword,
      searchKey,
      expirationFilter.id
    );
    setUseCardData(false);
    setOpenRecord(null);
    setOnModify(false);
    setOnRenewal(false);
    refreshbData();
    setRenewalEnded(false);
  };

  const startingSearch = () => {
    // reset searchKeyword and hasKeyword
    let currentSearchKey = "";
    let currentHasKeyword = false;
    setSearchKey(currentSearchKey);
    setHasKeyword(currentHasKeyword);

    let tempActivePage = 1;
    setActivePage(tempActivePage);

    getCards(
      chosenCard.id,
      chosenTarget.id,
      orgId,
      itemsPerPage * tempActivePage - itemsPerPage,
      itemsPerPage,
      vipFilter,
      currentHasKeyword,
      currentSearchKey,
      expirationFilter.id
    );
    getAllCards(
      chosenCard.id,
      chosenTarget.id,
      orgId,
      vipFilter,
      currentHasKeyword,
      currentSearchKey,
      expirationFilter.id
    );
  };

  useEffect(() => {
    setChosenCard(cardTypes[0]);
    setExpirationFilter(expirationsList[2]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardTypes]);

  useEffect(() => {
    if (
      !isEmpty(chosenCard) &&
      chosenCard.target_tesseres.find((card) => card.id === -1) === undefined
    ) {
      chosenCard.target_tesseres = [
        { id: -1, nome: "Tutte" },
        ...chosenCard.target_tesseres,
      ];
    }
    setChosenTarget(chosenCard?.target_tesseres[0]);
  }, [chosenCard]);

  useEffect(() => {
    if (!isEmpty(chosenTarget)) {
      let tempActivePage = 1;
      setActivePage(tempActivePage);

      getCards(
        chosenCard.id,
        chosenTarget.id,
        orgId,
        itemsPerPage * activePage - itemsPerPage,
        itemsPerPage,
        vipFilter,
        hasKeyword,
        searchKey,
        expirationFilter.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chosenCard,
    chosenTarget,
    orgId,
    itemsPerPage,
    vipFilter,
    expirationFilter,
  ]);

  useEffect(() => {
    if (!isEmpty(chosenTarget)) {
      getCards(
        chosenCard.id,
        chosenTarget.id,
        orgId,
        itemsPerPage * activePage - itemsPerPage,
        itemsPerPage,
        vipFilter,
        hasKeyword,
        searchKey,
        expirationFilter.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]);

  useEffect(() => {
    if (!isEmpty(chosenTarget)) {
      getAllCards(
        chosenCard.id,
        chosenTarget.id,
        orgId,
        vipFilter,
        hasKeyword,
        searchKey,
        expirationFilter.id
      );
      setActivePage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenCard, chosenTarget, orgId, vipFilter, expirationFilter]);

  useEffect(() => {
    if (!isEmpty(openRecord)) {
      const {
        numero,
        nome,
        cognome,
        email,
        nascita,
        indirizzo,
        codice_fiscale,
        partita_iva,
        sdi,
        consenso_profilazione,
        consenso_marketing,
      } = openRecord;
      setTempCardNumber(numero);
      setCardNumber(numero);
      setName(nome);
      setSurname(cognome);
      setEmail(email);
      setBirth(nascita);
      setAddress(indirizzo.via);
      setCity(indirizzo.citta);
      setCounty(indirizzo.provincia);
      setZip(indirizzo.cap);
      let hasVAT = isEmpty(codice_fiscale);
      setHasVAT(hasVAT ? true : false);
      setTaxCode(hasVAT ? partita_iva : codice_fiscale);
      setSdiCode(sdi);
      setProfilationCheckBox(consenso_profilazione);
      setMarketingCheckBox(consenso_marketing);
    } else {
      refreshData();
    }
  }, [openRecord]);

  useEffect(() => {
    var finalColumns = [
      "Gruppo",
      "Cognome",
      "Nome",
      "Email",
      "Data di nascita",
      "Bambino",
      "Numero",
    ];
    //Sorted by creation
    let temp = JSON.parse(JSON.stringify(allCards)).sort(function (a, b) {
      if (a.created_at < b.created_at) {
        return -1;
      }
      if (a.created_at > b.created_at) {
        return 1;
      }
      return 0;
    }); //not assigned to sortedByCreation (already sorted by id)
    //Sorted by group
    if (temp[0]?.target_tessere.gruppo) {
      var grouped = groupBy(temp, (card) => card.gruppo_tessere?.id);
      var list = [];
      var counter = 0;
      for (let family of grouped) {
        counter++;
        for (let component of family[1]) {
          const temp = [
            counter,
            camelCase(component.cognome),
            camelCase(component.nome),
            component.email,
            component.nascita,
            calculateAge(component.nascita) < 18 ? "Bambino" : "",
            component.numero.toString(),
          ];
          list.push(temp);
        }
      }
      setExcelDataByGroup([{ columns: finalColumns, data: list }]);
    }
  }, [allCards, chosenTarget]);

  useEffect(() => {
    if (useCardData === true) {
      setbName(openRecord.vendita_tessere.nome);
      setbSurname(openRecord.vendita_tessere.cognome);
      setbEmail(openRecord.vendita_tessere.email);
      setbAddress(openRecord.vendita_tessere.indirizzo.via);
      setbCity(openRecord.vendita_tessere.indirizzo.citta);
      setbCounty(openRecord.vendita_tessere.indirizzo.provincia);
      setbZip(openRecord.vendita_tessere.indirizzo.cap);
      let hasVAT = isEmpty(openRecord.vendita_tessere.codice_fiscale);
      setbHasVAT(hasVAT ? true : false);
      setbTaxCode(
        hasVAT
          ? openRecord.vendita_tessere.partita_iva
          : openRecord.vendita_tessere.codice_fiscale
      );
      setbSdiCode(openRecord.vendita_tessere.sdi);
      setIsVip(openRecord.vip);
    }
  }, [
    useCardData,
    openRecord,
    setbName,
    setbSurname,
    setbEmail,
    setbAddress,
    setbCity,
    setbCounty,
    setbZip,
  ]);

  useEffect(() => {
    const handleSubmit = () => {
      let tempHasKeyword = false;
      setSearchKey(tempSearchKey);
      if (tempSearchKey !== "") {
        tempHasKeyword = true;
      } else {
        tempHasKeyword = false;
      }
      setHasKeyword(tempHasKeyword);

      let tempActivePage = 1;
      setActivePage(tempActivePage);

      getCards(
        chosenCard.id,
        chosenTarget.id,
        orgId,
        itemsPerPage * tempActivePage - itemsPerPage,
        itemsPerPage,
        vipFilter,
        tempHasKeyword,
        tempSearchKey,
        expirationFilter.id
      );
      getAllCards(
        chosenCard.id,
        chosenTarget.id,
        orgId,
        vipFilter,
        tempHasKeyword,
        tempSearchKey,
        expirationFilter.id
      );
    };

    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, tempSearchKey]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      overflowX: "scroll",
    },
    label: {
      fontSize: 13,
      color: colors.gray,
    },
    menu: {
      border: "1px solid lightgray",
      width: 150,
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 10,
      color: colors.darkgray,
      borderRadius: 20,
      backgroundColor: "transparent",
      outline: "none",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "50%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    rowTop: {
      width: "90%",
      minHeight: 150,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      borderBottom: "1px solid darkgray",
    },
    rowBottom: {
      display: "flex",
      justifyContent: "center",
      width: "90%",
      overflow: "scroll",
    },
    buttonBox: {
      display: "flex",
      flexDirection: "row",
    },
    subcontainer: {
      width: "100%",
      paddingBottom: 20,
      paddingTop: 20,
      display: "flex",
      flexDirection: "column",
    },
    inputSearch: {
      borderRadius: 40,
      border: "1px solid #F0F0F0",
      width: "50%",
      height: 40,
      display: "flex",
      flexDirection: "row",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
    downloadButton: {
      border: "none",
      outline: "none",
      backgroundColor: colors.darkgray,
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      width: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    goBackButton: {
      width: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    saveButton: {
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: colors.darkgray,
      margin: 30,
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 15,
    },
    arrow: {
      height: 13,
      objectFit: "contain",
    },
    filterButton: {
      border: "1px solid #F0F0F0",
      outline: "none",
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.body}>
        {!openRecord && chosenCard && chosenCard.target_tesseres.length > 0 && (
          <div style={styles.rowTop}>
            {chosenCard && chosenCard.target_tesseres.length > 0 && (
              <div style={styles.selectBox}>
                <p style={styles.label}>Seleziona la card</p>
                <select
                  name={chosenCard?.nome}
                  style={styles.menu}
                  value={chosenCard?.id}
                  onChange={(event) => {
                    setChosenCard(cardTypes[event.target.selectedIndex]);
                    if (searchKey !== tempSearchKey) {
                      setTempSearchKey(searchKey); //put the last search key in the input
                    }
                    setExpirationFilter(expirationsList[2]);
                  }}
                >
                  {cardTypes.map((type, key) => (
                    <option key={key} value={type.id}>
                      {type.nome}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div style={styles.selectBox}>
              <p style={styles.label}>Seleziona la tipologia</p>
              <select
                name={chosenTarget?.nome}
                style={styles.menu}
                value={chosenTarget?.id}
                onChange={(event) => {
                  setChosenTarget(
                    chosenCard.target_tesseres[event.target.selectedIndex]
                  );
                  if (searchKey !== tempSearchKey) {
                    setTempSearchKey(searchKey); //put the last search key in the input
                  }
                }}
              >
                {chosenCard.target_tesseres.map((target, key) => (
                  <option key={key} value={target.id}>
                    {target.nome}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        <div style={styles.rowBottom}>
          {!isEmpty(openRecord) ? (
            //If a card has been selected, show its info
            <div style={styles.subcontainer}>
              <button
                style={styles.goBackButton}
                onClick={() => {
                  setUseCardData(false);
                  setOpenRecord(null);
                  setOnModify(false);
                  setOnRenewal(false);
                  refreshbData();
                }}
              >
                <img src={arrow} style={styles.arrow} alt={"Go back"} />
                <p
                  style={{
                    fontSize: 12,
                    color: colors.darkgray,
                    margin: 0,
                    marginLeft: 5,
                    fontWeight: "600",
                  }}
                >
                  Indietro
                </p>
              </button>
              {onRenewal ? (
                renewalEnded ? (
                  renewalFeedback.status ? (
                    <Success
                      restart={restart}
                      createdCards={renewalFeedback.message}
                      renewal={true}
                    />
                  ) : (
                    <Failure
                      restart={restart}
                      errorMessage={renewalFeedback.message}
                      renewal={true}
                    />
                  )
                ) : (
                  <RenewalForm
                    colors={colors}
                    formFunctions={billingForm}
                    card={openRecord}
                  />
                )
              ) : (
                <Form
                  colors={colors}
                  formFunctions={form}
                  onModify={onModify}
                />
              )}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                {/*Form buttons*/}
                {!onRenewal &&
                  (buttonLoader ? (
                    <div style={{ margin: 30 }}>
                      <ReactLoading
                        type={"spinningBubbles"}
                        color={colors.darkgray}
                        height={50}
                        width={50}
                      />
                    </div>
                  ) : (
                    <button
                      style={{
                        ...styles.saveButton,
                        backgroundColor: preventSaveInfo()
                          ? colors.lightgray
                          : colors.darkgray,
                      }}
                      onClick={onModify ? modifyCard : () => setOnModify(true)}
                      disabled={preventSaveInfo()}
                    >
                      <p
                        style={{
                          fontSize: 12,
                          color: colors.white,
                          margin: 0,
                          fontWeight: "600",
                        }}
                      >
                        {onModify ? "Salva" : "Modifica"}
                      </p>
                    </button>
                  ))}

                {/*RenewalForm buttons*/}
                {!onModify &&
                  (loading ? (
                    <div style={{ margin: 30 }}>
                      <ReactLoading
                        type={"spinningBubbles"}
                        color={colors.darkgray}
                        height={50}
                        width={50}
                      />
                    </div>
                  ) : (
                    !renewalEnded && (
                      <button
                        style={{
                          ...styles.saveButton,
                          backgroundColor:
                            onRenewal && preventSaveBillingInfo()
                              ? colors.lightgray
                              : colors.darkgray,
                        }}
                        onClick={
                          onRenewal ? renewCards : () => setOnRenewal(true)
                        }
                        disabled={onRenewal && preventSaveBillingInfo()}
                      >
                        <p
                          style={{
                            fontSize: 12,
                            color: colors.white,
                            margin: 0,
                            fontWeight: "600",
                          }}
                        >
                          {onRenewal ? "Conferma" : "Rinnova"}
                        </p>
                      </button>
                    )
                  ))}
              </div>
            </div>
          ) : //If no card has been selected, show the general table with all the cards
          chosenCard && chosenCard.target_tesseres.length > 0 ? (
            <div style={styles.subcontainer}>
              <div style={{ ...styles.row }}>
                <div
                  style={{
                    ...styles.row,
                    justifyContent: "start",
                    width: "100%",
                  }}
                >
                  {totalItemsCount > 1 || totalItemsCount === 0 ? (
                    <p
                      style={{
                        color: colors?.gray,
                        fontSize: 11,
                        textAlign: "left",
                      }}
                    >
                      {totalItemsCount} tessere trovate
                    </p>
                  ) : (
                    <p
                      style={{
                        color: colors?.gray,
                        fontSize: 11,
                        textAlign: "left",
                      }}
                    >
                      {totalItemsCount} tessera trovata
                    </p>
                  )}
                </div>
              </div>

              <div
                style={{
                  ...styles.row,
                  justifyContent: "space-between",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <div style={styles.inputSearch}>
                  <img
                    alt="Search icon"
                    src={search}
                    style={{ width: 30, objectFit: "contain", margin: 10 }}
                  />
                  <input
                    type="text"
                    style={{
                      border: 0,
                      width: "100%",
                      borderRadius: 40,
                      outline: "none",
                    }}
                    value={tempSearchKey}
                    onChange={(event) => {
                      setTempSearchKey(event.target.value);
                    }}
                    placeholder={dictionary.search}
                  ></input>
                  <button
                    style={{
                      ...styles.filterButton,
                    }}
                    onClick={() => {
                      setTempSearchKey("");
                      setSearchKey("");
                      startingSearch(); //come back to the original result search (i.e. the ones at the page opening)
                    }}
                  >
                    <img
                      alt="Refresh icon"
                      src={refresh}
                      style={{ width: 18, objectFit: "contain", margin: 2 }}
                    />
                  </button>
                </div>

                {expirationFilter && (
                  <select
                    name={expirationFilter.name}
                    style={styles.menu}
                    value={expirationFilter.id}
                    onChange={(event) => {
                      setExpirationFilter(
                        expirationsList[event.target.selectedIndex]
                      );
                    }}
                  >
                    {expirationsList.map((filter, key) => (
                      <option key={key} value={filter.id}>
                        {filter.name}
                      </option>
                    ))}
                  </select>
                )}

                <button
                  style={{
                    ...styles.filterButton,
                    backgroundColor: vipFilter ? colors.gray : colors.white,
                  }}
                  onClick={() => {
                    setVipFilter(!vipFilter);
                    if (searchKey !== tempSearchKey) {
                      setTempSearchKey(searchKey); //put the last search key in the input
                    }
                    setActivePage(1);
                  }}
                >
                  <img
                    alt="VIP icon"
                    src={vip}
                    style={{ width: 20, objectFit: "contain", marginRight: 15 }}
                  />
                  <p
                    style={{
                      fontSize: 13,
                      color: vipFilter ? colors.white : colors.gray,
                    }}
                  >
                    {vipFilter ? "Annulla filtro VIP" : "Filtra per VIP"}
                  </p>
                </button>
                <div style={styles.buttonBox}>
                  {chosenTarget?.gruppo &&
                    excelDataByGroup[0]?.data.length > 0 && (
                      <ExcelFile
                        filename={
                          chosenCard?.nome +
                          "-" +
                          chosenTarget?.nome +
                          "-gruppi" +
                          (vipFilter ? "-VIP" : "")
                        }
                        element={
                          <button
                            style={{
                              ...styles.downloadButton,
                              marginRight: 20,
                            }}
                          >
                            <img
                              alt="Users icon"
                              src={users}
                              style={{ width: 17, objectFit: "contain" }}
                            />
                          </button>
                        }
                      >
                        <ExcelSheet
                          dataSet={excelDataByGroup}
                          name={chosenCard?.nome + "-" + chosenTarget?.nome}
                        />
                      </ExcelFile>
                    )}
                  {/* // */}
                  {allCards.length > 0 && (
                    <ExcelFile
                      filename={
                        chosenCard?.nome +
                        "-" +
                        chosenTarget?.nome +
                        (vipFilter ? "-VIP" : "")
                      }
                      element={
                        <button style={styles.downloadButton}>
                          <img
                            alt="Download icon"
                            src={download}
                            style={{ width: 15, objectFit: "contain" }}
                          />
                        </button>
                      }
                    >
                      <ExcelSheet
                        dataSet={excelData}
                        name={chosenCard?.nome + "-" + chosenTarget?.nome}
                      />
                    </ExcelFile>
                  )}
                </div>
              </div>

              <div
                id="scroll"
                style={{ overflowY: cardsLoaded ? "scroll" : "hidden" }}
              >
                {cardsLoaded ? (
                  cards.length > 0 ? (
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <Table
                        allCards={chosenTarget?.id === -1}
                        cards={cards}
                        colors={colors}
                        setOpenRecord={setOpenRecord}
                        activePage={activePage}
                        itemsPerPage={itemsPerPage}
                      />
                    </div>
                  ) : (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 14,
                        color: colors.gray,
                        marginTop: 40,
                      }}
                    >
                      Nessuna tessera corrispondente ai criteri della ricerca
                    </p>
                  )
                ) : (
                  <div
                    style={{
                      display: "flex",
                      marginTop: 40,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ReactLoading
                      type={"spinningBubbles"}
                      color={colors?.primary}
                      height={50}
                      width={50}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <p
              style={{
                textAlign: "center",
                fontSize: 14,
                color: colors.gray,
                marginTop: 40,
              }}
            >
              Nessuna tessera disponibile
            </p>
          )}
        </div>
      </div>
      <div style={{ width: "90%" }}>
        {cards.length > 0 &&
          totalItemsCount > itemsPerPageOptions[0] &&
          isEmpty(openRecord) && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridAutoRows: "1fr",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <select
                  name="options"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: 15,
                    height: 50,
                    width: 70,
                    padding: 10,
                    textAlign: "left",
                    marginRight: 10,
                  }}
                  value={itemsPerPage}
                  onChange={(event) => {
                    setItemsPerPage(parseInt(event.target.value));
                    setActivePage(1);
                  }}
                >
                  {itemsPerPageOptions.map((option, key) => {
                    return (
                      <option key={key} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
                <p style={{ color: colors?.gray, fontSize: 12 }}>
                  {dictionary.pageElements}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => setActivePage(pageNumber)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default CardsManager;
