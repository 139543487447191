import React, { useState } from "react";
import invitation from "./../../../img/invitation.png";

const InvitationButton = ({
  text,
  colors,
  width,
  backgroundColor,
  onClick,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const [coordinates, setCoordinates] = useState(false);
  const styles = {
    buttonBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 2,
      cursor: "pointer",
    },
    hoverLabel: {
      textAlign: "center",
      fontSize: 12,
      width: width,
      position: "absolute",
      color: colors?.darkgray,
      zIndex: 100,
      backgroundColor: backgroundColor,
      borderRadius: 10,
      padding: 20,
      top: coordinates.y,
      left: coordinates.x,
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      cursor: "pointer",
    },
  };
  return (
    <div
      style={styles.buttonBox}
      onMouseEnter={() => {
        if (!disabled) {
          setOpen(true);
        }
      }}
      onMouseLeave={() => setOpen(false)}
      onMouseMove={(e) => {
        setCoordinates({
          x: width === 200 ? e.pageX - window.innerWidth + 150 : e.pageX,
          y: e.pageY - 150,
        });
      }}
    >
      {open && <p style={styles.hoverLabel}>{text}</p>}
      <button
        style={{
          ...styles.button,
          color: colors?.white,
          opacity: disabled ? 0.3 : 1,
        }}
        disabled={disabled}
        onClick={() => onClick()}
      >
        <img
          alt="Invitation icon"
          src={invitation}
          style={{ height: 15, objectFit: "contain" }}
        />
      </button>
    </div>
  );
};

export default InvitationButton;
