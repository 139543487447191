import React, { useEffect, useState, useContext } from "react";
import { cardsService } from "../../../_services";
import search from "../../../img/search.png";
import arrow from "../../../img/arrow_back_dark.png";
import refresh from "../../../img/refresh.png";
import { LanguageContext } from "../../../containers/language";
import Table from "./table";
import Form from "../form";
import moment from "moment";
import "moment/locale/it";
import Recap from "../recap";
import Success from "../success";
import Failure from "../failure";
import Footer from "./footer";
import ReactLoading from "react-loading";

function isEmpty(field) {
  return field === "" || field === null || field === undefined;
}

const CardsActivator = ({ cardTypes, colors, orgId }) => {
  const [chosenCard, setChosenCard] = useState(null);
  const [chosenTarget, setChosenTarget] = useState(null);
  const [cards, setCards] = useState([]);
  const [openRecord, setOpenRecord] = useState(null);
  //Card info
  const [cardNumber, setCardNumber] = useState("");
  const [existingCardNumberError, setExistingCardNumberError] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [birth, setBirth] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [zip, setZip] = useState("");
  const [taxCode, setTaxCode] = useState("");
  const [sdiCode, setSdiCode] = useState("");
  const [hasVAT, setHasVAT] = useState(false);
  const [profilationCheckBox, setProfilationCheckBox] = useState(false);
  const [marketingCheckBox, setMarketingCheckBox] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [tempSearchKey, setTempSearchKey] = useState("");
  const [infoCollected, setInfoCollected] = useState(false);
  const [ownersList, setOwnersList] = useState([]);
  const [index, setIndex] = useState(1);
  const [creationEnded, setCreationEnded] = useState(false);
  const [success, setSuccess] = useState(false);
  const [createdCards, setCreatedCards] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const { dictionary } = useContext(LanguageContext);

  const [expirationFilter, setExpirationFilter] = useState(2);
  const [cardsLoaded, setCardsLoaded] = useState(false);

  const getCardsSoldOnline = (orgId, searchKey, expirationFilter) => {
    setCardsLoaded(false);
    cardsService
      .getCardsSoldOnline(orgId, searchKey, expirationFilter)
      .then((cards) => {
        const deconstructedCards = JSON.parse(
          JSON.stringify(cards.filter((c) => c.tesseres.length === 0))
        );
        for (let card of deconstructedCards) {
          let temp = card;
          temp.cap = card.indirizzo.cap;
          temp.indirizzoString =
            card.indirizzo.via +
            ", " +
            card.indirizzo.citta +
            " (" +
            card.indirizzo.provincia +
            ") " +
            card.indirizzo.cap;
        }
        setCards(
          deconstructedCards.sort(function (a, b) {
            if (a.cognome.toLowerCase() < b.cognome.toLowerCase()) {
              return -1;
            }
            if (a.cognome.toLowerCase() > b.cognome.toLowerCase()) {
              return 1;
            }
            return 0;
          })
        );
        setCardsLoaded(true);
      });
  };

  const activateCards = () => {
    cardsService
      .activateCards(chosenTarget.id, JSON.stringify(ownersList), openRecord.id)
      .then((response) => {
        setCreationEnded(true);
        setSuccess(response.status);
        if (response.status) {
          setCreatedCards(response.message);
        } else {
          setErrorMessage(response.message);
        }
      });
  };

  const saveInfo = () => {
    let owner = {};
    owner.numero = cardNumber.toLowerCase();
    owner.nome = name;
    owner.cognome = surname;
    owner.email = email;
    owner.nascita = birth;
    owner.via = address;
    owner.citta = city;
    owner.provincia = county;
    owner.cap = parseInt(zip);
    owner.consenso_profilazione = profilationCheckBox;
    owner.consenso_marketing = marketingCheckBox;
    setOwnersList([...ownersList, owner]);
  };

  const goBack = () => {
    if (index > 1) {
      setIndex(index - 1);
    }
  };

  const goNext = () => {
    cardsService.checkCardNumber(cardNumber).then((response) => {
      if (
        response.length === 0 &&
        isEmpty(ownersList.find((owner) => owner.numero === cardNumber))
      ) {
        if (index <= chosenTarget?.max_componenti) {
          if (index > ownersList.length) {
            saveInfo();
            refreshData();
          }
          setIndex(index + 1);
        }
      } else {
        setExistingCardNumberError(true);
      }
    });
  };

  const preventSaveInfo = () => {
    return (
      isEmpty(email) ||
      emailError === true ||
      isEmpty(birth) ||
      isEmpty(address) ||
      isEmpty(city) ||
      isEmpty(county) ||
      isEmpty(zip) ||
      existingCardNumberError === true ||
      profilationCheckBox === false ||
      marketingCheckBox === false
    );
  };

  const refreshData = () => {
    setCardNumber("");
    setName("");
    setSurname("");
    setEmail("");
    setBirth("");
    setAddress("");
    setCity("");
    setCounty("");
    setZip("");
    setTaxCode("");
    setSdiCode("");
    setHasVAT(false);
    setProfilationCheckBox(false);
    setMarketingCheckBox(false);
  };

  const form = {
    cardNumber: cardNumber,
    setCardNumber: setCardNumber,
    existingCardNumberError: existingCardNumberError,
    setExistingCardNumberError: setExistingCardNumberError,
    name: name,
    setName: setName,
    surname: surname,
    setSurname: setSurname,
    email: email,
    setEmail: setEmail,
    birth: birth,
    setBirth: setBirth,
    emailError: emailError,
    setEmailError: setEmailError,
    address: address,
    setAddress: setAddress,
    city: city,
    setCity: setCity,
    county: county,
    setCounty: setCounty,
    zip: zip,
    setZip: setZip,
    taxCode: taxCode,
    setTaxCode: setTaxCode,
    sdiCode: sdiCode,
    setSdiCode: setSdiCode,
    hasVAT: hasVAT,
    setHasVAT: setHasVAT,
    profilationCheckBox: profilationCheckBox,
    setProfilationCheckBox: setProfilationCheckBox,
    marketingCheckBox: marketingCheckBox,
    setMarketingCheckBox: setMarketingCheckBox,
  };

  const startingSearch = () => {
    // reset searchKey
    let currentSearchKey = "";
    setSearchKey(currentSearchKey);

    getCardsSoldOnline(orgId, currentSearchKey, expirationFilter);
  };

  useEffect(() => {
    getCardsSoldOnline(orgId, searchKey, expirationFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId, expirationFilter]);

  useEffect(() => {
    if (!isEmpty(openRecord)) {
      const {
        target_tessere,
        nome,
        cognome,
        email,
        indirizzo,
        consenso_profilazione,
        consenso_marketing,
      } = openRecord;
      setChosenCard(
        cardTypes.find((type) => type.id === target_tessere.tipologie_tessere)
      );
      setChosenTarget(target_tessere);
      setName(nome);
      setSurname(cognome);
      setEmail(email);
      setAddress(indirizzo.via);
      setCity(indirizzo.citta);
      setCounty(indirizzo.provincia);
      setZip(indirizzo.cap);
      setProfilationCheckBox(consenso_profilazione);
      setMarketingCheckBox(consenso_marketing);
    } else {
      refreshData();
    }
  }, [openRecord, cardTypes]);

  useEffect(() => {
    if (index > ownersList.length) {
      refreshData();
    } else {
      const i = index - 1;
      setCardNumber(ownersList[i].numero.toLowerCase());
      setName(ownersList[i].nome);
      setSurname(ownersList[i].cognome);
      setEmail(ownersList[i].email);
      setBirth(ownersList[i].nascita);
      setAddress(ownersList[i].via);
      setCity(ownersList[i].citta);
      setCounty(ownersList[i].provincia);
      setZip(ownersList[i].cap);
      setProfilationCheckBox(ownersList[i].consenso_profilazione);
      setMarketingCheckBox(ownersList[i].consenso_marketing);
      setTaxCode(
        !isEmpty(ownersList[i].codice_fiscale)
          ? ownersList[i].codice_fiscale
          : ownersList[i].partita_iva
      );
      setSdiCode(ownersList[i].sdi);
    }
  }, [index, ownersList]);

  useEffect(() => {
    if (ownersList.length === chosenTarget?.max_componenti) {
      setInfoCollected(true);
    } else {
      setInfoCollected(false);
    }
  }, [ownersList, setInfoCollected, chosenTarget]);

  useEffect(() => {
    setExistingCardNumberError(false);
  }, [cardNumber]);

  useEffect(() => {
    const handleSubmit = () => {
      setSearchKey(tempSearchKey);
      getCardsSoldOnline(orgId, tempSearchKey, expirationFilter);
    };

    if (tempSearchKey === "") {
      handleSubmit();
    }

    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, tempSearchKey]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      overflowX: "scroll",
      marginBottom: 20,
    },
    label: {
      fontSize: 13,
      color: colors.gray,
    },
    menu: {
      border: "1px solid lightgray",
      width: 150,
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 10,
      color: colors.darkgray,
      borderRadius: 20,
      backgroundColor: "transparent",
      outline: "none",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "50%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    rowTop: {
      width: "100%",
      // height: 120,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      //borderBottom: "1px solid darkgray",
    },
    rowBottom: {
      display: "flex",
      justifyContent: "center",
      width: "90%",
      overflow: "scroll",
    },
    subcontainer: {
      width: "100%",
      paddingBottom: 20,
      paddingTop: 10,
      display: "flex",
      flexDirection: "column",
    },
    inputSearch: {
      borderRadius: 40,
      border: "1px solid #F0F0F0",
      width: "50%",
      height: 40,
      display: "flex",
      flexDirection: "row",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
    downloadButton: {
      border: "none",
      outline: "none",
      backgroundColor: colors.darkgray,
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      width: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    saveButton: {
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: colors.darkgray,
      margin: 30,
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 15,
    },
    arrow: {
      height: 13,
      objectFit: "contain",
    },
    buttonChoice: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "#e2e2e2",
      padding: 20,
      color: colors.gray,
      border: "1px solid #e2e2e2",
      borderTop: 0,
      fontSize: 12,
      fontWeight: "500",
    },
    cardImg: {
      width: "40%",
      objectFit: "contain",
      border: "1px solid darkgray",
      borderRadius: 15,
    },
    type: {
      fontSize: 18,
      fontWeight: "700",
      textAlign: "center",
      marginBottom: 0,
      marginTop: 50,
      color: colors.darkgray,
    },
    target: {
      fontSize: 18,
      fontWeight: "700",
      textAlign: "center",
      marginBottom: 0,
      marginTop: 50,
      color: colors.gray,
    },
    description: {
      fontSize: 13,
      width: "100%",
      textAlign: "left",
    },
    goNextButton: {
      position: "absolute",
      bottom: 130,
      right: 80,
      display: "flex",
      alignItems: "center",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    goBackButton: {
      position: "absolute",
      bottom: 130,
      right: 180,
      display: "flex",
      alignItems: "center",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    goToListButton: {
      display: "flex",
      alignItems: "center",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
      marginBottom: 20,
      marginTop: 0,
    },
    counter: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    title: {
      color: colors.darkgray,
      fontSize: 18,
      margin: 0,
    },
    filterButton: {
      border: "1px solid #F0F0F0",
      outline: "none",
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.body}>
        <div style={styles.rowTop}>
          <button
            style={{
              ...styles.buttonChoice,
              borderRight: 0,
              backgroundColor:
                expirationFilter === 2 ? "transparent" : "#e2e2e2",
              borderBottom: expirationFilter === 2 ? 0 : "1px solid #e2e2e2",
            }}
            onClick={() => {
              setExpirationFilter(2);
              setTempSearchKey("");
              setSearchKey("");
            }}
            disabled={!isEmpty(openRecord)}
          >
            VALIDE
          </button>
          <button
            style={{
              ...styles.buttonChoice,
              borderRight: 0,
              backgroundColor:
                expirationFilter === 1 ? "transparent" : "#e2e2e2",
              borderBottom: expirationFilter === 1 ? 0 : "1px solid #e2e2e2",
            }}
            onClick={() => {
              setExpirationFilter(1);
              setTempSearchKey("");
              setSearchKey("");
            }}
            disabled={!isEmpty(openRecord)}
          >
            SCADUTE
          </button>
        </div>
        <div
          style={{
            ...styles.rowTop,
            width: "90%",
            height: openRecord ? 120 : null,
          }}
        >
          {openRecord ? (
            <div style={styles.row}>
              <p style={styles.title}>Codice acquisto </p>
              <p
                style={{
                  ...styles.title,
                  fontWeight: "600",
                  fontSize: 25,
                  marginLeft: 10,
                }}
              >
                {openRecord.codice.toUpperCase()}
              </p>
            </div>
          ) : (
            <div
              style={{
                ...styles.row,
                justifyContent: "space-between",
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <div style={styles.inputSearch}>
                <img
                  alt="Search icon"
                  src={search}
                  style={{ width: 30, objectFit: "contain", margin: 10 }}
                />
                <input
                  type="text"
                  style={{
                    border: 0,
                    width: "100%",
                    borderRadius: 40,
                    outline: "none",
                  }}
                  value={tempSearchKey}
                  onChange={(event) => setTempSearchKey(event.target.value)}
                  placeholder={dictionary.search}
                ></input>
                <button
                  style={styles.filterButton}
                  onClick={() => {
                    setTempSearchKey("");
                    setSearchKey("");
                    startingSearch(); //come back to the original result search (i.e. the ones at the page opening)
                  }}
                >
                  <img
                    alt="Refresh icon"
                    src={refresh}
                    style={{ width: 18, objectFit: "contain", margin: 2 }}
                  />
                </button>
              </div>
              {/*cards.length > 0 && (
                <ExcelFile
                  filename={chosenCard?.nome + "-" + chosenTarget?.nome}
                  element={
                    <button style={styles.downloadButton}>
                      <img
                        alt="Download icon"
                        src={download}
                        style={{ width: 15, objectFit: "contain" }}
                      />
                    </button>
                  }
                >
                  <ExcelSheet
                    data={cards}
                    name={chosenCard?.nome + "-" + chosenTarget?.nome}
                  >
                    {filterColumns(cards).map((col, key) => {
                      return (
                        <ExcelColumn
                          key={key}
                          label={camelCase(getLabel(col))}
                          value={col}
                        />
                      );
                    })}
                  </ExcelSheet>
                </ExcelFile>
                  )*/}
            </div>
          )}
        </div>
        <div style={styles.rowBottom}>
          {!isEmpty(openRecord) ? (
            <div style={styles.subcontainer}>
              <div
                style={{
                  ...styles.row,
                  justifyContent: "flex-start",
                  height: 20,
                }}
              >
                <button
                  style={styles.goToListButton}
                  onClick={() => setOpenRecord(null)}
                >
                  <img src={arrow} style={styles.arrow} alt={"Go to list"} />
                  <p
                    style={{
                      fontSize: 12,
                      color: colors.darkgray,
                      marginLeft: 5,
                      fontWeight: "600",
                    }}
                  >
                    Torna alla lista
                  </p>
                </button>
              </div>
              <div style={{ ...styles.row, justifyContent: "space-between" }}>
                <div style={{ ...styles.column, width: "30%" }}>
                  <img
                    style={styles.cardImg}
                    src={chosenTarget?.img.url}
                    alt="Tessera"
                  ></img>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-end",
                      textAlign: "left",
                    }}
                  >
                    <p style={styles.type}>{chosenCard?.nome}</p>
                    <p style={styles.target}>{chosenTarget?.nome}</p>
                  </div>
                  <p style={styles.description}>{chosenTarget?.descrizione}</p>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-end",
                      textAlign: "left",
                    }}
                  >
                    <p
                      style={{
                        ...styles.target,
                        fontSize: 10,
                        fontWeight: "500",
                        marginTop: 10,
                        marginRight: 5,
                      }}
                    >
                      Acquisto valido fino al
                    </p>
                    <p
                      style={{
                        ...styles.type,
                        fontWeight: "500",
                        marginTop: 10,
                        fontSize: 15,
                      }}
                    >
                      {moment(new Date(openRecord?.scadenza)).format(
                        "DD MMM yyyy"
                      )}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-end",
                      textAlign: "left",
                    }}
                  >
                    <p
                      style={{
                        ...styles.target,
                        fontSize: 10,
                        fontWeight: "500",
                        marginTop: 10,
                        marginRight: 5,
                      }}
                    >
                      Prezzo pagato all'acquisto
                    </p>
                    <p
                      style={{
                        ...styles.type,
                        fontWeight: "500",
                        marginTop: 10,
                        fontSize: 15,
                      }}
                    >
                      {openRecord.prezzo.toFixed(2)}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "flex-end",
                      textAlign: "left",
                    }}
                  >
                    <p
                      style={{
                        ...styles.target,
                        fontSize: 10,
                        fontWeight: "500",
                        marginTop: 10,
                        marginRight: 5,
                      }}
                    >
                      Numero intestatari
                    </p>
                    <p
                      style={{
                        ...styles.type,
                        fontWeight: "500",
                        marginTop: 10,
                        fontSize: 15,
                      }}
                    >
                      {chosenTarget?.max_componenti}
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "65%",
                    flexDirection: "column",
                  }}
                >
                  {chosenTarget?.max_componenti > 1 &&
                    ownersList.length < chosenTarget?.max_componenti && (
                      <div style={styles.counter}>
                        <p
                          style={{
                            fontSize: 12,
                            color: colors.darkgray,
                            fontWeight: "800",
                          }}
                        >
                          {ownersList.length +
                            1 +
                            "/" +
                            chosenTarget?.max_componenti}
                        </p>
                      </div>
                    )}
                  <div
                    style={{
                      ...styles.column,
                      width: "100%",
                      backgroundColor: colors.verylightgray,
                      paddingBottom: 20,
                      marginBottom: 20,
                    }}
                  >
                    {creationEnded ? (
                      success ? (
                        <Success
                          restart={() => {
                            getCardsSoldOnline(
                              orgId,
                              searchKey,
                              expirationFilter
                            );
                            setOpenRecord(null);
                          }}
                          createdCards={createdCards}
                        />
                      ) : (
                        <Failure
                          restart={() => setOpenRecord(null)}
                          errorMessage={errorMessage}
                        />
                      )
                    ) : infoCollected ? (
                      <Recap colors={colors} ownersList={ownersList} />
                    ) : (
                      <Form
                        colors={colors}
                        formFunctions={form}
                        onModify={
                          moment(
                            new Date(openRecord.scadenza).setHours(0, 0, 0, 0)
                          ) >= moment(new Date().setHours(0, 0, 0, 0))
                        }
                      />
                    )}
                  </div>
                </div>
                {!infoCollected && index > 1 && (
                  <button style={styles.goBackButton} onClick={goBack}>
                    <img src={arrow} style={styles.arrow} alt={"Go back"} />
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.darkgray,
                        marginLeft: 5,
                        fontWeight: "600",
                      }}
                    >
                      Indietro
                    </p>
                  </button>
                )}
                {!infoCollected && !preventSaveInfo() && (
                  <button style={styles.goNextButton} onClick={goNext}>
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.darkgray,
                        marginRight: 5,
                        fontWeight: "600",
                      }}
                    >
                      Avanti
                    </p>
                    <img
                      src={arrow}
                      style={{ ...styles.arrow, transform: "rotate(180deg)" }}
                      alt={"Go next"}
                    />
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div style={styles.subcontainer}>
              {!cardsLoaded ? (
                <div
                  style={{
                    display: "flex",
                    marginTop: 40,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={colors?.primary}
                    height={50}
                    width={50}
                  />
                </div>
              ) : cards.length > 0 ? (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Table
                    cards={cards}
                    cardTypes={cardTypes}
                    colors={colors}
                    setOpenRecord={setOpenRecord}
                  />
                </div>
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    fontSize: 14,
                    color: colors.gray,
                    marginTop: 40,
                  }}
                >
                  Nessuna tessera corrispondente ai criteri della ricerca
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      {infoCollected && <Footer colors={colors} createCards={activateCards} />}
    </div>
  );
};

export default CardsActivator;
