import React, { useEffect, useState } from "react";
import moment from "moment";
import back from "./../../../../img/arrow_back.png";
import trash from "./../../../../img/delete.png";
import pen from "./../../../../img/modify.png";
import reserved from "./../../../../img/reserved.png";
import upload from "./../../../../img/uploadimg.png";
import placeholder from "./../../../../img/placeholder_img.png";
import ReactLoading from "react-loading";
import add from "./../../../../img/add.png";
import remove from "./../../../../img/minus_white.png";
import { eventsService, reservationsService } from "../../../../_services";
import types from "../../../../types";
import InfoButton from "../../../../components/buttons/infoButton";

function addProp(
  id,
  model,
  setModel,
  newProp,
  setNewProp,
  newType,
  setNewType,
  options,
  setOptions,
  mandatory
) {
  let temp = model;
  if (newType === 6) {
    temp.push({
      name: newProp,
      type: newType,
      options: options,
      mandatory: mandatory,
    });
  } else {
    temp.push({ name: newProp, type: newType, mandatory: mandatory });
  }

  if (id !== undefined) {
    eventsService.modifyModel(id, temp).then((response) => {
      setModel(response);
    });
  } else {
    setModel(temp);
  }
  setNewProp("");
  setNewType(1);
  setOptions([]);
}

function removeProp(id, model, setModel, newProp) {
  var filtered = model.filter(function (el) {
    return el.name !== newProp;
  });
  if (id !== undefined) {
    eventsService
      .modifyModel(id, filtered)
      .then((newModel) => setModel(newModel));
  } else {
    setModel(filtered);
  }
}

function getType(id) {
  let type = types.find((t) => t.id === id);
  return type.name;
}

function getInfo(id, onShowReservation) {
  reservationsService
    .getReservationInfo(id)
    .then((info) => onShowReservation(info));
}

const ShowActivity = ({
  activity,
  categories,
  categorySelected,
  closeInfo,
  onDeleteSchema,
  onModifySchema,
  onCreateSchema,
  flagModify,
  uploadImg,
  colors,
  onShowReservation,
  spaces,
  blockingSpace,
}) => {
  const [onUpdate, setOnUpdate] = useState(flagModify);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [abstract, setAbstract] = useState("");
  const [description, setDescription] = useState("");
  const [model, setModel] = useState([]);

  const [duration, setDuration] = useState("");
  const [capacity, setCapacity] = useState("");
  const [minParticipants, setMinParticipants] = useState("");

  const [individualPayment, setIndividualPayment] = useState(false);

  const [spaceID, setSpaceID] = useState("");
  const [selectedSpace, setSelectedSpace] = useState("");
  const [newProp, setNewProp] = useState("");
  const [newType, setNewType] = useState(1);
  const [options, setOptions] = useState([]);
  const [newOption, setNewOption] = useState("");
  const [loader, setLoader] = useState(false);
  const [imgLoader, setImgLoader] = useState(false);
  const [pathToUpload, setPathToUpload] = useState(null);
  const [tempImage, setTempImage] = useState(null);
  const [mandatory, setMandatory] = useState(false);

  const [ticketsType, setTicketsType] = useState([]);
  const [ticketsTypeName, setTicketsTypeName] = useState("");
  const [ticketsTypePrice, setTicketsTypePrice] = useState("");
  const [editingTicketsType, setEditingTicketsType] = useState("");

  const [imageError, setImageError] = useState(true);

  React.useEffect(() => {
    setName(activity ? activity.nome : "");
    setCategory(activity ? activity.categoria?.id : categorySelected.id);
    setAbstract(activity && activity.abstract ? activity.abstract : "");
    setDescription(
      activity && activity.descrizione ? activity.descrizione : ""
    );
    setModel(activity ? activity.modello_prenotazione : []);
    setDuration(activity ? activity.durata : "");
    setCapacity(
      activity && activity.capienza !== null ? activity.capienza : ""
    );
    setMinParticipants(
      activity && activity.min_partecipanti !== null
        ? activity.min_partecipanti
        : ""
    );
    setTicketsType(activity ? activity.biglietti : []);
    setImgLoader(false);
    setPathToUpload(null);
    setTempImage(null);
    setImageError(false);

    setIndividualPayment(activity ? activity.pagamento_individuale : false);

    var tmpSpace = null;
    spaces.forEach((space) => {
      if (space.id === activity?.spazi) {
        tmpSpace = space;
        setSelectedSpace(space);
        setSpaceID(space.id);
      }
    });

    if (tmpSpace === null) {
      setSelectedSpace("");
      setSpaceID("");
    }
  }, [activity, categorySelected, spaces]);

  const hiddenFileInput = React.useRef(null);

  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    const fileUploaded = e.target.files[0];
    if (fileUploaded) {
      if (fileUploaded.size < 1000000) {
        if (activity) {
          setImgLoader(true);
          //uploadImg(activity.id, fileUploaded);
          var isImageCorrectlyUploaded = uploadImg(activity.id, fileUploaded);
          setImageError(!isImageCorrectlyUploaded);
        } else {
          setTempImage(URL.createObjectURL(fileUploaded));
          setPathToUpload(e.target.files[0]);
        }
        setImageError(false);
      } else {
        console.log(
          "Dimensione dell'immagine scelta: " +
            (fileUploaded.size / 1000000).toFixed(2) +
            "... MB (superiore a 1 MB)"
        );
        setImageError(true);
      }
    } else {
      setImageError(false);
    }
  };

  const handleCheck = () => {
    setMandatory(!mandatory);
  };

  const handleCheckPayment = () => {
    setIndividualPayment(!individualPayment);
  };

  useEffect(() => {
    spaces.forEach((space) => {
      if (space.id === parseInt(spaceID)) {
        setSelectedSpace(space);
        if (minParticipants > space.capienza) {
          setMinParticipants(space.capienza);
        }
        if (capacity > space.capienza) {
          setCapacity(space.capienza);
        }
      }
    });
  }, [spaceID, capacity, minParticipants, spaces]);

  useEffect(() => {
    if (minParticipants > capacity) {
      setMinParticipants(capacity);
    }
  }, [minParticipants, capacity]);

  const styles = {
    container: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      width: 400,
      height: "100hv",
      backgroundColor: colors.tertiary,
      alignItems: "center",
      zIndex: 200,
    },
    backButton: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 100,
      margin: 10,
      cursor: "pointer",
    },
    deleteButton: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 100,
      margin: 10,
      cursor: "pointer",
    },
    propButton: {
      border: 0,
      outline: "none",
      borderRadius: 20,
      backgroundColor: colors.primary,
      height: 20,
      width: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: 20,
    },
    uploadButton: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      position: "absolute",
      top: 150,
      right: 0,
      zIndex: 100,
      margin: 10,
      cursor: "pointer",
    },
    modifyButton: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      position: "absolute",
      top: 0,
      right: 30,
      zIndex: 100,
      margin: 10,
      cursor: "pointer",
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      cursor: "pointer",
    },
    header: {
      height: 200,
      width: "100%",
    },
    body: {
      width: "90%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflowY: "scroll",
    },
    titleBox: {
      height: onUpdate ? 100 : 50,
      width: "100%",
      backgroundColor: colors.secondary,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    title: {
      color: colors.white,
      fontWeight: "700",
      fontSize: 20,
    },
    subtitle: {
      height: 40,
      width: "100%",
      backgroundColor: colors.primary,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
    },
    fieldBox: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    description: {
      maxHeight: 80,
      textAlign: "left",
      fontSize: 14,
    },
    field: {
      fontSize: 15,
    },
    location: {
      color: colors.white,
      fontSize: 11,
    },
    row: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
    },
    label: {
      color: colors.secondary,
      width: 120,
      fontSize: 13,
      textAlign: "left",
    },
    list: {
      width: "100%",
      height: "calc(100vh - 650px)",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      overflowY: "scroll",
    },
    resInfoList: {
      maxWidth: 400,
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      overflow: "scroll",
    },
    info: {
      flex: 1,
      flexDirection: "row",
      display: "flex",
      alignItems: "center",
    },
    event: {
      flexDirection: "row",
      display: "flex",
      width: "80%",
      height: 40,
      alignItems: "center",
      justifyContent: "space-around",
      cursor: "pointer",
    },
    passedEvent: {
      flexDirection: "row",
      display: "flex",
      width: "80%",
      height: 40,
      alignItems: "center",
      justifyContent: "space-around",
      opacity: 0.4,
      cursor: "pointer",
    },
    textInput: {
      borderRadius: 5,
      border: 0,
      outline: "none",
      textAlign: "center",
    },
    reservationField: {
      backgroundColor: colors.secondary,
      borderRadius: 25,
      padding: 5,
      paddingLeft: 15,
      paddingRight: 15,
      marginLeft: 2,
      marginRight: 2,
    },
    boxResProps: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <button style={styles.backButton} onClick={closeInfo}>
          <img
            alt="Back Button"
            src={back}
            style={{ height: 20, objectFit: "cover" }}
          />
        </button>
        {!onUpdate && (
          <button
            style={styles.modifyButton}
            onClick={() => setOnUpdate(!onUpdate)}
          >
            <img
              alt="Modify Event Schema Button"
              src={pen}
              style={{ height: 20 }}
            />
          </button>
        )}
        {!onUpdate && (
          <button
            style={styles.deleteButton}
            onClick={() => onDeleteSchema(activity)}
          >
            <img
              alt="Delete Event Schema Button"
              src={trash}
              style={{ height: 20 }}
            />
          </button>
        )}
        {onUpdate && (
          <>
            <button style={styles.uploadButton} onClick={handleClick}>
              <img alt="Upload Button" src={upload} style={{ height: 20 }} />
            </button>
            <input
              type="file"
              accept=".png,.jpg,.jpeg"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
          </>
        )}
        {imgLoader ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={colors.primary}
              height={50}
              width={50}
            />
          </div>
        ) : (
          <img
            alt="Header"
            src={
              activity?.immagine
                ? activity?.immagine.url
                : tempImage
                ? tempImage
                : placeholder
            }
            style={{ height: 200, width: "100%", objectFit: "cover" }}
          />
        )}
      </div>
      {imageError ? (
        <div
          style={{
            ...styles.subtitle,
            backgroundColor: colors?.highlight,
            color: "white",
            fontSize: 10,
            fontWeight: "700",
          }}
        >
          <p>{"L'immagine scelta non può eccedere la dimensione di 1MB"}</p>
        </div>
      ) : null}
      <div style={styles.titleBox}>
        {onUpdate ? (
          <input
            type="text"
            name="name"
            autoComplete="on"
            value={name}
            onChange={(event) => setName(event.target.value)}
            placeholder="Nome evento"
            style={{ ...styles.textInput, height: "60%", width: "80%" }}
          />
        ) : (
          <p style={styles.title}>{name}</p>
        )}
      </div>
      {loader ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={colors.primary}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <div style={styles.body}>
          <div style={{ width: "100%", flex: 2 }}>
            <div style={styles.field}>
              {(abstract || onUpdate) && (
                <p style={{ ...styles.label, width: 200 }}>
                  Abstract dell' attività
                </p>
              )}
              {onUpdate ? (
                <textarea
                  type="text"
                  name="location"
                  value={abstract}
                  onChange={(event) =>
                    setAbstract(event.target.value.replace("\n", ""))
                  }
                  placeholder="Scrivi un breve riassunto dell'attività"
                  style={{
                    ...styles.textInput,
                    width: "90%",
                    height: 60,
                    textAlign: "left",
                    padding: 10,
                    fontFamily: "Arial",
                    resize: "none",
                  }}
                />
              ) : (
                <p style={{ ...styles.description, overflowY: "scroll" }}>
                  {abstract}
                </p>
              )}
            </div>
            <div style={styles.field}>
              {(description || onUpdate) && (
                <p style={styles.label}>Descrizione attività</p>
              )}
              {onUpdate ? (
                <textarea
                  type="text"
                  name="location"
                  value={description}
                  onChange={(event) =>
                    setDescription(event.target.value.replace("\n", ""))
                  }
                  placeholder="Aggiungi qui informazioni utili per i visitatori"
                  style={{
                    ...styles.textInput,
                    width: "90%",
                    height: 80,
                    textAlign: "left",
                    padding: 10,
                    fontFamily: "Arial",
                    resize: "none",
                  }}
                />
              ) : (
                <p style={{ ...styles.description, overflowY: "scroll" }}>
                  {description}
                </p>
              )}
            </div>
            <div style={styles.field}>
              <p style={styles.label}>Durata</p>
              {onUpdate ? (
                <div style={styles.row}>
                  <input
                    type="number"
                    style={{ ...styles.textInput, height: 30, width: "80%" }}
                    value={duration}
                    min={1}
                    onChange={(event) => {
                      if (event.target.value >= 0) {
                        setDuration(event.target.value.replace(/[^\d]/, ""));
                      }
                    }}
                    placeholder="60"
                  />
                  <p style={{ fontSize: 15, textAlign: "left" }}> minuti</p>
                </div>
              ) : (
                <p style={{ ...styles.description, overflowY: "scroll" }}>
                  {duration} minuti
                </p>
              )}
            </div>
            <div style={styles.field}>
              <p style={{ ...styles.label, width: "" }}>Spazio assegnato</p>
              {onUpdate ? (
                <div
                  style={{
                    ...styles.row,
                    justifyContent: "start",
                    marginLeft: "7px",
                  }}
                >
                  <select
                    name="Space"
                    style={{
                      ...styles.textInput,
                      width: "95%",
                      padding: 10,
                      textAlign: "left",
                    }}
                    value={spaceID}
                    onChange={(event) => {
                      setSpaceID(event.target.value);
                    }}
                  >
                    <option key={0} value={""}>
                      {"Spazio non selezionato"}
                    </option>
                    {spaces.map((space, key) => {
                      return (
                        <option key={key} value={space.id}>
                          {space.nome}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : (
                <p style={{ ...styles.description, overflowY: "scroll" }}>
                  {selectedSpace?.nome || "Spazio non selezionato"}
                </p>
              )}
            </div>
            <div style={styles.field}>
              <p style={{ ...styles.label, width: "" }}>
                Capienza massima attività
              </p>
              {onUpdate ? (
                <div
                  style={{
                    ...styles.row,
                    justifyContent: "start",
                    marginLeft: "7px",
                  }}
                >
                  <input
                    type="number"
                    placeholder="10"
                    max={selectedSpace ? selectedSpace.capienza : ""}
                    style={{ ...styles.textInput, height: 30, width: "94%" }}
                    value={capacity}
                    onChange={(event) => {
                      if (event.target.value >= 0) {
                        setCapacity(
                          parseInt(event.target.value.replace(/[^\d]/, ""))
                        );
                      }
                      if (selectedSpace !== "") {
                        spaces.forEach((space) => {
                          if (
                            space.id === parseInt(spaceID) &&
                            event.target.value > space.capienza
                          ) {
                            parseInt(setCapacity(space.capienza));
                          }
                        });
                      }
                    }}
                  />
                </div>
              ) : (
                <p style={{ ...styles.description, overflowY: "scroll" }}>
                  {capacity} partecipanti
                </p>
              )}
            </div>
            <div style={styles.field}>
              <p style={{ ...styles.label, width: "" }}>
                Numero minimo partecipanti
              </p>
              {onUpdate ? (
                <div
                  style={{
                    ...styles.row,
                    justifyContent: "start",
                    marginLeft: "7px",
                  }}
                >
                  <input
                    type="number"
                    placeholder="10"
                    max={selectedSpace ? selectedSpace.capienza : ""}
                    style={{ ...styles.textInput, height: 30, width: "94%" }}
                    value={minParticipants}
                    onChange={(event) => {
                      if (event.target.value >= 0) {
                        setMinParticipants(
                          parseInt(event.target.value.replace(/[^\d]/, ""))
                        );
                      }
                      if (selectedSpace !== "") {
                        spaces.forEach((space) => {
                          if (
                            space.id === parseInt(spaceID) &&
                            event.target.value > space.capienza
                          ) {
                            setMinParticipants(parseInt(space.capienza));
                          }
                        });
                      }
                    }}
                  />
                </div>
              ) : (
                <p style={{ ...styles.description, overflowY: "scroll" }}>
                  {minParticipants} partecipanti
                </p>
              )}
            </div>
            {onUpdate && (
              <div style={{ ...styles.field, marginBottom: "10px" }}>
                <p style={styles.label}>Categoria</p>
                <select
                  name="category"
                  style={{
                    ...styles.textInput,
                    width: "95%",
                    padding: 10,
                    textAlign: "left",
                  }}
                  value={category}
                  onChange={(event) => setCategory(event.target.value)}
                >
                  {categories.map((cat, key) => {
                    return (
                      <option key={key} value={cat.id}>
                        {cat.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>
          <div style={{ width: "100%", marginBottom: "10px" }}>
            <div style={styles.field}>
              <p
                style={{
                  color: colors.secondary,
                  fontSize: 13,
                  textAlign: "left",
                }}
              >
                Dati aggiuntivi richiesti alla prenotazione
              </p>
              <div style={onUpdate ? styles.boxResProps : styles.resInfoList}>
                {model?.map((e, key) => {
                  return onUpdate ? (
                    <div key={key} style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <p
                          style={{
                            margin: 5,
                            marginRight: 10,
                            fontSize: 15,
                            flex: 1,
                            textAlign: "left",
                          }}
                        >
                          {e.name + (e.mandatory ? "*" : "")}
                        </p>
                        <p
                          style={{
                            margin: 5,
                            marginLeft: 10,
                            marginRight: 10,
                            fontStyle: "italic",
                            flex: 1,
                            fontSize: 12,
                          }}
                        >
                          {getType(e.type)}
                        </p>
                        <button
                          style={styles.propButton}
                          onClick={() =>
                            removeProp(activity?.id, model, setModel, e.name)
                          }
                        >
                          <img
                            alt={"Remove prop"}
                            src={remove}
                            style={{
                              width: 10,
                              height: 10,
                              objectFit: "contain",
                            }}
                          ></img>
                        </button>
                      </div>
                      {e.type === 6 && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          {e.options?.map((option, key) => {
                            return (
                              <p
                                key={key}
                                style={{
                                  margin: 3,
                                  width: "95%",
                                  textAlign: "left",
                                  fontSize: 12,
                                }}
                              >
                                {key + 1}. {option}
                              </p>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div key={key} style={styles.reservationField}>
                      <p
                        style={{ margin: 0, color: colors.white, fontSize: 13 }}
                      >
                        {e.name}
                      </p>
                    </div>
                  );
                })}
                {onUpdate && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="text"
                        style={{
                          ...styles.textInput,
                          width: "50%",
                          padding: 10,
                          textAlign: "left",
                          marginRight: 10,
                        }}
                        value={newProp}
                        onChange={(event) => setNewProp(event.target.value)}
                        placeholder="Campo"
                      />
                      <select
                        name="user"
                        style={{
                          ...styles.textInput,
                          width: "50%",
                          padding: 10,
                          textAlign: "left",
                        }}
                        value={newType}
                        onChange={(event) =>
                          setNewType(parseInt(event.target.value))
                        }
                      >
                        {types.map((type, key) => {
                          return (
                            <option key={key} value={type.id}>
                              {type.name}
                            </option>
                          );
                        })}
                      </select>
                      <button
                        style={{
                          ...styles.propButton,
                          opacity:
                            (parseInt(newType) === 6 &&
                              (newProp === "" || options.length < 1)) ||
                            newProp === ""
                              ? 0.4
                              : 1,
                        }}
                        disabled={
                          (parseInt(newType) === 6 &&
                            (newProp === "" || options.length < 1)) ||
                          newProp === ""
                        }
                        onClick={() =>
                          addProp(
                            activity?.id,
                            model,
                            setModel,
                            newProp,
                            setNewProp,
                            newType,
                            setNewType,
                            options,
                            setOptions,
                            mandatory,
                            setMandatory
                          )
                        }
                      >
                        <img
                          alt={"Add prop button"}
                          src={add}
                          style={{
                            width: 10,
                            height: 10,
                            objectFit: "contain",
                          }}
                        ></img>
                      </button>
                    </div>
                    {parseInt(newType) === 6 && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {options.map((option, key) => {
                          return (
                            <div
                              key={key}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <p
                                style={{
                                  margin: 5,
                                  marginRight: 10,
                                  fontSize: 12,
                                  flex: 1,
                                  textAlign: "left",
                                }}
                              >
                                {option}
                              </p>
                              <button
                                style={{
                                  ...styles.propButton,
                                  backgroundColor: colors.secondary,
                                }}
                                onClick={() =>
                                  setOptions(
                                    options.filter((o) => o !== option)
                                  )
                                }
                              >
                                <img
                                  alt={"Remove prop"}
                                  src={remove}
                                  style={{
                                    width: 10,
                                    height: 10,
                                    objectFit: "contain",
                                  }}
                                ></img>
                              </button>
                            </div>
                          );
                        })}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginTop: 5,
                            width: "100%",
                          }}
                        >
                          <input
                            type="text"
                            style={{
                              ...styles.textInput,
                              width: "100%",
                              padding: 10,
                              textAlign: "left",
                            }}
                            value={newOption}
                            onChange={(event) =>
                              setNewOption(event.target.value)
                            }
                            placeholder="Campo"
                          />
                          <button
                            style={{
                              ...styles.propButton,
                              opacity: newOption !== "" ? 1 : 0.4,
                              backgroundColor: colors.secondary,
                            }}
                            disabled={newOption === ""}
                            onClick={() => {
                              setOptions(options.concat(newOption));
                              setNewOption("");
                            }}
                          >
                            <img
                              alt={"Add email button"}
                              src={add}
                              style={{
                                width: 10,
                                height: 10,
                                objectFit: "contain",
                              }}
                            ></img>
                          </button>
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: "5px",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={handleCheck}
                        checked={mandatory}
                      />
                      <p
                        style={{
                          ...styles.label,
                          width: "100%",
                          marginLeft: 10,
                          color: colors?.darkgray,
                        }}
                      >
                        Campo obbligatorio
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div style={styles.field}>
              <div style={onUpdate ? styles.boxResProps : styles.resInfoList}>
                <>
                  <div>
                    <p
                      style={{
                        color: colors.secondary,
                        fontSize: 13,
                        textAlign: "left",
                      }}
                    >
                      Biglietti disponibili
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: "5px",
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={handleCheckPayment}
                        checked={individualPayment}
                        disabled={!onUpdate}
                      />
                      <p
                        style={{
                          ...styles.label,
                          width: "",
                          marginLeft: 10,
                          color: colors?.darkgray,
                        }}
                      >
                        Costo per singolo partecipante
                      </p>
                      <InfoButton
                        width={200}
                        backgroundColor={colors?.quaternary}
                        text="Selezionando questa opzione, il costo dei biglietti é da intendersi per ogni partecipante all'attività, accompagnatori esclusi"
                        colors={colors}
                      />
                    </div>
                    {ticketsType && ticketsType.length > 0 ? (
                      ticketsType.map((el, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flex: 1,
                            }}
                            key={`tickets-type-${index.toString()}`}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 3,
                              }}
                            >
                              <p style={styles.label}>Tipo biglietto</p>
                              <input
                                type="text"
                                name="ticketsTypeName"
                                autoComplete="on"
                                value={el.name}
                                disabled={editingTicketsType !== el.id}
                                onChange={(event) => {
                                  const newTicketsType = JSON.parse(
                                    JSON.stringify(ticketsType)
                                  );
                                  newTicketsType[index].nome =
                                    event.target.value;
                                  setTicketsType(newTicketsType);
                                }}
                                placeholder="Tipo biglietto"
                                style={{
                                  ...styles.textInput,
                                  padding: 10,
                                  textAlign: "left",
                                  width: "80%",
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                flex: 2,
                              }}
                            >
                              <p style={styles.label}>Prezzo</p>
                              <input
                                type="text"
                                name="ticketsTypePrice"
                                autoComplete="on"
                                value={el.price}
                                disabled={editingTicketsType !== el.id}
                                onChange={(event) => {
                                  const newTicketsType = JSON.parse(
                                    JSON.stringify(ticketsType)
                                  );
                                  newTicketsType[index].price =
                                    event.target.value;
                                  setTicketsType(newTicketsType.price);
                                }}
                                placeholder="Prezzo"
                                style={{
                                  ...styles.textInput,
                                  padding: 10,
                                  textAlign: "left",
                                  width: "70%",
                                }}
                              />
                            </div>
                            {onUpdate && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  flex: 1,
                                  justifyContent: "flex-end",
                                }}
                              >
                                <button
                                  style={{
                                    ...styles.button,
                                    backgroundColor: colors.primary,
                                    padding: 10,
                                    borderRadius: 8,
                                    color: colors.white,
                                    marginBottom: 0,
                                  }}
                                  onClick={() => {
                                    var tmpList = [];
                                    if (editingTicketsType === el.id) {
                                      if (el.name && el.price) {
                                        tmpList = ticketsType;
                                        tmpList.forEach((ticket, index) => {
                                          if (ticket.id === el.id) {
                                            tmpList[index] = ticket;
                                          }
                                        });
                                        setEditingTicketsType("");
                                        setTicketsType(tmpList);
                                      }
                                    } else {
                                      // onDeleteTicketsType(el.id);
                                      ticketsType.forEach((ticket) => {
                                        if (ticket.id !== el.id) {
                                          tmpList.push(ticket);
                                        }
                                      });
                                      setTicketsType(tmpList);
                                    }
                                  }}
                                >
                                  {editingTicketsType === el.id ? (
                                    <img
                                      alt="Edit icon"
                                      src={reserved}
                                      style={{
                                        width: 10,
                                        objectFit: "contain",
                                        margin: "0",
                                      }}
                                    />
                                  ) : (
                                    <img
                                      alt="Delete icon"
                                      src={trash}
                                      style={{
                                        width: 10,
                                        objectFit: "contain",
                                        margin: "0",
                                      }}
                                    />
                                  )}
                                </button>
                              </div>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <p
                        style={{
                          color: colors?.darkgray,
                          fontSize: 10,
                          textAlign: "left",
                        }}
                      >
                        Nessun biglietto disponibile per l'attività. <br />
                      </p>
                    )}
                    {onUpdate && (
                      <div style={{ display: "flex", alignItems: "flex-end" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 3,
                          }}
                        >
                          <p style={styles.label}>Tipo biglietto</p>
                          <input
                            type="text"
                            name="ticketsTypeName"
                            autoComplete="on"
                            value={ticketsTypeName}
                            onChange={(event) =>
                              setTicketsTypeName(event.target.value)
                            }
                            placeholder="Tipo biglietto"
                            style={{
                              ...styles.textInput,
                              width: "80%",
                              padding: 10,
                              textAlign: "left",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 2,
                          }}
                        >
                          <p style={styles.label}>Prezzo</p>
                          <input
                            type="text"
                            name="ticketsTypePrice"
                            autoComplete="on"
                            value={ticketsTypePrice}
                            onChange={(event) =>
                              setTicketsTypePrice(
                                event.target.value.replace(/[^0-9.]/, "")
                              )
                            }
                            placeholder="Prezzo"
                            style={{
                              ...styles.textInput,
                              width: "70%",
                              padding: 10,
                              textAlign: "left",
                            }}
                          />
                        </div>
                        <div>
                          <button
                            style={{
                              ...styles.button,
                              backgroundColor: colors.primary,
                              padding: 10,
                              borderRadius: 8,
                              color: colors.white,
                              marginBottom: 0,
                            }}
                            onClick={() => {
                              const newTicketsType = [].concat(ticketsType);
                              newTicketsType.push({
                                name: ticketsTypeName,
                                price: ticketsTypePrice,
                              });
                              setTicketsType(newTicketsType);
                              setTicketsTypePrice("");
                              setTicketsTypeName("");
                            }}
                            disabled={!ticketsTypeName || !ticketsTypePrice}
                          >
                            <img
                              alt="Add icon"
                              src={add}
                              style={{
                                width: 10,
                                objectFit: "contain",
                                margin: "0",
                              }}
                            />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              </div>
            </div>
          </div>
          {!onUpdate && (
            <div style={{ width: "100%", marginTop: 10 }}>
              {activity?.prenotazioni.length > 0 ? (
                <div style={styles.field}>
                  <p
                    style={{
                      color: colors.secondary,
                      fontSize: 13,
                      textAlign: "left",
                    }}
                  >
                    Prenotazioni confermate:
                  </p>
                  <div
                    style={{ ...styles.list, height: "calc(100vh - 530px)" }}
                  >
                    {activity?.prenotazioni
                      .sort(function (a, b) {
                        return (
                          new Date(a.datetime_inizio) -
                          new Date(b.datetime_inizio)
                        );
                      })
                      .map((e, key) => {
                        console.log(e);
                        return (
                          <div
                            key={key}
                            style={
                              new Date(e.ora_inizio) >= new Date()
                                ? styles.event
                                : styles.passedEvent
                            }
                            onClick={() => getInfo(e.id, onShowReservation)}
                          >
                            <p style={{ ...styles.info, flex: 1 }}>
                              {moment(e.datetime_inizio).format("D MMMM yyyy")}
                            </p>
                            <div style={styles.info}>
                              <p>{e.ente.nome}</p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              ) : (
                <p
                  style={{
                    color: colors.secondary,
                    fontSize: 13,
                  }}
                >
                  Nessuna data in programma.
                </p>
              )}
            </div>
          )}
          {onUpdate && (
            <button
              style={{
                ...styles.button,
                backgroundColor: colors.primary,
                padding: 15,
                borderRadius: 15,
                width: "60%",
                marginBottom: 20,
                marginTop: 40,
                color: colors.white,
                opacity:
                  name === "" ||
                  description === "" ||
                  duration === "" ||
                  minParticipants === "" ||
                  abstract === "" ||
                  (blockingSpace && selectedSpace === "") ||
                  capacity === "" ||
                  ticketsType.length < 1
                    ? 0.3
                    : 1,
              }}
              disabled={
                name === "" ||
                description === "" ||
                duration === "" ||
                minParticipants === "" ||
                abstract === "" ||
                (blockingSpace && selectedSpace === "") ||
                capacity === "" ||
                ticketsType.length < 1
              }
              onClick={() => {
                setLoader(true);
                if (activity) {
                  onModifySchema(
                    activity.id,
                    name,
                    abstract,
                    description,
                    category,
                    duration,
                    capacity,
                    minParticipants,
                    model,
                    ticketsType,
                    selectedSpace,
                    individualPayment
                  );
                } else {
                  onCreateSchema(
                    name,
                    abstract,
                    description,
                    category,
                    duration,
                    capacity,
                    minParticipants,
                    model,
                    ticketsType,
                    pathToUpload,
                    selectedSpace,
                    individualPayment
                  );
                }
              }}
            >
              Salva
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ShowActivity;
