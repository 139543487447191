import React, { useState } from "react";
import add from "./../../img/add.png";

const AddButton = ({
  flag,
  toggleAdd,
  toggleAddItem,
  toggleAddExcel,
  colors,
}) => {
  const [open, setOpen] = useState(false);
  const styles = {
    button: {
      outline: "none",
      border: "none",
      display: "flex",
      backgroundColor: colors.primary,
      width: 80,
      height: 80,
      borderRadius: 50,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    buttonBox: {
      display: "flex",
      position: "fixed",
      flexDirection: "column",
      zIndex: 200,
      right: 100,
      bottom: 40,
      alignItems: "center",
      justifyContent: "center",
      width: 150,
    },
    option: {
      outline: "none",
      border: "none",
      display: "flex",
      zIndex: 200,
      backgroundColor: colors.primary,
      width: 150,
      borderRadius: 30,
      alignItems: "center",
      justifyContent: "center",
      color: colors.white,
      paddingLeft: 15,
      paddingRight: 15,
      cursor: "pointer",
      marginBottom: 10,
    },
  };
  return (
    <div
      style={styles.buttonBox}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      {open && (
        <div>
          <button
            onClick={() => {
              setOpen(!open);
              toggleAddExcel();
            }}
            style={styles.option}
          >
            <p>IMPORTA INVENTARIO</p>
          </button>
          <button
            onClick={() => {
              setOpen(!open);
              toggleAdd();
            }}
            style={styles.option}
          >
            <p>AGGIUNGI CATEGORIA</p>
          </button>
          {flag && (
            <button
              onClick={() => {
                setOpen(!open);
                toggleAddItem();
              }}
              style={styles.option}
            >
              <p>AGGIUNGI ARTICOLO</p>
            </button>
          )}
        </div>
      )}
      <button style={styles.button} onClick={() => setOpen(!open)}>
        <img
          alt="Add icon"
          src={add}
          style={{ width: 35, objectFit: "contain" }}
        />
      </button>
    </div>
  );
};

export default AddButton;
