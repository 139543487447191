import React, { useContext, useState } from "react";
import close from "./../../img/closeButton.png";
import ReactLoading from "react-loading";
import { usersService } from "../../_services";
import { OrgContext } from "../../contexts";

const AddUser = ({
  rolesList,
  closeModal,
  saveData,
  sendEmail,
  showConfirmation,
  showEmailError,
  colors,
  me,
}) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [role, setRole] = useState("1");
  const [loader, setLoader] = useState(false);
  const [step, setStep] = useState(1);

  const org = useContext(OrgContext)?.org;

  //Flag used to show the correct fields when trying to add a new user (the things shown change depending on the fact that the email is already associated to an account or not)
  const [multiOrg, setMultiOrg] = useState(false);

  const [alreadyInOrg, setAlreadyInOrg] = useState(false);

  const checkEmail = (email) => {
    setLoader(true);
    setEmail(email);

    usersService.getUserFromEmail(email).then((response) => {
      if (Object.keys(response).length > 0) {
        let alreadyReg = response.user.org_list.find(
          (org) => org.orgId === me.org_museo
        );

        if (alreadyReg !== undefined) {
          setAlreadyInOrg(true);
        } else {
          setMultiOrg(true);
          setAlreadyInOrg(false);
          setStep(2);
        }
      } else {
        setStep(2);
        setMultiOrg(false);
        setAlreadyInOrg(false);
      }
    });
    setLoader(false);
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 30,
      bottom: 30,
      left: 0,
      right: 0,
      margin: "auto",
      width: 500,
      height: step === 1 ? 350 : 500,
      zIndex: 300,
      backgroundColor: colors.white,
      borderRadius: 10,
    },
    header: {
      display: "flex",
      width: "100%",
      backgroundColor: colors.primary,
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    content: {
      width: "100%",
      height: step === 1 ? 350 : 500,
      backgroundColor: colors.white,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      overflow: "scroll",
    },
    message: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      margin: 40,
    },
    textInput: {
      border: "1px solid lightgray",
      borderRadius: 10,
      width: "90%",
      padding: 15,
      outline: "none",
    },
    field: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      width: "80%",
      marginTop: 15,
    },
    footer: {
      display: "flex",
      width: "100%",
      backgroundColor: colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      border: "none",
      outline: "none",
      marginTop: 30,
      cursor: "pointer",
    },
    disabledButton: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      border: "none",
      outline: "none",
      marginTop: 30,
      opacity: 0.7,
    },
    menu: {
      border: "1px solid lightgray",
      width: "100%",
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 15,
      paddingBottom: 15,
      color: colors.darkgray,
      borderRadius: 20,
      backgroundColor: "transparent",
      outline: "none",
    },
    box: {
      width: 400,
      display: "flex",
    },
  };

  React.useEffect(() => {
    setLoader(false);
  }, [showEmailError]);

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          witdh: "100%",
          height: "100%",
          margin: "auto",
          backgroundColor: colors.gray,
          opacity: 0.3,
        }}
      />
      <div style={styles.container}>
        <div style={styles.header}>
          <p style={{ color: colors.white, fontSize: 20 }}>AGGIUNGI UTENTE</p>
          <button
            onClick={closeModal}
            style={{
              position: "absolute",
              right: 30,
              marginTop: "auto",
              marginBottom: "auto",
              border: "none",
              backgroundColor: "transparent",
              outline: "none",
              cursor: "pointer",
            }}
          >
            <img
              alt="Close button"
              src={close}
              style={{ width: 15, objectFit: "contain" }}
            />
          </button>
        </div>
        {showConfirmation ? (
          <div style={styles.content}>
            <div style={styles.message}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 25,
                  color: colors.primary,
                  textAlign: "left",
                }}
              >
                {multiOrg
                  ? "Invito inoltrato con successo"
                  : "Registrazione completata con successo"}
              </p>
              <p
                style={{
                  fontSize: 15,
                  color: colors.primary,
                  textAlign: "left",
                }}
              >
                {multiOrg
                  ? "Un'email per accettare l'invito é stata inviata all'indirizzo " +
                    email
                  : "Un'email per l'abilitazione del nuovo account è stata inviata all'indirizzo " +
                    email}
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 10,
                  color: colors.primary,
                  textAlign: "left",
                }}
              >
                Attenzione! L'email potrebbe essere stata erroneamente
                reindirizzata alla casella Spam.
              </p>
            </div>
          </div>
        ) : loader && !showEmailError ? (
          <div
            style={{
              height: 280,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: colors.white,
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={colors.primary}
              height={50}
              width={50}
            />
          </div>
        ) : (
          <form style={styles.content}>
            {step === 1 && (
              <div style={styles.field}>
                <p style={{ fontSize: 15, color: colors.lightgray }}>Email</p>
                <input
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder="Email"
                  style={styles.textInput}
                />
                {showEmailError && (
                  <p style={{ color: colors.highlight, fontSize: 10 }}>
                    {multiOrg
                      ? "Si é verificato un errore, riprovare."
                      : "L'email è già stata utilizzata."}
                  </p>
                )}
                {alreadyInOrg && (
                  <p style={{ color: colors.highlight, fontSize: 10 }}>
                    Esiste già un utente con questa email registrato in questo
                    museo.
                  </p>
                )}
              </div>
            )}
            {!multiOrg && step === 2 && (
              <>
                <div style={styles.field}>
                  <p style={{ fontSize: 15, color: colors.lightgray }}>
                    Nome utente
                  </p>
                  <input
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    placeholder="Nome Cognome"
                    style={styles.textInput}
                  />
                </div>
                <div style={styles.field}>
                  <p style={{ fontSize: 15, color: colors.lightgray }}>
                    Password
                  </p>
                  <input
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder="Password"
                    style={styles.textInput}
                    type="password"
                  />
                </div>
                <div style={styles.field}>
                  <p style={{ fontSize: 15, color: colors.lightgray }}>
                    Conferma password
                  </p>
                  <input
                    value={confirmPassword}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                    placeholder="Password"
                    style={styles.textInput}
                    type="password"
                  />
                  {confirmPassword !== "" && password !== confirmPassword && (
                    <div style={{ color: colors.highlight, fontSize: 13 }}>
                      Le password non corrispondono.
                    </div>
                  )}
                </div>
              </>
            )}
            {multiOrg && step === 2 && (
              <div style={styles.field}>
                <p style={{ fontSize: 13, color: colors.lightgray }}>
                  Account esistente. L'indirizzo mail inserito appartiene già ad
                  un account EMMA.
                </p>
                <p style={{ fontSize: 13, color: colors.lightgray }}>
                  Vuoi consentire all'account {email} di avere accesso al museo{" "}
                  {org.nome}?
                </p>
                <p style={{ fontSize: 13, color: colors.lightgray }}>
                  Scegliendo "Aggiungi utente" verrà inviato all'indirizzo{" "}
                  {email} un invito ad unirsi al team
                </p>
              </div>
            )}
            {step === 2 && (
              <div style={styles.field}>
                <p style={{ fontSize: 15, color: colors.lightgray }}>
                  Inserisci ruolo
                </p>
                <div style={styles.box}>
                  <select
                    name="user"
                    style={styles.menu}
                    value={role}
                    onChange={(event) => setRole(event.target.value)}
                  >
                    {rolesList.roles.map((role, key) => {
                      if (role.name !== "Public") {
                        return (
                          <option key={key} value={role.id}>
                            {role.name}
                          </option>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </select>
                </div>
              </div>
            )}
          </form>
        )}
        <div style={styles.footer}>
          {step === 2 && !loader && (
            <button
              onClick={() => {
                setStep(step - 1);
              }}
              style={{ ...styles.button, backgroundColor: colors.secondary }}
            >
              INDIETRO
            </button>
          )}
          {!showConfirmation && !loader ? (
            <button
              onClick={() => {
                step === 1 && checkEmail(email);
                step === 2 && setLoader(true);
                !multiOrg &&
                  step === 2 &&
                  saveData(name, email, password, role);
                multiOrg && step === 2 && sendEmail(email, role, multiOrg);
              }}
              disabled={
                step === 1
                  ? email === ""
                  : !multiOrg &&
                    (name === "" ||
                      password === "" ||
                      confirmPassword === "" ||
                      role === "" ||
                      password !== confirmPassword)
              }
              style={
                step === 1
                  ? email === ""
                    ? styles.disabledButton
                    : styles.button
                  : !multiOrg &&
                    (name === "" ||
                      password === "" ||
                      confirmPassword === "" ||
                      role === "" ||
                      password !== confirmPassword)
                  ? styles.disabledButton
                  : styles.button
              }
            >
              {step === 1 ? "PROSEGUI" : "AGGIUNGI UTENTE"}
            </button>
          ) : (
            <div style={{ height: 20 }} />
          )}
        </div>
      </div>
    </>
  );
};

export default AddUser;
