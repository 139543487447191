import React from "react";
import { Switch } from "react-router-dom";
import Login from "./login";
import Registration from "./registration";
import ChangePassword from "./changePassword";
import Dashboard from "./dashboard";
import Route from "./route";
import VisitorReservation from "./visitorReservation";
import VisitorEventReservation from "./visitorEventReservation";
import OnlineEventReservation from "./onlineEventReservation";
import OnlineEventReservationRedirect from "./onlineEventReservationRedirect";
import FindReservation from "./findReservation";
import DeleteReservation from "./deleteReservation";
import CardShop from "./cardShop";
import ActivityView from "../modules/activities/online/activityView";
import SpeakerView from "../modules/activities/online/speakerView";
import SpeakerLogin from "../modules/activities/activities/speakersDash/speakerLogin";
import SpeakerDashboard from "../modules/activities/activities/speakersDash/speakerDashboard";
import AllActivities from "../modules/activities/online/allActivities";
import RedirectLogin from "../modules/activities/activities/speakersDash/redirectLogin";
import JoinOrg from "./joinOrg";

const Routes = () => {
  return (
    <Switch>
      <Route path="/" exact component={Login} isPrivate={false} />
      <Route path="/signup" component={Registration} isPrivate={false} />
      <Route path="/join/:token" component={JoinOrg} isPrivate={false} />
      <Route
        path="/:museum/ingressi"
        component={VisitorReservation}
        isPrivate={false}
      />
      <Route
        path="/:museum/eventi"
        exact
        component={VisitorEventReservation}
        isPrivate={false}
      />
      <Route
        path="/:museum/eventi/:event/:code"
        component={OnlineEventReservation}
        isPrivate={false}
      />
      <Route
        path="/:museum/eventiredirect/:event/:code"
        component={OnlineEventReservationRedirect}
        isPrivate={false}
      />
      <Route
        path={"/:museum/activities"}
        component={AllActivities}
        isPrivate={false}
      />
      <Route
        path={"/:museum/activity/:id"}
        component={ActivityView}
        isPrivate={false}
      />
      <Route
        path="/:museum/relatore/:id"
        component={SpeakerView}
        isPrivate={false}
      />
      <Route
        path={["/relatore/login/:orgId", "/relatore/login"]}
        component={SpeakerLogin}
        isPrivate={false}
      />
      <Route
        path="/redirectLogin"
        component={RedirectLogin}
        isPrivate={false}
      />
      <Route
        path="/relatore/dashboard"
        component={SpeakerDashboard}
        isPrivate={false}
      />
      <Route
        path="/iframe/:index/:index2"
        component={Dashboard}
        isPrivate={true}
      />
      <Route
        path={["/dashboard", "/iframe/:index"]}
        component={Dashboard}
        isPrivate={true}
      />
      <Route
        path="/changePassword/:code"
        component={ChangePassword}
        isPrivate={false}
      />
      <Route
        path="/cancellazione"
        exact
        component={FindReservation}
        isPrivate={false}
      />
      <Route
        path="/cancellazione/:type/:id/:otp"
        component={DeleteReservation}
        isPrivate={false}
      />
      <Route
        path={["/:organisation/card", "/:organisation/card/:section"]}
        component={CardShop}
        isPrivate={false}
        exact
      />
      {/* redirect user to SignIn page if route does not exist and user is not authenticated*/}
      <Route component={Login} isPrivate={true} />
    </Switch>
  );
};

export default Routes;
