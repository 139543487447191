import React from "react";
import colors from "./../../museumColors";

const Success = ({ createdCards, restart, renewal, isCardGifted }) => {
  const styles = {
    body: {
      flex: 1,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      overflow: "scroll",
    },
    button: {
      outline: "none",
      border: "none",
      display: "flex",
      backgroundColor: colors.darkgray,
      width: 80,
      height: 50,
      borderRadius: 50,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    title: {
      color: colors.darkgray,
      fontWeight: "800",
      width: "80%",
      textAlign: "left",
      fontSize: 25,
      marginBottom: 0,
    },
    subtitle: {
      color: colors.gray,
      fontWeight: "500",
      width: "80%",
      textAlign: "left",
    },
    text: {
      width: "80%",
      textAlign: "left",
      fontSize: 13,
    },
    column: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    label: {
      fontSize: 12,
      width: "60%",
    },
    restartButton: {
      outline: "none",
      border: "none",
      display: "flex",
      backgroundColor: "transparent",
      width: "70%",
      alignItems: "center",
      justifyContent: "flex-end",
      cursor: "pointer",
      color: colors.gray,
      marginTop: 20,
      fontSize: 12,
      textDecoration: "underline",
    },
  };
  return (
    <div style={styles.body}>
      <div style={styles.column}>
        <p style={styles.title}>
          {isCardGifted === true
            ? "Voucher creato ed inviato correttamente."
            : createdCards.length > 1
            ? renewal
              ? "Rinnovo tessere concluso correttamente."
              : "Creazione tessere conclusa correttamente."
            : renewal
            ? "Rinnovo tessera concluso correttamente."
            : "Creazione tessera conclusa correttamente."}
        </p>
        {isCardGifted !== true && (
          <p style={styles.subtitle}>
            {createdCards.length > 1
              ? renewal
                ? "Sono state rinnovate "
                : "Sono state create "
              : renewal
              ? "È stata rinnovata "
              : "È stata creata "}{" "}
            <b>{createdCards.length}</b>{" "}
            {createdCards.length > 1 ? " tessere." : " tessera."}
          </p>
        )}
        {isCardGifted !== true &&
          createdCards.map((card, key) => {
            return (
              <p key={key} style={styles.text}>
                {card.nome} {card.cognome} - Card n° <b>{card.numero}</b>
              </p>
            );
          })}
        <button style={styles.restartButton} onClick={restart}>
          {" "}
          Ok
        </button>
      </div>
    </div>
  );
};

export default Success;
