import React, { useEffect, useState } from "react";
import modify from "./../../img/modify_gray.png";
import trash from "./../../img/delete_gray.png";
import add from "./../../img/plus.png";
import reduce from "./../../img/reduce.png";
import close from "./../../img/closeButton_gray.png";
import "../../scrollbar.css";

const Item = ({
  isTicket,
  isBook,
  item,
  onDelete,
  onModify,
  onAddStock,
  onModifyStock,
  colors,
  purchaseMethods,
}) => {
  const [openCrud, setopenCrud] = useState(false);
  const [stockQty, setStockQty] = useState(0);

  useEffect(() => {
    let tmp = 0;
    for (let purchase of item.acquisti) {
      tmp += purchase.giacenza;
    }
    setStockQty(tmp);
  }, [item]);

  const styles = {
    rowContainer: {
      background: openCrud ? colors.quaternary : "none",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "row",
    },
    nameField: {
      width: 250,
      fontSize: 15,
      textAlign: "center",
      margin: 20,
    },
    field: {
      width: 180,
      fontSize: 15,
      textAlign: "center",
      margin: 20,
    },
    crudField: {
      width: 200,
      textAlign: "center",
      margin: 10,
    },
    crudButton: {
      border: "none",
      backgroundColor: "transparent",
      outline: "none",
      cursor: "pointer",
    },
  };

  return (
    <div style={styles.rowContainer}>
      <button
        onClick={() => setopenCrud(!openCrud)}
        style={{
          outline: "none",
          border: "none",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "transparent",
          color: openCrud ? colors.primary : "black",
        }}
      >
        <div>
          <p style={styles.nameField}>{item.nome_articolo}</p>
        </div>
        {isBook && (
          <>
            <div>
              <p style={styles.field}>{item.autore ? item.autore : "-"}</p>
            </div>
            <div>
              <p style={styles.field}>
                {item.codice_articolo ? item.codice_articolo : "-"}
              </p>
            </div>
          </>
        )}
        <div>
          <p style={styles.field}>
            € {item.prezzo_articolo && item.prezzo_articolo.toFixed(2)}
          </p>
        </div>
        {isTicket && (
          <div>
            <p style={styles.field}>{item.num_ingressi}</p>
          </div>
        )}
        {isTicket && (
          <div>
            <p style={styles.field}>
              {item.validita}
              {item.validita === 1 ? " giorno" : " giorni"}
            </p>
          </div>
        )}
        {!isTicket && (
          <>
            <div>
              <p style={styles.field}>{stockQty}</p>
            </div>
          </>
        )}
      </button>
      {openCrud && (
        <div
          style={{
            outline: "none",
            backgroundColor: colors.quaternary,
            borderRadius: "10px",
            borderColor: "transparent",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: "15px",
          }}
        >
          <div style={styles.crudField}>
            <button onClick={() => onDelete()} style={styles.crudButton}>
              <img
                alt="Delete icon"
                src={trash}
                style={{
                  width: 15,
                  objectFit: "contain",
                  marginRight: 12,
                }}
              />
            </button>
            <button onClick={() => onModify()} style={styles.crudButton}>
              <img
                alt="Modify icon"
                src={modify}
                style={{
                  width: 18,
                  objectFit: "contain",
                  marginRight: 12,
                }}
              />
            </button>
            {!isTicket && (
              <button onClick={() => onAddStock()} style={styles.crudButton}>
                <img
                  alt="Add stock icon"
                  src={add}
                  style={{
                    width: 18,
                    objectFit: "contain",
                    marginRight: 12,
                  }}
                />
              </button>
            )}
            {!isTicket && stockQty > 0 && (
              <button onClick={() => onModifyStock()} style={styles.crudButton}>
                <img
                  alt="Modify stock icon"
                  src={reduce}
                  style={{
                    width: 18,
                    objectFit: "contain",
                    marginRight: 12,
                  }}
                />
              </button>
            )}
            <button
              onClick={() => setopenCrud(!openCrud)}
              style={styles.crudButton}
            >
              <img
                alt="Close icon"
                src={close}
                style={{ width: 17, objectFit: "contain" }}
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Item;
