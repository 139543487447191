import React from "react";
import colors from "./../../museumColors";
import download from "./../../img/download.png";
import done from "./../../img/reserved.png";

/*
    // Retrieve voucher
    */
function getVoucher(url) {
  var a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.download = "Voucher.pdf";
  document.body.appendChild(a);
  a.click();
  a.remove();
}

const SuccessStep = ({ name, width, voucher, restart, renewal }) => {
  const styles = {
    body: {
      flex: 1,
      width: "100%",
      height: "100%",
      display: width > 1000 && "flex",
      flexDirection: width > 1000 ? "row" : "column",
      justifyContent: "center",
      alignItems: "center",
      overflow: "scroll",
    },
    button: {
      outline: "none",
      border: "none",
      display: "flex",
      backgroundColor: colors.darkgray,
      width: 80,
      height: 50,
      borderRadius: 50,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    title: {
      color: colors.darkgray,
      fontWeight: "800",
      width: width > 1000 ? "60%" : "85%",
      textAlign: "left",
      fontSize: 25,
      marginBottom: 0,
    },
    subtitle: {
      color: colors.gray,
      fontWeight: "500",
      width: width > 1000 ? "60%" : "85%",
      textAlign: "left",
    },
    text: {
      width: width > 1000 ? "60%" : "85%",
      textAlign: "left",
      fontSize: 13,
    },
    column: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    label: {
      fontSize: 12,
      width: "60%",
    },
    restartButton: {
      outline: "none",
      border: "none",
      display: "flex",
      backgroundColor: "transparent",
      width: "70%",
      height: 50,
      alignItems: "center",
      justifyContent: "flex-end",
      cursor: "pointer",
      color: colors.gray,
      marginTop: 50,
      fontSize: 12,
      textDecoration: "underline",
    },
    errorBox: {
      display: "flex",
      backgroundColor: colors.green,
      width: 80,
      height: 80,
      borderRadius: 50,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
  };
  return (
    <div style={styles.body}>
      <div
        style={{
          ...styles.column,
          flex: 1,
          alignItems: width > 1000 ? "flex-end" : "center",
          justifyContent: width > 1000 ? "center" : "flex-end",
          marginTop: width < 1000 && 20,
        }}
      >
        {renewal ? (
          <div style={styles.errorBox}>
            <img
              alt="Done icon"
              src={done}
              style={{ height: 35, objectFit: "contain" }}
            />
          </div>
        ) : (
          <div style={{ ...styles.column, marginBottom: width < 1000 && 20 }}>
            <p style={styles.label}>Scarica qui il tuo voucher</p>
            <button
              style={{ ...styles.button, height: 80 }}
              onClick={() => getVoucher(voucher)}
            >
              <img
                alt="Download icon"
                src={download}
                style={{ height: 35, objectFit: "contain" }}
              />
            </button>
          </div>
        )}
      </div>
      <div style={{ ...styles.column, flex: 2 }}>
        <p style={styles.title}>Grazie per il tuo acquisto</p>
        <p style={styles.subtitle}>
          {renewal ? "Il rinnovo" : "L'acquisto"} della card <b>{name}</b> si è
          concluso correttamente.
        </p>
        {!renewal && (
          <p style={styles.text}>
            Scarica il voucher e ritira la tua tessera al museo.
            <br />
            Potrai ritirare la tua tessera in qualsiasi momento, mostrando il
            voucher agli operatori di biglietteria dallo schermo del tuo
            smartphone o in versione cartacea.
          </p>
        )}
        <button style={styles.restartButton} onClick={restart}>
          {" "}
          Effettua un nuovo {renewal ? "rinnovo" : "acquisto"}
        </button>
      </div>
    </div>
  );
};

export default SuccessStep;
