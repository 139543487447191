import config from "../config";
import { handleResponse, authHeader } from "../_helpers";

export const itemsService = {
  getCategories,
  getPurchaseMethods,
  getFormattedCategories,
  getItems,
  createItem,
  addArticlePurchase,
  decreaseQuantity,
  modifyItem,
  deleteItem,
  createCategory,
  modifyCategory,
  deleteCategory,
  checkCode,
  findItem,
  getVisitorByCode,
  sell,
  createInventory,
  updateOrderCategory,
  updateAscendantCategory,
  getInventoryPdf,
  getInventoryExcel,
  getBarcodeDoc,
  isTicketSold,
  checkEntrance,
  getTicketsPublic,
  getTips,
  getTipsIngressi,
};

function getTipsIngressi(orgId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.worker.url}/listasuggerimentiingressi/${orgId}`,
    requestOptions
  ).then(handleResponse);
}

function getTips(orgId) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.worker.url}/listasuggerimenti/${orgId}`,
    requestOptions
  ).then(handleResponse);
}

function getCategories() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/categories/get_all`, requestOptions).then(
    handleResponse
  );
}

function getPurchaseMethods() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/modalita_acquistos`, requestOptions).then(
    handleResponse
  );
}

function getItems(org, start, limit, categoryId, articleName, author) {
  var request = `${config.api.url}/articolis?organisation=${org}&_limit=${limit}&_start=${start}`;

  if (categoryId && articleName && articleName !== "") {
    request += `&categoria_articolo=${categoryId}&nome_articolo=${articleName.trim()}`;
  }
  if (author && author !== "") {
    request += `&autore=${author.trim()}`;
  }

  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(request, requestOptions).then(handleResponse);
}

function getTicketsPublic(orgId) {
  const requestOptions = { method: "GET" };
  return fetch(`${config.api.url}/articolis/${orgId}`, requestOptions).then(
    handleResponse
  );
}

function getFormattedCategories() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/categories/get_formatted`,
    requestOptions
  ).then(handleResponse);
}

function getInventoryPdf() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/articolis/inventarioPdf`,
    requestOptions
  ).then(handleResponse);
}

function getInventoryExcel() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/articolis/inventarioExcel`,
    requestOptions
  ).then(handleResponse);
}

function getBarcodeDoc() {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/articolis/docVetrina`, requestOptions).then(
    handleResponse
  );
}

function isTicketSold(code) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/visitatoris/isTicketSold/${code}`,
    requestOptions
  ).then(handleResponse);
}

function checkEntrance(code) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/ingressis/checkIngresso/${code}/web`,
    requestOptions
  ).then(handleResponse);
}

function createItem(
  codice_articolo,
  nome_articolo,
  autore,
  categoria_articolo,
  modalita_acquisto,
  venditore_articolo,
  quantita_acquistata,
  percentuale_guadagno,
  scadenza_conto_vendita,
  costo_articolo,
  prezzo_articolo,
  num_ingressi,
  validita
) {
  if (nome_articolo) {
    nome_articolo = encodeURIComponent(nome_articolo);
  }
  if (venditore_articolo) {
    venditore_articolo = encodeURIComponent(venditore_articolo);
  }
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      codice_articolo,
      nome_articolo,
      autore,
      categoria_articolo,
      modalita_acquisto,
      venditore_articolo,
      quantita_acquistata,
      percentuale_guadagno,
      scadenza_conto_vendita,
      costo_articolo,
      prezzo_articolo,
      num_ingressi,
      validita,
    }),
  };

  return fetch(`${config.api.url}/articolis`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function addArticlePurchase(
  modalita_acquisto,
  scadenza_conto_vendita,
  quantita,
  venditore,
  percentuale_guadagno,
  costo_articolo,
  giacenza,
  codice_articolo
) {
  if (venditore) {
    venditore = encodeURIComponent(venditore);
  }
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      modalita_acquisto,
      scadenza_conto_vendita,
      quantita,
      venditore,
      percentuale_guadagno,
      costo_articolo,
      giacenza,
      codice_articolo,
    }),
  };
  return fetch(`${config.api.url}/articolis/addArticlePurchase`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function decreaseQuantity(codice_articolo, quantita) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      codice_articolo,
      quantita,
    }),
  };

  return fetch(`${config.api.url}/articolis/decreaseQuantity`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function updateOrderCategory(id, ordine) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      id,
      ordine,
    }),
  };

  return fetch(`${config.api.url}/categories/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function updateAscendantCategory(id, ascendente) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      id,
      ascendente,
    }),
  };

  return fetch(`${config.api.url}/categories/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function modifyItem(
  id,
  nome_articolo,
  autore,
  categoria_articolo,
  prezzo_articolo,
  num_ingressi,
  validita
) {
  if (nome_articolo) {
    nome_articolo = encodeURIComponent(nome_articolo);
  }
  if (autore) {
    autore = encodeURIComponent(autore);
  }
  var deleted = false;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      nome_articolo,
      autore,
      categoria_articolo,
      prezzo_articolo,
      num_ingressi,
      validita,
      deleted,
    }),
  };

  return fetch(`${config.api.url}/articolis/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteItem(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/articolis/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function createCategory(nome_categoria) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      nome_categoria,
    }),
  };

  return fetch(`${config.api.url}/categories`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function modifyCategory(id, nome_categoria) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      nome_categoria,
    }),
  };

  return fetch(`${config.api.url}/categories/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteCategory(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/categories/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function checkCode(isbn) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(`${config.api.url}/articolis/isbn/${isbn}`, requestOptions).then(
    handleResponse
  );
}

function findItem(code) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/articolis/findCode/${code}`,
    requestOptions
  ).then(handleResponse);
}

function getVisitorByCode(code) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/visitatoris/findCode/${code}`,
    requestOptions
  ).then(handleResponse);
}

function sell(items, metodo_pagamento, tessera) {
  const mobile = false;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({ items, mobile, metodo_pagamento, tessera }),
  };

  return fetch(`${config.api.url}/vendites`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function createInventory() {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
  };

  return fetch(`${config.api.url}/articolis/inventarioArticoli`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}
