import React, { useCallback, useEffect, useState, useContext } from "react";
import AddCategory from "../../../../components/popup/addCategory";
import ModifyCategory from "../../../../components/popup/modifyCategory";
import RemoveSchemaEvent from "../../popup/removeSchemaEvent";
import {
  eventsService,
  reservationsService,
  settingsService,
} from "../../../../_services";
import EndOperation from "../../../../components/popup/endOperation";
import ShowActivity from "./showActivity";
import RemoveEventCategory from "../../popup/removeEventCategory";
import add from "./../../../../img/add.png";
import ReactLoading from "react-loading";
import GenerateInvitation from "../../../../components/popup/generateInvitation";
import ActivityCategory from "./activityCategory";
import ShowReservation from "../../popup/showReservation";
import { UserContext } from "../../../../contexts";
import { activitiesService } from "../../../../_services/activities.service";

const ActivitiesManager = (props) => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [neatCategoriesList, setNeatCategoriesList] = useState([]);
  const [showAddCategory, setShowAddCategory] = useState(false);
  const [showAddSchema, setShowAddSchema] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteCategory, setShowDeleteCategory] = useState(false);
  const [showModifyCategory, setShowModifyCategory] = useState(false);
  const [showGenerate, setShowGenerate] = useState(false);
  const [showReservation, setShowReservation] = useState(false);
  const [eventSelected, setEventSelected] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const [endOperation, setEndOperation] = useState(false);
  const operationResult = useState(true);
  const [operation, setOperation] = useState(null);
  const [event, setEvent] = useState(null);
  const [loader, setLoader] = useState(true);
  const [hover, setHover] = useState(false);
  const colors = useContext(UserContext)?.colors;
  const [spaces, setSpaces] = useState([]);

  const [blockingSpace, setBlockingSpace] = useState(false);

  const getActivities = useCallback(() => {
    eventsService
      .getActivityCategories()
      .then((categoriesList) => {
        setCategoriesList(categoriesList);
        let tempList = [];
        for (let i = 0; i < categoriesList.length; i++) {
          let temp = {};
          temp.id = categoriesList[i].id;
          temp.name = categoriesList[i].nome;
          tempList.push(temp);
        }
        setNeatCategoriesList(tempList);
        setLoader(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  }, [props.history]);

  const onAddCategory = (newCategory) => {
    let nome = newCategory;
    eventsService
      .createActivityCategory(nome)
      .then((newCategoriesList) => {
        let tempList = [];
        for (let i = 0; i < newCategoriesList.length; i++) {
          let temp = {};
          temp.id = newCategoriesList[i].id;
          temp.name = newCategoriesList[i].nome;
          tempList.push(temp);
        }
        setCategoriesList(newCategoriesList);
        setNeatCategoriesList(tempList);
        toggleAddCategory();
        setEndOperation(true);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onAddSchema = (
    nome,
    abstract,
    descrizione,
    categoria,
    durata,
    capienza,
    min_partecipanti,
    modello_prenotazione,
    biglietti,
    pathToUpload,
    selectedSpace,
    individualPayment
  ) => {
    eventsService
      .createActivity(
        nome,
        abstract,
        descrizione,
        categoria,
        durata,
        capienza,
        min_partecipanti,
        modello_prenotazione,
        biglietti,
        selectedSpace !== "" ? selectedSpace : null,
        individualPayment
      )
      .then((newCategoriesList) => {
        if (pathToUpload) {
          let selectedEvent;
          let temp = null;
          for (let i = 0; i < newCategoriesList.length; i++) {
            temp = newCategoriesList[i].lista_attivita.find(
              (event) => event.nome === nome && event.categoria.id === categoria
            );
            if (temp !== undefined) {
              selectedEvent = temp;
            }
          }
          if (selectedEvent !== undefined) {
            uploadImg(selectedEvent.id, pathToUpload, true);
          }
          setCategoriesList(newCategoriesList);
        } else {
          toggleAddSchema();
          setEndOperation(true);
        }
        setCategoriesList(newCategoriesList);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onModifyCategory = (newName) => {
    let id = categorySelected.id;
    let nome = newName;
    eventsService
      .modifyActivityCategory(id, nome)
      .then((newCategoriesList) => {
        setShowModifyCategory(false);
        setCategorySelected(null);
        setCategoriesList(newCategoriesList);
        setEndOperation(true);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onModifySchema = (
    id,
    nome,
    abstract,
    descrizione,
    categoria,
    durata,
    capienza,
    min_partecipanti,
    modello_prenotazione,
    biglietti,
    selectedSpace,
    individualPayment
  ) => {
    setOperation(1);
    eventsService
      .modifyActivity(
        id,
        nome,
        abstract,
        descrizione,
        categoria,
        durata,
        capienza,
        min_partecipanti,
        modello_prenotazione,
        biglietti,
        selectedSpace !== "" ? selectedSpace : null,
        individualPayment
      )
      .then((newCategoriesList) => {
        setCategoriesList(newCategoriesList);
        setEndOperation(true);
        setEvent(null);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onDeleteCategory = () => {
    if (categorySelected.lista_attivita.length > 0) {
      categorySelected.lista_attivita.forEach((event) => {
        eventsService.deleteActivity(event.id).catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            props.history.push("/");
          }
        });
      });
    }

    let id = categorySelected.id;
    eventsService
      .deleteActivityCategory(id)
      .then((newCategoriesList) => {
        setShowDeleteCategory(false);
        setEventSelected(null);
        setCategoriesList(newCategoriesList);
        setEndOperation(true);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onDeleteSchema = () => {
    let id = eventSelected.id;
    eventsService
      .deleteActivity(id)
      .then((newCategoriesList) => {
        setShowDelete(false);
        setEventSelected(null);
        setCategoriesList(newCategoriesList);
        setEndOperation(true);
        setEvent(null);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const onGenerateInvitations = (num) => {
    const num_ingressi = 2;
    let id = eventSelected.id;
    reservationsService
      .createEventInvitations(id, num, num_ingressi)
      .then((response) => {
        var a = document.createElement("a");
        a.href = response.url;
        a.target = "_blank";
        a.download = "inviti.xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
        toggleShowGenerate(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const downloadRes = (id) => {
    setLoader(true);
    reservationsService
      .getResDoc(id)
      .then((file) => {
        setLoader(false);
        var a = document.createElement("a");
        a.href = file.url;
        a.target = "_blank";
        a.download = "prenotazioni.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  const toggleAddCategory = () => {
    if (!showAddCategory) {
      setOperation(0);
    }
    setShowAddCategory(!showAddCategory);
  };

  const toggleAddSchema = (category) => {
    if (!showAddSchema) {
      setOperation(0);
    } else {
      setEventSelected(null);
    }

    setShowAddSchema(!showAddSchema);
    setCategorySelected(category);
  };

  const toggleModifyCategory = (cat) => {
    setCategorySelected(cat);
    setShowModifyCategory(!showModifyCategory);
  };

  useEffect(() => {
    if (showModifyCategory) {
      setOperation(1);
    }
  }, [showModifyCategory]);

  const toggleShowReservation = (res) => {
    if (!showReservation) {
      setOperation(1);
    }

    setEventSelected(res);
    setShowReservation(!showReservation);
  };

  const toggleDeleteCategory = (cat) => {
    setCategorySelected(cat);
    setShowDeleteCategory(!showDeleteCategory);
  };

  useEffect(() => {
    if (showDeleteCategory) {
      setOperation(2);
    }
  }, [showDeleteCategory]);

  const toggleDeleteSchema = (event) => {
    setEventSelected(event);
    setShowDelete(!showDelete);
  };

  useEffect(() => {
    if (showDelete) {
      setOperation(2);
    }
  }, [showDelete]);

  const toggleEndOperation = () => {
    setEndOperation(!endOperation);
  };

  const toggleShowGenerate = (event) => {
    setEventSelected(event);
    setShowGenerate(!showGenerate);
  };

  const toggleInfo = (eventSelected) => {
    setEvent(null);
    setShowAddSchema(false);

    if (event === eventSelected) {
      setEvent(null);
      setShowAddSchema(false);
    } else if (!showAddSchema) {
      setEvent(eventSelected);
      setShowAddSchema(false);
    }
  };

  const closeInfo = () => {
    setEvent(null);
    setShowAddSchema(false);
  };

  const uploadImg = (id, img, isCreation) => {
    const formData = new FormData();
    formData.append("files", img);
    formData.append("refId", id);
    formData.append("ref", "attivita");
    formData.append("field", "immagine");
    settingsService
      .upload(formData)
      .then((response) => {
        eventsService.getActivityCategories().then((categoriesList) => {
          let selectedEvent;
          for (let i = 0; i < categoriesList.length; i++) {
            let temp = categoriesList[i].lista_attivita.find(
              (attivita) => attivita.id === id
            );
            if (temp !== undefined) {
              selectedEvent = temp;
            }
          }
          setCategoriesList(categoriesList);
          if (event) {
            setEvent(selectedEvent);
          }
          let tempList = [];
          for (let i = 0; i < categoriesList.length; i++) {
            let temp = {};
            temp.id = categoriesList[i].id;
            temp.name = categoriesList[i].nome;
            tempList.push(temp);
          }
          setNeatCategoriesList(tempList);
          setLoader(false);
          if (isCreation) {
            toggleAddSchema();
            setEndOperation(true);
          }
        });
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          props.history.push("/");
        }
      });
  };

  useEffect(() => {
    getActivities();

    activitiesService.getSpaces(0, -1, "").then((allSpaces) => {
      setSpaces(allSpaces);
    });

    settingsService.getSettings().then((settings) => {
      setBlockingSpace(settings.impostazioni_attivita.spazio_bloccante);
    });
  }, [getActivities]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    mainView: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      overflowX: "scroll",
    },
    button: {
      backgroundColor: colors.primary,
      width: 80,
      height: 80,
      borderRadius: 50,
      alignItems: "center",
      outline: "none",
      border: "none",
      justifyContent: "center",
      cursor: "pointer",
    },
    buttonBox: {
      outline: "none",
      border: "none",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      zIndex: 200,
      right: 40,
      bottom: 40,
    },
    hoverLabel: {
      textAlign: "center",
      fontSize: 12,
      maxWidth: 80,
    },
  };

  return (
    <div style={styles.container}>
      {loader ? (
        <ReactLoading
          type={"spinningBubbles"}
          color={colors.primary}
          height={50}
          width={50}
        />
      ) : (
        <div style={styles.mainView}>
          {categoriesList.length > 0 &&
            categoriesList.map((cat, key) => {
              return (
                <ActivityCategory
                  key={key}
                  onDelete={() => toggleDeleteCategory(cat)}
                  onModify={() => toggleModifyCategory(cat)}
                  onAddSchema={() => toggleAddSchema(cat)}
                  category={cat}
                  eventsList={cat.lista_attivita}
                  toggleInfo={toggleInfo}
                  colors={colors}
                />
              );
            })}
        </div>
      )}
      {showAddCategory && (
        <AddCategory
          closeModal={toggleAddCategory}
          saveData={onAddCategory}
          colors={colors}
        />
      )}
      {showModifyCategory && (
        <ModifyCategory
          name={categorySelected.nome}
          closeModal={toggleModifyCategory}
          saveData={onModifyCategory}
          colors={colors}
        />
      )}
      {showDeleteCategory && (
        <RemoveEventCategory
          name={categorySelected.nome}
          closeModal={toggleDeleteCategory}
          saveData={onDeleteCategory}
          colors={colors}
        />
      )}
      {showDelete && (
        <RemoveSchemaEvent
          name={eventSelected.nome}
          closeModal={toggleDeleteSchema}
          saveData={onDeleteSchema}
          colors={colors}
        />
      )}
      {endOperation && (
        <EndOperation
          flag={operationResult}
          operation={operation}
          closeModal={toggleEndOperation}
          colors={colors}
        />
      )}
      {showGenerate && (
        <GenerateInvitation
          capacity={eventSelected.posti_totali}
          closeModal={toggleShowGenerate}
          colors={colors}
          saveData={onGenerateInvitations}
        />
      )}
      {showReservation && (
        <ShowReservation
          closeModal={toggleShowReservation}
          activity={eventSelected}
          colors={colors}
        />
      )}

      <div
        style={styles.buttonBox}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {hover && (
          <p style={styles.hoverLabel}>Crea nuova categoria attività</p>
        )}
        <button style={styles.button} onClick={() => toggleAddCategory()}>
          <img
            alt="Add icon"
            src={add}
            style={{ width: 35, objectFit: "contain" }}
          />
        </button>
      </div>
      {(event !== null || showAddSchema) && (
        <ShowActivity
          activity={event}
          categories={neatCategoriesList}
          categorySelected={categorySelected}
          onDeleteSchema={toggleDeleteSchema}
          onModifySchema={onModifySchema}
          onCreateSchema={onAddSchema}
          closeInfo={closeInfo}
          flagModify={event !== null ? false : showAddSchema ? true : false}
          onShowGenerate={toggleShowGenerate}
          onDownloadRes={downloadRes}
          uploadImg={uploadImg}
          colors={colors}
          onShowReservation={toggleShowReservation}
          spaces={spaces}
          blockingSpace={blockingSpace}
        />
      )}
    </div>
  );
};

export default ActivitiesManager;
