import React, { useState } from "react";
import download from "./../../../img/download_res.png";

const DownloadResButton = ({
  text,
  colors,
  width,
  backgroundColor,
  onClick,
}) => {
  const [open, setOpen] = useState(false);
  const [coordinates, setCoordinates] = useState(false);
  const styles = {
    buttonBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginRight: 2,
      cursor: "pointer",
    },
    hoverLabel: {
      textAlign: "center",
      fontSize: 12,
      width: width,
      position: "absolute",
      color: colors?.darkgray,
      zIndex: 100,
      backgroundColor: backgroundColor,
      borderRadius: 10,
      padding: 20,
      top: coordinates.y,
      left: coordinates.x,
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      cursor: "pointer",
    },
  };
  return (
    <div
      style={styles.buttonBox}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      onMouseMove={(e) => {
        setCoordinates({
          x: width === 200 ? e.pageX - window.innerWidth + 150 : e.pageX,
          y: e.pageY - 100,
        });
      }}
    >
      {open && <p style={styles.hoverLabel}>{text}</p>}
      <button
        style={{ ...styles.button, color: colors?.white }}
        onClick={() => {
          onClick();
        }}
      >
        <img
          alt="Download icon"
          src={download}
          style={{ height: 15, objectFit: "contain" }}
        />
      </button>
    </div>
  );
};

export default DownloadResButton;
