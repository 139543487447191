import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { OrgContext, UserContext } from "../../../contexts";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { reportService } from "../../../_services";
import ReactLoading from "react-loading";
import DataBoxUserBehavior from "../boxes/dataBoxUserBehaviour";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const ClickedObjectsChart = (props) => {
  const org = useContext(OrgContext)?.org;
  const colors = useContext(UserContext)?.colors;

  //The selected data category
  const [selection, setSelection] = useState("");
  const prevSelection = usePrevious(selection);

  const [loader, setLoader] = useState(false);

  //The render options of the chart. These can be changed as explained in chart.js doc
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  //The structure of the chart's data. Labels is the array of the identifiers of each dataset, while label identifies the meaning of the data shown
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        label: "",
      },
    ],
  });

  //Handle the change of data category selected
  const handleSelection = (selection) => {
    setSelection(selection);
  };

  const generatePastelColor = () => {
    let R = Math.floor(Math.random() * 127 + 127);
    let G = Math.floor(Math.random() * 127 + 127);
    let B = Math.floor(Math.random() * 127 + 127);

    let rgb = (R << 16) + (G << 8) + B;
    return `#${rgb.toString(16)}`;
  };

  const getGraphStats = useCallback(() => {
    setLoader(true);
    reportService
      .getObjects(
        org,
        props.temporalFilters.start,
        props.temporalFilters.end,
        selection,
        true
      )
      .then((data) => {
        var labels = data.labels;
        var dataLabels = data.interactions;
        var datasets = [];
        for (const label of dataLabels) {
          var dataList = [];
          var backgroundColor = generatePastelColor();

          for (const key in data.values) {
            var tmpValue = 0;
            for (const innerKey in data.values[key]) {
              if (innerKey.toString() === label.toString()) {
                tmpValue = data.values[key][innerKey];
              }
            }
            dataList.push(tmpValue);
          }

          var labelData = {
            label: label,
            data: dataList,
            backgroundColor: backgroundColor,
          };

          datasets.push(labelData);
        }

        setData({ labels, datasets });
        setLoader(false);
      });
  }, [org, props.temporalFilters.end, props.temporalFilters.start, selection]);

  useEffect(() => {
    if (
      selection !== "" &&
      selection !== undefined &&
      prevSelection !== selection
    ) {
      getGraphStats();
    }
  }, [selection, prevSelection, getGraphStats]);

  return (
    <DataBoxUserBehavior
      title={"Click"}
      // maintainAspectRatio: false gives the opportunity to handle the chart's dimension
      body={
        loader ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "320px",
              paddingBottom: "320px",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={colors?.primary}
              height={50}
              width={50}
            />
          </div>
        ) : (
          <Bar
            data={data}
            options={{ ...options, maintainAspectRatio: false }}
            height={"700%"}
          />
        )
      }
      isMean={false}
      handleSelection={(selection) => handleSelection(selection)}
      contentFilters={props.sections}
    />
  );
};

export default ClickedObjectsChart;
