import config from "../config";
import { handleResponse, authHeader } from "../_helpers";

export const reservationsService = {
  createEventReservation,
  createActivityReservation,
  createEventInvitations,
  createPrivateEventReservation,
  createVisitReservation,
  modifyReservation,
  modifyActivityReservation,
  deleteAccessReservation,
  deleteEventReservation,
  deleteEventWLReservation,
  deleteActivityReservation,
  deleteAccessReservationOnline,
  deleteEventReservationOnline,
  confirmpurchase,
  getReservations,
  getReservationInfo,
  getAvailableSlot,
  createOnlineReservation,
  getOnlineAvailableSlot,
  getOnlineAccessReservation,
  getOnlineEventReservation,
  getOnlineClosingDay,
  getOnlineEvents,
  getTicketsAvailable,
  createOnlineEventReservation,
  getResDoc,
  confirmActivityReservation,
  findEventReservationToDelete,
  findAccessReservationToDelete,
  sendEmailEvent,
  getActivitiesResPerSpace,
  shiftWaitingList,
};

function getReservations(date) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/prenotazioni_eventis/eventiFuturi/${date}`,
    requestOptions
  ).then(handleResponse);
}

function getActivitiesResPerSpace(date, space) {
  var formattedDate = new Date(date),
    y = formattedDate.getFullYear(),
    m = formattedDate.getMonth();
  var firstDay = new Date(y, m, 1).toISOString();
  var lastDay = new Date(y, m + 1, 1).toISOString();

  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/prenotazioni_attivita/?datetime_inizio_gte=${firstDay}&datetime_inizio_lt=${lastDay}&spazio=${space}`,
    requestOptions
  ).then(handleResponse);
}

function getTicketsAvailable(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/biglietti-eventis/tickets/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getReservationInfo(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/prenotazioni_attivita/struttura/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getResDoc(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/eventi_childs/prenotatiPDF/${id}`,
    requestOptions
  ).then(handleResponse);
}

function sendEmailEvent(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/prenotazioni_eventis/email/${id}`,
    requestOptions
  ).then(handleResponse);
}

function createEventReservation(
  evento,
  card,
  nome_partecipante,
  cognome_partecipante,
  numero_partecipanti,
  email,
  numero_telefono,
  bambini,
  CAP,
  nazione,
  sesso,
  nascita,
  flag_attesa,
  ticketType,
  prezzo
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      evento,
      card,
      nome_partecipante,
      cognome_partecipante,
      numero_partecipanti,
      email,
      numero_telefono,
      bambini,
      CAP,
      nazione,
      sesso,
      nascita,
      flag_attesa,
      ticketType,
      prezzo,
    }),
  };

  return (
    fetch(`${config.api.url}/prenotazioni_eventis`, requestOptions)
      //return fetch(`${config.api.url}/shop`, requestOptions)
      .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // TODO handle error
        return false;
      })
  );
}

function createActivityReservation(body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(body),
  };

  return fetch(`${config.api.url}/prenotazioni_attivita`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function modifyActivityReservation(id, body) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(body),
  };

  return fetch(`${config.api.url}/prenotazioni_attivita/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function createEventInvitations(evento, num, num_ingressi) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      num,
      evento,
      num_ingressi,
    }),
  };

  return fetch(`${config.api.url}/autorizzazionis`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function createPrivateEventReservation(
  token,
  evento,
  id,
  card,
  nome_partecipante,
  cognome_partecipante,
  numero_partecipanti,
  email,
  numero_telefono,
  CAP,
  nazione,
  sesso,
  nascita,
  flag_attesa
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      token,
      evento,
      id,
      card,
      nome_partecipante,
      cognome_partecipante,
      numero_partecipanti,
      email,
      numero_telefono,
      CAP,
      nazione,
      sesso,
      nascita,
      flag_attesa,
    }),
  };

  return fetch(`${config.api.url}/prenotazioni_eventis/online`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function getAvailableSlot(date) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/slot_orarie/lista/${date}`,
    requestOptions
  ).then(handleResponse);
}

function getOnlineAvailableSlot(id, date) {
  const requestOptions = { method: "GET" };
  return fetch(
    `${config.api.url}/slot_orarie/listaOnlineTemp/${id}/${date}`,
    requestOptions
  ).then(handleResponse);
}

function getOnlineEvents(id, date) {
  const requestOptions = { method: "GET" };
  return fetch(
    `${config.api.url}/eventi_childs/online/${id}/${date}`,
    requestOptions
  ).then(handleResponse);
}

function getOnlineClosingDay(id, date) {
  const requestOptions = { method: "GET" };
  return fetch(
    `${config.api.url}/museis/onlineGiorniChiusura/${id}/${date}`,
    requestOptions
  ).then(handleResponse);
}

function getOnlineAccessReservation(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/prenotazioni_ingressi/online/${id}`,
    requestOptions
  ).then(handleResponse);
}

function getOnlineEventReservation(id) {
  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(
    `${config.api.url}/prenotazioni_eventis/online/${id}`,
    requestOptions
  ).then(handleResponse);
}

function createVisitReservation(
  nome_partecipante,
  cognome_partecipante,
  numero_partecipanti,
  email,
  numero_telefono,
  ora_inizio,
  giorno_prenotazione
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      nome_partecipante,
      cognome_partecipante,
      numero_partecipanti,
      email,
      numero_telefono,
      ora_inizio,
      giorno_prenotazione,
    }),
  };

  return fetch(`${config.api.url}/prenotazioni_ingressi`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function createOnlineReservation(
  id,
  nome_partecipante,
  cognome_partecipante,
  numero_partecipanti,
  email,
  numero_telefono,
  ora_inizio
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id,
      nome_partecipante,
      cognome_partecipante,
      numero_partecipanti,
      email,
      numero_telefono,
      ora_inizio,
    }),
  };

  return fetch(`${config.api.url}/prenotazioni_ingressi/online`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function createOnlineEventReservation(
  evento,
  id,
  nome_partecipante,
  cognome_partecipante,
  numero_partecipanti,
  email,
  numero_telefono
) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      evento,
      id,
      nome_partecipante,
      cognome_partecipante,
      numero_partecipanti,
      email,
      numero_telefono,
    }),
  };

  return fetch(`${config.api.url}/prenotazioni_eventis/online`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function modifyReservation(id, numero_partecipanti) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      numero_partecipanti,
    }),
  };

  return fetch(`${config.api.url}/prenotazioni_eventis/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteEventReservation(reservations) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      reservations,
    }),
  };
  return fetch(`${config.api.url}/prenotazioni_eventis/delete`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteEventWLReservation(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/lista_attesa/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function findEventReservationToDelete(codice) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      codice,
    }),
  };

  return fetch(
    `${config.api.url}/prenotazioni_eventis/cancellazione`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function findAccessReservationToDelete(codice) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      codice,
    }),
  };

  return fetch(
    `${config.api.url}/prenotazioni_ingressi/cancellazione`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function confirmpurchase(
  idReservation,
  paymentmethod,
  nome_articolo,
  id_articolo
) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
    },
    body: JSON.stringify({
      idReservation,
      paymentmethod,
      nome_articolo,
      id_articolo,
    }),
  };

  return fetch(`${config.api.url}/shop/confirmpurchase`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function confirmActivityReservation(id, flag_confermato) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({ flag_confermato }),
  };

  return fetch(
    `${config.api.url}/prenotazioni_attivita/conferma/${id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteAccessReservation(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/prenotazioni_ingressi/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteActivityReservation(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/prenotazioni_attivita/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteAccessReservationOnline(id, otp) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(
    `${config.api.url}/prenotazioni_ingressi/online/${id}/${otp}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteEventReservationOnline(id, otp, reservations) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      reservations,
    }),
  };
  return fetch(
    `${config.api.url}/prenotazioni_eventis/online/${id}/${otp}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function shiftWaitingList(evento, num_posti) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      evento,
      num_posti,
    }),
  };
  return fetch(
    `${config.api.url}/prenotazioni_eventis/scalaAttesa`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}
