import React, { useContext } from "react";
import SubButton from "./buttons/subButton";
import { UserContext } from "../contexts";

const SubBar = ({ buttons, selection, setSelection }) => {
  const colors = useContext(UserContext)?.colors;

  const styles = {
    sideBar: {
      width: "12%",
      backgroundColor: colors?.darkgray,
      boxShadow: "2px 0 4px 0 rgb(0 0 0 / 20%)",
      height: "100%",
    },
  };
  return (
    <div style={styles.sideBar}>
      {buttons.map((button, key) => {
        return (
          <SubButton
            key={key}
            icon={button.icon}
            mod={{ id: key, name: button.name }}
            colors={colors}
            onClick={() => setSelection(key)}
            selection={selection}
            size={18}
            selectionColor={colors?.gray}
            hoverColor={colors?.mediumgray}
          />
        );
      })}
    </div>
  );
};

export default SubBar;
