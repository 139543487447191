import React, { useEffect, useState, useRef } from "react";
import logo from "./../img/logo_white.png";
import { cardsService, settingsService } from "../_services";
import colors from "../museumColors";
import back from "./../img/arrow_back.png";
import FirstStep from "../modules/cardShop/firstStep";
import SecondStep from "../modules/cardShop/secondStep";
import ThirdStep from "../modules/cardShop/thirdStep";
import Loading from "../modules/cardShop/loading";
import SuccessStep from "../modules/cardShop/successStep";
import ErrorStep from "../modules/cardShop/errorStep";
import moment from "moment";
const allowRenewalDays = 60;

function findSection(param) {
  switch (param) {
    case "acquista":
      return 0;
    case "regala":
      return 1;
    case "rinnova":
      return 2;
    default:
      return 0;
  }
}

const CardShop = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [info, setInfo] = useState(undefined);
  const [cards, setCards] = useState([]);
  const [chosenCard, setChosenCard] = useState(null);
  const [chosenTarget, setChosenTarget] = useState(null);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [zip, setZip] = useState("");
  const [taxCode, setTaxCode] = useState("");
  const [taxCodeError, setTaxCodeError] = useState(false);
  const [hasVAT, setHasVAT] = useState(false);
  const [sdiCode, setSdiCode] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [profilationCheckBox, setProfilationCheckBox] = useState(false);
  const [marketingCheckBox, setMarketingCheckBox] = useState(false);
  const [voucher, setVoucher] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [cardToRenew, setCardToRenew] = useState(undefined);
  const [researchStatus, setResearchStatus] = useState("waiting");
  const [researchFeedback, setResearchFeedback] = useState(false);
  const [cardType, setCardType] = useState({});
  const [receiverName, setReceiverName] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [message, setMessage] = useState("");
  const [section, setSection] = useState(
    findSection(props.match.params.section)
  );
  const prevStep = usePrevious({ step });

  const giftForm = {
    name: receiverName,
    setName: setReceiverName,
    email: receiverEmail,
    setEmail: setReceiverEmail,
    message: message,
    setMessage: setMessage,
  };
  const [isEmailIncorrect, setIsEmailIncorrect] = useState(false);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    const getInfo = () => {
      settingsService
        .getSettingsByOrgName(props.match.params.organisation)
        .then((info) => {
          setInfo(info);
          if (info !== undefined) {
            const script = document.createElement("script");

            script.src =
              "https://www.paypal.com/sdk/js?client-id=" +
              info.paypalId +
              "&currency=EUR";
            script.async = true;

            document.body.appendChild(script);
          }
        });
    };

    getInfo();
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
      const newHeight = window.innerHeight;
      setHeight(newHeight);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, [props]);

  useEffect(() => {
    const getCards = () => {
      cardsService.getCardTypesPerOrg(info?.id_org).then((cards) => {
        setCards(cards);
        setLoading(false);
        setChosenCard(cards[0]);
        setChosenTarget(cards[0]?.target_tesseres[0]);
      });
    };

    if (info !== undefined) {
      getCards();
    }
  }, [info]);

  useEffect(() => {
    const reset = () => {
      setName("");
      setSurname("");
      setEmail("");
      setAddress("");
      setCity("");
      setCounty("");
      setZip("");
      setTaxCode("");
      setSdiCode("");
      setHasVAT(false);
      setProfilationCheckBox(false);
      setMarketingCheckBox(false);
      setStep(1);
      setVoucher(null);
      setErrorMessage("");
      setResearchStatus("waiting");
      setResearchFeedback(false);
      setCardToRenew(undefined);
    };

    if (step === 1 && prevStep?.step !== step && prevStep !== undefined) {
      reset();
    }
  }, [step, prevStep]);

  const getCards = () => {
    cardsService.getCardTypesPerOrg(info?.id_org).then((cards) => {
      setCards(cards);
      setLoading(false);
      setChosenCard(cards[0]);
      setChosenTarget(cards[0]?.target_tesseres[0]);
    });
  };

  const findCard = (cardNumber, surname) => {
    cardsService
      .findCard(info?.id_org, cardNumber, surname)
      .then((response) => {
        if (
          response.length > 0 &&
          response[0].cognome.toLowerCase() === surname.toLowerCase()
        ) {
          setCardToRenew(response[0]);
          setResearchFeedback(true);
          setResearchStatus("done");
        } else {
          setResearchFeedback(false);
          setResearchStatus("done");
        }
      });
  };
  const manageOrderResponse = (response) => {
    let indirizzo = {};
    indirizzo.via = address;
    indirizzo.citta = city;
    indirizzo.provincia = county;
    indirizzo.cap = zip;
    if (response.status === true) {
      if (section === 2) {
        let billingForm = {
          nome: name,
          cognome: surname,
          email: email,
          indirizzo: indirizzo,
          codice_fiscale: taxCode,
          sdi: sdiCode,
          metodo_pagamento: "Paypal",
          vip: cardToRenew.vip,
          pagamento: response.data,
        };
        cardsService
          .renewCardsOnline(
            info.id_org,
            cardToRenew.target_tessere.id,
            JSON.stringify(cardToRenew),
            JSON.stringify(billingForm)
          )
          .then((renewal) => {
            if (renewal.status === true) {
              setStep(200);
            } else {
              setStep(500);
              setErrorMessage(renewal.message);
            }
          });
      } else {
        if (section === 1) {
          giftForm.senderName = name;
          giftForm.senderSurname = surname;
        }
        let tempEmail = giftForm.email !== "" ? giftForm.email : email;
        cardsService
          .createCardSale(
            info.id_org,
            chosenTarget.id,
            tempEmail,
            name,
            surname,
            //birth,
            indirizzo,
            hasVAT,
            hasVAT ? "" : taxCode,
            hasVAT ? taxCode : "",
            sdiCode,
            response.data,
            "online",
            parseFloat(chosenTarget.prezzo),
            profilationCheckBox,
            marketingCheckBox,
            "Paypal",
            false,
            section === 1,
            giftForm
          )
          .then((sale) => {
            if (sale.status === true) {
              setStep(200);
              setVoucher(sale.voucher);
            } else {
              setStep(500);
              setErrorMessage(sale.message);
              cardsService.createSaleError(
                info.id_org,
                chosenTarget.id,
                email,
                name,
                surname,
                //birth,
                indirizzo,
                hasVAT,
                hasVAT ? "" : taxCode,
                hasVAT ? taxCode : "",
                sdiCode,
                response.data,
                sale.message
              );
            }
          });
      }
    } else {
      setStep(500);
      setErrorMessage(response.data);
      cardsService.createSaleError(
        info.id_org,
        chosenTarget.id,
        email,
        name,
        surname,
        //birth,
        indirizzo,
        hasVAT,
        hasVAT ? "" : taxCode,
        hasVAT ? taxCode : "",
        sdiCode,
        response.data
      );
    }
  };

  const isExpiring = (card) => {
    let today = moment();
    let expiry = moment(card?.scadenza);
    return expiry.diff(today, "days") <= allowRenewalDays;
  };

  const reset = () => {
    getCards();
    setName("");
    setSurname("");
    setEmail("");
    setAddress("");
    setCity("");
    setCounty("");
    setZip("");
    setTaxCode("");
    setSdiCode("");
    setHasVAT(false);
    setProfilationCheckBox(false);
    setMarketingCheckBox(false);
    setStep(1);
    setVoucher(null);
    setErrorMessage("");
    setResearchStatus("waiting");
    setResearchFeedback(false);
    setCardToRenew(undefined);
    setReceiverName("");
    setMessage("");
  };

  const renderStep = (step) => {
    switch (step) {
      case 1:
        return (
          <FirstStep
            cards={cards}
            chosenCard={chosenCard}
            setChosenCard={(param) => setChosenCard(param)}
            chosenTarget={chosenTarget}
            setChosenTarget={(param) => setChosenTarget(param)}
            colors={colors}
            width={width}
            height={height}
            section={section}
            setSection={(status) => setSection(status)}
            findCard={findCard}
            cardToRenew={cardToRenew}
            researchFeedback={researchFeedback}
            researchStatus={researchStatus}
            cardType={cardType}
            setCardType={(obj) => setCardType(obj)}
            giftForm={giftForm}
            setIsEmailIncorrect={setIsEmailIncorrect}
          />
        );
      case 2:
        return (
          <SecondStep
            chosenCard={chosenCard}
            chosenTarget={chosenTarget}
            colors={colors}
            width={width}
            name={name}
            setName={(param) => setName(param)}
            surname={surname}
            setSurname={(param) => setSurname(param)}
            email={email}
            setEmail={(param) => setEmail(param)}
            address={address}
            setAddress={(param) => setAddress(param)}
            city={city}
            setCity={(param) => setCity(param)}
            county={county}
            setCounty={(param) => setCounty(param)}
            zip={zip}
            setZip={(param) => setZip(param)}
            taxCode={taxCode}
            setTaxCode={(param) => setTaxCode(param)}
            sdiCode={sdiCode}
            setSdiCode={(param) => setSdiCode(param)}
            hasVAT={hasVAT}
            setHasVAT={(param) => {
              setHasVAT(param);
              setTaxCode("");
            }}
            profilationCheckBox={profilationCheckBox}
            setProfilationCheckBox={(param) => setProfilationCheckBox(param)}
            marketingCheckBox={marketingCheckBox}
            setMarketingCheckBox={(param) => setMarketingCheckBox(param)}
            setEmailError={(param) => setEmailError(param)}
            setTaxCodeError={(param) => setTaxCodeError(param)}
            privacyPolicy={info.privacyPolicy}
            cardToRenew={cardToRenew}
            section={section}
          />
        );
      case 3:
        return (
          <ThirdStep
            width={width}
            chosenCard={chosenCard}
            chosenTarget={chosenTarget}
            manageOrderResponse={manageOrderResponse}
            cardToRenew={cardToRenew}
            renewal={section === 2}
            cardType={cardType}
          />
        );
      case 4:
        return <Loading />;
      case 200:
        return (
          <SuccessStep
            width={width}
            voucher={voucher}
            name={chosenCard?.nome + " - " + chosenTarget?.nome}
            restart={reset}
            renewal={section === 2}
          />
        );
      case 500:
        return (
          <ErrorStep
            name={chosenCard?.nome + " - " + chosenTarget?.nome}
            errorMessage={errorMessage}
            restart={reset}
          />
        );
      default:
        break;
    }
  };

  const renderButton = (step) => {
    switch (step) {
      case 1:
        if (section === 0) {
          return "ACQUISTA";
        } else if (section === 1) {
          return "ACQUISTA";
        } else {
          return "RINNOVA";
        }
      case 2:
        return "VAI AL PAGAMENTO";
      default:
        break;
    }
  };

  const isEmpty = (field) => {
    return field === "" || field === null || field === undefined;
  };

  const disableButton = (step) => {
    switch (step) {
      case 1:
        if (section !== 1) {
          return !chosenCard || !chosenTarget;
        } else {
          return (
            !chosenCard ||
            !chosenTarget ||
            receiverName === "" ||
            isEmailIncorrect === true ||
            message === ""
          );
        }

      case 2:
        return (
          isEmpty(name) ||
          isEmpty(surname) ||
          isEmpty(email) ||
          emailError === true ||
          //isEmpty(birth) ||
          isEmpty(address) ||
          isEmpty(city) ||
          isEmpty(county) ||
          isEmpty(zip) ||
          isEmpty(taxCode) ||
          taxCodeError === true ||
          (section !== 2 &&
            (profilationCheckBox === false || marketingCheckBox === false))
        );
      default:
        break;
    }
  };

  const nextStep = () => {
    // Decomment the following part to skip the payment step of the card purchase (only for errors fixing purpose)

    // if (step === 2) {
    //   let response = {};
    //   response.status = true;
    //   response.data = { pagamento: "Copia qui" };
    //   manageOrderResponse(response);
    // } else {
    setStep(step + 1);
    // }
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      alignItems: "center",
    },
    loaderBox: {
      flex: 1,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      width: "100%",
      height: 60,
      backgroundColor: "black",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    footer: {
      display: "flex",
      width: "100%",
      minHeight: step < 4 ? 70 : 40,
      backgroundColor: "black",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      bottom: 0,
    },
    button: {
      outline: "none",
      backgroundColor: "white",
      border: "none",
      borderRadius: 50,
      width: 200,
      padding: 10,
      fontWeight: "800",
      cursor: "pointer",
    },
    arrowBack: {
      position: "absolute",
      left: width > 1000 ? 30 : 15,
      height: 50,
      objectFit: "contain",
      outline: "none",
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
    },
    body: {
      width: "100%",
      height: "calc(100% - 140px)", //80px footer + 60px header
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        {info?.logo !== null && info?.logo !== undefined ? (
          <img
            alt={"Logo museo"}
            src={info?.logo?.url}
            style={{ height: "80%", objectFit: "contain", marginLeft: 50 }}
          />
        ) : (
          <div style={{ width: "100%" }} />
        )}
        <img
          alt={"Logo EMMA"}
          src={logo}
          style={{ height: "50%", objectFit: "contain", marginRight: 50 }}
        />
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div style={styles.body}>{renderStep(step)}</div>
      )}
      <div style={styles.footer}>
        {step > 1 && step < 4 && (
          <button style={styles.arrowBack} onClick={() => setStep(step - 1)}>
            <img
              alt={"Go back"}
              src={back}
              style={{
                height: "50%",
                objectFit: "contain",
                marginRight: 50,
              }}
            ></img>
          </button>
        )}
        {step < 3 &&
          (section === 0 || isExpiring(cardToRenew) ? (
            <button
              style={{
                ...styles.button,
                display: section === 2 && researchFeedback === false && "none",
              }}
              onClick={nextStep}
              disabled={disableButton(step)}
            >
              {renderButton(step)}
            </button>
          ) : (
            <p
              style={{
                color: "white",
                fontSize: 13,
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              È possibile effettuare il rinnovo a partire dal {allowRenewalDays}
              ° giorno prima della scadenza della card.
            </p>
          ))}
      </div>
    </div>
  );
};

export default CardShop;
