import React, { useState, useEffect, useContext } from "react";
import pencil from "./../img/modify_gray.png";
import remove from "./../img/minus_white.png";
import add from "./../img/add.png";
import { UserContext } from "../contexts";
import { settingsService } from "../_services";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../style.css";

const ClosingDays = ({ entity, amIAdmin }) => {
  const colors = useContext(UserContext)?.colors;

  const [daysString, setDaysString] = useState(entity?.chiusure_straordinarie);
  const [closingDaysList, setClosingDaysList] = useState([]);
  const [tempList, setTempList] = useState([]);
  const [newDate, setNewDate] = useState(new Date());
  const [edit, setEdit] = useState(false);
  const [showOld, setShowOld] = useState(false);

  const [calendarDate, setCalendarDate] = useState(new Date());

  const isEmptyArray = (array) => {
    return array === null || array === undefined || array.length === 0;
  };

  const updateClosingDays = (days) => {
    settingsService.updateClosingDays(days.toString());
  };

  const removeClosingDayFromList = (toBeRemoved) => {
    let tempA = [...closingDaysList];
    let index = tempA.indexOf(toBeRemoved);
    if (index > -1) {
      // only splice array when item is found
      tempA.splice(index, 1); // 2nd parameter means remove one item only
    }
    setTempList(tempA);
  };

  const addClosingDayToList = (toBeAdded) => {
    let tempA = [...closingDaysList];
    toBeAdded = moment(toBeAdded).format("yyyy-MM-DD");
    let index = tempA.indexOf(toBeAdded);
    if (index === -1) {
      tempA.push(toBeAdded);
      tempA.sort(function (a, b) {
        return a.localeCompare(b);
      });
      setTempList(tempA);
    }
  };

  const translateDate = (oldDate) => {
    let temp = oldDate.split("-");
    let day = temp[2];
    if (day[0] === "0") day = day[1];
    let year = temp[0];
    let month;
    switch (temp[1]) {
      case "01":
        month = "Gennaio";
        break;
      case "02":
        month = "Febbraio";
        break;
      case "03":
        month = "Marzo";
        break;
      case "04":
        month = "Aprile";
        break;
      case "05":
        month = "Maggio";
        break;
      case "06":
        month = "Giugno";
        break;
      case "07":
        month = "Luglio";
        break;
      case "08":
        month = "Agosto";
        break;
      case "09":
        month = "Settembre";
        break;
      case "10":
        month = "Ottobre";
        break;
      case "11":
        month = "Novembre";
        break;
      case "12":
        month = "Dicembre";
        break;
      default:
        month = "";
        break;
    }
    return day + " " + month + " " + year;
  };

  const CustomInput = ({ value, onClick }) => (
    <button className="custom-input-res" onClick={onClick}>
      {value}
    </button>
  );

  useEffect(() => {
    setClosingDaysList(tempList);
  }, [tempList]);

  useEffect(() => {
    setDaysString(entity?.chiusure_straordinarie);
  }, [entity]);

  useEffect(() => {
    if (daysString !== undefined && daysString !== null) {
      setClosingDaysList(daysString.split(","));
    } else {
      setClosingDaysList([]);
    }
  }, [daysString]);

  const styles = {
    closingDaysSection: {
      marginBottom: 30,
      marginLeft: 10,
      width: "30%",
    },
    header: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    label: {
      fontSize: 14,
      textAlign: "center",
      width: "100%",
      justifyContent: "center",
    },
    addDayLabel: {
      fontSize: 12,
      color: colors?.darkgray,
      textAlign: "center",
      justifyContent: "center",
      width: "90%",
      marginTop: 5,
      marginBottom: 5,
      paddingTop: 15,
      borderTop: "1px solid lightgray",
    },
    labelNoDay: {
      fontSize: 14,
      textAlign: "center",
      width: "100%",
      justifyContent: "center",
    },
    labelTable: {
      marginBottom: 15,
      fontSize: 14,
      color: colors?.lightgray,
      textAlign: "center",
      display: "flex",
      flexDirection: edit ? "column" : "row",
      alignItems: "center",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
      paddingTop: 5,
    },
    addRemoveButton: {
      border: 0,
      outline: "none",
      borderRadius: 20,
      backgroundColor: colors.tertiary,
      height: 20,
      width: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: colors?.lightgray,
      cursor: "pointer",
      borderRadius: 15,
      padding: "11px",
      marginTop: "20px",
    },
  };

  return (
    <div style={styles.closingDaysSection}>
      <div style={styles.header}>
        <div style={styles.labelTable}>
          Giorni di chiusura straordinaria
          {amIAdmin === true && !edit && (
            <img
              alt="Modify"
              src={pencil}
              style={{
                width: 15,
                objectFit: "contain",
                marginLeft: 15,
                cursor: "pointer",
              }}
              onClick={() => {
                setEdit(!edit);
              }}
            />
          )}
          {amIAdmin === true && edit && (
            <button
              style={styles.button}
              onClick={() => {
                updateClosingDays(closingDaysList);
                setEdit(!edit);
                setShowOld(false);
              }}
            >
              Salva
            </button>
          )}
        </div>
      </div>
      {isEmptyArray(closingDaysList) ? (
        <p style={styles.labelNoDay}>
          Nessun giorno <br /> di chiusura straordinaria <br /> indicato per i
          prossimi mesi
        </p>
      ) : (
        !edit && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "80%",
              margin: "auto",
              marginTop: 10,
            }}
          >
            <Calendar
              onChange={setCalendarDate}
              value={calendarDate}
              tileClassName={({ date }) => {
                var marks = new Set(closingDaysList);
                if (marks.has(moment(date).format("YYYY-MM-DD"))) {
                  return "highlightedDay";
                }
              }}
              minDate={new Date()}
              minDetail="month"
            />
          </div>
        )
      )}
      {edit && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflowY: "scroll",
            marginTop: "5px",
            marginBottom: 20,
          }}
        >
          <label style={styles.addDayLabel}>
            Aggiungi un giorno di chiusura
          </label>
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <DatePicker
              selected={newDate}
              onChange={(date) => {
                setNewDate(date);
              }}
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
              locale="it"
              customInput={<CustomInput />}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{ ...styles.addRemoveButton, marginBottom: 20 }}
                onClick={() => addClosingDayToList(newDate)}
              >
                <img
                  alt={"Add closing day button"}
                  src={add}
                  style={{ width: 10, height: 10, objectFit: "contain" }}
                ></img>
              </button>
            </div>
          </div>
        </div>
      )}
      {edit && (
        <div style={styles.label}>
          {closingDaysList
            .slice(0)
            .reverse()
            .map((closingD, key) => {
              let today = new Date();
              today.setHours(0);
              today.setMinutes(0);
              today.setSeconds(0);

              let date = new Date(closingD);
              date.setHours(0);
              date.setMinutes(0);
              date.setSeconds(0);
              if (date >= today) {
                return (
                  <div style={styles.row} key={key}>
                    <p key={key}>{translateDate(closingD)}</p>
                    <></>
                    <button
                      style={styles.addRemoveButton}
                      onClick={() => removeClosingDayFromList(closingD)}
                    >
                      <img
                        alt={"Remove closing day button"}
                        src={remove}
                        style={{ width: 10, height: 10, objectFit: "contain" }}
                      ></img>
                    </button>
                  </div>
                );
              } else {
                return <></>;
              }
            })}
        </div>
      )}
      {edit && (
        <button
          style={styles.button}
          onClick={() => {
            setShowOld(!showOld);
          }}
        >
          {showOld ? "Nascondi chiusure passate" : "Mostra chiusure passate"}
        </button>
      )}
      {edit && showOld && (
        <div style={styles.label}>
          {closingDaysList
            .slice(0)
            .reverse()
            .map((closingD, key) => {
              let today = new Date();
              today.setHours(0);
              today.setMinutes(0);
              today.setSeconds(0);

              let date = new Date(closingD);
              date.setHours(0);
              date.setMinutes(0);
              date.setSeconds(0);
              if (date < today) {
                return (
                  <div style={styles.row} key={key}>
                    <p key={key}>{translateDate(closingD)}</p>
                  </div>
                );
              } else {
                return <></>;
              }
            })}
        </div>
      )}
    </div>
  );
};

export default ClosingDays;
