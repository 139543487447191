import React, { useEffect, useState } from "react";
import trash from "./../img/delete_gray.png";
import Switch from "react-switch";
import RemoveDiscount from "./popup/removeDiscount";
import { settingsService } from "../_services";

const Discount = ({ discount, colors, categoriesList, deleteDiscount }) => {
  const [enabled, setEnabled] = useState(discount.flag_abilitato);
  const [color, setColor] = useState("transparent");
  const [removeDiscount, setRemoveDiscount] = useState(false);

  //Toggle enabled
  useEffect(() => {
    settingsService.toggleDiscountAvailability(discount.id, enabled);
  }, [enabled, discount]);

  const styles = {
    container: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      opacity: !enabled ? 0.4 : 1,
      marginBottom: 8,
      marginTop: 8,
      backgroundColor: color,
    },
    field: {
      fontSize: 15,
      textAlign: "left",
      flex: 1,
    },
    button: {
      border: "none",
      backgroundColor: "transparent",
      outline: "none",
      display: "flex",
      justifyContent: "center",
    },
    cat: {
      marginBottom: 2,
      marginTop: 2,
      fontSize: 12,
    },
  };
  return (
    <div
      style={styles.container}
      onMouseEnter={() => setColor(colors.verylightgray)}
      onMouseLeave={() => setColor("transparent")}
    >
      <p style={styles.field}>{discount.codice}</p>
      <div
        style={{ ...styles.field, display: "flex", flexDirection: "column" }}
      >
        {discount.categorie.map((cat, key) => {
          return (
            <p key={key} style={styles.cat}>
              {categoriesList.find((c) => cat === c.id)?.nome_categoria}
            </p>
          );
        })}
      </div>
      <p style={styles.field}>{discount.sconto}%</p>
      <div style={styles.field}>
        <Switch
          onChange={() => setEnabled(!enabled)}
          checked={enabled}
          checkedIcon={false}
          uncheckedIcon={false}
          onColor={colors.darkgray}
          width={30}
          height={20}
        />
      </div>
      {enabled && (
        <div style={{ ...styles.button, flex: 1, justifyContent: "left" }}>
          <button
            style={{
              cursor: "pointer",
              border: "none",
              backgroundColor: "transparent",
              outline: "none",
            }}
            onClick={() => setRemoveDiscount(true)}
          >
            <img
              alt="Delete icon"
              src={trash}
              style={{ width: 15, objectFit: "contain" }}
            />
          </button>
        </div>
      )}
      {removeDiscount && (
        <RemoveDiscount
          closeModal={() => setRemoveDiscount(false)}
          saveData={() => deleteDiscount(discount.id)}
          colors={colors}
        />
      )}
    </div>
  );
};

export default Discount;
