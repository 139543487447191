import React, { useContext } from "react";
import download from "./../../img/download.png";
import { UserContext } from "../../contexts";

const GenericDownloadButton = ({ onClick }) => {
  const colors = useContext(UserContext)?.colors;

  const styles = {
    button: {
      outline: "none",
      border: "none",
      display: "flex",
      backgroundColor: colors.primary,
      width: 80,
      height: 80,
      borderRadius: 50,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    buttonBox: {
      display: "flex",
      position: "absolute",
      flexDirection: "column",
      zIndex: 200,
      right: 0,
      bottom: 40,
      alignItems: "center",
      justifyContent: "center",
      width: 160,
    },
    option: {
      outline: "none",
      border: "none",
      display: "flex",
      zIndex: 200,
      backgroundColor: colors.primary,
      width: 140,
      borderRadius: 30,
      alignItems: "center",
      justifyContent: "center",
      color: colors.white,
      paddingLeft: 15,
      paddingRight: 15,
      cursor: "pointer",
      marginBottom: 10,
    },
  };
  return (
    <div style={styles.buttonBox}>
      <button style={styles.button}>
        <img
          alt="Download icon"
          src={download}
          style={{ height: 35, objectFit: "contain" }}
          onClick={onClick}
        />
      </button>
    </div>
  );
};

export default GenericDownloadButton;
