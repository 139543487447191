import React, { useState, useContext } from "react";
import { UserContext, OrgContext } from "../../../contexts";
import { LanguageContext } from "../../../containers/language";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import arrow from "../../../img/arrow_back_dark.png";
import modify from "../../../img/modify_black.png";
import close from "../../../img/closeButton_dark.png";
import deleteIcon from "../../../img/delete_darkgray.png";
import { customersService } from "../../../_services/customers.service";
import ReactLoading from "react-loading";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import EndOperation from "../../../components/popup/endOperation";
import DeleteCustomer from "../../../components/popup/deleteCustomer";
import { reportService } from "../../../_services";
import DatePicker, { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
import nations from "../../../assets/states_it.json";
registerLocale("it", it);

function validateEmail(email, setShowError, setIsEmail) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let error = re.test(String(email).toLowerCase());
  if (email === "") {
    setShowError(false);
  } else {
    setShowError(true);
  }
  setIsEmail(error);
  return error;
}

function filterNullValue(value) {
  if (value === null || value === "null") {
    return "";
  } else {
    return value;
  }
}

function getList(type) {
  switch (type) {
    case "countries":
      return nations;
    default:
      return [];
  }
}

function createFields(profiling) {
  let fields = [];

  for (let i = 0; i < profiling?.length; i++) {
    let tmpField = {
      name: profiling[i].name,
      type: profiling[i].type,
      value: profiling[i].default,
      options: profiling[i].options,
      mandatory: profiling[i].mandatory,
    };

    fields.push(tmpField);
  }

  return fields;
}

function getInput(
  type,
  field,
  disabled,
  onChange,
  style,
  options,
  retrieveCitiesFromZip,
  city,
  setCity,
  setShowError,
  setIsEmail,
  showError,
  isEmail
) {
  let name = field.name;
  let value = field.value;
  switch (type) {
    case 1: //Testo
      return (
        <input
          type="text"
          name={name}
          placeholder={name}
          value={filterNullValue(value)}
          disabled={disabled}
          onChange={(event) => {
            onChange(field, event.target.value);
          }}
          style={style}
        />
      );
    case 2: //Telefono
      return (
        <input
          type="text"
          name={name}
          placeholder={name}
          value={filterNullValue(value)}
          disabled={disabled}
          onChange={(event) => {
            onChange(field, event.target.value.replace(/[^\d]/, ""));
          }}
          style={style}
        />
      );
    case 3: //Email
      return (
        <>
          <input
            type="text"
            name={name}
            placeholder={name}
            value={filterNullValue(value)}
            disabled={disabled}
            onChange={(event) => {
              onChange(field, event.target.value);
              validateEmail(event.target.value, setShowError, setIsEmail);
            }}
            style={style}
          />
          {showError && !isEmail && (
            <p
              style={{
                color: "darkred",
                marginTop: 2,
                fontSize: 10,
                width: "100%",
              }}
            >
              Email non valida
            </p>
          )}
        </>
      );
    case 4: //Numero
      return (
        <input
          type="text"
          name={name}
          placeholder={name}
          value={filterNullValue(value)}
          disabled={disabled}
          onChange={(event) => {
            onChange(field, event.target.value.replace(/[^\d]/, ""));
          }}
          style={style}
        />
      );
    case 5: //Prezzo
      return (
        <input
          type="text"
          name={name}
          placeholder={name}
          value={filterNullValue(value)}
          disabled={disabled}
          onChange={(event) => {
            onChange(field, event.target.value.replace(/[^0-9.]/, ""));
          }}
          style={style}
        />
      );
    case 6: //Scelta multipla
      return (
        <select
          name="options"
          style={{ ...style, padding: "9px", marginLeft: "4%" }}
          value={filterNullValue(value)}
          onChange={(event) => onChange(field, event.target.value)}
          disabled={disabled}
        >
          {field.options.map((option, key) => {
            return (
              <option key={key} value={option}>
                {option}
              </option>
            );
          })}
        </select>
      );
    case 7: //Data
      return (
        <div
          style={{
            ...style,
            flex: 1,
            width: "100%",
            textAlign: "left",
            paddingLeft: 20,
            justifyContent: "center",
          }}
        >
          <DatePicker
            name="date"
            dateFormat="dd/MM/yyyy"
            locale="it"
            className={"event-custom-input-multiple"}
            selected={filterNullValue(value)}
            onChange={(date) => onChange(field, date)}
            placeholderText={field.name}
            popperPlacement="bottom-start"
            disabled={disabled}
          />
        </div>
      );
    case 8: //Data e ora
      return (
        <div
          style={{
            ...style,
            flex: 1,
            width: "100%",
            textAlign: "left",
            paddingLeft: 20,
            justifyContent: "center",
          }}
        >
          <DatePicker
            name="startingTime"
            locale="it"
            className={"event-custom-input-multiple"}
            selected={filterNullValue(value)}
            //filterDate={(date) => !closingDays.includes(moment(date).format("yyyy-MM-DD"))}
            onChange={(date) => onChange(field, date)}
            placeholderText={field.name}
            popperPlacement="bottom-start"
            showTimeSelect
            dateFormat="Pp"
            disabled={disabled}
          />
        </div>
      );
    case 9: //Autocomplete
      let list = getList(field.options);
      return (
        <>
          <input
            type="text"
            name="options"
            style={style}
            value={filterNullValue(value)}
            onChange={(event) => onChange(field, event.target.value)}
            list="objectlist"
            disabled={disabled}
          />
          <datalist id="objectlist">
            {list.map((option, key) => {
              return (
                <option key={key} value={option.name} style={{ width: "100%" }}>
                  {option.name}
                </option>
              );
            })}
          </datalist>
        </>
      );
    case 10: //CAP
      return (
        <div style={{ ...style, display: "flex", flexDirection: "row" }}>
          <input
            type="text"
            name="options"
            maxLength="5"
            placeholder="CAP"
            style={{
              ...style,
              marginRight: options.length > 0 && 5,
            }}
            value={filterNullValue(value)}
            onChange={(event) => {
              onChange(field, event.target.value);
              if (event.target.value.length > 4) {
                retrieveCitiesFromZip(event.target.value);
              }
            }}
            list="citylist"
            disabled={disabled}
          />
          {options.length > 0 && (
            <select
              name="options"
              style={{
                ...style,
                marginLeft: 5,
                appearance: options.length < 2 && "none",
              }}
              value={filterNullValue(city)}
              onChange={(event) => setCity(event.target.value)}
            >
              {options.map((option, key) => {
                return (
                  <option
                    key={key}
                    value={option.name}
                    style={{ width: "100%" }}
                  >
                    {option.name}
                  </option>
                );
              })}
            </select>
          )}
        </div>
      );
    case 11: //Birth year
      return (
        <input
          type="text"
          style={style}
          value={filterNullValue(value)}
          onChange={(event) =>
            onChange(event.target.value.replace(/[^\d]/, ""))
          }
          placeholder={field.name}
          disabled={disabled}
        />
      );

    default:
      break;
  }
}

const getCustomFields = (
  styles,
  newCustomFields,
  isEditing,
  setNewCustomFields,
  options,
  retrieveCitiesFromZip,
  city,
  setCity,
  setShowError,
  setIsEmail,
  showError,
  isEmail
) => {
  let customContent = [];

  let fields = JSON.parse(JSON.stringify(newCustomFields));

  function updateField(field, value) {
    field.value = value;
    setNewCustomFields(fields);
  }

  for (let i = 0; i < fields?.length; i += 3) {
    customContent.push(
      <div style={styles.row} key={i}>
        <div style={styles.column}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
            key={i}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 3,
              }}
            >
              <p style={styles.label}>
                {fields[i].name.charAt(0).toUpperCase() +
                  fields[i].name.slice(1) +
                  (fields[i].mandatory ? "*" : "")}
              </p>
              {getInput(
                fields[i].type,
                fields[i],
                !isEditing,
                updateField,
                {
                  ...styles.textInput,
                  width: fields[i + 1]
                    ? fields[i + 2]
                      ? "90%"
                      : "94%"
                    : "97%",
                },
                options,
                retrieveCitiesFromZip,
                city,
                setCity,
                setShowError,
                setIsEmail,
                showError,
                isEmail
              )}
            </div>

            {fields[i + 1] && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 3,
                }}
              >
                <p style={styles.label}>
                  {fields[i + 1].name.charAt(0).toUpperCase() +
                    fields[i + 1].name.slice(1) +
                    (fields[i + 1].mandatory ? "*" : "")}
                </p>
                {getInput(
                  fields[i + 1].type,
                  fields[i + 1],
                  !isEditing,
                  updateField,
                  {
                    ...styles.textInput,
                    width: fields[i + 2] ? "90%" : "94%",
                  },
                  options,
                  retrieveCitiesFromZip,
                  city,
                  setCity,
                  setShowError,
                  setIsEmail,
                  showError,
                  isEmail
                )}
              </div>
            )}

            {fields[i + 2] && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 3,
                }}
              >
                <p style={styles.label}>
                  {fields[i + 2].name.charAt(0).toUpperCase() +
                    fields[i + 2].name.slice(1) +
                    (fields[i + 2].mandatory ? "*" : "")}
                </p>
                {getInput(
                  fields[i + 2].type,
                  fields[i + 2],
                  !isEditing,
                  updateField,
                  styles.textInput,
                  options,
                  retrieveCitiesFromZip,
                  city,
                  setCity,
                  setShowError,
                  setIsEmail,
                  showError,
                  isEmail
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return customContent;
};

const SingleVisitorPage = (props) => {
  const colors = useContext(UserContext)?.colors;
  const org = useContext(OrgContext)?.org;

  const { dictionary } = useContext(LanguageContext);
  const isCreation = props.isCreation;
  const [tabIndex, setTabIndex] = useState(0);
  var customer = JSON.parse(JSON.stringify(props.customer));
  const [newFiscalCode, setNewFiscalCode] = useState(customer.codice_fiscale);
  const [newSurname, setNewSurname] = useState(customer.cognome);
  const [newName, setNewName] = useState(customer.nome);
  const [newEmail, setNewEmail] = useState(customer.email);
  const [newAge, setNewAge] = useState(
    customer.data_nascita
      ? new Date().getFullYear() - customer.data_nascita?.split("-")[0]
      : ""
  );
  const [newVia, setNewVia] = useState(customer.via);
  const [newComune, setNewComune] = useState(customer.comune);
  const [newProvincia, setNewProvincia] = useState(customer.provincia);
  const [newCap, setNewCap] = useState(customer.cap);
  const [newNazione, setNewNazione] = useState(customer.nazione);
  const [newGenere, setNewGenere] = useState(customer.genere);

  const [newCustomFields, setNewCustomFields] = useState(
    isCreation
      ? createFields(props.profiling)
      : JSON.parse(JSON.stringify(customer.additional_infos))
  );
  const [options, setOptions] = useState([]);

  const [city, setCity] = useState("");
  const [showError, setShowError] = useState(false);
  const [isEmail, setIsEmail] = useState(false);

  //We intialiaze this as the isCreation bool because the page's appereance during the creation is the same as while modifying a speaker
  const [isEditing, setIsEditing] = useState(props.isCreation);

  //Bool used during creation to check that all required fields have been compiled
  const [notFilled, setNotFilled] = useState(false);

  const [loader, setLoader] = useState(false);
  //const [view, setView] = useState("month");

  //Flag used to show the popup for confirming the delete of the speaker
  const [deleteCustomer, setDeleteCustomer] = useState(false);

  //Bool used to show the result of operations
  const [endOperation, setEndOperation] = useState(false);

  const getBirthDateFromAge = (age) => {
    var today = new Date();
    return new Date(
      today.getMonth() +
        " " +
        today.getDate() +
        " " +
        (new Date().getFullYear() - parseInt(age)) +
        " 12:00:00"
    );
  };

  const resetValues = () => {
    setNewFiscalCode(customer.codice_fiscale);
    setNewSurname(customer.cognome);
    setNewName(customer.nome);
    setNewEmail(customer.email);
    setNewAge(
      customer.data_nascita
        ? new Date().getFullYear() - customer.data_nascita?.split("-")[0]
        : ""
    );
    setNewVia(customer.via);
    setNewComune(customer.comune);
    setNewProvincia(customer.provincia);
    setNewCap(customer.cap);
    setNewNazione(customer.nazione);
    setNewGenere(customer.genere);

    setNewCustomFields(
      isCreation
        ? createFields(props.profiling)
        : JSON.parse(JSON.stringify(customer.additional_infos))
    );
    setCity("");
    setShowError(false);
    setIsEmail(false);
    setNotFilled(false);
  };

  const createVisitor = () => {
    if (newFiscalCode !== "" && newFiscalCode !== null) {
      customer.codice_fiscale = newFiscalCode;
    }

    if (newSurname !== "" && newSurname !== null) {
      customer.cognome = newSurname;
    }

    if (newName !== "" && newName !== null) {
      customer.nome = newName;
    }

    if (newEmail !== "" && newEmail !== null) {
      customer.email = newEmail;
    }

    if (newAge !== "" && newAge !== null) {
      customer.data_nascita = getBirthDateFromAge(newAge);
    }

    if (newCustomFields !== "" && newCustomFields !== null) {
      customer.additional_infos = newCustomFields;
    }

    if (newVia !== "" && newVia !== null) {
      customer.via = newVia;
    }

    if (newComune !== "" && newComune !== null) {
      customer.comune = newComune;
    }

    if (newProvincia !== "" && newProvincia !== null) {
      customer.provincia = newProvincia;
    }

    if (newCap !== "" && newCap !== null) {
      customer.cap = newCap;
    }

    if (newNazione !== "" && newNazione !== null) {
      customer.nazione = newNazione;
    }

    if (newGenere !== "" && newGenere !== null) {
      customer.genere = newGenere;
    }

    setLoader(true);
    customersService
      .createVisitor(org.id, customer)
      .then(() => {
        setLoader(false);
        props.getCustomers();
        props.setOpenRecord(null);
        props.setIsCreation(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  const updateVisitor = () => {
    if (newFiscalCode !== "" && newFiscalCode !== null) {
      customer.codice_fiscale = newFiscalCode;
    }

    if (newSurname !== "" && newSurname !== null) {
      customer.cognome = newSurname;
    }

    if (newName !== "" && newName !== null) {
      customer.nome = newName;
    }

    if (newEmail !== "" && newEmail !== null) {
      customer.email = newEmail;
    }

    if (newAge !== "" && newAge !== null) {
      customer.data_nascita = getBirthDateFromAge(newAge);
    }

    if (newCustomFields !== "" && newCustomFields !== null) {
      customer.additional_infos = newCustomFields;
    }

    if (newVia !== "" && newVia !== null) {
      customer.via = newVia;
    }

    if (newComune !== "" && newComune !== null) {
      customer.comune = newComune;
    }

    if (newProvincia !== "" && newProvincia !== null) {
      customer.provincia = newProvincia;
    }

    if (newCap !== "" && newCap !== null) {
      customer.cap = newCap;
    }

    if (newNazione !== "" && newNazione !== null) {
      customer.nazione = newNazione;
    }

    if (newGenere !== "" && newGenere !== null) {
      customer.genere = newGenere;
    }

    setLoader(true);
    customersService.updateVisitor(customer).then(() => {
      setLoader(false);
      props.getCustomers();
      props.setOpenRecord(null);
    });
  };

  const toggleDeleteCustomer = () => {
    setDeleteCustomer(!deleteCustomer);
  };

  const toggleEndOperation = () => {
    setEndOperation(!endOperation);
  };

  const handleDelete = () => {
    customersService
      .deleteVisitor(customer.id)
      .then(() => {
        props.getCustomers();
        props.setIsCreation(false);
        props.setOpenRecord(null);
      })
      .catch(() => {
        setDeleteCustomer(false);
        toggleEndOperation();
      });
  };

  const checkCustomFields = () => {
    if (newCustomFields?.length > 0) {
      let check = true;
      for (let i = 0; i < newCustomFields?.length; i++) {
        if (newCustomFields[i].value === "" && newCustomFields[i].mandatory)
          check = false;
      }
      return check;
    } else {
      return true;
    }
  };

  const retrieveCitiesFromZip = (cap) => {
    setCity("");
    reportService.getInfoFromZipCode(cap).then((response) => {
      if (response) {
        setOptions(
          response.comuni.map((c) => {
            return { name: c, value: cap };
          })
        );
      }
    });
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      overflowX: "scroll",
    },
    rowTop: {
      alignItems: "start",
      color: colors?.primary,
      width: "100%",
    },
    rowBottom: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      overflow: "scroll",
    },
    subcontainer: {
      width: "95%",
      paddingBottom: 20,
      paddingTop: 20,
    },
    goBackButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    arrow: {
      height: 13,
      objectFit: "contain",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      paddingBottom: "30px",
    },
    firstColumn: {
      display: "flex",
      flexDirection: "column",
      width: "33.3%",
      justifyContent: "left",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "center",
    },
    avatar: {
      backgroundColor: colors.gray,
      width: "250px",
      height: "250px",
    },
    label: {
      color: colors?.primary,
      fontSize: 15,
      textAlign: "left",
    },
    textInput: {
      outline: "none",
      padding: 10,
      textAlign: "left",
      width: "90%",
      border: "1px solid lightgray",
      borderRadius: 5,
    },
    saveButton: {
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: colors.darkgray,
      margin: 30,
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 15,
    },
  };

  return (
    <div style={styles.container}>
      {deleteCustomer && (
        <DeleteCustomer
          name={newName.concat(" ".concat(newSurname))}
          closeModal={toggleDeleteCustomer}
          saveData={() => handleDelete()}
          colors={colors}
        />
      )}
      {endOperation && (
        <EndOperation
          flag={false}
          operation={2}
          closeModal={toggleEndOperation}
          colors={colors}
        />
      )}
      <div style={styles.body}>
        <div style={styles.rowTop}>
          <Tabs
            value={tabIndex}
            variant="fullWidth"
            TabIndicatorProps={{ style: { background: colors?.secondary } }}
            onChange={(event, newValue) => {
              setTabIndex(newValue);
            }}
          >
            <Tab label={"Info"} />
          </Tabs>
        </div>
        <div style={styles.rowBottom}>
          <div style={styles.subcontainer}>
            <div style={{ ...styles.row, justifyContent: "space-between" }}>
              <button
                style={styles.goBackButton}
                onClick={() => {
                  props.setIsCreation(false);
                  props.setOpenRecord(null);
                }}
                disabled={loader}
              >
                <img src={arrow} style={styles.arrow} alt={"Go back"} />
                <p
                  style={{
                    fontSize: 12,
                    color: colors.darkgray,
                    margin: 0,
                    marginLeft: 5,
                    fontWeight: "600",
                  }}
                >
                  Indietro
                </p>
              </button>
              {!isEditing ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <button
                    style={styles.goBackButton}
                    onClick={() => setIsEditing(!isEditing)}
                  >
                    <img src={modify} style={styles.arrow} alt={"Go back"} />
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.darkgray,
                        margin: 0,
                        marginLeft: 5,
                        fontWeight: "600",
                      }}
                    >
                      Modifica
                    </p>
                  </button>
                  <button
                    style={styles.goBackButton}
                    onClick={() => setDeleteCustomer(!deleteCustomer)}
                  >
                    <img src={deleteIcon} style={styles.arrow} alt={"Delete"} />
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.darkgray,
                        margin: 0,
                        marginLeft: 5,
                        fontWeight: "600",
                      }}
                    >
                      Elimina
                    </p>
                  </button>
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <button
                    style={styles.goBackButton}
                    onClick={() => {
                      setIsEditing(!isEditing);
                      resetValues();
                    }}
                  >
                    <img src={close} style={styles.arrow} alt={"Go back"} />
                    <p
                      style={{
                        fontSize: 12,
                        color: colors.darkgray,
                        margin: 0,
                        marginLeft: 5,
                        fontWeight: "600",
                      }}
                    >
                      Annulla
                    </p>
                  </button>
                </div>
              )}
            </div>
            <div style={{ ...styles.row, justifyContent: "left" }}>
              <div style={styles.firstColumn}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {/* Email */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>
                      {"Email"}
                      {isEditing && "*"}
                    </p>
                    <input
                      type="text"
                      name="customerEmail"
                      placeholder={"Email"}
                      value={newEmail}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewEmail(event.target.value.toLowerCase());
                      }}
                      style={styles.textInput}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={styles.row}>
              <div style={styles.column}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {/* Nome */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>
                      {dictionary.name}
                      {isEditing && "*"}
                    </p>
                    <input
                      type="text"
                      name="customerName"
                      placeholder={dictionary.name}
                      value={newName}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewName(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                  {/* Cognome */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>
                      {dictionary.surname}
                      {isEditing && "*"}
                    </p>
                    <input
                      type="text"
                      name="customerSurname"
                      placeholder={dictionary.surname}
                      value={newSurname}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewSurname(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                  {/* Genere */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>
                      {dictionary.genre}
                      {isEditing && "*"}
                    </p>
                    <input
                      type="text"
                      name="customerGenre"
                      placeholder={dictionary.genre}
                      value={newGenere}
                      disabled={!isEditing}
                      onChange={(event) => {
                        if (
                          event.target.value.toUpperCase() === "F" ||
                          event.target.value.toUpperCase() === "D"
                        ) {
                          setNewGenere("F");
                        } else if (
                          event.target.value.toUpperCase() === "M" ||
                          event.target.value.toUpperCase() === "U"
                        ) {
                          setNewGenere("M");
                        } else if (
                          event.target.value === "" ||
                          event.target.value.toUpperCase() === "ALTR"
                        ) {
                          setNewGenere("");
                        } else {
                          setNewGenere("Altro");
                        }
                      }}
                      style={styles.textInput}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={styles.row}>
              <div style={styles.column}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {/* Birth year */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>
                      {dictionary.age}
                      {isEditing && "*"}
                    </p>
                    <input
                      type="text"
                      name="customerAge"
                      placeholder={dictionary.age}
                      value={newAge}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewAge(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                  {/* Comune */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>
                      {dictionary.customerCity}
                      {isEditing && "*"}
                    </p>
                    <input
                      type="text"
                      name="customerCity"
                      placeholder={dictionary.customerCity}
                      value={newComune}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewComune(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                  {/* Via */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>{dictionary.address}</p>
                    <input
                      type="text"
                      name="customerVia"
                      placeholder={dictionary.address}
                      value={newVia}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewVia(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style={styles.row}>
              <div style={styles.column}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {/* Cap */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>{dictionary.customerZipCode}</p>
                    <input
                      type="text"
                      name="customerZipCode"
                      placeholder={dictionary.customerZipCode}
                      value={newCap}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewCap(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                  {/* Provincia */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>{dictionary.customerCounty}</p>
                    <input
                      type="text"
                      name="customerCounty"
                      placeholder={dictionary.customerCounty}
                      value={newProvincia}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewProvincia(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                  {/* Nazione */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>{dictionary.customerState}</p>
                    <input
                      type="text"
                      name="customerState"
                      placeholder={dictionary.customerState}
                      value={newNazione}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewNazione(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ ...styles.row, justifyContent: "left" }}>
              <div style={styles.firstColumn}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {/* Codice fiscale */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                    }}
                  >
                    <p style={styles.label}>{dictionary.customerFiscalCode}</p>
                    <input
                      type="text"
                      name="customerFiscalCode"
                      placeholder={dictionary.customerFiscalCode}
                      value={newFiscalCode}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewFiscalCode(event.target.value);
                      }}
                      style={styles.textInput}
                    />
                  </div>
                </div>
              </div>
            </div>
            {customer.additional_infos &&
              customer.additional_infos.length > 0 && (
                <>
                  <div style={styles.rowTop}>
                    <Tabs
                      value={tabIndex}
                      variant="fullWidth"
                      TabIndicatorProps={{
                        style: { background: colors?.secondary },
                      }}
                      onChange={(event, newValue) => {
                        setTabIndex(newValue);
                      }}
                    >
                      <Tab label={"Info aggiuntive"} />
                    </Tabs>
                  </div>
                  {getCustomFields(
                    styles,
                    newCustomFields,
                    isEditing,
                    setNewCustomFields,
                    options,
                    retrieveCitiesFromZip,
                    city,
                    setCity,
                    setShowError,
                    setIsEmail,
                    showError,
                    isEmail
                  )}
                </>
              )}
            {isEditing && (
              <div style={{ ...styles.column, alignItems: "center" }}>
                {notFilled && (
                  <p style={{ ...styles.label, color: colors?.highlight }}>
                    {dictionary.notFilled}
                  </p>
                )}
                <div style={styles.row}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {!loader && (
                      <button
                        style={{
                          ...styles.saveButton,
                          backgroundColor: colors.darkgray,
                        }}
                        onClick={() => {
                          var tmpNotFilled = notFilled;

                          if (
                            newName === "" ||
                            newSurname === "" ||
                            newEmail === "" ||
                            newComune === "" ||
                            newAge === "" ||
                            newGenere === "" ||
                            !checkCustomFields()
                          ) {
                            tmpNotFilled = true;
                            setNotFilled(true);
                          } else {
                            tmpNotFilled = false;
                            setNotFilled(false);
                          }

                          if (!tmpNotFilled) {
                            if (isCreation) {
                              createVisitor();
                            } else {
                              updateVisitor();
                            }
                          }
                        }}
                        disabled={isCreation && notFilled}
                      >
                        <p
                          style={{
                            fontSize: 12,
                            color: colors.white,
                            margin: 0,
                            fontWeight: "600",
                          }}
                        >
                          Salva
                        </p>
                      </button>
                    )}
                    {loader && (
                      <ReactLoading
                        type={"spinningBubbles"}
                        color={colors?.primary}
                        height={50}
                        width={50}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleVisitorPage;
