import React, { Component } from "react";
import login from "./../img/login.png";
import logoEmma from "./../img/logo_white.png";
import emma from "./../img/emma.png";
import { authenticationService, usersService } from "../_services";
import { settingsService } from "../_services";
import colors from "./../museumColors";
import InfoButton from "../components/buttons/infoButton";
import ReactLoading from "react-loading";
//import { SketchPicker } from "react-color";
import Select from "react-select";

const moduleOptions = [
  { value: "1", label: "Flusso cassa" },
  { value: "2", label: "Inventario" },
  { value: "3", label: "Report" },
  { value: "4", label: "Storico" },
  { value: "5", label: "Eventi" },
  { value: "6", label: "Prenotazioni" },
  { value: "7", label: "Turni" },
  { value: "8", label: "Rete" },
  { value: "9", label: "Statistiche" },
  { value: "10", label: "Tessere" },
  { value: "43", label: "Attività" },
  { value: "44", label: "Spazi" },
  { value: "77", label: "Clienti" },
  { value: "78", label: "Interventi" },
  { value: "79", label: "Voucher" },
];

export default class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      showError: false,
      showWrongEmail: false,
      showConfirmation: false,
      message: "",
      name: "",
      description: "",
      address: "",
      zip: "",
      city: "",
      tel: "",
      email: "",
      logoLight: "",
      tempLogoLight: "",
      logoDark: "",
      tempLogoDark: "",
      cover: "",
      tempCover: "",
      lightLogoError: false,
      darkLogoError: false,
      coverError: false,
      admin_user: "",
      admin_email: "",
      admin_pw: "",
      admin_pw_confirm: "",
      capacity: 100,
      timezone: "Europe/Rome",
      modules: [],
      step: 1,
      multiOrg: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.signup = this.signup.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
  }

  validateEmail() {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let correct = re.test(String(this.state.admin_email).toLowerCase());

    return correct;
  }

  checkEmail = () => {
    this.setState({ step: 5 });

    usersService.getUserFromEmail(this.state.admin_email).then((response) => {
      if (Object.keys(response).length > 0) {
        this.setState({ step: 4, multiOrg: true });
      } else {
        this.setState({ step: 3, multiOrg: false });
      }
    });
  };

  onSubmitLogoLight(tempLogoLight, id) {
    const formData = new FormData();
    formData.append("files", tempLogoLight);
    formData.append("refId", id);
    formData.append("ref", "musei");
    formData.append("field", "logo_light");
    if (tempLogoLight) {
      settingsService
        .upload(formData)
        .then((logoLight) => this.setState({ logoLight: logoLight[0] }))
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            this.props.history.push("/");
          }
        });
    }
  }

  onSubmitLogoDark(tempLogoDark, id) {
    const formData = new FormData();
    formData.append("files", tempLogoDark);
    formData.append("refId", id);
    formData.append("ref", "musei");
    formData.append("field", "logo_dark");
    if (tempLogoDark) {
      settingsService
        .upload(formData)
        .then((logoDark) => this.setState({ logoDark: logoDark[0] }))
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            this.props.history.push("/");
          }
        });
    }
  }

  onSubmitCover(tempCover, id) {
    const formData = new FormData();
    formData.append("files", tempCover);
    formData.append("refId", id);
    formData.append("ref", "musei");
    formData.append("field", "cover");
    if (tempCover) {
      settingsService
        .upload(formData)
        .then((cover) => this.setState({ cover: cover[0] }))
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            this.props.history.push("/");
          }
        });
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.setState({ start: new Date() });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.signup();
  };

  notEmpty(field) {
    return field !== "" && field !== null;
  }

  notEmptyArray(array) {
    return array !== null && array.length !== 0;
  }

  hasCards() {
    const { modules } = this.state;
    return modules.includes("10");
  }

  enableSecondStep() {
    const {
      name,
      description,
      address,
      zip,
      city,
      tel,
      email,
      tempLogoLight,
      tempLogoDark,
      tempCover,
      modules,
    } = this.state;
    return (
      this.notEmpty(name) &&
      this.notEmpty(description) &&
      this.notEmpty(address) &&
      this.notEmpty(zip) &&
      this.notEmpty(city) &&
      this.notEmpty(tel) &&
      this.notEmpty(email) &&
      this.notEmpty(tempLogoLight) &&
      this.notEmpty(tempLogoDark) &&
      this.notEmpty(tempCover) &&
      this.notEmptyArray(modules)
    );
  }

  enableCreation() {
    const { admin_user, admin_pw, admin_pw_confirm } = this.state;
    return (
      this.notEmpty(admin_user) &&
      this.notEmpty(admin_pw) &&
      admin_pw === admin_pw_confirm
    );
  }
  /**
   * Call backend for login
   */
  signup() {
    this.setState({ step: 5 });
    const {
      name,
      address,
      zip,
      city,
      tel,
      email,
      admin_email,
      admin_pw,
      admin_user,
      capacity,
      timezone,
      modules,
      description,
      //color,
    } = this.state;
    this.setState({ message: "", showError: false });
    var tempDescription = description.replace(/\r\n|\r|\n/g, "<br/>");
    this.setState({ description: tempDescription });

    authenticationService
      .signup(
        name,
        name,
        address,
        zip,
        city,
        tel,
        email,
        admin_user,
        admin_email,
        admin_pw,
        capacity,
        timezone,
        modules,
        tempDescription //,
        //color
      )
      .then((response) => {
        if (response.statusCode === 400) {
          this.setState({
            showError: true,
            message: response.message[0].messages.message,
          });
          this.setState({ step: 1 });
        } else if (response.statusCode === 666) {
          this.setState({
            showError: true,
            message: "Email già in uso",
          });
          this.setState({ step: 1 });
        } else {
          // set logos and cover, knowing the museum id (got from the corresponding organization)
          this.onSubmitLogoLight(this.state.tempLogoLight, response.id_entita);
          this.onSubmitLogoDark(this.state.tempLogoDark, response.id_entita);
          this.onSubmitCover(this.state.tempCover, response.id_entita);
          this.setState({ step: this.state.multiOrg ? 7 : 6 });
        }
      })
      .catch((errors) => {
        this.setState({ step: 1 });
        // c'è stato un errore, mostra il messaggio
        this.setState({
          showError: true,
          message: errors.message[0].messages[0].message,
        });
      });
  }

  render() {
    const {
      width,
      showError,
      message,
      name,
      address,
      zip,
      city,
      tel,
      email,
      admin_email,
      admin_pw,
      admin_user,
      admin_pw_confirm,
      step,
      description,
      //color,
    } = this.state;
    return (
      <div style={styles.container}>
        <a style={styles.header} href="https://www.rnb4culture.com/emma/">
          <img
            alt="App logo"
            src={logoEmma}
            style={{ width: width / 8, objectFit: "contain" }}
          />
        </a>
        {showError && (
          <div style={styles.message}>
            <p style={{ color: colors.white }}>ATTENZIONE! {message}</p>{" "}
          </div>
        )}
        <div style={styles.content}>
          <div style={styles.row}>
            <div
              style={{
                width: "50%",
                marginTop: 30,
                marginRight: 10,
                backgroundColor: colors.white,
                padding: 30,
                borderRadius: 10,
                height: 600,
              }}
            >
              <a href="https://www.rnb4culture.com/emma/">
                <img
                  alt="Presentation"
                  src={emma}
                  style={{ width: "60%", objectFit: "contain" }}
                />
              </a>
              <h1 style={{ color: colors.primary }}>
                Prova EMMA gratuitamente per 30 giorni
              </h1>
              <h3 style={{ color: colors.secondary }}>
                Tutte le funzionalità di EMMA (versione beta) disponibili in un
                unico piano gratuito
              </h3>
              <p style={{ color: colors.darkgray }}>
                Il piano Freemium scadrà automaticamente al 31° giorno, senza
                rinnovi automatici. Non è richiesto l'inserimento di carte di
                credito.
              </p>
              <h4 style={{ color: colors.primary }}>
                Per saperne di più, visita il nostro{" "}
                <a
                  href="https://www.rnb4culture.com/emma/"
                  style={{ color: colors.primary }}
                >
                  sito
                </a>{" "}
                o{" "}
                <a
                  style={{ color: colors.primary }}
                  href={`mailto:emma@rnb4culture.com?subject=Informazioni su EMMA&body=`}
                >
                  contattaci
                </a>
              </h4>
            </div>
            {step === 1 && (
              <form
                style={{
                  width: "50%",
                  height: 600,
                  marginTop: 30,
                  marginLeft: 10,
                  backgroundColor: colors.tertiary,
                  padding: 30,
                  overflowY: "scroll",
                  borderRadius: 10,
                }}
                onSubmit={this.handleSubmit}
                id="scroll"
              >
                <h1 style={{ color: colors.primary }}>Registra il tuo museo</h1>
                <h3 style={{ color: colors.secondary }}>
                  Compila il form con le informazioni del museo e crea il tuo
                  account
                </h3>
                <div style={styles.field}>
                  <InfoButton
                    backgroundColor={colors.quaternary}
                    width={150}
                    text="Nome del tuo museo"
                    colors={colors}
                  />
                  <p style={styles.label}>Nome museo</p>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={(event) =>
                      this.setState({ name: event.target.value })
                    }
                    placeholder="Nome"
                    style={styles.textInput}
                  />
                </div>
                <div style={styles.field}>
                  <InfoButton
                    backgroundColor={colors.quaternary}
                    width={150}
                    text="Descrizione del tuo museo"
                    colors={colors}
                  />
                  <p style={styles.label}>Descrizione</p>
                  <textarea
                    name="description"
                    value={description.replace(/<br*\/?>/gi, "\n")}
                    onChange={(event) =>
                      this.setState({ description: event.target.value })
                    }
                    placeholder="Descrizione"
                    style={styles.textareaInput}
                  />
                </div>
                <div style={styles.field}>
                  <InfoButton
                    backgroundColor={colors.quaternary}
                    width={150}
                    text="Indirizzo e numero civico del tuo museo"
                    colors={colors}
                  />
                  <p style={styles.label}>Indirizzo</p>
                  <input
                    type="text"
                    name="address"
                    value={address}
                    onChange={(event) =>
                      this.setState({ address: event.target.value })
                    }
                    placeholder="Indirizzo e numero civico"
                    style={styles.textInput}
                  />
                </div>
                <div style={styles.field}>
                  <InfoButton
                    backgroundColor={colors.quaternary}
                    width={150}
                    text="Città in cui si trova il tuo museo"
                    colors={colors}
                  />
                  <p style={styles.label}>Città</p>
                  <input
                    type="text"
                    name="city"
                    value={city}
                    onChange={(event) =>
                      this.setState({ city: event.target.value })
                    }
                    placeholder="Città"
                    style={styles.textInput}
                  />
                </div>
                <div style={styles.field}>
                  <InfoButton
                    backgroundColor={colors.quaternary}
                    width={150}
                    text="CAP della città del tuo museo"
                    colors={colors}
                  />
                  <p style={styles.label}>CAP</p>
                  <input
                    type="number"
                    name="address"
                    value={zip}
                    onChange={(event) =>
                      this.setState({ zip: event.target.value })
                    }
                    placeholder="CAP"
                    style={styles.textInput}
                  />
                </div>
                <div style={styles.field}>
                  <InfoButton
                    backgroundColor={colors.quaternary}
                    width={150}
                    text="Numero di telefono a cui vuoi essere contattato per informazioni dai tuoi visitatori"
                    colors={colors}
                  />
                  <p style={styles.label}>Telefono museo</p>
                  <input
                    type="tel"
                    name="tel"
                    value={tel}
                    onChange={(event) =>
                      this.setState({ tel: event.target.value })
                    }
                    placeholder="Telefono"
                    style={styles.textInput}
                  />
                </div>
                <div style={styles.field}>
                  <InfoButton
                    backgroundColor={colors.quaternary}
                    width={150}
                    text="Email a cui vuoi essere contattato per informazioni dai tuoi visitatori"
                    colors={colors}
                  />
                  <p style={styles.label}>Email museo</p>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    }
                    placeholder="Email"
                    style={styles.textInput}
                  />
                </div>
                <div style={styles.field}>
                  <InfoButton
                    backgroundColor={colors.quaternary}
                    width={150}
                    text="Logo chiaro del tuo museo"
                    colors={colors}
                  />
                  <p style={styles.label}>Logo chiaro museo</p>
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    name="logoLight"
                    onChange={(event) => {
                      const tmpFileUploaded = event.target.files[0];
                      if (tmpFileUploaded) {
                        if (tmpFileUploaded.size < 1000000) {
                          this.setState({ tempLogoLight: tmpFileUploaded });
                          this.setState({ lightLogoError: false });
                        } else {
                          console.log(
                            "Dimensione dell'immagine scelta: " +
                              (tmpFileUploaded.size / 1000000).toFixed(2) +
                              "... MB (superiore a 1 MB)"
                          );
                          this.setState({ lightLogoError: true });
                        }
                      } else {
                        this.setState({ lightLogoError: false });
                      }
                    }}
                    alt="image"
                    style={styles.textInput}
                  />
                </div>
                {this.state.lightLogoError && (
                  <p style={styles.errorLabel}>
                    {"L'immagine scelta non può eccedere la dimensione di 1MB"}
                  </p>
                )}
                <div style={styles.field}>
                  <InfoButton
                    backgroundColor={colors.quaternary}
                    width={150}
                    text="Logo scuro del tuo museo"
                    colors={colors}
                  />
                  <p style={styles.label}>Logo scuro museo</p>
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    name="logoDark"
                    onChange={(event) => {
                      const tmpFileUploaded = event.target.files[0];
                      if (tmpFileUploaded) {
                        if (tmpFileUploaded.size < 1000000) {
                          this.setState({ tempLogoDark: tmpFileUploaded });
                          this.setState({ darkLogoError: false });
                        } else {
                          console.log(
                            "Dimensione dell'immagine scelta: " +
                              (tmpFileUploaded.size / 1000000).toFixed(2) +
                              "... MB (superiore a 1 MB)"
                          );
                          this.setState({ darkLogoError: true });
                        }
                      } else {
                        this.setState({ darkLogoError: false });
                      }
                    }}
                    alt="image"
                    style={styles.textInput}
                  />
                </div>
                {this.state.darkLogoError && (
                  <p style={styles.errorLabel}>
                    {"L'immagine scelta non può eccedere la dimensione di 1MB"}
                  </p>
                )}
                <div style={styles.field}>
                  <InfoButton
                    backgroundColor={colors.quaternary}
                    width={150}
                    text="Cover del tuo museo"
                    colors={colors}
                  />
                  <p style={styles.label}>Cover museo</p>
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    name="cover"
                    onChange={(event) => {
                      const tmpFileUploaded = event.target.files[0];
                      if (tmpFileUploaded) {
                        if (tmpFileUploaded.size < 1000000) {
                          this.setState({ tempCover: tmpFileUploaded });
                          this.setState({ coverError: false });
                        } else {
                          console.log(
                            "Dimensione dell'immagine scelta: " +
                              (tmpFileUploaded.size / 1000000).toFixed(2) +
                              "... MB (superiore a 1 MB)"
                          );
                          this.setState({ coverError: true });
                        }
                      } else {
                        this.setState({ coverError: false });
                      }
                    }}
                    alt="image"
                    style={styles.textInput}
                  />
                </div>
                {this.state.coverError && (
                  <p style={styles.errorLabel}>
                    {"L'immagine scelta non può eccedere la dimensione di 1MB"}
                  </p>
                )}
                <div style={styles.field}>
                  <InfoButton
                    backgroundColor={colors.quaternary}
                    width={150}
                    text="Moduli disponibili da aggiungere a EMMA"
                    colors={colors}
                  />
                  <p style={styles.label}>Moduli disponibili</p>
                  <div style={styles.select}>
                    <Select
                      placeholder="Selezione moduli"
                      defaultValue={[]}
                      isMulti
                      name="modules"
                      options={moduleOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(event) => {
                        var temp = [];
                        event.forEach((element) => {
                          temp.push(element.value);
                        });
                        temp.sort();
                        this.setState({ modules: temp });
                      }}
                    />
                  </div>
                </div>

                {this.enableSecondStep() && (
                  <button
                    onClick={() => {
                      this.setState({ step: 2 });
                    }}
                    style={{
                      border: "none",
                      width: "100%",
                      backgroundColor: "transparent",
                      outline: "none",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        color: colors.white,
                        fontWeight: "bolder",
                        marginRight: 10,
                        fontSize: 18,
                      }}
                    >
                      AVANTI
                    </p>
                    <img
                      alt="Login button"
                      src={login}
                      style={{ width: 30, objectFit: "contain" }}
                    />
                  </button>
                )}
              </form>
            )}
            {step === 2 && (
              <form
                style={{
                  width: "50%",
                  height: 600,
                  marginTop: 30,
                  marginLeft: 10,
                  backgroundColor: colors.tertiary,
                  padding: 30,
                  overflow: "scroll",
                  borderRadius: 10,
                }}
                onSubmit={this.handleSubmit}
              >
                <h1 style={{ color: colors.primary }}>
                  Crea l'account amministratore
                </h1>
                <h3 style={{ color: colors.secondary }}>
                  Inserisci l'e-mail del responsabile del museo.{" "}
                </h3>
                <div style={styles.field}>
                  <InfoButton
                    backgroundColor={colors.quaternary}
                    width={150}
                    text="Email dell'amministratore del museo"
                    colors={colors}
                  />
                  <p style={styles.label}>Email amministratore</p>
                  <input
                    type="email"
                    name="admin_email"
                    value={admin_email}
                    onChange={(event) =>
                      this.setState({
                        admin_email: event.target.value.toLowerCase(),
                      })
                    }
                    placeholder="Email"
                    style={styles.textInput}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  {this.validateEmail() && (
                    <button
                      onClick={() => {
                        this.checkEmail();
                      }}
                      style={{
                        border: "none",
                        width: "100%",
                        backgroundColor: "transparent",
                        outline: "none",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          color: colors.white,
                          fontWeight: "bolder",
                          fontSize: 18,
                          backgroundColor: colors.secondary,
                          borderRadius: 10,
                          padding: 20,
                        }}
                      >
                        VERIFICA EMAIL
                      </p>
                    </button>
                  )}
                </div>
              </form>
            )}
            {step === 3 && (
              <form
                style={{
                  width: "50%",
                  height: 600,
                  marginTop: 30,
                  marginLeft: 10,
                  backgroundColor: colors.tertiary,
                  padding: 30,
                  overflow: "scroll",
                  borderRadius: 10,
                }}
                onSubmit={this.handleSubmit}
              >
                <h1 style={{ color: colors.primary }}>
                  Crea l'account amministratore
                </h1>
                <h3 style={{ color: colors.secondary }}>
                  Compila il form con le informazioni del responsabile del
                  museo. L'amministratore potrà creare in ogni momento nuovi
                  account amministratore e operatore.{" "}
                </h3>
                <div style={styles.field}>
                  <InfoButton
                    backgroundColor={colors.quaternary}
                    width={150}
                    text="Nome dell'amministratore del museo"
                    colors={colors}
                  />
                  <p style={styles.label}>Nome amministratore</p>
                  <input
                    type="text"
                    name="admin_user"
                    value={admin_user}
                    onChange={(event) =>
                      this.setState({ admin_user: event.target.value })
                    }
                    placeholder="Nome Cognome"
                    style={styles.textInput}
                  />
                </div>
                <div style={styles.field}>
                  <InfoButton
                    backgroundColor={colors.quaternary}
                    width={150}
                    text="Password dell'amministratore del museo"
                    colors={colors}
                  />
                  <p style={styles.label}>Password amministratore</p>
                  <input
                    type="password"
                    name="admin_pw"
                    value={admin_pw}
                    onChange={(event) =>
                      this.setState({ admin_pw: event.target.value })
                    }
                    placeholder="Password"
                    style={styles.textInput}
                  />
                </div>
                <div style={styles.field}>
                  <InfoButton
                    backgroundColor={colors.quaternary}
                    width={150}
                    text="Assicurati che le password coincidano"
                    colors={colors}
                  />
                  <p style={styles.label}>Conferma password</p>
                  <input
                    type="password"
                    name="password"
                    value={admin_pw_confirm || ""}
                    onChange={(event) =>
                      this.setState({ admin_pw_confirm: event.target.value })
                    }
                    placeholder="Conferma password"
                    style={styles.textInput}
                  />
                </div>
                {admin_pw !== "" && admin_pw !== admin_pw_confirm && (
                  <div style={{ color: colors.highlight, fontSize: 13 }}>
                    Le password non corrispondono.
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  {this.enableCreation() && (
                    <button
                      onClick={() => {
                        this.signup();
                      }}
                      style={{
                        border: "none",
                        width: "100%",
                        backgroundColor: "transparent",
                        outline: "none",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          color: colors.white,
                          fontWeight: "bolder",
                          fontSize: 18,
                          backgroundColor: colors.secondary,
                          borderRadius: 10,
                          padding: 20,
                        }}
                      >
                        CREA ACCOUNT
                      </p>
                    </button>
                  )}
                </div>
              </form>
            )}
            {step === 4 && (
              <form
                style={{
                  width: "50%",
                  height: 600,
                  marginTop: 30,
                  marginLeft: 10,
                  backgroundColor: colors.tertiary,
                  padding: 30,
                  overflow: "scroll",
                  borderRadius: 10,
                }}
                onSubmit={this.handleSubmit}
              >
                <h1 style={{ color: colors.primary }}>
                  La mail del responsabile é già associata a un altro account
                </h1>
                <h3 style={{ color: colors.secondary }}>
                  Si desidera procedere ugualmente associando il museo in fase
                  di creazione all'account già esistente o tornare indietro per
                  inserire un nuovo indirizzo?{" "}
                </h3>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <button
                    onClick={() => {
                      this.setState({ step: 2 });
                    }}
                    style={{
                      border: "none",
                      width: "100%",
                      backgroundColor: "transparent",
                      outline: "none",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    INDIETRO
                  </button>
                  <button
                    onClick={() => {
                      this.signup();
                    }}
                    style={{
                      backgroundColor: colors.primary,
                      color: colors.white,
                      padding: 20,
                      borderRadius: 20,
                      marginRight: 40,
                      marginBottom: 40,
                      border: "none",
                      outline: "none",
                      marginTop: 30,
                      cursor: "pointer",
                    }}
                  >
                    CREA ACCOUNT
                  </button>
                </div>
              </form>
            )}
            {step === 5 && (
              <div
                style={{
                  width: "50%",
                  height: 600,
                  marginTop: 30,
                  marginLeft: 10,
                  backgroundColor: colors.tertiary,
                  padding: 30,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: 10,
                }}
                onSubmit={this.handleSubmit}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={colors.primary}
                  height={50}
                  width={50}
                />
              </div>
            )}
            {step === 6 && (
              <div
                style={{
                  width: "50%",
                  height: 600,
                  marginTop: 30,
                  marginLeft: 10,
                  backgroundColor: colors.tertiary,
                  padding: 30,
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 10,
                }}
                onSubmit={this.handleSubmit}
              >
                <h1 style={{ color: colors.primary }}>Benvenuto in EMMA!</h1>
                <h3 style={{ color: colors.secondary }}>
                  Il tuo museo {name} è stato creato correttamente
                </h3>
                <p style={{ color: colors.darkgray }}>
                  Abbiamo inviato un'email di conferma all'indirizzo{" "}
                  <b>{admin_email}</b>.
                  <br />
                  Clicca sul link per abilitare il tuo account e iniziare ad
                  utilizzare la piattaforma.
                </p>
                <br />
                <p style={{ color: colors.darkgray }}>
                  Il tuo account di prova ha validità 30 giorni. Dopo tale data
                  non riuscirai più ad accedere al tuo account. Rinnova
                  l'abbonamento per continuare ad utilizzare EMMA!
                </p>
                <h3 style={{ color: colors.primary, marginTop: 40 }}>
                  Contattaci
                </h3>
                <a
                  style={{ color: colors.primary }}
                  href={`mailto:emma@rnb4culture.com?subject=Rinnovo account EMMA&body=`}
                >
                  emma@rnb4culture.com
                </a>
                <a
                  style={{
                    color: colors.primary,
                    marginTop: 80,
                    fontSize: 12,
                    outline: "none",
                  }}
                  href={`${process.env.REACT_APP_WEB_URL}/`}
                >
                  Vai al Login
                </a>
              </div>
            )}
            {step === 7 && (
              <div
                style={{
                  width: "50%",
                  height: 600,
                  marginTop: 30,
                  marginLeft: 10,
                  backgroundColor: colors.tertiary,
                  padding: 30,
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 10,
                }}
                onSubmit={this.handleSubmit}
              >
                <h1 style={{ color: colors.primary }}>Benvenuto in EMMA!</h1>
                <h3 style={{ color: colors.secondary }}>
                  Il tuo museo {name} è stato creato correttamente
                </h3>
                <h3 style={{ color: colors.primary, marginTop: 40 }}>
                  Contattaci
                </h3>
                <a
                  style={{ color: colors.primary }}
                  href={`mailto:emma@rnb4culture.com?subject=Rinnovo account EMMA&body=`}
                >
                  emma@rnb4culture.com
                </a>
                <a
                  style={{
                    color: colors.primary,
                    marginTop: 80,
                    fontSize: 12,
                    outline: "none",
                  }}
                  href={`${process.env.REACT_APP_WEB_URL}/`}
                >
                  <b>Vai al Login</b>
                </a>
              </div>
            )}
          </div>
          <div style={{ display: "flex", flex: 1 }}></div>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    alignItems: "center",
    backgroundColor: colors.secondary,
    overflow: "scroll",
  },
  header: {
    display: "flex",
    flex: 4,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
    marginBottom: 20,
  },
  content: {
    display: "flex",
    width: "80%",
    flexDirection: "column",
    flex: 5,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 100,
  },
  textInput: {
    borderRadius: 30,
    width: "100%",
    border: 0,
    padding: 13,
    outline: "none",
  },
  textareaInput: {
    borderRadius: 25,
    height: 60,
    width: "100%",
    border: 0,
    padding: 13,
    outline: "none",
    fontFamily: "inherit",
    resize: "none",
  },
  field: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: 30,
    marginBottom: 30,
  },
  label: {
    color: colors.white,
    width: 250,
  },
  errorLabel: {
    fontSize: 11,
    textAlign: "left",
    color: colors?.red,
    marginTop: -30,
  },
  password: {
    color: colors.white,
    fontStyle: "italic",
    fontSize: 13,
  },
  message: {
    color: colors.white,
    fontWeight: 700,
    fontSize: 13,
    marginBottom: 50,
    width: "100%",
    backgroundColor: "rgba(277,277,277,0.3)",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  select: {
    borderRadius: 100,
    width: "100%",
    border: 0,
    padding: 13,
    outline: "none",
    textAlignLast: "left",
  },
};
