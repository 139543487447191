import React, { useState } from "react";
import close from "./../../img/closeButton.png";
import ReactLoading from "react-loading";

const DeleteIntervention = ({ name, closeModal, saveData, colors }) => {
  const [loader, setLoader] = useState(false);
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      width: 500,
      height: 300,
      zIndex: 300,
    },
    header: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors.primary,
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    content: {
      width: "100%",
      flex: 6,
      backgroundColor: colors.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    field: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      width: "100%",
      marginLeft: 50,
      marginRight: 50,
    },
    footer: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    cancelButton: {
      borderWidth: 1,
      borderColor: colors.primary,
      backgroundColor: colors.white,
      color: colors.primary,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
  };
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: colors.gray,
          opacity: 0.3,
        }}
      />
      <div style={styles.container}>
        <div style={styles.header}>
          <p style={{ color: colors.white, fontSize: 20 }}>
            ELIMINA SEGNALAZIONE
          </p>
          <button
            onClick={closeModal}
            style={{
              position: "absolute",
              right: 30,
              marginTop: "auto",
              marginBottom: "auto",
              border: "none",
              backgroundColor: "transparent",
              outline: "none",
              cursor: "pointer",
            }}
          >
            <img
              alt="Close button"
              src={close}
              style={{ width: 15, objectFit: "contain" }}
            />
          </button>
        </div>
        <div style={styles.content}>
          {loader ? (
            <ReactLoading
              type={"spinningBubbles"}
              color={colors.primary}
              height={50}
              width={50}
            />
          ) : (
            <div style={styles.field}>
              <p style={{ fontSize: 15, color: colors.lightgray }}>
                Attenzione!
              </p>
              <p style={{ textAlign: "left", color: colors.darkgray }}>
                La segnalazione numero {name} verrà eliminata definitivamente.{" "}
                <br /> Sei sicuro di voler procedere?
              </p>
            </div>
          )}
        </div>
        <div style={styles.footer}>
          <button onClick={closeModal} style={styles.cancelButton}>
            ANNULLA
          </button>
          <button
            onClick={() => {
              saveData();
              setLoader(true);
            }}
            style={styles.button}
          >
            ELIMINA
          </button>
        </div>
      </div>
    </>
  );
};

export default DeleteIntervention;
