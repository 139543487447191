import React, { useState } from "react";
import TimeSlot from "./timeSlot";
import add from "./../img/add.png";
import remove from "./../img/minus_white.png";
import Switch from "react-switch";

const Daytime = ({ day, timetable, save, colors, edit }) => {
  const [showSecondSlot, setShowSecondSlot] = useState(
    timetable.ora_apertura_2 !== null
  );
  const [firstSlotOpening, setFirstSlotOpening] = useState(
    timetable.ora_apertura_1
  );
  const [firstSlotClosing, setFirstSlotClosing] = useState(
    timetable.ora_chiusura_1
  );
  const [secondSlotOpening, setSecondSlotOpening] = useState(
    timetable.ora_apertura_2
  );
  const [secondSlotClosing, setSecondSlotClosing] = useState(
    timetable.ora_chiusura_2
  );
  const [open, setOpen] = useState(!timetable.flag_chiuso);

  React.useEffect(() => {
    setShowSecondSlot(timetable.ora_apertura_2 !== null);
    setFirstSlotOpening(timetable.ora_apertura_1);
    setFirstSlotClosing(timetable.ora_chiusura_1);
    setSecondSlotOpening(timetable.ora_apertura_2);
    setSecondSlotClosing(timetable.ora_chiusura_2);
    setOpen(!timetable.flag_chiuso);
  }, [timetable]);

  React.useEffect(() => {
    let temp = {
      ora_apertura_1: firstSlotOpening,
      ora_chiusura_1: firstSlotClosing,
      ora_apertura_2: secondSlotOpening,
      ora_chiusura_2: secondSlotClosing,
      flag_chiuso: !open,
    };
    if (secondSlotOpening === null || secondSlotOpening > firstSlotClosing) {
      save(temp);
    }
  }, [
    firstSlotOpening,
    firstSlotClosing,
    secondSlotOpening,
    secondSlotClosing,
    open,
    save,
  ]);

  React.useEffect(() => {
    if (showSecondSlot === false) {
      setSecondSlotOpening(null);
      setSecondSlotClosing(null);
    }
  }, [showSecondSlot]);
  const styles = {
    row: {
      display: "flex",
      flexDirection: edit ? "column" : "row",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 10,
      paddingBottom: 10,
      borderBottom: "1px solid lightgray",
    },
    day: {
      width: 70,
      fontSize: 14,
      textAlign: "left",
      marginRight: 15,
      color: colors.darkgray,
    },
    addButton: {
      border: 0,
      outline: "none",
      borderRadius: 20,
      backgroundColor: colors.tertiary,
      height: 20,
      width: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  return (
    <div style={styles.row}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <p style={styles.day}>{day}</p>
        {edit ? (
          <Switch
            onChange={() => setOpen(!open)}
            checked={open}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor={colors.primary}
            width={30}
            height={20}
            edit={edit}
          />
        ) : (
          !open && <p style={{ fontSize: 14, width: 120 }}>CHIUSO</p>
        )}
      </div>
      {open && (
        <>
          <div style={{ width: edit ? 200 : 120 }}>
            <TimeSlot
              starting={firstSlotOpening}
              closing={firstSlotClosing}
              setOpening={setFirstSlotOpening}
              setClosing={setFirstSlotClosing}
              edit={edit}
            />
            {showSecondSlot && (
              <>
                <TimeSlot
                  starting={secondSlotOpening}
                  closing={secondSlotClosing}
                  setOpening={setSecondSlotOpening}
                  setClosing={setSecondSlotClosing}
                  edit={edit}
                />
                {secondSlotOpening < firstSlotClosing && (
                  <p
                    style={{
                      color: colors.highlight,
                      fontSize: 10,
                      marginTop: 0,
                      width: 180,
                    }}
                  >
                    <b>Errore.</b> L'orario di riapertura deve essere successivo
                    all'orario di chiusura del primo turno.
                  </p>
                )}
              </>
            )}
          </div>
          {edit && (
            <button
              style={styles.addButton}
              onClick={() => setShowSecondSlot(!showSecondSlot)}
            >
              <img
                alt={"Add timeslot button"}
                src={showSecondSlot ? remove : add}
                style={{ width: 10, height: 10, objectFit: "contain" }}
              ></img>
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default Daytime;
