import React, { useState, useContext, useEffect, useCallback } from "react";
import { UserContext, OrgContext } from "../../../../contexts";
import { LanguageContext } from "../../../../containers/language";
import { Badge, Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import modify from "../../../../img/modify_black.png";
import upload from "../../../../img/uploadimg_black.png";
import arrow from "../../../../img/arrow_back_dark.png";
import logout from "../../../../img/logout_black.png";
import close from "../../../../img/closeButton.png";
import burgerIcon from "../../../../img/burgerIcon.png";
import { activitiesService } from "../../../../_services/activities.service";
import { authenticationService, settingsService } from "../../../../_services";
import ReactLoading from "react-loading";
import { ChromePicker, SwatchesPicker } from "react-color";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Modal from "../speakers/modal";
import RequestsManager from "./requests/requestsManager";
import Reservations from "./reservations";
import { push as Menu } from "react-burger-menu";
const localizer = momentLocalizer(moment);

const minTime = new Date();
minTime.setHours(8, 0, 0);
const maxTime = new Date();
maxTime.setHours(20, 0, 0);

function checkOverlappingAvailabilities(allAvailabilities, slot) {
  let availabilities = allAvailabilities.filter((av) => av.id !== slot.id);
  let overlappingStart = availabilities.filter(
    (av) =>
      new Date(av.datetime_inizio) < new Date(slot.start) &&
      new Date(av.datetime_fine) > new Date(slot.start)
  );
  let overlappingEnd = availabilities.filter(
    (av) =>
      new Date(av.datetime_fine) > new Date(slot.end) &&
      new Date(av.datetime_inizio) < new Date(slot.end)
  );

  let fullyOverlapping = availabilities.filter(
    (av) =>
      new Date(slot.start) <= new Date(av.datetime_inizio) &&
      new Date(slot.end) >= new Date(av.datetime_fine)
  );

  if (overlappingStart.length > 0) {
    let maxStart = overlappingStart.reduce((prev, curr) =>
      prev.datetime_fine > curr.datetime_fine ? prev : curr
    );
    slot.start = maxStart.datetime_fine;
  }
  if (overlappingEnd.length > 0) {
    let minEnd = overlappingEnd.reduce((prev, curr) =>
      prev.datetime_inizio < curr.datetime_inizio ? prev : curr
    );
    slot.end = minEnd.datetime_inizio;
  }
  if (
    fullyOverlapping.length > 0 ||
    new Date(slot.start) >= new Date(slot.end)
  ) {
    slot = null;
  }

  return slot;
}

const SpeakerDashboard = (props) => {
  const fetchUser = useContext(UserContext)?.fetch;
  const userSignout = useContext(UserContext)?.signout;
  const fetchOrg = useContext(OrgContext)?.fetch;
  const orgSignout = useContext(UserContext)?.signout;
  const colors = useContext(UserContext)?.colors;
  const user = useContext(UserContext)?.user;
  const org = useContext(OrgContext)?.org;

  const { dictionary } = useContext(LanguageContext);

  const [tabIndex, setTabIndex] = useState(0);

  const [isEditing, setIsEditing] = useState(false);

  const [speaker, setSpeaker] = useState(null);
  const [tmpImage, setTmpImage] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(null);
  const [newName, setNewName] = useState(speaker?.name);
  const [newSurname, setNewSurname] = useState(speaker?.surname);
  const [newPhone, setNewPhone] = useState(speaker?.mobile);
  const [phoneError, setPhoneError] = useState(false);
  const [newEmail, setNewEmail] = useState(speaker?.email);
  const [emailError, setEmailError] = useState(false);
  const [newDescription, setNewDescription] = useState(
    speaker?.description || ""
  );
  const [descriptionLength, setDescriptionLength] = useState(
    speaker?.description?.length || 1
  );
  const [newColor, setNewColor] = useState(speaker?.color);

  const [showEmail, setShowEmail] = useState(speaker?.show_email);
  const [showPhone, setShowPhone] = useState(speaker?.show_mobile);

  //Bool used to show a warning message above the save button if there are errors
  const [warning, setWarning] = useState(false);

  const [loader, setLoader] = useState(false);
  //Main loader used when opening the dashboard
  const [mainLoader, setMainLoader] = useState(true);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isMobile, setIsMobile] = useState(false);

  //Used to handle autmatic close of the menu
  const [isOpen, setOpen] = useState(false);

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const closeSideBar = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (windowSize.innerWidth > 800) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize.innerWidth]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [view, setView] = useState("month");
  const [dateSelected, setDateSelected] = useState(new Date());
  const [availabilities, setAvailabilities] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activitiesSelected, setActivitiesSelected] = useState(null);
  const [activitiesList, setActivitiesList] = useState([]);
  const [slot, setSlot] = useState(null);
  const [action, setAction] = useState("create");

  const [imageError, setImageError] = useState(false);

  const hiddenFileInput = React.useRef(null);

  const CustomToolbar = (toolbar) => {
    const styles = {
      toolbarContainer: {
        margin: 20,
      },
      button: {
        marginLeft: 20,
        marginRight: 20,
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        fontSize: 20,
        cursor: "pointer",
      },
      row: {
        display: "flex",
        flexDirection: "row",
        justifyContent: isMobile ? "flex-end" : "flex-start",
        margin: 0,
        visibility: view === "month" && "hidden",
      },
      back: {
        fontSize: 10,
        marginLeft: 5,
        cursor: "pointer",
      },
      buttonBack: {
        outline: "none",
        backgroundColor: "transparent",
        border: "none",
        fontSize: isMobile ? 35 : 20,
        cursor: "pointer",
      },
      arrow: {
        height: 15,
        objectFit: "contain",
      },
    };

    const goToBack = () => {
      if (view === "month") {
        toolbar.date.setMonth(toolbar.date.getMonth() - 1);
      } else if (view === "day") {
        toolbar.date.setDate(toolbar.date.getDate() - 1);
      }

      toolbar.onNavigate("prev");
    };

    const goToNext = () => {
      if (view === "month") {
        toolbar.date.setDate(1);
        toolbar.date.setMonth(toolbar.date.getMonth() + 1);
      } else if (view === "day") {
        toolbar.date.setDate(toolbar.date.getDate() + 1);
      }
      toolbar.onNavigate("next");
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <span>
          {view === "day" && <b>{date.format("DD")} </b>}
          <b>{date.format("MMMM")}</b>
          <b> {date.format("YYYY")}</b>
        </span>
      );
    };

    return (
      <>
        <div style={styles.row}>
          <img
            src={arrow}
            style={styles.arrow}
            alt={"Go back"}
            onClick={() => setView("month")}
          />
          <p
            style={{
              fontSize: 13,
              color: colors?.darkgray,
              margin: 0,
              marginLeft: 5,
              fontWeight: "600",
            }}
            onClick={() => setView("month")}
          >
            Torna al calendario
          </p>
        </div>
        <div style={styles.toolbarContainer}>
          <button style={styles.button} onClick={goToBack}>
            &#8249;
          </button>
          <label>{label()}</label>
          <button style={styles.button} onClick={goToNext}>
            &#8250;
          </button>
        </div>
      </>
    );
  };

  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    const tmpFileUploaded = e.target.files[0];
    if (tmpFileUploaded) {
      if (tmpFileUploaded.size < 1000000) {
        setFileUploaded(tmpFileUploaded);
        setTmpImage(URL.createObjectURL(tmpFileUploaded));
        setImageError(false);
      } else {
        console.log(
          "Dimensione dell'immagine scelta: " +
            (tmpFileUploaded.size / 1000000).toFixed(2) +
            "... MB (superiore a 1 MB)"
        );
        setImageError(true);
      }
    } else {
      setImageError(false);
    }
  };

  const updateSpeaker = () => {
    if (newName !== "" && newName !== null) {
      speaker.name = newName;
    }

    if (newSurname !== "" && newSurname !== null) {
      speaker.surname = newSurname;
    }

    if (newPhone !== "" && newPhone !== null) {
      speaker.mobile = newPhone;
    }

    if (newDescription !== "" && newDescription !== null) {
      speaker.description = newDescription;
    }

    if (newEmail !== "" || newEmail !== null) {
      speaker.email = newEmail;
    }

    if (newColor !== "" || newColor !== null) {
      speaker.color = newColor;
    }

    if (!emailError && !phoneError) {
      setLoader(true);
      activitiesService.updateSpeaker(speaker).then(() => {
        if (fileUploaded) {
          const formData = new FormData();
          formData.append("files", fileUploaded);
          formData.append("refId", speaker?.id);
          formData.append("ref", "relatori");
          formData.append("field", "photo");

          settingsService.upload(formData).then(() => {
            activitiesService.getSpeakerFromUser(user.id).then((speaker) => {
              setSpeaker(speaker[0]);
              setLoader(false);
              setIsEditing(false);
            });
          });
        } else {
          activitiesService.getSpeakerFromUser(user.id).then((speaker) => {
            setSpeaker(speaker[0]);
            setLoader(false);
            setIsEditing(false);
          });
        }
      });
    }
  };

  const handleChangePhone = () => {
    setShowPhone(!showPhone);
  };

  const handleChangeEmail = () => {
    setShowEmail(!showEmail);
  };

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let correct = re.test(String(email).toLowerCase());
    setEmailError(!correct);
  }

  function validatePhone(phone) {
    var numberRex = new RegExp("^[0-9]+$");
    let correct = numberRex.test(phone);
    setPhoneError(!correct);
  }

  const createAvailability = (isRepeated, endingTime) => {
    activitiesService
      .createSpeakerAvailability(
        slot.start,
        slot.end,
        speaker.id,
        activitiesSelected,
        isRepeated,
        endingTime
      )
      .then((newAvailabilities) => {
        setAvailabilities(newAvailabilities);
        setShowModal(false);
      });
  };

  const modifyAvailability = () => {
    activitiesService
      .modifySpeakerAvailability(
        slot.id,
        slot.start,
        slot.end,
        activitiesSelected
      )
      .then((newAvailabilities) => {
        setAvailabilities(newAvailabilities);
        setShowModal(false);
      });
  };

  const removeAvailability = () => {
    activitiesService
      .deleteSpeakerAvailability(slot.id)
      .then((newAvailabilities) => {
        setAvailabilities(newAvailabilities);
        setShowModal(false);
      });
  };

  const ogPhoto = speaker?.photo?.url;
  const ogName = speaker?.name;
  const ogSurname = speaker?.surname;
  const ogMobile = speaker?.mobile;
  const ogEmail = speaker?.email;
  const ogDescription = speaker?.description || "";
  const ogDescriptionLength = speaker?.description?.length || 1;
  const ogColor = speaker?.color;
  const ogShowEmail = speaker?.show_email;
  const ogShowMobile = speaker?.show_mobile;

  const resetValues = useCallback(() => {
    setTmpImage(ogPhoto);
    setFileUploaded(null);
    setNewName(ogName);
    setNewSurname(ogSurname);
    setNewPhone(ogMobile);
    setPhoneError(false);
    setNewEmail(ogEmail);
    setEmailError(false);
    setNewDescription(ogDescription);
    setDescriptionLength(ogDescriptionLength);
    setNewColor(ogColor);
    setShowEmail(ogShowEmail);
    setShowPhone(ogShowMobile);
  }, [
    ogPhoto,
    ogColor,
    ogDescription,
    ogDescriptionLength,
    ogEmail,
    ogMobile,
    ogName,
    ogSurname,
    ogShowEmail,
    ogShowMobile,
  ]);

  useEffect(() => {
    if (user) {
      activitiesService
        .getSpeakerFromUser(user.id)
        .then((speaker) => setSpeaker(speaker[0]));
    } else {
      //window.location = "http://localhost:3000/relatore/login"; //"https://emma4culture.com/relatore/login";
    }
  }, [user]);

  useEffect(() => {
    const getAvailabilities = (speaker, date) => {
      activitiesService
        .getSpeakerAvailabilities(speaker, date)
        .then((response) => {
          setAvailabilities(response);
          setMainLoader(false);
        });
    };
    const getActivities = (orgId) => {
      activitiesService.getActivities(orgId).then((response) => {
        setActivitiesList(response);
      });
    };

    if (speaker && org && view === "month") {
      getAvailabilities(speaker, dateSelected);
      getActivities(org?.id);
    }
  }, [speaker, dateSelected, org, view]);

  useEffect(() => {
    const compareDates = (first, second) => {
      return (
        first.getFullYear() === second.getFullYear() &&
        first.getMonth() === second.getMonth() &&
        first.getDate() === second.getDate()
      );
    };

    if (view === "day") {
      let tmpMax = 20;

      let tmpMin = 8;

      availabilities.forEach((element) => {
        if (
          compareDates(new Date(element.datetime_inizio), dateSelected) &&
          (new Date(element.datetime_inizio).getHours() > 20 ||
            new Date(element.datetime_fine).getHours() > 20) &&
          new Date(element.datetime_fine).getHours() > tmpMax
        ) {
          tmpMax = new Date(element.datetime_fine).getHours();
        }

        if (
          compareDates(new Date(element.datetime_inizio), dateSelected) &&
          (new Date(element.datetime_inizio).getHours() < 8 ||
            new Date(element.datetime_fine).getHours() > 8) &&
          new Date(element.datetime_fine).getHours() < tmpMin
        ) {
          tmpMin = new Date(element.datetime_inizio).getHours();
        }
      });

      maxTime.setHours(tmpMax, 0, 0);
      minTime.setHours(tmpMin, 0, 0);
    }
  }, [dateSelected, view, availabilities]);

  useEffect(() => {
    setImageError(false);
  }, [tabIndex]);

  useEffect(() => {
    resetValues();
  }, [resetValues]);

  useEffect(() => {
    fetchUser();
    fetchOrg();
  }, [fetchUser, fetchOrg]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
      height: "100vh",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      overflowX: "scroll",
    },
    rowTop: {
      alignItems: "start",
      color: colors?.primary,
      width: "100%",
    },
    rowBottom: {
      display: "flex",
      justifyContent: "center",
      width: "90%",
      height: "100%",
      overflow: "scroll",
      marginBottom: isMobile ? "20px" : "",
    },
    subcontainer: {
      width: "100%",
      paddingBottom: isMobile ? 0 : 20,
      paddingTop: 20,
    },
    goBackButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    arrow: {
      height: 15,
      objectFit: "contain",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      paddingBottom: "50px",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "center",
      alignItems: isMobile ? "center" : "",
    },
    avatar: {
      backgroundColor: colors?.gray,
      width: "250px",
      height: "250px",
    },
    label: {
      color: colors?.primary,
      fontSize: 15,
      textAlign: "left",
    },
    textInput: {
      outline: "none",
      padding: 10,
      textAlign: "left",
      width: "80%",
      border: "1px solid lightgray",
      borderRadius: 5,
    },
    saveButton: {
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: colors?.darkgray,
      margin: 30,
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 15,
    },
  };

  var menuStyles = {
    bmBurgerButton: {
      position: "fixed",
      width: "36px",
      height: "30px",
      left: "10px",
      top: "12px",
    },
    bmBurgerBars: {
      background: "#373a47",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "transparent",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },
    bmMenu: {
      background: "#373a47",
      padding: "2.5em 0em 0",
      fontSize: "1.15em",
    },
    bmMorphShape: {
      fill: "#373a47",
    },
    bmItemList: {
      color: "#white",
      width: "100%",
    },
    bmItem: {
      display: "flex",
      flexDirection: "column",
      color: "white",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },
  };

  return (
    <div style={styles.container} id="outer-container">
      {mainLoader ? (
        <ReactLoading
          type={"spinningBubbles"}
          color={colors?.primary}
          height={50}
          width={50}
          style={{
            marginTop: "50hv",
          }}
        />
      ) : (
        <div style={styles.body}>
          <div style={styles.rowTop}>
            {isMobile ? (
              <Menu
                customBurgerIcon={<img src={burgerIcon} alt="burgerIcon" />}
                customCrossIcon={
                  <img
                    src={close}
                    style={{ width: windowSize.innerWidth / 10 }}
                    alt="cross"
                  />
                }
                pageWrapId={"page-wrap"}
                outerContainerId={"outer-container"}
                styles={menuStyles}
                isOpen={isOpen}
                onOpen={handleIsOpen}
                onClose={handleIsOpen}
              >
                <div
                  style={{
                    backgroundColor: tabIndex === 0 ? colors.darkgray : "",
                    padding: "15px",
                  }}
                >
                  <b
                    id="home"
                    className="menu-item"
                    onClick={() => {
                      setTabIndex(0);
                      setShowModal(false);
                      setView("month");
                      closeSideBar();
                    }}
                  >
                    Info
                  </b>
                </div>
                <div
                  style={{
                    backgroundColor: tabIndex === 1 ? colors.darkgray : "",
                    padding: "15px",
                  }}
                >
                  <b
                    id="about"
                    className="menu-item"
                    onClick={() => {
                      setTabIndex(1);
                      setShowModal(false);
                      setView("month");
                      closeSideBar();
                    }}
                  >
                    {dictionary.availability}
                  </b>
                </div>
                <div
                  style={{
                    backgroundColor: tabIndex === 2 ? colors.darkgray : "",
                    padding: "15px",
                  }}
                >
                  <b
                    id="contact"
                    className="menu-item"
                    onClick={() => {
                      setTabIndex(2);
                      setShowModal(false);
                      setView("month");
                      closeSideBar();
                    }}
                  >
                    {dictionary.requests}
                  </b>
                </div>
                <div
                  style={{
                    backgroundColor: tabIndex === 3 ? colors.darkgray : "",
                    padding: "15px",
                  }}
                >
                  <b
                    id="contact"
                    className="menu-item"
                    onClick={() => {
                      setTabIndex(3);
                      setShowModal(false);
                      setView("month");
                      closeSideBar();
                    }}
                  >
                    Prenotazioni
                  </b>
                </div>
                <b
                  id="contact"
                  className="menu-item"
                  onClick={() => {
                    userSignout();
                    orgSignout();
                    authenticationService.logout();
                    window.location = `${process.env.REACT_APP_WEB_URL}/relatore/login/`;
                  }}
                  style={{ marginTop: "30%", marginBottom: 0 }}
                >
                  Logout
                </b>
              </Menu>
            ) : (
              <Tabs
                value={tabIndex}
                variant="fullWidth"
                TabIndicatorProps={{ style: { background: colors?.secondary } }}
                onChange={(event, newValue) => {
                  setTabIndex(newValue);
                  setShowModal(false);
                  setView("month");
                }}
              >
                <Tab label={"Info"} />
                <Tab label={dictionary.availability} />
                <Tab label={dictionary.requests} />
                <Tab label={"Prenotazioni"} />
              </Tabs>
            )}
          </div>
          <div style={styles.rowBottom} id="page-wrap">
            {tabIndex === 0 && (
              <div style={styles.subcontainer}>
                <div style={{ ...styles.row, justifyContent: "space-between" }}>
                  {isMobile && <div></div>}
                  {!isEditing && (
                    <button
                      style={styles.goBackButton}
                      onClick={() => setIsEditing(!isEditing)}
                    >
                      <img src={modify} style={styles.arrow} alt={"Modify"} />
                      <p
                        style={{
                          fontSize: 13,
                          color: colors?.darkgray,
                          margin: 0,
                          marginLeft: 5,
                          fontWeight: "600",
                        }}
                      >
                        Modifica
                      </p>
                    </button>
                  )}
                  {isEditing && (
                    <button
                      style={styles.goBackButton}
                      onClick={() => {
                        resetValues();
                        setIsEditing(false);
                      }}
                      disabled={loader}
                    >
                      <img src={arrow} style={styles.arrow} alt={"Go back"} />
                      <p
                        style={{
                          fontSize: 13,
                          color: colors?.darkgray,
                          margin: 0,
                          marginLeft: 5,
                          fontWeight: "600",
                        }}
                      >
                        Indietro
                      </p>
                    </button>
                  )}
                  {!isMobile && (
                    <button
                      style={styles.goBackButton}
                      onClick={() => {
                        userSignout();
                        orgSignout();
                        authenticationService.logout();
                        window.location = `${process.env.REACT_APP_WEB_URL}/relatore/login/`;
                      }}
                    >
                      <img src={logout} style={styles.arrow} alt={"Logout"} />
                      <p
                        style={{
                          fontSize: 13,
                          color: colors?.darkgray,
                          margin: 0,
                          marginLeft: 5,
                          fontWeight: "600",
                        }}
                      >
                        Logout
                      </p>
                    </button>
                  )}
                </div>
                <div style={isMobile ? styles.column : styles.row}>
                  <div
                    style={
                      isMobile
                        ? { marginBottom: "30px" }
                        : { paddingRight: "50px" }
                    }
                  >
                    {isEditing ? (
                      <>
                        <Badge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          badgeContent={
                            <>
                              <img
                                alt="Upload img"
                                src={upload}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "5px",
                                  backgroundColor: colors?.white,
                                  cursor: "pointer",
                                  padding: "1px",
                                }}
                                onClick={handleClick}
                              />
                              <input
                                type="file"
                                accept=".png,.jpg,.jpeg"
                                ref={hiddenFileInput}
                                onChange={handleChange}
                                style={{ display: "none" }}
                              />
                            </>
                          }
                        >
                          <Avatar
                            alt="Profile Picture"
                            src={
                              tmpImage !== null
                                ? tmpImage
                                : speaker?.photo
                                ? speaker?.photo.url
                                : ""
                            }
                            style={styles.avatar}
                            onClick={handleClick}
                          />
                          <input
                            type="file"
                            accept=".png,.jpg,.jpeg"
                            ref={hiddenFileInput}
                            onChange={handleChange}
                            style={{ display: "none", cursor: "pointer" }}
                          />
                        </Badge>
                        <div style={{ width: 250 }}>
                          {imageError && (
                            <p
                              style={{
                                ...styles.label,
                                color: colors?.highlight,
                                textAlign: "center",
                              }}
                            >
                              {
                                "L'immagine scelta non può eccedere la dimensione di 1MB"
                              }
                            </p>
                          )}
                        </div>
                      </>
                    ) : (
                      <Avatar
                        alt="Profile Picture"
                        src={
                          tmpImage !== null
                            ? tmpImage
                            : speaker?.photo
                            ? speaker?.photo.url
                            : ""
                        }
                        style={styles.avatar}
                        sx={{ width: "300px", height: "300px" }}
                      />
                    )}
                  </div>
                  <div style={styles.column}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 3,
                        }}
                      >
                        <p style={styles.label}>{dictionary.name}</p>
                        <input
                          type="text"
                          name="speakerName"
                          autoComplete="on"
                          value={newName}
                          disabled={!isEditing}
                          onChange={(event) => {
                            setNewName(event.target.value);
                          }}
                          style={styles.textInput}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 3,
                        }}
                      >
                        <p style={styles.label}>{dictionary.surname}</p>
                        <input
                          type="text"
                          name="speakerSurname"
                          autoComplete="on"
                          value={newSurname}
                          disabled={!isEditing}
                          onChange={(event) => {
                            setNewSurname(event.target.value);
                          }}
                          style={styles.textInput}
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 3,
                        }}
                      >
                        {phoneError ? (
                          <p
                            style={{
                              ...styles.label,
                              color: colors?.highlight,
                            }}
                          >
                            {" "}
                            Numero non valido{" "}
                          </p>
                        ) : (
                          <p style={styles.label}>{dictionary.phone}</p>
                        )}
                        <input
                          type="text"
                          name="speakerPhone"
                          autoComplete="on"
                          value={newPhone}
                          disabled={!isEditing}
                          onChange={(event) => {
                            setNewPhone(event.target.value);
                            validatePhone(event.target.value);
                          }}
                          style={styles.textInput}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 3,
                        }}
                      >
                        {emailError ? (
                          <p
                            style={{
                              ...styles.label,
                              color: colors?.highlight,
                            }}
                          >
                            Email non valida
                          </p>
                        ) : (
                          <p style={styles.label}>Email</p>
                        )}
                        <input
                          type="text"
                          name="speakerEmail"
                          autoComplete="on"
                          value={newEmail}
                          disabled={!isEditing}
                          onChange={(event) => {
                            setNewEmail(event.target.value);
                            validateEmail(event.target.value);
                          }}
                          style={styles.textInput}
                        />
                      </div>
                    </div>
                    <div
                      style={
                        isMobile
                          ? { ...styles.row, paddingBottom: 0 }
                          : styles.row
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flex: 3,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginLeft: "5px",
                          }}
                        >
                          <input
                            type="checkbox"
                            onChange={handleChangePhone}
                            checked={showPhone}
                            disabled={!isEditing}
                          />
                          <p
                            style={{
                              ...styles.label,
                              width: "100%",
                              marginLeft: 10,
                              color: colors?.darkgray,
                            }}
                          >
                            {dictionary.showPhone}
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 3,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginLeft: "5px",
                          }}
                        >
                          <input
                            type="checkbox"
                            onChange={handleChangeEmail}
                            checked={showEmail}
                            disabled={!isEditing}
                          />
                          <p
                            style={{
                              ...styles.label,
                              width: "100%",
                              marginLeft: 10,
                              color: colors?.darkgray,
                            }}
                          >
                            {dictionary.showEmail}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={
                    isMobile
                      ? { ...styles.column, alignItems: "start" }
                      : { ...styles.row, paddingBottom: 10 }
                  }
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: isMobile ? "95%" : "76%",
                    }}
                  >
                    <p style={styles.label}>{dictionary.presentation}</p>
                    <textarea
                      value={newDescription}
                      disabled={!isEditing}
                      onChange={(event) => {
                        setNewDescription(event.target.value.replace("\n", ""));
                        setDescriptionLength(event.target.value.length);
                      }}
                      style={{
                        ...styles.textInput,
                        width: "93%",
                        resize: "none",
                        fontFamily: "inherit",
                      }}
                      rows={
                        isMobile
                          ? Math.ceil(descriptionLength / 100) + 1
                          : Math.ceil(descriptionLength / 100)
                      }
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 3,
                      width: isMobile ? "95%" : "",
                    }}
                  >
                    <p style={styles.label}>{dictionary.color}</p>
                    {isEditing ? (
                      <>
                        {isMobile ? (
                          <SwatchesPicker
                            color={newColor || "#fff"}
                            onChange={(e) => setNewColor(e.hex)}
                          />
                        ) : (
                          <ChromePicker
                            color={newColor || "#fff"}
                            onChange={(e) => setNewColor(e.hex)}
                          />
                        )}
                      </>
                    ) : (
                      <div
                        style={{
                          backgroundColor: colors?.darkgray,
                          height: 22,
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: newColor,
                            height: 20,
                            width: "99%",
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {isEditing && (
                  <div style={{ ...styles.column, alignItems: "center" }}>
                    {warning && (
                      <p style={{ ...styles.label, color: colors?.highlight }}>
                        {dictionary.checkErrors}
                      </p>
                    )}
                    <div
                      style={
                        isMobile
                          ? { ...styles.row, paddingBottom: 0 }
                          : styles.row
                      }
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {!loader && (
                          <button
                            style={{
                              ...styles.saveButton,
                              backgroundColor: colors?.darkgray,
                            }}
                            onClick={() => {
                              //We declare a duplicate of the bool to avoid errors caused by the delay of the state update. We don't want to enter the last if in case of errors.
                              var tmpWarning = warning;

                              if (emailError || phoneError) {
                                tmpWarning = true;
                                setWarning(true);
                              } else {
                                tmpWarning = false;
                                setWarning(false);
                              }

                              if (!tmpWarning) {
                                updateSpeaker();
                              }
                            }}
                          >
                            <p
                              style={{
                                fontSize: 12,
                                color: colors?.white,
                                margin: 0,
                                fontWeight: "600",
                              }}
                            >
                              {dictionary.save}
                            </p>
                          </button>
                        )}
                        {loader && (
                          <ReactLoading
                            type={"spinningBubbles"}
                            color={colors?.primary}
                            height={50}
                            width={50}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {tabIndex === 1 && (
              <div
                style={{
                  backgroundColor: "#FFFFFF",
                  display: "flex",
                  height: "100%",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    height: "90%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end",
                  }}
                >
                  <Calendar
                    date={moment(dateSelected).toDate()}
                    localizer={localizer}
                    events={availabilities}
                    messages={{
                      next: "Successivo",
                      previous: "Precedente",
                      today: "Oggi",
                      month: "Mese",
                      week: "Settimana",
                      day: "Giorno",
                      showMore: (eventCount) =>
                        window.innerWidth <= 800
                          ? `+ ${eventCount}`
                          : `+ ${eventCount} attività`,
                    }}
                    titleAccessor={(event) => {
                      if (view === "day") {
                        let activities = event.attivitas.map((act) => {
                          return act.nome;
                        });
                        return activities.toString().replace(",", ", ");
                      } else {
                        if (event.attivitas.length > 1) {
                          return event.attivitas.length + " attività";
                        } else {
                          return event.attivitas[0]?.nome;
                        }
                      }
                    }}
                    startAccessor={(event) => {
                      return new Date(event.datetime_inizio);
                    }}
                    endAccessor={(event) => {
                      return new Date(event.datetime_fine);
                    }}
                    style={{
                      height: "95%",
                      width: "100%",
                    }}
                    components={{
                      toolbar: CustomToolbar,
                    }}
                    view={view}
                    defaultView={view}
                    min={minTime}
                    max={maxTime}
                    onNavigate={(date) => setDateSelected(new Date(date))}
                    popup={true}
                    onSelectEvent={(event) => {
                      setDateSelected(new Date(event.datetime_inizio));
                      if (view === "month") {
                        setView("day");
                      } else {
                        if (new Date(event.datetime_inizio) >= new Date()) {
                          setAction("modify");
                          setShowModal(true);
                          setSlot(event);
                          setActivitiesSelected(event.attivitas);
                        }
                      }
                      //setAvailabilitySelected(event);
                    }}
                    onDrillDown={(date) => {
                      if (new Date(date) >= new Date()) {
                        if (view === "month") {
                          setView("day");
                        }
                        setDateSelected(date);
                      }
                    }}
                    selectable={view === "day"}
                    onSelectSlot={(slot) => {
                      if (new Date(slot.start) >= new Date()) {
                        setAction("create");
                        let newSlot = checkOverlappingAvailabilities(
                          availabilities,
                          slot
                        );
                        if (newSlot) {
                          setSlot(newSlot);
                          setShowModal(true);
                        }
                      }
                    }}
                    eventPropGetter={(event, start, end, isSelected) => {
                      let newStyle = {
                        backgroundColor: colors?.green,
                        color: colors?.darkgray,
                        borderRadius: 5,
                        outline: "none",
                        borderTop: "1px solid",
                        borderBottom: "1px solid",
                        borderRightWidth:
                          window.innerWidth > 800 ? "8px solid" : "0",
                        borderLeftWidth:
                          window.innerWidth > 800 ? "8px solid" : "0",
                        borderColor: colors?.green,
                        fontSize: 13,
                      };
                      return {
                        className: "",
                        style: newStyle,
                      };
                    }}
                    dayPropGetter={(day) => {
                      let newStyle = {
                        backgroundColor: "#f6f6f6",
                        color: colors.white,
                        opacity: 1,
                      };
                      if (moment(day) < moment()) {
                        return {
                          className: "",
                          style: newStyle,
                        };
                      } else {
                        return null;
                      }
                    }}
                  />
                </div>
              </div>
            )}
            {tabIndex === 2 && (
              <RequestsManager
                colors={colors}
                speaker={speaker}
                org={org}
                isMobile={isMobile}
              />
            )}
            {tabIndex === 3 && (
              <Reservations
                colors={colors}
                speaker={speaker}
                isMobile={isMobile}
              />
            )}
          </div>
        </div>
      )}

      {showModal && (
        <Modal
          action={action}
          slot={slot}
          activitiesList={activitiesList}
          activitiesSelected={activitiesSelected}
          setActivitiesSelected={setActivitiesSelected}
          setShowModal={setShowModal}
          create={createAvailability}
          modify={modifyAvailability}
          remove={removeAvailability}
          checkOverlappingAvailabilities={(slot) =>
            checkOverlappingAvailabilities(availabilities, slot)
          }
        />
      )}
    </div>
  );
};

export default SpeakerDashboard;
