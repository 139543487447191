import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useRef,
} from "react";
import moment from "moment";
import { reportService, usersService } from "../../_services";
import ReactLoading from "react-loading";
import DownloadButton from "../../components/buttons/downloadButton";
import ShareButton from "../../components/buttons/shareButton";
import Sale from "../../components/sale";
import { UserContext, OrgContext } from "../../contexts";
import arrowDown from "./../../img/arrow_down.png";
import refresh from "./../../img/refresh.png";
import arrowUp from "./../../img/arrow_up.png";
import "./../../App.css";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Report = ({ iframe }) => {
  const [report, setReport] = useState(undefined);
  const [monthReport, setMonthReport] = useState(undefined);
  const [loader, setLoader] = useState(true);
  const [filter, setFilter] = useState(-1);
  const [museumList, setMuseumList] = useState([]);
  const [showPerformance, setShowPerformance] = useState(false);
  const [selectedCategoryPerformance, setSelectedCategoryPerformance] =
    useState(null);
  const user = useContext(UserContext)?.user;
  const org = useContext(OrgContext)?.org;
  const colors = useContext(UserContext)?.colors;
  const prevUser = usePrevious(user);
  const prevOrg = usePrevious(org);
  const prevFilter = usePrevious(filter);
  const labels = [
    "Articolo",
    "Categoria",
    "Quantità",
    "Prezzo",
    "Data",
    "Metodo di pagamento",
    user?.flag_network ? "Museo" : "Operatore",
  ];

  //Rotate is the flag to handle loading img rotation when refreshing
  const [rotate, setRotate] = useState(false);
  const [refreshMsg, setRefreshMsg] = useState("Sincronizza");

  const checkCurrentCategory = (key) => {
    if (
      showPerformance &&
      selectedCategoryPerformance !== null &&
      selectedCategoryPerformance.key === key
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkAnyPerformance = (performanceObject) => {
    if (
      performanceObject !== undefined &&
      Object.keys(performanceObject).length !== 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getMuseums = () => {
    usersService
      .getMuseums()
      .then((list) => setMuseumList(list))
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  const getPdf = () => {
    setLoader(true);
    reportService
      .getReportPdf(
        org,
        filter,
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      )
      .then((file) => {
        setLoader(false);
        var a = document.createElement("a");
        a.href = file;
        a.target = "_blank";
        a.download =
          "report_" + moment(new Date()).format("DD_MM_yyyy") + ".pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  const closeSales = () => {
    setRefreshMsg("Sincronizzazione in corso...");
    setRotate(true);
    reportService
      .closeSalesVivaticket()
      .then(() => {
        let now = new Date();
        getTodayReport();
        getCurrentMonthReport();
        setRefreshMsg(
          "Ultima sincronizzazione " +
            now.getHours().toString() +
            ":" +
            now.getMinutes().toString()
        );
        setRotate(false);
      })
      .catch((errors) => {
        setRefreshMsg("Errore di sincronizzazione");
        setRotate(false);
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  const getExcel = () => {
    setLoader(true);
    reportService
      .getReportExcel(
        org,
        filter,
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD")
      )
      .then((file) => {
        setLoader(false);
        var a = document.createElement("a");
        a.href = file;
        a.download =
          "report_" + moment(new Date()).format("DD_MM_yyyy") + ".xlsx";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  const getTodayReport = useCallback(() => {
    setLoader(true);
    reportService
      .getTodayReport(org, filter)
      .then((report) => {
        setReport(report);
        setLoader(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentMonthReport = useCallback(() => {
    setLoader(true);
    reportService
      .getVisitorsData(
        org,
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
        filter
      )
      .then((ret) => {
        setMonthReport(ret);
        setLoader(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (prevUser?.flag_network !== user?.flag_network) {
      if (user?.flag_network) {
        getMuseums();
      } else {
        setFilter(-1);
      }
      getTodayReport();
      getCurrentMonthReport();
    }

    if (
      (prevFilter !== filter && prevFilter !== undefined) ||
      (prevOrg?.id !== org.id && prevOrg !== undefined)
    ) {
      getTodayReport();
      getCurrentMonthReport();
    }
  }, [
    user,
    prevUser,
    filter,
    prevFilter,
    org,
    prevOrg,
    getTodayReport,
    getCurrentMonthReport,
  ]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      width: iframe ? "100%" : "85%",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors?.white,
      position: "absolute",
      right: 0,
      overflow: "hidden",
    },
    upSection: {
      display: "flex",
      flexDirection: "row",
      width: "99%",
      paddingLeft: "15px",
      paddingRight: "15px",
    },
    centerSection: {
      display: "flex",
      flexDirection: "row",
      width: "99%",
      overflowX: "scroll",
    },
    bottomSection: {
      flex: 5,
      overflowX: "scroll",
      display: "flex",
      flexDirection: "column",
      flexWrap: "wrap",
      width: "80%",
      alignItems: "right",
    },
    box: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: colors?.secondary,
      borderRadius: 20,
      marginTop: 10,
      marginBottom: 5,
      marginLeft: 5,
      marginRight: 5,
      alignItems: "center",
      justifyContent: "center",
      paddingTop: "15px",
      paddingBottom: "15px",
    },
    brownBox: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: colors?.primary,
      borderRadius: 20,
      margin: 5,
      alignItems: "center",
      justifyContent: "space-around",
      width: "100%",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    grayBox: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      backgroundColor: colors?.darkgray,
      borderRadius: 20,
      margin: 5,
      marginTop: 0,
      alignItems: "center",
      justifyContent: "space-around",
      width: "100%",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    label: {
      color: colors?.white,
      fontSize: 15,
      margin: "1px",
      marginBottom: "10px",
    },
    value: {
      color: colors?.white,
      fontSize: 20,
      fontWeight: "700",
      margin: "1px",
    },
    scrollerX: {
      overflowX: "scroll",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    labelTable: {
      width: 170,
      fontSize: 12,
      color: colors?.lightgray,
    },
    header: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      marginTop: 10,
    },
    menu: {
      border: "1px solid lightgray",
      width: "100%",
      padding: 10,
      color: colors?.darkgray,
      borderRadius: 10,
      backgroundColor: colors?.white,
      outline: "none",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "90%",
      alignItems: "center",
      justifyContent: "flex-end",
      marginTop: 20,
      marginBottom: 5,
    },
    filterButton: {
      border: "1px solid #FFFFFF",
      backgroundColor: "#FFFFFF",
      outline: "none",
      cursor: "pointer",
      borderRadius: 40,
      height: 0,
      padding: 10,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
    },
  };

  return loader ? (
    <div style={styles.container}>
      <ReactLoading
        type={"spinningBubbles"}
        color={colors?.primary}
        height={50}
        width={50}
      />
    </div>
  ) : (
    <div style={styles.container}>
      {org && org.settings && org.settings.viva_ticket ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            alignItems: "center",
            marginRight: "30px",
            marginTop: "10px",
          }}
        >
          <p style={{ ...styles.labelTable, width: "" }}>{refreshMsg}</p>
          <button
            style={{
              ...styles.filterButton,
            }}
            className={rotate && "image-container rotating"}
            onClick={() => {
              closeSales();
            }}
          >
            <img
              alt="Refresh icon"
              src={refresh}
              style={{ width: 15, objectFit: "contain", margin: 1 }}
            />
          </button>
        </div>
      ) : null}
      {user?.flag_network && (
        <select
          name="filter"
          style={{ ...styles.menu, width: "85%", marginTop: 25 }}
          value={filter}
          onChange={(event) => setFilter(parseInt(event.target.value))}
        >
          <option value={-1}>{"Tutti i musei"}</option>
          {museumList.map((museum, key) => {
            return (
              <option key={key} value={museum.id}>
                {museum.nome}
              </option>
            );
          })}
        </select>
      )}
      <div style={styles.upSection}>
        <div style={styles.box}>
          <p style={styles.label}>Totale giornaliero</p>

          <p style={styles.value}>
            € {report?.revenue > 0 ? report?.revenue?.toFixed(2) : "0.00"}
          </p>
        </div>
        <div style={styles.box}>
          <p style={styles.label}>Totale mensile</p>

          <p style={styles.value}>
            €{" "}
            {monthReport?.revenue > 0
              ? monthReport?.revenue?.toFixed(2)
              : "0.00"}
          </p>
        </div>
        <div style={styles.box}>
          <p style={styles.label}>Media giornaliera</p>

          <p style={styles.value}>
            €{" "}
            {monthReport?.revenue > 0
              ? (monthReport?.revenue / monthReport?.numberOfDays)?.toFixed(2)
              : "0.00"}
          </p>
        </div>
        <div style={styles.box}>
          <p style={styles.label}>Previsionale mensile</p>

          <p style={styles.value}>
            €{" "}
            {monthReport?.revenue > 0
              ? (
                  (monthReport?.revenue / monthReport?.numberOfDays) *
                  monthReport?.numberOfDaysOfCurrentMonth
                ).toFixed(2)
              : "0.00"}
          </p>
        </div>
      </div>
      <div style={styles.centerSection} id="scroll">
        {report !== undefined &&
          Object.keys(report.categories).map((key, index) => {
            return (
              <div
                style={{
                  ...styles.brownBox,
                  cursor: checkAnyPerformance(
                    report.categories[key].performances
                  )
                    ? "pointer"
                    : "default",
                  opacity:
                    !showPerformance || checkCurrentCategory(key) ? 1 : 0.4,
                }}
                onClick={(e) => {
                  if (
                    checkAnyPerformance(report.categories[key].performances)
                  ) {
                    if (selectedCategoryPerformance === null) {
                      let tempObj = report.categories[key];
                      tempObj.key = key;
                      setSelectedCategoryPerformance(tempObj);
                    } else {
                      setSelectedCategoryPerformance(null);
                    }
                    setShowPerformance(!showPerformance);
                  }
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <p style={styles.label}>{key}</p>
                  {checkAnyPerformance(report.categories[key].performances) && (
                    <img
                      alt="Arrow icon"
                      src={checkCurrentCategory(key) ? arrowUp : arrowDown}
                      style={{
                        ...styles.label,
                        width: 10,
                        objectFit: "contain",
                        paddingLeft: 3,
                      }}
                    />
                  )}
                </div>
                <p style={styles.value}>{report.categories[key].tot}</p>
                <p style={styles.value}>
                  € {report.categories[key].incassi.toFixed(2)}
                </p>
              </div>
            );
          })}
      </div>
      {showPerformance && (
        <div
          style={{
            ...styles.centerSection,
            marginBottom: 10,
          }}
          id="scroll"
        >
          {selectedCategoryPerformance !== null &&
            Object.keys(selectedCategoryPerformance.performances).map(
              (key, index) => {
                return (
                  <div style={styles.brownBox}>
                    <p style={styles.label}>{key}</p>
                    <p style={styles.value}>
                      {selectedCategoryPerformance.performances[key].tot}
                    </p>
                    <p style={styles.value}>
                      €{" "}
                      {selectedCategoryPerformance.performances[
                        key
                      ].incassi.toFixed(2)}
                    </p>
                  </div>
                );
              }
            )}
        </div>
      )}
      <div style={styles.centerSection} id="scroll">
        {report !== undefined &&
          Object.keys(report.paymentMethods).map((key, index) => {
            return (
              <div
                style={{
                  ...styles.grayBox,
                  opacity: showPerformance ? 0.4 : 1,
                }}
              >
                <p style={styles.label}>{key}</p>
                <p style={styles.value}>
                  € {report.paymentMethods[key].incassi.toFixed(2)}
                </p>
              </div>
            );
          })}
      </div>
      <div
        style={
          report?.totalSell === undefined || report?.totalSell.length === 0
            ? styles.bottomSection
            : {
                ...styles.bottomSection,
                alignContent: "space-between",
                justifyContent: "flex-start",
              }
        }
      >
        <div style={styles.scrollerX}>
          <div style={styles.header}>
            {report?.totalSell === undefined ||
            report?.totalSell.length === 0 ? (
              <p style={{ ...styles.labelTable, width: "100%" }}>
                Nessuna vendita relativa ad oggi
              </p>
            ) : (
              labels.map((label, key) => {
                if (label === "Articolo") {
                  return (
                    <div style={{ ...styles.labelTable, width: 200 }} key={key}>
                      <p>{label}</p>
                    </div>
                  );
                } else if (label === "Prezzo" || label === "Quantità") {
                  return (
                    <div style={{ ...styles.labelTable, width: 80 }} key={key}>
                      <p>{label}</p>
                    </div>
                  );
                } else if (label === "Metodo di pagamento") {
                  return (
                    <div style={{ ...styles.labelTable, width: 140 }} key={key}>
                      <p>{label}</p>
                    </div>
                  );
                } else {
                  return (
                    <div style={styles.labelTable} key={key}>
                      <p>{label}</p>
                    </div>
                  );
                }
              })
            )}{" "}
          </div>
          {report?.totalSell?.map((sale, key) => {
            return <Sale key={key + "_sale"} sale={sale} />;
          })}
        </div>
      </div>
      <DownloadButton getPdf={getPdf} getExcel={getExcel} />
      <ShareButton report={report} monthReport={monthReport} />
    </div>
  );
};

export default Report;
