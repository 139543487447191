import React, { useState, useEffect, useRef } from "react";
import colors from "./../../../../museumColors";
import show from "./../../../../img/show.png";
import upload from "./../../../../img/uploadimg_black.png";
import { Avatar, Badge } from "@material-ui/core";
import ReactLoading from "react-loading";
import { ChromePicker } from "react-color";
import {
  activitiesService,
  settingsService,
  usersService,
} from "../../../../_services";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const SignupForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionLength, setDescriptionLength] = useState("");
  const [color, setColor] = useState("#FFFFFF");
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState("");
  const [tmpImage, setTmpImage] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(null);
  const [emailAlreadyUsed, setEmailAlreadyUsed] = useState(false);
  const [wrongOrgEmail, setWrongOrgEmail] = useState(false);
  const [userId, setUserId] = useState(null);

  const [imageError, setImageError] = useState(false);

  //We divide the signup flow into steps for better readability
  const [step, setStep] = useState(1);

  const prevMandatory = usePrevious(props.isMandatory);

  useEffect(() => {
    if (!prevMandatory && props.isMandatory) {
      setStep(1);
    }
  }, [prevMandatory, props.isMandatory]);

  const [completedMessage, setCompletedMessage] = useState("");

  //The ID of the speaker role on strapi
  const [role, setRole] = useState("");

  //The id of the museums'network
  const netId = "";

  const [loader, setLoader] = useState(false);

  const hiddenFileInput = React.useRef(null);

  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    const tmpFileUploaded = e.target.files[0];
    if (tmpFileUploaded) {
      if (tmpFileUploaded.size < 1000000) {
        setFileUploaded(tmpFileUploaded);
        setTmpImage(URL.createObjectURL(tmpFileUploaded));
        setImageError(false);
      } else {
        console.log(
          "Dimensione dell'immagine scelta: " +
            (tmpFileUploaded.size / 1000000).toFixed(2) +
            "... MB (superiore a 1 MB)"
        );
        setImageError(true);
      }
    } else {
      setImageError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const nextStep = () => {
    if (step === 1) {
      if (
        name === "" ||
        surname === "" ||
        (!props.isMandatory && email === "")
      ) {
        setShowError(true);
        setMessage("Compila tutti i campi!");
      } else if (!props.isMandatory && validateEmail(email)) {
        setShowError(true);
        setMessage("Inserire un'email valida");
      } else if (emailAlreadyUsed) {
        if (wrongOrgEmail) {
          setShowError(true);
          setMessage("Email collegata a organizzazione esterna");
        } else {
          setShowError(false);
          setStep(step + 2);
        }
      } else {
        setShowError(false);
        if (props.isMandatory) {
          setStep(step + 2);
        } else {
          setStep(step + 1);
        }
      }
    } else if (step === 2) {
      if (!props.isMandatory && (password === "" || repeatedPassword === "")) {
        setShowError(true);
        setMessage("Compila tutti i campi!");
      } else if (!props.isMandatory && password !== repeatedPassword) {
        setShowError(true);
        setMessage("Le password non coincidono!");
      } else {
        setShowError(false);
        setStep(step + 1);
      }
    } else if (step === 3) {
      if (phoneNumber === "" || fileUploaded === null) {
        setShowError(true);
        setMessage("Inserire una foto e un numero di telefono");
      } else if (validatePhone(phoneNumber)) {
        setShowError(true);
        setMessage("Inserire un numero di telefono valido");
      } else {
        setLoader(true);
        setShowError(false);
        if (userId != null) {
          createSpeakerFromExistingUser();
        } else {
          createSpeaker();
        }
      }
    }
  };

  const createSpeaker = () => {
    var speaker = [];

    speaker.name = name;
    speaker.surname = surname;
    speaker.mobile = phoneNumber;
    speaker.description = description;
    if (!props.isMandatory) {
      speaker.email = email;
    } else {
      speaker.email = props.user.email;
    }

    speaker.color = color;
    speaker.show_email = false;
    speaker.show_mobile = false;

    if (!props.isMandatory) {
      let username = name + " " + surname + " Relatore";
      let user = null;
      usersService
        .createUser(username, email, password, role, props.orgId, netId)
        .then((response) => {
          user = response[response.length - 1];
          activitiesService
            .createSpeaker(speaker, user)
            .then((response) => {
              if (fileUploaded) {
                const formData = new FormData();
                formData.append("files", fileUploaded);
                formData.append("refId", response.id);
                formData.append("ref", "relatori");
                formData.append("field", "photo");

                settingsService.upload(formData).then(() => {
                  setLoader(false);
                  setStep(step + 1);
                  setCompletedMessage(
                    "Fai click sul link di conferma ricevuto per email"
                  );
                });
              } else {
                setLoader(false);
                setStep(step + 1);
                setCompletedMessage(
                  "Fai click sul link di conferma ricevuto per email"
                );
              }
            })
            .catch((errors) => {
              if (errors.statusCode === 401 || errors.statusCode === 403) {
                this.props.history.push("/");
              } else {
                // toggleEmailPopup()
                // setEmailTaken(true);
                setLoader(false);
              }
            });
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            this.props.history.push("/");
          } else {
            // toggleEmailPopup()
            // setEmailTaken(true);
            setLoader(false);
          }
        });
    } else {
      activitiesService
        .createSpeaker(speaker, props.user)
        .then((response) => {
          if (fileUploaded) {
            const formData = new FormData();
            formData.append("files", fileUploaded);
            formData.append("refId", response.id);
            formData.append("ref", "relatori");
            formData.append("field", "photo");

            settingsService.upload(formData).then(() => {
              setLoader(false);
              setStep(step + 1);
              props.setIsMandatory();
              setCompletedMessage(
                "Accedi utilizzando le credenziali del tuo account"
              );
            });
          } else {
            setLoader(false);
            setStep(step + 1);
            props.setIsMandatory();
            setCompletedMessage(
              "Accedi utilizzando le credenziali del tuo account"
            );
          }
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            this.props.history.push("/");
          } else {
            // toggleEmailPopup()
            // setEmailTaken(true);
            setLoader(false);
          }
        });
    }
  };

  const createSpeakerFromExistingUser = () => {
    var speaker = [];

    speaker.name = name;
    speaker.surname = surname;
    speaker.mobile = phoneNumber;
    speaker.description = description;
    if (!props.isMandatory) {
      speaker.email = email;
    } else {
      speaker.email = props.user.email;
    }

    speaker.color = color;
    speaker.show_email = false;
    speaker.show_mobile = false;

    if (userId != null) {
      activitiesService
        .createSpeaker(speaker, userId)
        .then((response) => {
          if (fileUploaded) {
            const formData = new FormData();
            formData.append("files", fileUploaded);
            formData.append("refId", response.id);
            formData.append("ref", "relatori");
            formData.append("field", "photo");

            settingsService.upload(formData).then(() => {
              setLoader(false);
              setStep(step + 1);
              props.setIsMandatory();
              setCompletedMessage(
                "Accedi utilizzando le credenziali del tuo account"
              );
            });
          } else {
            setLoader(false);
            setStep(step + 1);
            props.setIsMandatory();
            setCompletedMessage(
              "Accedi utilizzando le credenziali del tuo account"
            );
          }
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            this.props.history.push("/");
          } else {
            // toggleEmailPopup()
            // setEmailTaken(true);
            setLoader(false);
          }
        });
    } else {
      this.props.history.push("/");
      setLoader(false);
    }
  };

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let correct = re.test(String(email).toLowerCase());

    return !correct;
  }

  function validatePhone(phone) {
    var numberRex = new RegExp("^[0-9]+$");
    let correct = numberRex.test(phone);

    return !correct;
  }

  useEffect(() => {
    usersService.getUserFromEmail(email).then((response) => {
      if (Object.keys(response).length > 0) {
        setEmailAlreadyUsed(true);
        let userOrgid = null;
        if (response.user.flag_network) {
          userOrgid = response.user.org_rete;
        } else {
          userOrgid = response.user.org_museo;
        }

        if (userOrgid !== parseInt(props.orgId)) {
          setWrongOrgEmail(true);
        } else {
          setUserId(response.user);
          setWrongOrgEmail(false);
        }
      } else {
        setUserId(null);
        setEmailAlreadyUsed(false);
        setWrongOrgEmail(false);
      }
      return false;
    });
  }, [email, props.orgId]);

  useEffect(() => {
    usersService.getRoles().then((response) => {
      response.roles.forEach((ruolo) => {
        if (ruolo.name === "Relatore") {
          setRole(ruolo.id);
        }
      });
    });
  }, []);

  const styles = {
    content: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    textInput: {
      outline: "none",
      padding: 10,
      textAlign: "left",
      width: "50%",
      border: "1px solid lightgray",
      borderRadius: 5,
      fontFamily: "inherit",
      resize: "none",
      backgroundColor: colors.quaternary,
    },
    field: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      marginTop: 20,
    },
    label: {
      color: colors.primary,
      width: "30%",
      marginRight: 40,
    },
    password: {
      color: colors.primary,
      fontStyle: "italic",
      fontSize: 13,
    },
    message: {
      color: colors.white,
      fontWeight: 700,
      fontSize: 13,
      width: "100%",
      backgroundColor: "rgba(277,277,277,0.3)",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    avatar: {
      backgroundColor: colors.gray,
      width: "200px",
      height: "200px",
      marginTop: 20,
    },
  };

  return (
    <form
      style={{
        width: props.isMobile ? "90%" : props.isMandatory ? "80%" : "50%",
        height: props.isMobile ? "100%" : "87%",
        marginTop: props.isMobile ? 10 : 20,
        marginRight: props.isMobile ? 0 : 20,
        marginBottom: props.isMobile ? "15%" : 0,
        backgroundColor: colors.white,
        padding: 30,
        overflowY: "scroll",
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        // justifyContent: "space-between",
        alignItems: "center",
      }}
      onSubmit={handleSubmit}
      id="scroll"
    >
      {step !== 4 && (
        <>
          <h1 style={{ color: colors.primary }}>
            {props.isMandatory
              ? "Crea un profilo relatore"
              : "Vuoi creare un account da relatore?"}
          </h1>
          <h3 style={{ color: colors.secondary, marginTop: 0 }}>
            Compila i campi sottostanti
          </h3>
          <h4 style={{ color: colors.primary, marginBottom: 0, marginTop: 0 }}>
            {step === 1 || step === 2
              ? "Informazioni personali"
              : "Informazioni Aggiuntive"}
          </h4>
          <div style={{ height: "100%" }}></div>
        </>
      )}
      {step === 1 && (
        <>
          <div style={styles.field}>
            <p style={styles.label}>Nome</p>
            <input
              type="text"
              name="name"
              autoComplete="on"
              value={name}
              onChange={(event) => setName(event.target.value)}
              placeholder="Nome"
              style={styles.textInput}
            />
          </div>
          <div style={styles.field}>
            <p style={styles.label}>Cognome</p>
            <input
              type="text"
              name="surname"
              autoComplete="on"
              value={surname}
              onChange={(event) => setSurname(event.target.value)}
              placeholder="Cognome"
              style={styles.textInput}
            />
          </div>
          {!props.isMandatory && (
            <>
              <div style={styles.field}>
                <p style={styles.label}>Email</p>
                <input
                  type="email"
                  name="email"
                  autoComplete="on"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value.toLowerCase());
                  }}
                  placeholder="Email"
                  style={styles.textInput}
                />
              </div>
            </>
          )}
        </>
      )}
      {step === 2 && (
        <>
          {!props.isMandatory && (
            <>
              <div style={styles.field}>
                <p style={styles.label}>Password</p>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  autoComplete="on"
                  value={password || ""}
                  onChange={(event) => setPassword(event.target.value)}
                  placeholder="Password"
                  style={styles.textInput}
                />
                {password !== "" && (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPassword(!showPassword);
                    }}
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      outline: "none",
                      cursor: "pointer",
                      marginLeft: -50,
                    }}
                  >
                    <img
                      alt="Show password"
                      src={show}
                      style={{ width: 20, objectFit: "contain" }}
                    />
                  </button>
                )}
              </div>
              <div style={styles.field}>
                <p style={styles.label}>Ripeti Password</p>
                <input
                  type={showPassword ? "text" : "password"}
                  name="repeatedPassword"
                  autoComplete="on"
                  value={repeatedPassword || ""}
                  onChange={(event) => setRepeatedPassword(event.target.value)}
                  placeholder="Ripeti Password"
                  style={styles.textInput}
                />
                {repeatedPassword !== "" && (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPassword(!showPassword);
                    }}
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      outline: "none",
                      cursor: "pointer",
                      marginLeft: -50,
                    }}
                  >
                    <img
                      alt="Show password"
                      src={show}
                      style={{ width: 20, objectFit: "contain" }}
                    />
                  </button>
                )}
              </div>
            </>
          )}
        </>
      )}
      {step === 3 && (
        <>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <>
                <img
                  alt="Upload img"
                  src={upload}
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "5px",
                    backgroundColor: colors.white,
                    cursor: "pointer",
                    padding: "1px",
                  }}
                  onClick={handleClick}
                />
                <input
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
              </>
            }
          >
            <Avatar
              alt="Profile Picture"
              src={tmpImage !== null ? tmpImage : ""}
              style={styles.avatar}
              onClick={handleClick}
            />
            <input
              type="file"
              accept=".png,.jpg,.jpeg"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none", cursor: "pointer" }}
            />
          </Badge>
          {imageError && (
            <div style={styles.message}>
              <p style={{ color: colors.red }}>
                {"L'immagine scelta non può eccedere la dimensione di 1MB"}
              </p>
            </div>
          )}
          <div style={styles.field}>
            <p style={styles.label}>Numero di telefono</p>
            <input
              type="text"
              name="speakerPhone"
              autoComplete="on"
              value={phoneNumber}
              onChange={(event) => {
                setPhoneNumber(event.target.value);
              }}
              placeholder="Numero di telefono"
              style={styles.textInput}
            />
          </div>
          <div style={styles.field}>
            <p style={styles.label}>Presentazione</p>
            <textarea
              value={description}
              onChange={(event) => {
                setDescription(event.target.value.replace("\n", ""));
                setDescriptionLength(event.target.value.length);
              }}
              style={{
                ...styles.textInput,
                fontFamily: "inherit",
                resize: "none",
              }}
              rows={Math.ceil(descriptionLength / 50)}
            />
          </div>
          <div style={styles.field}>
            <p style={styles.label}>Colore personalizzato</p>
            <ChromePicker
              color={color || "#fff"}
              onChange={(e) => setColor(e.hex)}
            />
          </div>
        </>
      )}
      {step === 4 && (
        <>
          <h1 style={{ color: colors.primary }}>Creazione completata</h1>
          <h3 style={{ color: colors.secondary }}>{completedMessage}</h3>
        </>
      )}
      {step !== 4 && (
        <>
          <div style={{ height: "100%" }}></div>
          {showError && (
            <div style={styles.message}>
              <p style={{ color: colors.red, marginBottom: 0 }}>
                ATTENZIONE: {message}
              </p>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: 20,
            }}
          >
            {!loader && (
              <button onClick={() => nextStep()} style={styles.button}>
                {step === 1 || step === 2 ? "PROSEGUI" : "REGISTRATI"}
              </button>
            )}
            {loader && (
              <ReactLoading
                type={"spinningBubbles"}
                color={colors?.primary}
                height={50}
                width={50}
              />
            )}
          </div>
        </>
      )}
    </form>
  );
};

export default SignupForm;
