import React, { useRef, useState, useContext, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import arrowDown from "./../../img/arrow_down.png";
import arrowUp from "./../../img/arrow_up.png";
import filterIcon from "./../../img/filter.png";
import ReactLoading from "react-loading";
import ReactExport from "react-export-excel";
import "./../../customizer.css";
import Pagination from "react-js-pagination";
import { UserContext, OrgContext } from "../../contexts";
import refreshInputs from "./../../img/closeButton.png";
import { inventoryService, itemsService } from "../../_services";
import Purchase from "../../components/purchase";
import PurchaseInvoiceManagement from "./popup/purchaseInvoiceManagement";
import ModifyPurchase from "./popup/modifyPurchase";
import RemovePurchase from "./popup/removePurchase";
import EndOperation from "../../components/popup/endOperation";
import GenericDownloadButton from "../../components/buttons/genericDownloadButton";
import Select from "react-select";
import DateCalendar from "../analytics/dateCalendar";
import { styled } from "@material-ui/core/styles";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function isEmptyNumber(value) {
  return value === null || value === undefined;
}

function isEmpty(value) {
  return (
    value === "" || value === null || value === undefined || value.length === 0
  );
}

const itemsPerPageOptions = [10, 20, 50, 100];

const PurchasesHistory = () => {
  const colors = useContext(UserContext)?.colors;
  const org = useContext(OrgContext)?.org;
  const prevOrg = usePrevious(org);

  const StyledAutocomplete = styled(Autocomplete)({
    "& .MuiAutocomplete-inputRoot": {
      color: colors?.white,
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors?.white,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: colors?.white,
      },
      "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
        borderColor: colors?.white,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: colors?.white,
      },
      "& .MuiButtonBase-root": {
        color: colors?.white,
      },
    },
    "& .MuiInputLabel-outlined": {
      color: colors?.white,
    },
    "&:hover .MuiInputLabel-outlined": {
      color: colors?.white,
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: colors?.white,
    },
    "& .MuiInputLabel-outlined.Mui-disabled": {
      color: colors?.white,
    },
  });

  const [purchasesHistory, setPurchasesHistory] = useState([]);
  const [startingDate, setStartingDate] = useState(
    moment().subtract(30, "days")
  );
  const [endingDate, setEndingDate] = useState(moment());
  const [showDateSelection, setShowDateSelection] = useState(false);
  const [showMonthSelection, setShowMonthSelection] = useState(false);
  const [name, setName] = useState("");
  const [category, setCategory] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [seller, setSeller] = useState("");
  const [purchaseMethod, setPurchaseMethod] = useState("");
  const purchaseMethodsList = [
    { value: "", label: "Tutte le modalità di acquisto" },
    { value: 1, label: "Acquisto" },
    { value: 2, label: "Conto vendita" },
  ];
  const [paymentState, setPaymentState] = useState("");
  const paymentStatesList = [
    { value: "", label: "Tutti gli stati di pagamento" },
    { value: 1, label: "Pagati" },
    { value: 2, label: "Da pagare" },
  ];
  const [filterSelected, setFilterSelected] = useState("none");
  const [loader, setLoader] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const prevActivePage = usePrevious(activePage);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const prevItemsPerPage = usePrevious(itemsPerPage);
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [filterApplied, setFilterApplied] = useState(false);
  const prevFilterApplied = usePrevious(filterApplied);
  const [excelData, setExcelData] = useState([]);
  const labels = [
    "Nome articolo",
    "Categoria articolo",
    "Data carico",
    "Costo unitario",
    "Quantità",
    "Modalità acquisto",
    "Venditore",
  ];

  const defaultFilters = {
    datetime_acquisto_gte: moment().subtract(30, "days").format("yyyy-MM-DD"),
    datetime_acquisto_lt: moment().format("yyyy-MM-DD"),
  };

  const [filters, setFilters] = useState(defaultFilters);

  const filterFields = [
    {
      identifier: "date",
      label: "Data carico",
      var: startingDate || endingDate,
    },
    { identifier: "name", label: "Nome articolo", var: name },
    { identifier: "category", label: "Categoria", var: category },
    { identifier: "seller", label: "Venditore", var: seller },
    {
      identifier: "purchaseMethod",
      label: "Modalità acquisto",
      var: purchaseMethod,
    },
    { identifier: "paymentState", label: "Stato pagamento", var: paymentState },
  ];

  const [allNames, setAllNames] = useState([]);
  const [allSellers, setAllSellers] = useState([]);
  const [allListsLoaded, setAllListsLoaded] = useState(false);

  const [itemSelected, setItemSelected] = useState(null);
  const [showInvoiceManagement, setShowInvoiceManagement] = useState(false);
  const [showModifyItem, setShowModifyItem] = useState(false);
  const [showDeleteItem, setShowDeleteItem] = useState(false);
  const [showEndOperation, setShowEndOperation] = useState(false);
  const [operationResult, setOperationResult] = useState(true);
  const [operation, setOperation] = useState(null);

  const [inputsRecap, setInputsRecap] = useState("");
  var inputsArray = [
    startingDate,
    endingDate,
    name,
    category,
    seller,
    purchaseMethod,
    paymentState,
  ];
  const generateInputsRecap = () => {
    //for every used filter, add a part to the string of filters recap
    var vocalDays = [1, 8, 11]; //used to deal with days starting with a vocal
    var recapString = "";
    if (startingDate !== undefined) {
      recapString +=
        (vocalDays.includes(moment(startingDate).date()) ? "Dall'" : "Dal ") +
        moment(startingDate).locale("it").format("D MMMM YYYY");
    }
    if (endingDate !== undefined) {
      if (moment(endingDate).isSame(new Date(), "day")) {
        recapString += " a oggi";
      } else {
        recapString +=
          (vocalDays.includes(moment(endingDate).date()) ? " all'" : " al ") +
          moment(endingDate).locale("it").format("D MMMM YYYY");
      }
    }
    if (name !== "") {
      recapString += ', Nome articolo contiene "' + name.trim() + '"';
    }
    if (category.length !== 0) {
      recapString += ", Categoria ";
      for (var c = 0; c < category.length; c++) {
        recapString +=
          '"' +
          category[c].substring(0, 1).toUpperCase() +
          category[c].substring(1) +
          '"';
        if (c !== category.length - 1) {
          recapString += " o ";
        }
      }
    }
    if (seller !== "") {
      recapString += ', Venditore contiene "' + seller.trim() + '"';
    }
    if (purchaseMethod !== "") {
      recapString +=
        ', soltanto con Modalità di acquisto "' +
        purchaseMethodsList[purchaseMethod].label +
        '"';
    }
    if (paymentState !== "") {
      recapString +=
        ", soltanto " + paymentStatesList[paymentState].label.toLowerCase();
    }
    return recapString;
  };

  useEffect(() => {
    setInputsRecap(generateInputsRecap());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputsArray]);

  const [currentFiltersRecap, setCurrentFiltersRecap] = useState("");
  const generateCurrentFiltersRecap = () => {
    var recapString = "filtri della ricerca corrente: ";
    recapString +=
      generateInputsRecap().substring(0, 1).toLocaleLowerCase() +
      generateInputsRecap().substring(1);
    return recapString;
  };

  const importData = () => {
    setLoader(true);

    itemsService.getCategories().then((res) => {
      var tempList = [];
      for (var el of res) {
        tempList.push({
          value: el.nome_categoria.toLowerCase(),
          label: el.nome_categoria,
        });
      }
      setCategoriesList(tempList);
    });

    inventoryService
      .countPurchases(org.id, filters)
      .then((tot) => setTotalItemsCount(tot));
    inventoryService
      .getPurchases(
        org.id,
        filters,
        itemsPerPage * activePage - itemsPerPage,
        itemsPerPage
      )
      .then((history) => {
        setPurchasesHistory(history);
        setCurrentFiltersRecap(generateCurrentFiltersRecap());
        if (allListsLoaded) setLoader(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });

    inventoryService
      .getPurchases(org.id, filters, 0, -1)
      .then((history) => {
        //EXCEL REPORT
        var finalColumns = [
          // Article info:
          "Nome Articolo",
          "Categoria",
          "Prezzo articolo [in €]",

          // Purchase info
          "Venditore",
          "Modalità acquisto",
          "Data carico",
          "Costo unitario carico [in €]",
          "Carico chiuso/pagato",
          "N° fattura",
          "Spese aggiuntive carico [in €]",
          "Costo carico [in €]",
          "Costo carico (se con spese) [in €]",

          // Numbers:
          "Quantità carico",
          "Venduti da questo carico",
          "Scarichi da questo carico",
          "Giacenza attuale carico",
          "Valore inventario [in €]",
        ];

        const finalData = history.map((item) => {
          const purchaseDatetime = moment(item.datetime_acquisto).format(
            "DD/MM/YYYY, HH:mm"
          );

          const singleItemCost = !isEmptyNumber(item.costo_articolo)
            ? parseFloat(item.costo_articolo)
            : "-";

          const itemsCost =
            item.modalita_acquisto &&
            !isEmptyNumber(item.costo_articolo) &&
            !isEmptyNumber(item.quantita) &&
            !isEmptyNumber(item.giacenza) &&
            !isEmptyNumber(item.scarichi)
              ? item.modalita_acquisto.nome_modalita.includes("Conto vendita")
                ? parseFloat(
                    parseFloat(item.costo_articolo) *
                      (parseInt(item.quantita) -
                        parseInt(item.giacenza) -
                        parseInt(item.scarichi))
                  )
                : parseFloat(
                    parseFloat(item.costo_articolo) * parseInt(item.quantita)
                  )
              : "-";

          const inventoryValue =
            item.modalita_acquisto &&
            !isEmptyNumber(item.costo_articolo) &&
            !isEmptyNumber(item.giacenza)
              ? item.modalita_acquisto.nome_modalita.includes("Conto vendita")
                ? "-"
                : parseFloat(
                    parseFloat(item.costo_articolo) * parseInt(item.giacenza)
                  )
              : "-";

          const totalCost = !isEmptyNumber(item.tot_fattura)
            ? item.tot_fattura
            : "-";

          const itemsSold =
            !isEmptyNumber(item.quantita) &&
            !isEmptyNumber(item.giacenza) &&
            !isEmptyNumber(item.scarichi)
              ? parseInt(item.quantita) -
                parseInt(item.giacenza) -
                parseInt(item.scarichi)
              : "-";

          var additionalCost = "";
          if (item.modalita_acquisto) {
            if (
              item.modalita_acquisto.nome_modalita.includes("Conto vendita")
            ) {
              if (
                !isEmptyNumber(item.tot_fattura) &&
                !isEmptyNumber(item.costo_articolo) &&
                !isEmptyNumber(item.quantita) &&
                !isEmptyNumber(item.giacenza)
              ) {
                additionalCost =
                  parseFloat(item.tot_fattura) -
                  parseFloat(
                    parseFloat(item.costo_articolo) *
                      (parseInt(item.quantita) - parseInt(item.giacenza))
                  );
              } else {
                additionalCost = "-";
              }
            } else {
              if (
                !isEmptyNumber(item.tot_fattura) &&
                !isEmptyNumber(item.costo_articolo) &&
                !isEmptyNumber(item.quantita)
              ) {
                additionalCost =
                  parseFloat(item.tot_fattura) -
                  parseFloat(
                    parseFloat(item.costo_articolo) * parseInt(item.quantita)
                  );
              } else {
                additionalCost = "-";
              }
            }
          } else {
            additionalCost = "-";
          }

          const paymentDatetime = item.datetime_pagamento
            ? moment(item.datetime_pagamento).format("DD/MM/YYYY")
            : "No";

          const articlePrice = item.acquisti_articoli
            ? item.acquisti_articoli.prezzo_articolo
            : "-";

          const res = [
            // Article info:
            item.acquisti_articoli ? item.acquisti_articoli.nome_articolo : "-",
            item.acquisti_articoli
              ? item.acquisti_articoli.nome_categoria
              : "-",
            articlePrice,

            // Purchase info
            item.venditore ? item.venditore : "-",
            item.modalita_acquisto ? item.modalita_acquisto.nome_modalita : "-",
            purchaseDatetime,
            singleItemCost,
            paymentDatetime,
            !isEmpty(item.num_fattura) ? item.num_fattura : "-",
            additionalCost,
            itemsCost,
            totalCost,

            // Numbers:
            !isEmptyNumber(item.quantita) ? item.quantita : "-",
            itemsSold,
            !isEmptyNumber(item.scarichi) ? item.scarichi : "-",
            !isEmptyNumber(item.giacenza) ? item.giacenza : "-",
            inventoryValue,
          ];
          return res;
        });

        setExcelData([{ columns: finalColumns, data: finalData }]);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  const filterLabel = (fil, key) => {
    return (
      <div
        style={{
          ...styles.singleFilter,
          outline:
            !isEmpty(fil.var) && filterSelected === "none"
              ? "solid 3px"
              : "solid 1px",
          opacity:
            filterSelected === "none" || filterSelected === fil.identifier
              ? 1
              : 0.3,
        }}
        onClick={(e) => {
          setFilterSelected(
            filterSelected === fil.identifier ? "none" : fil.identifier
          );
        }}
        key={key}
      >
        <p
          style={{
            margin: "5px 10px",
            fontWeight:
              !isEmpty(fil.var) && filterSelected === "none"
                ? "bold"
                : "normal",
          }}
        >
          {fil.label}
        </p>
        <img
          alt="Arrow icon"
          src={filterSelected === fil.identifier ? arrowUp : arrowDown}
          style={{ width: 10, objectFit: "contain", marginRight: 10 }}
        />
      </div>
    );
  };

  const showFilter = () => {
    switch (filterSelected) {
      case "date":
        return (
          <div
            style={{
              marginBottom: 20,
              display: "flex",
              flexDirection: "row",
              fontSize: 13,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                opacity: filterApplied === false ? 1 : 0.3,
              }}
            >
              {/* "Last 30 days" button, highlighted even if the free selection corresponds */}
              <button
                onClick={() => {
                  setEndingDate(moment());
                  setStartingDate(moment().subtract(30, "days"));
                  setShowMonthSelection(false);
                  setShowDateSelection(false);
                }}
                style={{
                  ...styles.button,
                  marginRight: 10,
                  backgroundColor:
                    moment(endingDate).isSame(moment(), "day") &&
                    moment(startingDate).isSame(
                      moment().subtract(30, "days"),
                      "day"
                    )
                      ? colors?.primary
                      : colors?.gray,
                  cursor: filterApplied === false ? "pointer" : "default",
                }}
                disabled={filterApplied}
              >
                Ultimi 30 giorni
              </button>
              {/* "Select a month" button */}
              <button
                onClick={() => {
                  setShowMonthSelection(true);
                  setShowDateSelection(false);
                }}
                style={{
                  ...styles.button,
                  marginRight: 10,
                  backgroundColor: showMonthSelection
                    ? colors?.primary
                    : colors?.gray,
                  cursor: filterApplied === false ? "pointer" : "default",
                }}
                disabled={filterApplied}
              >
                Scelta mese
              </button>
              {/* "Free selection" button */}
              <button
                onClick={() => {
                  setShowMonthSelection(false);
                  setShowDateSelection(true);
                }}
                style={{
                  ...styles.button,
                  marginRight: 20,
                  backgroundColor: showDateSelection
                    ? colors?.primary
                    : colors?.gray,
                  cursor: filterApplied === false ? "pointer" : "default",
                }}
                disabled={filterApplied}
              >
                Scelta manuale
              </button>
            </div>
            {showDateSelection && (
              <>
                <p style={{ marginRight: 10 }}>Dal</p>
                <input
                  type="date"
                  name="startingDate"
                  disabled={filterApplied}
                  max={moment(new Date()).format("yyyy-MM-DD")}
                  value={moment(startingDate).format("yyyy-MM-DD")}
                  onChange={(event) => {
                    setStartingDate(event.target.value);
                  }}
                  placeholder="Dal"
                  style={styles.dateText}
                />
                <p style={{ marginRight: 10, marginLeft: 10 }}>al</p>
                <input
                  type="date"
                  name="endingDate"
                  disabled={filterApplied}
                  min={moment(startingDate).format("yyyy-MM-DD")}
                  max={moment(new Date()).format("yyyy-MM-DD")}
                  value={moment(endingDate).format("yyyy-MM-DD")}
                  onChange={(event) => setEndingDate(event.target.value)}
                  placeholder="al"
                  style={styles.dateText}
                />
              </>
            )}
            {showMonthSelection && (
              <DateCalendar
                isAnnual={false}
                isDisabled={filterApplied}
                prevDate={endingDate}
                dateSelected={(date) => {
                  var dDate = new Date(date);
                  var today = moment(new Date()).format("yyyy-MM-DD");
                  var first = moment(dDate.setDate(1)).format("yyyy-MM-DD");
                  setStartingDate(first);
                  var last = moment(
                    new Date(dDate.getFullYear(), dDate.getMonth() + 1, 0)
                  ).format("yyyy-MM-DD");
                  if (last > today) {
                    last = today;
                  }
                  setEndingDate(last);
                }}
              />
            )}
          </div>
        );

      case "name":
        return (
          <StyledAutocomplete
            id="combo-box-demo"
            freeSolo
            autoSelect
            options={allNames}
            sx={styles.importedFilterSize}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Nome articolo"
                variant="outlined"
                sx={{ opacity: filterApplied ? 0.5 : 1 }}
              />
            )}
            onChange={(event) => {
              setName(
                event.target.value
                  ? event.target.value
                  : event.target.outerText
                  ? event.target.outerText
                  : ""
              );
            }}
            value={!isEmpty(name) ? name : ""}
            disabled={filterApplied}
          />
        );
      case "category":
        return (
          <div style={styles.importedFilterSize}>
            <Select
              placeholder="Tutte le categorie"
              defaultValue={categoriesList.filter((el) =>
                category.includes(el.value)
              )}
              isMulti
              name="categories"
              options={categoriesList}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(event) => {
                var temp = [];
                event.forEach((element) => {
                  temp.push(element.value);
                });
                temp.sort();
                setCategory(temp);
              }}
              isDisabled={filterApplied}
            />
          </div>
        );
      case "seller":
        return (
          <StyledAutocomplete
            id="combo-box-demo"
            freeSolo
            autoSelect
            options={allSellers}
            sx={styles.importedFilterSize}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Venditore"
                variant="outlined"
                sx={{ opacity: filterApplied ? 0.5 : 1 }}
              />
            )}
            onChange={(event) => {
              setSeller(
                event.target.value
                  ? event.target.value
                  : event.target.outerText
                  ? event.target.outerText
                  : ""
              );
            }}
            value={!isEmpty(seller) ? seller : ""}
            disabled={filterApplied}
          />
        );
      case "purchaseMethod":
        return (
          <select
            name={
              isEmpty(purchaseMethod)
                ? purchaseMethodsList[0].label
                : purchaseMethodsList[purchaseMethod].label
            }
            style={styles.textInput}
            value={
              isEmpty(purchaseMethod)
                ? purchaseMethodsList[0].value
                : purchaseMethodsList[purchaseMethod].value
            }
            disabled={filterApplied}
            onChange={(event) => {
              event.target.selectedIndex === 0
                ? setPurchaseMethod("")
                : setPurchaseMethod(
                    purchaseMethodsList[event.target.selectedIndex].value
                  );
            }}
          >
            {purchaseMethodsList.map((opt, key) => (
              <option key={key} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
        );
      case "paymentState":
        return (
          <select
            name={
              isEmpty(paymentState)
                ? paymentStatesList[0].label
                : paymentStatesList[paymentState].label
            }
            style={styles.textInput}
            value={
              isEmpty(paymentState)
                ? paymentStatesList[0].value
                : paymentStatesList[paymentState].value
            }
            disabled={filterApplied}
            onChange={(event) => {
              event.target.selectedIndex === 0
                ? setPaymentState("")
                : setPaymentState(
                    paymentStatesList[event.target.selectedIndex].value
                  );
            }}
          >
            {paymentStatesList.map((opt, key) => (
              <option key={key} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
        );
      case "none":
        break;
      default:
        break;
    }
  };

  const areThereFilters = () => {
    return (
      !isEmpty(startingDate) ||
      !isEmpty(endingDate) ||
      !isEmpty(seller) ||
      !isEmpty(purchaseMethod) ||
      !isEmpty(name) ||
      !isEmpty(category) ||
      !isEmpty(paymentState)
    );
  };

  const startSearch = () => {
    setActivePage(1);
    setFilterApplied(true);
  };

  const resetFilters = () => {
    setFilters(defaultFilters);
    setShowDateSelection(false);
    setShowMonthSelection(false);
    setStartingDate(moment().subtract(30, "days"));
    setEndingDate(moment());
    setName("");
    setCategory([]);
    setSeller("");
    setPurchaseMethod("");
    setPaymentState("");
    setFilterSelected("none");
  };

  const filtersAlreadyReset = () => {
    return (
      moment(startingDate).date() === moment().subtract(30, "days").date() &&
      moment(endingDate).date() === moment().date() &&
      name === "" &&
      category.length === 0 &&
      seller === "" &&
      purchaseMethod === "" &&
      paymentState === ""
    );
  };

  useEffect(() => {
    inventoryService
      .getPurchases(org.id, {}, 0, -1)
      .then((allHistory) => {
        var tempAllNames = [];
        var tempAllSellers = [];
        for (var el of allHistory) {
          if (
            el.acquisti_articoli &&
            el.acquisti_articoli.nome_articolo &&
            !el.acquisti_articoli.nome_articolo.includes("_DEL") &&
            !tempAllNames.includes(el.acquisti_articoli.nome_articolo)
          ) {
            tempAllNames.push(el.acquisti_articoli.nome_articolo);
          }
          if (el.venditore && !tempAllSellers.includes(el.venditore.trim())) {
            tempAllSellers.push(el.venditore.trim());
          }
        }
        tempAllNames.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        setAllNames(tempAllNames);

        tempAllSellers.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        setAllSellers(tempAllSellers);

        setAllListsLoaded(true);
        setLoader(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleInvoiceManagement = (item) => {
    if (!showInvoiceManagement) {
      setItemSelected(item);
      setOperation(5);
    } else {
      setItemSelected(null);
    }
    setShowInvoiceManagement(!showInvoiceManagement);
  };

  const toggleModifyItem = (item) => {
    if (!showModifyItem) {
      setItemSelected(item);
      setOperation(1);
    } else {
      setItemSelected(null);
    }
    setShowModifyItem(!showModifyItem);
  };

  const toggleEndOperation = () => {
    setShowEndOperation(!showEndOperation);
  };

  const onInvoiceManaged = (
    id,
    newPurchase,
    datetime_pagamento,
    tot_fattura,
    num_fattura
  ) => {
    setLoader(true);
    toggleInvoiceManagement();
    inventoryService
      .invoiceManagement(
        id,
        newPurchase,
        datetime_pagamento,
        tot_fattura,
        num_fattura
      )
      .then((response) => {
        if (response === false) {
          setOperationResult(false);
        } else {
          setOperationResult(true);
        }
        importData(); //it contains the setLoader(false)
        setShowEndOperation(true);
        setActivePage(1);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  const onModifyItem = (
    modalita_acquisto,
    venditore,
    quantita,
    scadenza_conto_vendita,
    costo_articolo,
    percentuale_guadagno,
    giacenza,
    codice_articolo
  ) => {
    setLoader(true);
    toggleModifyItem();
    let id = itemSelected.id;
    inventoryService
      .modifyPurchase(
        id,
        modalita_acquisto,
        venditore,
        quantita,
        scadenza_conto_vendita,
        costo_articolo,
        percentuale_guadagno,
        giacenza,
        codice_articolo
      )
      .then((response) => {
        if (response === false) {
          setOperationResult(false);
        } else {
          setOperationResult(true);
        }
        importData(); //it contains the setLoader(false)
        setActivePage(1);
        setShowEndOperation(true);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  const toggleDeleteItem = (item) => {
    if (!showDeleteItem) {
      setItemSelected(item);
      setOperation(2);
    } else {
      setItemSelected(null);
    }
    setShowDeleteItem(!showDeleteItem);
  };

  const onDeleteItem = () => {
    setLoader(true);
    toggleDeleteItem();
    inventoryService
      .deletePurchase(itemSelected.id)
      .then((response) => {
        if (response === false) {
          setOperationResult(false);
        } else {
          setOperationResult(true);
        }
        importData(); //it contains the setLoader(false)
        setActivePage(1);
        setShowEndOperation(true);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  useEffect(() => {
    if (
      prevFilterApplied !== filterApplied &&
      filterApplied !== false &&
      prevFilterApplied !== undefined
    ) {
      importData();
      setActivePage(1);
      setFilterSelected("none");
    }
    if (
      (prevActivePage !== activePage && prevActivePage !== undefined) ||
      (prevItemsPerPage !== itemsPerPage && prevItemsPerPage !== undefined)
    ) {
      importData();
    }
    if (prevOrg !== org) {
      importData();
      setActivePage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activePage,
    prevActivePage,
    itemsPerPage,
    prevItemsPerPage,
    prevOrg,
    org,
    filterApplied,
    prevFilterApplied,
    filters,
  ]);

  useEffect(() => {
    if (!isEmpty(startingDate)) {
      filters.datetime_acquisto_gte = moment(new Date(startingDate)).format(
        "yyyy-MM-DD"
      );
    } else {
      delete filters.datetime_acquisto_gte;
    }
  }, [startingDate, filters]);

  useEffect(() => {
    if (!isEmpty(endingDate)) {
      filters.datetime_acquisto_lt = moment(new Date(endingDate)).format(
        "yyyy-MM-DD"
      );
    } else {
      delete filters.datetime_acquisto_lt;
    }
  }, [endingDate, filters]);

  useEffect(() => {
    if (!isEmpty(name)) {
      filters.name_contains = [encodeURIComponent(name.trim())];
    } else {
      delete filters.name_contains;
    }
  }, [name, filters]);

  useEffect(() => {
    if (!isEmpty(category)) {
      filters.category_contains = category;
    } else {
      delete filters.category_contains;
    }
  }, [category, filters]);

  useEffect(() => {
    if (!isEmpty(seller)) {
      filters.venditore_contains = [encodeURIComponent(seller.trim())];
    } else {
      delete filters.venditore_contains;
    }
  }, [seller, filters]);

  useEffect(() => {
    if (!isEmpty(purchaseMethod)) {
      if (purchaseMethod === 1) filters.purchaseMethod_contains = ["acquisto"];
      else if (purchaseMethod === 2)
        filters.purchaseMethod_contains = ["conto"];
    } else {
      delete filters.purchaseMethod_contains;
    }
  }, [purchaseMethod, filters]);

  useEffect(() => {
    if (!isEmpty(paymentState)) {
      if (paymentState === 1) filters.datetime_pagamento_null = [false];
      else if (paymentState === 2) filters.datetime_pagamento_null = [true];
    } else {
      delete filters.paymentState_contains;
    }
  }, [paymentState, filters]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
      marginTop: "3px",
    },
    bottomSection: {
      flex: 5,
      overflowY: "scroll",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    label: {
      color: colors?.white,
      fontSize: 20,
    },
    value: {
      color: colors?.white,
      fontSize: 30,
      fontWeight: "700",
    },
    scrollerX: {
      overflowX: "scroll",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    labelTable: {
      width: 170,
      fontSize: 12,
      color: colors?.lightgray,
    },
    smallLabelTable: {
      width: 120,
      fontSize: 12,
      color: colors?.lightgray,
    },
    header: {
      display: "flex",
      flexDirection: "row",
      marginTop: 10,
    },
    importedFilterSize: {
      width: 500,
      marginBottom: "5px",
    },
    button: {
      backgroundColor: colors?.primary,
      width: 80,
      height: 50,
      borderRadius: 10,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textDecorationLine: "none",
      border: "none",
      color: colors?.white,
      fontWeight: "800",
      cursor: "pointer",
    },
    textInput: {
      borderRadius: 5,
      border: 0,
      outline: "none",
      textAlign: "center",
      padding: 10,
      marginRight: 5,
      fontFamily: "Arial",
      fontSize: "15px",
      color: "#555555",
      width: 500,
    },
    dateText: {
      borderRadius: 5,
      border: 0,
      outline: "none",
      textAlign: "center",
      padding: 10,
      paddingTop: 8,
      paddingBottom: 8,
      marginRight: 5,
      fontFamily: "Arial",
      flex: 1,
      width: 200,
      cursor: filterApplied === false ? "pointer" : "default",
    },
    filterSection: {
      backgroundColor: colors?.secondary,
      width: "100%",
      paddingTop: 20,
      paddingBottom: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
    },
    singleFilter: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      color: colors?.white,
      fontWeight: "bold",
      margin: "10px 10px 10px 0px",
      cursor: "pointer",
      outline: "solid 1px",
      minHeight: 50,
      borderRadius: 5,
    },
    inputsRecap: {
      color: colors?.white,
      fontWeight: "bold",
      textAlign: "left",
    },
    filterBox: {
      width: "90%",
      display: "flex",
      alignItems: "flex-start",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    buttonBox: {
      display: "flex",
      flexDirection: "row",
    },
    downloadButton: {
      border: "none",
      outline: "none",
      backgroundColor: colors.darkgray,
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      width: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return loader ? (
    <div style={styles.container}>
      <ReactLoading
        type={"spinningBubbles"}
        color={colors?.primary}
        height={50}
        width={50}
      />
    </div>
  ) : (
    <div style={styles.container}>
      <div style={styles.filterSection}>
        <div
          style={{
            width: "90%",
            marginLeft: 70,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                marginBottom: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <img
                  alt="Filter icon"
                  src={filterIcon}
                  style={{ width: 10, objectFit: "contain" }}
                />
                <p
                  style={{
                    color: colors?.white,
                    fontWeight: "bold",
                    margin: 0,
                    marginLeft: 10,
                  }}
                >
                  FILTRA
                </p>
              </div>
              {!filterApplied && !filtersAlreadyReset() && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  onClick={() => resetFilters()}
                >
                  <img
                    alt="Refresh inputs"
                    src={refreshInputs}
                    style={{
                      width: 8,
                      height: 8,
                    }}
                  ></img>
                  <p
                    style={{
                      color: colors?.white,
                      marginBlock: 0,
                      marginInline: 5,
                      fontSize: 11,
                      textDecoration: "underline",
                    }}
                  >
                    Ripristina filtri di default
                  </p>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {filterFields.map((filter, key) => {
                  return filterLabel(filter, key);
                })}
              </div>
              <div>
                {areThereFilters() && (
                  <button
                    style={styles.button}
                    onClick={() =>
                      filterApplied ? setFilterApplied(false) : startSearch()
                    }
                  >
                    {filterApplied ? "MODIFICA FILTRI" : "FILTRA"}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div style={styles.filterBox}>{showFilter()}</div>
          <div style={{ width: "90%" }}>
            <p
              style={{
                ...styles.inputsRecap,
                marginBottom: 0,
                fontWeight: "normal",
                fontStyle: "italic",
                fontSize: 11,
              }}
            >
              Riepilogo filtri selezionati
            </p>
            <p style={{ ...styles.inputsRecap, marginBlock: 5 }}>
              {inputsRecap}
            </p>
          </div>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "90%" }}>
        <p
          style={{
            color: colors?.gray,
            fontSize: 11,
            textAlign: "left",
            marginTop: 20,
            marginBottom: 0,
          }}
        >
          {totalItemsCount +
            " caric" +
            (totalItemsCount === 1 ? "o" : "hi") +
            " trovat" +
            (totalItemsCount === 1 ? "o" : "i")}
          {" (" + currentFiltersRecap + ")"}
        </p>
      </div>
      <div id="scroll" style={styles.bottomSection}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            margin: purchasesHistory.length !== 0 ? "0px auto" : "none",
          }}
        >
          <div id="scroll" style={styles.scrollerX}>
            <div style={styles.header}>
              {purchasesHistory.length === 0 ? (
                <div style={styles.row}>
                  <p style={{ ...styles.labelTable, marginBlock: 30 }}>
                    Nessun risultato
                  </p>
                </div>
              ) : (
                labels.map((label, key) => {
                  var smallerColumns = ["Costo unitario", "Quantità"];
                  if (label === "Nome articolo") {
                    return (
                      <div
                        style={{ ...styles.labelTable, width: 220 }}
                        key={key}
                      >
                        <p>{label}</p>
                      </div>
                    );
                  } else if (smallerColumns.includes(label)) {
                    return (
                      <div
                        style={{ ...styles.labelTable, width: 80 }}
                        key={key}
                      >
                        <p>{label}</p>
                      </div>
                    );
                  } else {
                    return (
                      <div style={styles.labelTable} key={key}>
                        <p>{label}</p>
                      </div>
                    );
                  }
                })
              )}{" "}
            </div>
            <div>
              {purchasesHistory?.map((purchase, key) => {
                return (
                  <Purchase
                    purchase={purchase}
                    setInvoiceSelection={(purchase) => {
                      toggleInvoiceManagement(purchase);
                    }}
                    setSelection={(purchase) => {
                      toggleModifyItem(purchase);
                    }}
                    onDelete={(purchase) => {
                      toggleDeleteItem(purchase);
                    }}
                    key={key}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {showInvoiceManagement && (
          <PurchaseInvoiceManagement
            purchase={itemSelected}
            closeModal={toggleInvoiceManagement}
            saveData={onInvoiceManaged}
            colors={colors}
          />
        )}
        {showModifyItem && (
          <ModifyPurchase
            purchase={itemSelected}
            closePurchaseModal={toggleModifyItem}
            saveData={onModifyItem}
            colors={colors}
          />
        )}
        {showDeleteItem && (
          <RemovePurchase
            purchaseSelected={itemSelected}
            closeModal={toggleDeleteItem}
            saveData={onDeleteItem}
            colors={colors}
          />
        )}
        {showEndOperation && (
          <EndOperation
            flag={operationResult}
            operation={operation}
            closeModal={toggleEndOperation}
            colors={colors}
          />
        )}
        {purchasesHistory.length > 0 && (
          <div style={styles.buttonBox}>
            <ExcelFile
              filename={
                (startingDate === endingDate
                  ? "Carichi_Bookshop_" +
                    moment(new Date(startingDate)).format("yyyy-MM-DD")
                  : "Carichi_Bookshop_" +
                    moment(new Date(startingDate)).format("yyyy-MM-DD") +
                    "_" +
                    moment(new Date(endingDate)).format("yyyy-MM-DD")) +
                (name !== ""
                  ? `_nome-contiene-${name.replace(" ", "-")}`
                  : "") +
                (category.length > 0
                  ? `_categoria-${category.toString().replace(",", "-")}`
                  : "") +
                (seller !== ""
                  ? `_venditore-contiene-${seller.replace(" ", "-")}`
                  : "") +
                (purchaseMethod !== ""
                  ? `_modalita-acquisto-${purchaseMethodsList[
                      purchaseMethod
                    ].label.replace(" ", "-")}`
                  : "") +
                (paymentState !== ""
                  ? `_${paymentStatesList[paymentState].label
                      .toLowerCase()
                      .replace(" ", "-")}`
                  : "")
              }
              element={<GenericDownloadButton></GenericDownloadButton>}
            >
              <ExcelSheet dataSet={excelData} name="Carichi Bookshop" />
            </ExcelFile>{" "}
          </div>
        )}
      </div>
      <div style={{ width: "100%" }}>
        {purchasesHistory.length > 0 &&
          totalItemsCount > itemsPerPageOptions[0] && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridAutoRows: "1fr",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <select
                  name="options"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: 15,
                    height: 50,
                    width: 70,
                    padding: 10,
                    textAlign: "left",
                    marginRight: 10,
                    marginLeft: 40,
                  }}
                  value={itemsPerPage}
                  onChange={(event) => {
                    setItemsPerPage(parseInt(event.target.value));
                    setActivePage(1);
                  }}
                >
                  {itemsPerPageOptions.map((option, key) => {
                    return (
                      <option key={key} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
                <p style={{ color: colors?.gray, fontSize: 12 }}>
                  elementi per pagina
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => setActivePage(pageNumber)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
export default PurchasesHistory;
