import React, { useContext } from "react";
import "./../../style.css";
import { LanguageContext } from "../../containers/language";
import { UserContext, OrgContext } from "../../contexts";
import CustomProfilingForm from "./customProfilingForm";

const UserProfiling = ({
  showWarning,
  warning,
  errorMessage,
  codeNotFoundError,
  isTicket,
  form,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const colors = useContext(UserContext)?.colors;
  const settings = useContext(OrgContext)?.org?.settings;

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      margin: "auto",
      width: "100%",
      height: "100%",
    },
    content: {
      width: "100%",
      flex: 6,
      flexDirection: "column",
      backgroundColor: colors?.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    field: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: 50,
      marginRight: 50,
    },
    footer: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    button: {
      backgroundColor: colors?.darkgray,
      color: colors?.white,
      padding: 15,
      borderRadius: 10,
      marginBottom: 30,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    menu: {
      border: "1px solid lightgray",
      backgroundColor: colors?.white,
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 10,
      color: colors?.darkgray,
      borderRadius: 20,
      outline: "none",
    },
    label: {
      fontSize: 15,
      color: colors?.lightgray,
      margin: 20,
      width: 150,
      textAlign: "right",
    },
    message: {
      backgroundColor: colors?.white,
      fontSize: 13,
      color: colors?.highlight,
    },
    errorMessage: {
      color: colors?.highlight,
      fontSize: 10,
      fontWeight: "bold",
      marginTop: -6,
    },
    typeButton: {
      flex: 1,
      backgroundColor: colors?.tertiary,
      height: 60,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: colors?.darkgray,
      cursor: "pointer",
      fontSize: 13,
    },
    smallTypeButton: {
      flex: 1,
      backgroundColor: colors?.white,
      height: 40,
      width: 95,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: colors?.lightgray,
      cursor: "pointer",
      fontSize: 15,
      margin: 1,
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        {isTicket ? (
          <>
            {settings?.biglietti_con_codice && (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: colors?.white,
                    marginBottom: 20,
                  }}
                >
                  <div
                    style={{
                      ...styles.typeButton,
                      marginRight: 1,
                      backgroundColor:
                        form.eTicket === false
                          ? colors?.white
                          : colors?.quaternary,
                    }}
                    onClick={() => {
                      form.seteTicket(false);
                      if (form.eTicket) {
                        form.setNewTicketCode("");
                        form.setCode("");
                      }
                      form.setEmail("");
                    }}
                  >
                    {dictionary.paperTicket.toUpperCase()}
                  </div>
                  <div
                    style={{
                      ...styles.typeButton,
                      marginLeft: 1,
                      backgroundColor:
                        form.eTicket === true
                          ? colors?.white
                          : colors?.quaternary,
                    }}
                    onClick={() => {
                      form.seteTicket(true);
                      if (form.eTicket) {
                        form.setNewTicketCode("");
                        form.setCode("");
                      }
                      form.setEmail("");
                    }}
                  >
                    eTICKET
                  </div>
                </div>
                {form.eTicket ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {showWarning && warning && (
                      <p
                        style={{
                          color: "red",
                          marginLeft: 10,
                          fontSize: 10,
                          width: "100%",
                        }}
                      >
                        {errorMessage}
                      </p>
                    )}
                    <div style={styles.field}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <p style={{ ...styles.label, marginRight: 10 }}>
                          {"E-mail intestatario"}
                        </p>
                        <p style={{ fontSize: 15, color: "red" }}>*</p>
                      </div>
                      <div
                        style={{
                          width: 300,
                          height: 70,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          value={form.email}
                          onChange={(event) => {
                            form.setEmail(event.target.value);
                          }}
                          placeholder="E-mail"
                          style={{
                            ...styles.menu,
                            height: 18,
                            width: 200,
                            marginLeft: 35,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {showWarning && warning && (
                      <p
                        style={{
                          color: "red",
                          marginLeft: 10,
                          fontSize: 10,
                          width: "100%",
                        }}
                      >
                        {errorMessage}
                      </p>
                    )}
                    <div style={styles.field}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <p style={{ ...styles.label, marginRight: 10 }}>
                          {dictionary.ticketCode}
                        </p>
                        <p style={{ fontSize: 15, color: "red" }}>*</p>
                      </div>
                      <div
                        style={{
                          width: 300,
                          height: 70,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <input
                          value={form.newTicketCode}
                          onChange={(event) => {
                            form.setNewTicketCode(event.target.value);
                          }}
                          placeholder="Codice biglietto"
                          style={{
                            ...styles.menu,
                            height: 18,
                            width: 200,
                            marginLeft: 35,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      width: "60%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <input
                      name="present"
                      type="checkbox"
                      checked={form.openDate}
                      onChange={() => form.setOpenDate(!form.openDate)}
                    />
                    <p
                      style={{
                        marginLeft: 10,
                        color: colors?.darkgray,
                        fontSize: 13,
                      }}
                    >
                      {dictionary.openDateTicket}
                    </p>
                  </div>
                  <p
                    style={{
                      width: "60%",
                      color: colors?.gray,
                      fontSize: 11,
                      textAlign: "left",
                      marginTop: 0,
                    }}
                  >
                    {dictionary.openDateTicketInfo}
                  </p>
                </div>
              </>
            )}
            {settings?.biglietti_con_codice &&
              settings?.profilazione_visitatori && (
                <p
                  style={{
                    borderTop: "1px solid lightgray",
                    width: "85%",
                    textAlign: "end",
                    fontWeight: "bold",
                    fontSize: 18,
                  }}
                ></p>
              )}
            {settings?.profilazione_visitatori && (
              <>
                <CustomProfilingForm
                  tickets={1}
                  customProfiling={form.info}
                  update={form.setInfo}
                  isTicket={isTicket}
                />
              </>
            )}{" "}
          </>
        ) : (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                backgroundColor: colors?.white,
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  ...styles.typeButton,
                  marginRight: 1,
                  backgroundColor:
                    form.alreadyRegistered === false
                      ? colors?.white
                      : colors?.quaternary,
                }}
                onClick={() => {
                  form.setAlreadyRegistered(false);
                }}
              >
                DATI CLIENTE
              </div>
              <div
                style={{
                  ...styles.typeButton,
                  marginLeft: 1,
                  backgroundColor:
                    form.alreadyRegistered === true
                      ? colors?.white
                      : colors?.quaternary,
                }}
                onClick={() => {
                  form.setAlreadyRegistered(true);
                }}
              >
                CLIENTE GIÀ PROFILATO
              </div>
            </div>
            {form.alreadyRegistered ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {codeNotFoundError && (
                  <p
                    style={{
                      color: "red",
                      marginLeft: 10,
                      fontSize: 10,
                      width: "100%",
                    }}
                  >
                    Il codice inserito non corrisponde ad alcun profilo
                    visitatore
                  </p>
                )}
                <div style={styles.field}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <p style={{ ...styles.label, marginRight: 10 }}>
                      {dictionary.ticketCode}
                    </p>
                  </div>
                  <div
                    style={{
                      width: 300,
                      height: 100,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      value={form.code}
                      onChange={(event) => form.setCode(event.target.value)}
                      placeholder="Codice biglietto"
                      style={{
                        ...styles.menu,
                        height: 18,
                        width: 200,
                        marginLeft: 35,
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <CustomProfilingForm
                tickets={1}
                customProfiling={form.info}
                update={form.setInfo}
                isTicket={isTicket}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserProfiling;
