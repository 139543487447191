import React from 'react'; 
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom'; 
import { isLoggedIn } from '../_helpers';
import Dashboard from './dashboard';
import Login from './login';

export default function RouteWrapper({ 
  component,  
  isPrivate,   
  ...rest 
}) {   
  const signed = isLoggedIn();
  /**    
  * Redirect user to Login page if he tries to access a private route
  * without authentication.    
  */   
  if (isPrivate && !signed) {  
    return  <Route {...rest} component={Login}/>;   
  }    
  /**    
  * Redirect user to Main page if he tries to access a non private route    
  * after being authenticated.    
  */   
  if (isPrivate && signed) {

    return <Route {...rest} component={Dashboard}/>;   
  }    
  
  /**    
  * If not included on both previous cases, redirect user to the desired route.    
  */   

  return <Route {...rest} component={component} />; 
}

RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
      .isRequired,
  };