import moment from "moment";
import config from "../config";
import axios from "axios";
//import querystring from "query-string";
import { handleResponse, authHeader, handleAxiosResponse } from "../_helpers";

export const reportService = {
  getTodayReport,
  deleteSale,
  deleteEntrance,
  modifyUserProfile,
  getReportPdf,
  getReportExcel,
  getEntrancesReportPdf,
  getEntrancesReportExcel,
  getSalesHistoryPdf,
  getSalesHistoryExcel,
  getVisitorsData,
  getSalesCategories,
  getGraphStats,
  getCrossedFiltersGraph,
  getProfilingSalesGraph,
  getGraphVendite,
  getGraphArticoli,
  getGraphCategories,
  getBuyers,
  getReportVisitorPdf,
  getReportSalesPdf,
  getReportBehaviorExcel,
  getReportBehaviorPDF,
  getSales,
  countSales,
  getEntrances,
  countEntrances,
  importReport,
  importSales,
  importEntrances,
  getLastUpdate,
  getInfoFromZipCode,
  createVisitors,
  getSections,
  getObjects,
  getSectionTimes,
  getInteractions,
  getObjectsInteractions,
  getSectionObjectDistribution,
  getInteractionsDistribution,
  getSectionObjectTimes,
  closeSalesVivaticket,
};

// LISTA VENDITE
function getSales(
  org,
  filter = {},
  start,
  limit,
  sort = [{ datetime_vendita: "desc" }]
) {
  if (!filter.datetime_vendita_gte) {
    filter.datetime_vendita_gte = moment()
      .startOf("month")
      .format("YYYY-MM-DD");
  }
  if (!filter.datetime_vendita_lt) {
    filter.datetime_vendita_lt = moment().format("YYYY-MM-DD");
  }

  const _start = start;
  const _limit = limit;

  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ filter: filter }),
  };
  return fetch(
    `${config.worker.url}/listavendite/${org.id}/${filter.datetime_vendita_gte}/${filter.datetime_vendita_lt}/${_start}/${_limit}`,
    requestOptions
  ).then(handleResponse);
}

function countSales(org, filter) {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ filter: filter }),
  };
  return fetch(
    `${config.worker.url}/listavenditecount/${org.id}/${filter.datetime_vendita_gte}/${filter.datetime_vendita_lt}`,
    requestOptions
  ).then(handleResponse);
}

// LISTA ENTRANCES
function getEntrances(
  org,
  filter = {},
  start,
  limit,
  sort = [{ datetime_vendita: "desc" }]
) {
  if (!filter.datetime_vendita_gte) {
    filter.datetime_vendita_gte = moment()
      .startOf("month")
      .format("YYYY-MM-DD");
  }
  if (!filter.datetime_vendita_lt) {
    filter.datetime_vendita_lt = moment().format("YYYY-MM-DD");
  }

  const _start = start;
  const _limit = limit;

  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ filter: filter }),
  };
  return fetch(
    `${config.worker.url}/listaingressi/${org.id}/${filter.datetime_vendita_gte}/${filter.datetime_vendita_lt}/${_start}/${_limit}`,
    requestOptions
  ).then(handleResponse);
}

function countEntrances(org, filter) {
  if (!filter.datetime_vendita_gte) {
    filter.datetime_vendita_gte = moment()
      .startOf("month")
      .format("YYYY-MM-DD");
  }
  if (!filter.datetime_vendita_lt) {
    filter.datetime_vendita_lt = moment().format("YYYY-MM-DD");
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ filter: filter }),
  };
  return fetch(
    `${config.worker.url}/listaingressicount/${org.id}/${filter.datetime_vendita_gte}/${filter.datetime_vendita_lt}`,
    requestOptions
  ).then(handleResponse);
}

// DATI REPORT
function getTodayReport(org, filter) {
  const requestOptions = { method: "GET", headers: authHeader() };

  return fetch(
    `${config.worker.url}/statstoday/${org.id}/${filter}`,
    requestOptions
  ).then(handleResponse);
}

function getVisitorsData(org, startingDate, endingDate, filter, tab) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }
  const requestOptions = { method: "GET", headers: authHeader() };

  return fetch(
    `${config.worker.url}/stats/${org.id}/${filter}/${startingDate}/${endingDate}/${tab}`,
    requestOptions
  ).then(handleResponse);
}

function getSalesCategories(org, startingDate, endingDate) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }
  const requestOptions = { method: "GET", headers: authHeader() };

  return fetch(
    `${config.worker.url}/getCategories/${org.id}/${startingDate}/${endingDate}`,
    requestOptions
  ).then(handleResponse);
}

function getGraphStats(
  org,
  contentFilter,
  dateFilter,
  startingDate,
  endingDate,
  tab
) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  const requestOptions = { method: "GET", headers: authHeader() };

  let escapedContent = contentFilter;
  if (contentFilter) {
    escapedContent = encodeURIComponent(contentFilter);
  }

  return fetch(
    `${config.worker.url}/graphStats/${org.id}/${escapedContent}/${dateFilter}/${tab}/${startingDate}/${endingDate}`,
    requestOptions
  ).then(handleResponse);
}

function getCrossedFiltersGraph(
  org,
  xField,
  yField,
  startingDate,
  endingDate,
  tab
) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  const requestOptions = { method: "GET", headers: authHeader() };

  let xEscapedContent = xField;
  if (xField) {
    xEscapedContent = encodeURIComponent(xField);
  }

  let yEscapedContent = yField;
  if (yField) {
    yEscapedContent = encodeURIComponent(yField);
  }

  return fetch(
    `${config.worker.url}/crossFilterGraphStats/${org.id}/${xEscapedContent}/${yEscapedContent}/${tab}/${startingDate}/${endingDate}`,
    requestOptions
  ).then(handleResponse);
}

function getProfilingSalesGraph(
  org,
  category,
  profiling,
  startingDate,
  endingDate
) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  let oldBody = JSON.stringify({
    orgId: org.id,
    category: category,
    start: startingDate,
    end: endingDate,
    profilingField: profiling,
  });

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: oldBody,
  };

  return fetch(`${config.worker.url}/profilingSaleGraph`, requestOptions).then(
    handleResponse
  );
}

function getGraphVendite(
  org,
  contentFilter,
  dateFilter,
  startingDate,
  endingDate
) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  let oldBody = JSON.stringify({
    orgId: org.id,
    category: contentFilter,
    start: startingDate,
    end: endingDate,
    temporalFilter: dateFilter,
  });

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: oldBody,
  };

  return fetch(`${config.worker.url}/graphVendite`, requestOptions).then(
    handleResponse
  );
}

function getGraphArticoli(
  org,
  contentFilter,
  dateFilter,
  startingDate,
  endingDate
) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  let oldBody = JSON.stringify({
    orgId: org.id,
    category: contentFilter,
    start: startingDate,
    end: endingDate,
    temporalFilter: dateFilter,
  });

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: oldBody,
  };

  return fetch(`${config.worker.url}/graphArticoli`, requestOptions).then(
    handleResponse
  );
}

function getGraphCategories(org, dateFilter, startingDate, endingDate) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  const requestOptions = { method: "GET", headers: authHeader() };

  return fetch(
    `${config.worker.url}/graphCategories/${org.id}/${dateFilter}/${startingDate}/${endingDate}`,
    requestOptions
  ).then(handleResponse);
}

function getBuyers(org, startingDate, endingDate) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  const requestOptions = { method: "GET", headers: authHeader() };

  return fetch(
    `${config.worker.url}/getVisitorsBuyers/${org.id}/${startingDate}/${endingDate}`,
    requestOptions
  ).then(handleResponse);
}

function getReportPdf(org, filter, startingDate, endingDate) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  return axios
    .post(
      `${config.worker.url}/reportpdf/${org.id}/${filter}/${org.nome}/${startingDate}/${endingDate}`,
      { filter: {} },
      { headers: authHeader() }
    )
    .then(handleAxiosResponse);
}

function getReportVisitorPdf(org, startingDate, endingDate, isBookshop) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  const requestOptions = { method: "GET", headers: authHeader() };

  let url = ``;
  if (isBookshop) {
    url = `${config.worker.url}/reportvisitatoripdf/${org.id}/${org.nome}/${startingDate}/${endingDate}/${isBookshop}`;
  } else {
    url = `${config.worker.url}/reportvisitatoripdf/${org.id}/${org.nome}/${startingDate}/${endingDate}`;
  }
  return fetch(url, requestOptions).then(handleResponse);
}

function getReportSalesPdf(org, startingDate, endingDate) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  const requestOptions = { method: "GET", headers: authHeader() };
  //return fetch(`${config.api.url}/vendites/todayReportPDF/${id}`, requestOptions).then(handleResponse);
  return fetch(
    `${config.worker.url}/reportvenditestatspdf/${org.id}/${org.nome}/${startingDate}/${endingDate}`,
    requestOptions
  ).then(handleResponse);
}

function getReportBehaviorExcel(org, startingDate, endingDate) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  const requestOptions = { method: "GET", headers: authHeader() };
  //return fetch(`${config.api.url}/vendites/todayReportPDF/${id}`, requestOptions).then(handleResponse);
  return fetch(
    `${config.worker.url}/reportUserBehaviourExcel/${org.id}/${org.nome}/${startingDate}/${endingDate}`,
    requestOptions
  ).then(handleResponse);
}

function getReportBehaviorPDF(org, startingDate, endingDate) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  const requestOptions = { method: "GET", headers: authHeader() };
  //return fetch(`${config.api.url}/vendites/todayReportPDF/${id}`, requestOptions).then(handleResponse);
  return fetch(
    `${config.worker.url}/reportUserBehaviourPdf/${org.id}/${org.nome}/${startingDate}/${endingDate}`,
    requestOptions
  ).then(handleResponse);
}

function getReportExcel(org, filter, startingDate, endingDate) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  return axios
    .post(
      `${config.worker.url}/reportexcel/${org.id}/${filter}/${org.nome}/${startingDate}/${endingDate}`,
      { filter: {} },
      { headers: authHeader() }
    )
    .then(handleAxiosResponse);
}

function importReport() {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return /*moment()*/ fetch(`${config.worker.url}/import`, requestOptions).then(
    handleResponse
  );
}

function importSales() {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return /*moment();*/ fetch(
    `${config.worker.url}/importvendite`,
    requestOptions
  ).then(handleResponse);
}

function importEntrances() {
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return /*moment();*/ fetch(
    `${config.worker.url}/importingressi`,
    requestOptions
  ).then(handleResponse);
}

function getLastUpdate() {
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return fetch(`${config.worker.url}/lastupdate`, requestOptions).then(
    handleResponse
  );
}

//CREO ANCHGE IL PDF
function getEntrancesReportPdf(
  org,
  data_inizio,
  data_fine,
  nome_file,
  lista_ingressi,
  filtri,
  filterPayload
) {
  return axios
    .post(
      `${config.worker.url}/reportingressipdf/${org.id}/-1/${org.nome}/${data_inizio}/${data_fine}`,
      { filter: filterPayload },
      { headers: authHeader() }
    )
    .then(handleAxiosResponse);
}

//CREO ANCHGE IL PDF
function getEntrancesReportExcel(
  org,
  data_inizio,
  data_fine,
  nome_file,
  lista_ingressi,
  filtri,
  filterPayload
) {
  return axios
    .post(
      `${config.worker.url}/reportingressiexcel/${org.id}/-1/${org.nome}/${data_inizio}/${data_fine}`,
      { filter: filterPayload },
      { headers: authHeader() }
    )
    .then(handleAxiosResponse);
}

function getSalesHistoryPdf(
  org,
  data_inizio,
  data_fine,
  nome_file,
  lista_vendite,
  filtri,
  filterPayload
) {
  return axios
    .post(
      `${config.worker.url}/reportpdf/${org.id}/-1/${org.nome}/${data_inizio}/${data_fine}`,
      { filter: filterPayload },
      { headers: authHeader() }
    )
    .then(handleAxiosResponse);
}

function getSalesHistoryExcel(
  org,
  data_inizio,
  data_fine,
  nome_file,
  lista_vendite,
  filtri,
  filterPayload
) {
  return axios
    .post(
      `${config.worker.url}/reportexcel/${org.id}/-1/${org.nome}/${data_inizio}/${data_fine}`,
      { filter: filterPayload },
      { headers: authHeader() }
    )
    .then(handleAxiosResponse);
}

// function getReport(data_inizio, data_fine, lista_vendite) {
//   const requestOptions = {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       ...authHeader(),
//     },
//     body: JSON.stringify({
//       data_inizio,
//       data_fine,
//       lista_vendite,
//     }),
//   };
//
//   return fetch(`${config.api.url}/vendites/report`, requestOptions)
//     .then(handleResponse)
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       // TODO handle error
//       return false;
//     });
// }

function createVisitors(visitors) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify(visitors),
  };

  return fetch(`${config.api.url}/visitatoris/gun`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function modifyUserProfile(id, visitatore, paymentMethod) {
  var stringToSend =
    visitatore !== null
      ? JSON.stringify({
          visitatore,
        })
      : JSON.stringify({ metodo_pagamento: paymentMethod });
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: stringToSend,
  };

  return fetch(`${config.api.url}/vendites/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deleteSale(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return (
    fetch(`${config.api.url}/vendites/${id}`, requestOptions)
      //.then(fetch('${config.worker.url}/'))
      .then(handleResponse)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        // TODO handle error
        return false;
      })
  );
}

function deleteEntrance(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/ingressis/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

// //TEMP
// function getVisitors() {
//   const requestOptions = { method: "GET", headers: authHeader() };
//   return fetch(`${config.api.url}/visitatoris?_limit=-1`, requestOptions).then(
//     handleResponse
//   );
// }

function getInfoFromZipCode(zip) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(
    `${config.api.url}/visitatoris/capInfo/${zip}`,
    requestOptions
  ).then(handleResponse);
}

function getSections(org, startingDate, endingDate, interaction) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  const requestOptions = { method: "GET", headers: authHeader() };

  let url = ``;
  if (interaction) {
    url = `${config.worker.url}/getSections/${org.id}/${startingDate}/${endingDate}/${interaction}`;
  } else {
    url = `${config.worker.url}/getSections/${org.id}/${startingDate}/${endingDate}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function getObjects(org, startingDate, endingDate, selection, clickEnabled) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  const requestOptions = { method: "GET", headers: authHeader() };

  let escapedContent = selection;
  if (selection) {
    escapedContent = encodeURIComponent(selection);
  }

  let url = ``;
  if (clickEnabled) {
    url = `${config.worker.url}/getObjects/${org.id}/${startingDate}/${endingDate}/${escapedContent}/${clickEnabled}`;
  } else {
    url = `${config.worker.url}/getObjects/${org.id}/${startingDate}/${endingDate}/${escapedContent}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function getSectionTimes(org, startingDate, endingDate) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  const requestOptions = { method: "GET", headers: authHeader() };

  return fetch(
    `${config.worker.url}/getSectionTimes/${org.id}/${startingDate}/${endingDate}`,
    requestOptions
  ).then(handleResponse);
}

function getInteractions(org, startingDate, endingDate, clickEnabled) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  const requestOptions = { method: "GET", headers: authHeader() };

  let url = ``;
  if (clickEnabled) {
    url = `${config.worker.url}/getInteractions/${org.id}/${startingDate}/${endingDate}/${clickEnabled}`;
  } else {
    url = `${config.worker.url}/getInteractions/${org.id}/${startingDate}/${endingDate}`;
  }

  return fetch(url, requestOptions).then(handleResponse);
}

function getObjectsInteractions(
  org,
  startingDate,
  endingDate,
  sectionFilter,
  objectFilter
) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  const requestOptions = { method: "GET", headers: authHeader() };

  let url = `${config.worker.url}/getObjectInteractions/${org.id}/${startingDate}/${endingDate}/${sectionFilter}/${objectFilter}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getSectionObjectDistribution(
  org,
  startingDate,
  endingDate,
  selection
) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  const requestOptions = { method: "GET", headers: authHeader() };

  let escapedContent = selection;
  if (selection) {
    escapedContent = encodeURIComponent(selection);
  }

  let url = `${config.worker.url}/getSectionObjectDistribution/${org.id}/${startingDate}/${endingDate}/${escapedContent}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getInteractionsDistribution(org, startingDate, endingDate, selection) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  const requestOptions = { method: "GET", headers: authHeader() };

  let escapedContent = selection;
  if (selection) {
    escapedContent = encodeURIComponent(selection);
  }

  let url = `${config.worker.url}/getInteractionsDistribution/${org.id}/${startingDate}/${endingDate}/${escapedContent}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getSectionObjectTimes(org, startingDate, endingDate, selection) {
  if (!startingDate) {
    startingDate = moment().subtract(365, "days").format("YYYY-MM-DD");
  }
  if (!endingDate) {
    endingDate = moment().add(1, "days").format("YYYY-MM-DD");
  }

  const requestOptions = { method: "GET", headers: authHeader() };

  let escapedContent = selection;
  if (selection) {
    escapedContent = encodeURIComponent(selection);
  }

  let url = `${config.worker.url}/getSectionObjectTimes/${org.id}/${startingDate}/${endingDate}/${escapedContent}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function closeSalesVivaticket(startingDate, endingDate) {
  let parsedStartingDate = startingDate
    ? moment(startingDate).startOf("day").format("YYYYMMDDHHmmss")
    : moment().startOf("day").format("YYYYMMDDHHmmss");
  let parsedEndingDate = endingDate
    ? moment(endingDate).add(1, "day").startOf("day").format("YYYYMMDDHHmmss")
    : moment().add(1, "day").startOf("day").format("YYYYMMDDHHmmss");

  let oldBody = JSON.stringify({
    start: parsedStartingDate,
    end: parsedEndingDate,
  });

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: oldBody,
  };

  let url = `${config.api.url}/vendites/closeSales`;

  return fetch(url, requestOptions).then(handleResponse);
}
