let config = {
  environment: process.env.NODE_ENV || "development",
  api: {
    url:
      process.env.REACT_APP_API_URL || "http://start.teclasystem.com:5555/api", //"https://emmaapi.eu-gb.mybluemix.net", "http://localhost:1337",
  },
  worker: {
    url:
      process.env.REACT_APP_REPORT_URL ||
      "http://start.teclasystem.com:5555/report", //"http://localhost:5051", //"c", //"https://api.emma4culture.com"
  },
  shop: {
    url:
      process.env.REACT_APP_API_URL || "http://start.teclasystem.com:5555/api",
  },
  comuni: {
    url: process.env.REACT_APP_API_URL || "https://comuni.openapi.it",
    token: process.env.REACT_APP_API_URL || "6400672ff10a472722270f8c",
  },
  webapp: {
    url: process.env.REACT_APP_WEB_URL || "http://start.teclasystem.com:5555",
  },
};
module.exports = config;
