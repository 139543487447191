import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts";

const LineCustomLegend = (props) => {
  const colors = useContext(UserContext)?.colors;
  const [hiddenDatasets, setHiddenDatasets] = useState({}); // State to track visibility of datasets

  const toggleDatasetVisibility = (datasetLabel) => {
    setHiddenDatasets((prevHiddenDatasets) => ({
      ...prevHiddenDatasets,
      [datasetLabel]: !prevHiddenDatasets[datasetLabel],
    }));
  };

  useEffect(() => {
    props.setHiddenDatasets(hiddenDatasets);
  }, [props, hiddenDatasets]);

  return (
    <div
      id="scroll"
      style={{
        maxHeight: "200px",
        overflowX: "auto",
        overflowY: "none",
        display: "flex",
        flexDirection: "row",
        justifyContent: "start",
      }}
    >
      {props.data.datasets.map((dataset, index) => (
        <div
          key={index}
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            margin: "10px",
            width: "100%",
          }}
          onClick={() => toggleDatasetVisibility(dataset.label)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "45px",
              height: "15px",
              marginRight: "10px",
              paddingLeft: "4px",
              paddingRight: "4px",
              backgroundColor: props.data.datasets[index].borderColor,
              opacity: hiddenDatasets[dataset.label] ? "0.3" : "1",
            }}
          >
            <div
              style={{
                width: "38px",
                height: "8px",
                backgroundColor: colors.lightgray,
                opacity: hiddenDatasets[dataset.label] ? "0.3" : "1",
              }}
            />
          </div>
          {hiddenDatasets[dataset.label] ? (
            <s
              style={{
                fontSize: 12,
                color: colors.darkgray,
                margin: 0,
                width: "100%",
              }}
            >
              {dataset.label}
            </s>
          ) : (
            <p
              style={{
                fontSize: 12,
                color: colors.darkgray,
                margin: 0,
                width: "100%",
              }}
            >
              {dataset.label}
            </p>
          )}
        </div>
      ))}
    </div>
  );
};

export default LineCustomLegend;
