import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import DataBox from "../boxes/dataBox";
import { OrgContext, UserContext } from "../../../contexts";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { reportService } from "../../../_services";
import ReactLoading from "react-loading";
import LineCustomLegend from "../legends/lineCustomLegend";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const ProfitLineChart = (props) => {
  const org = useContext(OrgContext)?.org;
  const colors = useContext(UserContext)?.colors;

  const [labels, setLabels] = useState([]);

  //The selected data category
  const [selection, setSelection] = useState("");
  const prevSelection = usePrevious(selection);

  const [dateFilter, setDateFilter] = useState(
    props.isAnnual ? "Annuale" : "Mensile"
  );

  const [loader, setLoader] = useState(false);

  const tension = 0.4;

  const [hiddenDatasets, setHiddenDatasets] = useState({}); // State to track visibility of datasets

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    maintainAspectRatio: false,
    scales: {
      yAxis: {
        ticks: {
          callback: function (value) {
            if (value % 1 === 0) {
              return value;
            }
            return "";
          },
        },
      },
    },
  };

  const generatePastelColor = () => {
    let R = Math.floor(Math.random() * 127 + 127);
    let G = Math.floor(Math.random() * 127 + 127);
    let B = Math.floor(Math.random() * 127 + 127);

    let rgb = (R << 16) + (G << 8) + B;
    return `#${rgb.toString(16)}`;
  };

  useEffect(() => {
    if (!props.isAnnual) {
      let days = Math.ceil(
        (new Date(props.temporalFilters.end).getTime() -
          new Date(props.temporalFilters.start).getTime()) /
          (1000 * 3600 * 24) +
          1
      );

      let tmp = [];
      for (let i = 1; i <= days; i++) {
        tmp.push(i.toString());
      }

      setLabels(tmp);
    } else {
      setLabels([
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggio",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
      ]);
    }
  }, [props.temporalFilters, props.isAnnual]);

  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        fill: true,
        borderColor: "",
        tension: tension,
      },
    ],
  });

  const filteredData = {
    ...data,
    datasets: data.datasets.map((dataset) => ({
      ...dataset,
      data: dataset.data.map((value, index) =>
        hiddenDatasets[dataset.label] ? null : value
      ),
    })),
  };

  const handleSelection = (selection) => {
    setSelection(selection);
  };

  const getGraphStats = useCallback(() => {
    setLoader(true);
    reportService
      .getGraphVendite(
        org,
        selection.charAt(0).toUpperCase() + selection.slice(1),
        dateFilter,
        props.temporalFilters.start,
        props.temporalFilters.end
      )
      .then((data) => {
        var dataLabels = data.labels;
        var datasets = [];
        for (const label of dataLabels) {
          var dataList = [];
          var borderColor = generatePastelColor();

          for (const key in data.values) {
            var tmpValue = 0;
            for (const innerKey in data.values[key]) {
              if (innerKey.toString() === label.toString()) {
                tmpValue = data.values[key][innerKey];
              }
            }
            dataList.push(tmpValue);
          }

          var labelData = {
            label: label,
            data: dataList,
            fill: true,
            borderColor: borderColor,
            tension: tension,
          };

          datasets.push(labelData);
        }

        setData({ labels, datasets });
        setLoader(false);
      });
  }, [
    dateFilter,
    labels,
    org,
    props.temporalFilters.end,
    props.temporalFilters.start,
    selection,
  ]);

  useEffect(() => {
    if (
      selection !== "" &&
      selection !== undefined &&
      prevSelection !== selection
    ) {
      getGraphStats();
    }
  }, [selection, prevSelection, getGraphStats]);

  useEffect(() => {
    setDateFilter(props.isAnnual ? "Annuale" : "Mensile");
  }, [props.isAnnual]);

  return (
    <DataBox
      title={"Andamento Incassi, Costi e Profitti"}
      body={
        loader ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "230px",
              paddingBottom: "230px",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={colors?.primary}
              height={50}
              width={50}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <LineCustomLegend
                data={data}
                setHiddenDatasets={setHiddenDatasets}
              />
            </div>
            <div style={{ width: "100%" }}>
              <Line data={filteredData} options={options} height={"500%"} />
            </div>
          </div>
        )
      }
      isMean={false}
      handleSelection={(selection) => handleSelection(selection)}
      contentFilters={props.categories}
    />
  );
};

export default ProfitLineChart;
