import React from "react";
import modify from "./../../../../img/modify_gray.png";
import trash from "./../../../../img/delete_gray.png";
import ActivityBox from "./activityBox";

const ActivityCategory = ({
  onDelete,
  onModify,
  category,
  eventsList,
  onAddSchema,
  toggleInfo,
  colors,
}) => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: 3,
      borderBottom: "3px solid",
      borderColor: colors.secondary,
    },
    title: {
      color: colors.secondary,
      marginLeft: 30,
      fontWeight: "700",
    },
    alignHorizontal: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      marginRight: 30,
    },
    content: {
      backgroundColor: colors.highlight,
    },
    scroller: {
      overflowY: "scroll",
      display: "flex",
      alignItems: "flex-start",
    },
    row: {
      display: "inline-flex",
    },
  };
  return (
    <div style={{ width: "100%" }}>
      <div style={styles.container}>
        <p style={styles.title}>{category.nome.toUpperCase()}</p>
        <div style={styles.alignHorizontal}>
          <>
            <button
              onClick={() => onDelete()}
              style={{
                border: "none",
                backgroundColor: "transparent",
                outline: "none",
                cursor: "pointer",
              }}
            >
              <img
                alt="Delete icon"
                src={trash}
                style={{ width: 15, objectFit: "contain", margin: 10 }}
              />
            </button>
            <button
              onClick={() => onModify()}
              style={{
                border: "none",
                backgroundColor: "transparent",
                outline: "none",
                cursor: "pointer",
              }}
            >
              <img
                alt="Modify icon"
                src={modify}
                style={{ width: 15, objectFit: "contain", margin: 10 }}
              />
            </button>
          </>
        </div>
      </div>
      <div id="scroll" style={styles.scroller}>
        <div style={styles.row}>
          {eventsList.length > 0 &&
            eventsList.map((item, key) => (
              <ActivityBox
                key={key}
                addFlag={false}
                activity={item}
                toggleInfo={() => toggleInfo(item)}
                colors={colors}
              />
            ))}
          <ActivityBox
            addFlag={true}
            onAdd={() => onAddSchema()}
            colors={colors}
          />
        </div>
      </div>
    </div>
  );
};

export default ActivityCategory;
