import React from "react";
import location from "./../../../img/location_secondary.png";
import placeholder from "./../../../img/placeholder_img.png";
import duplicate from "./../../../img/duplicate.png";
import moment from "moment";
import "moment/locale/it";
moment.locale("it");

const ArchiveEventBox = ({ event, toggleCopy, toggleInfo, colors }) => {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      width: 220,
      height: 280,
      borderRadius: 30,
      alignItems: "center",
      boxShadow: "1px 2px 1px 1px #A9A9A9",
    },
    addContainer: {
      display: "flex",
      flexDirection: "column",
      width: 220,
      height: 280,
      backgroundColor: colors?.secondary,
      borderRadius: 30,
      justifyContent: "center",
      alignItems: "center",
      opacity: 0.4,
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      display: "flex",
      flexDirection: "column",
      width: 250,
      height: 300,
      borderRadius: 30,
      justifyContent: "center",
      alignItems: "center",
      margin: 10,
      cursor: "pointer",
    },
    header: {
      height: 110,
      width: "100%",
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
    },
    body: {
      width: "100%",
      height: 180,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderBottomLeftRadius: 30,
      borderBottomRightRadius: 30,
    },
    title: {
      color: colors?.primary,
      maxWidth: 190,
      fontWeight: "600",
      fontSize: 18,
      maxHeight: 20,
      margin: 2,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    data: {
      color: colors?.primary,
      width: "60%",
      fontSize: 12,
      textAlign: "center",
    },
    location: {
      color: colors?.secondary,
      maxWidth: 200,
      fontSize: 14,
    },
  };

  return (
    <div style={styles.button} onClick={() => toggleInfo()}>
      <div style={styles.container}>
        <div style={styles.header}>
          <img
            alt="Header"
            src={
              event.immagine_evento ? event.immagine_evento.url : placeholder
            }
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
            }}
          />
        </div>
        <div style={styles.body}>
          <div style={styles.title}>
            {event.nome_evento.substring(0, 36)}
            {event.nome_evento.length > 33 && "..."}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <img
              alt="Location icon"
              src={location}
              style={{ width: 10, objectFit: "contain", marginRight: 10 }}
            />
            <p style={styles.location}>
              {event.luogo_evento.substring(0, 20)}
              {event.luogo_evento.length > 30 && "..."}
            </p>
          </div>
          {moment(event.ora_inizio).format("DD/MM/yyyy") ===
          moment(event.ora_fine).format("DD/MM/yyyy") ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "80%",
              }}
            >
              <p style={styles.data}>
                {moment(event.ora_inizio).format("DD/MM/yyyy")}
              </p>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "80%",
              }}
            >
              <p style={styles.data}>
                {"Dal " + moment(event.ora_inizio).format("DD/MM/yyyy")}
              </p>
              <p style={styles.data}>
                {" al " + moment(event.ora_fine).format("DD/MM/yyyy")}
              </p>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "right",
              width: "100%",
            }}
          >
            <button
              style={{ ...styles.button, height: 30, width: 50 }}
              onClick={(e) => {
                toggleCopy();
                e.stopPropagation();
              }}
            >
              <img
                alt="Duplicate icon"
                src={duplicate}
                style={{ height: 25, objectFit: "contain" }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchiveEventBox;
