import React, { useEffect, useState, useContext, useRef } from "react";
import ReactExport from "react-export-excel";
import { vouchersService } from "../../_services";
import search from "../../img/search.png";
import add from "../../img/add.png";
import download from "../../img/download.png";
import refresh from "../../img/refresh.png";
import { LanguageContext } from "../../containers/language";
import ReactLoading from "react-loading";
import Table from "./table";
import moment from "moment";
import "moment/locale/it";
import AddFromFile from "./addVouchersPopup";
import Pagination from "react-js-pagination";

moment.locale("it");
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const itemsPerPageOptions = [10, 20, 50, 100];

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const AllVouchers = ({
  vouchersCategories,
  vouchersCategoriesLoaded,
  colors,
  orgId,
}) => {
  const [vouchersList, setVouchersList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [extendedVouchersCategories, setExtendedVouchersCategories] = useState(
    []
  );
  const [loader, setLoader] = useState(false);

  const [chosenVouchersCategory, setChosenVouchersCategory] = useState(null);

  // filters
  const [tempSearchKey, setTempSearchKey] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [hasKeyword, setHasKeyword] = useState(false);

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const prevActivePage = usePrevious(activePage);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  //const prevItemsPerPage = usePrevious(itemsPerPage);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const { dictionary } = useContext(LanguageContext);

  const [showAddExcel, setShowAddExcel] = useState(false);

  const validitiesList = [
    { id: 0, name: "Tutti" },
    { id: 1, name: "Non convalidati" },
    { id: 2, name: "Convalidati" },
  ];
  const [validityFilter, setValidityFilter] = useState(null);

  useEffect(() => {
    if (vouchersCategories.length > 0) {
      var temp = [{ id: -1, nome: "Tutte" }, ...vouchersCategories];
      setExtendedVouchersCategories(temp);
      setChosenVouchersCategory(temp[0]);
      setValidityFilter(validitiesList[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vouchersCategories]);

  const getVouchersList = (
    chosenVouchersCategoryId,
    orgId,
    start,
    limit,
    validityFilterId,
    hasKeyword,
    searchKey
  ) => {
    setLoader(true);

    // count the results for the pagination
    vouchersService
      .countVouchers(
        chosenVouchersCategoryId,
        orgId,
        validityFilterId,
        hasKeyword,
        searchKey
      )
      .then((tot) => setTotalItemsCount(tot));

    //get the results and set them
    vouchersService
      .getVouchers(
        chosenVouchersCategoryId,
        orgId,
        start,
        limit,
        validityFilterId,
        hasKeyword,
        searchKey
      )
      .then((res) => {
        const deconstructedRes = JSON.parse(JSON.stringify(res));
        setVouchersList(deconstructedRes);
        setLoader(false);
      });
  };

  const getAllVouchersList = (orgId, chosenVouchersCategoryId) => {
    vouchersService
      .getVouchers(chosenVouchersCategoryId, orgId, 0, -1, 0, false, "")
      .then((allRes) => {
        var finalColumns = [
          "Categoria",
          "Codice",
          "Tipologia",
          "Scadenza",
          "Utilizzo",
          "Operatore",
        ];
        const finalData = allRes.map((item) => {
          const res = [
            item.categoria_voucher.nome,
            item.codice.toString(),
            item.tipologia,
            moment(item.scadenza).format("DD/MM/yyyy"),
            item.data_utilizzo
              ? moment(item.data_utilizzo).format("DD/MM/yyyy")
              : "-",
            item.operatore ? item.operatore : "-",
          ];
          return res;
        });
        setExcelData([{ columns: finalColumns, data: finalData }]);
      });
  };

  const startingSearch = () => {
    // reset searchKeyword and hasKeyword
    let currentSearchKey = "";
    let currentHasKeyword = false;
    setSearchKey(currentSearchKey);
    setHasKeyword(currentHasKeyword);

    let tempActivePage = 1;
    setActivePage(tempActivePage);

    getVouchersList(
      chosenVouchersCategory.id,
      orgId,
      itemsPerPage * tempActivePage - itemsPerPage,
      itemsPerPage,
      validityFilter.id,
      currentHasKeyword,
      encodeURIComponent(currentSearchKey.trim())
    );
    getAllVouchersList(orgId, chosenVouchersCategory.id);
  };

  const toggleAddExcel = () => {
    if (showAddExcel) {
      window.location.reload(true);
    }
    setShowAddExcel(!showAddExcel);
  };

  useEffect(() => {
    let tempActivePage = activePage;
    if (prevActivePage === activePage) {
      tempActivePage = 1;
      setActivePage(tempActivePage);
    }
    if (chosenVouchersCategory && validityFilter) {
      getVouchersList(
        chosenVouchersCategory.id,
        orgId,
        itemsPerPage * tempActivePage - itemsPerPage,
        itemsPerPage,
        validityFilter.id,
        hasKeyword,
        encodeURIComponent(searchKey.trim())
      );
      getAllVouchersList(orgId, chosenVouchersCategory.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenVouchersCategory, validityFilter, activePage, orgId, itemsPerPage]);

  useEffect(() => {
    const handleSubmit = () => {
      let tempHasKeyword = false;
      setSearchKey(tempSearchKey);
      if (tempSearchKey !== "") {
        tempHasKeyword = true;
      } else {
        tempHasKeyword = false;
      }
      setHasKeyword(tempHasKeyword);
      let tempActivePage = 1;
      setActivePage(tempActivePage);
      getVouchersList(
        chosenVouchersCategory.id,
        orgId,
        itemsPerPage * tempActivePage - itemsPerPage,
        itemsPerPage,
        validityFilter.id,
        tempHasKeyword,
        encodeURIComponent(tempSearchKey.trim())
      );
      getAllVouchersList(orgId, chosenVouchersCategory.id);
    };

    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, tempSearchKey]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      overflowX: "scroll",
    },
    label: {
      fontSize: 13,
      color: colors.gray,
    },
    menu: {
      border: "1px solid lightgray",
      width: 150,
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 10,
      color: colors.darkgray,
      borderRadius: 20,
      backgroundColor: "transparent",
      outline: "none",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "50%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    rowTop: {
      width: "90%",
      minHeight: 150,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      borderBottom: "1px solid darkgray",
    },
    rowBottom: {
      display: "flex",
      justifyContent: "center",
      width: "90%",
      overflow: "scroll",
    },
    buttonBox: {
      display: "flex",
      flexDirection: "row",
    },
    subcontainer: {
      width: "100%",
      paddingBottom: 20,
      display: "flex",
      flexDirection: "column",
    },
    inputSearch: {
      borderRadius: 40,
      border: "1px solid #F0F0F0",
      width: "50%",
      height: 40,
      display: "flex",
      flexDirection: "row",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
    downloadButton: {
      border: "none",
      outline: "none",
      backgroundColor: colors.darkgray,
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      width: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    goBackButton: {
      width: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    saveButton: {
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: colors.darkgray,
      margin: 30,
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 15,
    },
    arrow: {
      height: 13,
      objectFit: "contain",
    },
    filterButton: {
      border: "1px solid #F0F0F0",
      outline: "none",
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.body}>
        {vouchersCategories.length > 0 && (
          <div style={styles.rowTop}>
            <div style={styles.selectBox}>
              <p style={styles.label}>
                Seleziona la categoria di voucher da visualizzare
              </p>
              <select
                name={chosenVouchersCategory?.nome}
                style={styles.menu}
                value={chosenVouchersCategory?.id}
                onChange={(event) => {
                  setChosenVouchersCategory(
                    extendedVouchersCategories[event.target.selectedIndex]
                  );
                  setTempSearchKey("");
                  setSearchKey("");
                  setValidityFilter(validitiesList[0]);
                }}
              >
                {extendedVouchersCategories.map((type, key) => (
                  <option key={key} value={type.id}>
                    {type.nome}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        <div style={styles.rowBottom}>
          <div style={styles.subcontainer}>
            {loader || !vouchersCategoriesLoaded ? (
              <div
                style={{
                  display: "flex",
                  marginTop: 40,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ReactLoading
                  type={"spinningBubbles"}
                  color={colors?.primary}
                  height={50}
                  width={50}
                />
              </div>
            ) : (
              <>
                <div style={{ ...styles.row }}>
                  <div
                    style={{
                      ...styles.row,
                      justifyContent: "start",
                      width: "100%",
                    }}
                  >
                    {totalItemsCount > 1 || totalItemsCount === 0 ? (
                      <p
                        style={{
                          color: colors?.gray,
                          fontSize: 11,
                          textAlign: "left",
                        }}
                      >
                        {totalItemsCount} voucher trovati
                      </p>
                    ) : (
                      <p
                        style={{
                          color: colors?.gray,
                          fontSize: 11,
                          textAlign: "left",
                        }}
                      >
                        {totalItemsCount} voucher trovato
                      </p>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    ...styles.row,
                    justifyContent:
                      vouchersCategories.length > 0
                        ? "space-between"
                        : "center",
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  {vouchersCategories.length > 0 && (
                    <div style={styles.inputSearch}>
                      <img
                        alt="Search icon"
                        src={search}
                        style={{
                          width: 30,
                          objectFit: "contain",
                          margin: 10,
                        }}
                      />
                      <input
                        type="text"
                        style={{
                          border: 0,
                          width: "100%",
                          borderRadius: 40,
                          outline: "none",
                        }}
                        value={tempSearchKey}
                        onChange={(event) => {
                          setTempSearchKey(event.target.value);
                        }}
                        placeholder={dictionary.search}
                      ></input>
                      <button
                        style={{
                          ...styles.filterButton,
                        }}
                        onClick={() => {
                          setTempSearchKey("");
                          setSearchKey("");
                          startingSearch(); //come back to the original result search (i.e. the ones at the page opening)
                        }}
                      >
                        <img
                          alt="Refresh icon"
                          src={refresh}
                          style={{
                            width: 18,
                            objectFit: "contain",
                            margin: 2,
                          }}
                        />
                      </button>
                    </div>
                  )}

                  {validityFilter && (
                    <select
                      name={validityFilter.name}
                      style={styles.menu}
                      value={validityFilter.id}
                      onChange={(event) => {
                        setValidityFilter(
                          validitiesList[event.target.selectedIndex]
                        );
                      }}
                    >
                      {validitiesList.map((filter, key) => (
                        <option key={key} value={filter.id}>
                          {filter.name}
                        </option>
                      ))}
                    </select>
                  )}

                  <div style={styles.buttonBox}>
                    <button
                      style={styles.downloadButton}
                      onClick={() => {
                        toggleAddExcel();
                      }}
                    >
                      <img
                        alt="Add icon"
                        src={add}
                        style={{ width: 15, objectFit: "contain" }}
                      />
                    </button>

                    {excelData[0]?.data.length > 0 && (
                      <ExcelFile
                        filename={"Voucher_" + chosenVouchersCategory?.nome}
                        element={
                          <button
                            style={{ ...styles.downloadButton, marginLeft: 10 }}
                          >
                            <img
                              alt="Download icon"
                              src={download}
                              style={{ width: 15, objectFit: "contain" }}
                            />
                          </button>
                        }
                      >
                        <ExcelSheet
                          dataSet={excelData}
                          name={chosenVouchersCategory?.nome}
                        />
                      </ExcelFile>
                    )}
                  </div>
                </div>
                <div id="scroll" style={{ overflowY: "scroll" }}>
                  {vouchersList.length > 0 &&
                  chosenVouchersCategory &&
                  validityFilter ? (
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <Table
                        vouchersList={vouchersList}
                        chosenVouchersCategory={chosenVouchersCategory}
                        validityFilter={validityFilter}
                        colors={colors}
                        activePage={activePage}
                        itemsPerPage={itemsPerPage}
                      />
                    </div>
                  ) : (
                    vouchersCategoriesLoaded && (
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          color: colors.gray,
                          marginTop: 40,
                        }}
                      >
                        {chosenVouchersCategory &&
                        chosenVouchersCategory.id !== -1
                          ? "Nessun voucher corrispondente ai parametri selezionati"
                          : "Nessun voucher disponibile"}
                      </p>
                    )
                  )}
                </div>
              </>
            )}
            {showAddExcel && (
              <AddFromFile
                closeModal={toggleAddExcel}
                settingsId={orgId}
                colors={colors}
              />
            )}
          </div>
        </div>
      </div>
      <div style={{ width: "90%" }}>
        {vouchersList.length > 0 &&
          totalItemsCount > itemsPerPageOptions[0] &&
          chosenVouchersCategory &&
          validityFilter && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gridAutoRows: "1fr",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <select
                  name="options"
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: 15,
                    height: 50,
                    width: 70,
                    padding: 10,
                    textAlign: "left",
                    marginRight: 10,
                  }}
                  value={itemsPerPage}
                  onChange={(event) => {
                    setItemsPerPage(parseInt(event.target.value));
                    setActivePage(1);
                  }}
                >
                  {itemsPerPageOptions.map((option, key) => {
                    return (
                      <option key={key} value={option}>
                        {option}
                      </option>
                    );
                  })}
                </select>
                <p style={{ color: colors?.gray, fontSize: 12 }}>
                  {dictionary.pageElements}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={itemsPerPage}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={5}
                  onChange={(pageNumber) => setActivePage(pageNumber)}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default AllVouchers;
