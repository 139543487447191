import React from "react";
import colors from "../../museumColors";
import ReactLoading from "react-loading";

const Loading = () => {
  const styles = {
    body: {
      flex: 1,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  return (
    <div style={styles.body}>
      <ReactLoading
        type={"spinningBubbles"}
        color={colors.darkgray}
        height={50}
        width={50}
      />
    </div>
  );
};

export default Loading;
