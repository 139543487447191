import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../../contexts";
import { itemsService } from "../../_services";
import { LanguageContext } from "../../containers/language";
import checked from "./../../img/reserved.png";
import error from "./../../img/closeButton.png";
import ReactLoading from "react-loading";

const RegisterEntrance = React.memo(() => {
  const colors = useContext(UserContext)?.colors;
  const { dictionary } = useContext(LanguageContext);

  const [response, setResponse] = useState(undefined);
  const [showFeedback, setShowFeedback] = useState(false);
  const [code, setCode] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  /*
  // Check ticket validity for entrance
  */
  const checkTicketCode = (code) => {
    setShowLoading(true);
    itemsService
      .checkEntrance(code)
      .then((response) => {
        setResponse(response);
        setShowLoading(false);
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          this.props.history.push("/");
        }
      });
  };

  useEffect(() => {
    if (showFeedback) {
      setTimeout(() => {
        setShowFeedback(false);
      }, 7000);
    }
    if (response?.status) {
      setCode("");
    }
  }, [showFeedback, response, setCode]);

  useEffect(() => {
    if (response !== undefined) {
      setShowFeedback(true);
    }
  }, [response]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    content: {
      flexDirection: "column",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    title: { fontWeight: "700", color: colors?.primary, fontSize: 30 },

    field: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "70%",
      marginLeft: 50,
      marginRight: 50,
    },
    footer: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors?.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      backgroundColor: colors?.primary,
      color: colors?.white,
      padding: 20,
      borderRadius: 20,
      border: "none",
      outline: "none",
      cursor: "pointer",
      width: 300,
      marginTop: 50,
    },
    label: {
      fontSize: 15,
      color: colors?.lightgray,
      margin: 20,
      width: 150,
    },
    message: {
      backgroundColor: colors?.white,
      padding: 15,
      fontSize: 13,
      color: colors?.highlight,
    },
    feedback: {
      position: "absolute",
      top: 0,
      width: "100%",
      backgroundColor: response?.status ? colors?.green : colors?.red,
      height: 100,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return (
    <div style={styles.container}>
      {showFeedback && (
        <div style={styles.feedback}>
          <img
            alt={"Feedback icon"}
            src={response?.status ? checked : error}
            style={{
              width: 20,
              padding: 10,
              borderRadius: 30,
            }}
          ></img>
          <p
            style={{
              color: colors?.white,
              fontWeight: "600",
              marginLeft: 10,
            }}
          >
            {response.message}
          </p>
        </div>
      )}
      {!showLoading ? (
        <div style={styles.content}>
          <p style={styles.title}>
            {dictionary.entranceRegister.toUpperCase()}
          </p>
          <p
            style={{
              fontSize: 15,
              marginBottom: 40,
              width: "70%",
              color: colors?.lightgray,
            }}
          >
            {dictionary.entranceCheck}
          </p>
          <div style={styles.field}>
            <p style={styles.label}>{dictionary.ticketCode}</p>
            <div style={{ width: 300, display: "flex" }}>
              <input
                value={code}
                onChange={(event) => setCode(event.target.value)}
                placeholder={dictionary.code}
                style={{
                  border: "1px solid lightgray",
                  borderRadius: 10,
                  width: "60%",
                  padding: 18,
                  outline: "none",
                }}
              />
            </div>
          </div>
          <button
            onClick={() => checkTicketCode(code)}
            disabled={code === ""}
            style={{ ...styles.button, opacity: code === "" && 0.7 }}
          >
            {dictionary.register.toUpperCase()}
          </button>
        </div>
      ) : (
        <div style={styles.content}>
          <ReactLoading
            type={"spinningBubbles"}
            color={colors?.primary}
            height={50}
            width={50}
          />
        </div>
      )}
    </div>
  );
});

export default RegisterEntrance;
