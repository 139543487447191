import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../contexts";
import ReservationButton from "../../../components/buttons/reservationButton";
import close from "./../../../img/closeButton.png";
import { reservationsService } from "../../../_services";
import moment from "moment";
import ReactLoading from "react-loading";
import ReservationInfo from "./reservationInfo";
import EventInfo from "./eventinfo";

function withoutTime(date) {
  var d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return d;
}

function spaceFilter(string) {
  let checkedString = string;
  if (checkedString.startsWith(" ")) {
    checkedString = checkedString.substring(1);
  }
  if (checkedString.endsWith(" ")) {
    checkedString = checkedString.substring(0, checkedString.length - 1);
  }
  return checkedString;
}

const SideColumn = ({
  history,
  dateSelected,
  isClosed,
  onBooking,
  setOnBooking,
  selectedVisits,
  setVisitsList,
  setSelectedVisits,
  setEventsList,
  setEventSelected,
  setShowDeleteAccessReservation,
  showDeleteAccessReservation,
  setReservationSelected,
  setEventId,
  idSelected,
  setIdSelected,
  selectedEvents,
}) => {
  const [height, setHeight] = useState(window.innerHeight);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [isEmail, setIsEmail] = useState(false);
  const [showError, setShowError] = useState(false);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [loader, setLoader] = useState(false);

  const colors = useContext(UserContext)?.colors;

  const styles = {
    infoBox: {
      width: 600,
      height: "100%",
      overflowY: "scroll",
      backgroundColor: colors?.tertiary,
    },
    header: {
      height: 100,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
    body: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderBottomLeftRadius: 30,
      borderBottomRightRadius: 30,
    },
    label: {
      color: colors?.secondary,
      width: "80%",
      fontSize: 13,
      textAlign: "left",
    },
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      cursor: "pointer",
    },
    textInput: {
      borderRadius: 5,
      border: 0,
      outline: "none",
      textAlign: "center",
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
      width: "80%",
    },
    menu: {
      border: "1px solid lightgray",
      width: "88%",
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
      color: colors?.darkgray,
      borderRadius: 5,
      backgroundColor: colors?.white,
      outline: "none",
    },
    field: {
      fontSize: 15,
    },
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let error = re.test(String(email).toLowerCase());
    if (email === "") {
      setShowError(false);
    }
    setIsEmail(error);
    return error;
  };

  const preventVisitReservation = () => {
    return (
      name === "" ||
      surname === "" ||
      selectedTime === "" ||
      number === "" ||
      number === "0" ||
      mobile === "" ||
      email === ""
    );
  };

  const resetForm = () => {
    setOnBooking(!onBooking);
    setName("");
    setSurname("");
    setEmail("");
    setMobile("");
    setNumber("");
    setSelectedTime("");
  };

  const toggleBooking = () => {
    resetForm();
    reservationsService
      .getAvailableSlot(moment(dateSelected).format("yyyy-MM-DD"))
      .then((slots) => {
        setAvailableSlots(slots);
        setSelectedTime(slots[0]);
      });
  };

  const createVisitReservation = () => {
    let starting = new Date(
      dateSelected.getFullYear(),
      dateSelected.getMonth(),
      dateSelected.getDate(),
      selectedTime.split(":")[0],
      selectedTime.split(":")[1]
    );
    reservationsService
      .createVisitReservation(
        spaceFilter(name),
        spaceFilter(surname),
        parseInt(number),
        spaceFilter(email.toLowerCase()),
        mobile,
        starting
      )
      .then((visits) => {
        setOnBooking(false);
        setLoader(false);
        visits.forEach((visit) => {
          visit.ora_inizio = new Date(visit.ora_inizio);
          visit.ora_fine = new Date(visit.ora_fine);
          visit.nome_evento =
            visit.cognome_partecipante + " x " + visit.numero_partecipanti;
        });
        setVisitsList(visits);
        setSelectedVisits(
          visits.filter(
            (visit) =>
              moment(visit.ora_inizio).format("yyyy-MM-DD") ===
              moment(dateSelected).format("yyyy-MM-DD")
          )
        );
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          history.push("/");
        }
      });
  };

  const modifyReservation = (id, eventId, newNumber) => {
    reservationsService
      .modifyReservation(id, newNumber)
      .then((response) => {
        let temp = response;
        for (let i = 0; i < temp.length; i++) {
          temp[i].ora_inizio = new Date(temp[i].ora_inizio);
          temp[i].ora_fine = new Date(temp[i].ora_fine);
        }
        if (temp[0].flag_visita) {
          setVisitsList(temp);
        } else {
          setEventsList(temp);
          setEventSelected(temp.filter((e) => e.id === eventId)[0]);
        }
      })
      .catch((errors) => {
        if (errors.statusCode === 401 || errors.statusCode === 403) {
          history.push("/");
        }
      });
  };

  const toggleDeleteAccessReservation = (reservation, eventId) => {
    setShowDeleteAccessReservation(!showDeleteAccessReservation);

    if (showDeleteAccessReservation) {
      setReservationSelected(null);
      setEventId(null);
    } else {
      setReservationSelected(reservation);
      setEventId(eventId);
    }
  };

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newHeight = window.innerHeight;
      setHeight(newHeight);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <div id="scroll" style={styles.infoBox}>
      <div style={{ ...styles.header, marginTop: 30 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "baseline",
          }}
        >
          <p
            style={{
              fontSize: 70,
              fontWeight: "bold",
              color: colors?.white,
              margin: 0,
              marginRight: 10,
            }}
          >
            {moment(dateSelected).format("D")}
          </p>
          <p
            style={{
              fontSize: 25,
              color: colors?.white,
              margin: 0,
              marginRight: 20,
            }}
          >
            {moment(dateSelected).format("MMMM")}
          </p>
        </div>
        <p
          style={{
            fontSize: 15,
            color: colors?.white,
            margin: 0,
            marginRight: 20,
          }}
        >
          {moment(dateSelected).format("dddd")}
        </p>
      </div>
      {isClosed ? (
        <div>
          <div
            style={{
              width: "100%",
              backgroundColor: colors?.secondary,
              color: colors?.white,
              fontWeight: "bold",
              paddingTop: 20,
              paddingBottom: 20,
              marginTop: 20,
            }}
          >
            CHIUSO
          </div>
          <div>
            {selectedEvents.length > 0 ? (
              <div style={styles.field}>
                <p
                  style={{
                    color: colors?.secondary,
                    fontSize: 13,
                    textAlign: "left",
                    marginLeft: 15,
                  }}
                >
                  Eventi
                </p>
                <div style={styles.list}>
                  {selectedEvents.map((e, key) => {
                    return (
                      <EventInfo
                        key={key}
                        event={e}
                        idSelected={idSelected}
                        setIdSelected={setEventSelected}
                        setEventSelected={setEventSelected}
                        colors={colors}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              <p
                style={{
                  color: colors?.secondary,
                  fontSize: 13,
                }}
              >
                Nessun evento.
              </p>
            )}
          </div>
        </div>
      ) : (
        <div style={styles.body}>
          {withoutTime(moment(dateSelected).format("yyyy-MM-DD")) >=
            withoutTime(new Date()) &&
            !onBooking && (
              <ReservationButton toggle={toggleBooking} colors={colors} />
            )}
          <div style={{ width: "100%" }}>
            {onBooking ? (
              <>
                <button
                  style={{
                    ...styles.button,
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  onClick={toggleBooking}
                >
                  <img
                    alt="Back Button"
                    src={close}
                    style={{
                      height: 15,
                      objectFit: "contain",
                      margin: 20,
                    }}
                  />
                </button>
                <form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    overflowY: "scroll",
                    height: height - 300,
                  }}
                >
                  <label style={styles.label}>Orario ingresso</label>
                  <select
                    name="time"
                    style={styles.menu}
                    value={selectedTime}
                    onChange={(event) => {
                      setSelectedTime(event.target.value);
                    }}
                  >
                    {availableSlots.map((slot, key) => {
                      return (
                        <option key={key} value={slot}>
                          {slot}
                        </option>
                      );
                    })}
                  </select>
                  <label style={styles.label}>Nome</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    placeholder="Nome"
                    style={{ ...styles.textInput }}
                  />
                  <label style={styles.label}>Cognome</label>
                  <input
                    type="text"
                    name="surname"
                    value={surname}
                    onChange={(event) => setSurname(event.target.value)}
                    placeholder="Cognome"
                    style={{ ...styles.textInput }}
                  />
                  <label style={styles.label}>Numero partecipanti</label>
                  <input
                    type="number"
                    name="number"
                    value={number}
                    onChange={(event) => setNumber(event.target.value)}
                    placeholder="Numero partecipanti"
                    style={{ ...styles.textInput }}
                  />
                  <label style={styles.label}>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                      validateEmail(event.target.value);
                    }}
                    placeholder="Email"
                    style={{ ...styles.textInput }}
                  />
                  {showError && !isEmail && (
                    <p
                      style={{
                        color: colors?.highlight,
                        marginTop: 2,
                        fontSize: 10,
                        width: "72%",
                      }}
                    >
                      Email non valida
                    </p>
                  )}

                  <label style={styles.label}>Telefono</label>
                  <input
                    type="tel"
                    name="mobile"
                    value={mobile}
                    onChange={(event) =>
                      setMobile(event.target.value.replace(/[^\d]/, ""))
                    }
                    placeholder="Cellulare"
                    style={{ ...styles.textInput }}
                  />
                </form>
                {loader ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 30,
                    }}
                  >
                    <ReactLoading
                      type={"spinningBubbles"}
                      color={colors?.primary}
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <button
                    style={{
                      ...styles.button,
                      width: 200,
                      backgroundColor: colors?.secondary,
                      color: colors?.white,
                      padding: 10,
                      borderRadius: 15,
                      margin: 30,
                      opacity: preventVisitReservation() ? 0.5 : 1,
                    }}
                    onClick={() => {
                      if (!isEmail) {
                        setShowError(true);
                      } else {
                        setLoader(true);
                        setShowError(false);
                        createVisitReservation();
                      }
                    }}
                    disabled={preventVisitReservation()}
                  >
                    PRENOTA
                  </button>
                )}
              </>
            ) : (
              <div>
                {selectedEvents.length > 0 ? (
                  <div style={styles.field}>
                    <p
                      style={{
                        color: colors?.secondary,
                        fontSize: 13,
                        textAlign: "left",
                        marginLeft: 15,
                      }}
                    >
                      Eventi
                    </p>
                    <div style={styles.list}>
                      {selectedEvents.map((e, key) => {
                        return (
                          <EventInfo
                            key={key}
                            event={e}
                            idSelected={idSelected}
                            setIdSelected={setEventSelected}
                            setEventSelected={setEventSelected}
                            colors={colors}
                          />
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <p
                    style={{
                      color: colors?.secondary,
                      fontSize: 13,
                    }}
                  >
                    Nessun evento.
                  </p>
                )}
                {selectedVisits.length > 0 ? (
                  <div style={styles.field}>
                    <p
                      style={{
                        color: colors?.secondary,
                        fontSize: 13,
                        textAlign: "left",
                        marginLeft: 15,
                      }}
                    >
                      Prenotati
                    </p>
                    <div style={styles.list}>
                      {selectedVisits.map((e, key) => {
                        return (
                          <ReservationInfo
                            key={key}
                            reservation={e}
                            idSelected={idSelected}
                            setIdSelected={setIdSelected}
                            onModifyReservation={modifyReservation}
                            onDeleteReservation={toggleDeleteAccessReservation}
                          />
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <p
                    style={{
                      color: colors?.secondary,
                      fontSize: 13,
                    }}
                  >
                    Nessuna prenotazione.
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SideColumn;
