import moment from "moment";
import config from "../config";
import { handleResponse, authHeader } from "../_helpers";

export const inventoryService = {
  getTransactions,
  countTransactions,
  getPurchases,
  countPurchases,
  invoiceManagement,
  modifyPurchase,
  deletePurchase,
};

function getTransactions(
  org,
  filter = {},
  start,
  limit,
  sort = [{ datetime: "desc" }]
) {
  let filterCopy = { ...filter };
  if (!filterCopy.datetime_azione_gte) {
    filterCopy.datetime_azione_gte = moment()
      .startOf("month")
      .format("YYYY-MM-DD");
  }
  if (!filterCopy.datetime_azione_lt) {
    filterCopy.datetime_azione_lt = moment()
      .add(1, "days")
      .format("YYYY-MM-DD");
  } else {
    filterCopy.datetime_azione_lt = moment(filterCopy.datetime_azione_lt)
      .add(1, "days")
      .format("YYYY-MM-DD");
  }

  var request = ``;

  if (Object.keys(filter).length > 2) {
    var array = [];
    request = `${config.api.url}/storico-magazzinos/?organisation=${org}&_sort=datetime:desc&datetime_lt=${filterCopy.datetime_azione_lt}&datetime_gte=${filterCopy.datetime_azione_gte}&_limit=${limit}&_start=${start}`;
    delete filterCopy.datetime_azione_lt;
    delete filterCopy.datetime_azione_gte;
    for (var i in filterCopy) {
      array.push([i, filterCopy[i]]);
    }
    for (var fil of array) {
      if (fil[1].length < 2) {
        request += `&${fil[0]}=${fil[1]}`;
      } else {
        var tempList = "";
        for (var el of fil[1]) {
          if (tempList !== "") {
            tempList += "_OR_";
          }
          tempList += el;
        }
        request += `&${fil[0]}=${tempList}`;
      }
    }
  } else {
    request = `${config.api.url}/storico-magazzinos/?organisation=${org}&datetime_lt=${filterCopy.datetime_azione_lt}&datetime_gte=${filterCopy.datetime_azione_gte}&_limit=${limit}&_start=${start}&_sort=datetime:desc`;
  }

  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(request, requestOptions).then(handleResponse);
}

function countTransactions(org, filter = {}) {
  let filterCopy = { ...filter };
  if (!filterCopy.datetime_azione_gte) {
    filterCopy.datetime_azione_gte = moment()
      .startOf("month")
      .format("YYYY-MM-DD");
  }
  if (!filterCopy.datetime_azione_lt) {
    filterCopy.datetime_azione_lt = moment()
      .add(1, "days")
      .format("YYYY-MM-DD");
  } else {
    filterCopy.datetime_azione_lt = moment(filterCopy.datetime_azione_lt)
      .add(1, "days")
      .format("YYYY-MM-DD");
  }

  var request = ``;

  if (Object.keys(filter).length > 2) {
    var array = [];

    request = `${config.api.url}/storico-magazzinos/count?organisation=${org}&datetime_lt=${filterCopy.datetime_azione_lt}&datetime_gte=${filterCopy.datetime_azione_gte}`;
    delete filterCopy.datetime_azione_lt;
    delete filterCopy.datetime_azione_gte;
    for (var i in filterCopy) {
      array.push([i, filterCopy[i]]);
    }
    for (const fil of array) {
      if (fil[1].length < 2) {
        request += `&${fil[0]}=${fil[1]}`;
      } else {
        var tempList = "";
        for (var el of fil[1]) {
          if (tempList !== "") {
            tempList += "_OR_";
          }
          tempList += el;
        }
        request += `&${fil[0]}=${tempList}`;
      }
    }
  } else {
    request = `${config.api.url}/storico-magazzinos/count?organisation=${org}&datetime_lt=${filterCopy.datetime_azione_lt}&datetime_gte=${filterCopy.datetime_azione_gte}`;
  }

  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(request, requestOptions).then(handleResponse);
}

function getPurchases(
  org,
  filter = {},
  start,
  limit,
  sort = [{ datetime_acquisto: "desc" }]
) {
  let filterCopy = { ...filter };
  let numOfFiltersToCheck = 2;

  if (!filterCopy.datetime_acquisto_gte && !filterCopy.datetime_acquisto_lt) {
    numOfFiltersToCheck = 0;
  }
  if (filterCopy.datetime_acquisto_lt) {
    filterCopy.datetime_acquisto_lt = moment(filterCopy.datetime_acquisto_lt)
      .add(1, "days")
      .format("YYYY-MM-DD");
  }

  var request = ``;

  if (Object.keys(filter).length > numOfFiltersToCheck) {
    var array = [];
    if (filterCopy.datetime_acquisto_lt && filterCopy.datetime_acquisto_lt) {
      request = `${config.api.url}/acquistis/?organisation=${org}&datetime_acquisto_lt=${filterCopy.datetime_acquisto_lt}&datetime_acquisto_gte=${filterCopy.datetime_acquisto_gte}&_limit=${limit}&_start=${start}`;
      delete filterCopy.datetime_acquisto_lt;
      delete filterCopy.datetime_acquisto_gte;
    } else {
      request = `${config.api.url}/acquistis/?organisation=${org}&_limit=${limit}&_start=${start}`;
    }
    for (var i in filterCopy) {
      array.push([i, filterCopy[i]]);
    }
    for (var fil of array) {
      if (fil[1].length < 2) {
        request += `&${fil[0]}=${fil[1]}`;
      } else {
        var tempList = "";
        for (var el of fil[1]) {
          if (tempList !== "") {
            tempList += "_OR_";
          }
          tempList += el;
        }
        request += `&${fil[0]}=${tempList}`;
      }
    }
  } else {
    if (filterCopy.datetime_acquisto_lt && filterCopy.datetime_acquisto_lt) {
      request = `${config.api.url}/acquistis/?organisation=${org}&datetime_acquisto_lt=${filterCopy.datetime_acquisto_lt}&datetime_acquisto_gte=${filterCopy.datetime_acquisto_gte}&_limit=${limit}&_start=${start}&_sort=datetime_acquisto:desc`;
    } else {
      request = `${config.api.url}/acquistis/?organisation=${org}&_limit=${limit}&_start=${start}`;
    }
  }

  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(request, requestOptions).then(handleResponse);
}

function countPurchases(org, filter = {}) {
  let filterCopy = { ...filter };
  if (!filterCopy.datetime_acquisto_gte) {
    filterCopy.datetime_acquisto_gte = moment()
      .startOf("month")
      .format("YYYY-MM-DD");
  }
  if (!filterCopy.datetime_acquisto_lt) {
    filterCopy.datetime_acquisto_lt = moment()
      .add(1, "days")
      .format("YYYY-MM-DD");
  } else {
    filterCopy.datetime_acquisto_lt = moment(filterCopy.datetime_acquisto_lt)
      .add(1, "days")
      .format("YYYY-MM-DD");
  }

  var request = ``;

  if (Object.keys(filter).length > 2) {
    var array = [];

    request = `${config.api.url}/acquistis/count?organisation=${org}&datetime_acquisto_lt=${filterCopy.datetime_acquisto_lt}&datetime_acquisto_gte=${filterCopy.datetime_acquisto_gte}`;
    delete filterCopy.datetime_acquisto_lt;
    delete filterCopy.datetime_acquisto_gte;
    for (var i in filterCopy) {
      array.push([i, filterCopy[i]]);
    }
    for (const fil of array) {
      if (fil[1].length < 2) {
        request += `&${fil[0]}=${fil[1]}`;
      } else {
        var tempList = "";
        for (var el of fil[1]) {
          if (tempList !== "") {
            tempList += "_OR_";
          }
          tempList += el;
        }
        request += `&${fil[0]}=${tempList}`;
      }
    }
  } else {
    request = `${config.api.url}/acquistis/count?organisation=${org}&datetime_acquisto_lt=${filterCopy.datetime_acquisto_lt}&datetime_acquisto_gte=${filterCopy.datetime_acquisto_gte}`;
  }

  const requestOptions = { method: "GET", headers: authHeader() };
  return fetch(request, requestOptions).then(handleResponse);
}

function invoiceManagement(
  id,
  newPurchase,
  datetime_pagamento,
  tot_fattura,
  num_fattura
) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      newPurchase,
      datetime_pagamento,
      tot_fattura,
      num_fattura,
    }),
  };

  return fetch(`${config.api.url}/setPayment/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function modifyPurchase(
  id,
  modalita_acquisto,
  venditore,
  quantita,
  scadenza_conto_vendita,
  costo_articolo,
  percentuale_guadagno,
  giacenza,
  codice_articolo
) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      modalita_acquisto,
      venditore,
      quantita,
      scadenza_conto_vendita,
      costo_articolo,
      percentuale_guadagno,
      giacenza,
      codice_articolo,
    }),
  };

  return fetch(`${config.api.url}/acquistis/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}

function deletePurchase(id) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(`${config.api.url}/acquistis/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // TODO handle error
      return false;
    });
}
