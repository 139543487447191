import React, { useEffect, useState } from "react";
import { bounce } from "react-animations";
import styled, { keyframes } from "styled-components";
import arrow from "./../../img/arrow_down_dark.png";
import moment from "moment";
import "moment/locale/it";
import { cardsService } from "../../_services";
moment.locale("it");
const Bounce = styled.div`
  animation: 2s ${keyframes`${bounce}`} infinite;
`;

function validateEmail(email, setEmailError, setIsEmailIncorrect) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let error = re.test(String(email).toLowerCase());
  if (email === "") {
    setEmailError(false);
    setIsEmailIncorrect(false);
  } else {
    setEmailError(!error);
    setIsEmailIncorrect(!error);
  }
  return error;
}

const FirstStep = ({
  cards,
  chosenCard,
  setChosenCard,
  chosenTarget,
  setChosenTarget,
  colors,
  width,
  section,
  setSection,
  findCard,
  cardToRenew,
  researchFeedback,
  researchStatus,
  giftForm,
  setIsEmailIncorrect,
}) => {
  const [cardNumber, setCardNumber] = useState("");
  const [surname, setSurname] = useState("");
  const [cardType, setCardType] = useState({});
  const [receiverEmailError, setReceiverEmailError] = useState(false);

  useEffect(() => {
    if (cardToRenew) {
      cardsService
        .getTypeFromId(cardToRenew.target_tessere.tipologie_tessere)
        .then((response) => {
          cardToRenew.tipologie_tessere = response[0];
          setCardType(response[0]);
        });

      if (cardToRenew.gruppo_tessere) {
        cardsService
          .getCardGroup(cardToRenew.gruppo_tessere.id)
          .then((response) => {
            cardToRenew.gruppo_tessere = response;
          });
      }
    }
  }, [cardToRenew]);

  const styles = {
    body: {
      width: "100%",
      height: "100%",
      display: width > 1000 && "flex",
      flexDirection: width > 1000 ? "row" : "column",
      justifyContent: "center",
      alignItems: "center",
      overflow: "scroll",
      position: "relative",
      top: 0,
    },
    columnLeft: {
      width: width > 1000 ? "40%" : "100%",
      height: width > 1000 ? "100%" : "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    columnRight: {
      width: width > 1000 ? "40%" : "100%",
      height: width > 1000 ? "100%" : "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: width < 1000 && 40,
    },
    label: {
      fontSize: 13,
      color: colors.gray,
    },
    selectBox: {
      marginTop: width > 1000 ? 20 : 10,
      marginBottom: width > 1000 ? 20 : 10,
    },
    menu: {
      border: "1px solid lightgray",
      width: 150,
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 10,
      color: colors.darkgray,
      borderRadius: 20,
      backgroundColor: "transparent",
      outline: "none",
    },
    cardImg: {
      width: "60%",
      objectFit: "contain",
      border: "1px solid darkgray",
      borderRadius: 15,
    },
    type: {
      fontSize: 18,
      fontWeight: "700",
      textAlign: "left",
      marginBottom: 0,
      marginTop: 50,
      color: colors.darkgray,
    },
    target: {
      fontSize: 18,
      fontWeight: "700",
      textAlign: "left",
      marginBottom: 0,
      marginTop: 50,
      color: colors.gray,
    },
    description: {
      fontSize: 13,
      textAlign: width > 1000 ? "right" : "center",
    },
    rowTop: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
    },
    buttonChoice: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "#e2e2e2",
      padding: 20,
      color: colors.darkgray,
      border: "1px solid #e2e2e2",
      borderTop: 0,
      fontSize: 12,
      fontWeight: "500",
    },
    textInput: {
      outline: "none",
      border: "1px solid lightgray",
      width: 250,
      height: 20,
      borderRadius: 10,
      marginRight: 10,
      marginLeft: 10,
      padding: 5,
      textAlign: "center",
    },
    button: {
      outline: "none",
      backgroundColor: colors.darkgray,
      color: "white",
      border: "none",
      borderRadius: 10,
      width: 250,
      marginTop: 40,
      marginRight: 10,
      marginLeft: 10,
      padding: 10,
      fontWeight: "800",
      cursor: "pointer",
    },
    fieldContainer: {
      width: "70%",
      display: "flex",
      flexDirection: "row",
      justifyContent: width > 1000 ? "flex-end" : "center",
      alignItems: "flex-end",
      textAlign: "left",
    },
  };

  const renderBody = () => {
    switch (section) {
      case 0:
        return cards.length > 0 ? (
          <div style={styles.body}>
            <div style={styles.columnLeft}>
              <div style={styles.selectBox}>
                <p style={styles.label}>Seleziona la tua card</p>
                <select
                  name={chosenCard?.nome}
                  style={styles.menu}
                  value={chosenCard?.id}
                  onChange={(event) => {
                    setChosenCard(cards[event.target.selectedIndex]);
                  }}
                >
                  {cards.map((type, key) => (
                    <option key={key} value={type.id}>
                      {type.nome}
                    </option>
                  ))}
                </select>
              </div>
              {chosenCard && chosenCard.target_tesseres.length > 0 && (
                <div style={styles.selectBox}>
                  <p style={styles.label}>Seleziona la tipologia</p>
                  <select
                    name={chosenTarget?.nome}
                    style={styles.menu}
                    value={chosenTarget?.id}
                    onChange={(event) => {
                      setChosenTarget(
                        chosenCard.target_tesseres[event.target.selectedIndex]
                      );
                    }}
                  >
                    {chosenCard.target_tesseres
                      .filter((c) => c.in_vendita)
                      .map((target, key) => (
                        <option key={key} value={target.id}>
                          {target.nome}
                        </option>
                      ))}
                  </select>
                </div>
              )}
              {width < 1000 && (
                <Bounce style={{ marginTop: 80 }}>
                  <img
                    alt="Arrow"
                    src={arrow}
                    style={{ width: 18, objectFit: "contain" }}
                  />
                </Bounce>
              )}
            </div>
            <div style={styles.columnRight}>
              <div
                style={{
                  ...styles.fieldContainer,
                  justifyContent: width > 1000 ? "flex-end" : "center",
                }}
              >
                <img
                  style={styles.cardImg}
                  src={chosenTarget?.img.url}
                  alt="Tessere"
                ></img>
              </div>
              <div style={styles.fieldContainer}>
                <p style={styles.type}>{chosenCard?.nome}</p>
                <p style={styles.target}>{chosenTarget?.nome}</p>
              </div>
              <div style={styles.fieldContainer}>
                <p style={styles.description}>{chosenTarget?.descrizione}</p>
              </div>
              <div style={styles.fieldContainer}>
                <p
                  style={{
                    ...styles.target,
                    fontSize: 10,
                    fontWeight: "500",
                    marginTop: 10,
                    marginRight: 5,
                  }}
                >
                  {chosenTarget?.validita === "giorni"
                    ? "Validità"
                    : "Valida fino al"}
                </p>
                <p
                  style={{
                    ...styles.type,
                    fontWeight: "500",
                    marginTop: 10,
                    fontSize: 15,
                  }}
                >
                  {chosenTarget?.validita === "giorni"
                    ? chosenTarget?.giorni + " giorni"
                    : moment(new Date(chosenTarget?.data_scadenza)).format(
                        "DD MMM yyyy"
                      )}
                </p>
              </div>
              <div style={styles.fieldContainer}>
                <p
                  style={{
                    ...styles.target,
                    fontSize: 10,
                    fontWeight: "500",
                    marginTop: 10,
                    marginRight: 5,
                  }}
                >
                  Prezzo
                </p>
                <p
                  style={{
                    ...styles.type,
                    fontWeight: "500",
                    marginTop: 10,
                    fontSize: 15,
                  }}
                >
                  {chosenTarget?.prezzo.toFixed(2)}
                </p>
              </div>
              <div style={styles.fieldContainer}>
                <p
                  style={{
                    ...styles.target,
                    fontSize: 10,
                    fontWeight: "500",
                    marginTop: 10,
                    marginRight: 5,
                  }}
                >
                  Numero intestatari
                </p>
                <p
                  style={{
                    ...styles.type,
                    fontWeight: "500",
                    marginTop: 10,
                    fontSize: 15,
                  }}
                >
                  {chosenTarget?.max_componenti}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div style={styles.body}>
            <p style={{ ...styles.description, textAlign: "center" }}>
              Non ci sono card attualmente in vendita.
            </p>
          </div>
        );
      case 1:
        return cards.length > 0 ? (
          <div style={styles.body}>
            <div style={{ ...styles.columnLeft, justifyContent: "flex-start" }}>
              <div
                style={{ ...styles.rowTop, marginTop: 10, marginBottom: 15 }}
              >
                <div style={styles.selectBox}>
                  <p style={styles.label}>Seleziona la card</p>
                  <select
                    name={chosenCard?.nome}
                    style={styles.menu}
                    value={chosenCard?.id}
                    onChange={(event) => {
                      setChosenCard(cards[event.target.selectedIndex]);
                    }}
                  >
                    {cards.map((type, key) => (
                      <option key={key} value={type.id}>
                        {type.nome}
                      </option>
                    ))}
                  </select>
                </div>
                {chosenCard && chosenCard.target_tesseres.length > 0 && (
                  <div style={styles.selectBox}>
                    <p style={styles.label}>Seleziona la tipologia</p>
                    <select
                      name={chosenTarget?.nome}
                      style={styles.menu}
                      value={chosenTarget?.id}
                      onChange={(event) => {
                        setChosenTarget(
                          chosenCard.target_tesseres[event.target.selectedIndex]
                        );
                      }}
                    >
                      {chosenCard.target_tesseres
                        .filter((c) => c.in_vendita)
                        .map((target, key) => (
                          <option key={key} value={target.id}>
                            {target.nome}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
              </div>
              <div
                style={{ width: "90%", borderBottom: "1px solid darkgray" }}
              ></div>
              <div style={{ width: "80%" }}>
                <p style={{ ...styles.type, marginTop: 30 }}>
                  A chi vuoi regalare la card?
                </p>
                <div style={styles.selectBox}>
                  <p style={{ ...styles.label, textAlign: "left" }}>
                    Nome del destinatario
                  </p>
                  <input
                    type="text"
                    style={{
                      ...styles.textInput,
                      width: "100%",
                      margin: 0,
                      padding: 5,
                      textAlign: "left",
                    }}
                    value={giftForm.name}
                    onChange={(event) => giftForm.setName(event.target.value)}
                  ></input>
                </div>
                <div style={styles.selectBox}>
                  <p style={{ ...styles.label, textAlign: "left" }}>
                    Email del destinatario
                  </p>
                  <input
                    type="text"
                    style={{
                      ...styles.textInput,
                      width: "100%",
                      margin: 0,
                      padding: 5,
                      textAlign: "left",
                    }}
                    value={giftForm.email}
                    onChange={(event) => {
                      giftForm.setEmail(event.target.value);
                      validateEmail(
                        event.target.value,
                        setReceiverEmailError,
                        setIsEmailIncorrect
                      );
                    }}
                  ></input>
                </div>
                {receiverEmailError && (
                  <p
                    style={{
                      color: colors.highlight,
                      marginTop: 2,
                      fontSize: 10,
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    Email non valida
                  </p>
                )}
                <div style={styles.selectBox}>
                  <p style={{ ...styles.label, textAlign: "left" }}>
                    Lascia un messaggio per il destinatario
                  </p>
                  <textarea
                    maxLength="250"
                    style={{
                      ...styles.textInput,
                      width: "100%",
                      margin: 0,
                      padding: 5,
                      height: 120,
                      textAlign: "left",
                      resize: "none",
                      fontFamily: "inherit",
                    }}
                    value={giftForm.surname}
                    onChange={(event) =>
                      giftForm.setMessage(event.target.value.replace("\n", ""))
                    }
                  ></textarea>
                </div>
              </div>
            </div>
            <div style={styles.columnRight}>
              <div
                style={{
                  ...styles.fieldContainer,
                  justifyContent: width > 1000 ? "flex-end" : "center",
                }}
              >
                <img
                  style={styles.cardImg}
                  src={chosenTarget?.img.url}
                  alt="Tessere"
                ></img>
              </div>
              <div style={styles.fieldContainer}>
                <p style={styles.type}>{chosenCard?.nome}</p>
                <p style={styles.target}>{chosenTarget?.nome}</p>
              </div>
              <div style={styles.fieldContainer}>
                <p style={styles.description}>{chosenTarget?.descrizione}</p>
              </div>
              <div style={styles.fieldContainer}>
                <p
                  style={{
                    ...styles.target,
                    fontSize: 10,
                    fontWeight: "500",
                    marginTop: 10,
                    marginRight: 5,
                  }}
                >
                  {chosenTarget?.validita === "giorni"
                    ? "Validità"
                    : "Valida fino al"}
                </p>
                <p
                  style={{
                    ...styles.type,
                    fontWeight: "500",
                    marginTop: 10,
                    fontSize: 15,
                  }}
                >
                  {chosenTarget?.validita === "giorni"
                    ? chosenTarget?.giorni + " giorni"
                    : moment(new Date(chosenTarget?.data_scadenza)).format(
                        "DD MMM yyyy"
                      )}
                </p>
              </div>
              <div style={styles.fieldContainer}>
                <p
                  style={{
                    ...styles.target,
                    fontSize: 10,
                    fontWeight: "500",
                    marginTop: 10,
                    marginRight: 5,
                  }}
                >
                  Prezzo
                </p>
                <p
                  style={{
                    ...styles.type,
                    fontWeight: "500",
                    marginTop: 10,
                    fontSize: 15,
                  }}
                >
                  {chosenTarget?.prezzo.toFixed(2)}
                </p>
              </div>
              <div style={styles.fieldContainer}>
                <p
                  style={{
                    ...styles.target,
                    fontSize: 10,
                    fontWeight: "500",
                    marginTop: 10,
                    marginRight: 5,
                  }}
                >
                  Numero intestatari
                </p>
                <p
                  style={{
                    ...styles.type,
                    fontWeight: "500",
                    marginTop: 10,
                    fontSize: 15,
                  }}
                >
                  {chosenTarget?.max_componenti}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div style={styles.body}>
            <p style={{ ...styles.description, textAlign: "center" }}>
              Non ci sono card attualmente in vendita.
            </p>
          </div>
        );
      case 2:
        return (
          <div style={styles.body}>
            <div
              style={{
                ...styles.columnLeft,
                justifyContent: "center",
              }}
            >
              <p style={{ ...styles.type, marginBottom: 10 }}>
                Trova la tua card
              </p>
              <div style={{ ...styles.selectBox, margin: 5 }}>
                <p style={{ ...styles.label, textAlign: "left" }}>
                  Numero card
                </p>
                <input
                  type="text"
                  style={{ ...styles.textInput, margin: 0 }}
                  value={cardNumber}
                  onChange={(event) => {
                    setCardNumber(event.target.value);
                  }}
                  placeholder="Numero card"
                ></input>
              </div>
              <div style={{ ...styles.selectBox, margin: 5 }}>
                <p style={{ ...styles.label, textAlign: "left" }}>Cognome</p>
                <input
                  type="text"
                  style={{ ...styles.textInput, margin: 0 }}
                  value={surname}
                  onChange={(event) => {
                    setSurname(event.target.value);
                  }}
                  placeholder="Cognome"
                ></input>
              </div>
              <button
                style={styles.button}
                onClick={() => findCard(cardNumber, surname)}
              >
                {"Cerca"}
              </button>
              {width < 1000 && cardToRenew && (
                <Bounce style={{ marginTop: 80 }}>
                  <img
                    alt="Arrow"
                    src={arrow}
                    style={{ width: 18, objectFit: "contain" }}
                  />
                </Bounce>
              )}
            </div>
            <div style={styles.columnRight}>
              {researchStatus === "done" &&
                (researchFeedback ? (
                  <>
                    <div style={styles.fieldContainer}>
                      <img
                        style={styles.cardImg}
                        src={cardToRenew.target_tessere?.img.url}
                        alt="Tessere"
                      ></img>
                    </div>
                    <div style={styles.fieldContainer}>
                      <p style={styles.type}>{cardType?.nome}</p>
                      <p style={styles.target}>
                        {cardToRenew.target_tessere?.nome}
                      </p>
                    </div>
                    <div style={styles.fieldContainer}>
                      <p
                        style={{
                          ...styles.target,
                          fontSize: 10,
                          fontWeight: "500",
                          marginTop: 10,
                          marginRight: 5,
                        }}
                      >
                        Intestatario
                      </p>
                      <p
                        style={{
                          ...styles.type,
                          fontWeight: "500",
                          marginTop: 10,
                          fontSize: 15,
                        }}
                      >
                        {cardToRenew.nome + " " + cardToRenew.cognome}
                      </p>
                    </div>
                    <div
                      style={{
                        ...styles.fieldContainer,
                        color:
                          new Date(cardToRenew?.scadenza) < new Date() && "red",
                      }}
                    >
                      <p
                        style={{
                          ...styles.target,
                          fontSize: 10,
                          fontWeight: "500",
                          marginTop: 10,
                          marginRight: 5,
                        }}
                      >
                        {new Date(cardToRenew?.scadenza) < new Date()
                          ? "Scaduta il "
                          : "In scadenza il"}
                      </p>
                      <p
                        style={{
                          ...styles.type,
                          fontWeight: "500",
                          marginTop: 10,
                          fontSize: 15,
                        }}
                      >
                        {moment(new Date(cardToRenew?.scadenza)).format(
                          "DD MMM yyyy"
                        )}
                      </p>
                    </div>
                  </>
                ) : (
                  <div style={styles.description}>
                    Nessuna tessera corrispondente ai dati inseriti
                  </div>
                ))}
            </div>
          </div>
        );

      default:
        return <p>Errore</p>;
    }
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={styles.rowTop}>
        <button
          style={{
            ...styles.buttonChoice,
            borderRight: 0,
            backgroundColor: section === 0 ? "transparent" : "#e2e2e2",
            borderBottom: section === 0 ? 0 : "1px solid #e2e2e2",
          }}
          onClick={() => setSection(0)}
        >
          ACQUISTA
        </button>
        <button
          style={{
            ...styles.buttonChoice,
            borderRight: 0,
            backgroundColor: section === 1 ? "transparent" : "#e2e2e2",
            borderBottom: section === 1 ? 0 : "1px solid #e2e2e2",
          }}
          onClick={() => setSection(1)}
        >
          REGALA
        </button>
        <button
          style={{
            ...styles.buttonChoice,
            borderRight: 0,
            backgroundColor: section === 2 ? "transparent" : "#e2e2e2",
            borderBottom: section === 2 ? 0 : "1px solid #e2e2e2",
          }}
          onClick={() => setSection(2)}
        >
          RINNOVA
        </button>
      </div>
      {renderBody()}
    </div>
  );
};

export default FirstStep;
