import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts";
import { reportService } from "../../../_services";
import InsightsBox from "../boxes/insightsBox";

const Buyers = (props) => {
  const colors = useContext(UserContext)?.colors;
  const [buyers, setBuyers] = useState([]);

  useEffect(() => {
    reportService
      .getBuyers(
        props.org,
        props.temporalFilters.start,
        props.temporalFilters.end
      )
      .then((data) => {
        setBuyers(data.values);
      });
  }, [props]);

  const styles = {
    container: {
      width: "50%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
      borderRadius: 10,
    },
    content: {
      width: "99%",
      height: "99%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 8,
    },
    column: {
      display: "flex",
      flexDirection: "column",
      width: "70%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <InsightsBox
          title={"Hanno acquistato dal bookshop"}
          body={
            <div style={styles.row}>
              <div style={styles.column}>
                <h2 style={{ color: colors?.primary }}>
                  {buyers["visitatori"]}
                </h2>{" "}
                <h2
                  style={{
                    color: colors?.primary,
                    marginTop: 0,
                    marginBottom: "20px",
                  }}
                >
                  {"clienti visitatori"}
                </h2>{" "}
              </div>
              <div style={styles.column}>
                <h2 style={{ color: colors?.primary }}>
                  {buyers["non visitatori"]}
                </h2>{" "}
                <h2
                  style={{
                    color: colors?.primary,
                    marginTop: 0,
                    marginBottom: "20px",
                  }}
                >
                  {"clienti non visitatori"}
                </h2>{" "}
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default Buyers;
