import React, { useContext } from "react";
import { UserContext } from "../../../contexts";

const NoFiltersBox = (props) => {
  const colors = useContext(UserContext)?.colors;

  const styles = {
    container: {
      backgroundColor: colors?.darkBackground,
      width: "98%",
      display: "flex",
      flexDirection: "column",
      margin: 10,
      borderRadius: 10,
    },
    header: {
      backgroundColor: colors?.secondary,
      height: 50,
      alignItems: "center",
      justifyContent: "space-between",
      display: "flex",
      outline: "none",
      border: "none",
      borderRadius: 10,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    title: {
      color: colors?.white,
      marginLeft: 30,
      fontSize: 18,
    },
    dropdown: {
      backgroundColor: colors?.primary,
      borderColor: colors?.primary,
      color: colors?.white,
      height: 40,
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      margin: 1,
      marginRight: 10,
      borderRadius: 10,
      fontSize: 15,
      padding: 10,
    },
    body: {
      margin: 10,
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <p style={styles.title}>{props.title}</p>
      </div>
      <div style={styles.body}>{props.body}</div>
    </div>
  );
};

export default NoFiltersBox;
