import React, { useContext } from "react";
import { LanguageContext } from "../containers/language";
import search from "./../img/search.png";
import { UserContext, OrgContext } from "../contexts";

const SearchBox = ({
  inputValue,
  filterOnChange,
  categoriesList,
  onSelectCategory,
  selectedCategory,
}) => {
  const { dictionary } = useContext(LanguageContext);
  const colors = useContext(UserContext)?.colors;
  const settings = useContext(OrgContext)?.org?.settings;

  const styles = {
    searchBox: {
      display: "flex",
      flexDirection: "column",
      flex: 4,
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    inputSearch: {
      borderRadius: 40,
      border: "1px solid #F0F0F0",
      width: "90%",
      height: 40,
      marginTop: 40,
      marginBottom: 0,
      display: "flex",
      flexDirection: "row",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
    wrapperDiv: {
      overflowX: "scroll",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "90%",
      height: "100%",
    },
    button: {
      borderRadius: 10,
      padding: 18,
      margin: 3,
      color: colors?.white,
      width: 150,
    },
  };
  return (
    <div style={styles.searchBox}>
      <div style={styles.inputSearch}>
        <img
          alt="Search icon"
          src={search}
          style={{ width: 30, objectFit: "contain", margin: 10 }}
        />
        <input
          type="text"
          style={{
            border: 0,
            width: "100%",
            borderRadius: 40,
            outline: "none",
          }}
          value={inputValue}
          onChange={filterOnChange}
          placeholder={dictionary.searchInsideCategory}
        ></input>
      </div>
      <div style={styles.wrapperDiv}>
        {categoriesList?.map((cat, key) => {
          if (
            cat.lista_articoli.length > 0 ||
            (settings?.viva_ticket && key === 0)
          ) {
            return (
              <button
                key={key}
                onClick={() => onSelectCategory(cat.id)}
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                <p
                  style={{
                    ...styles.button,
                    backgroundColor:
                      selectedCategory === cat.id
                        ? colors?.primary
                        : colors?.secondary,
                  }}
                >
                  {cat.nome_categoria.toUpperCase()}
                </p>
              </button>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default SearchBox;
