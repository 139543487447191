import React, { useContext, useState } from "react";
import Field from "../../components/field";
import moment from "moment";
import { UserContext } from "../../contexts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const InterventionBox = (props) => {
  const colors = useContext(UserContext)?.colors;
  const [closingDateTime, setClosingDateTime] = useState(
    props.intervention?.data_chiusura
      ? new Date(props.intervention?.data_chiusura)
      : ""
  );

  const encodeStatus = (statusString) => {
    switch (statusString) {
      case "Intervento in corso":
        return 0;
      case "Non risolto":
        return 1;
      case "Risolto":
        return 2;
      default:
        return null;
    }
  };

  const statusList = [
    { value: 0, label: "Intervento in corso" },
    { value: 1, label: "Non risolto" },
    { value: 2, label: "Risolto" },
  ];

  const translateStatus = (status) => {
    switch (status) {
      case 0:
        return "Intervento in corso";
      case 1:
        return "Non risolto";
      case 2:
        return "Risolto";
      default:
        return null;
    }
  };

  const CustomInput = ({ value, onClick }) => (
    <button className="custom-input-res" onClick={onClick}>
      {value}
    </button>
  );

  function isEmpty(value) {
    return value === null || value === undefined || value === "";
  }

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
      marginBottom: 10,
    },
    value: {
      textAlign: "left",
      margin: 2,
      marginBottom: 10,
      fontSize: 15,
    },
    title: {
      transform: "rotate(270deg)",
      fontWeight: "600",
      width: 150,
    },
    column: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: 10,
    },
    textInput: {
      outline: "none",
      padding: 10,
      textAlign: "left",
      width: "95%",
      border: "1px solid lightgray",
      borderRadius: 5,
      resize: "none",
    },
    saveButton: {
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: colors.darkgray,
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 15,
      marginTop: 30,
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
    },
  };
  return (
    <div style={styles.container}>
      <p style={styles.title}>{props.title}</p>
      <div style={styles.column}>
        <div style={styles.row}>
          <div style={styles.column}>
            <div>
              <p
                style={{
                  margin: 10,
                  marginLeft: 0,
                  fontSize: 12,
                  textAlign: "left",
                }}
              >
                Data e ora inizio intervento
              </p>
              <p style={styles.value}>
                {moment(props.intervention?.data_creazione).format(
                  "DD/MM/yyyy, HH:mm"
                )}
              </p>
            </div>

            <div>
              <p
                style={{
                  margin: 10,
                  marginLeft: 0,
                  fontSize: 12,
                  textAlign: "left",
                }}
              >
                Esito
              </p>
              {props.isEditing ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <select
                    name={
                      isEmpty(props.status)
                        ? statusList[0].label
                        : statusList[encodeStatus(props.status)].label
                    }
                    style={styles.textInput}
                    value={
                      isEmpty(props.status)
                        ? statusList[0].value
                        : statusList[encodeStatus(props.status)].value
                    }
                    onChange={(event) => {
                      if (event.target.selectedIndex === 0) {
                        props.setStatus(translateStatus(0));
                        setClosingDateTime("");
                        props.setClosing(null);
                      } else {
                        props.setStatus(
                          translateStatus(
                            statusList[event.target.selectedIndex].value
                          )
                        );
                        if (closingDateTime === "") {
                          setClosingDateTime(new Date());
                          props.setClosing(new Date());
                        }
                      }
                    }}
                  >
                    {statusList.map((opt, key) => (
                      <option key={key} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <p style={styles.value}>{props.status}</p>
              )}
            </div>

            {props.intervention?.info
              ?.filter((field) => field.type !== -1)
              .slice(0, Math.ceil(props.intervention?.info.length / 2))
              .map((field, key) => {
                return (
                  <Field
                    key={key}
                    field={field}
                    colors={colors}
                    addField={(newField) => (field.value = newField)}
                    fixed={!props.isEditing}
                    listLenght={1}
                  />
                );
              })}
          </div>

          <div style={styles.column}>
            {props.isEditing && props.status !== "Intervento in corso" ? (
              <div>
                <p
                  style={{
                    margin: 10,
                    marginLeft: 0,
                    fontSize: 12,
                    textAlign: "left",
                  }}
                >
                  Data e ora chiusura intervento
                </p>
                <div
                  style={{
                    textAlign: "left",
                    marginTop: -10,
                    marginBottom: -10,
                  }}
                >
                  <DatePicker
                    name="closingDatetime"
                    locale="it"
                    className={"event-custom-input-multiple"}
                    selected={closingDateTime}
                    onChange={(date) => {
                      setClosingDateTime(date);
                      props.setClosing(date);
                    }}
                    placeholderText={"Data e ora chiusura intervento"}
                    popperPlacement="bottom-start"
                    showTimeSelect
                    dateFormat="Pp"
                    timeIntervals={5}
                    customInput={<CustomInput />}
                  />
                </div>
              </div>
            ) : (
              <div>
                <p
                  style={{
                    margin: 10,
                    marginLeft: 0,
                    fontSize: 12,
                    textAlign: "left",
                  }}
                >
                  Data e ora chiusura intervento
                </p>
                <p style={styles.value}>
                  {closingDateTime
                    ? moment(closingDateTime).format("DD/MM/yyyy, HH:mm")
                    : "-"}
                </p>
              </div>
            )}
            {props.isEditing ? (
              <div>
                <p
                  style={{
                    margin: 10,
                    marginLeft: 0,
                    fontSize: 12,
                    textAlign: "left",
                  }}
                >
                  Report
                </p>
                <textarea
                  type="text"
                  style={{ ...styles.textInput, fontFamily: "inherit" }}
                  value={props.field}
                  onChange={(event) => props.setField(event.target.value)}
                  placeholder="Report"
                />
              </div>
            ) : (
              <div>
                <p
                  style={{
                    margin: 10,
                    marginLeft: 0,
                    fontSize: 12,
                    textAlign: "left",
                  }}
                >
                  Report
                </p>
                <p
                  style={{
                    ...styles.value,
                    width: 420,
                    wordWrap: "break-word",
                  }}
                >
                  {props.field ? props.field : "-"}
                </p>
              </div>
            )}

            {props.intervention?.info
              ?.slice(Math.ceil(props.intervention?.info.length / 2))
              .map((field, key) => {
                return (
                  <Field
                    key={key}
                    field={field}
                    colors={colors}
                    addField={(newField) => (field.value = newField)}
                    fixed={!props.isEditing}
                    listLenght={1}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterventionBox;
