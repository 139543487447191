import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../../contexts";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./../../index.css";
import ReservationsManager from "./events/reservationsManager";
import SubBar from "../../components/subBar";
import calendar from "./../../img/calendar.png";
import modify from "./../../img/modify.png";
import ActivitiesResManager from "./activities/activitiesResManager";

const Reservation = ({ history, permissions, iframe, index }) => {
  const [selection, setSelection] = useState(0);

  useEffect(() => {
    if (index !== undefined && !isNaN(parseInt(index))) {
      setSelection(parseInt(index));
    }
  }, [index]);

  const colors = useContext(UserContext)?.colors;
  const user = useContext(UserContext)?.user;

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      width: iframe ? "100%" : "85%",
      position: "absolute",
      right: 0,
    },
  };

  let buttons = [
    {
      icon: calendar,
      name: "Ingressi ed eventi",
      section: <ReservationsManager history={history} />,
    },
  ];

  if (permissions) {
    buttons = [];
    if (permissions?.ingressi) {
      buttons.push({
        icon: calendar,
        name: "Ingressi ed eventi",
        section: <ReservationsManager history={history} />,
      });
    }

    if (permissions?.attivita) {
      buttons.push({
        icon: modify,
        name: "Attività",
        section: <ActivitiesResManager colors={colors} />,
      });
    }
  }

  const selected = (id) => {
    return buttons[id].section;
  };

  return (
    <div style={styles.container}>
      {!iframe && (
        <SubBar
          colors={colors}
          selection={selection}
          setSelection={(section) => setSelection(section)}
          isNetwork={user?.flag_network}
          buttons={buttons}
        />
      )}
      {selected(selection)}
    </div>
  );
};

export default Reservation;
