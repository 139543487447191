import React, { useState, useEffect } from "react";
import colors from "../museumColors";
import show from "./../img/show.png";
import logo from "./../img/logo_white.png";
import RetrievePassword from "../components/popup/retrievePassword";
import { authenticationService } from "../_services";
import ReactLoading from "react-loading";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [openRetrievePassword, setOpenRetrievePassword] = useState(false);
  const [showWrongEmail, setShowWrongEmail] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState("");

  const [loader, setLoader] = useState(false);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isMobile, setIsMobile] = useState(false);

  const [username, setUsername] = useState("");
  const [organisations, setOrganisations] = useState([]);
  const [chosenOrg, setChosenOrg] = useState();
  const [showList, setShowList] = useState(false);

  useEffect(() => {
    if (windowSize.innerWidth > 800) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize.innerWidth]);

  const toggleRetrievePassword = () => {
    setShowConfirmation(false);
    setOpenRetrievePassword(!openRetrievePassword);
  };

  const retrievePassword = (email) => {
    authenticationService.forgotPassword(email).then((response) => {
      if (response) {
        setShowWrongEmail(false);
        setShowConfirmation(true);
      } else {
        setShowWrongEmail(true);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  /**
   * Call backend for login
   */
  const login = () => {
    setMessage("");
    setShowError(false);

    setLoader(true);

    authenticationService
      .getOrgs(email, password)
      .then((response) => {
        if (response.user.role.name !== "Relatore") {
          if (response && response.orgs && response.orgs.length > 1) {
            setOrganisations(response.orgs);
            setShowList(true);
            setUsername(response.user.username);
            setChosenOrg(response.orgs[0].orgId);
            setLoader(false);
          } else {
            authenticationService
              .login(email, password)
              .then((response) => {
                props.history.push("/dashboard");
                setLoader(false);
              })
              .catch((errors) => {
                // c'è stato un errore, mostra il messaggio
                setLoader(false);
                setShowError(true);
                setMessage(errors.message[0].messages.message);
              });
          }
        } else {
          setMessage("Non hai i permessi necessari ad accedere");
          setShowError(true);
          setLoader(false);
        }
      })
      .catch((errors) => {
        // c'è stato un errore, mostra il messaggio
        setLoader(false);
        setShowError(true);
        setMessage(errors.message[0].messages.message);
      });
  };

  const loginWithOrg = () => {
    setLoader(true);
    authenticationService
      .loginWithOrg(email, password, chosenOrg)
      .then((response) => {
        props.history.push("/dashboard");
        setLoader(false);
      })
      .catch((errors) => {
        // c'è stato un errore, mostra il messaggio
        setLoader(false);
        setShowError(true);
        setMessage(errors.message[0].messages.message);
      });
  };

  async function logout() {
    setOrganisations([]);
    setShowList(false);
    setUsername();
    setChosenOrg();
  }

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.secondary,
      overflow: "scroll",
    },
    header: {
      display: isMobile ? "" : "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "100px",
    },
    mainContent: {
      display: "flex",
      width: "80%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 10,
      height: "60%",
    },
    content: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    textInput: {
      outline: "none",
      padding: 10,
      textAlign: "left",
      width: "60%",
      border: "1px solid lightgray",
      borderRadius: 5,
      fontFamily: "inherit",
      resize: "none",
      backgroundColor: colors.quaternary,
    },
    field: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      marginTop: 20,
    },
    label: {
      color: colors.primary,
      width: 100,
      marginRight: 40,
    },
    password: {
      color: colors.primary,
      fontStyle: "italic",
      fontSize: 13,
    },
    message: {
      color: colors.white,
      fontWeight: 700,
      fontSize: 13,
      width: "100%",
      backgroundColor: "rgba(277,277,277,0.3)",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
  };

  return (
    <div style={styles.container}>
      <a style={styles.header} href="https://www.rnb4culture.com/emma/">
        <img
          alt="App logo"
          src={logo}
          style={{
            width: isMobile
              ? windowSize.innerWidth / 3
              : windowSize.innerWidth / 8,
            objectFit: "contain",
          }}
        />
      </a>
      <div style={styles.mainContent}>
        <div
          style={{
            width: "70%",
            height: "87%",
            marginTop: 20,
            marginRight: 20,
            backgroundColor: colors.white,
            padding: 30,
            borderRadius: 10,
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!showList ? (
            <>
              <h1 style={{ color: colors.primary }}>Benvenuto in EMMA</h1>
              <h3 style={{ color: colors.secondary }}>
                Accedi inserendo le tue credenziali
              </h3>
              {showError && (
                <div style={styles.message}>
                  <p style={{ color: colors.red }}>ATTENZIONE: {message}</p>{" "}
                </div>
              )}
              <div style={styles.content}>
                <form
                  style={{ width: "80%", margin: 5 }}
                  onSubmit={handleSubmit}
                >
                  <div style={styles.field}>
                    <p style={styles.label}>Email</p>
                    <input
                      type="email"
                      name="email"
                      autoComplete="on"
                      value={email}
                      onChange={(event) =>
                        setEmail(event.target.value.toLowerCase())
                      }
                      placeholder="Email"
                      style={styles.textInput}
                    />
                  </div>
                  <div style={styles.field}>
                    <p style={styles.label}>Password</p>
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      autoComplete="on"
                      value={password || ""}
                      onChange={(event) => setPassword(event.target.value)}
                      placeholder="Password"
                      style={styles.textInput}
                    />
                    {password !== "" && (
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setShowPassword(!showPassword);
                        }}
                        style={{
                          border: "none",
                          backgroundColor: "transparent",
                          outline: "none",
                          cursor: "pointer",
                          marginLeft: -50,
                        }}
                      >
                        <img
                          alt="Show password"
                          src={show}
                          style={{ width: 20, objectFit: "contain" }}
                        />
                      </button>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: 20,
                    }}
                  >
                    {loader ? (
                      <ReactLoading
                        type={"spinningBubbles"}
                        color={colors?.primary}
                        height={50}
                        width={50}
                      />
                    ) : (
                      <button
                        onClick={() => login()}
                        style={{
                          ...styles.button,
                          opacity: !email || !password ? 0.3 : 1,
                        }}
                        disabled={!email || !password}
                      >
                        ACCEDI
                      </button>
                    )}
                  </div>
                </form>
                <button
                  onClick={() => toggleRetrievePassword()}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    outline: "none",
                    cursor: "pointer",
                  }}
                >
                  <p style={styles.password}>Hai dimenticato la password?</p>
                </button>
                {openRetrievePassword && (
                  <RetrievePassword
                    closeModal={toggleRetrievePassword}
                    retrievePassword={retrievePassword}
                    showWrongEmail={showWrongEmail}
                    showConfirmation={showConfirmation}
                    colors={colors}
                  />
                )}
                <div style={{ display: "flex", flex: 1 }}></div>
              </div>
            </>
          ) : (
            <>
              <h1 style={{ color: colors.primary }}>
                {"Bentornato in EMMA " + username}
              </h1>
              <h3 style={{ color: colors.secondary }}>
                Seleziona il museo a cui accedere:
              </h3>
              <div style={styles.content}>
                <form
                  style={{ width: "80%", margin: 5 }}
                  onSubmit={handleSubmit}
                >
                  <div
                    style={{
                      ...styles.field,
                      justifyContent: "center",
                      marginBottom: "40px",
                    }}
                  >
                    <select
                      name="options"
                      style={styles.textInput}
                      value={chosenOrg}
                      onChange={(event) => {
                        setChosenOrg(event.target.value);
                      }}
                    >
                      {organisations.map((org, key) => {
                        return (
                          <option key={key} value={org.orgId}>
                            {org.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      marginTop: 20,
                    }}
                  >
                    {loader ? (
                      <ReactLoading
                        type={"spinningBubbles"}
                        color={colors?.primary}
                        height={50}
                        width={50}
                      />
                    ) : (
                      <button
                        onClick={() => loginWithOrg()}
                        style={{
                          ...styles.button,
                          opacity: !chosenOrg ? 0.3 : 1,
                        }}
                        disabled={!chosenOrg}
                      >
                        ACCEDI
                      </button>
                    )}
                  </div>
                </form>
                <button
                  onClick={() => logout()}
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    outline: "none",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                >
                  <p style={styles.password}>
                    Vuoi accedere con altre credenziali?
                  </p>
                </button>
                <div style={{ display: "flex", flex: 1 }}></div>
                <div style={{ display: "flex", flex: 1 }}></div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
