import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { OrgContext, UserContext } from "../../../contexts";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import CrossedFiltersBox from "../boxes/crossedFiltersBox";
import { reportService } from "../../../_services";
import ReactLoading from "react-loading";
import StackCustomLegend from "../legends/stackCustomLegend";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const CrossedFilters = (props) => {
  const org = useContext(OrgContext)?.org;
  const colors = useContext(UserContext)?.colors;

  const [firstSelection, setFirstSelection] = useState("");
  const prevFirstSelection = usePrevious(firstSelection);
  const [secondSelection, setSecondSelection] = useState("");
  const prevSecondSelection = usePrevious(secondSelection);

  const prevTab = usePrevious(props.tab);

  const [contentFilters, setContentFilters] = useState([]);

  const [loader, setLoader] = useState(false);

  const [hiddenDatasets, setHiddenDatasets] = useState({}); // State to track visibility of datasets

  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
      },
    ],
  });

  const filteredData = {
    ...data,
    datasets: data.datasets.map((dataset) => ({
      ...dataset,
      data: dataset.data.map((value, index) =>
        hiddenDatasets[dataset.label] ? null : value
      ),
    })),
  };

  const options = {
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            var label =
              context.dataset.label +
              ": " +
              context.raw +
              (secondSelection === "eta" || secondSelection === "Età"
                ? "%"
                : "");
            return label;
          },
        },
      },
    },
    responsive: true,
    scales: {
      yAxis: {
        ticks: {
          callback: function (value) {
            if (value % 1 === 0) {
              return value;
            }
            return "";
          },
        },
      },
    },
  };

  useEffect(() => {
    let tmpFilters = [];
    let showAge = false;
    if (props.tab === "bookshop") {
      org.configurazioni.bookshop.forEach((element) => {
        if (element.type !== 1) {
          tmpFilters.push(element.name);
          if (element.name === "Anno di nascita") showAge = true;
        }
      });
    } else {
      org.configurazioni.biglietteria.forEach((element) => {
        if (element.type !== 1) {
          tmpFilters.push(element.name);
          if (element.name === "Anno di nascita") showAge = true;
        }
      });
    }
    if (showAge) tmpFilters.push("Età");

    setContentFilters(tmpFilters);
    setFirstSelection(tmpFilters[0]);
    setSecondSelection(tmpFilters[tmpFilters.length - 1]);
  }, [org, props.tab]);

  const handleFirstSelection = (firstSelection) => {
    setFirstSelection(firstSelection);
  };

  const handleSecondSelection = (secondSelection) => {
    setSecondSelection(secondSelection);
  };

  const generatePastelColor = () => {
    let R = Math.floor(Math.random() * 127 + 127);
    let G = Math.floor(Math.random() * 127 + 127);
    let B = Math.floor(Math.random() * 127 + 127);

    let rgb = (R << 16) + (G << 8) + B;
    return `#${rgb.toString(16)}`;
  };

  const getGraphStats = useCallback(() => {
    setLoader(true);
    reportService
      .getCrossedFiltersGraph(
        org,
        firstSelection,
        secondSelection,
        props.temporalFilters.start,
        props.temporalFilters.end,
        props.tab
      )
      .then((data) => {
        var labels = data.labels.x;
        var datasets = [];

        for (const yLabel of data.labels.y) {
          var tmpValues = [];
          for (const xLabel of labels) {
            const value =
              data.values[xLabel][yLabel] === undefined
                ? 0
                : data.values[xLabel][yLabel];

            tmpValues.push(value);
          }

          datasets.push({
            label: yLabel,
            data: tmpValues,
            backgroundColor: generatePastelColor(),
          });
        }

        setData({ labels, datasets });
        setLoader(false);
      });
  }, [
    org,
    firstSelection,
    secondSelection,
    props.temporalFilters.start,
    props.temporalFilters.end,
    props.tab,
  ]);

  useEffect(() => {
    if (
      (firstSelection !== "" &&
        firstSelection !== undefined &&
        (prevFirstSelection !== firstSelection ||
          (props.tab !== "" &&
            props.tab !== undefined &&
            prevTab !== props.tab))) ||
      (secondSelection !== "" &&
        secondSelection !== undefined &&
        (prevSecondSelection !== secondSelection ||
          (props.tab !== "" &&
            props.tab !== undefined &&
            prevTab !== props.tab)))
    ) {
      getGraphStats();
    }
  }, [
    firstSelection,
    prevFirstSelection,
    secondSelection,
    prevSecondSelection,
    props.tab,
    prevTab,
    getGraphStats,
  ]);

  return (
    <CrossedFiltersBox
      title={"Filtri Incrociati"}
      body={
        loader ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "250px",
              paddingBottom: "250px",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={colors?.primary}
              height={50}
              width={50}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <StackCustomLegend
                data={data}
                setHiddenDatasets={setHiddenDatasets}
              />
            </div>
            <div style={{ width: "100%" }}>
              <Bar
                data={filteredData}
                options={{ ...options, maintainAspectRatio: false }}
                height={"550%"}
              />
            </div>
          </div>
        )
      }
      handleFirstSelection={(firstSelection) =>
        handleFirstSelection(firstSelection)
      }
      handleSecondSelection={(secondSelection) =>
        handleSecondSelection(secondSelection)
      }
      contentFilters={contentFilters}
    />
  );
};

export default CrossedFilters;
