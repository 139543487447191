import React, { useState, useEffect } from "react";
import close from "./../../img/closeButton.png";

const RetrievePassword = ({
  closeModal,
  retrievePassword,
  showWrongEmail,
  showConfirmation,
  colors,
}) => {
  const [email, setEmail] = useState("");
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (windowSize.innerWidth > 800) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize.innerWidth]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      width: isMobile ? "92%" : "50%",
      height: 400,
      borderRadius: 10,
    },
    header: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors.primary,
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    content: {
      width: "100%",
      flex: 6,
      backgroundColor: colors.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    textInput: {
      outline: "none",
      padding: 10,
      textAlign: "left",
      width: "60%",
      border: "1px solid lightgray",
      borderRadius: 5,
      fontFamily: "inherit",
      resize: "none",
      backgroundColor: colors.quaternary,
    },
    field: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      marginTop: 20,
    },
    label: {
      color: colors.primary,
      marginBottom: "40px",
    },
    footer: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "center",
    },
    confirmation: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      margin: 40,
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginBottom: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    message: {
      color: colors.white,
      fontWeight: 700,
      fontSize: 13,
      width: "100%",
      backgroundColor: colors.secondary,
    },
  };
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: colors.gray,
          opacity: 0.3,
        }}
      />
      <div style={styles.container}>
        <div style={styles.header}>
          <p style={{ color: colors.white, fontSize: 20 }}>RECUPERO PASSWORD</p>
          <button
            onClick={closeModal}
            style={{
              position: "absolute",
              right: 30,
              marginTop: "auto",
              marginBottom: "auto",
              border: "none",
              backgroundColor: "transparent",
              outline: "none",
              cursor: "pointer",
            }}
          >
            <img
              alt="App logo"
              src={close}
              style={{ width: 15, objectFit: "contain" }}
            />
          </button>
        </div>
        <div style={styles.content}>
          {showConfirmation ? (
            <div style={styles.confirmation}>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 25,
                  color: colors.primary,
                  textAlign: "left",
                }}
              >
                Email recupero password inviata
              </p>
              <p style={{ fontSize: 15, color: colors.primary }}>
                Il link per il recupero password è stato inviato all'indirizzo{" "}
                {email}.
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: 10,
                  color: colors.primary,
                  textAlign: "left",
                }}
              >
                Attenzione! L'email potrebbe essere stata erroneamente
                reindirizzata alla casella Spam.
              </p>
            </div>
          ) : (
            <div style={styles.field}>
              <p style={styles.label}>Inserisci la tua email</p>
              <input
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Email"
                style={styles.textInput}
              />
            </div>
          )}
        </div>
        {showWrongEmail && (
          <div style={styles.message}>
            <p style={{ color: colors.white }}>
              ATTENZIONE! Email non riconosciuta.
            </p>{" "}
          </div>
        )}
        <div style={styles.footer}>
          {!showConfirmation && (
            <button
              onClick={() => retrievePassword(email)}
              style={styles.button}
            >
              RECUPERA
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default RetrievePassword;
