import React, { useEffect, useState } from "react";
import logo from "./../img/logo_white.png";
import { reservationsService } from "../_services";
import colors from "./../museumColors";
import InfoButton from "../components/buttons/infoButton";
import ReactLoading from "react-loading";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const FindReservation = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isMobile, setIsMobile] = useState(false);
  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState("");
  const [code, setCode] = useState("");
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (windowSize.innerWidth > 800) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize.innerWidth]);

  const handleSubmit = (e) => {
    e.preventDefault();
    findReservation();
  };

  const notEmpty = (field) => {
    return field !== "" && field !== null;
  };

  const enableCreation = () => {
    return notEmpty(code);
  };

  /**
   * Call backend to retrieve reservation
   */
  const findReservation = () => {
    setStep(2);
    setMessage("");
    setShowError(false);

    if (code.length === 13) {
      reservationsService
        .findAccessReservationToDelete(code)
        .then((response) => {
          if (response.status === false) {
            reservationsService
              .findEventReservationToDelete(code)
              .then((response) => {
                if (response.status === false) {
                  setShowError(true);
                  setMessage(response.message);
                  setStep(1);
                } else {
                  setStep(3);
                }
              })
              .catch((errors) => {
                setStep(1);
                // c'è stato un errore, mostra il messaggio
                setShowError(true);
                setMessage(errors.message[0].messages[0].message);
              });
          } else {
            setStep(3);
          }
        })
        .catch((errors) => {
          setStep(1);
          // c'è stato un errore, mostra il messaggio
          setShowError(true);
          setMessage(errors.message[0].messages[0].message);
        });
    } else {
      setShowError(true);
      setMessage("Il codice inserito non è un codice prenotazione.");
      setStep(1);
    }
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.secondary,
      overflow: "scroll",
    },
    header: {
      display: isMobile ? "" : "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "100px",
    },
    mainContent: {
      display: "flex",
      width: isMobile ? "90%" : "80%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 10,
    },
    content: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    textInput: {
      outline: "none",
      padding: 10,
      textAlign: "left",
      width: "100%",
      border: "1px solid lightgray",
      borderRadius: 5,
      fontFamily: "inherit",
      resize: "none",
      backgroundColor: colors.quaternary,
      marginRight: isMobile ? 0 : 20,
    },
    field: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      marginTop: 20,
    },
    label: {
      color: colors.primary,
      width: 100,
      marginRight: 40,
    },
    message: {
      color: colors.white,
      fontWeight: 700,
      fontSize: 13,
      width: "100%",
      backgroundColor: "rgba(277,277,277,0.3)",
    },
  };

  return (
    <div style={styles.container}>
      <a style={styles.header} href="https://www.rnb4culture.com/emma/">
        <img
          alt="App logo"
          src={logo}
          style={{ width: 200, objectFit: "contain" }}
        />
      </a>
      <div style={styles.mainContent}>
        <div
          style={{
            height: "87%",
            marginTop: 20,
            backgroundColor: step === 2 ? "" : colors.white,
            padding: 30,
            borderRadius: 10,
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {showError && notEmpty(code) && (
            <div style={styles.message}>
              <p style={{ color: colors.white }}>ATTENZIONE! {message}</p>{" "}
            </div>
          )}
          <div style={styles.content}>
            {step === 1 && (
              <form
                style={{
                  width: isMobile ? "100%" : "90%",
                  padding: 30,
                  borderRadius: 10,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onSubmit={handleSubmit}
              >
                <h2 style={{ color: colors.primary }}>
                  Recupera la tua prenotazione
                </h2>
                <h3
                  style={{
                    color: colors.secondary,
                    fontSize: 13,
                    marginBottom: 0,
                  }}
                >
                  Per annullare la tua prenotazione, inserisci qui il codice che
                  trovi nell'e-mail di conferma.{" "}
                </h3>
                <h3
                  style={{
                    color: colors.secondary,
                    fontSize: 13,
                    marginTop: 0,
                  }}
                >
                  Un'e-mail con le istruzioni per annullare la prenotazione
                  verrà inviata all'indirizzo che hai inserito in fase di
                  prenotazione.
                </h3>
                <div style={styles.field}>
                  <p style={styles.label}>Codice prenotazione</p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <input
                      type="text"
                      name="code"
                      value={code}
                      onChange={(event) => setCode(event.target.value)}
                      placeholder="Codice prenotazione"
                      style={styles.textInput}
                    />
                    {!isMobile && (
                      <InfoButton
                        backgroundColor={colors.quaternary}
                        width={150}
                        text="Il codice di prenotazione è un codice di 13 cifre che identifica univocamente la tua prenotazione. Puoi trovare il tuo codice nell'e-mail di conferma. Es. rnbE213456788"
                        colors={colors}
                        dark={true}
                      />
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  {enableCreation() && (
                    <button
                      onClick={() => findReservation()}
                      style={{
                        border: "none",
                        width: "100%",
                        backgroundColor: "transparent",
                        outline: "none",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <p
                        style={{
                          color: colors.white,
                          fontWeight: "bolder",
                          fontSize: 18,
                          backgroundColor: colors.primary,
                          borderRadius: 10,
                          padding: 20,
                        }}
                      >
                        RECUPERA PRENOTAZIONE
                      </p>
                    </button>
                  )}
                </div>
              </form>
            )}
            {step === 2 && (
              <ReactLoading
                type={"spinningBubbles"}
                color={colors.primary}
                height={50}
                width={50}
              />
            )}
            {step === 3 && (
              <div
                style={{
                  width: "100%",
                  marginTop: 30,
                  marginLeft: 10,
                  padding: 30,
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 10,
                }}
              >
                <h2 style={{ color: colors.primary }}>
                  {showError === true ? "Errore" : "E-mail inviata!"}
                </h2>
                <p style={{ color: colors.darkgray }}>{message}</p>
              </div>
            )}
          </div>
          <div style={{ display: "flex", flex: 1 }}></div>
        </div>
      </div>
    </div>
  );
};

export default FindReservation;
