import React, { useState, useContext, useEffect } from "react";
import EventsManager from "./events/eventsManager";
import { UserContext } from "../../contexts";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import ArchiveManager from "./archive/archiveManager";

const Event = ({ history, permissions, iframe }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const colors = useContext(UserContext)?.colors;

  const selected = () => {
    return tabIndex === 0 ? (
      <EventsManager history={history} />
    ) : (
      <ArchiveManager history={history} setIndex={setTabIndex} />
    );
  };

  useEffect(() => {
    if (permissions?.programmed) setTabIndex(0);
    else if (permissions?.archivio) setTabIndex(1);
  }, [permissions]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      width: iframe ? "100%" : "85%",
      position: "absolute",
      right: 0,
      overflow: "scroll",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    section: {
      alignItems: "start",
      color: colors?.primary,
      width: "100%",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <div style={styles.section}>
          <Tabs
            value={tabIndex}
            variant="fullWidth"
            TabIndicatorProps={{ style: { background: colors?.secondary } }}
            onChange={(event, newValue) => {
              setTabIndex(newValue);
            }}
          >
            <Tab label="In Programma" disabled={!permissions?.programmed} />
            <Tab label="Archivio" disabled={!permissions?.archivio} />
          </Tabs>
        </div>
        {selected()}
      </div>
    </div>
  );
};

export default Event;
