import React, { useState, useEffect } from "react";
import colors from "../museumColors";
import logo from "./../img/logo_white.png";
import { authenticationService } from "../_services";
import ReactLoading from "react-loading";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const JoinOrg = () => {
  const [loader, setLoader] = useState(false);

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [isMobile, setIsMobile] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const jwt = params.get("confirmation");

  const [user, setUser] = useState();
  const [org, setOrg] = useState();
  const [role, setRole] = useState();

  const [noLongerAvailable, setNoLongerAvailable] = useState(false);

  const [isRefuesed, setIsRefused] = useState(false);

  useEffect(() => {
    if (windowSize.innerWidth > 800) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [windowSize.innerWidth]);

  useEffect(() => {
    setLoader(true);
    authenticationService.getMultiOrgToken(jwt).then((response) => {
      if (response.length > 0) {
        setRole(response[0].role);
        setUser(response[0].user);
        setOrg(response[0].organisation);
      } else {
        setNoLongerAvailable(true);
      }
      setLoader(false);
    });
  }, [jwt]);

  const accept = () => {
    setLoader(true);
    authenticationService.acceptMultiOrg(jwt).then((response) => {
      setIsRefused(false);
      setLoader(false);
      window.location = `${process.env.REACT_APP_WEB_URL}`;
    });
  };

  const decline = () => {
    setLoader(true);
    authenticationService.declineMultiOrg(jwt).then((response) => {
      setIsRefused(true);
      setLoader(false);
    });
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.secondary,
      overflow: "scroll",
    },
    header: {
      display: isMobile ? "" : "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "100px",
    },
    mainContent: {
      display: "flex",
      width: "80%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: 10,
      height: "60%",
    },
    content: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    textInput: {
      outline: "none",
      padding: 10,
      textAlign: "left",
      width: "60%",
      border: "1px solid lightgray",
      borderRadius: 5,
      fontFamily: "inherit",
      resize: "none",
      backgroundColor: colors.quaternary,
    },
    field: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      marginTop: 20,
    },
    label: {
      color: colors.primary,
      width: 100,
      marginRight: 40,
    },
    password: {
      color: colors.primary,
      fontStyle: "italic",
      fontSize: 13,
    },
    message: {
      color: colors.white,
      fontWeight: 700,
      fontSize: 13,
      width: "100%",
      backgroundColor: "rgba(277,277,277,0.3)",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      border: "none",
      outline: "none",
      cursor: "pointer",
      margin: "10px",
    },
  };

  return (
    <div style={styles.container}>
      <a style={styles.header} href="https://www.rnb4culture.com/emma/">
        <img
          alt="App logo"
          src={logo}
          style={{
            width: isMobile
              ? windowSize.innerWidth / 3
              : windowSize.innerWidth / 8,
            objectFit: "contain",
          }}
        />
      </a>
      <div style={styles.mainContent}>
        <div
          style={{
            width: "70%",
            height: "87%",
            marginTop: 20,
            marginRight: 20,
            backgroundColor: colors.white,
            padding: 30,
            borderRadius: 10,
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loader ? (
            <ReactLoading
              type={"spinningBubbles"}
              color={colors?.primary}
              height={50}
              width={50}
            />
          ) : isRefuesed ? (
            <>
              <h1 style={{ color: colors.primary }}>Feedback inviato</h1>
              <h3 style={{ color: colors.secondary }}>
                A presto, il team EMMA
              </h3>
            </>
          ) : noLongerAvailable ? (
            <>
              <h1 style={{ color: colors.primary }}>Link non più valido</h1>
              <h3 style={{ color: colors.secondary }}>
                Verifica di non aver già fornito il tuo feedback o contatta il
                museo
              </h3>
            </>
          ) : (
            <>
              <h1 style={{ color: colors.primary }}>
                {"Bentornato in EMMA, " + user?.username}
              </h1>
              <h3 style={{ color: colors.secondary }}>
                {org?.nome +
                  " ti ha invitato ad unirti al suo team con il ruolo di " +
                  role?.name}
              </h3>
              <div style={styles.content}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: 20,
                  }}
                >
                  <div>
                    <button
                      onClick={() => {
                        accept();
                      }}
                      style={styles.button}
                    >
                      ACCETTA
                    </button>
                    <button
                      onClick={() => decline()}
                      style={{
                        ...styles.button,
                        backgroundColor: colors.secondary,
                      }}
                    >
                      DECLINA
                    </button>
                  </div>
                </div>
                <div style={{ display: "flex", flex: 1 }}></div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default JoinOrg;
