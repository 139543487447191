import React, { useState, useEffect } from "react";
import moment from "moment";
import close from "./../../../img/closeButton.png";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import "./../../../customizer.css";

const CustomInput = ({ value, onClick }) => (
  <button
    className="custom-input"
    onClick={onClick}
    style={{ width: 165, textAlign: "left" }}
  >
    {value}
  </button>
);

function isEmpty(value) {
  return value === "" || value === null || value === undefined;
}

function sameValues(val1, val2) {
  var tempVal1, tempVal2;
  if (isEmpty(val1)) tempVal1 = "";
  else tempVal1 = val1.toString();
  if (isEmpty(val2)) tempVal2 = "";
  else tempVal2 = val2.toString();
  return tempVal1 === tempVal2;
}

function somethingDifferent(
  startingInvoiceDate,
  invoiceDate,
  startingInvoiceNumber,
  invoiceNumber,
  startingInvoiceTotal,
  invoiceTotal
) {
  return !(
    sameValues(
      moment(new Date(startingInvoiceDate)).format("DD/MM/yyyy"),
      moment(new Date(invoiceDate)).format("DD/MM/yyyy")
    ) &&
    sameValues(startingInvoiceNumber, invoiceNumber) &&
    sameValues(parseFloat(startingInvoiceTotal), parseFloat(invoiceTotal))
  );
}

function restoreName(nameString) {
  if (nameString.includes("_DEL")) {
    var splitName = nameString.split("_DEL");
    return splitName[0];
  } else {
    return nameString;
  }
}

const PurchaseInvoiceManagement = ({
  purchase,
  closeModal,
  saveData,
  colors,
}) => {
  const [step, setStep] = useState(1);

  const articleName =
    purchase.acquisti_articoli !== null
      ? restoreName(purchase.acquisti_articoli.nome_articolo)
      : "-";

  const articleCategory =
    purchase.acquisti_articoli !== null
      ? purchase.acquisti_articoli.nome_categoria
      : "-";

  const purchaseMethod =
    purchase.modalita_acquisto !== null
      ? purchase.modalita_acquisto.nome_modalita
      : null;

  const isContoVendita =
    purchase.modalita_acquisto !== null
      ? purchase.modalita_acquisto.nome_modalita === "Conto vendita"
        ? true
        : false
      : false;

  const unitaryCost =
    purchase.costo_articolo !== null ? purchase.costo_articolo : 0;
  const quantity = purchase.quantita !== null ? purchase.quantita : 0;
  const giacenza = purchase.giacenza !== null ? purchase.giacenza : 0;
  const sold = purchase.quantita !== null ? purchase.quantita - giacenza : 0;
  const articlesTotalCost = isContoVendita
    ? unitaryCost * sold
    : purchase.quantita
    ? unitaryCost * purchase.quantita
    : 0;
  const dateFixed = purchase.datetime_pagamento ? true : false;
  const [paidSwitch, setPaidSwitch] = useState(
    purchase.datetime_pagamento ? true : false
  );
  const startingInvoiceDate = purchase.datetime_pagamento;
  const startingInvoiceNumber = purchase.num_fattura;
  const startingInvoiceTotal = purchase.tot_fattura;

  const [invoiceDate, setInvoiceDate] = useState(
    purchase.datetime_pagamento ? purchase.datetime_pagamento : null
  );
  const [invoiceNumber, setInvoiceNumber] = useState(
    purchase.num_fattura ? purchase.num_fattura : null
  );
  const [invoiceTotal, setInvoiceTotal] = useState(
    purchase ? purchase.tot_fattura : null
  );
  const [additionalCost, setAdditionalCost] = useState(
    invoiceTotal ? invoiceTotal - articlesTotalCost : 0
  );

  useEffect(() => {
    if (invoiceTotal >= articlesTotalCost) {
      setAdditionalCost(invoiceTotal - articlesTotalCost);
    } else {
      setAdditionalCost(0);
    }
  }, [invoiceTotal, articlesTotalCost]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 30,
      margin: "auto",
      width: 600,
      zIndex: 300,
      justifyContent: "center",
    },
    header: {
      display: "flex",
      width: "100%",
      height: 60,
      backgroundColor: colors.primary,
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    content: {
      width: "100%",
      maxHeight: 400,
      flexDirection: "column",
      backgroundColor: colors.white,
      display: "flex",
      alignItems: "center",
      overflowY: "scroll",
    },
    textInput: {
      border: "1px solid lightgray",
      borderRadius: 10,
      width: 130,
      padding: 18,
      outline: "none",
    },
    footer: {
      display: "flex",
      width: "100%",
      height: somethingDifferent(
        startingInvoiceDate,
        invoiceDate,
        startingInvoiceNumber,
        invoiceNumber,
        startingInvoiceTotal,
        invoiceTotal
      )
        ? 120
        : 30,
      backgroundColor: colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    footer2buttons: {
      display: "flex",
      width: "100%",
      height: 120,
      backgroundColor: colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "space-between",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      marginTop: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    buttonLeft: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginLeft: 40,
      marginBottom: 40,
      marginTop: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    disabledButton: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      marginTop: 40,
      border: "none",
      outline: "none",
      opacity: 0.7,
    },
    label: {
      fontSize: 15,
      color: colors.lightgray,
      width: 400,
      textAlign: "left",
    },
    text: {
      fontSize: 15,
      marginTop: 30,
      width: 400,
      textAlign: "center",
    },
    halfLabel: {
      fontSize: 15,
      color: colors.lightgray,
      width: "100%",
      textAlign: "left",
    },
    box: {
      width: 400,
      display: "flex",
    },
    halfBox: {
      width: "100%",
      display: "flex",
    },
    warning: {
      color: "red",
      marginLeft: 10,
      fontSize: 14,
      marginTop: 40,
      width: 400,
      textAlign: "left",
    },
    doubleRow: {
      display: "flex",
      flexDirection: "row",
      width: 400,
      marginTop: 10,
    },
    halfRow: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
    },
    subsectionHeader: {
      display: "flex",
      flexDirection: "row",
      width: 400,
      borderBottom: "1px solid",
      borderBottomColor: colors.secondary,
      marginTop: 40,
    },
    subsectionTitle: {
      color: colors.secondary,
      marginTop: 10,
      marginBottom: 10,
    },
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: colors.gray,
          opacity: 0.7,
        }}
      />
      <div style={styles.container}>
        <div style={styles.header}>
          <p style={{ color: colors.white, fontSize: 20 }}>PAGAMENTO</p>
          <button
            onClick={closeModal}
            style={{
              position: "absolute",
              right: 30,
              marginTop: "auto",
              marginBottom: "auto",
              border: "none",
              backgroundColor: "transparent",
              outline: "none",
              cursor: "pointer",
            }}
          >
            <img
              alt="Close symbol"
              src={close}
              style={{ width: 15, objectFit: "contain" }}
            />
          </button>
        </div>
        {step === 1 && (
          <>
            <div id="scroll" style={styles.content}>
              <div>
                <div style={styles.subsectionHeader}>
                  <p style={styles.subsectionTitle}>INFO CARICO</p>
                </div>
                <div style={styles.doubleRow}>
                  <div style={styles.halfRow}>
                    <p style={styles.halfLabel}>Articolo</p>
                    <div style={styles.halfBox}>
                      <p>{articleName}</p>
                    </div>
                  </div>
                  <div style={styles.halfRow}>
                    <p style={styles.halfLabel}>Categoria</p>
                    <div style={styles.halfBox}>
                      <p>{articleCategory}</p>
                    </div>
                  </div>
                </div>
                <div style={styles.doubleRow}>
                  <div style={styles.halfRow}>
                    <p style={styles.halfLabel}>Modalità d'acquisto</p>
                    <div style={styles.halfBox}>
                      <p>{purchaseMethod}</p>
                    </div>
                  </div>
                  {isContoVendita && (
                    <div style={styles.halfRow}>
                      <p style={styles.halfLabel}>Quantità</p>
                      <div style={styles.halfBox}>
                        <p>{quantity}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div style={styles.subsectionHeader}>
                <p style={styles.subsectionTitle}>COSTO MERCE</p>
              </div>
              <div>
                <div style={styles.doubleRow}>
                  <div style={styles.halfRow}>
                    <p style={styles.halfLabel}>Costo unitario</p>
                    <div style={styles.halfBox}>
                      <p>{unitaryCost.toFixed(2) + " €"}</p>
                    </div>
                  </div>
                  {isContoVendita ? (
                    <div style={styles.halfRow}>
                      <p style={styles.halfLabel}>Venduti</p>
                      <div style={styles.halfBox}>
                        <p>{sold}</p>
                      </div>
                    </div>
                  ) : (
                    <div style={styles.halfRow}>
                      <p style={styles.halfLabel}>Quantità</p>
                      <div style={styles.halfBox}>
                        <p>{quantity}</p>
                      </div>
                    </div>
                  )}
                </div>
                <div style={styles.doubleRow}>
                  <div style={styles.halfRow}>
                    <p style={styles.halfLabel}>Totale</p>
                    <div style={styles.halfBox}>
                      <p>
                        {!isContoVendita || sold > 0
                          ? articlesTotalCost.toFixed(2) + " €"
                          : "0.00 €"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style={styles.subsectionHeader}>
                  <p style={styles.subsectionTitle}>PAGAMENTO FATTURA</p>
                </div>
                <div style={styles.doubleRow}>
                  <div style={styles.halfRow}>
                    <p style={styles.halfLabel}>Fattura pagata</p>
                    <div
                      style={{
                        flex: 2,
                        display: "flex",
                        justifyContent: "left",
                        width: "100%",
                        height: 50,
                      }}
                    >
                      <Switch
                        onChange={() => {
                          if (paidSwitch) {
                            setInvoiceDate(null);
                          } else {
                            setInvoiceDate(new Date());
                          }
                          setPaidSwitch(!paidSwitch);
                        }}
                        checked={paidSwitch}
                        checkedIcon={false}
                        uncheckedIcon={false}
                        disabled={dateFixed}
                        onColor={colors.primary}
                        width={30}
                        height={20}
                      />
                    </div>
                  </div>
                  {paidSwitch && (
                    <div style={styles.halfRow}>
                      <p style={styles.halfLabel}>Data pagamento</p>
                      <div style={styles.halfBox}>
                        <DatePicker
                          selected={new Date(invoiceDate)}
                          onChange={(date) => setInvoiceDate(date)}
                          dateFormat="dd/MM/yyyy"
                          defaultDate={new Date()}
                          locale="it"
                          customInput={<CustomInput />}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div style={styles.subsectionHeader}>
                  <p style={styles.subsectionTitle}>FATTURA</p>
                </div>
                <div style={styles.doubleRow}>
                  <div style={styles.doubleRow}>
                    <div style={styles.halfRow}>
                      <p style={styles.halfLabel}>Numero fattura</p>
                      <div style={styles.halfBox}>
                        <input
                          value={invoiceNumber}
                          onChange={(event) => {
                            setInvoiceNumber(event.target.value);
                          }}
                          placeholder="Numero fattura"
                          style={styles.textInput}
                        />
                      </div>
                    </div>
                    <div style={styles.halfRow}>
                      <p style={styles.halfLabel}>Totale fattura {"[in €]"}</p>
                      <div style={styles.halfBox}>
                        <input
                          value={invoiceTotal}
                          onChange={(event) =>
                            setInvoiceTotal(
                              event.target.value.replace(/[^0-9.]/, "")
                            )
                          }
                          placeholder="Totale fattura"
                          style={styles.textInput}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={styles.doubleRow}>
                  <div style={styles.doubleRow}>
                    <div style={styles.halfRow}>
                      <p style={styles.halfLabel}>Costo merce</p>
                      <div style={styles.halfBox}>
                        <p>{articlesTotalCost.toFixed(2) + " €"}</p>
                      </div>
                    </div>
                    <div style={styles.halfRow}>
                      <p style={styles.halfLabel}>Spese aggiuntive</p>
                      <div style={styles.halfBox}>
                        <p>
                          {!isEmpty(additionalCost) &&
                          !isEmpty(invoiceTotal) &&
                          invoiceTotal >= articlesTotalCost
                            ? additionalCost.toFixed(2) + " €"
                            : "0.00 €"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {!isEmpty(invoiceTotal) && invoiceTotal < articlesTotalCost && (
                <p style={styles.warning}>
                  Il totale della fattura non può essere inferiore al costo
                  della merce
                </p>
              )}
              {paidSwitch && isEmpty(invoiceTotal) && (
                <p style={styles.warning}>Inserisci il totale della fattura</p>
              )}
            </div>

            <div style={styles.footer}>
              {somethingDifferent(
                startingInvoiceDate,
                invoiceDate,
                startingInvoiceNumber,
                invoiceNumber,
                startingInvoiceTotal,
                invoiceTotal
              ) && (
                <button
                  onClick={() => {
                    if (
                      isContoVendita &&
                      !isEmpty(invoiceDate) &&
                      giacenza > 0
                    ) {
                      setStep(2);
                    } else {
                      var tempInvoiceNumber = isEmpty(invoiceNumber)
                        ? null
                        : invoiceNumber;
                      var tempInvoiceTotal = isEmpty(invoiceTotal)
                        ? null
                        : invoiceTotal;
                      saveData(
                        purchase.id,
                        false,
                        invoiceDate,
                        tempInvoiceTotal,
                        tempInvoiceNumber
                      );
                    }
                  }}
                  style={
                    (!isEmpty(invoiceTotal) &&
                      invoiceTotal < articlesTotalCost) ||
                    (paidSwitch && isEmpty(invoiceTotal))
                      ? styles.disabledButton
                      : styles.button
                  }
                  disabled={
                    (!isEmpty(invoiceTotal) &&
                      invoiceTotal < articlesTotalCost) ||
                    (paidSwitch && isEmpty(invoiceTotal))
                  }
                >
                  {"SALVA"}
                </button>
              )}
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div style={styles.content}>
              <p style={styles.text}>
                {"Attenzione!"} {giacenza > 1 ? "Ci sono ancora" : "C'è ancora"}{" "}
                {giacenza} {"unità dell'articolo"}{" "}
                {giacenza > 1 ? "rimaste invendute." : "rimasta invenduta."}{" "}
                {"Vuoi restituirl"}
                {giacenza > 1 ? "e" : "a"}{" "}
                {"al fornitore o creare un nuovo carico con"}{" "}
                {giacenza > 1 ? "gli articoli residui?" : "l'articolo residuo"}
              </p>
            </div>

            <div style={styles.footer2buttons}>
              <button
                onClick={() => {
                  var tempInvoiceNumber = isEmpty(invoiceNumber)
                    ? null
                    : invoiceNumber;
                  var tempInvoiceTotal = isEmpty(invoiceTotal)
                    ? null
                    : invoiceTotal;
                  saveData(
                    purchase.id,
                    false,
                    invoiceDate,
                    tempInvoiceTotal,
                    tempInvoiceNumber
                  );
                }}
                style={styles.buttonLeft}
              >
                {"HO RESTITUITO GLI ARTICOLI"}
              </button>
              <button
                onClick={() => {
                  var tempInvoiceNumber = isEmpty(invoiceNumber)
                    ? null
                    : invoiceNumber;
                  var tempInvoiceTotal = isEmpty(invoiceTotal)
                    ? null
                    : invoiceTotal;
                  saveData(
                    purchase.id,
                    true,
                    invoiceDate,
                    tempInvoiceTotal,
                    tempInvoiceNumber
                  );
                }}
                style={styles.button}
              >
                {"CREA NUOVO CARICO"}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PurchaseInvoiceManagement;
