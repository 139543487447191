import React, { useState, useContext } from "react";
import trash from "./../img/delete_gray.png";
import network from "./../img/network_gray.png";
import { UserContext } from "../contexts";

import moment from "moment";

const Entrance = ({ entrance, userRole, selectEntrance, isNetwork }) => {
  const [hover, setHover] = useState(false);
  const [hoverNetwork, setHoverNetwork] = useState(false);
  const [coordinates, setCoordinates] = useState(false);

  const colors = useContext(UserContext)?.colors;

  const styles = {
    field: {
      width: 170,
      fontSize: 15,
    },
    label: {
      position: "absolute",
      backgroundColor: colors.quaternary,
      paddingLeft: 15,
      paddingRight: 15,
      borderRadius: 15,
      top: coordinates.y,
      left: coordinates.x,
      zIndex: 100,
      color: colors.darkgray,
    },
  };
  return (
    <div>
      {hover && entrance.deleted && (
        <div style={styles.label}>
          <p>Ingresso annullato</p>
        </div>
      )}
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          color: entrance.deleted === true && colors.highlight,
          cursor: "default",
        }}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onMouseMove={(e) => setCoordinates({ x: e.pageX - 200, y: e.pageY })}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {hoverNetwork && !entrance.deleted && (
            <div style={styles.label}>
              <p>Rete museale</p>
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 170,
            }}
          >
            <p>{entrance.codice_biglietto}</p>
            {!isNetwork && entrance.flag_network && (
              <img
                alt="Network Icon"
                src={network}
                style={{ objectFit: "contain", width: 15, marginLeft: 10 }}
                onMouseOver={() => setHoverNetwork(true)}
                onMouseLeave={() => setHoverNetwork(false)}
                onMouseMove={(e) =>
                  setCoordinates({ x: e.pageX - 200, y: e.pageY })
                }
              />
            )}
          </div>
        </div>
        <div>
          <p style={styles.field}>
            {entrance.tipologia ? entrance.tipologia : "-"}
          </p>
        </div>
        <div>
          <p style={styles.field}>
            {moment(new Date(entrance.datetime_ingresso)).format(
              "DD/MM/yyyy, HH:mm"
            )}
          </p>
        </div>
        <div>
          <p style={styles.field}>{entrance.ingressi_rimanenti}</p>
        </div>
        <div>
          <p style={styles.field}>
            {moment(new Date(entrance.datetime_vendita)).format(
              "DD/MM/yyyy, HH:mm"
            )}
          </p>
        </div>
        <div>
          <p style={styles.field}>
            {moment(new Date(entrance.datetime_scadenza)).format("DD/MM/yyyy")}
          </p>
        </div>
        <div>
          <p style={styles.field}>
            {entrance.operatore !== null &&
            entrance.operatore !== undefined &&
            entrance.operatore !== ""
              ? entrance.operatore
              : " - "}
          </p>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          {false &&
            !isNetwork &&
            (entrance.deleted !== true ? (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  selectEntrance(entrance);
                }}
                style={{
                  zIndex: 200,
                  border: "none",
                  backgroundColor: "transparent",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                <img
                  alt="Delete icon"
                  src={trash}
                  style={{ width: 12, objectFit: "contain", margin: 10 }}
                />
              </button>
            ) : (
              <div style={{ width: 45 }}></div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Entrance;
