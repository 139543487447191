import React, { useContext } from "react";
import { UserContext } from "../../../contexts";
import InsightsBox from "../boxes/insightsBox";

const Accesses = (props) => {
  const colors = useContext(UserContext)?.colors;

  //Mean number of accesses rounded to the first decimal
  const accessesNumber =
    Math.round(
      (props.visitorsData.totVis / props.visitorsData.numberOfDays) * 10
    ) / 10;

  const styles = {
    container: {
      width: "75%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: 10,
      borderRadius: 10,
    },
    content: {
      width: "99%",
      height: "99%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 8,
    },
  };
  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <InsightsBox
          title={
            props.tab === "bookshop" ? "Totale Clienti" : "Totale Visitatori"
          }
          body={
            <h2 style={{ color: colors?.primary }}>
              {Math.ceil(props.totVis)}
            </h2>
          }
        />
        <InsightsBox
          title={
            props.tab === "bookshop"
              ? "Media Giornaliera Clienti"
              : "Accessi Medi Giornalieri"
          }
          body={
            <h2 style={{ color: colors?.primary }}>
              {Math.ceil(accessesNumber)}
            </h2>
          }
        />
      </div>
    </div>
  );
};

export default Accesses;
