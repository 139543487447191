import React, { useEffect, useState, useRef } from "react";
import colors from "../museumColors";
import moment from "moment";
import logo from "./../img/logo_white.png";
import rnb from "./../img/rnb4culture.png";

import "react-big-calendar/lib/css/react-big-calendar.css";
import "./../index.css";
import {
  eventsService,
  reservationsService,
  settingsService,
} from "../_services";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it";
import download from "./../img/download.png";
import ReactLoading from "react-loading";
import AutoCompleteText from "../components/autocompleteOnline";
import InfoButton from "../components/buttons/infoButton";

registerLocale("it", it);

function checkColor(color) {
  var c = color.substring(1); // strip #
  var rgb = parseInt(c, 16); // convert rrggbb to decimal
  var r = (rgb >> 16) & 0xff; // extract red
  var g = (rgb >> 8) & 0xff; // extract green
  var b = (rgb >> 0) & 0xff; // extract blue
  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  if (luma < 80) {
    return colors.white;
  } else {
    return colors.darkgray;
  }
}
function spaceFilter(string) {
  let checkedString = string;
  if (checkedString.startsWith(" ")) {
    checkedString = checkedString.substring(1);
  }
  if (checkedString.endsWith(" ")) {
    checkedString = checkedString.substring(0, checkedString.length - 1);
  }
  return checkedString;
}

const OnlineEventReservation = (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [age, setAge] = useState("");
  const [genre, setGenre] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [privacyFlag, setPrivacyFlag] = useState(false);
  const [step, setStep] = useState(1);
  const [info, setInfo] = useState(null);
  const [response, setResponse] = useState("");
  const [loader, setLoader] = useState(true);
  const [loaderCreation, setLoaderCreation] = useState(false);
  const [titleColor, setTitleColor] = useState(colors.darkgray);
  const [isEmail, setIsEmail] = useState(false);
  const [showError, setShowError] = useState(false);
  const [event, setEvent] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [card, setCard] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState(null);

  function waitForElm(selector) {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          observer.disconnect();
          resolve(document.querySelector(selector));
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  }

  const divRef = useRef();
  useEffect(() => {
    waitForElm(".thisRef").then(() => {
      if (
        !!event?.descrizione_evento &&
        divRef.current !== undefined &&
        divRef.current !== null
      ) {
        divRef.current.innerHTML = event?.descrizione_evento;
      }
    });
  }, [event]);

  const styles = {
    container: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    topBar: {
      height: 70,
      width: "100%",
      position: "fixed",
      top: 0,
      backgroundColor: colors.darkgray,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      zIndex: 100,
    },
    header: {
      width: "100%",
      marginTop: 70,
    },
    body: {
      flex: 1,
      display: "flex",
      flexDirection: width > 1000 ? "row" : "column",
    },
    eventInfo: {
      position: "relative",
      top: 0,
      flex: width > 1000 && 1,
      backgroundColor: event?.colore || info?.colore,
      padding: 30,
    },
    reservationInfo: {
      overflowY: "scroll",
      flex: 3,
      paddingTop: 50,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    field: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    title: {
      color: colors.white,
      fontSize: width > 1000 ? 22 : 19,
      fontWeight: "700",
      textAlign: "left",
      marginTop: width < 1000 && 2,
      marginBottom: width < 1000 && 5,
    },
    label: {
      color: titleColor,
      fontSize: 13,
      marginBottom: width < 1000 && 2,
    },
    content: {
      color: titleColor,
      fontSize: 15,
      marginLeft: 20,
      fontWeight: "600",
      textAlign: "left",
      marginBottom: width < 1000 && 2,
    },
    labelInput: {
      color: colors.darkgray,
      fontSize: 16,
    },
    textInput: {
      borderRadius: 5,
      border: "1px solid lightgray",
      outline: "none",
      textAlign: "center",
      marginTop: 10,
      marginBottom: 10,
      padding: 10,
      width: "80%",
    },
    button: {
      outline: "none",
      border: "none",
      display: "flex",
      backgroundColor: event?.colore || info?.colore,
      width: 80,
      height: 50,
      borderRadius: 50,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    bar: {
      width: "100%",
      backgroundColor: colors.highlight,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 15,
      paddingBottom: 15,
      marginTop: 10,
    },
    docDownloader: {
      outline: "none",
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      fontSize: 10,
      padding: 0,
    },
    privacyPolicyText: {
      fontSize: 10,
      color: colors.darkgray,
    },
  };

  const onClickButton = () => {
    switch (step) {
      case 1:
        if (isEmail) {
          setShowError(false);
          setLoaderCreation(true);
          setStep(2);
          reserveEvent();
        } else {
          setShowError(true);
        }
        break;
      case 2:
        resetData();
        setStep(1);
        break;
      default:
        setStep(1);
        break;
    }
  };

  const resetData = () => {
    setName("");
    setSurname("");
    setNumber("");
    setEmail("");
    setMobile("");
    setPrivacyFlag(false);
  };

  const reserveEvent = () => {
    reservationsService
      .createPrivateEventReservation(
        props.match.params.code,
        event.id,
        orgId,
        card,
        spaceFilter(name),
        spaceFilter(surname),
        parseInt(number),
        spaceFilter(email.toLowerCase()),
        mobile,
        zip,
        country,
        genre,
        age,
        event?.posti_disponibili > 0 ? false : true
      )
      .then((response) => {
        setResponse(response);
        setLoaderCreation(false);
      });
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let error = re.test(String(email).toLowerCase());
    if (email === "") {
      setShowError(false);
    }
    setIsEmail(error);

    return error;
  };

  /*
    // Retrieve ticket document
    */
  const getTicket = () => {
    var a = document.createElement("a");
    a.href = response.url;
    a.target = "_blank";
    a.download = "ticket.pdf";
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const getPdf = (url) => {
    var a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = "privacypolicy.pdf";
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  useEffect(() => {
    const getInfo = () => {
      settingsService.getAllMuseums().then((list) => {
        let museum = list.find(
          (mus) => mus.identificativo_museo === props.match.params.museum
        );

        settingsService.getMuseumFromId(museum.id_museo).then((museum) => {
          getEvent();
          setInfo(museum);
          setTitleColor(checkColor(museum.colore));
          setOrgId(museum.orgId);
          setPrivacyPolicy(museum.privacyPolicy);
        });
      });
    };

    const getEvent = () => {
      eventsService.getSingleEvent(props.match.params.event).then((event) => {
        setEvent(event);
        setLoader(false);
      });
    };

    getInfo();
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, [props.match.params]);

  return loader ? (
    <div
      style={{
        ...styles.container,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ReactLoading
        type={"spinningBubbles"}
        color={colors.darkgray}
        height={50}
        width={50}
      />
    </div>
  ) : (
    <div style={styles.container}>
      <div style={styles.topBar}>
        <img
          alt={"Logo"}
          src={logo}
          style={{ height: "60%", objectFit: "contain", marginLeft: 20 }}
        ></img>
        <img
          alt={"Logo"}
          src={rnb}
          style={{ height: "60%", objectFit: "contain", marginLeft: 20 }}
        ></img>
        <img
          alt={"Logo"}
          src={info?.logo?.url}
          style={{ height: "60%", objectFit: "contain", marginRight: 20 }}
        ></img>
      </div>
      <div style={styles.header}>
        <img
          alt={"Header"}
          src={event?.immagine_evento.url}
          style={{ width: "100%", height: "100%", objectFit: "fill" }}
        ></img>
      </div>
      <div style={styles.body}>
        <div style={styles.eventInfo}>
          <div style={{ ...styles.field, alignItems: "flex-start" }}>
            <p style={{ ...styles.title, color: titleColor }}>
              {event?.nome_evento}
            </p>
          </div>
          <div style={{ ...styles.field, flexDirection: "row" }}>
            <p style={styles.label}>Data</p>
            <p style={styles.content}>
              {moment(event?.ora_inizio).format("DD MMM yyy")}
            </p>
          </div>
          <div style={{ ...styles.field, flexDirection: "row" }}>
            <p style={styles.label}>Ora</p>
            <p style={styles.content}>
              {moment(event?.ora_inizio).format("HH:mm")}
            </p>
          </div>
          <div style={{ ...styles.field, flexDirection: "row" }}>
            <p style={styles.label}>Luogo</p>
            <p style={styles.content}>{event?.luogo_evento}</p>
          </div>
          {/*<div style={{ ...styles.field, flexDirection: 'row' }}>
                          <p style={styles.label}>Posti disponibili</p>
                          <p style={styles.content}>{event?.posti_disponibili > 0 ? event?.posti_disponibili : "TERMINATI"}</p>
      </div>*/}
          <div style={{ ...styles.field, alignItems: "flex-start" }}>
            <p style={styles.label}>Informazioni</p>
            <p
              style={{
                ...styles.content,
                marginLeft: 0,
                marginTop: 0,
                textAlign: "left",
                whiteSpace: "pre-line",
              }}
            >
              <div className="thisRef" ref={divRef}>
                {
                  //Custom content will be programmatically inserted in div below
                }
                <div></div>
              </div>
            </p>
          </div>
        </div>
        {step === 1 &&
          (event?.prenotabile || event?.prenotabile_card ? (
            <div style={styles.reservationInfo}>
              <p
                style={{
                  ...styles.title,
                  color: colors.darkgray,
                  fontSize: 25,
                  textAlign: "center",
                }}
              >
                Modulo di prenotazione / <i>Registration form</i>
              </p>
              {event?.posti_disponibili <= 0 && (
                <div style={styles.bar}>
                  <p
                    style={{
                      fontSize: 13,
                      color: colors.white,
                      width: "80%",
                      textAlign: "left",
                    }}
                  >
                    <b
                      style={{
                        fontSize: 18,
                        marginBottom: 10,
                      }}
                    >
                      Tutti i posti disponibili per l'evento sono terminati /
                      <i> All the available seats for the event are booked</i>
                    </b>
                    <br />
                    <br />
                    La tua richiesta di prenotazione verrà aggiunta nella lista
                    d'attesa per l’evento; qualora si liberassero dei posti, li
                    assegneremo secondo l'ordine di inserimento nella lista. Se
                    si arriverà al tuo nominativo, riceverai un'e-mail di
                    conferma prenotazione, contenente il biglietto di ingresso.{" "}
                    <br />
                    <br />
                    <i>
                      {" "}
                      We will add your booking request to the waiting list and
                      assign the untaken seats in the order of registration on
                      the list. You will recive a booking confirmation email
                      with the admission ticket as soon as your seat is free!
                    </i>
                  </p>
                </div>
              )}
              {event?.prenotabile === false &&
                event?.prenotabile_card === true && (
                  <div
                    style={{
                      ...styles.bar,
                      backgroundColor: colors.darkgray,
                    }}
                  >
                    <p
                      style={{
                        fontSize: 13,
                        color: colors.white,
                        width: "80%",
                        textAlign: "left",
                      }}
                    >
                      <b
                        style={{
                          fontSize: 18,
                          marginBottom: 10,
                        }}
                      >
                        Prenotazioni riservate ai possessori di Card /
                        <i> Reservations reserved for Card holders</i>
                      </b>
                      <br />
                      <br />
                      È possibile effettuare una prenotazione solo se in
                      possesso di una Card del Museo. <br />
                      <br />
                      <i>
                        {" "}
                        It is possible to make a reservation only if you own a
                        Museum Card.
                      </i>
                    </p>
                  </div>
                )}
              {props.match.params.code !== "0" && (
                <>
                  <p
                    style={{
                      ...styles.label,
                      color: colors.gray,
                      width: "80%",
                    }}
                  >
                    L'invito ricevuto tramite e-mail non costituisce titolo
                    d'accesso. <br />
                    Per accreditarsi all'evento è necessario registrarsi
                    compilando il form sottostante.
                  </p>
                  <i
                    style={{
                      ...styles.label,
                      color: colors.gray,
                      width: "80%",
                    }}
                  >
                    The invitation received via email is not valid as an access
                    title. <br />
                    To receive the invitation, it’s necessary to register by
                    filling out the form below.
                  </i>
                </>
              )}
              <form style={{ width: "100%" }}>
                {event.prenotabile_card && !event.prenotabile && (
                  <div style={styles.field}>
                    <p style={styles.labelInput}>Numero Card *</p>
                    <input
                      style={styles.textInput}
                      type="text"
                      name="card"
                      value={card}
                      onChange={(event) =>
                        setCard(event.target.value.toLowerCase())
                      }
                      placeholder="Numero Card / Card Number"
                    ></input>
                  </div>
                )}
                <div style={styles.field}>
                  <p style={styles.labelInput}>Nome*</p>
                  <input
                    style={styles.textInput}
                    type="text"
                    name="name"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    placeholder="Nome / Name"
                  ></input>
                </div>
                <div style={styles.field}>
                  <p style={styles.labelInput}>Cognome*</p>
                  <input
                    style={styles.textInput}
                    type="text"
                    name="surname"
                    value={surname}
                    onChange={(event) => setSurname(event.target.value)}
                    placeholder="Cognome / Surname"
                  ></input>
                </div>
                <div style={styles.field}>
                  <p style={styles.labelInput}>Email*</p>
                  <input
                    style={styles.textInput}
                    type="email"
                    name="email"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                      validateEmail(event.target.value);
                    }}
                    placeholder="Email / Email"
                  ></input>
                  {showError && !isEmail && (
                    <p
                      style={{
                        color: colors.highlight,
                        marginTop: 2,
                        fontSize: 10,
                        width: "72%",
                      }}
                    >
                      Email non valida
                    </p>
                  )}
                </div>
                <div style={styles.field}>
                  <p style={styles.labelInput}>Cellulare*</p>
                  <input
                    style={styles.textInput}
                    type="tel"
                    name="mobile"
                    value={mobile}
                    onChange={(event) =>
                      setMobile(event.target.value.replace(/[^\d]/, ""))
                    }
                    placeholder="Cellulare"
                  ></input>
                </div>
                {!event.prenotabile_card && (
                  <div style={styles.field}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <p style={{ ...styles.labelInput, marginRight: 5 }}>
                        Numero Card
                      </p>
                      <InfoButton
                        backgroundColor={colors.quaternary}
                        width={150}
                        dark={true}
                        text="Sei titolare di una Card del Museo? Inserisci qui
                      il numero della tua Card. / Are you a Museum Card holder? Insert here
                      the number of your Card."
                        colors={colors}
                      />
                    </div>
                    <input
                      style={styles.textInput}
                      type="text"
                      name="card"
                      value={card}
                      onChange={(event) =>
                        setCard(event.target.value.toLowerCase())
                      }
                      placeholder="Numero Card / Card Number"
                    ></input>
                  </div>
                )}
                <div style={styles.field}>
                  <p style={styles.labelInput}>Numero partecipanti*</p>
                  <p
                    style={{ fontSize: 10, margin: 0, color: colors.darkgray }}
                  >
                    Max 2 persone
                  </p>
                  <input
                    style={styles.textInput}
                    type="number"
                    name="number"
                    value={number}
                    max={2}
                    min={1}
                    onChange={(event) =>
                      event.target.value > 0 && event.target.value < 3
                        ? setNumber(event.target.value)
                        : setNumber("")
                    }
                    placeholder="Numero partecipanti / Number of participants"
                  ></input>
                </div>
                <div style={styles.field}>
                  <p style={styles.labelInput}>Città</p>
                  <AutoCompleteText
                    placeholder="Città"
                    apiKey="GHCZpfub3aa7Io8V9v9x08SzKW1Q9Y5_JX_6yCOGBX8"
                    setZip={(zip) => setZip(zip)}
                    setCountry={(country) => setCountry(country)}
                    colors={colors}
                  />
                </div>
                <div style={{ ...styles.field, marginTop: 10 }}>
                  <p style={styles.labelInput}>Anno di nascita</p>
                  <input
                    value={age}
                    maxLength={4}
                    onChange={(event) =>
                      event.target.value.length === 4
                        ? (parseInt(event.target.value) >=
                            new Date().getFullYear() - 120 &&
                            parseInt(event.target.value) <=
                              new Date().getFullYear()) ||
                          event.target.value === ""
                          ? setAge(event.target.value.replace(/[^0-9.]/, ""))
                          : setAge("")
                        : setAge(event.target.value.replace(/[^0-9.]/, ""))
                    }
                    placeholder="Es. 1990"
                    style={styles.textInput}
                  />
                </div>
                <div style={styles.field}>
                  <p style={styles.labelInput}>Genere</p>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <label
                      style={{
                        marginRight: 30,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <input
                        type="radio"
                        style={{ outline: "none", marginRight: 10 }}
                        value={"M"}
                        checked={genre === "M"}
                        onChange={(event) => setGenre(event.target.value)}
                      />
                      <p>M</p>
                    </label>
                    <label
                      style={{
                        marginRight: 30,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <input
                        type="radio"
                        style={{ outline: "none", marginRight: 10 }}
                        value={"F"}
                        checked={genre === "F"}
                        onChange={(event) => setGenre(event.target.value)}
                      />
                      <p>F</p>
                    </label>
                    <label
                      style={{
                        marginRight: 30,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                      }}
                    >
                      <input
                        type="radio"
                        style={{ outline: "none", marginRight: 10 }}
                        value={"Altro"}
                        checked={genre === "Altro"}
                        onChange={(event) => setGenre(event.target.value)}
                      />
                      <p>Altro</p>
                    </label>
                  </div>
                </div>
                <div
                  style={{
                    ...styles.field,
                    flexDirection: "row",
                    justifyContent: "center",
                    marginTop: 30,
                    marginBottom: 30,
                  }}
                >
                  <input
                    type="checkbox"
                    id="privacy"
                    name="privacy"
                    style={{ outline: "none", marginRight: 10 }}
                    checked={privacyFlag === true}
                    onChange={(event) => setPrivacyFlag(!privacyFlag)}
                  ></input>
                  <label for="privacy" style={styles.privacyPolicyText}>
                    Ho preso visione del{" "}
                    <button
                      style={{
                        ...styles.docDownloader,
                        color: colors.darkgray,
                        textDecoration: "underline",
                      }}
                      onClick={() => getPdf(privacyPolicy.url)}
                    >
                      Trattamento dei dati personali
                    </button>
                  </label>
                </div>
                <div style={styles.field}>
                  <button
                    type="button"
                    style={{
                      ...styles.button,
                      width: 200,
                      cursor: event?.posti_disponibili > 0 && "pointer",
                      backgroundColor: colors.darkgray,
                      color: colors.white,
                      padding: 10,
                      borderRadius: 15,
                      margin: 30,
                      opacity:
                        name === "" ||
                        surname === "" ||
                        email === "" ||
                        mobile === "" ||
                        number === "" ||
                        privacyFlag === false ||
                        (event.prenotabile_card &&
                          !event.prenotabile &&
                          card === "")
                          ? 0.5
                          : 1,
                    }}
                    disabled={
                      name === "" ||
                      surname === "" ||
                      email === "" ||
                      mobile === "" ||
                      number === "" ||
                      privacyFlag === false ||
                      (event.prenotabile_card &&
                        !event.prenotabile &&
                        card === "")
                    }
                    onClick={() => onClickButton()}
                  >
                    {event?.posti_disponibili > 0
                      ? "PRENOTA"
                      : "AGGIUNGI ALLA LISTA D'ATTESA"}
                  </button>
                </div>
              </form>
            </div>
          ) : (
            <div style={styles.reservationInfo}>
              <p
                style={{
                  ...styles.title,
                  color: colors.darkgray,
                  fontSize: 25,
                  textAlign: "center",
                }}
              >
                Prenotazioni chiuse / <i>Reservations closed</i>
              </p>
            </div>
          ))}
        {step === 2 &&
          (loaderCreation ? (
            <div style={styles.reservationInfo}>
              <ReactLoading
                type={"spinningBubbles"}
                color={colors.darkgray}
                height={50}
                width={50}
              />
            </div>
          ) : (
            <div style={styles.reservationInfo}>
              <p
                style={{
                  ...styles.title,
                  color: colors.darkgray,
                  fontSize: 25,
                }}
              >
                {response.status
                  ? "Prenotazione effettuata / Reservation completed"
                  : "La prenotazione non è andata a buon fine / Reservation failed"}
              </p>
              <p
                style={{
                  ...styles.label,
                  color: colors.darkgray,
                  textAlign: "left",
                  width: "70%",
                }}
              >
                {response.message}
              </p>
              {response.status && response.url && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: 40,
                    marginBottom: 80,
                  }}
                >
                  <p
                    style={{
                      ...styles.label,
                      color: colors.gray,
                      width: "80%",
                      marginBottom: 30,
                      fontSize: 14,
                    }}
                  >
                    Scarica il biglietto di ingresso
                  </p>
                  <button
                    style={{ ...styles.button, height: 80 }}
                    onClick={() => getTicket()}
                  >
                    <img
                      alt="Download icon"
                      src={download}
                      style={{ height: 35, objectFit: "contain" }}
                    />
                  </button>
                </div>
              )}
              <div></div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default OnlineEventReservation;
