import React, { useEffect, useState, useRef, useContext } from "react";
import useScanDetection from "use-scan-detection";
import trash from "./../../img/delete_darkgray.png";
import { UserContext, CheckoutContext } from "../../contexts";
import CustomProfilingForm from "./customProfilingForm";
import scanner from "../../img/scannergun.png";
import add from "../../img/add.png";

const Ticket = ({ value, onDelete }) => {
  const styles = {
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    button: {
      outline: "none",
      border: "none",
      backgroundColor: "transparent",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
  };
  return (
    <div style={styles.row}>
      <p>{value}</p>
      <button style={styles.button} onClick={() => onDelete(value)}>
        <img
          alt="Trash icon"
          src={trash}
          style={{ height: 15, objectFit: "contain" }}
        ></img>
      </button>
    </div>
  );
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
function isEmpty(value) {
  return value === "" || value === undefined || value === null;
}
const ScanProfiling = () => {
  const customProfiling = useContext(CheckoutContext)?.customProfiling;
  const updateInfo = useContext(CheckoutContext)?.updateInfo;
  const update = useContext(CheckoutContext)?.updateTicketList;

  const [code, setCode] = useState("");
  const [ticketsList, setTicketsList] = useState([]);
  const prevCode = usePrevious(code);
  const colors = useContext(UserContext)?.colors;

  const [tmpCode, setTmpCode] = useState("");

  const [byHand, setByHand] = useState(false);

  const styles = {
    column: {
      display: "flex",
      flexDirection: "column",
      overflow: "scroll",
      alignItems: "center",
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      margin: 20,
    },
    button: {
      backgroundColor: colors?.darkgray,
      borderRadius: 10,
      padding: 5,
      color: colors?.white,
      fontSize: 15,
      height: 50,
      width: 140,
      cursor: "pointer",
      border: "none",
      outline: "none",
    },
    label: {
      fontSize: 12,
    },
    title: {
      fontWeight: 600,
    },
    byHand: {
      color: colors.primary,
      fontStyle: "italic",
      fontSize: 14,
      marginTop: "30px",
    },
    input: {
      border: "1px solid lightgray",
      borderRadius: 10,
      width: "80%",
      padding: "10px",
      marginTop: "10px",
      marginBottom: "30px",
      outline: "none",
    },
  };
  const deleteTicket = (value) => {
    setCode("");
    setTicketsList(ticketsList?.filter((el) => el !== value));
  };

  useScanDetection({
    onComplete: (code) => {
      let ticketCode = code.replaceAll("Shift", "").replaceAll("Backspace", "");
      setCode(ticketCode);
    },
    minLength: 4, // EAN13
  });

  useEffect(() => {
    function checkIfInList(list, value) {
      return list.includes(value);
    }
    if (
      prevCode !== code &&
      !isEmpty(code) &&
      !checkIfInList(ticketsList, code)
    ) {
      setTicketsList([...ticketsList, code]);
    }
  }, [code, prevCode, ticketsList]);

  useEffect(() => {
    update(ticketsList);
    // eslint-disable-next-line
  }, [ticketsList]);

  const handleCodeInsert = (newCode) => {
    setTmpCode("");
    setCode(newCode);
    setByHand(false);
  };

  return (
    <div style={styles.container}>
      <div style={{ ...styles.column, flex: 1 }}>
        {!ticketsList || ticketsList?.length < 1 ? (
          byHand ? (
            <div style={styles.column}>
              <p style={styles.title}>Inserisci il codice CA del biglietto</p>
              <input
                type="text"
                value={tmpCode}
                onChange={(event) => {
                  setTmpCode(event.target.value);
                }}
                style={styles.input}
              />
              <button
                style={{ ...styles.button, opacity: tmpCode === "" ? 0.4 : 1 }}
                disabled={tmpCode === ""}
                onClick={() => {
                  handleCodeInsert(tmpCode);
                }}
              >
                Inserisci
              </button>
              <button
                onClick={() => setByHand(false)}
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                <p style={styles.byHand}>Scannerizza il codice</p>
              </button>
            </div>
          ) : (
            <div style={styles.column}>
              <p style={styles.title}>Scannerizza un biglietto</p>
              <img
                alt="Delete icon"
                src={scanner}
                style={{
                  width: 200,
                  objectFit: "contain",
                  margin: "0",
                }}
              />
              <button
                onClick={() => setByHand(true)}
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                <p style={styles.byHand}>
                  Non riesci a scannerizzare il codice?
                </p>
              </button>
            </div>
          )
        ) : (
          <div style={{ ...styles.column, width: "100%" }}>
            <p style={styles.label}>
              {ticketsList?.length} bigliett
              {ticketsList?.length < 2 ? "o" : "i"}
            </p>
            {!byHand ? (
              <button
                onClick={() => setByHand(true)}
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  outline: "none",
                  cursor: "pointer",
                }}
              >
                <p style={styles.byHand}>
                  Non riesci a scannerizzare il codice?
                </p>
              </button>
            ) : (
              <div
                style={{
                  ...styles.row,
                  justifyContent: "center",
                  alignItems: "center",
                  width: "90%",
                }}
              >
                <input
                  type="text"
                  value={tmpCode}
                  onChange={(event) => {
                    setTmpCode(event.target.value);
                  }}
                  style={{
                    ...styles.input,
                    width: "90%",
                    marginBottom: 0,
                    marginTop: 0,
                    marginRight: "20px",
                  }}
                  placeholder={"Inserisci il codice CA del biglietto"}
                />
                <button
                  style={{
                    ...styles.button,
                    opacity: tmpCode === "" ? 0.4 : 1,
                    height: "90%",
                    width: "20%",
                  }}
                  disabled={tmpCode === ""}
                  onClick={() => {
                    handleCodeInsert(tmpCode);
                  }}
                >
                  <img
                    alt="Add icon"
                    src={add}
                    style={{
                      width: 10,
                      objectFit: "contain",
                      margin: "0",
                    }}
                  />
                </button>
              </div>
            )}

            {ticketsList?.map((ticket, key) => {
              return (
                <Ticket key={key} value={ticket} onDelete={deleteTicket} />
              );
            })}
          </div>
        )}
      </div>
      <div
        style={{
          ...styles.column,
          flex: 3,
          backgroundColor: colors.verylightgray,
          borderRadius: 15,
          padding: 30,
          display: ticketsList?.length > 0 ? "" : "none",
        }}
      >
        <CustomProfilingForm
          tickets={ticketsList?.length}
          customProfiling={customProfiling}
          update={updateInfo}
          isTicket={true}
        />
      </div>
    </div>
  );
};
export default ScanProfiling;
