import React, { useContext } from "react";
import { UserContext } from "../../contexts";

const ReservationButton = ({ toggle }) => {
  const colors = useContext(UserContext)?.colors;

  const styles = {
    button: {
      outline: "none",
      border: 0,
      backgroundColor: "transparent",
      cursor: "pointer",
    },
    selectionBox: {
      display: "flex",
      flexDirection: "column",
    },
  };
  return (
    <div style={{ marginTop: 20 }}>
      <button
        style={{
          ...styles.button,
          width: 200,
          backgroundColor: colors?.secondary,
          color: colors?.white,
          padding: 10,
          borderRadius: 15,
          margin: 30,
          borderBottomLeftRadius: 15,
          borderBottomRightRadius: 15,
        }}
        onClick={toggle}
      >
        PRENOTA{" "}
      </button>
      {/*toggle && (
        <div style={styles.selectionBox}>
          <button
            style={{
              ...styles.button,
              width: 200,
              backgroundColor: colors?.primary,
              color: colors?.white,
              padding: 10,
              borderBottomLeftRadius: 15,
              borderBottomRightRadius: 15,
            }}
            onClick={toggleVisit}
          >
            PRENOTA INGRESSO
          </button>
          <button
            style={{
              ...styles.button,
              width: 200,
              backgroundColor: colors?.primary,
              color: colors?.white,
              padding: 10,
              borderBottomLeftRadius: 15,
              borderBottomRightRadius: 15,
            }}
            onClick={() => {
              setToggle(false);
              toggleActivity();
            }}
          >
            PRENOTA ATTIVITÀ
          </button>
        </div>
        )*/}
    </div>
  );
};

export default ReservationButton;
