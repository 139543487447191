import React from "react";
import colors from "../../museumColors";
import cross from "./../../img/closeButton.png";

const Failure = ({ restart, renewal }) => {
  const styles = {
    body: {
      flex: 1,
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    button: {
      display: "flex",
      backgroundColor: "red",
      width: 80,
      height: 80,
      borderRadius: 50,
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    title: {
      color: colors.darkgray,
      fontWeight: "800",
      width: "60%",
      textAlign: "left",
      fontSize: 25,
      marginBottom: 0,
    },
    subtitle: {
      color: colors.gray,
      fontWeight: "500",
      width: "60%",
      textAlign: "left",
    },
    text: {
      width: "60%",
      textAlign: "left",
      fontSize: 13,
    },
    column: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    label: {
      fontSize: 12,
      width: "60%",
    },
    restartButton: {
      outline: "none",
      border: "none",
      display: "flex",
      backgroundColor: "transparent",
      width: "70%",
      alignItems: "center",
      justifyContent: "flex-end",
      cursor: "pointer",
      color: colors.gray,
      marginTop: 20,
      fontSize: 12,
      textDecoration: "underline",
    },
  };
  return (
    <div style={styles.body}>
      <div
        style={{
          ...styles.column,
          flex: 1,
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        <div style={styles.column}>
          <div style={styles.button}>
            <img
              alt="Download icon"
              src={cross}
              style={{ height: 35, objectFit: "contain" }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          ...styles.column,
          flex: 2,
        }}
      >
        <p style={styles.title}>Si è verificato un errore</p>
        <p style={styles.subtitle}>
          {renewal
            ? "Il rinnovo della card non è andato a buon fine."
            : "La creazione della card non è andata a buon fine."}
        </p>
        <p style={styles.text}>
          Ci scusiamo per il disagio. La preghiamo di riprovare.
        </p>
        {/*<p style={styles.text}>Errore: {errorMessage}</p>*/}
        <button style={styles.restartButton} onClick={restart}>
          {" "}
          Riprova
        </button>
      </div>
    </div>
  );
};

export default Failure;
