import React, { useEffect, useState } from "react";
import close from "./../../../img/closeButton.png";
import ReactLoading from "react-loading";
import { eventsService } from "../../../_services";

const RemoveReservation = ({ reservation, closeModal, saveData, colors }) => {
  const [loader, setLoader] = useState(true);
  const [reservations, setReservations] = useState([]);
  const [toDelete, setToDelete] = useState([]);

  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (toDelete.length === reservations.length && toDelete.length > 0) {
      setSelectAll(true);
    }
  }, [toDelete, reservations.length]);

  useEffect(() => {
    eventsService.getGroup(reservation.gruppo?.id).then((response) => {
      setReservations(response.prenotazioni_eventi);
      let tmpRes = response.prenotazioni_eventi.find(
        (el) => el.id === reservation.id
      );
      setToDelete([tmpRes]);
      setLoader(false);
    });
  }, [reservation]);

  const handleList = (item) => {
    // Check if the item is already selected
    const isSelected = toDelete.some(
      (selectedItem) => selectedItem.id === item.id
    );

    // If the item is selected, remove it from the list; otherwise, add it
    if (isSelected) {
      setToDelete(
        toDelete.filter((selectedItem) => selectedItem.id !== item.id)
      );
      setSelectAll(false);
    } else {
      setToDelete([...toDelete, item]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setToDelete([]);
      setSelectAll(false);
    } else {
      setToDelete(reservations);
      setSelectAll(true);
    }
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      width: 500,
      height: 300,
      zIndex: 300,
    },
    loaderBox: {
      display: "flex",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
      zIndex: 300,
      justifyContent: "center",
      alignItems: "center",
    },
    header: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors.primary,
      alignItems: "center",
      justifyContent: "center",
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
    },
    content: {
      width: "100%",
      flex: 6,
      backgroundColor: colors.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    field: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "center",
      width: "100%",
      marginLeft: 50,
      marginRight: 50,
    },
    footer: {
      display: "flex",
      width: "100%",
      flex: 2,
      backgroundColor: colors.white,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
      alignItems: "center",
      justifyContent: "flex-end",
    },
    button: {
      backgroundColor: colors.primary,
      color: colors.white,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
    cancelButton: {
      borderWidth: 1,
      borderColor: colors.primary,
      backgroundColor: colors.white,
      color: colors.primary,
      padding: 20,
      borderRadius: 20,
      marginRight: 40,
      marginBottom: 40,
      border: "none",
      outline: "none",
      cursor: "pointer",
    },
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: colors.gray,
          opacity: 0.3,
        }}
      />
      {loader ? (
        <div style={styles.loaderBox}>
          <ReactLoading
            type={"spinningBubbles"}
            color={colors.primary}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <div style={styles.container}>
          <div style={styles.header}>
            <p style={{ color: colors.white, fontSize: 20 }}>
              ELIMINA PRENOTAZIONE
            </p>
            <button
              onClick={closeModal}
              style={{
                position: "absolute",
                right: 30,
                marginTop: "auto",
                marginBottom: "auto",
                border: "none",
                backgroundColor: "transparent",
                outline: "none",
                cursor: "pointer",
              }}
            >
              <img
                alt="App logo"
                src={close}
                style={{ width: 15, objectFit: "contain" }}
              />
            </button>
          </div>
          <div style={styles.content}>
            {reservations.length <= 1 ? (
              <div style={styles.field}>
                <p style={{ fontSize: 15, color: colors.lightgray }}>
                  Attenzione!
                </p>
                <p style={{ textAlign: "left", color: colors.darkgray }}>
                  La prenotazione{" "}
                  {/*a nome {reservation.nome_partecipante + " "+ reservation.cognome_partecipante} per {reservation.numero_partecipanti} persone*/}{" "}
                  verrà eliminata definitivamente. <br /> Sei sicuro di voler
                  procedere?
                </p>
              </div>
            ) : (
              <div style={styles.field}>
                <p style={{ fontSize: 15, color: colors.lightgray }}>
                  Attenzione!
                </p>
                <p style={{ textAlign: "left", color: colors.darkgray }}>
                  La prenotazione{" "}
                  {/*a nome {reservation.nome_partecipante + " "+ reservation.cognome_partecipante} per {reservation.numero_partecipanti} persone*/}{" "}
                  appartiene a un gruppo. <br /> Seleziona quale dei componenti
                  vuoi rimuovere:
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "scroll",
                  }}
                  id="scroll"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <input
                      type="checkbox"
                      onChange={() => handleSelectAll()}
                      checked={selectAll}
                      style={{ cursor: "pointer" }}
                    />
                    <p
                      style={{
                        marginLeft: 20,
                        fontSize: 15,
                        fontWeight: "bold",
                      }}
                    >
                      Seleziona tutti
                    </p>
                  </div>
                  {reservations.map((res, key) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <input
                          key={key}
                          type="checkbox"
                          onChange={() => handleList(res)}
                          checked={
                            toDelete.some(
                              (selectedItem) => selectedItem.id === res.id
                            ) || selectAll
                          }
                          style={{ cursor: "pointer" }}
                        />
                        <p style={{ marginLeft: 20, fontSize: 15 }}>
                          {res.cognome_partecipante +
                            " " +
                            res.nome_partecipante}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div style={styles.footer}>
            <button onClick={closeModal} style={styles.cancelButton}>
              ANNULLA
            </button>
            <button
              onClick={() => {
                if (toDelete.length > 0) {
                  setLoader(true);
                  saveData(toDelete);
                }
              }}
              style={styles.button}
              disable={toDelete.length <= 0}
            >
              ELIMINA
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default RemoveReservation;
