import React, { useEffect, useState, useContext } from "react";
import SearchBox from "../../components/searchBox";
import plus from "./../../img/plus.png";
import minus from "./../../img/minus.png";
import reserved from "./../../img/reserved.png";
import { UserContext, OrgContext } from "../../contexts";
import ReactLoading from "react-loading";
import ScanProfiling from "./scanProfiling";

const ItemsList = React.memo(
  ({
    categoriesList,
    categoriesLoaded,
    shoppingCart,
    setShoppingCart,
    setIsTicket,
  }) => {
    const [inputValue, setInputValue] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(-1); // category selected
    const [itemsList, setItemsList] = useState([]); // list of items for the selected category
    const colors = useContext(UserContext)?.colors;
    const user = useContext(UserContext)?.user;
    const settings = useContext(OrgContext)?.org?.settings;

    const [atLeastOneItem, setAtLeastOneItem] = useState(false);

    const [selectedArticleQty, setSelectedArticleQty] = useState(null);
    const [tempQty, setTempQty] = useState(0);

    // check what category show open as default when opening the "Flusso cassa" module
    const correctStartingCategory = () => {
      //return the index corresponding to the first category with at least 1 item; it returns the first (Checkout) even if it has no items in case Vivaticket setting is enabled
      let i = 0;
      if (settings?.viva_ticket) {
        return i;
      } else {
        for (i = 0; i < categoriesList.length; i++) {
          if (categoriesList[i].lista_articoli.length > 0) {
            return i;
          }
        }
        return 0; //if no category has at least 1 item, return 0 by default
      }
    };

    // check if there is at least one item in the "Inventario"
    const checkAtLeastOneItem = () => {
      //set atLeastOneItem true as soon as an item is found in the "Inventario"
      let i = 0;
      for (i = 0; i < categoriesList.length; i++) {
        if (categoriesList[i].lista_articoli.length > 0) {
          return true;
        }
      }
      return false;
    };

    const computeItemGiacenza = (item) => {
      let tmp = 0;
      for (let purchase of item.acquisti) {
        tmp += purchase.giacenza;
      }
      return tmp;
    };

    useEffect(() => {
      if (categoriesList.length > 0 && itemsList.length > 0) {
        //Check if ticket category is selected
        let tickets = categoriesList.find(
          (cat) => cat.nome_categoria.toUpperCase() === "BIGLIETTI"
        );
        setIsTicket(
          tickets.lista_articoli.some((item) => item.id === itemsList[0].id)
        );
      }
    }, [categoriesList, itemsList, setIsTicket]);

    useEffect(() => {
      if (user !== null) {
        setSelectedCategory(-1);
        //getCategories();
      }
    }, [user]);

    useEffect(() => {
      setAtLeastOneItem(checkAtLeastOneItem());
      if (categoriesList.length > 0) {
        setSelectedCategory(categoriesList[correctStartingCategory()].id);
        setItemsList(categoriesList[correctStartingCategory()].lista_articoli);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoriesList]);

    const styles = {
      content: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        height: 100,
        flex: 8,
        margin: 10,
      },
      column: {
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
      },
      wrapperDivVertical: {
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "90%",
        height: "100%",
      },
      itemField: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-around",
      },
      itemOutOfStock: {
        display: "none",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-around",
        opacity: 0.4,
      },
      noItemsInCategoryWarning: {
        margin: 20,
        color: colors?.primary,
      },
      noItemsWarning: {
        display: "flex",
        margin: 20,
        color: colors?.primary,
        alignItems: "center",
        justifyContent: "center",
      },
      textInput: {
        borderRadius: 5,
        border: "1px solid lightgray",
        outline: "none",
        textAlign: "center",
        padding: 5,
        width: "40%",
        fontSize: "medium",
      },
      button: {
        outline: "none",
        border: 0,
        backgroundColor: "transparent",
        marginLeft: 10,
        marginRight: 2,
        cursor: "pointer",
      },
    };

    useEffect(() => {
      setSelectedArticleQty(null);
      setTempQty(0);
      if (selectedCategory !== -1) {
        if (inputValue !== "") {
          setItemsList(
            categoriesList
              .find((cat) => cat.id === selectedCategory)
              .lista_articoli.filter((item) => {
                let tmp = 0;
                for (let purchase of item.acquisti) {
                  tmp = tmp + purchase.giacenza;
                }
                if (item.deleted !== true) {
                  return item.nome_articolo
                    .toLowerCase()
                    .includes(inputValue.toLowerCase());
                } else {
                  return null;
                }
              })
          );
        } else {
          setItemsList(
            categoriesList
              .find((cat) => cat.id === selectedCategory)
              .lista_articoli.filter((item) => {
                let tmp = 0;
                for (let purchase of item.acquisti) {
                  tmp += purchase.giacenza;
                }
                if (
                  (item.nome_categoria === "Biglietti" || tmp > 0) &&
                  item.deleted !== true
                ) {
                  //shoe the items in Flusso cassa only if it has not been deleted AND it is a ticket or if it has giacenza > 0
                  return item;
                } else {
                  return null;
                }
              })
          );
        }
      }
    }, [selectedCategory, categoriesList, inputValue]);

    return (
      <div
        style={{
          ...styles.column,
          width: shoppingCart.length > 0 ? "80%" : "100%",
        }}
      >
        {atLeastOneItem && (
          <SearchBox
            inputValue={inputValue}
            filterOnChange={(event) => setInputValue(event.target.value)}
            categoriesList={categoriesList}
            onSelectCategory={setSelectedCategory}
            selectedCategory={selectedCategory}
            colors={colors}
          />
        )}

        <div style={styles.content}>
          {settings?.viva_ticket &&
          categoriesList[correctStartingCategory()]?.id === selectedCategory ? (
            <ScanProfiling />
          ) : itemsList.length > 0 ? (
            <div style={styles.wrapperDivVertical} id="scroll">
              {itemsList.map((item, key) => {
                return (
                  <div
                    key={key}
                    style={
                      item.categoria_articolo.nome_categoria.toUpperCase() ===
                        "BIGLIETTI" || computeItemGiacenza(item) > 0
                        ? styles.itemField
                        : styles.itemOutOfStock
                    }
                  >
                    <p style={{ width: 200 }}>
                      {item.nome_articolo} {item.autore && " - " + item.autore}
                    </p>
                    <p>€ {item.prezzo_articolo.toFixed(2)}</p>
                    {selectedArticleQty !== item.id ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "right",
                          alignItems: "center",
                          width: "150px",
                        }}
                      >
                        <button
                          onClick={() => {
                            if (
                              shoppingCart.filter((x) => x.id === item.id)
                                .length > 0
                            ) {
                              setSelectedArticleQty(null);
                              setTempQty(0);
                              const temp = [...shoppingCart];
                              const keys = temp.map((el) => el.id);
                              temp.splice(keys.lastIndexOf(item.id), 1);
                              setShoppingCart(temp);
                            }
                          }}
                          disabled={
                            shoppingCart.filter((x) => x.id === item.id)
                              .length <= 0
                          }
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            display: "flex",
                            cursor:
                              shoppingCart.filter((x) => x.id === item.id)
                                .length <= 0
                                ? "default"
                                : "pointer",
                            opacity:
                              shoppingCart.filter((x) => x.id === item.id)
                                .length <= 0
                                ? 0
                                : 1,
                          }}
                        >
                          <img
                            alt="Remove icon"
                            src={minus}
                            style={{
                              width: 8,
                              objectFit: "contain",
                              marginInline: 20,
                            }}
                          />
                        </button>
                        <p
                          style={{ cursor: "pointer", minWidth: 50 }}
                          onClick={() => {
                            setSelectedArticleQty(item.id);
                            setTempQty(
                              shoppingCart.filter((x) => x.id === item.id)
                                .length
                            );
                          }}
                        >
                          {shoppingCart.filter((x) => x.id === item.id).length}
                        </p>
                        <button
                          onClick={() => {
                            setSelectedArticleQty(null);
                            setTempQty(0);
                            if (
                              item.categoria_articolo.nome_categoria.toUpperCase() ===
                                "BIGLIETTI" ||
                              computeItemGiacenza(item) >
                                shoppingCart.filter((x) => x.id === item.id)
                                  .length
                            ) {
                              let copiedItem = Object.assign({}, item);
                              setShoppingCart([...shoppingCart, copiedItem]);
                            }
                          }}
                          disabled={
                            item.categoria_articolo.nome_categoria.toUpperCase() !==
                              "BIGLIETTI" &&
                            computeItemGiacenza(item) <=
                              shoppingCart.filter((x) => x.id === item.id)
                                .length
                          }
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                            outline: "none",
                            display: "flex",
                            cursor:
                              item.categoria_articolo.nome_categoria.toUpperCase() !==
                                "BIGLIETTI" &&
                              computeItemGiacenza(item) <=
                                shoppingCart.filter((x) => x.id === item.id)
                                  .length
                                ? "default"
                                : "pointer",
                            opacity:
                              item.categoria_articolo.nome_categoria.toUpperCase() !==
                                "BIGLIETTI" &&
                              computeItemGiacenza(item) <=
                                shoppingCart.filter((x) => x.id === item.id)
                                  .length
                                ? 0
                                : 1,
                          }}
                        >
                          <img
                            alt="Add icon"
                            src={plus}
                            style={{
                              width: 8,
                              objectFit: "contain",
                              marginInline: 20,
                            }}
                          />
                        </button>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "150px",
                        }}
                      >
                        <input
                          value={tempQty}
                          onChange={(event) => {
                            setTempQty(event.target.value);
                          }}
                          style={{
                            ...styles.textInput,
                            color:
                              item.categoria_articolo.nome_categoria.toUpperCase() !==
                                "BIGLIETTI" &&
                              computeItemGiacenza(item) < tempQty
                                ? colors.red
                                : colors.darkgray,
                          }}
                        />
                        <button
                          style={{
                            ...styles.button,
                            backgroundColor: colors.secondary,
                            opacity:
                              (item.categoria_articolo.nome_categoria.toUpperCase() !==
                                "BIGLIETTI" &&
                                computeItemGiacenza(item) < tempQty) ||
                              tempQty === ""
                                ? 0.2
                                : 1,
                            cursor:
                              (item.categoria_articolo.nome_categoria.toUpperCase() !==
                                "BIGLIETTI" &&
                                computeItemGiacenza(item) < tempQty) ||
                              tempQty === ""
                                ? "default"
                                : "pointer",
                            padding: 10,
                            borderRadius: 8,
                            color: colors.white,
                            marginBottom: 0,
                          }}
                          disabled={
                            (item.categoria_articolo.nome_categoria.toUpperCase() !==
                              "BIGLIETTI" &&
                              computeItemGiacenza(item) < tempQty) ||
                            tempQty === ""
                          }
                          onClick={() => {
                            var currentlyIn = shoppingCart.filter(
                              (x) => x.id === item.id
                            ).length;
                            if (tempQty > currentlyIn) {
                              var toBeAdded = tempQty - currentlyIn;

                              var tempInAdd = [...shoppingCart];
                              let copiedItem = Object.assign({}, item);
                              for (var i = 0; i < toBeAdded; i++) {
                                tempInAdd = [...tempInAdd, copiedItem];
                              }
                              setShoppingCart(tempInAdd);
                            } else if (tempQty < currentlyIn) {
                              var toBeRemoved = currentlyIn - tempQty;

                              var tempInRemove = [...shoppingCart];
                              for (var j = 0; j < toBeRemoved; j++) {
                                var keys = tempInRemove.map((el) => el.id);
                                tempInRemove.splice(
                                  keys.lastIndexOf(item.id),
                                  1
                                );
                              }
                              setShoppingCart(tempInRemove);
                            } else {
                              // no action
                            }
                            setSelectedArticleQty(null);
                            setTempQty(0);
                          }}
                        >
                          <img
                            alt="Confirm icon"
                            src={reserved}
                            style={{
                              width: 10,
                              objectFit: "contain",
                              margin: "0",
                            }}
                          />
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : atLeastOneItem ? (
            <p style={styles.noItemsInCategoryWarning}>
              Nessun articolo di questa tipologia corrispondente ai criteri di
              ricerca presente in Inventario
            </p>
          ) : categoriesLoaded ? (
            <p style={styles.noItemsWarning}>
              Nessun articolo presente in Inventario
            </p>
          ) : (
            <div style={styles.noItemsWarning}>
              <ReactLoading
                type={"spinningBubbles"}
                color={colors?.primary}
                height={50}
                width={50}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default ItemsList;
