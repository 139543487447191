import React, { useContext } from "react";
import { LanguageContext } from "../../../containers/language";

const Footer = ({ colors, createCards }) => {
  const { dictionary } = useContext(LanguageContext);

  const styles = {
    footer: {
      display: "flex",
      flex: 1,
      width: "100%",
      minHeight: 80,
      background: colors.secondary,
      alignItems: "center",
      justifyContent: "flex-end",
      boxShadow: "0 0 4px 2px rgb(0 0 0 / 10%)",
    },

    button: {
      backgroundColor: colors.primary,
      borderRadius: 10,
      padding: 2,
      color: colors.white,
      fontSize: 15,
      width: 150,
      marginRight: 50,
      cursor: "pointer",
      border: "none",
      outline: "none",
    },
  };
  return (
    <div style={styles.footer}>
      <button style={styles.button} onClick={createCards}>
        <p>{dictionary.activateCard.toUpperCase()}</p>
      </button>
    </div>
  );
};

export default Footer;
