import React, { useEffect, useState, useContext } from "react";
import { OrgContext } from "../../../contexts";
import Footer from "../footer";
import moment from "moment";
import "moment/locale/it";
import Form from "../form";
import BillingForm from "../billingForm";
import arrow from "../../../img/arrow_back_dark.png";
import Recap from "../recap";
import { cardsService } from "../../../_services";
import Success from "../success";
import Failure from "../failure";
import ReactLoading from "react-loading";
import placeholderImg from "../../../img/placeholder.png";

moment.locale("it");

function isEmpty(field) {
  return field === "" || field === null || field === undefined;
}

function validateEmail(email, setEmailError) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let error = re.test(String(email).toLowerCase());
  if (email === "") {
    setEmailError(false);
  } else {
    setEmailError(!error);
  }
  return error;
}

const CardsCreator = ({ cardTypes, colors }) => {
  const org = useContext(OrgContext)?.org;

  const [loader, setLoader] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);
  const [total, setTotal] = useState(0);
  const [chosenCard, setChosenCard] = useState(null);
  const [chosenTarget, setChosenTarget] = useState(null);
  const [cardNumber, setCardNumber] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [birth, setBirth] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [zip, setZip] = useState("");
  const [taxCode, setTaxCode] = useState("");
  const [sdiCode, setSdiCode] = useState("");
  const [hasVAT, setHasVAT] = useState(false);

  const [giftName, setGiftName] = useState("");
  const [giftEmail, setGiftEmail] = useState("");
  const [giftEmailError, setGiftEmailError] = useState(false);
  const [giftMessage, setGiftMessage] = useState("");
  const [giftRecap, setGiftRecap] = useState(false);
  const [giftForm, setGiftForm] = useState(null);
  const [giftFormCollected, setGiftFormCollected] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [existingCardNumberError, setExistingCardNumberError] = useState(false);
  const [profilationCheckBox, setProfilationCheckBox] = useState(false);
  const [marketingCheckBox, setMarketingCheckBox] = useState(false);
  const [showButton, setShowButton] = useState(true);
  //Billing info
  const [bname, setbName] = useState("");
  const [bsurname, setbSurname] = useState("");
  const [bemail, setbEmail] = useState("");
  const [bbirth, setbBirth] = useState("");
  const [baddress, setbAddress] = useState("");
  const [bcity, setbCity] = useState("");
  const [bcounty, setbCounty] = useState("");
  const [bzip, setbZip] = useState("");
  const [btaxCode, setbTaxCode] = useState("");
  const [bsdiCode, setbSdiCode] = useState("");
  const [bhasVAT, setbHasVAT] = useState(false);
  const [bemailError, setbEmailError] = useState(false);
  const [useCardData, setUseCardData] = useState(false);
  const [taxCodeError, setTaxCodeError] = useState(false);
  const [infoCollected, setInfoCollected] = useState(false);
  const [billingInfoCollected, setBillingInfoCollected] = useState(false);
  const [ownersList, setOwnersList] = useState([]);
  const [billingInfo, setBillingInfo] = useState(null);
  const [index, setIndex] = useState(1);
  const [creationEnded, setCreationEnded] = useState(false);
  const [success, setSuccess] = useState(false);
  const [createdCards, setCreatedCards] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Contanti");
  const [isVip, setIsVip] = useState(false);

  const saveInfo = () => {
    let owner = {};
    owner.numero = cardNumber;
    owner.nome = name;
    owner.cognome = surname;
    owner.email = email;
    owner.nascita = birth;
    owner.via = address;
    owner.citta = city;
    owner.provincia = county;
    owner.cap = parseInt(zip);
    owner.consenso_profilazione = profilationCheckBox;
    owner.consenso_marketing = marketingCheckBox;
    setOwnersList([...ownersList, owner]);
  };

  const saveBillingInfo = () => {
    let owner = {};
    owner.nome = bname;
    owner.cognome = bsurname;
    owner.email = bemail;
    let indirizzo = {};
    indirizzo.via = baddress;
    indirizzo.citta = bcity;
    indirizzo.provincia = bcounty;
    indirizzo.cap = parseInt(bzip);
    owner.indirizzo = indirizzo;
    bhasVAT
      ? (owner.partita_iva = btaxCode)
      : (owner.codice_fiscale = btaxCode);
    owner.sdi = bsdiCode;
    owner.metodo_pagamento = paymentMethod;
    owner.vip = isVip;
    setBillingInfo(owner);
  };

  const goBack = () => {
    if (index > 1) {
      if (billingInfoCollected) {
        setBillingInfoCollected(false);
      } else if (infoCollected) {
        setInfoCollected(false);
        setIndex(1);
      } else if (giftFormCollected) {
        setGiftFormCollected(false);
      } else {
        setIndex(index - 1);
      }
    }
  };

  const goNext = () => {
    if (tabIndex === 0) {
      if (index === chosenTarget?.max_componenti) {
        setInfoCollected(true);
      }
      cardsService.checkCardNumber(cardNumber).then((response) => {
        if (
          response.length === 0 &&
          (isEmpty(ownersList.find((owner) => owner.numero === cardNumber)) ||
            ownersList.findIndex((owner) => owner.numero === cardNumber) + 1 ===
              index)
        ) {
          if (index <= chosenTarget?.max_componenti) {
            if (index > ownersList.length) {
              saveInfo();
              refreshData();
            }
            setIndex(index + 1);
          }
        } else {
          setExistingCardNumberError(true);
        }
      });
    } else {
      setGiftForm({
        name: giftName,
        message: giftMessage,
        email: giftEmail,
      });
      setGiftFormCollected(true);
      setIndex(index + 1);
    }
  };

  const preventSaveInfo = () => {
    if (tabIndex === 0) {
      return (
        isEmpty(cardNumber) ||
        isEmpty(name) ||
        isEmpty(surname) ||
        isEmpty(email) ||
        emailError === true ||
        isEmpty(birth) ||
        isEmpty(address) ||
        isEmpty(city) ||
        isEmpty(county) ||
        isEmpty(zip) ||
        existingCardNumberError === true ||
        profilationCheckBox === false ||
        marketingCheckBox === false
      );
    } else {
      return !giftRecap || giftEmailError === true;
    }
  };

  const preventSaveBillingInfo = () => {
    return (
      isEmpty(bname) ||
      isEmpty(bsurname) ||
      isEmpty(bemail) ||
      bemailError === true ||
      isEmpty(baddress) ||
      isEmpty(bcity) ||
      isEmpty(bcounty) ||
      isEmpty(bzip) ||
      isEmpty(btaxCode) ||
      taxCodeError === true
    );
  };

  const refreshData = () => {
    setCardNumber("");
    setName("");
    setSurname("");
    setEmail("");
    setBirth("");
    setAddress("");
    setCity("");
    setCounty("");
    setZip("");
    setTaxCode("");
    setHasVAT(false);
    setProfilationCheckBox(false);
    setMarketingCheckBox(false);
    setShowButton(true);
    setUseCardData(false);
  };

  const refreshBillingData = () => {
    setUseCardData(false);
    setbName("");
    setbSurname("");
    setbEmail("");
    setbBirth("");
    setbAddress("");
    setbCity("");
    setbCounty("");
    setbZip("");
    setbTaxCode("");
    setbSdiCode("");
    setbHasVAT(false);
    setPaymentMethod("Contanti");
    setIsVip(false);
  };

  const restart = () => {
    refreshData();
    refreshBillingData();
    setCreatedCards([]);
    setCreationEnded(false);
    setIndex(1);
    setChosenCard(cardTypes[0]);
    setInfoCollected(false);
    setBillingInfoCollected(false);
    setOwnersList([]);
    setGiftForm(null);
    setBillingInfo(null);
  };

  const createCards = () => {
    setLoader(true);
    setShowButton(false);
    if (tabIndex === 0) {
      cardsService
        .createCards(
          chosenTarget.id,
          JSON.stringify(ownersList),
          JSON.stringify(billingInfo)
        )
        .then((response) => {
          setLoader(false);
          setCreationEnded(true);
          setSuccess(response.status);
          if (response.status) {
            setCreatedCards(response.message);
          } else {
            setErrorMessage(response.message);
          }
        });
    } else {
      let indirizzo = {};
      indirizzo.via = billingForm.address;
      indirizzo.citta = billingForm.city;
      indirizzo.provincia = billingForm.county;
      indirizzo.cap = billingForm.zip;
      giftForm.senderName = billingForm.name;
      giftForm.senderSurname = billingForm.surname;
      let tempEmail = giftEmail !== "" ? giftEmail : billingForm.email;
      cardsService
        .createCardSale(
          org.id,
          chosenTarget.id,
          tempEmail,
          billingForm.name,
          billingForm.surname,
          //birth,
          indirizzo,
          billingForm.hasVAT,
          billingForm.hasVAT ? "" : billingForm.taxCode,
          billingForm.hasVAT ? billingForm.taxCode : "",
          billingForm.sdiCode,
          { type: "cassa" },
          "biglietteria",
          parseFloat(chosenTarget.prezzo),
          true,
          true,
          billingForm.paymentMethod,
          false,
          true,
          giftForm
        )
        .then((response) => {
          setLoader(false);
          setCreationEnded(true);
          setSuccess(response.status);
          if (response.status) {
            setCreatedCards(response.message);
          } else {
            setErrorMessage(response.message);
          }
        });
    }
  };

  const form = {
    cardNumber: cardNumber,
    setCardNumber: setCardNumber,
    existingCardNumberError: existingCardNumberError,
    setExistingCardNumberError: setExistingCardNumberError,
    name: name,
    setName: setName,
    surname: surname,
    setSurname: setSurname,
    email: email,
    setEmail: setEmail,
    birth: birth,
    setBirth: setBirth,
    emailError: emailError,
    setEmailError: setEmailError,
    address: address,
    setAddress: setAddress,
    city: city,
    setCity: setCity,
    county: county,
    setCounty: setCounty,
    zip: zip,
    setZip: setZip,
    taxCode: taxCode,
    setTaxCode: setTaxCode,
    sdiCode: sdiCode,
    setSdiCode: setSdiCode,
    hasVAT: hasVAT,
    setHasVAT: setHasVAT,
    profilationCheckBox: profilationCheckBox,
    setProfilationCheckBox: setProfilationCheckBox,
    marketingCheckBox: marketingCheckBox,
    setMarketingCheckBox: setMarketingCheckBox,
  };

  const billingForm = {
    name: bname,
    setName: setbName,
    surname: bsurname,
    setSurname: setbSurname,
    email: bemail,
    setEmail: setbEmail,
    birth: bbirth,
    setBirth: setbBirth,
    emailError: bemailError,
    setEmailError: setbEmailError,
    address: baddress,
    setAddress: setbAddress,
    city: bcity,
    setCity: setbCity,
    county: bcounty,
    setCounty: setbCounty,
    zip: bzip,
    setZip: setbZip,
    taxCode: btaxCode,
    setTaxCode: setbTaxCode,
    taxCodeError: taxCodeError,
    setTaxCodeError: setTaxCodeError,
    sdiCode: bsdiCode,
    setSdiCode: setbSdiCode,
    hasVAT: bhasVAT,
    setHasVAT: setbHasVAT,
    useCardData: useCardData,
    setUseCardData: setUseCardData,
    paymentMethod: paymentMethod,
    setPaymentMethod: setPaymentMethod,
    isVip: isVip,
    setIsVip: setIsVip,
  };

  useEffect(() => {
    if (tabIndex === 0 || (giftName !== "" && giftMessage !== "")) {
      setGiftRecap(true);
    } else {
      setGiftRecap(false);
    }
  }, [tabIndex, giftName, giftMessage]);

  useEffect(() => {
    setChosenCard(cardTypes[0]);
  }, [cardTypes]);

  useEffect(() => {
    if (
      !isEmpty(chosenCard) &&
      chosenCard.target_tesseres.find((card) => card.id === -1) === undefined
    ) {
      chosenCard.target_tesseres = [
        { id: -1, nome: "Tutte" },
        ...chosenCard.target_tesseres,
      ];
    }
    setChosenTarget(chosenCard?.target_tesseres[0]);
  }, [chosenCard]);

  useEffect(() => {
    if (!isEmpty(chosenCard)) {
      chosenCard.target_tesseres = chosenCard.target_tesseres.filter(
        (card) => card.id !== -1
      );
    }
    setChosenTarget(chosenCard?.target_tesseres[0]);
    refreshData();
    setOwnersList([]);
    setIndex(1);
  }, [chosenCard]);

  useEffect(() => {
    setTotal(chosenTarget?.prezzo);
    refreshData();
    setOwnersList([]);
    setIndex(1);
  }, [chosenTarget]);

  useEffect(() => {
    if (index > ownersList.length) {
      refreshData();
    } else {
      const i = index - 1;
      setCardNumber(ownersList[i].numero);
      setName(ownersList[i].nome);
      setSurname(ownersList[i].cognome);
      setEmail(ownersList[i].email);
      setBirth(ownersList[i].nascita);
      setAddress(ownersList[i].via);
      setCity(ownersList[i].citta);
      setCounty(ownersList[i].provincia);
      setZip(ownersList[i].cap);
      setTaxCode(
        !isEmpty(ownersList[i].codice_fiscale)
          ? ownersList[i].codice_fiscale
          : ownersList[i].partita_iva
      );
      setSdiCode(ownersList[i].sdi);
      setProfilationCheckBox(ownersList[i].consenso_profilazione);
      setMarketingCheckBox(ownersList[i].consenso_marketing);
    }
  }, [index, ownersList]);

  useEffect(() => {
    if (ownersList.length === chosenTarget?.max_componenti) {
      setInfoCollected(true);
    } else {
      setInfoCollected(false);
    }
  }, [ownersList, setInfoCollected, chosenTarget]);

  useEffect(() => {
    if (!isEmpty(billingInfo)) {
      setBillingInfoCollected(true);
    } else {
      setBillingInfoCollected(false);
    }
  }, [billingInfo, setBillingInfoCollected]);

  useEffect(() => {
    if (ownersList.length > 0) {
      if (useCardData === true) {
        setbName(ownersList[0].nome);
        setbSurname(ownersList[0].cognome);
        setbEmail(ownersList[0].email);
        setbAddress(ownersList[0].via);
        setbCity(ownersList[0].citta);
        setbCounty(ownersList[0].provincia);
        setbZip(ownersList[0].cap);
      } else {
        setbName("");
        setbSurname("");
        setbEmail("");
        setbAddress("");
        setbCity("");
        setbCounty("");
        setbZip("");
      }
    }
  }, [
    useCardData,
    ownersList,
    setbName,
    setbSurname,
    setbEmail,
    setbAddress,
    setbCity,
    setbCounty,
    setbZip,
  ]);

  useEffect(() => {
    setExistingCardNumberError(false);
  }, [cardNumber]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
    },
    buttonChoice: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      underline: "none",
      cursor: "pointer",
      borderRight: 0,
      backgroundColor: "#e2e2e2",
      padding: 20,
      color: colors.gray,
      border: "1px solid #e2e2e2",
      borderTop: 0,
      fontSize: 12,
      fontWeight: "500",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      overflowX: "scroll",
      justifyContent: tabIndex === 1 ? "space-evenly" : "none",
    },
    label: {
      fontSize: 13,
      color: colors.gray,
    },
    menu: {
      border: "1px solid lightgray",
      width: 150,
      paddingRight: 15,
      paddingLeft: 15,
      paddingTop: 10,
      paddingBottom: 10,
      color: colors.darkgray,
      borderRadius: 20,
      backgroundColor: "transparent",
      outline: "none",
    },
    column: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "50%",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    rowTabs: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
    },
    rowTop: {
      width: "90%",
      height: 120,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      borderBottom: "1px solid darkgray",
    },
    subcontainer: {
      width: "100%",
      height: "100%",
      paddingBottom: 10,
      paddingTop: 10,
    },
    rowBottom: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      alignContent: "center",
      marginTop: 10,
      width: "90%",
      overflow: "scroll",
    },
    cardImg: {
      width: "40%",
      objectFit: "contain",
      border: "1px solid darkgray",
      borderRadius: 15,
    },
    type: {
      fontSize: 18,
      fontWeight: "700",
      textAlign: "center",
      marginBottom: 0,
      marginTop: 10,
      color: colors.darkgray,
    },
    textInput: {
      outline: "none",
      border: "1px solid lightgray",
      width: "80%",
      height: 20,
      borderRadius: 10,
      marginRight: 10,
      marginLeft: 10,
      padding: 5,
      textAlign: "center",
    },
    target: {
      fontSize: 18,
      fontWeight: "700",
      textAlign: "center",
      marginBottom: 0,
      marginTop: 50,
      color: colors.gray,
    },
    description: {
      fontSize: 13,
      width: "100%",
      textAlign: "left",
    },
    arrow: {
      height: 13,
      objectFit: "contain",
    },
    goNextButton: {
      position: "absolute",
      bottom: 130,
      right: 80,
      display: "flex",
      alignItems: "center",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    goBackButton: {
      position: "absolute",
      bottom: 130,
      right: 180,
      display: "flex",
      alignItems: "center",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    counter: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
  };

  return (
    <div style={styles.container}>
      {loader ? (
        <ReactLoading
          type={"spinningBubbles"}
          color={colors.primary}
          height={50}
          width={50}
        />
      ) : (
        <>
          <div style={styles.rowTabs}>
            <button
              style={{
                ...styles.buttonChoice,
                backgroundColor: tabIndex === 0 ? "transparent" : "#e2e2e2",
                borderBottom: tabIndex === 0 ? 0 : "1px solid #e2e2e2",
              }}
              onClick={() => {
                if (tabIndex === 1) {
                  restart();
                }
                setTabIndex(0);
                setGiftName("");
                setGiftEmail("");
                setGiftMessage("");
              }}
            >
              ACQUISTO
            </button>
            <button
              style={{
                ...styles.buttonChoice,
                backgroundColor: tabIndex === 1 ? "transparent" : "#e2e2e2",
                borderBottom: tabIndex === 1 ? 0 : "1px solid #e2e2e2",
              }}
              onClick={() => {
                restart();
                setTabIndex(1);
              }}
            >
              REGALO
            </button>
          </div>
          <div style={styles.body}>
            <div style={styles.rowTop}>
              <div style={styles.selectBox}>
                <p style={styles.label}>Seleziona la card</p>
                <select
                  name={chosenCard?.nome}
                  style={styles.menu}
                  value={chosenCard?.id}
                  onChange={(event) => {
                    setChosenCard(cardTypes[event.target.selectedIndex]);
                  }}
                >
                  {cardTypes.map((type, key) => (
                    <option key={key} value={type.id}>
                      {type.nome}
                    </option>
                  ))}
                </select>
              </div>
              {chosenCard && chosenCard.target_tesseres.length > 0 && (
                <div style={styles.selectBox}>
                  <p style={styles.label}>Seleziona la tipologia</p>
                  <select
                    name={chosenTarget?.nome}
                    style={styles.menu}
                    value={chosenTarget?.id}
                    onChange={(event) => {
                      setChosenTarget(
                        chosenCard.target_tesseres[event.target.selectedIndex]
                      );
                    }}
                  >
                    {chosenCard.target_tesseres.map((target, key) => (
                      <option key={key} value={target.id}>
                        {target.nome}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <div style={styles.rowBottom}>
              <div style={styles.subcontainer}>
                <div
                  style={{
                    ...styles.row,
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ ...styles.column, width: "30%" }}>
                    <img
                      style={styles.cardImg}
                      src={
                        chosenTarget?.img?.url
                          ? chosenTarget?.img?.url
                          : placeholderImg
                      }
                      alt="Tessera"
                    ></img>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                        textAlign: "left",
                      }}
                    >
                      <p style={styles.type}>{chosenCard?.nome}</p>
                      <p style={styles.target}>{chosenTarget?.nome}</p>
                    </div>
                    <p style={styles.description}>
                      {chosenTarget?.descrizione}
                    </p>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                        textAlign: "left",
                      }}
                    >
                      <p
                        style={{
                          ...styles.target,
                          fontSize: 10,
                          fontWeight: "500",
                          marginTop: 10,
                          marginRight: 5,
                        }}
                      >
                        {chosenTarget?.validita === "giorni"
                          ? "Validità"
                          : "Valida fino al"}
                      </p>
                      <p
                        style={{
                          ...styles.type,
                          fontWeight: "500",
                          marginTop: 10,
                          fontSize: 15,
                        }}
                      >
                        {chosenTarget?.validita === "giorni"
                          ? chosenTarget?.giorni + " giorni"
                          : moment(
                              new Date(chosenTarget?.data_scadenza)
                            ).format("DD MMM yyyy")}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                        textAlign: "left",
                      }}
                    >
                      <p
                        style={{
                          ...styles.target,
                          fontSize: 10,
                          fontWeight: "500",
                          marginTop: 10,
                          marginRight: 5,
                        }}
                      >
                        Prezzo
                      </p>
                      <p
                        style={{
                          ...styles.type,
                          fontWeight: "500",
                          marginTop: 10,
                          fontSize: 15,
                        }}
                      >
                        {chosenTarget?.prezzo.toFixed(2)}
                      </p>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "flex-end",
                        textAlign: "left",
                      }}
                    >
                      <p
                        style={{
                          ...styles.target,
                          fontSize: 10,
                          fontWeight: "500",
                          marginTop: 10,
                          marginRight: 5,
                        }}
                      >
                        Numero intestatari
                      </p>
                      <p
                        style={{
                          ...styles.type,
                          fontWeight: "500",
                          marginTop: 10,
                          fontSize: 15,
                        }}
                      >
                        {chosenTarget?.max_componenti}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "65%",
                      flexDirection: "column",
                    }}
                  >
                    {chosenTarget?.max_componenti > 1 &&
                      ownersList.length < chosenTarget?.max_componenti && (
                        <div style={styles.counter}>
                          <p
                            style={{
                              fontSize: 12,
                              color: colors.darkgray,
                              fontWeight: "800",
                            }}
                          >
                            {index + "/" + chosenTarget?.max_componenti}
                          </p>
                        </div>
                      )}
                    <div
                      style={{
                        ...styles.column,
                        width: "100%",
                        backgroundColor: colors.verylightgray,
                        paddingBottom: 20,
                        marginBottom: 20,
                      }}
                    >
                      {creationEnded ? (
                        success ? (
                          <Success
                            restart={restart}
                            createdCards={createdCards}
                            isCardGifted={tabIndex === 1}
                          />
                        ) : (
                          <Failure
                            restart={restart}
                            errorMessage={errorMessage}
                          />
                        )
                      ) : infoCollected || giftFormCollected ? (
                        billingInfoCollected ? (
                          <Recap
                            colors={colors}
                            ownersList={ownersList}
                            giftForm={giftForm}
                          />
                        ) : (
                          <BillingForm
                            colors={colors}
                            formFunctions={billingForm}
                            giftBilling={tabIndex === 1}
                          />
                        )
                      ) : tabIndex === 1 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "90%",
                            marginTop: 20,
                            marginBottom: 10,
                          }}
                        >
                          <p
                            style={{
                              ...styles.type,
                              marginTop: 0,
                              textAlign: "left",
                              fontSize: 15,
                            }}
                          >
                            A chi si vuole regalare la card?
                          </p>
                          <div style={{ width: "90%", marginTop: 10 }}>
                            <p style={{ ...styles.label, textAlign: "left" }}>
                              Nome del destinatario *
                            </p>
                            <input
                              type="text"
                              style={{
                                ...styles.textInput,
                                width: "100%",
                                margin: 0,
                                padding: 5,
                                textAlign: "left",
                              }}
                              value={giftName}
                              onChange={(event) =>
                                setGiftName(event.target.value)
                              }
                            ></input>
                          </div>
                          <div style={{ width: "90%", marginTop: 10 }}>
                            <p style={{ ...styles.label, textAlign: "left" }}>
                              Email del destinatario (facoltativa, per inviare
                              una mail personalizzata)
                            </p>
                            <input
                              type="text"
                              style={{
                                ...styles.textInput,
                                width: "100%",
                                margin: 0,
                                padding: 5,
                                textAlign: "left",
                              }}
                              value={giftEmail}
                              onChange={(event) => {
                                setGiftEmail(event.target.value);
                                validateEmail(
                                  event.target.value,
                                  setGiftEmailError
                                );
                              }}
                            ></input>
                          </div>
                          {giftEmailError && (
                            <p
                              style={{
                                color: colors.highlight,
                                marginTop: 2,
                                fontSize: 10,
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              Email non valida
                            </p>
                          )}
                          <div style={{ width: "90%", marginTop: 10 }}>
                            <p style={{ ...styles.label, textAlign: "left" }}>
                              Messaggio per il destinatario *
                            </p>
                            <textarea
                              maxLength="250"
                              style={{
                                ...styles.textInput,
                                width: "100%",
                                margin: 0,
                                padding: 5,
                                height: 80,
                                textAlign: "left",
                                resize: "none",
                                fontFamily: "inherit",
                              }}
                              value={giftMessage}
                              onChange={(event) =>
                                setGiftMessage(
                                  event.target.value.replace("\n", "")
                                )
                              }
                            ></textarea>
                          </div>
                        </div>
                      ) : (
                        <Form
                          colors={colors}
                          formFunctions={form}
                          onModify={true}
                        />
                      )}
                    </div>
                  </div>
                  {!creationEnded && (
                    <>
                      {((tabIndex === 0 && infoCollected) ||
                        (tabIndex === 1 && giftFormCollected)) &&
                        index > 1 && (
                          <button style={styles.goBackButton} onClick={goBack}>
                            <img
                              src={arrow}
                              style={styles.arrow}
                              alt={"Go back"}
                            />
                            <p
                              style={{
                                fontSize: 12,
                                color: colors.darkgray,
                                marginLeft: 5,
                                fontWeight: "600",
                              }}
                            >
                              Indietro
                            </p>
                          </button>
                        )}
                      {((tabIndex === 0 && !infoCollected) ||
                        (tabIndex === 1 && !giftFormCollected)) &&
                        !preventSaveInfo() && (
                          <button style={styles.goNextButton} onClick={goNext}>
                            <p
                              style={{
                                fontSize: 12,
                                color: colors.darkgray,
                                marginRight: 5,
                                fontWeight: "600",
                              }}
                            >
                              Avanti
                            </p>
                            <img
                              src={arrow}
                              style={{
                                ...styles.arrow,
                                transform: "rotate(180deg)",
                              }}
                              alt={"Go next"}
                            />
                          </button>
                        )}
                      {((tabIndex === 0 && infoCollected) ||
                        (tabIndex === 1 && giftFormCollected)) &&
                        !preventSaveBillingInfo() &&
                        !billingInfoCollected && (
                          <button
                            style={styles.goNextButton}
                            onClick={saveBillingInfo}
                          >
                            <p
                              style={{
                                fontSize: 12,
                                color: colors.darkgray,
                                marginRight: 5,
                                fontWeight: "600",
                              }}
                            >
                              Avanti
                            </p>
                            <img
                              src={arrow}
                              style={{
                                ...styles.arrow,
                                transform: "rotate(180deg)",
                              }}
                              alt={"Go next"}
                            />
                          </button>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer
            colors={colors}
            total={total}
            isVip={isVip}
            infoCollected={
              ((tabIndex === 0 && infoCollected) ||
                (tabIndex === 1 && giftFormCollected)) &&
              billingInfoCollected &&
              showButton
            }
            createCards={createCards}
          />
        </>
      )}
    </div>
  );
};

export default CardsCreator;
