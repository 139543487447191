import React, { useEffect, useState } from "react";
import CustomersBar from "./customersBar";
import CustomersManager from "./groups/customersManager";
import SingleVisitorsManager from "./singleVisitors/singleVisitorsManager";

const Customers = ({ history, permissions, iframe, index }) => {
  const [selection, setSelection] = useState(1);

  const selected = (id) => {
    switch (id) {
      case 1:
        return <SingleVisitorsManager history={history} />;
      case 2:
        return <CustomersManager history={history} />;
      default:
        return <CustomersManager history={history} />;
    }
  };

  useEffect(() => {
    if (index !== undefined && !isNaN(parseInt(index))) {
      setSelection(parseInt(index));
    } else {
      permissions?.visitatori
        ? setSelection(1)
        : permissions?.gruppi
        ? setSelection(2)
        : setSelection(1);
    }
  }, [permissions, index]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      width: iframe ? "100%" : "85%",
      position: "absolute",
      right: 0,
      overflow: "scroll",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
  };

  return (
    <div style={styles.container}>
      {!iframe && (
        <CustomersBar
          selection={selection}
          setSelection={(value) => {
            setSelection(value);
          }}
          permissions={permissions}
        />
      )}
      <div style={styles.content}>{selected(selection)}</div>
    </div>
  );
};

export default Customers;
