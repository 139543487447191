import React, { useState, useEffect, useContext } from "react";
import ReactLoading from "react-loading";
import { settingsService } from "./../_services";
import add from "./../img/add.png";
import remove from "./../img/minus_white.png";
import { UserContext, OrgContext } from "../contexts";

function updateSettings(
  barcodeTicket,
  userProfilingChecked,
  stockChecked,
  stockTreshold,
  emailToEmployee,
  reportEmail,
  emailArray,
  strictSlotCapacity,
  fetchOrg
) {
  settingsService
    .updateSetting(
      barcodeTicket,
      userProfilingChecked,
      stockChecked,
      stockTreshold !== "" ? parseInt(stockTreshold) : 1,
      emailToEmployee,
      reportEmail,
      emailArray,
      strictSlotCapacity
    )
    .then((response) => fetchOrg());
}

function getSettings(
  setBarCodeTicket,
  setUserProfiling,
  setStockNotice,
  setStockTreshold,
  setEmailToEmployee,
  setReportEmail,
  setEmailArray,
  setStrictSlotCapacity,
  setLoader
) {
  setLoader(true);
  settingsService
    .getSettings()
    .then((settings) => {
      setLoader(false);
      setBarCodeTicket(settings.biglietti_con_codice);
      setUserProfiling(settings.profilazione_visitatori);
      setStockNotice(settings.avviso_articoli_esaurimento);
      setStockTreshold(settings.giacenza_limite);
      setEmailToEmployee(settings.email_dipendenti);
      setReportEmail(settings.flag_email_report);
      setEmailArray(settings.email_report);
      setStrictSlotCapacity(settings.strict_slot_capacity);
    })
    .catch((errors) => {
      if (errors.statusCode === 401 || errors.statusCode === 403) {
        this.props.history.push("/");
      }
    });
}

function addAddress(emailArray, setEmailArray, newEmail, setNewEmail) {
  let temp = emailArray;
  temp.push(newEmail);
  settingsService.updateEmailList(temp).then((emails) => {
    setEmailArray(emails.email_report);
    setNewEmail("");
  });
}

function removeAddress(emailArray, setEmailArray, newEmail, setNewEmail) {
  var filtered = emailArray.filter(function (el) {
    return el !== newEmail;
  });
  settingsService
    .updateEmailList(filtered)
    .then((emails) => setEmailArray(emails.email_report));
}

const SettingsManager = () => {
  const colors = useContext(UserContext)?.colors;
  const me = useContext(UserContext)?.user;
  const fetchOrg = useContext(OrgContext)?.fetch;

  const [barcodeTicket, setBarCodeTicket] = useState(null);
  const [userProfiling, setUserProfiling] = useState(null);
  const [stockNotice, setStockNotice] = useState(null);
  const [stockTreshold, setStockTreshold] = useState(null);
  const [emailToEmployee, setEmailToEmployee] = useState(null);
  const [reportEmail, setReportEmail] = useState(null);
  const [emailArray, setEmailArray] = useState(null);
  const [newEmail, setNewEmail] = useState("");
  const [strictSlotCapacity, setStrictSlotCapacity] = useState(null);

  const [loader, setLoader] = useState(false);

  //Get settings
  useEffect(() => {
    getSettings(
      setBarCodeTicket,
      setUserProfiling,
      setStockNotice,
      setStockTreshold,
      setEmailToEmployee,
      setReportEmail,
      setEmailArray,
      setStrictSlotCapacity,
      setLoader
    );
  }, [me.flag_network]);

  //Update if new barcode ticket
  useEffect(() => {
    if (
      barcodeTicket !== null &&
      userProfiling !== null &&
      stockNotice !== null &&
      stockTreshold !== null &&
      emailToEmployee !== null &&
      reportEmail !== null &&
      emailArray !== null &&
      strictSlotCapacity !== null
    ) {
      updateSettings(
        barcodeTicket,
        userProfiling,
        stockNotice,
        stockTreshold,
        emailToEmployee,
        reportEmail,
        emailArray,
        strictSlotCapacity,
        fetchOrg
      );
    }
  }, [
    barcodeTicket,
    userProfiling,
    stockNotice,
    stockTreshold,
    emailToEmployee,
    reportEmail,
    emailArray,
    strictSlotCapacity,
    fetchOrg,
  ]);

  const styles = {
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    settingsSection: {
      width: "90%",
    },
    labelTable: {
      flex: 1,
      fontSize: 12,
      color: colors.lightgray,
      textAlign: "left",
    },
    button: {
      border: 0,
      outline: "none",
      borderRadius: 20,
      backgroundColor: colors.tertiary,
      height: 20,
      width: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: 20,
    },
  };
  return loader ? (
    <div style={styles.container}>
      <ReactLoading
        type={"spinningBubbles"}
        color={colors?.primary}
        height={50}
        width={50}
      />
    </div>
  ) : (
    <div style={styles.settingsSection}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={styles.labelTable}>Impostazioni</div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ flexDirection: "column" }}>
            {!me?.flag_network && (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    onChange={() => setBarCodeTicket(!barcodeTicket)}
                    checked={barcodeTicket}
                    disabled={me?.role.id === 3}
                  />
                  <p style={{ marginLeft: 20, fontSize: 15 }}>
                    Utilizza biglietti con codice a barre EMMA
                  </p>
                </div>
                <p
                  style={{
                    marginLeft: 20,
                    fontSize: 12,
                    color: "gray",
                    marginTop: 0,
                    textAlign: "left",
                  }}
                >
                  L'utilizzo dei biglietti con codice a barre forniti da EMMA
                  garantisce l'accuratezza dei dati raccolti e delle analisi
                  effettuate, nonchè la puntualità della derivante reportistica
                  relativa alle abitudini del visitatore. Utilizzare biglietti
                  non scannerizzabili comporta la perdita di alcune informazioni
                  utili alla profilazione dei visitatori.
                </p>
              </>
            )}
            {!me?.flag_network && (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    onChange={() => setStrictSlotCapacity(!strictSlotCapacity)}
                    checked={strictSlotCapacity}
                    disabled={me?.role.id === 3}
                  />
                  <p style={{ marginLeft: 20, fontSize: 15 }}>
                    Rendi stringente la capienza
                  </p>
                </div>
                <p
                  style={{
                    marginLeft: 20,
                    fontSize: 12,
                    color: "gray",
                    marginTop: 0,
                    textAlign: "left",
                  }}
                >
                  Attivando quest'opzione, qualora gli slot orari delle visite
                  dovessero essere sovrapposti (ovvero, quando l'inizio di un
                  turno é antecedente la fine di uno o più turni di visita
                  precedenti), verrebbe considerata la capienza complessivia per
                  limitare il numero di ingressi. In caso contrario, con opzione
                  disattivata, il limite di ingressi sarebbe relativo a ogni
                  slot considerato, a prescindere dal numero di partecipanti
                  degli slot adiacenti.
                </p>
              </>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                onChange={() => setUserProfiling(!userProfiling)}
                checked={userProfiling}
                disabled={me?.role.id === 3}
              />
              <p style={{ marginLeft: 20, fontSize: 15 }}>
                Abilita profilazione visitatori
              </p>
            </div>
            {!me?.flag_network && (
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    type="checkbox"
                    onChange={() => setEmailToEmployee(!emailToEmployee)}
                    checked={emailToEmployee}
                    disabled={me?.role.id === 3}
                  />
                  <p style={{ marginLeft: 20, fontSize: 15 }}>
                    Abilita avviso modifica turni
                  </p>
                </div>
                <p
                  style={{
                    marginLeft: 20,
                    fontSize: 12,
                    color: "gray",
                    marginTop: 0,
                    textAlign: "left",
                  }}
                >
                  Abilitando l'avviso di modifica turni, gli operatori
                  riceveranno una notifica che li informerà dei nuovi turni e/o
                  delle modifiche effettuate su turni a loro assegnati.{" "}
                </p>
              </>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                onChange={() => setStockNotice(!stockNotice)}
                checked={stockNotice}
                disabled={me?.role.id === 3}
              />
              <p style={{ marginLeft: 20, fontSize: 15 }}>
                Abilita avviso articoli in esaurimento
              </p>
            </div>
            {stockNotice && (
              <div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <p
                    style={{
                      fontSize: 13,
                      color: colors.lightgray,
                      textAlign: "left",
                    }}
                  >
                    Avvisa per giacenze inferiori a{" "}
                  </p>
                  <input
                    type="text"
                    style={{
                      outline: "none",
                      border: "1px solid lightgray",
                      width: 30,
                      borderRadius: 40,
                      marginRight: 10,
                      marginLeft: 10,
                      padding: 10,
                      textAlign: "center",
                    }}
                    value={stockTreshold}
                    onChange={(event) => setStockTreshold(event.target.value)}
                    placeholder="10"
                    disabled={me?.role.id === 3}
                  />
                  <p
                    style={{
                      fontSize: 13,
                      color: colors.lightgray,
                      textAlign: "left",
                    }}
                  >
                    {" "}
                    pezzi
                  </p>
                </div>
              </div>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="checkbox"
                onChange={() => setReportEmail(!reportEmail)}
                checked={reportEmail}
                disabled={me?.role.id === 3}
              />
              <p style={{ marginLeft: 20, fontSize: 15 }}>
                Abilita invio report automatico
              </p>
            </div>
            <p
              style={{
                marginLeft: 20,
                fontSize: 12,
                color: "gray",
                marginTop: 0,
                textAlign: "left",
              }}
            >
              Abilitando l'invio automatico del report, ogni giorno verrà
              inviata una email automatica con le vendite della giornata agli
              indirizzi indicati.
            </p>
            {reportEmail && (
              <div style={styles.inputColumn}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="text"
                    style={{
                      outline: "none",
                      border: "1px solid lightgray",
                      width: 250,
                      borderRadius: 40,
                      marginRight: 10,
                      marginLeft: 10,
                      padding: 10,
                      textAlign: "center",
                    }}
                    value={newEmail}
                    onChange={(event) => setNewEmail(event.target.value)}
                    placeholder="nomecognome@email.com"
                    disabled={me?.role.id === 3}
                  />
                  <button
                    style={{
                      ...styles.button,
                      opacity: newEmail !== "" ? 1 : 0.4,
                    }}
                    disabled={newEmail === ""}
                    onClick={() =>
                      addAddress(
                        emailArray,
                        setEmailArray,
                        newEmail,
                        setNewEmail
                      )
                    }
                  >
                    <img
                      alt={"Add email button"}
                      src={add}
                      style={{ width: 10, height: 10, objectFit: "contain" }}
                    ></img>
                  </button>
                </div>
                {emailArray?.map((email, key) => {
                  return (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: 40,
                      }}
                    >
                      <p>{email}</p>
                      <button
                        style={styles.button}
                        onClick={() =>
                          removeAddress(
                            emailArray,
                            setEmailArray,
                            email,
                            setNewEmail
                          )
                        }
                      >
                        <img
                          alt={"Add timeslot button"}
                          src={remove}
                          style={{
                            width: 10,
                            height: 10,
                            objectFit: "contain",
                          }}
                        ></img>
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
            {/*<LanguageSelector />*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsManager;
