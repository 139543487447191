import React, { useState, useContext, useEffect } from "react";
import { LanguageContext } from "../../../../../containers/language";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import search from "../../../../../img/search.png";
import refresh from "../../../../../img/refresh.png";
import arrow from "../../../../../img/arrow_back_dark.png";
import ReactLoading from "react-loading";
import Pagination from "react-js-pagination";
import { eventsService } from "../../../../../_services";
import Table from "./table";
import RequestForm from "./requestForm";

const itemsPerPageOptions = [10, 20, 50, 100];

const RequestsManager = (props) => {
  const colors = props.colors;
  const { dictionary } = useContext(LanguageContext);
  const [tabIndex, setTabIndex] = useState(0);
  const [openRecord, setOpenRecord] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [tempSearchKey, setTempSearchKey] = useState("");
  const [bookings, setBookings] = useState([]);

  // Pagination
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const [bookingsLoaded, setBookingLoaded] = useState(false);

  const [loader, setLoader] = useState(false);
  const [tableLoaded, setTableLoader] = useState(false);

  const [ordine, setOrdine] = useState("Data e ora attività");
  const [ascendente, setAscendente] = useState(true);

  const getBookingRequests = (
    start,
    limit,
    invoice,
    searchKey,
    ordine,
    ascendente
  ) => {
    setBookingLoaded(false);
    setTableLoader(false);
    let tempInvoice = false;

    if (invoice === undefined) {
      tempInvoice = false;
    } else {
      if (invoice === 0) {
        tempInvoice = false;
      } else {
        tempInvoice = true;
      }
    }
    eventsService
      .countSpeakerBookingRequests(tempInvoice, searchKey, props.speaker.id)
      .then((allBookings) => {
        setTotalItemsCount(allBookings);
        eventsService
          .getSpeakerBookingRequests(
            start,
            limit,
            tempInvoice,
            searchKey,
            props.speaker.id,
            ordine,
            ascendente
          )
          .then((bookings) => {
            setBookings(bookings);
            setBookingLoaded(true);
            setTableLoader(true);
          });
      });
  };

  const markAs = (id, speaker_feedback) => {
    setLoader(true);
    eventsService.modifySpeakerRes(id, speaker_feedback).then(() => {
      setActivePage(1);
      getBookingRequests(
        0,
        itemsPerPage,
        tabIndex,
        searchKey,
        ordine,
        ascendente
      );
      setOpenRecord(null);
      setLoader(false);
    });
  };

  const startingSearch = () => {
    // reset searchKey
    let currentSearchKey = "";
    setSearchKey(currentSearchKey);

    let tempActivePage = 1;
    setActivePage(tempActivePage);

    getBookingRequests(
      itemsPerPage * tempActivePage - itemsPerPage,
      itemsPerPage,
      tabIndex,
      currentSearchKey,
      ordine,
      ascendente
    );
  };

  useEffect(() => {
    getBookingRequests(
      itemsPerPage * activePage - itemsPerPage,
      itemsPerPage,
      tabIndex,
      searchKey,
      ordine,
      ascendente
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage, itemsPerPage]);

  useEffect(() => {
    setActivePage(1);
    getBookingRequests(
      0,
      itemsPerPage,
      tabIndex,
      searchKey,
      ordine,
      ascendente
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex]);

  useEffect(() => {
    const handleSubmit = () => {
      setSearchKey(tempSearchKey);

      let tempActivePage = 1;
      setActivePage(tempActivePage);
      getBookingRequests(
        itemsPerPage * tempActivePage - itemsPerPage,
        itemsPerPage,
        tabIndex,
        tempSearchKey,
        ordine,
        ascendente
      );
    };

    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKey, tempSearchKey]);

  function isEmpty(field) {
    return field === "" || field === null || field === undefined;
  }

  const updateOrder = (ordine, ascendente) => {
    setOrdine(ordine);
    setAscendente(ascendente);
    getBookingRequests(
      itemsPerPage * activePage - itemsPerPage,
      itemsPerPage,
      tabIndex,
      searchKey,
      ordine,
      ascendente
    );
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      overflow: "scroll",
      marginTop: props.isMobile ? "50px" : "20px",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      alignItems: "center",
      overflowX: "scroll",
    },
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
    },
    rowTop: {
      alignItems: "start",
      color: colors?.primary,
      width: "100%",
    },
    rowBottom: {
      display: "flex",
      justifyContent: "center",
      width: "90%",
      overflow: "scroll",
    },
    subcontainer: {
      width: "100%",
      paddingBottom: 20,
      paddingTop: 20,
      display: "flex",
      flexDirection: "column",
    },
    inputSearch: {
      borderRadius: 40,
      border: "1px solid #F0F0F0",
      width: props.isMobile ? "100%" : "50%",
      height: 40,
      display: "flex",
      flexDirection: "row",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
    downloadButton: {
      border: "none",
      outline: "none",
      backgroundColor: colors.darkgray,
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      width: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    goBackButton: {
      width: "90%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: "transparent",
    },
    saveButton: {
      border: "none",
      underline: "none",
      cursor: "pointer",
      backgroundColor: colors.darkgray,
      margin: 30,
      padding: 10,
      paddingLeft: 30,
      paddingRight: 30,
      borderRadius: 15,
    },
    arrow: {
      height: 13,
      objectFit: "contain",
    },
    filterButton: {
      border: "1px solid #F0F0F0",
      outline: "none",
      cursor: "pointer",
      borderRadius: 40,
      height: 40,
      padding: 10,
      paddingLeft: 20,
      paddingRight: 20,
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.body}>
        <div style={styles.rowTop}>
          <Tabs
            value={tabIndex}
            variant="fullWidth"
            TabIndicatorProps={{ style: { background: colors?.primary } }}
            textColor={colors?.primary}
            onChange={(event, newValue) => {
              setOpenRecord(null);
              setTabIndex(newValue);
            }}
          >
            <Tab label={dictionary.toBeProcessed} />
            <Tab label={dictionary.processed} />
          </Tabs>
        </div>
        <div style={styles.rowBottom}>
          {!isEmpty(openRecord) ? (
            <div style={styles.subcontainer}>
              <button
                style={styles.goBackButton}
                onClick={() => setOpenRecord(null)}
              >
                <img src={arrow} style={styles.arrow} alt={"Go back"} />
                <p
                  style={{
                    fontSize: 12,
                    color: colors.darkgray,
                    margin: 0,
                    marginLeft: 5,
                    fontWeight: "600",
                  }}
                >
                  Indietro
                </p>
              </button>
              <RequestForm colors={colors} booking={openRecord} />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 40,
                  flexDirection: "row",
                }}
              >
                {loader ? (
                  <ReactLoading
                    type={"spinningBubbles"}
                    color={colors?.primary}
                    height={50}
                    width={50}
                  />
                ) : (
                  <>
                    <button
                      style={
                        tabIndex === 0
                          ? {
                              ...styles.saveButton,
                              backgroundColor: colors.darkgray,
                            }
                          : {
                              ...styles.saveButton,
                              backgroundColor: colors.white,
                              border: "3px solid #303030",
                            }
                      }
                      onClick={() =>
                        markAs(
                          openRecord.id,
                          tabIndex === 0 ? "confirmed" : "pending"
                        )
                      }
                    >
                      <p
                        style={{
                          fontSize: 12,
                          color: tabIndex === 0 ? colors.white : "#303030",
                          margin: 0,
                          fontWeight: "600",
                        }}
                      >
                        {tabIndex === 0
                          ? dictionary.confirmBooking
                          : dictionary.pendingBooking}
                      </p>
                    </button>
                    {tabIndex === 0 && (
                      <button
                        style={
                          tabIndex === 0
                            ? {
                                ...styles.saveButton,
                                backgroundColor: colors.gray,
                              }
                            : {
                                ...styles.saveButton,
                                backgroundColor: colors.white,
                                border: "3px solid #303030",
                              }
                        }
                        onClick={() => markAs(openRecord.id, "refused")}
                      >
                        <p
                          style={{
                            fontSize: 12,
                            color: tabIndex === 0 ? colors.white : "#303030",
                            margin: 0,
                            fontWeight: "600",
                          }}
                        >
                          {dictionary.deleteBooking}
                        </p>
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          ) : (
            <div style={styles.subcontainer}>
              <div
                style={{
                  ...styles.row,
                  justifyContent: "space-between",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <div style={styles.inputSearch}>
                  <img
                    alt="Search icon"
                    src={search}
                    style={{ width: 30, objectFit: "contain", margin: 10 }}
                  />
                  <input
                    type="text"
                    style={{
                      border: 0,
                      width: "100%",
                      borderRadius: 40,
                      outline: "none",
                    }}
                    value={tempSearchKey}
                    onChange={(event) => {
                      setTempSearchKey(event.target.value);
                    }}
                    placeholder={dictionary.search}
                  />
                  <button
                    style={{
                      ...styles.filterButton,
                    }}
                    onClick={() => {
                      setTempSearchKey("");
                      setSearchKey("");
                      startingSearch(); //come back to the original result search (i.e. the ones at the page opening)
                    }}
                  >
                    <img
                      alt="Refresh icon"
                      src={refresh}
                      style={{ width: 18, objectFit: "contain", margin: 2 }}
                    />
                  </button>
                </div>
              </div>
              <div
                id="scroll"
                style={{ overflowY: tableLoaded ? "scroll" : "hidden" }}
              >
                {tableLoaded ? (
                  bookings.length > 0 ? (
                    <div
                      style={{
                        width: "100%",
                        overflowX: "scroll",
                      }}
                    >
                      <Table
                        bookings={bookings}
                        colors={colors}
                        setOpenRecord={setOpenRecord}
                        activePage={activePage}
                        itemsPerPage={itemsPerPage}
                        isMobile={props.isMobile}
                        updateOrder={(ordine, ascendente) =>
                          updateOrder(ordine, ascendente)
                        }
                        ordine={ordine}
                        ascendente={ascendente}
                      />
                    </div>
                  ) : bookingsLoaded ? (
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 14,
                        color: colors.gray,
                        marginTop: 40,
                      }}
                    >
                      {dictionary.noActivity}
                    </p>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        marginTop: 40,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ReactLoading
                        type={"spinningBubbles"}
                        color={colors?.primary}
                        height={50}
                        width={50}
                      />
                    </div>
                  )
                ) : (
                  <div
                    style={{
                      display: "flex",
                      marginTop: 40,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ReactLoading
                      type={"spinningBubbles"}
                      color={colors?.primary}
                      height={50}
                      width={50}
                    />
                  </div>
                )}
                {tableLoaded && bookings.length > 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 10,
                      justifyContent: "center",
                    }}
                  >
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={itemsPerPage}
                      totalItemsCount={totalItemsCount}
                      pageRangeDisplayed={5}
                      onChange={(pageNumber) => {
                        setActivePage(pageNumber);
                      }}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                )}
              </div>
              {tableLoaded && bookings.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "left",
                    alignItems: "center",
                    marginLeft: 40,
                    marginBottom: 80,
                  }}
                >
                  <select
                    name="options"
                    style={{
                      bosetActivePagerder: "1px solid lightgray",
                      borderRadius: 15,
                      height: 50,
                      width: 70,
                      padding: 10,
                      textAlign: "left",
                      marginRight: 10,
                    }}
                    value={itemsPerPage}
                    onChange={(event) => {
                      setItemsPerPage(parseInt(event.target.value));
                      setActivePage(1);
                    }}
                  >
                    {itemsPerPageOptions.map((option, key) => {
                      return (
                        <option key={key} value={option}>
                          {option}
                        </option>
                      );
                    })}
                  </select>
                  <p style={{ color: colors?.gray, fontSize: 12 }}>
                    {dictionary.pageElements}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RequestsManager;
