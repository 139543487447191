import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { reportService } from "../../../_services";
import { OrgContext, UserContext } from "../../../contexts";
import { LanguageContext } from "../../../containers/language";
import ReactLoading from "react-loading";
import DoughnutCustomLegend from "../legends/doughnutCustomLegend";
import DataBoxUserBehavior from "../boxes/dataBoxUserBehaviour";

ChartJS.register(ArcElement, Tooltip, Legend);

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const DifferentInteractionsPercentage = (props) => {
  const org = useContext(OrgContext)?.org;
  const colors = useContext(UserContext)?.colors;
  const { dictionary } = useContext(LanguageContext);

  //The selected data category
  const [selection, setSelection] = useState("");
  const prevSelection = usePrevious(selection);

  const [loader, setLoader] = useState(false);

  const [hiddenDatasets, setHiddenDatasets] = useState({}); // State to track visibility of datasets

  //The render options of the chart. These can be changed as explained in chart.js doc
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "right",
        alignment: "start",
        overflow: "scroll",
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            var label = context.label + ": " + context.parsed + "%";
            return label;
          },
        },
      },
    },
  };

  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: [],
        borderWidth: 1,
      },
    ],
  });

  const filteredData = {
    ...data,
    datasets: data.datasets.map((dataset) => ({
      ...dataset,
      data: dataset.data.map((value, index) =>
        hiddenDatasets[data.labels[index]] ? null : value
      ),
    })),
  };

  //Handle the change of data category selected
  const handleSelection = (selection) => {
    setSelection(selection);
  };

  const generatePastelColor = () => {
    let R = Math.floor(Math.random() * 127 + 127);
    let G = Math.floor(Math.random() * 127 + 127);
    let B = Math.floor(Math.random() * 127 + 127);

    let rgb = (R << 16) + (G << 8) + B;
    return `#${rgb.toString(16)}`;
  };

  const getGraphStats = useCallback(() => {
    setLoader(true);
    reportService
      .getInteractionsDistribution(
        org,
        props.temporalFilters.start,
        props.temporalFilters.end,
        selection
      )
      .then((data) => {
        var labels = [];
        var dataList = [];
        var backgroundColor = [];

        for (const label in data.values) {
          labels.push(
            label +
              " " +
              (parseInt(label) > 1
                ? dictionary.interactions
                : dictionary.interaction)
          );
          dataList.push(data.values[label]);
          backgroundColor.push(generatePastelColor());
        }

        var datasets = [
          {
            label: selection,
            data: dataList,
            backgroundColor: backgroundColor,
            borderWidth: 1,
          },
        ];

        setData({ labels, datasets });
        setLoader(false);
      });
  }, [
    org,
    props.temporalFilters.end,
    props.temporalFilters.start,
    selection,
    dictionary,
  ]);

  useEffect(() => {
    if (
      selection !== "" &&
      selection !== undefined &&
      prevSelection !== selection
    ) {
      getGraphStats();
    }
  }, [selection, prevSelection, getGraphStats]);

  return (
    <DataBoxUserBehavior
      title={"Numero interazioni in percentuale"}
      // maintainAspectRatio: false gives the opportunity to handle the chart's dimension
      body={
        loader ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "120px",
              paddingBottom: "120px",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={colors?.primary}
              height={50}
              width={50}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div style={{ width: "100%" }}>
              <Doughnut
                data={filteredData}
                options={{ ...options, maintainAspectRatio: false }}
                height={"250%"}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <DoughnutCustomLegend
                data={data}
                setHiddenDatasets={setHiddenDatasets}
              />
            </div>
          </div>
        )
      }
      isMean={true}
      handleSelection={(selection) => handleSelection(selection)}
      contentFilters={props.sections}
    />
  );
};

export default DifferentInteractionsPercentage;
