import React, { useState, useContext, useEffect, useRef } from "react";
import { UserContext, OrgContext } from "../../../contexts";
import DateCalendar from "../dateCalendar";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it";
import download_dark from "./../../../img/download_dark.png";
import excel_dark from "./../../../img/excel_dark.png";
import pdf_dark from "./../../../img/pdf_dark.png";
import { reportService } from "../../../_services";
import moment from "moment";
import ReactLoading from "react-loading";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

registerLocale("it", it);

const TopBar = (props) => {
  const colors = useContext(UserContext)?.colors;
  const org = useContext(OrgContext)?.org;

  //check if the filter selected is "Annuale" (annual)
  const [isAnnual, setAnnual] = useState(false);
  const prevIsAnnual = usePrevious(isAnnual);

  const [isFree, setIsFree] = useState(false);
  const prevIsFree = usePrevious(isFree);

  //The index of the filter selected
  const [selection, setSelection] = useState(0);

  //Starting and ending time selected in the "Data libera" (free date selection) filtering option
  const [startingTime, setStartingTime] = useState(null);
  const [endingTime, setEndingTime] = useState(null);

  //When the "Evento" (event) filtering option is selected,
  //we need to chose the event category first and then the event from within the list of events corresponding to that category
  const [category, setCategory] = useState("");
  const [eventsList, setEventsList] = useState([]);
  const [event, setEvent] = useState(null);

  const [date, setDate] = useState(new Date());
  const prevDate = usePrevious(date);

  const [loader, setLoader] = useState(false);

  const [open, setOpen] = useState(false);

  //Method used when the filtering option selected is annual or monthly
  const handleDateSelected = (date) => {
    setDate(date);
  };

  useEffect(() => {
    let check = false;
    if (prevIsFree !== undefined && prevIsFree !== isFree && !isFree) {
      check = true;
    }
    if (prevIsAnnual !== undefined && isAnnual !== prevIsAnnual && !isFree) {
      check = true;
    }

    if (check) {
      props.dateSelected(date, isAnnual);
    }

    if (prevDate !== undefined) {
      var tmpPrevious = new Date(prevDate);
      tmpPrevious.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);
      if (tmpPrevious.getTime() !== date.getTime()) {
        props.dateSelected(date, isAnnual);
      }
    }
  }, [date, prevDate, isAnnual, prevIsAnnual, isFree, prevIsFree, props]);

  //Each time a new category of events is selected, we list of the events corresponding to it and seleted the first by default
  const getListEvents = (chosenCategory) => {
    setEvent(chosenCategory.lista_eventi[0]);
    return chosenCategory.lista_eventi;
  };

  function handleFilterSelection(selection, handleDateSelected) {
    switch (selection) {
      //Case 0: Mensile (monthly)
      case 0:
        return (
          <div style={{ ...styles.datePicker, padding: "1px" }}>
            <DateCalendar
              isAnnual={false}
              dateSelected={(date) => handleDateSelected(date)}
            />
          </div>
        );
      //Case 1: Annuale (annual)
      case 1:
        return (
          <div style={{ ...styles.datePicker, padding: "1px" }}>
            <DateCalendar
              isAnnual={true}
              dateSelected={(date) => handleDateSelected(date)}
            />
          </div>
        );
      //Case 2: Periodo (free date)
      case 2:
        return (
          <>
            <div style={styles.datePicker}>
              <DatePicker
                name="startingDate"
                dateFormat="dd/MM/yyyy"
                locale="it"
                className={"event-custom-input-multiple"}
                selected={startingTime}
                onChange={(date) => {
                  setStartingTime(date);
                  if (endingTime === "") {
                    setEndingTime(date);
                  }
                }}
                maxDate={endingTime !== null ? endingTime : new Date()}
                placeholderText="Data inizio"
              />
            </div>
            <div style={styles.datePicker}>
              <DatePicker
                name="endingTime"
                dateFormat="dd/MM/yyyy"
                className={"event-custom-input-multiple"}
                locale="it"
                minDate={startingTime !== null ? startingTime : null}
                maxDate={new Date()}
                selected={endingTime}
                onChange={(date) => setEndingTime(date)}
                placeholderText="Data fine"
                popperPlacement="bottom-end"
              />
            </div>
          </>
        );
      //Case 3: Evento (event)
      case 3:
        return (
          <div style={styles.row}>
            <div style={{ ...styles.field, width: "100%" }}>
              <select
                name={category?.name}
                style={{
                  ...styles.dropdown,
                  width: "100%",
                  marginRight: "10px",
                  marginLeft: "20px",
                }}
                defaultValue={""}
                value={category?.name}
                onChange={(chosenCategory) => {
                  setCategory(
                    props.categoriesList[chosenCategory.target.selectedIndex]
                  );
                  setEventsList(
                    getListEvents(
                      props.categoriesList[chosenCategory.target.selectedIndex]
                    )
                  );
                }}
              >
                {props.categoriesList.map((category, key) => (
                  <option key={key} value={category.nome_categoria}>
                    {category.nome_categoria}
                  </option>
                ))}
                <option value="" disabled>
                  Scegli una categoria
                </option>
              </select>
            </div>
            {eventsList.length === 0 && (
              <div style={styles.field}>
                <h2 style={{ fontSize: 14, color: colors.red }}>
                  Nessun evento disponibile
                </h2>
              </div>
            )}
            {eventsList.length !== 0 && (
              <div style={{ ...styles.field, width: "100%" }}>
                <select
                  name={event?.nome_evento}
                  style={{
                    ...styles.dropdown,
                    width: "100%",
                    marginRight: "20px",
                    marginLeft: "10px",
                  }}
                  defaultValue={""}
                  value={event?.nome_evento}
                  disabled={category === null}
                  onChange={(event) => {
                    setEvent(eventsList[event.target.selectedIndex]);
                  }}
                >
                  {eventsList.map((event, key) => (
                    <option key={key} value={event.nome_evento}>
                      {event.nome_evento}
                    </option>
                  ))}
                  <option value="" disabled>
                    Scegli un evento
                  </option>
                </select>
              </div>
            )}
          </div>
        );
      //Default: Mensile (monthly)
      default:
        return (
          <DateCalendar
            isAnnual={false}
            dateSelected={(date) => handleDateSelected(date)}
          />
        );
    }
  }

  //Set the starting and ending date with the "Data libera" (free date) filtering option
  useEffect(() => {
    if (startingTime !== null && endingTime !== null) {
      props.setDate(startingTime, endingTime);
    }
  }, [startingTime, endingTime, props]);

  //Set the chosen event
  useEffect(() => {
    if (event !== null) {
      props.setChosenEvent(event);
    }
  });

  const downloadReport = () => {
    setLoader(true);

    if (props.selection === 1) {
      if (props.tabIndex === 0) {
        reportService
          .getReportVisitorPdf(org, props.startingDate, props.endingDate)
          .then((file) => {
            var a = document.createElement("a");
            a.href = file;
            a.target = "_blank";
            a.download =
              "report_" + moment(new Date()).format("DD_MM_yyyy") + ".pdf";
            document.body.appendChild(a);
            a.click();
            a.remove();
            setLoader(false);
          })
          .catch((errors) => {
            if (errors.statusCode === 401 || errors.statusCode === 403) {
              this.props.history.push("/");
              setLoader(false);
            }
          });
      } else {
        reportService
          .getReportVisitorPdf(org, props.startingDate, props.endingDate, true)
          .then((file) => {
            var a = document.createElement("a");
            a.href = file;
            a.target = "_blank";
            a.download =
              "report_" + moment(new Date()).format("DD_MM_yyyy") + ".pdf";
            document.body.appendChild(a);
            a.click();
            a.remove();
            setLoader(false);
          })
          .catch((errors) => {
            if (errors.statusCode === 401 || errors.statusCode === 403) {
              this.props.history.push("/");
              setLoader(false);
            }
          });
      }
    } else if (props.selection === 2) {
      reportService
        .getReportSalesPdf(org, props.startingDate, props.endingDate)
        .then((file) => {
          var a = document.createElement("a");
          a.href = file;
          a.target = "_blank";
          a.download =
            "report_vendite_" +
            moment(new Date()).format("DD_MM_yyyy") +
            ".pdf";
          document.body.appendChild(a);
          a.click();
          a.remove();
          setLoader(false);
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            this.props.history.push("/");
            setLoader(false);
          }
        });
    }
  };

  const downloadBehavior = (isExcel) => {
    setLoader(true);
    setOpen(false);
    if (isExcel) {
      reportService
        .getReportBehaviorExcel(org, props.startingDate, props.endingDate)
        .then((file) => {
          var a = document.createElement("a");
          a.href = file;
          a.target = "_blank";
          a.download =
            "report_vendite_" +
            moment(new Date()).format("DD_MM_yyyy") +
            ".xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          setLoader(false);
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            this.props.history.push("/");
            setLoader(false);
          }
        });
    } else {
      //ELSE IS PDF
      reportService
        .getReportBehaviorPDF(org, props.startingDate, props.endingDate)
        .then((file) => {
          var a = document.createElement("a");
          a.href = file;
          a.target = "_blank";
          a.download =
            "report_vendite_" +
            moment(new Date()).format("DD_MM_yyyy") +
            ".pdf";
          document.body.appendChild(a);
          a.click();
          a.remove();
          setLoader(false);
        })
        .catch((errors) => {
          if (errors.statusCode === 401 || errors.statusCode === 403) {
            this.props.history.push("/");
            setLoader(false);
          }
        });
    }
  };

  const styles = {
    container: {
      padding: 2,
      position: "absolute",
      zIndex: 200,
      right: "0px",
      top: "10px",
      width: "86.8%",
    },
    content: {
      // backgroundColor: colors?.white,
      display: "flex",
      flexDirection: "row",
      padding: "2px",
      justifyContent: "center",
    },
    field: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    info: {
      backgroundColor: colors?.primary,
      color: colors?.white,
      height: 40,
      width: "20%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 20,
    },
    datePicker: {
      color: colors?.primary,
      height: "100%",
      width: "20%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      fontSize: 15,
      marginRight: "20px",
      outline: "none",
      textAlign: "left",
      border: "1px solid lightgray",
      borderRadius: 5,
      padding: "2px",
    },
    eventLabel: {
      color: colors?.primary,
      fontSize: 15,
      height: 30,
      width: "100%",
      margin: 10,
      borderRadius: 3,
    },
    dropdown: {
      color: colors?.primary,
      height: "100%",
      width: "20%",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
      fontSize: 15,
      padding: 10,
      marginRight: "20px",
      cursor: "pointer",
      outline: "none",
      textAlign: "left",
      border: "1px solid lightgray",
      borderRadius: 5,
    },
    row: {
      display: "flex",
      flexDirection: "row",
    },
    button: {
      display: "flex",
      flexDirection: "row",
      border: "1px solid lightgray",
      borderRadius: 5,
      backgroundColor: colors.white,
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "10px",
      cursor: loader || props.selection === 3 ? "" : "pointer",
    },
    popup: {
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <select
          name={"Filtro"}
          style={styles.dropdown}
          value={selection}
          onChange={(chosenCategory) => {
            setSelection(chosenCategory.target.selectedIndex);

            //Che if the filtering option selected is the annual one
            if (chosenCategory.target.selectedIndex === 1) {
              setAnnual(true);
            } else {
              setAnnual(false);
            }

            if (chosenCategory.target.selectedIndex === 2) {
              props.hideLine(true);
              setIsFree(true);
              if (startingTime !== null && endingTime !== null) {
                props.setDate(startingTime, endingTime);
              }
            } else {
              setIsFree(false);
              props.hideLine(false);
            }

            //Reset the event related parameters when another filtering option is selected
            if (chosenCategory.target.selectedIndex !== 3) {
              setEventsList([]);
              setCategory(null);
              setEvent(null);
            }
          }}
        >
          <option key={0} value={0}>
            Mensile
          </option>
          <option key={1} value={1}>
            Annuale
          </option>
          <option key={2} value={2}>
            Periodo
          </option>
          {/* <option key={3} value={3}>
            Evento
          </option> */}
        </select>
        {handleFilterSelection(selection, handleDateSelected)}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          onMouseEnter={() => {
            if (props.selection === 3) setOpen(true);
          }}
          onMouseLeave={() => {
            if (props.selection === 3) setOpen(false);
          }}
        >
          <button
            style={styles.button}
            onClick={() => (loader ? {} : downloadReport())}
          >
            {loader ? (
              <ReactLoading
                type={"spinningBubbles"}
                color={colors.primary}
                height={15}
                width={15}
              />
            ) : (
              <>
                <img
                  src={download_dark}
                  style={{ height: 15, objectFit: "contain" }}
                  alt={"Bookings list"}
                />
                <p
                  style={{
                    fontSize: 15,
                    color: colors.darkgray,
                    margin: 0,
                    marginLeft: 5,
                    fontWeight: "300",
                  }}
                >
                  {props.selection === 1
                    ? props.tabIndex === 0
                      ? "Scarica report dei visitatori"
                      : "Scarica report dei clienti"
                    : props.selection === 2
                    ? "Scarica report vendite"
                    : "Scarica report comportamento"}
                </p>
              </>
            )}
          </button>
          {open && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <button
                style={{
                  ...styles.button,
                  marginTop: "10px",
                  cursor: "pointer",
                }}
                onClick={() => downloadBehavior(true)}
              >
                <>
                  <img
                    src={excel_dark}
                    style={{ height: 15, objectFit: "contain" }}
                    alt={"Bookings list"}
                  />
                  <p
                    style={{
                      fontSize: 15,
                      color: colors.darkgray,
                      margin: 0,
                      marginLeft: 5,
                      fontWeight: "300",
                    }}
                  >
                    Scarica Excel comportamento
                  </p>
                </>
              </button>
              <button
                style={{
                  ...styles.button,
                  marginTop: "10px",
                  cursor: "pointer",
                }}
                onClick={() => downloadBehavior(false)}
              >
                <>
                  <img
                    src={pdf_dark}
                    style={{ height: 20, objectFit: "contain" }}
                    alt={"Bookings list"}
                  />
                  <p
                    style={{
                      fontSize: 15,
                      color: colors.darkgray,
                      margin: 0,
                      marginLeft: 5,
                      fontWeight: "300",
                    }}
                  >
                    Scarica PDF comportamento
                  </p>
                </>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
