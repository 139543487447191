import React from "react";
import arrowDown from "./../../../../img/arrow_down_dark.png";
import arrowUp from "./../../../../img/arrow_up_dark.png";
import moment from "moment";
import "moment/locale/it";
moment.locale("it");

const titles = ["Data e ora attività", "Attività", "Relatore", "Cliente"];
const Table = ({
  activities,
  colors,
  setOpenRecord,
  tab,
  updateOrder,
  ordine,
  ascendente,
}) => {
  const styles = {
    table: {
      textAlign: "center",
      borderCollapse: "collapse",
      border: "1px solid #ddd",
      width: "100%",
      overflow: "scroll",
    },
    headerCell: {
      border: "1px solid #ddd",
      padding: "8px",
      paddingTop: "12px",
      paddingBottom: "12px",
      textAlign: "center",
      backgroundColor: colors.verylightgray,
      fontSize: 12,
      color: colors.gray,
    },
    field: {
      border: "1px solid #ddd",
      padding: "8px",
      fontSize: 12,
    },
    row: {
      cursor: "pointer",
    },
  };

  const renderTableHeader = (titles) => {
    return titles.map((key, index) => {
      return (
        <th
          style={{
            ...styles.headerCell,
            color: ordine === key ? colors.darkgray : colors.gray,
            cursor: "pointer",
          }}
          key={index}
          onClick={() => {
            if (ordine === key) {
              updateOrder(ordine, !ascendente);
            } else {
              updateOrder(key, true);
            }
          }}
        >
          {key.toUpperCase()}
          {ordine === key && (
            <img
              alt="Order Arrow icon"
              src={ascendente ? arrowUp : arrowDown}
              style={{
                width: 10,
                objectFit: "contain",
                marginLeft: "10px",
                marginBottom: "1px",
              }}
            />
          )}
        </th>
      );
    });
  };

  const renderTableData = () => {
    return activities.map((activity, key) => {
      return (
        <tr
          key={key}
          style={styles.row}
          onClick={() => setOpenRecord(activity)}
        >
          <td style={{ ...styles.field, alignItems: "column" }}>
            {moment(new Date(activity.datetime_inizio)).format(
              "DD/MM/yyyy, HH:mm"
            )}
          </td>
          <td style={styles.field}>{activity.attivita?.nome}</td>
          <td style={styles.field}>
            {activity.relatori
              ? activity.relatori.surname + " " + activity.relatori.name
              : "-"}
          </td>
          <td style={styles.field}>{activity.cognome + " " + activity.nome}</td>
          {tab === 1 && <td style={styles.field}>{activity.numero_fattura}</td>}
        </tr>
      );
    });
  };
  return (
    <table style={styles.table}>
      <tbody>
        <tr>
          {renderTableHeader(
            tab === 1 ? [...titles, "Numero fattura"] : titles
          )}
        </tr>
        {renderTableData()}
      </tbody>
    </table>
  );
};

export default Table;
