import React, { useContext, useEffect, useState, useRef } from "react";
import { UserContext, OrgContext } from "../../contexts";
import { LanguageContext } from "../../containers/language";
import UserProfiling from "./userProfiling";
import { itemsService } from "../../_services";
import arrowDown from "./../../img/arrow_down_dark.png";
import arrowUp from "./../../img/arrow_up.png";

function isEmpty(value) {
  return value === "" || value === null || value === undefined;
}

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Profiling = React.memo(
  ({ shoppingCart, showWarning, setShowWarning, setIsCodeNotFound }) => {
    const [firstToBeProfiled, setFirstToBeProfiled] = useState(0);
    const { dictionary } = useContext(LanguageContext);
    const colors = useContext(UserContext)?.colors;
    const styles = {
      column: {
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
      },
      bar: {
        width: "100%",
        boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
        display: "flex",
        alignItems: "center",
        marginTop: 10,
        backgroundColor: colors?.quaternary,
        cursor: "pointer",
      },
      title: {
        color: colors?.darkgray,
        marginLeft: 10,
        fontSize: 14,
        fontWeight: "600",
      },
      box: {
        width: "85%",
      },
      body: {
        boxShadow: "0 0 2px 2px rgb(0 0 0 / 2%)",
      },
    };

    const Sale = ({ item, index, firstToBeProfiled }) => {
      const settings = useContext(OrgContext)?.org?.settings;
      const [open, setOpen] = useState(true);
      const [newTicketCode, setNewTicketCode] = useState(
        item.visitatore?.codice_biglietto || ""
      );
      const [eTicket, seteTicket] = useState(
        item.visitatore?.email ? true : false
      );
      const [openDate, setOpenDate] = useState(
        item.visitatore?.flag_data_aperta || false
      );
      const [email, setEmail] = useState(item.visitatore?.email || "");
      const [info, setInfo] = useState(
        item.visitatore?.additional_info
          ? JSON.parse(JSON.stringify(item.visitatore?.additional_info))
          : null
      );
      const [code, setCode] = useState(
        typeof item.visitatore === "string" ? item.visitatore : ""
      );
      const [codeNotFoundError, setCodeNotFoundError] = useState(false);
      const [alreadyRegistered, setAlreadyRegistered] = useState(
        typeof item.visitatore === "string" ? true : false
      );
      const [warning, setWarning] = useState(item.visitatore?.warning || false);
      const [errorMessage, setErrorMessage] = useState(
        item.visitatore?.errorMessage || ""
      );

      const preveTicket = usePrevious({ eTicket });
      const prevNewTicketCode = usePrevious({ newTicketCode });

      const form = {
        newTicketCode: newTicketCode,
        setNewTicketCode: setNewTicketCode,
        eTicket: eTicket,
        seteTicket: seteTicket,
        openDate: openDate,
        setOpenDate: setOpenDate,
        email: email,
        setEmail: setEmail,
        code: code,
        setCode: setCode,
        alreadyRegistered: alreadyRegistered,
        setAlreadyRegistered: setAlreadyRegistered,
        info: info,
        setInfo: setInfo,
      };

      useEffect(() => {
        if (index !== firstToBeProfiled) {
          setOpen(false);
        }
      }, [firstToBeProfiled, index]);

      useEffect(() => {
        let visitatore = {};
        if (alreadyRegistered && !codeNotFoundError) {
          //If the item on sale is NOT a ticket, the visitor may have already purchased a ticket that has been profiled
          visitatore = code;
        } else {
          //If the object on sale is a ticket, there is no possibility that the visitor already has a registered ticket code and all information is requested,
          //without the possibility of inserting an existing code
          visitatore.codice_biglietto = newTicketCode;
          visitatore.flag_data_aperta = openDate;
          visitatore.email = email;
          visitatore.warning = warning;
          visitatore.additional_info = info;
        }
        item.visitatore = visitatore;
      }, [
        info,
        newTicketCode,
        code,
        alreadyRegistered,
        openDate,
        email,
        warning,
        codeNotFoundError,
        item,
      ]);

      useEffect(() => {
        if (
          settings?.biglietti_con_codice &&
          item.categoria_articolo.nome_categoria.toUpperCase() === "BIGLIETTI"
        ) {
          if (eTicket) {
            if (isEmpty(email)) {
              setWarning(true);
              setErrorMessage(
                "Inserisci l'indirizzo e-mail del destinatario per proseguire"
              );
            }
          } else {
            if (isEmpty(newTicketCode)) {
              setWarning(true);
              setErrorMessage(
                "Inserisci il codice del biglietto per proseguire"
              );
            }
          }
        }
      }, [settings, eTicket, email, newTicketCode, item]);

      useEffect(() => {
        if (preveTicket?.eTicket !== eTicket) {
          const generateTicketCode = () => {
            return new Date().getTime().toString();
          };

          const isTicketAlreadyInCart = (code) => {
            return (
              shoppingCart.filter(
                (item) => item.visitatore?.codice_biglietto === code
              ).length > 1
            );
          };

          if (eTicket) {
            const tempCode = generateTicketCode();
            if (!isTicketAlreadyInCart(tempCode)) {
              setNewTicketCode(tempCode);
            } else {
              setNewTicketCode(tempCode + 1);
            }
          } else {
            setEmail("");
          }
        }
      }, [eTicket, preveTicket]);

      useEffect(() => {
        /*
      // Check if the ticket has already been sold, if so returns an error, otherwise sell it
      */
        const checkTicketCode = (code) => {
          if (isTicketAlreadyInCart(code)) {
            setErrorMessage(
              "Errore. Il biglietto con codice " + code + " è già nel carrello."
            );
            setWarning(true);
            setShowWarning(true);
          } else {
            itemsService
              .isTicketSold(code)
              .then((ticketSold) => {
                if (ticketSold) {
                  setErrorMessage(
                    "Errore. Il biglietto con codice " +
                      code +
                      " è già stato venduto."
                  );
                  setWarning(true);
                  setShowWarning(true);
                } else {
                  setWarning(false);
                  setErrorMessage("");
                  setShowWarning(false);
                }
              })
              .catch((errors) => {
                if (errors.statusCode === 401 || errors.statusCode === 403) {
                  this.props.history.push("/");
                }
              });
          }
        };

        /*
        // Check if the ticket has already been stored in the cart 
        */
        const isTicketAlreadyInCart = (code) => {
          return (
            shoppingCart.filter(
              (item) => item.visitatore?.codice_biglietto === code
            ).length > 1
          );
        };
        if (!isEmpty(newTicketCode) && !isEmpty(prevNewTicketCode)) {
          const delayDebounceFn = setTimeout(() => {
            checkTicketCode(newTicketCode);
          }, 1000);

          return () => clearTimeout(delayDebounceFn);
        }
      }, [newTicketCode, prevNewTicketCode]);

      useEffect(() => {
        const validateEmail = (email) => {
          const re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          let error = re.test(String(email).toLowerCase());
          setErrorMessage("E-mail non valida");
          setWarning(!error);
          return error;
        };

        const delayDebounceFn = setTimeout(() => {
          if (!isEmpty(email)) {
            validateEmail(email);
          }
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
      }, [email]);

      useEffect(() => {
        const checkTicketCode = (code) =>
          itemsService
            .isTicketSold(code)
            .then((ticketSold) => {
              if (!ticketSold) {
                setCodeNotFoundError(true);
                setIsCodeNotFound(true);
              } else {
                setCodeNotFoundError(false);
                setIsCodeNotFound(false);
              }
            })
            .catch((errors) => {
              if (errors.statusCode === 401 || errors.statusCode === 403) {
                this.props.history.push("/");
              }
            });

        const delayDebounceFn = setTimeout(() => {
          if (!isEmpty(code)) {
            checkTicketCode(code);
          } else {
            setCodeNotFoundError(false);
            setIsCodeNotFound(false);
          }
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
      }, [code]);

      useEffect(() => {
        if (item.visitatore && typeof item.visitatore !== "string") {
          item.visitatore.warning = warning;
          item.visitatore.errorMessage = errorMessage;
        }
      }, [warning, errorMessage, item]);

      return (
        <div style={styles.box}>
          <div
            key={index}
            style={{
              ...styles.bar,
              backgroundColor:
                showWarning && warning ? colors?.highlight : colors?.quaternary,
              justifyContent: "space-between",
            }}
            onClick={() => setOpen(!open)}
          >
            <p
              style={{
                ...styles.title,
                color: showWarning && warning ? colors.white : colors.darkgray,
              }}
            >
              {item.categoria_articolo.nome_categoria.toUpperCase() ===
              "BIGLIETTI"
                ? item.nome_articolo
                : "Profilazione"}
            </p>
            <div>
              {open ? (
                showWarning && warning ? (
                  <img
                    alt="arrowUp"
                    src={arrowUp}
                    style={{
                      width: 12,
                      objectFit: "contain",
                      marginRight: "10px",
                    }}
                  />
                ) : (
                  <img
                    alt="arrowUp"
                    src={arrowDown}
                    style={{
                      width: 12,
                      objectFit: "contain",
                      marginRight: "10px",
                      transform: "rotate(180deg)",
                    }}
                  />
                )
              ) : showWarning && warning ? (
                <img
                  alt="arrowDown"
                  src={arrowUp}
                  style={{
                    width: 12,
                    objectFit: "contain",
                    marginRight: "10px",
                    transform: "rotate(180deg)",
                  }}
                />
              ) : (
                <img
                  alt="arrowDown"
                  src={arrowDown}
                  style={{
                    width: 12,
                    objectFit: "contain",
                    marginRight: "10px",
                  }}
                />
              )}
            </div>
          </div>
          {open && (
            <div style={styles.body}>
              <UserProfiling
                showWarning={showWarning}
                warning={warning}
                errorMessage={errorMessage}
                codeNotFoundError={codeNotFoundError}
                isTicket={
                  item.categoria_articolo.nome_categoria.toUpperCase() ===
                  "BIGLIETTI"
                }
                form={form}
              />
            </div>
          )}
        </div>
      );
    };

    useEffect(() => {
      const firstToBeProfiled = () => {
        let ticketsInCart = shoppingCart.filter(
          (i) =>
            i.categoria_articolo.nome_categoria.toUpperCase() === "BIGLIETTI"
        );
        return shoppingCart.indexOf(ticketsInCart[0]) !== -1
          ? shoppingCart.indexOf(ticketsInCart[0])
          : 0;
      };

      setFirstToBeProfiled(firstToBeProfiled());
    }, [shoppingCart]);

    return (
      <div
        style={{
          ...styles.column,
          width: "80%",
          overflow: "scroll",
          marginBottom: 40,
        }}
      >
        <p
          style={{
            fontSize: 10,
            color: "gray",
            backgroundColor: colors?.white,
            textAlign: "justify",
            width: "80%",
            marginTop: 40,
          }}
        >
          {dictionary.profilingInfo}
        </p>
        {shoppingCart.some(
          (obj) =>
            obj.categoria_articolo.nome_categoria.toUpperCase() === "BIGLIETTI"
        ) ? (
          shoppingCart.map((item, key) => {
            if (
              item.categoria_articolo.nome_categoria.toUpperCase() !==
              "BIGLIETTI"
            ) {
              return null;
            } else {
              return (
                <Sale
                  key={key}
                  item={item}
                  index={key}
                  firstToBeProfiled={firstToBeProfiled}
                />
              );
            }
          })
        ) : (
          <Sale item={shoppingCart[0]} index={0} firstToBeProfiled={0} />
        )}
      </div>
    );
  }
);

export default Profiling;
