import React, { useContext, useEffect, useState } from "react";
import ModifyStock from "./modifyStock";
import StockHistory from "./stockHistory";
import PurchasesHistory from "./purchasesHistory";
import { UserContext } from "../../contexts";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

const Inventory = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const colors = useContext(UserContext)?.colors;
  const permissions = props.permissions;
  const selected = (index) => {
    switch (tabIndex) {
      case 0:
        return <ModifyStock />;
      case 1:
        return <StockHistory />;
      case 2:
        return <PurchasesHistory />;
      default:
        break;
    }
  };

  useEffect(() => {
    if (permissions?.gestione) setTabIndex(0);
    else if (permissions?.transazioni) setTabIndex(1);
    else if (permissions?.carichi) setTabIndex(2);
  }, [permissions]);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: props.iframe ? "100%" : "85%",
      position: "absolute",
      right: 0,
      overflow: "scroll",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    section: {
      alignItems: "start",
      color: colors?.primary,
      width: "100%",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <div style={styles.section}>
          <Tabs
            value={tabIndex}
            variant="fullWidth"
            TabIndicatorProps={{ style: { background: colors?.secondary } }}
            onChange={(event, newValue) => {
              setTabIndex(newValue);
            }}
          >
            <Tab label="Gestione" disabled={!permissions?.gestione} />
            <Tab label="Transazioni" disabled={!permissions?.transazioni} />
            <Tab label="Carichi" disabled={!permissions?.carichi} />
          </Tabs>
        </div>
      </div>
      {selected(tabIndex)}
    </div>
  );
};

export default Inventory;
