import React, { useState, useContext, useEffect } from "react";
import logo from "./../img/logo_white.png";
import settings from "./../img/settings_white.png";
import network from "./../img/network2.png";
import museum from "./../img/museum.png";
import museum_dark from "./../img/museum_white.png";
import network_dark from "./../img/network_white.png";
import logout from "./../img/logout.png";
import ModuleButton from "./buttons/moduleButton";
import SubButton from "./buttons/subButton";
import { UserContext, OrgContext } from "../contexts";
import ReactLoading from "react-loading";

const SideBar = ({
  width,
  selection,
  onSelect,
  exit,
  toggleNetwork,
  permissions,
  changeOrg,
}) => {
  const user = useContext(UserContext).user;
  const organisation = useContext(OrgContext).org;
  const colors = useContext(UserContext).colors;

  const [flagChanging, setFlagChanging] = useState(false);
  const [currentFlag, setCurrentFlag] = useState(user?.flag_network);

  const [loading, setLoading] = useState(false);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      width: "15%",
      position: "absolute",
      left: 0,
      alignItems: "center",
      backgroundColor: colors?.primary,
      boxShadow: "2px 0 4px 0 rgb(0 0 0 / 20%)",
    },
    header: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      marginTop: 30,
      marginBottom: 30,
    },
    content: {
      flex: 10,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      overflowY: "scroll",
    },
    footer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "center",
    },
    title: {
      color: colors?.white,
      fontWeight: "600",
      fontSize: 13,
      maxWidth: "70%",
      overflow: "hidden",
    },
    titleBox: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginBottom: 10,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: colors?.darkgray,
    },
  };

  useEffect(() => {
    if (user?.flag_network !== currentFlag) {
      setFlagChanging(false);
      setCurrentFlag(user?.flag_network);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.flag_network]);

  return (
    <div style={styles.container}>
      <div style={styles.titleBox}>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "40px",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={colors?.white}
              height={20}
              width={20}
            />
          </div>
        ) : (
          <>
            <img
              alt="App logo"
              src={user?.flag_network ? network_dark : museum_dark}
              style={{ width: 20, objectFit: "contain", marginRight: 15 }}
            />
            {user?.org_list?.length > 1 && !user?.flag_network ? (
              <select
                style={{
                  ...styles.title,
                  backgroundColor: "rgba(0, 0, 0, 0.0)",
                  borderColor: "rgba(0, 0, 0, 0.0)",
                  margin: "10px",
                }}
                value={organisation?.id}
                onChange={(event) => {
                  setLoading(true);
                  changeOrg(event.target.value);
                }}
              >
                {user.org_list.map((org, key) => (
                  <option key={key} value={org.orgId}>
                    {org.name}
                  </option>
                ))}
              </select>
            ) : (
              <p style={styles.title}>{organisation?.nome}</p>
            )}
          </>
        )}
      </div>
      <div style={styles.header}>
        <img
          alt="App logo"
          src={logo}
          style={{ width: width * 0.09, objectFit: "contain" }}
        />
      </div>
      <div id="scroll" style={styles.content}>
        {organisation?.moduli
          .sort((a, b) => a.number - b.number)
          .map((mod, key) => {
            if (mod.number === 100) {
              return null;
            } else if (
              permissions &&
              permissions[mod.number] &&
              permissions[mod.number].enabled
            ) {
              return (
                <div key={key} style={{ width: "100%" }}>
                  <ModuleButton
                    selection={selection}
                    mod={mod}
                    onSelect={onSelect}
                  />
                </div>
              );
            } else {
              return null;
            }
          })}
      </div>
      <div style={styles.footer}>
        {organisation?.moduli.some((mod) => mod.number === 100) &&
          !flagChanging && (
            <SubButton
              mod={{
                id: 100,
                name: user?.flag_network ? "Museo" : "Rete museale",
              }}
              selectionColor={colors?.secondary}
              hoverColor={colors?.quinquary}
              icon={user?.flag_network ? museum : network}
              onClick={() => {
                setFlagChanging(true);
                toggleNetwork();
              }}
              size={20}
            />
          )}
        {permissions?.[101]?.enabled && (
          <SubButton
            mod={{ id: 101, name: "Impostazioni" }}
            icon={settings}
            onClick={() => {
              onSelect(101);
            }}
            selection={selection}
            size={20}
            selectionColor={colors?.secondary}
            hoverColor={colors?.quinquary}
          />
        )}
        <SubButton
          mod={{ id: 102, name: "Logout" }}
          icon={logout}
          onClick={() => exit()}
          size={20}
          selectionColor={colors?.secondary}
          hoverColor={colors?.quinquary}
        />
      </div>
    </div>
  );
};

export default SideBar;
